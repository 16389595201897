@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&amp;display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --white: #fff;
  --text-primary: #222;
  --text-secondary: #777;
  --text-danger: #fa5f5f;
  --text-hint: #afafaf;
  --bg-color-hover: #f8f8f9;
  --bg-slate-20: #f3f4f7;
  --bg-slate-700: #4a5363;
  --bg-accent-violet: #9785c2;
  --input-border-color: #d8dde6;
  --border-color: #e4e6e9;
  --primary-color: #007bff;
  --background-color: #f0f0f5;
  --text-color: #343a40;
  --primary-rgb: 135, 96, 251;
  --primary-bg-rgb: 16, 19, 41;
  --primary-bg-color: rgb(var(--primary-rgb));
  --primary-bg-border: rgb(var(--primary-rgb));
  --primary-bg-hover: rgb(var(--primary-rgb), 0.7);
  --primary005: rgb(var(--primary-rgb), 0.05);
  --primary-05: rgb(var(--primary-rgb), 0.5);
  --primary01: rgb(var(--primary-rgb), 0.1);
  --primary02: rgb(var(--primary-rgb), 0.2);
  --primary03: rgb(var(--primary-rgb), 0.3);
  --primary04: rgb(var(--primary-rgb), 0.4);
  --primary05: rgb(var(--primary-rgb), 0.5);
  --primary06: rgb(var(--primary-rgb), 0.6);
  --primary07: rgb(var(--primary-rgb), 0.7);
  --primary08: rgb(var(--primary-rgb), 0.8);
  --primary09: rgb(var(--primary-rgb), 0.9);
  /*white variables*/

  --fixed-white: #fff;
  --white-1: rgba(255, 255, 255, 0.1);
  --white-2: rgba(255, 255, 255, 0.2);
  --white-3: rgba(255, 255, 255, 0.3);
  --white-4: rgba(255, 255, 255, 0.4);
  --white-5: rgba(255, 255, 255, 0.5);
  --white-6: rgba(255, 255, 255, 0.6);
  --white-7: rgba(255, 255, 255, 0.7);
  --white-8: rgba(255, 255, 255, 0.8);
  --white-9: rgba(255, 255, 255, 0.9);
  --white-05: rgba(255, 255, 255, 0.05);
  --white-08: rgba(255, 255, 255, 0.08);
  --white-75: rgba(255, 255, 255, 0.075);
  /*black variables*/
  --black-1: rgba(0, 0, 0, 0.1);
  --black-2: rgba(0, 0, 0, 0.2);
  --black-3: rgba(0, 0, 0, 0.3);
  --black-4: rgba(0, 0, 0, 0.4);
  --black-5: rgba(0, 0, 0, 0.5);
  --black-6: rgba(0, 0, 0, 0.6);
  --black-7: rgba(0, 0, 0, 0.7);
  --black-8: rgba(0, 0, 0, 0.8);
  --black-9: rgba(0, 0, 0, 0.9);
  --black-05: rgba(0, 0, 0, 0.05);
  /*general*/
  --header-bg: 255, 255, 255;
  --menu-bg: 255, 255, 255;
  --color: #165f60;
  --dark: #030303;
  --light: #f2f2f9;
  --menu-primary: rgb(var(--primary-rgb));
  --float-start: left;
  --float-end: right;
  --border: #e9edf4;
  --background: #f6f6fb;
  --light: #f7f8f9;
  --barcolor: #fff;
  --bg-gray-100: #f9f9fb;
  --bg-gray-200: #f0f0f8;
  --bg-gray-300: #e1e1ef;
  --bg-gray-400: #d6d6e6;
  --bg-gray-500: #949eb7;
  --bg-gray-600: #7987a1;
  --bg-gray-700: #4d5875;
  --bg-gray-800: #383853;
  --bg-gray-900: #323251;
  --secondary-rgb: 235, 111, 51;
  --pink-rgb: 241, 0, 117;
  --teal-rgb: 0, 204, 204;
  --purple-rgb: 111, 66, 193;
  --success-rgb: 40, 167, 69;
  --warning-rgb: 255, 193, 7;
  --danger-rgb: 255, 71, 61;
  --info-rgb: 1, 184, 255;
  --orange-rgb: 253, 126, 20;
  --red-rgb: 251, 13, 13;
  --lime-rgb: 13, 224, 13;
  --dark-rgb: 3, 3, 3;
  --indigo-rgb: 77, 106, 152;
  --cyan-rgb: 23, 162, 184;
  --light-rgb: 242, 242, 249;
  --green-rgb: 14, 239, 4;
  --blue-rgb: 7, 104, 241;
  --yellow-rgb: 255, 193, 7;
  --maroon-rgb: 199, 25, 25;
  --aqua-rgb: 3, 241, 241;
  --gray-rgb: 140, 145, 150;
  --darkgray-rgb: 52, 58, 64;
  --azure-rgb: 69, 170, 242;
  --black-rgb: 0, 0, 0;
  --muted-rgb: 153, 166, 183;
  --secondary: rgb(var(--secondary-rgb));
  --pink: rgb(var(--pink-rgb));
  --teal: rgb(var(--teal-rgb));
  --purple: rgb(var(--purple-rgb));
  --success: rgb(var(--success-rgb));
  --warning: rgb(var(--warning-rgb));
  --danger: rgb(var(--danger-rgb));
  --info: rgb(var(--info-rgb));
  --orange: rgb(var(--orange-rgb));
  --red: rgb(var(--red-rgb));
  --lime: rgb(var(--lime-rgb));
  --dark: rgb(var(--dark-rgb));
  --indigo: rgb(var(--indigo-rgb));
  --cyan: rgb(var(--cyan-rgb));
  --light: rgb(var(--light-rgb));
  --green: rgb(var(--green-rgb));
  --blue: rgb(var(--blue-rgb));
  --yellow: rgb(var(--yellow-rgb));
  --maroon: rgb(var(--maroon-rgb));
  --aqua: rgb(var(--aqua-rgb));
  --gray: rgb(var(--gray-rgb));
  --darkgray: rgb(var(--darkgray-rgb));
  --azure: rgb(var(--azure-rgb));
  --black: rgb(var(--black-rgb));
  --muted: rgb(var(--muted-rgb));
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}

body.light-mode {
  --primary-color: #007bff;
  --background-color: #f0f0f5;
  --text-color: #343a40;
  --barcolor: #fff;
  --box-shadow: 0 8px 16px 0 rgba(162, 169, 204, 0.24);
}

body.dark-mode {
  --primary-color: #6c757d;
  --background-color: #1c1c38;
  --barcolor: #1c1c38;
  --border: #1c1c38;
  --mid-background: #fff;
  --text-color: #f8f9fa;
  --color: #fff;
  --box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 8px 16px 0 rgba(0, 0, 0, 0.12);
}

/* button {
    background-color: var(--primary-color);
    color: var(--text-color);
  } */
body {
  /* background: var(--background-color); */
  background: #fff;
  color: var(--color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  -webkit-font-feature-settings: "liga" 0;
  font-feature-settings: "liga" 0;
  height: 100%;
  overflow-y: scroll;
  overflow-x: clip;
  position: relative;
}

/*---  Navigation  ---*/
.nav {
  display: flex;
  flex-wrap: wrap;
  padding-inline-start: 0;
  margin-block-end: 0;
  list-style: none;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: var(--muted);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-block-end: 1px solid var(--border);
  border-block-end-width: 0;
}

.nav-tabs .nav-item {
  margin-block-end: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid var(--border);
  border-start-start-radius: 3px;
  border-start-end-radius: 3px;
  background-color: var(--background);
  border-width: 0;
  border-radius: 0;
  padding: 10px 15px;
  line-height: 1.428;
  color: var(--color);
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: var(--border);
  background-color: rgba(255, 255, 255, 0.5);
  color: var(--color);
}

.nav-tabs .nav-link.disabled {
  color: var(--muted);
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active {
  color: #596882;
  background-color: rgb(var(--white));
  border-color: var(--border);
}

.nav-tabs .nav-item.show .nav-link {
  color: #596882;
  background-color: rgb(var(--white));
  border-color: var(--border);
}

.nav-tabs .dropdown-menu {
  margin-block-start: -1px;
  border-start-start-radius: 0;
  border-start-end-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 3px;
  color: var(--color);
}

.nav-pills .nav-link.active {
  color: rgb(var(--white));
  background-color: var(--primary-bg-color);
}

.nav-pills .show > .nav-link {
  color: rgb(var(--white));
  background-color: var(--primary-bg-color);
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-block-start: 0.33594rem;
  padding-block-end: 0.33594rem;
  margin-inline-end: 1rem;
  font-size: 1.09375rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-inline-start: 0;
  margin-block-end: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-inline-end: 0;
  padding-inline-start: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-block-start: 0.5rem;
  padding-block-end: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.09375rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 3px;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-inline-end: 0;
  padding-inline-start: 0;
  flex-wrap: nowrap;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-inline-end: 0.5rem;
  padding-inline-start: 0.5rem;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: rgb(var(--white));
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: rgb(var(--white));
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link {
  color: rgb(var(--white));
}

.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: rgb(var(--white));
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: rgb(var(--white));
}

.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: rgb(var(--white));
}

.main-navbar-backdrop {
  position: fixed;
  inset-block-start: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  inset-block-end: 0;
  background-color: rgba(0, 0, 0, 0.45);
  z-index: 900;
  visibility: hidden;
  opacity: 0;
  transition: all 0.4s;
  width: auto !important;
}

.main-navbar {
  position: fixed;
  inset-block-start: 0;
  inset-inline-start: 0;
  inset-block-end: 0;
  width: 240px;
  overflow-y: auto;
  background-color: rgb(var(--white));
  z-index: 1000;
  visibility: hidden;
  transform: translateX(-240px);
}

.main-navbar > .container,
.main-navbar > .container-fluid {
  display: flex;
  flex-direction: column;
}

.main-navbar .nav {
  flex-direction: column;
  padding: 20px;
  margin-block-end: 20px;
}

.main-navbar .nav-label {
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--muted);
  letter-spacing: 1px;
  display: block;
  margin-block-end: 10px;
}

.main-navbar .nav-item {
  position: relative;
}

.main-navbar .nav-item.active .nav-link,
.main-navbar .nav-item:hover .nav-link {
  color: var(--primary-bg-color);
}

.main-navbar .nav-item.active .nav-link.with-sub::after,
.main-navbar .nav-item:hover .nav-link.with-sub::after {
  color: var(--primary-bg-color);
}

.main-navbar .nav-item.show > .nav-sub {
  display: block;
}

.main-navbar .nav-item-mega {
  position: static;
}

.main-navbar .nav-link {
  padding: 0;
  color: var(--color);
  font-size: 15px;
  font-weight: 400;
  position: relative;
  display: flex;
  align-items: center;
  height: 42px;
  outline: none;
}

.main-navbar .nav-link i {
  line-height: 0;
  margin-inline-end: 5px;
}

.main-navbar .nav-link i.typcn {
  line-height: 1;
  width: 20px;
}

.main-navbar .nav-link i.typcn::before {
  width: auto;
}

.main-navbar .nav-link.with-sub::after {
  content: "\f3d0";
  font-family: "feather";
  font-size: 11px;
  font-weight: 400;
  display: inline-block;
  position: relative;
  margin-inline-start: auto;
  color: var(--muted);
}

.main-navbar .nav-link.with-sub::before {
  content: "";
  position: absolute;
  inset-block-start: 31px;
  inset-inline-start: 50%;
  margin-inline-start: -7px;
  width: 15px;
  height: 15px;
  border: 1px solid transparent;
  border-block-start-color: rgba(61, 119, 180, 0.2);
  border-inline-start-color: rgba(61, 119, 180, 0.2);
  transform: rotate(45deg);
  background-color: rgb(var(--white));
  z-index: 901;
  display: none;
}

.main-navbar .nav-sub {
  display: none;
  border-block-start: 1px dotted var(--border);
  list-style: none;
  padding: 0;
  margin: 0;
}

.main-navbar .nav-sub-item {
  position: relative;
  display: block;
  margin-inline-start: 0;
}

.main-navbar .nav-sub-item > .nav-sub {
  inset-inline-start: 176px;
  inset-block-start: -10px;
  display: none;
}

.main-navbar .nav-sub-item.show > .nav-sub {
  display: block;
}

.main-navbar .nav-sub-item.active > .nav-sub-link {
  color: var(--primary-bg-color);
}

.main-navbar .nav-sub-item.active > .nav-sub-link:before {
  color: var(--primary-bg-color);
}

.main-navbar .nav-sub-link {
  position: relative;
  font-size: 0.875rem;
  color: var(--color);
  display: flex;
  align-items: center;
  height: 34px;
}

.main-navbar .nav-sub-link:hover,
.main-navbar .nav-sub-link:focus {
  color: var(--primary-bg-color);
  outline: none;
}

.main-navbar .nav-sub-link.with-sub {
  justify-content: space-between;
}

.main-navbar .nav-sub-link.with-sub::after {
  content: "\f3d0";
  font-family: "feather";
  font-size: 11px;
  font-weight: 400;
  display: inline-block;
  position: relative;
  margin-inline-start: auto;
  color: var(--muted);
}

.main-navbar .nav-item .nav-sub.nav-sub-mega li:not(.nav-sub-item) {
  margin-block-end: 15px !important;
}

.main-navbar .nav-item .nav-sub li:not(.nav-sub-item) {
  display: block;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  color: var(--color);
  letter-spacing: 0.5px;
  margin-block-end: 5px !important;
  margin-block-start: 5px !important;
}

.main-navbar .nav-item .nav-sub li:not(.nav-sub-item):first-of-type {
  margin-block-start: 0;
}

.main-navbar .nav-sub-mega .nav li:not(.nav-sub-item) {
  display: block;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  color: var(--color);
  letter-spacing: 0.5px;
  margin-block-end: 10px;
  margin-block-start: 30px;
  margin-inline-start: 30px;
}

.main-navbar .nav-sub-mega .nav li:not(.nav-sub-item):first-of-type {
  margin-block-start: 0;
}

.main-navbar .nav-sub-mega .nav-sub-item + .nav-sub-item {
  padding-block-start: 7px;
  margin-block-start: 7px;
}

.main-navbar .nav-sub-mega .nav-sub-link {
  height: auto;
}

.main-navbar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  border-block-end: 1px solid var(--border);
  height: 60px;
}

.main-navbar-search {
  position: relative;
  padding: 0 20px;
  border-block-end: 1px solid var(--border);
}

.main-navbar-search .form-control {
  border-width: 0;
  padding: 0;
}

.main-navbar-search .form-control:focus {
  box-shadow: none !important;
  border-color: var(--border);
}

.main-navbar-search .btn,
.main-navbar-search .sp-container button {
  background-color: transparent;
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: 0;
  height: 38px;
  padding-inline-start: 20px;
  padding-inline-end: 20px;
  font-size: 16px;
  line-height: 1;
}

.sp-container .main-navbar-search button {
  background-color: transparent;
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: 0;
  height: 38px;
  padding-inline-start: 20px;
  padding-inline-end: 20px;
  font-size: 16px;
  line-height: 1;
}

.nav-link:hover,
.nav-link:focus,
.nav-link:active {
  outline: none;
}

.nav-pills .nav-link:hover,
.nav-pills .nav-link:focus {
  color: var(--color);
}

.nav-pills .nav-link.active {
  border-radius: 3px;
  background-color: var(--primary-bg-color);
}

.nav-pills .nav-link.active:hover,
.nav-pills .nav-link.active:focus {
  color: rgb(var(--white));
}

.nav-dark .nav-link {
  color: var(--muted);
}

.nav-dark .nav-link:hover,
.nav-dark .nav-link:focus {
  color: rgb(var(--white));
}

.nav-tabs .nav-link + .nav-link {
  margin-inline-start: 2px;
}

.nav-tabs .nav-link.active {
  background-color: rgb(var(--white));
  color: var(--color);
  font-weight: 500;
  letter-spacing: -0.1px;
}

.main-navbar-two .nav-item.active {
  position: relative;
}

.main-navbar-two .nav-item.active::before {
  content: "";
  position: absolute;
}

.main-navbar-two .nav-item.show .nav-link::before {
  display: none;
}

.main-navbar-two .nav-link {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
}

.main-navbar-two .nav-sub {
  border-width: 1px;
  inset-block-start: 39px;
  border-block-start-width: 0;
}

.main-navbar-two .nav-sub-item > .nav-sub {
  inset-inline-start: 178px;
  inset-block-start: -8px;
  border-block-start-width: 1px;
}

.main-navbar-two .nav-sub-mega {
  inset-block-start: 58px;
}

.main-navbar-three .nav-item {
  display: block;
}

.main-navbar-three .nav-item + .nav-item {
  border-inline-start-width: 0;
  padding-inline-start: 0;
}

.main-navbar-three .nav-item.show .nav-link::before {
  display: none;
}

.main-navbar-three .nav-item.active .nav-link {
  color: var(--primary-bg-color);
  font-weight: 700;
}

.main-navbar-three .nav-link {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
  color: #3b4863;
  position: relative;
}

.main-navbar-three .nav-link:hover,
.main-navbar-three .nav-link:focus {
  color: var(--color);
}

.main-navbar-three .nav-sub {
  inset-block-start: 35px;
  border-width: 0;
  padding-block-end: 5px;
}

.main-navbar-three .nav-sub-item + .nav-sub-item {
  border-block-start: 0;
}

.main-navbar-three .nav-sub-item .nav-sub {
  inset-block-start: -8px;
  inset-inline-start: 182px;
}

.main-navbar-three .nav-sub-link {
  height: 30px;
}

.main-nav .nav-link {
  display: block;
  color: var(--color);
  padding: 0;
  position: relative;
  line-height: normal;
}

.main-nav .nav-link:hover,
.main-nav .nav-link:focus {
  color: var(--color);
}

.main-nav .nav-link + .nav-link {
  padding-block-start: 12px;
  margin-block-start: 12px;
}

.main-nav .nav-link.active {
  color: var(--primary-bg-color);
}

.main-nav-column {
  flex-direction: column;
}

.main-nav-column .nav-link {
  padding: 0;
  height: 38px;
  color: var(--color);
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.main-nav-column .nav-link i {
  font-size: 24px;
  line-height: 0;
  width: 24px;
  margin-inline-end: 12px;
  text-align: center;
  transition: all 0.2s ease-in-out;
  line-height: 0.9;
}

.main-nav-column .nav-link i:not([class*=" tx-"]) {
  color: var(--muted);
}

.main-nav-column .nav-link span {
  font-weight: 400;
  font-size: 11px;
  margin-inline-start: auto;
}

.main-nav-column .nav-link:hover,
.main-nav-column .nav-link:focus,
.main-nav-column .nav-link:hover i:not([class*=" tx-"]),
.main-nav-column .nav-link:focus i:not([class*=" tx-"]) {
  color: var(--primary-bg-color);
}

.main-nav-column.sm .nav-link {
  font-size: 0.875rem;
  font-weight: 400;
  padding: 10px 0;
}

.main-nav-column.sm .nav-link i {
  font-size: 21px;
}

.main-nav-dark .nav-link {
  color: rgba(255, 255, 255, 0.7);
}

.main-nav-dark .nav-link:hover,
.main-nav-dark .nav-link:focus {
  color: var(--fixed-white);
}

.main-nav-dark .nav-link + .nav-link {
  border-color: #596882;
}

.main-nav-dark .nav-link.active {
  color: var(--primary-bg-color);
}

.main-nav-colored-bg .nav-link + .nav-link {
  border-color: rgba(255, 255, 255, 0.4);
}

.main-nav-colored-bg .nav-link.active {
  color: var(--fixed-white);
}

.main-nav-line {
  position: relative;
}

.main-nav-line .nav-link {
  padding: 0;
  color: var(--color);
  position: relative;
}

.main-nav-line .nav-link:hover,
.main-nav-line .nav-link:focus {
  color: var(--color);
}

.main-nav-line .nav-link.active {
  color: var(--color);
}

.main-nav-line .nav-link.active::before {
  content: "";
  position: absolute;
  inset-block-start: 0;
  inset-block-end: 0;
  inset-inline-start: -20px;
  width: 2px;
  background-color: var(--color);
}

.main-nav-line.main-nav-dark .nav-link {
  color: rgba(255, 255, 255, 0.7);
}

.main-nav-line.main-nav-dark .nav-link:hover,
.main-nav-line.main-nav-dark .nav-link:focus {
  color: rgb(var(--white));
}

.main-nav-line.main-nav-dark .nav-link.active {
  color: rgb(var(--white));
}

.main-nav-line.main-nav-dark .nav-link.active::before {
  background-color: rgb(var(--white));
}

.lSAction > a {
  z-index: 0px !important;
}

.main-nav-tabs {
  padding-block-start: 15px;
  padding-inline-end: 15px;
  padding-block-end: 0;
  padding-inline-start: 15px;
  background-color: var(--border);
}

.main-nav-tabs .lSSlideOuter {
  position: relative;
  padding-inline-start: 32px;
  padding-inline-end: 35px;
}

.main-nav-tabs .lSSlideWrapper {
  overflow: visible;
}

.main-nav-tabs .lSAction > a {
  display: block;
  height: 40px;
  inset-block-start: 16px;
  opacity: 1;
  background-color: var(--border);
  background-image: none;
}

.main-nav-tabs .lSAction > a:hover,
.main-nav-tabs .lSAction > a:focus {
  background-color: #a5afc4;
}

.main-nav-tabs .lSAction > a::before {
  font-family: "feather";
  font-size: 18px;
  position: absolute;
  inset-block-start: -4px;
  inset-inline-start: 0;
  inset-inline-end: 0;
  inset-block-end: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-nav-tabs .lSAction > a.lSPrev {
  inset-inline-start: -32px;
}

.main-nav-tabs .lSAction > a.lSPrev::before {
  content: "\e92e";
}

.main-nav-tabs .lSAction > a.lSNext {
  inset-inline-end: -35px;
}

.main-nav-tabs .lSAction > a.lSNext::before {
  content: "\e92f";
}

.main-nav-tabs .lSAction > a.disabled {
  background-color: var(--border);
  color: rgb(var(--white));
}

.main-nav-tabs .lightSlider {
  display: flex;
}

.main-nav-tabs .tab-item {
  flex-shrink: 0;
  display: block;
  float: none;
  min-width: 150px;
}

.main-nav-tabs .tab-link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  line-height: 1.428;
  color: var(--color);
  white-space: nowrap;
  background-color: var(--background);
}

.main-nav-tabs .tab-link:hover,
.main-nav-tabs .tab-link:focus {
  background-color: rgb(var(--white));
}

.main-nav-tabs .tab-link.active {
  background-color: rgb(var(--white));
  color: var(--color);
  font-weight: 500;
}

.main-nav-calendar-event .nav-link {
  font-size: 13px;
  position: relative;
}

.main-nav-calendar-event .nav-link i {
  font-size: 21px;
  position: relative;
  margin-inline-end: 10px;
  inset-block-start: -0.5px;
}

.main-nav-calendar-event .nav-link.exclude {
  color: var(--muted);
}

.main-nav-calendar-event .nav-link.exclude i {
  color: var(--muted);
}

.main-nav-calendar-event .nav-link.exclude::before {
  content: "";
  position: absolute;
  inset-block-start: 50%;
  inset-inline-start: 0;
  inset-inline-end: 0;
  border-block-start: 1px solid var(--muted);
}

@media print {
  .navbar {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-inline-end: 0;
    padding-inline-start: 0;
  }
}

@media (min-width: 576px) {
  .main-content-body-profile .main-nav-line .nav-link.active::before {
    inset-block-end: -11px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-inline-end: 0;
    padding-inline-start: 0;
  }
}

@media (min-width: 576px) and (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-inline-end: 0.5rem;
    padding-inline-start: 0.5rem;
  }

  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }

  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }

  .nav-tabs .nav-link {
    padding: 10px 20px;
  }

  .main-nav .nav-link + .nav-link {
    padding-block-start: 0;
    margin-block-start: 0;
    border-block-start: 0;
    padding-inline-start: 15px;
    margin-inline-start: 15px;
  }

  .main-nav {
    align-items: center;
  }
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-inline-end: 0;
    padding-inline-start: 0;
  }

  .main-navbar-show {
    overflow: inherit;
  }

  .main-navbar > .container,
  .main-navbar > .container-fluid {
    padding: 0;
  }

  .main-navbar .nav-sub-item > .nav-sub > .nav-sub-item {
    margin-inline-start: 37px;
  }

  .main-navbar .nav-sub-item > .nav-sub > .nav-sub-item > .nav-sub-link {
    padding-inline-start: 10px;
  }

  .main-navbar .nav-sub-mega .container,
  .main-navbar .nav-sub-mega .container-fluid {
    max-width: none;
  }

  .main-navbar .nav-sub-mega .nav + .nav .nav-sub-item:first-child {
    margin-block-start: 6px;
    padding-block-start: 6px;
  }
}

@media (min-width: 576px) and (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-inline-end: 0.5rem;
    padding-inline-start: 0.5rem;
  }

  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }

  .main-navbar {
    position: relative;
    inset-block-start: auto;
    inset-inline-start: auto;
    inset-block-end: auto;
    width: auto;
    padding: 2px;
    border-inline-end: 0;
    overflow-y: inherit;
    display: block;
    border-block-end: 0;
    visibility: visible;
    transform: none;
    z-index: auto;
    z-index: 10;
    box-shadow: 0 2px 17px 1px rgba(162, 169, 204, 0.24);
    border-block-end: 1px solid transparent;
  }

  .main-navbar > .container,
  .main-navbar > .container-fluid {
    flex-direction: row;
    align-items: center;
  }

  .main-navbar > .container {
    padding: 0;
  }

  .main-navbar > .container-fluid {
    padding-inline-start: 20px;
    padding-inline-end: 20px;
  }

  .main-navbar .nav {
    flex-direction: row;
    align-items: center;
    margin-block-end: 0;
    padding: 0;
  }

  .main-navbar .nav-label {
    display: none;
  }

  .main-navbar .nav-item + .nav-item {
    border-block-start: 0;
  }

  .main-navbar .nav-item.show .nav-link::before {
    display: block;
  }

  .main-navbar .nav-link {
    height: auto;
    font-size: 14px;
  }

  .main-navbar .nav-link i {
    margin-inline-end: 8px;
    font-size: 18px;
  }

  .main-navbar .nav-link i.typcn {
    line-height: 0.9;
    width: auto;
  }

  .main-navbar .nav-link.with-sub::after {
    margin-inline-start: 4px;
  }

  .main-navbar .nav-sub {
    position: absolute;
    inset-block-start: 39px;
    inset-inline-start: 0;
    width: 200px;
    background-color: rgb(var(--white));
    border: 1px solid transparent;
    border-block-start: 1px solid var(--border);
    padding: 8px 5px;
    z-index: 900;
    box-shadow: 0 8px 16px 0 rgba(162, 169, 204, 0.24);
  }

  .nav-item-mega .nav-sub {
    box-shadow: none;
  }

  .main-navbar .nav-sub .container {
    box-shadow: 0 8px 16px 0 rgba(162, 169, 204, 0.24);
  }

  .main-navbar .nav-sub-item {
    margin-inline-start: 0;
    padding: 0px 10px;
  }

  .main-navbar .nav-sub-link {
    font-size: 0.8125rem;
    height: 36px;
  }

  .main-navbar .nav-sub-link.with-sub::after {
    margin-inline-start: 4px;
    content: "\e92f";
    font-size: 12px;
  }

  .main-navbar .nav-sub-mega {
    padding: 0 25px;
    border-block-start: 0;
  }

  .main-navbar .nav-sub-mega .container,
  .main-navbar .nav-sub-mega .container-fluid {
    display: flex;
    background-color: rgb(var(--white));
    border: 1px solid transparent;
    border-block-start: 1px solid var(--border);
  }

  .main-navbar .nav-sub-mega .container > div,
  .main-navbar .nav-sub-mega .container-fluid > div {
    display: flex;
    padding: 20px;
    flex: 1;
    margin-block-start: 0;
  }

  .main-navbar .nav-sub-mega .container > div + div,
  .main-navbar .nav-sub-mega .container-fluid > div + div {
    margin-block-start: 0;
    border-inline-start: 1px solid var(--border);
  }

  .main-navbar .nav-sub-mega .nav + .nav {
    margin-inline-start: 20px;
    margin-block-start: 31px;
    padding-block-start: 0;
    border-block-start: 0;
  }

  .main-navbar .nav-item .nav-sub li:not(.nav-sub-item) {
    margin-block-end: 15px;
    margin-inline-start: 0;
  }

  .main-navbar-header,
  .main-navbar-search {
    display: none;
  }

  .main-navbar-three .nav-item + .nav-item {
    margin-inline-start: 25px;
  }

  .main-navbar-three .nav-link {
    display: block;
  }

  .main-navbar-three .nav-link i {
    display: none;
  }

  .main-navbar-three .nav-sub {
    box-shadow: 0 0 0 2px rgba(180, 189, 206, 0.5);
    padding-block-end: 8px;
  }

  .main-navbar-three .nav-sub-link {
    height: 36px;
  }
}

@media (min-width: 576px) and (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-inline-end: 0.5rem;
    padding-inline-start: 0.5rem;
  }

  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }

  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }

  .main-navbar .nav-item + .nav-item {
    margin-inline-start: 20px;
    padding-inline-start: 20px;
  }

  .main-navbar .nav-sub-mega .container > div,
  .main-navbar .nav-sub-mega .container-fluid > div {
    padding: 20px 25px;
  }

  .main-navbar .nav-sub-mega .nav {
    min-width: 140px;
  }

  .main-navbar .nav-sub-mega .nav + .nav {
    margin-inline-start: 25px;
  }
}

@media (min-width: 576px) and (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-inline-end: 0;
    padding-inline-start: 0;
  }
}

@media (min-width: 576px) and (prefers-reduced-motion: reduce) {
  .main-nav-column .nav-link i,
  .main-navbar-backdrop,
  .main-navbar {
    transition: none;
  }
}

@media (min-width: 576px) {
  .main-navbar .nav-sub-mega {
    inset-block-start: 58px;
    inset-inline-start: 0;
    inset-inline-end: 0;
    background-color: transparent;
    border: 0;
    width: auto;
    pointer-events: none;
  }
}

@media (min-width: 576px) {
  .main-navbar .nav-sub-mega .nav {
    min-width: 140px;
    flex-direction: column;
    align-items: stretch;
    padding: 0;
    margin: 0;
  }
}

@media (min-width: 576px) {
  .main-navbar .nav-sub-mega .container,
  .main-navbar .nav-sub-mega .container-fluid {
    padding: 0;
    pointer-events: auto;
  }
}

.nav-pills-circle {
  line-height: 3.7rem;
}

.main-nav-line .nav-link + .nav-link {
  margin-block-start: 0;
  margin-inline-start: 0px;
}

@media (min-width: 320px) {
  .main-nav-line .nav-link + .nav-link {
    margin-inline-start: 25px;
  }

  .main-nav-line .nav-link.active::before {
    inset-block-start: 20px;
    inset-block-end: -18px;
    inset-inline-start: 0;
    inset-inline-end: 0;
    height: 2px;
    width: auto;
  }
}

/*---  Navigation  ---*/
/*---  Pagination  ---*/
.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-inline-start: -1px;
  line-height: 1.25;
  color: var(--primary-bg-color);
  background-color: rgb(var(--white));
  border: 1px solid var(--border);
  color: var(--color);
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0.75rem;
  border-width: 0;
  border: 1px solid var(--border);
  font-weight: 400;
}

.page-item.active .page-link {
  color: rgb(var(--white));
  background-color: var(--primary-bg-color);
  border-color: var(--primary-bg-color);
  background-color: var(--primary-bg-color);
}

.page-item.disabled .page-link {
  color: var(--muted);
  pointer-events: none;
  cursor: auto;
  background-color: rgb(var(--white));
  border-color: var(--border);
}

.page-link:hover {
  z-index: 2;
  color: #7c59e6;
  text-decoration: none;
  background-color: var(--border);
  border-color: var(--border);
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(135, 96, 251, 0.25);
}

.pagination {
  display: flex;
  padding-inline-start: 0;
  list-style: none;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.09375rem;
  line-height: 1.5;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.76562rem;
  line-height: 1.5;
}

.pagination-dark .page-item.active .page-link {
  background-color: var(--primary-bg-color);
  color: rgb(var(--white));
}

.pagination-dark .page-link {
  font-weight: 400;
  background-color: rgba(255, 255, 255, 0.05);
  color: rgba(255, 255, 255, 0.8);
}

.pagination-dark .page-link:hover,
.pagination-dark .page-link:focus {
  color: rgb(var(--white));
  background-color: rgba(255, 255, 255, 0.08);
}

.pagination-primary .page-item.active .page-link {
  background-color: var(--primary-bg-color);
}

.pagination-success .page-item.active .page-link {
  background-color: var(--success);
  border-color: var(--success);
}

.pagination-circled .page-item + .page-item {
  margin-inline-start: 5px;
}

.pagination-circled .page-item:first-child .page-link,
.pagination-circled .page-item:last-child .page-link {
  border-radius: 100%;
}

.pagination-circled .page-link {
  padding: 0;
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

.page-item.active .page-link {
  color: var(--fixed-white);
  background-color: var(--primary-bg-color);
  border-color: var(--primary-bg-color);
  background-color: var(--primary-bg-color);
}

.page-item.disabled .page-link {
  color: var(--muted);
  pointer-events: none;
  cursor: auto;
  background-color: rgb(var(--white));
  border-color: var(--border);
}

.page-link i {
  font-size: 15px;
  line-height: 0;
}

.page-link:hover,
.page-link:focus {
  color: var(--primary-bg-color);
  background-color: var(--border);
  box-shadow: none;
}

.main-page-header {
  position: relative;
  padding-block-start: 30px;
  padding-inline-end: 0px;
  padding-block-end: 40px;
  padding-inline-start: 0px;
  border-block-end: 1px solid #dee2ef;
  margin-block-end: 40px;
}

.main-page-title {
  font-weight: 700;
  font-size: 40px;
  font-family: "Raleway", sans-serif;
  margin-block-end: 15px;
  line-height: 1;
  letter-spacing: -1.5px;
}

.main-page-text {
  font-size: 20px;
  font-weight: 300;
  margin-block-end: 30px;
}

.main-page-content-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.main-page-content-list li {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
}

.main-page-content-list li + li {
  margin-block-start: 5px;
}

.main-page-content-list li i {
  margin-inline-end: 10px;
  font-size: 10px;
  line-height: 0;
  color: var(--muted);
  text-indent: 1px;
}

.main-page-label {
  font-size: 20px;
  font-weight: 600;
  margin-block-end: 5px;
  letter-spacing: -0.2px;
}

/*---  Pagination  ---*/
/*---  Panels  ---*/
.panel-title-landing {
  color: #1643a3 !important;
  font-size: 35px;
  font-weight: bold;
}

.panel-footer-landing {
  border: solid 2px #1643a3 !important;
  border-end-start-radius: 10px !important;
  border-end-end-radius: 10px !important;
  border-block-start: none !important;
  text-align: center;
  background: #f7f7f7;
}

.panel-body {
  padding: 15px;
}

.panel-body .lead {
  font-size: 20px;
  margin-block-end: 0;
  padding: 10px 0;
}

.panel-body .lead strong {
  font-size: 40px;
  margin-block-end: 0;
}

.panel-footer {
  padding: 10px 15px;
  background-color: rgb(var(--white));
  border-block-start: 1px solid var(--border);
  border-inline-start: 1px solid var(--border);
  border-inline-end: 1px solid var(--border);
  border-end-end-radius: 3px;
  border-end-start-radius: 3px;
}

.panel-group .panel,
.panel-group1 .panel {
  border-radius: 5px;
  box-shadow: none;
  border: 1px solid var(--border);
  overflow: hidden;
}

.panel-heading1 a.active {
  border-start-start-radius: 5px;
  border-start-end-radius: 5px;
  border-end-end-radius: 0px;
  border-end-start-radius: 0px;
}

.panel-default > .panel-heading {
  padding: 0;
  border-radius: 0;
  background-color: var(--background);
  border-color: var(--border);
}

.panel-title {
  font-size: 14px;
  margin-block-end: 0;
}

.panel-title > a {
  display: block;
  padding: 15px;
  text-decoration: none;
}

.panel-default > .panel-heading + .panel-collapse > .panel-body {
  border: 0px solid var(--border);
  border-block-start: 0;
}

.panel1 {
  border-block-start-width: 0px;
  border-inline-end-width: 0px;
  border-block-end-width: 1px;
  border-inline-start-width: 0px;
  border-style: solid;
  border-color: rgb(var(--white));
  background: none;
  box-shadow: none;
}

.panel1:last-child {
  border-block-end: none;
}

.panel-group1 > .panel1:first-child .panel-heading1 {
  border-start-start-radius: 4px;
  border-start-end-radius: 4px;
  border-end-end-radius: 0px;
  border-end-start-radius: 0px;
}

.panel-group1 .panel1 {
  border-radius: 0;
}

.panel-body1 {
  padding: 10px;
  background: rgb(var(--white));
}

.panel-title1 {
  font-size: 14px;
  margin-block-end: 0;
}

.panel-group1 .panel1 + .panel1 {
  margin-block-start: 0;
}

.panel-heading1 {
  background-color: #5797fc;
  border-radius: 5px;
  border: none;
  color: rgb(var(--white));
  padding: 0;
}

.panel-group1 .panel-body {
  border: 0px solid var(--border);
}

.panel-title1 a {
  display: block;
  color: var(--fixed-white) !important;
  padding: 15px;
  position: relative;
  font-size: 14px;
  font-weight: 400;
  border-radius: 5px;
}

.panel1:last-child .panel-body1 {
  border-start-start-radius: 0;
  border-start-end-radius: 0;
  border-end-end-radius: 4px;
  border-end-start-radius: 4px;
}

.panel1:last-child .panel-heading1 {
  border-start-start-radius: 0;
  border-start-end-radius: 0;
  border-end-end-radius: 4px;
  border-end-start-radius: 4px;
  transition: border-radius 0.3s linear 0.2s;
}

.panel1:last-child .panel-heading1.active {
  border-radius: 0;
  transition: border-radius linear 0s;
}

/* #bs-collapse icon scale option */
.panel-title a.accordion-toggle:before {
  content: "\f068";
  padding-block-start: 0;
  padding-inline-end: 10px;
  padding-block-end: 0;
  padding-inline-start: 0;
  color: rgb(var(--white));
  font-family: FontAwesome;
  float: right;
}

.panel-title a.accordion-toggle.collapsed:before {
  content: "\f067";
  padding-block-start: 0;
  padding-inline-end: 10px;
  padding-block-end: 0;
  padding-inline-start: 0;
  color: rgb(var(--white));
  font-family: FontAwesome;
  float: right;
}

.panel-heading1 a.collapsed:before {
  content: "\e9af";
  position: absolute;
  font-family: "feather" !important;
  inset-inline-end: 16px;
  inset-block-start: 15px;
  font-size: 15px;
  transition: all 0.5s;
  transform: scale(1);
}

.panel-heading1 a:before {
  content: "\e994";
  position: absolute;
  font-family: "feather" !important;
  inset-inline-end: 16px;
  inset-block-start: 15px;
  font-size: 15px;
  transition: all 0.5s;
  transform: scale(1);
}

.panel-heading1.active a:before {
  content: " ";
  transition: all 0.5s;
  transform: scale(0);
}

.demo-accordion p:last-child,
.panel-group1 p:last-child {
  margin-block-end: 0;
}

.expanel-body {
  padding: 13px !important;
}

.expanel-title {
  margin-block-start: 0;
  margin-block-end: 0;
  font-size: 16px;
  color: inherit;
}

.expanel-default > .expanel-heading {
  background-color: var(--border);
  border-color: var(--border);
}

.expanel-heading {
  padding: 10px 15px;
  border-block-end: 1px solid #e9edf4;
  border-start-start-radius: 3px;
  border-start-end-radius: 3px;
}

.expanel {
  margin-block-end: 20px;
  background-color: var(--background);
  border: 1px solid var(--border);
  border-radius: 3px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.01);
}

.expanel-footer {
  padding: 10px 15px;
  background-color: var(--border);
  border-block-start: 1px solid var(--border);
  border-end-end-radius: 3px;
  border-end-start-radius: 3px;
}

.expanel-primary > .expanel-heading {
  color: #fff;
  background-color: var(--primary-bg-color);
  border-color: var(--primary-bg-color);
}

.expanel-secondary > .expanel-heading {
  color: #fff;
  background-color: #eb6f33;
  border-color: #eb6f33;
}

.expanel-success > .expanel-heading {
  color: #fff;
  background-color: #03c895;
  border-color: #03c895;
}

.expanel-danger > .expanel-heading {
  color: #fff;
  background-color: #ff473d;
  border-color: #ff473d;
}

/*---  Panels  ---*/

/*---  Colorpicker  ---*/
.sp-container {
  background-color: rgb(var(--white));
  border-color: var(--border);
  z-index: 200;
}

.sp-container button {
  border: 0;
  padding: 8px 15px;
  background-image: none;
  background-color: var(--border);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 12px;
  text-shadow: none;
  text-transform: capitalize;
  border-radius: 2px;
}

.sp-container button:hover,
.sp-container button:focus {
  border: 0;
  background-image: none;
  background-color: var(--muted);
  text-shadow: none;
}

.sp-button-container .sp-cancel {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 12px;
  text-transform: capitalize;
  color: var(--muted) !important;
}

.sp-button-container .sp-cancel:hover,
.sp-button-container .sp-cancel:focus {
  color: #3b4863 !important;
  text-decoration: none;
}

.sp-picker-container {
  border-inline-start: 0;
}

.sp-replacer {
  border-color: var(--border);
  background-color: rgb(var(--white));
}

.sp-replacer:hover,
.sp-replacer:focus {
  border-color: #b4bdce;
}

.sp-replacer.sp-active {
  border-color: var(--muted);
}

.sp-dd {
  text-indent: -99999px;
  position: relative;
  width: 10px;
}

.sp-dd::before {
  content: "\f280";
  font-family: "Ionicons";
  font-size: 15px;
  color: var(--muted);
  position: absolute;
  text-indent: 0;
  inset-inline-start: 0;
  z-index: 10;
}

.sp-preview {
  border: 0;
}

.sp-dragger {
  background-color: transparent;
}

.sp-choose {
  background-color: var(--border);
}

.sp-palette .sp-thumb-el:hover,
.sp-palette .sp-thumb-el.sp-thumb-active {
  border-color: #3b4863;
}

.navbar-toggler {
  padding-block-start: 0.4rem;
  padding-inline-end: 0;
  padding-block-end: 0.45rem;
  padding-inline-start: 0.75rem;
  font-size: 1.09375rem;
  color: #272746;
  position: relative;
  line-height: 35px;
  outline: none;
  height: 37px;
  width: 37px;
  text-align: center;
  border: 0px solid #dfd7e9;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 2em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
  color: var(--color);
}

.navbar-toggler:focus {
  box-shadow: none;
}

@media (max-width: 991px) {
  .col-xs-6 {
    width: 50%;
  }
  .responsive-navbar.navbar .navbar-collapse {
    width: 100%;
    background: #fff;
    margin-block-start: 0;
    z-index: 999;
    inset-block-start: 64px;
    border-block-end: 1px solid var(--border);
    border-block-start: 1px solid var(--border);
    position: fixed;
    inset-inline-start: 0;
    inset-inline-end: 0;
  }

  .responsive-navbar.navbar .new.nav-link {
    margin: 10px 6px !important;
  }

  .main-header-right .nav-link.icon {
    margin-block-start: 5px;
  }

  .main-header-right .dropdown {
    position: inherit;
  }

  .main-header-right .dropdown .dropdown-menu {
    width: 95%;
    inset-inline-start: 10px;
    inset-inline-end: 5px;
    inset-block-start: 48px;
  }

  .main-header-right .dropdown .dropdown-menu::before {
    display: none;
  }

  .sidemenu-logo {
    display: none;
  }

  .main-sidebar-body {
    margin-block-start: 0 !important;
  }

  .main-sidebar {
    inset-block-start: 65px !important;
  }

  .main-sidebar-hide .side-header {
    inset-inline-start: 0px;
    inset-inline-end: 0px;
  }

  .side-header {
    position: fixed !important;
    visibility: visible;
  }

  .responsive-logo .mobile-logo {
    display: block;
  }

  .responsive-logo .mobile-logo-light {
    display: none;
  }
}

@media (min-width: 992px) {
  .responsive-logo {
    display: none;
  }

  .navbar-toggler.navresponsive-toggler {
    display: none;
  }

  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg.responsive-navbar .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
    background: transparent;
  }

  .main-sidebar {
    position: relative;
    display: flex;
    transform: none;
    z-index: 9999;
    border-inline-end-width: 1px;
    visibility: visible;
  }

  .main-content.side-content {
    margin-inline-start: 240px;
  }

  .side-header {
    position: fixed !important;
    inset-inline-end: 0;
    padding-inline-start: 240px;
  }

  .main-sidebar-hide .side-header {
    padding-inline-start: 100px;
  }

  .main-footer {
    padding-block-start: 20px;
    padding-inline-end: 20px;
    padding-block-end: 20px;
    padding-inline-start: 270px;
    font-size: 14px;
    font-weight: 400;
    background-color: #fff;
    margin-inline-start: 0px !important;
  }

  .main-sidebar-hide .main-footer {
    padding: 20px 20px 20px 80px;
    padding-block-start: 20px;
    padding-inline-end: 20px;
    padding-block-end: 20px;
    padding-inline-start: 80px;
  }
}

.main-header-right .nav-link.icon {
  margin-block-start: 5px;
}

@media (max-width: 991px) {
  .main-sidebar-hide .sidemenu-logo {
    padding: 12px;
  }
}

/* file manager */
.file-image-1.file-image-md {
  width: 150px;
  height: 150px;
}

.file-image-1.file-image-lg {
  width: 200px;
  height: 200px;
}

.file-image .icons {
  padding: 0;
  margin: 0;
  list-style: none;
  opacity: 0;
  inset-inline-end: 27px;
  inset-block-start: 10px;
  text-align: center;
  position: absolute;
  inset-block-end: 0px;
  z-index: 1;
  transition: all 0.5s ease 0s;
  text-align: right;
}

.file-image:hover .icons {
  opacity: 1;
  inset-block-end: 33px;
}

.file-image-1 {
  width: 110px;
  height: 100px;
  display: inline-table;
  margin: 4px;
  position: relative;
  border: 1px solid #e9edf4;
  margin-block-end: 20px;
  border-radius: 3px;
}

.file-image-1 .icons {
  padding: 0;
  margin: 0;
  list-style: none;
  opacity: 0;
  inset-inline-end: 7px;
  inset-block-start: 6px;
  position: absolute;
  inset-block-end: 0;
  z-index: 1;
  transition: all 0.5s ease 0s;
  text-align: right;
}

.file-image-1:hover .icons {
  opacity: 1;
  inset-block-end: 33px;
}

.file-image-1 .icons li a {
  font-size: 12px;
  line-height: 28px;
  text-align: center;
  height: 25px;
  width: 25px;
  margin: 7px -11px;
  padding: 0;
  display: block;
  position: relative;
  transition: all 0.3s ease-in-out;
  color: #fff;
  border-radius: 3px;
}

.file-image .icons li a {
  font-size: 15px;
  line-height: 35px;
  text-align: center;
  height: 35px;
  width: 35px;
  margin: 6px 6px;
  padding: 0;
  display: block;
  position: relative;
  transition: all 0.3s ease-in-out;
  border-radius: 3px;
}

.file-image-1 .file-name-1 {
  position: absolute;
  inset-inline-end: 0;
  inset-inline-start: 0;
  inset-block-end: -28px;
  color: #524d4d;
  font-size: 12px;
  text-align: center;
  font-weight: 500;
}

.tag:not(:last-child) {
  margin-right: 0.5rem !important;
}

.tags > .tag {
  margin-block-end: 0.5rem;
}

.tag-outline {
  border: 1px solid #e8e8f7;
  color: #1d212f;
  background-color: transparent;
}

.file-radius-attachments {
  padding: 4px 12px;
  border-radius: 50px;
  font-size: 15px;
}

.file-square-attachments {
  padding: 4px 12px;
  border-radius: 3px;
  font-size: 15px;
}

.file-transparent-rounded {
  border-radius: 50px !important;
}

.file-name {
  position: absolute;
  inset-block-end: 20px;
  inset-inline-start: 0;
  inset-inline-end: 0;
  color: #fff;
  font-size: 16px;
  text-align: center;
  font-weight: 500;
}

.list-group-transparent .list-group-item {
  background: none;
}

.file-sm {
  font-size: 12px !important;
  padding: 0px 11px;
}

.file-md {
  font-size: 14px !important;
  padding: 3px 13px;
}

.file-lg {
  font-size: 16px !important;
  padding: 6px 15px;
}

.dropdown-menu.show {
  display: block;
  margin: 0;
  border: 1px solid var(--white);
  box-shadow: 0 3px 9px 0 rgba(104, 113, 123, 0.15);
  border-radius: 0px 0px 5px 5px;
  z-index: 9;
}

.dropdown-menu > li > a {
  display: block;
  padding: 6px 12px !important;
  clear: both;
  font-weight: 400;
  line-height: 1.428571429;
  color: #76839a;
  white-space: nowrap;
}

.open-file {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  inset-inline-end: 0;
  inset-inline-start: 0;
  inset-block-start: 0;
}

.dropdown-menu > li > a:hover {
  text-decoration: none;
  color: var(--primary-bg-color) !important;
  background-color: none;
}

.file-dropdown {
  z-index: 2;
}

.file-manager-image {
  background: #e5bc4a40;
  border-radius: 3px;
  margin-block-end: 25px;
  padding: 0px;
  width: 60px;
  margin-block-start: 0px;
}

.file-image-lg .icons li a {
  font-size: 15px;
  line-height: 35px;
  height: 35px;
  width: 35px;
}

.file-image-md .icons li a {
  font-size: 13px;
  line-height: 30px;
  height: 28px;
  width: 28px;
}

.d-flex .list-group-item {
  border: none !important;
}

.file-list .list-group-item {
  border: none !important;
}

.lg-actions .lg-prev {
  border: 1px solid #a5a1dd;
}

.file-opt span {
  width: 30px;
  height: 30px;
  box-shadow: 0 0 0 2px #fff;
  margin-inline-end: -2px !important;
  border-radius: 5px;
}

/* file manager */
/* file attachments */
/* search page */
.masonry .brick {
  width: auto;
  margin-block-end: 20px;
}

.smartphoto {
  z-index: 9999 !important;
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.smartphoto-arrows li {
  padding: 8px;
  border-radius: 5px;
  background: var(--primary-bg-color);
  border: 1px solid #a5a1dd;
}

.smartphoto-dismiss {
  width: 15px !important;
  height: 15px !important;
  inset-inline-end: 22px !important;
  inset-block-start: 18px !important;
}

.smartphoto-arrow-right {
  inset-inline-end: 15px !important;
}

.smartphoto-arrow-left {
  inset-inline-start: 15px !important;
}

.smartphoto-arrows a {
  width: 50% !important;
  height: 50% !important;
  margin: 7px;
}

/*---  Headers  ---*/
.main-header {
  height: 60px;
  background-color: rgb(var(--header-bg));
  box-shadow: none;
  border-block-end: 1px solid var(--border);
  z-index: 99;
  width: 100%;
}

.main-header > .container,
.main-header > .container-fluid {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline-start: 20px;
  padding-inline-end: 20px;
}

.main-header-left {
  display: flex;
  align-items: center;
}

.main-header-left .main-header-arrow {
  display: none;
  position: relative;
  inset-block-start: -2px;
}

.main-header-menu-icon {
  margin-inline-end: 10px;
  width: 20px;
  height: 30px;
  display: flex;
  align-items: center;
}

.main-header-menu-icon span {
  display: block;
  position: relative;
  width: 15px;
  height: 2px;
  background-color: #fff;
  border-radius: 3px;
  transition: all 0.2s ease-in-out;
}

.main-header-menu-icon span::before,
.main-header-menu-icon span::after {
  content: "";
  position: absolute;
  inset-inline-start: 0;
  width: 20px;
  height: 2px;
  background-color: var(--color);
  border-radius: 3px;
  transition: all 0.2s ease-in-out;
}

.main-header-menu-icon span::before {
  inset-block-start: -6px;
}

.main-header-menu-icon span::after {
  inset-block-end: -6px;
}

.main-header-menu {
  position: fixed;
  inset-block-start: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  width: 240px;
  background-color: rgb(var(--white));
  overflow-y: auto;
  z-index: 1000;
  transform: translateX(-240px);
  transition: all 0.35s;
}

.main-header-menu > .nav {
  flex-direction: column;
  padding: 20px;
}

.main-header-menu .nav-item::before {
  content: "";
  position: absolute;
  inset-block-start: 67px;
  margin-inline-start: 25px;
  width: 15px;
  height: 15px;
  border-block-start: 2px solid rgba(28, 39, 60, 0.12);
  border-inline-start: 2px solid rgba(28, 39, 60, 0.12);
  background-color: rgb(var(--white));
  transform: rotate(45deg);
  z-index: 100;
  display: none;
}

.main-header-menu .nav-item + .nav-item {
  margin-block-start: 10px;
}

.main-header-menu .nav-item > .nav-link {
  display: flex;
  align-items: center;
  position: relative;
  color: var(--color);
  font-size: 15px;
  font-weight: 500;
  padding: 0;
  transition: all 0.2s ease-in-out;
}

.main-header-menu .nav-item > .nav-link:hover {
  color: var(--primary-bg-color);
}

.main-header-menu .nav-item > .nav-link:hover i {
  color: var(--primary-bg-color);
}

.main-header-menu .nav-item > .nav-link.with-sub::after {
  content: "\f3d0";
  display: inline-block;
  font-family: "Ionicons";
  font-size: 12px;
  font-weight: 400;
  margin-inline-start: auto;
  margin-block-start: 3px;
  opacity: 0.5;
}

.main-header-menu .nav-item > .nav-link .typcn {
  font-size: 20px;
  line-height: 0.95;
  margin-inline-end: 15px;
  width: 16px;
}

.main-header-menu .nav-item > .nav-link .typcn::before {
  width: auto;
}

.main-header-menu .nav-item.active > .nav-link {
  color: var(--primary-bg-color);
  position: relative;
}

.main-header-menu .nav-item.active > .nav-link::before {
  content: "";
  position: absolute;
  inset-block-end: 0;
  inset-block-start: 0;
  inset-inline-start: -20px;
  width: 2px;
  background-color: var(--primary-bg-color);
  border-radius: 3px;
}

.main-header-menu .nav-item.show .main-menu-sub {
  display: block;
}

.main-header-menu-header {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  border-block-end: 1px solid rgba(28, 39, 60, 0.05);
}

.main-header-menu-header .btn-close {
  font-weight: 300;
  font-size: 28px;
}

.main-header-center {
  flex: 1;
  margin: 0 0.5rem;
  width: 250px;
  position: relative;
  display: none;
}

.main-header-center .form-control {
  border-color: var(--border);
  border-width: 1px;
  background-color: rgb(var(--white));
  transition: none;
  height: 40px;
}

.main-header-center .form-control:focus {
  background-color: rgb(var(--white));
  border-color: #b4bdce;
  box-shadow: none !important;
}

.main-header-center .form-control:focus + .btn {
  color: var(--muted);
}

.main-header-center .sp-container .form-control:focus + button {
  color: var(--muted);
}

.sp-container .main-header-center .form-control:focus + button {
  color: var(--muted);
}

.main-header-center .btn,
.main-header-center .sp-container button {
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: 0;
  background-color: transparent;
  height: 40px;
  color: #b4bdce;
  transition: none;
  font-size: 16px;
  padding-inline-end: 13px;
}

.sp-container .main-header-center button {
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: 0;
  background-color: transparent;
  height: 40px;
  color: #b4bdce;
  transition: none;
  font-size: 16px;
  padding-inline-end: 13px;
}

.main-header-center .btn:hover,
.main-header-center .sp-container button:hover {
  color: var(--color);
}

.sp-container .main-header-center button:hover {
  color: var(--color);
}

.main-header-center .btn:focus,
.main-header-center .sp-container button:focus {
  color: var(--color);
}

.sp-container .main-header-center button:focus {
  color: var(--color);
}

.main-header-right {
  display: flex;
  align-items: center;
}

.main-header-right .btn-social {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--muted);
  border-radius: 3px;
  transition: all 0.2s ease-in-out;
}

.main-header-right .btn-social:hover,
.main-header-right .btn-social:focus {
  color: #596882;
}

.main-header-right .btn-social i {
  font-size: 18px;
  line-height: 0;
}

.main-header-right .btn-social + .btn-social {
  margin-inline-start: 10px;
}

.main-header-right .btn-buy {
  text-transform: uppercase;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 11px;
  font-weight: 500;
  background-color: var(--primary-bg-color);
  color: rgb(var(--white));
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
  border-radius: 3px;
  transition: all 0.2s ease-in-out;
  margin-inline-start: 20px;
}

.main-header-right .btn-buy:hover,
.main-header-right .btn-buy:focus {
  background-color: var(--primary-bg-color);
}

.main-header-search-link {
  font-size: 18px;
  color: #596882;
  line-height: 1;
  margin-inline-end: 15px;
}

.main-header-arrow {
  font-size: 27px;
  color: var(--color);
  display: inline-block;
  line-height: 0;
}

.main-header-arrow i {
  line-height: 0;
}

.main-header-arrow:hover,
.main-header-arrow:focus {
  color: #596882;
}

.main-header-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.main-header-profile .main-img-user {
  width: 120px;
  height: 120px;
  margin-block-end: 20px;
}

.main-header-profile .main-img-user::after {
  display: none;
}

.main-header-profile h6 {
  font-size: 24px;
  font-weight: 700;
  color: var(--color);
  margin-block-end: 0;
}

.main-header-profile span {
  display: block;
  font-size: 13px;
}

.main-header-sticky {
  position: fixed;
  inset-block-start: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  z-index: 1000;
}

.main-logo {
  font-weight: 700;
  font-size: 28px;
  font-family: "Poppins", sans-serif;
  text-transform: lowercase;
  color: var(--primary-bg-color);
  letter-spacing: -1px;
  display: flex;
  align-items: center;
  position: relative;
  inset-block-start: 0px;
}

.main-logo:hover,
.main-logo:focus {
  color: var(--primary-bg-color);
}

.main-logo i {
  display: block;
  line-height: 0.9;
  margin-inline-end: 8px;
  font-size: 40px;
}

.main-logo i::before {
  width: auto;
  height: auto;
}

/*---  Headers  ---*/
.main-header {
  height: 65px;
  background-color: var(--barcolor);
  box-shadow: none;
  border-block-end: 1px solid var(--border);
  z-index: 99;
}

.main-header > .container,
.main-header > .container-fluid {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  padding-inline-start: 20px;
  padding-inline-end: 20px;
}

.main-header-left .main-header-arrow {
  display: none;
  position: relative;
  inset-block-start: -2px;
}

.main-header-menu-icon span::before,
.main-header-menu-icon span::after {
  content: "";
  position: absolute;
  inset-inline-start: 0;
  width: 20px;
  height: 2px;
  background-color: #fff;
  border-radius: 3px;
  transition: all 0.2s ease-in-out;
}

.main-header-menu-icon span::before {
  inset-block-start: -6px;
}

.main-header-menu-icon span::after {
  inset-block-end: -6px;
}

.main-header-menu {
  position: fixed;
  inset-block-start: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  width: 240px;
  background-color: rgb(var(--white));
  overflow-y: auto;
  z-index: 1000;
  transform: translateX(-240px);
  transition: all 0.35s;
}

.main-header-menu > .nav {
  flex-direction: column;
  padding: 20px;
}

.main-header-menu .nav-item::before {
  content: "";
  position: absolute;
  inset-block-start: 67px;
  margin-inline-start: 25px;
  width: 15px;
  height: 15px;
  border-block-start: 2px solid rgba(28, 39, 60, 0.12);
  border-inline-start: 2px solid rgba(28, 39, 60, 0.12);
  background-color: rgb(var(--white));
  transform: rotate(45deg);
  z-index: 100;
  display: none;
}

.main-header-menu .nav-item + .nav-item {
  margin-block-start: 10px;
}

.main-header-menu .nav-item > .nav-link {
  display: flex;
  align-items: center;
  position: relative;
  color: var(--color);
  font-size: 15px;
  font-weight: 500;
  padding: 0;
  transition: all 0.2s ease-in-out;
}

.main-header-menu .nav-item > .nav-link:hover {
  color: var(--primary-bg-color);
}

.main-header-menu .nav-item > .nav-link:hover i {
  color: var(--primary-bg-color);
}

.main-header-menu .nav-item > .nav-link.with-sub::after {
  content: "\f3d0";
  display: inline-block;
  font-family: "Ionicons";
  font-size: 12px;
  font-weight: 400;
  margin-inline-start: auto;
  margin-block-start: 3px;
  opacity: 0.5;
}

.main-header-menu .nav-item > .nav-link .typcn {
  font-size: 20px;
  line-height: 0.95;
  margin-inline-end: 15px;
  width: 16px;
}

.main-header-menu .nav-item > .nav-link .typcn::before {
  width: auto;
}

.main-header-menu .nav-item.active > .nav-link {
  color: var(--primary-bg-color);
  position: relative;
}

.main-header-menu .nav-item.active > .nav-link::before {
  content: "";
  position: absolute;
  inset-block-end: 0;
  inset-block-start: 0;
  inset-inline-start: -20px;
  width: 2px;
  background-color: var(--primary-bg-color);
  border-radius: 3px;
}

.main-header-menu .nav-item.show .main-menu-sub {
  display: block;
}

.main-header-menu-header {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  border-block-end: 1px solid rgba(28, 39, 60, 0.05);
}

.main-header-menu-header .btn-close {
  font-weight: 300;
  font-size: 28px;
}

.main-header-center {
  flex: 1;
  margin: 0 0.5rem;
  width: 250px;
  position: relative;
  display: none;
}

.main-header-center .form-control {
  border-color: var(--border);
  border-width: 1px;
  background-color: rgb(var(--white));
  transition: none;
  height: 40px;
}

.main-header-center .form-control:focus {
  background-color: var(--white);
  border-color: #b4bdce;
  box-shadow: none !important;
}

.main-header-center .form-control:focus + .btn,
.main-header-center .sp-container .form-control:focus + button,
.sp-container .main-header-center .form-control:focus + button {
  color: var(--muted);
}

.main-header-center .btn,
.main-header-center .sp-container button,
.sp-container .main-header-center button {
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: 0;
  background-color: transparent;
  height: 40px;
  color: #b4bdce;
  transition: none;
  font-size: 16px;
  padding-inline-end: 13px;
}

.main-header-right {
  display: flex;
  align-items: center;
}

.main-header-right .btn-social {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--muted);
  border-radius: 3px;
  transition: all 0.2s ease-in-out;
}

.main-header-right .btn-social:hover,
.main-header-right .btn-social:focus {
  color: #596882;
}

.main-header-right .btn-social i {
  font-size: 18px;
  line-height: 0;
}

.main-header-right .btn-social + .btn-social {
  margin-inline-start: 10px;
}

.main-header-right .btn-buy {
  text-transform: uppercase;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 11px;
  font-weight: 500;
  background-color: var(--primary-bg-color);
  color: rgb(var(--white));
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
  border-radius: 3px;
  transition: all 0.2s ease-in-out;
  margin-inline-start: 20px;
}

.main-header-right .btn-buy:hover,
.main-header-right .btn-buy:focus {
  background-color: var(--primary-bg-color);
}

.main-header-search-link {
  font-size: 18px;
  color: #596882;
  line-height: 1;
  margin-inline-end: 15px;
}

.main-header-arrow {
  font-size: 27px;
  color: var(--color);
  display: inline-block;
  line-height: 0;
}

.main-header-arrow i {
  line-height: 0;
}

.main-header-arrow:hover,
.main-header-arrow:focus {
  color: #596882;
}

.main-header-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.main-logo {
  font-weight: 700;
  font-size: 28px;
  font-family: "Poppins", sans-serif;
  text-transform: lowercase;
  color: var(--primary-bg-color);
  letter-spacing: -1px;
  display: flex;
  align-items: center;
  position: relative;
  inset-block-start: 0px;
}

.main-logo:hover,
.main-logo:focus {
  color: var(--primary-bg-color);
}

.main-logo i {
  display: block;
  line-height: 0.9;
  margin-inline-end: 8px;
  font-size: 40px;
}

.main-logo i::before {
  width: auto;
  height: auto;
}

@media (min-width: 576px) {
  .main-header-profile .main-img-user {
    width: 80px;
    height: 80px;
  }

  .main-header-profile h6 {
    font-size: 18px;
  }

  .main-header-profile .main-img-user {
    width: 80px;
    height: 80px;
  }

  .main-header-profile h6 {
    font-size: 18px;
  }
}

@media (max-width: 991.98px) {
  .main-header-menu-show {
    overflow: hidden;
  }

  .main-header-menu-show .main-header-menu {
    border-inline-end: 1px solid rgba(28, 39, 60, 0.08);
    box-shadow: 0 0 30px rgba(28, 39, 60, 0.08);
    transform: translateX(0);
  }

  .main-header-menu-show .main-navbar-backdrop {
    visibility: visible;
    opacity: 1;
  }

  .main-header > .container {
    max-width: none;
  }

  .main-header-menu-show {
    overflow: hidden;
  }

  .main-header-menu-show .main-header-menu {
    border-inline-end: 1px solid rgba(28, 39, 60, 0.08);
    box-shadow: 0 0 30px rgba(28, 39, 60, 0.08);
    transform: translateX(0);
  }

  .main-header-menu-show .main-navbar-backdrop {
    visibility: visible;
    opacity: 1;
  }

  .main-header > .container {
    max-width: none;
  }
}

@media (min-width: 992px) {
  .main-header {
    position: relative;
    height: 65px;
  }

  .main-header > .container,
  .main-header > .container-fluid {
    padding-inline-start: 25px;
    padding-inline-end: 25px;
  }

  .main-header > .container {
    padding: 0;
  }

  .main-header .main-header-left .main-logo {
    display: flex;
  }

  .main-header-menu-icon {
    margin-inline-end: 25px;
  }

  .main-header-menu {
    background-color: transparent;
    border-inline-end: 0;
    position: static;
    width: auto;
    overflow-y: visible;
    transform: none;
    z-index: auto;
  }

  .main-header-menu > .nav {
    padding: 0;
    flex-direction: row;
    align-items: center;
  }

  .main-header-menu .nav-item + .nav-item {
    margin-block-start: 0;
    margin-inline-start: 30px;
  }

  .main-header-menu .nav-item > .nav-link {
    font-size: 0.875rem;
  }

  .main-header-menu .nav-item > .nav-link.with-sub::after {
    margin-inline-start: 5px;
  }

  .main-header-menu .nav-item > .nav-link .typcn {
    font-size: 18px;
    margin-inline-end: 7px;
  }

  .main-header-menu .nav-item.active > .nav-link::before {
    inset-block-start: auto;
    inset-block-end: -22px;
    inset-inline-start: 0;
    inset-inline-end: 0;
    width: auto;
    height: 2px;
  }

  .main-header-menu .nav-item.show::before {
    display: block;
  }

  .main-header-menu-header {
    display: none;
  }

  .main-header-center {
    display: block;
  }

  .main-header {
    position: relative;
    height: 66px;
  }

  .main-header > .container,
  .main-header > .container-fluid {
    padding-inline-start: 25px;
    padding-inline-end: 25px;
  }

  .main-header .main-header-left .main-logo {
    display: none;
  }

  .main-header-menu-icon {
    margin-inline-end: 25px;
  }

  .main-header-menu {
    background-color: transparent;
    border-inline-end: 0;
    position: static;
    width: auto;
    overflow-y: visible;
    transform: none;
    z-index: auto;
  }

  .main-header-menu > .nav {
    padding: 0;
    flex-direction: row;
    align-items: center;
  }

  .main-header-menu .nav-item + .nav-item {
    margin-block-start: 0;
    margin-inline-start: 30px;
  }

  .main-header-menu .nav-item > .nav-link {
    font-size: 0.875rem;
  }

  .main-header-menu .nav-item > .nav-link.with-sub::after {
    margin-inline-start: 5px;
  }

  .main-header-menu .nav-item > .nav-link .typcn {
    font-size: 18px;
    margin-inline-end: 7px;
  }

  .main-header-menu .nav-item.active > .nav-link::before {
    inset-block-start: auto;
    inset-block-end: -22px;
    inset-inline-start: 0;
    inset-inline-end: 0;
    width: auto;
    height: 2px;
  }

  .main-header-menu .nav-item.show::before {
    display: block;
  }

  .main-header-menu-header {
    display: none;
  }

  .main-header-center {
    display: block;
  }
}

@media (prefers-reduced-motion: reduce) {
  .main-header-menu-icon span {
    transition: none;
  }

  .main-header-menu-icon span::before,
  .main-header-menu-icon span::after {
    transition: none;
  }

  .main-header-menu {
    transition: none;
  }

  .main-header-menu .nav-item > .nav-link {
    transition: none;
  }

  .main-header-right .btn-social {
    transition: none;
  }

  .main-header-right .btn-buy {
    transition: none;
  }

  .main-header-menu-icon span {
    transition: none;
  }

  .main-header-menu-icon span::before,
  .main-header-menu-icon span::after {
    transition: none;
  }

  .main-header-menu {
    transition: none;
  }

  .main-header-menu .nav-item > .nav-link {
    transition: none;
  }

  .main-header-right .btn-social,
  .main-header-right .btn-buy {
    transition: none;
  }
}

.main-navbar .nav-item .nav-sub-item.show > .nav-sub1 {
  display: block;
}

.main-navbar .nav-sub1 {
  display: none;
  list-style: none;
  padding: 0;
  margin: 0;
}

.main-navbar .nav-sub-link {
  padding: 0;
  color: var(--color);
  font-size: 0.8125rem;
  font-weight: 400;
  position: relative;
  display: flex;
  align-items: center;
  height: 42px;
  outline: none;
}

.main-navbar .nav-sub-link.with-sub1::after {
  content: "\f3d0";
  font-family: "Ionicons";
  font-size: 11px;
  font-weight: 400;
  display: inline-block;
  position: relative;
  margin-inline-start: auto;
  color: #a8afc7;
}

.main-navbar .nav-item .nav-sub-item.show > .nav-sub2 {
  display: block;
}

.main-navbar .nav-sub2 {
  display: none;
  list-style: none;
  padding: 0;
  margin: 0;
}

.main-navbar .nav-sub-link.with-sub2::after {
  content: "\f3d0";
  font-family: "Ionicons";
  font-size: 11px;
  font-weight: 400;
  display: inline-block;
  position: relative;
  margin-inline-start: auto;
  color: #a8afc7;
}

/*---  Main-menu  ---*/
[dir="rtl"] .demo_changer {
  transition: all 0.3s ease;
  left: -270px;
  right: auto !important;
}

[dir="rtl"] .demo_changer.active {
  transition: all 0.3s ease;
  right: auto !important;
  left: 0;
}

[dir="rtl"] .sidebar.sidebar-right,
[dir="rtl"] .sidebar.sidebar-profile {
  transform: translate(-100%, 0);
}

[dir="rtl"] .sidebar.sidebar-right.sidebar-open,
[dir="rtl"] .sidebar.sidebar-profile.sidebar-open {
  transform: translate(0, 0);
}

[dir="rtl"] .media-title {
  text-align: start;
}

[dir="rtl"] .card-text {
  text-align: start;
}

[dir="rtl"] .card-title {
  text-align: start;
}

[dir="rtl"] .card-subtitle {
  text-align: start;
}

[dir="rtl"] .leaflet-pane,
[dir="rtl"] .leaflet-tile,
[dir="rtl"] .leaflet-marker-icon,
[dir="rtl"] .leaflet-marker-shadow,
[dir="rtl"] .leaflet-tile-container {
  inset-inline-end: 0;
  inset-inline-start: auto;
}

[dir="rtl"] [type="email"],
[dir="rtl"] [type="number"],
[dir="rtl"] [type="tel"],
[dir="rtl"] [type="url"] {
  direction: rtl;
}

[dir="rtl"] .select2-dropdown.select2-dropdown--below .select2-results__option {
  text-align: end;
}

[dir="rtl"] .vtimeline .timeline-wrapper .timeline-panel {
  box-shadow: -16px 9px 16px 0 rgba(162, 169, 204, 0.24);
}

[dir="rtl"] .vtimeline .timeline-wrapper.timeline-inverted .timeline-panel {
  box-shadow: 16px 9px 16px 0 rgba(162, 169, 204, 0.24);
}

[dir="rtl"] #thumbcarousel .carousel-control-prev {
  left: 0;
  right: inherit;
  transform: rotate(360deg);
}

[dir="rtl"] #thumbcarousel .carousel-control-next {
  right: 0;
  left: inherit;
  transform: rotate(360deg);
}

[dir="rtl"] .fe.fe-chevron-right {
  transform: rotate(180deg);
}

[dir="rtl"] .fe.fe-chevron-left {
  transform: rotate(180deg);
}

[dir="rtl"] .main-msg-wrapper:before,
[dir="rtl"] .main-msg-wrapper:after {
  left: 100%;
  right: inherit;
  border-color: transparent transparent transparent var(--background);
}

[dir="rtl"] .tree .branch .si-arrow-right-circle {
  transform: rotate(180deg);
}

[dir="rtl"] .dTree li.folder-group {
  background: none;
}

[dir="rtl"] .carousel-control-next,
[dir="rtl"] .carousel-control-prev {
  transform: rotate(180deg);
}

[dir="rtl"] .carousel-control-next,
[dir="rtl"] .carousel-control-next {
  transform: rotate(180deg);
}

[dir="rtl"] .step-footer .mdi-arrow-left {
  transform: rotate(180deg);
}

[dir="rtl"] .step-footer .mdi-arrow-right {
  transform: rotate(180deg);
}

[dir="rtl"] .wizard.vertical > .content {
  border-inline-start: 1px solid var(--border);
}

[dir="rtl"] .fa.fa-angle-double-right {
  transform: rotate(180deg);
}

[dir="rtl"] .ion-chevron-right {
  transform: rotate(180deg);
}

[dir="rtl"] .component-item a:before {
  transform: rotate(180deg);
}

[dir="rtl"] .page-link .ion-chevron-right {
  transform: rotate(180deg);
}

[dir="rtl"] .page-link .ion-chevron-left {
  transform: rotate(180deg);
}

[dir="rtl"] .lds-heart {
  transform: rotate(315deg);
}

[dir="rtl"] .lg-actions .lg-prev {
  transform: rotate(180deg);
}

[dir="rtl"] .lg-actions .lg-next {
  transform: rotate(180deg);
}

[dir="rtl"] .list-group-flush .fa-angle-right {
  transform: rotate(180deg);
}

[dir="rtl"] .daterangepicker.ltr.show-calendar {
  right: 310px !important;
}

[dir="rtl"] .angle {
  transform: rotate(180deg);
}

[dir="rtl"] .main-nav-tabs .lSAction > a.lSPrev {
  transform: rotate(180deg);
}

[dir="rtl"] .main-nav-tabs .lSAction > a.lSNext {
  transform: rotate(180deg);
}

[dir="rtl"] .tab_wrapper .content_wrapper .accordian_header .arrow {
  transform: rotate(135deg);
}

[dir="rtl"] .tab_wrapper .content_wrapper .accordian_header.active .arrow {
  transform: rotate(312deg);
}

[dir="rtl"] .MS-content {
  direction: ltr;
}

@media (max-width: 767px) {
  [dir="rtl"] .vtimeline .timeline-wrapper .timeline-panel:after {
    border-left: 14px solid var(--light) !important;
    border-right: 0 solid transparent !important;
    right: -14px !important;
    left: auto !important;
  }
}

[dir="rtl"] .ui-datepicker .ui-datepicker-header .ui-datepicker-next {
  transform: rotate(180deg);
}

[dir="rtl"] .ui-datepicker .ui-datepicker-header .ui-datepicker-prev {
  transform: rotate(180deg);
}

[dir="rtl"] .echart-height span {
  margin-left: 5px;
  margin-right: inherit;
}

[dir="rtl"] .fc-direction-ltr {
  direction: rtl;
}

[dir="rtl"] .fc-icon-chevron-left {
  transform: rotate(-180deg);
}

[dir="rtl"] .fc-icon-chevron-right {
  transform: rotate(-180deg);
}

[dir="rtl"] .fc-direction-ltr {
  direction: rtl;
}

[dir="rtl"] .ribbon-top-left span {
  transform: rotate(45deg);
}

[dir="rtl"] .ribbone-top-left span {
  transform: rotate(45deg);
}

[dir="rtl"] .ribbone-top-right span {
  transform: rotate(315deg);
}

[dir="rtl"] .power-ribbone-top-right span {
  transform: rotate(-45deg);
}

[dir="rtl"] .power-ribbone-top-right span i {
  transform: rotate(45deg);
}

[dir="rtl"] .power-ribbone-bottom-right span {
  transform: rotate(45deg);
}

[dir="rtl"] .power-ribbone-bottom-right span i {
  transform: rotate(-45deg);
}

[dir="rtl"] .power-ribbone-bottom-left span {
  transform: rotate(-45deg);
}

[dir="rtl"] .power-ribbone-bottom-left span i {
  transform: rotate(45deg);
}

[dir="rtl"] .power-ribbone-top-left span {
  transform: rotate(45deg);
}

[dir="rtl"] .power-ribbone-top-left span i {
  transform: rotate(-45deg);
}

[dir="rtl"] .morris-wrapper-demo svg.text {
  text-anchor: start;
}

[dir="rtl"] .img-flag {
  float: right;
  margin-block-start: 4px;
}

/*---  Sidebar  ---*/
.main-sidebar {
  width: 240px;
  flex-direction: column;
  flex-shrink: 0;
  border-inline-end: 0 solid var(--border);
  transform: translateX(-240px);
  visibility: hidden;
  transition: all 0.4s;
}

.main-sidebar-sticky {
  position: fixed;
  inset-block-start: 0px;
  inset-inline-start: 0;
  inset-block-end: 0;
  background: rgb(var(--white));
  box-shadow: 0 8px 24px rgba(61, 119, 180, 0.12);
  transition: left 0.3s ease, width 0.3s ease;
  border-inline-end: 1px solid var(--border);
}

.main-sidebar-header {
  flex-shrink: 0;
  height: 64px;
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.main-sidebar-loggedin {
  padding: 10px 20px;
  display: flex;
  flex-shrink: 0;
}

.main-sidebar-loggedin .main-img-user {
  width: 36px;
  height: 36px;
}

.main-sidebar-loggedin .media-body {
  margin-inline-start: 12px;
}

.main-sidebar-loggedin .media-body h6 {
  font-weight: 700;
  font-size: 14px;
  color: var(--color);
  margin-block-end: 2px;
}

.main-sidebar-loggedin .media-body span {
  display: block;
  color: var(--muted);
}

.main-sidebar-body {
  padding: 10px;
}

.main-sidebar-body .nav {
  flex-direction: column;
  margin-inline: 0 !important;
}

.main-sidebar-body .nav-label {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: var(--muted);
  padding-block-start: 15px;
  padding-inline-end: 0;
  padding-block-end: 0px;
  padding-inline-start: 0;
  text-align: start;
}

.main-sidebar-body .nav-item {
  position: relative;
  display: block;
}

.main-sidebar-body .nav-item.active .nav-link {
  color: var(--primary-bg-color);
}

.main-sidebar-body .nav-item + .nav-item {
  margin-block-start: -1px;
}

.main-sidebar-body .nav-link {
  display: flex;
  align-items: center;
  padding: 14px 0px;
  font-weight: 400;
  font-size: 15px;
  color: var(--color);
  margin-inline-start: 10px;
}

.main-sidebar-body .nav-link i {
  font-size: 18px;
  line-height: 0;
  margin-inline-end: 5px;
  width: 24px;
}

.main-sidebar-body .nav-link i.typcn {
  line-height: 0.9;
}

.main-sidebar-body .nav-link i.typcn::before {
  width: auto;
}

.main-sidebar-body .nav-link.with-sub {
  position: relative;
}

.main-sidebar-body .nav-sub {
  list-style: none;
  padding: 0;
  margin-inline-start: 36px;
}

.main-sidebar-body .nav-sub .nav-sub-link:before {
  content: "\e0b1";
  font-family: "typicons";
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  inset-block-start: 8px;
  font-size: 11px;
  margin-inline-end: 8px;
  color: #68798b;
}

.main-sidebar-body .nav-sub-item .nav-sub {
  padding-block-start: 0;
  padding-inline-end: 0;
  padding-block-end: 0;
  padding-inline-start: 12px;
  border-inline-start-width: 1px;
  margin-inline-start: 0;
}

.main-sidebar-body .nav-sub-item .nav-sub .nav-sub-link {
  height: auto;
  padding: 7px 0;
}

.main-sidebar-body .nav-sub-item.active > .nav-sub-link {
  color: var(--primary-bg-color);
}

.main-sidebar-body .nav-sub-item.show .nav-sub {
  display: block;
}

.main-sidebar-body .nav-sub-link {
  padding: 8px 0px;
  display: flex;
  align-items: center;
  color: var(--color);
  font-size: 14px;
}

.main-sidebar-body .nav-sub-link:hover,
.main-sidebar-body .nav-sub-link:focus {
  color: var(--primary-bg-color);
}

.main-sidebar-body .nav-sub-link.with-sub {
  justify-content: space-between;
}

.main-sidebar-body .nav-sub-link.with-sub::after {
  content: "\f3d0";
  font-family: "Ionicons";
  font-size: 12px;
  font-weight: 400;
  display: inline-block;
  position: relative;
  margin-inline-start: auto;
  color: var(--border);
}

.main-sidebar-body .nav-sub-link + .nav-sub-link {
  border-block-start: 1px dotted var(--border);
}

@media (min-width: 992px) {
  .main-sidebar-hide .main-sidebar {
    display: none;
  }

  .main-sidebar-hide .main-sidebar-sticky + .main-content {
    margin-inline-start: 0;
  }

  .main-sidebar {
    position: relative;
    display: flex;
    transform: none;
    z-index: 9999;
    border-inline-end-width: 1px;
    visibility: visible;
  }

  .main-sidebar-sticky + .main-content {
    margin-inline-start: 240px;
  }

  .main-sidebar-body .nav-sub-link.with-sub::after {
    margin-inline-start: 4px;
    font-size: 12px;
  }
}

@media (min-width: 1200px) {
  .main-sidebar-sticky + .main-content {
    margin-inline-start: 240px;
  }

  .main-sidebar-body {
    padding: 12px;
  }
}

@media (min-width: 992px) {
  .main-sidebar-show {
    overflow: inherit;
  }

  .main-sidebar-show .main-sidebar {
    display: flex;
    transform: translateX(0);
    visibility: visible;
    transition: left 0.3s ease, width 0.3s ease;
  }

  .main-sidebar-show .main-navbar-backdrop {
    visibility: visible;
    opacity: 1;
  }

  .main-sidebar {
    position: fixed;
    inset-block-start: 0;
    inset-inline-start: 0;
    inset-block-end: 0;
    z-index: 1000;
    background-color: rgb(var(--white));
    transition: none;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .main-sidebar {
    width: 220px;
  }
}

/*---  Sidebar  ---*/
/*---  Sidemenu  ---*/
@media (max-width: 991.98px) {
  .app-sidebar .main-sidemenu {
    margin-block-start: 25px;
  }
}

.app-sidebar {
  position: fixed;
  inset-block-start: 0px;
  inset-block-end: 0;
  inset-inline-start: 0;
  width: 240px;
  background: var(--barcolor);
  overflow: auto;
  box-shadow: 0 0 0 0 rgba(111, 134, 230, 0.15),
    0 4px 16px 0 rgba(179, 187, 195, 0.0035);
  border-inline-end: 1px solid var(--border);
  transition: all ease 0.3s;
  z-index: 1024;
  max-height: 100%;
}

.app-sidebar__header {
  height: 66px;
  padding: 10px 20px;
  width: 240px;
  background: var(--barcolor);
  border-block-end: 1px solid var(--border);
  position: fixed;
  border-inline-end: 1px solid #165f60;
  z-index: 999;
  inset-block-start: 0;
  inset-inline: 0;
  transition: all ease 0.3s;
}

.main-logo img {
  /* height: 3.6rem; */
  margin: -20px auto;
  max-width: 100%;
  width: 100%;
}

.mobile-logo,
.desktop-logo {
  display: none;
}

@media (min-width: 992px) {
  .desktop-logo-dark {
    display: block;
  }
}

@media (max-width: 992px) {
  .desktop-logo-dark {
    display: block;
  }
}

.app-sidebar__user {
  position: relative;
  padding: 18px 0px;
  text-align: center;
  background-repeat: no-repeat;
  border-block-end: 1px solid var(--border);
  color: #3c4858;
}

.app-sidebar__profile-img {
  transition: width ease 0.3s;
  width: 4rem;
}

.app-sidebar__user-name,
.app-sidebar__user-designation {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 80%;
  margin: 0 auto;
}

.app-sidebar__user-name {
  font-size: 17px;
  line-height: 1.3;
}

.side-menu {
  margin-block-end: 0;
  padding: 0;
  margin-right: 0;
  margin-inline-start: 0px;
}

.slide {
  position: relative;
}

.slide.is-expanded .angle {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
}

.angle {
  opacity: 0.6;
}

.side-menu__item {
  position: relative;
  padding: 12px 20px;
  font-size: 15px;
  display: flex;
  align-items: center;
  margin: 0;
  color: var(--color);
}

.side-menu__item.active {
  color: var(--primary-bg-color) !important;
}

.side-menu__item.active .side-menu__icon {
  border-color: rgba(255, 255, 255, 0.1);
}

.side-menu__item:hover,
.side-menu__item:focus {
  color: var(--primary-bg-color) !important;
}

.side-menu__item:hover .side_menu_img .side-menu__icon,
.side-menu__item:focus .side_menu_img .side-menu__icon {
  border-color: rgba(255, 255, 255, 0.1);
}

.side-menu__icon {
  position: relative;
  height: 30px;
  width: 30px;
  min-width: 30px;
  border-radius: 7px;
  border-color: var(--border);
  display: inline-block;
  margin-inline-end: 15px;
}

.side_menu_img {
  position: absolute;
  inset-inline-start: 55%;
  inset-block-start: 15%;
  font-size: 18px;
}

.side-menu__label {
  display: block;
  flex: 1 1 auto;
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
}

.slide-menu {
  margin-inline-start: 45px;
  display: none;
  height: auto;
}

.slide-menu li {
  position: relative;
}

.app-sidebar .sub-category:first-child {
  padding-top: 15px !important;
}

.app-sidebar .sub-category {
  color: #1c1d22;
  opacity: 0.7;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 5px;
  padding: 0 20px 0 25px;
}

.slide-item,
.sub-side-menu__item,
.sub-slide-item2 {
  font-size: 14px;
  color: var(--color);
  padding-block: 8px;
  padding-inline-start: 20px;
  padding-inline-end: 20px;
  display: block;
  position: relative;
}

.slide-item:hover,
.sub-side-menu__item:hover,
.sub-slide-item2:hover {
  color: var(--primary-bg-color);
}

.slide-right,
.slide-left {
  inset-block: 0;
  background-color: transparent;
}

.sub-angle,
.sub-angle2 {
  position: absolute;
  inset-block-start: 50%;
  transform: translateY(-50%);
}

.sub-angle {
  inset-inline-end: 27px;
}

.sub-angle2 {
  inset-inline-end: 25px;
}

.sub-slide-menu,
.sub-slide-menu1 {
  display: none;
  margin-inline-start: 20px;
}

.sub-slide.is-expanded .sub-slide-menu {
  display: block;
}

.sidebar-mini .slide-left,
.sidebar-mini .slide-right {
  display: none;
}

@media (min-width: 992px) {
  .sidebar-mini.sidenav-toggled .side-item.side-item-category {
    display: none;
  }

  .sidebar-mini.sidenav-toggled.show-sidenav .side-item.side-item-category {
    display: block;
  }

  .sidebar-mini.sidenav-toggled .side_menu_img {
    inset-inline-start: 15%;
  }

  .sidebar-mini.sidenav-toggled .main-content.side-content {
    margin-inline-start: 70px;
  }

  .sidebar-mini.sidenav-toggled .main-header.side-header {
    padding-inline-start: 65px;
  }

  .sidebar-mini.sidenav-toggled .app-sidebar {
    inset-inline-start: 0;
    width: 70px;
  }

  .sidebar-mini.sidenav-toggled .app-sidebar__header {
    width: 70px;
    padding: 10px;
  }

  .sidebar-mini.sidenav-toggled .desktop-logo {
    display: none;
  }

  .sidebar-mini.sidenav-toggled .mobile-logo {
    display: none;
  }

  .sidebar-mini.sidenav-toggled .mobile-logo.mobile-logo-dark {
    display: block;
  }

  .sidebar-mini.sidenav-toggled .side-menu__item {
    display: block;
    text-align: center;
  }

  .sidebar-mini.sidenav-toggled .app-sidebar__profile-img {
    width: 2.5rem;
  }

  .sidebar-mini.sidenav-toggled .app-header {
    padding-inline-start: 55px;
  }

  .sidebar-mini.sidenav-toggled .app-sidebar__user-name,
  .sidebar-mini.sidenav-toggled .app-sidebar__user-designation,
  .sidebar-mini.sidenav-toggled .side-menu__label,
  .sidebar-mini.sidenav-toggled .angle,
  .sidebar-mini.sidenav-toggled .slide.is-expanded .slide-menu {
    display: none !important;
  }

  .sidebar-mini.sidenav-toggled.show-sidenav .app-sidebar {
    width: 240px;
  }

  .sidebar-mini.sidenav-toggled.show-sidenav .mobile-logo {
    display: none;
  }

  .sidebar-mini.sidenav-toggled.show-sidenav .desktop-logo {
    display: none;
  }

  .sidebar-mini.sidenav-toggled.show-sidenav .desktop-logo.desktop-logo-dark {
    display: block;
  }

  .sidebar-mini.sidenav-toggled.show-sidenav .app-sidebar__profile-img {
    width: 4rem;
  }

  .sidebar-mini.sidenav-toggled.show-sidenav .app-sidebar__user-name,
  .sidebar-mini.sidenav-toggled.show-sidenav .app-sidebar__user-designation,
  .sidebar-mini.sidenav-toggled.show-sidenav .side-menu__label,
  .sidebar-mini.sidenav-toggled.show-sidenav .angle,
  .sidebar-mini.sidenav-toggled.show-sidenav .slide.is-expanded .slide-menu {
    display: block !important;
  }

  .sidebar-mini.sidenav-toggled.show-sidenav .side-menu__item {
    display: flex;
    align-items: center;
    text-align: start;
  }

  .sidebar-mini.sidenav-toggled.show-sidenav .side-menu__icon {
    margin-inline-end: 15px !important;
  }

  .sidebar-mini.sidenav-toggled.show-sidenav .app-sidebar__header {
    width: 240px;
    padding: 10px 20px;
  }

  .sidebar-mini .app-sidebar .main-sidemenu {
    margin-block-start: 80px;
  }

  .app.sidenav-toggled .side-menu .side-menu__icon {
    margin-inline-end: 0;
    margin-inline-start: 0;
  }
}

@media (max-width: 991px) {
  .app .app-sidebar {
    inset-inline-start: -250px;
  }

  .app.sidenav-toggled .app-sidebar {
    inset-inline-start: 0;
  }

  .app-sidebar {
    z-index: 9 !important;
    inset-block-start: 65px !important;
  }

  .app-sidebar__header {
    display: none;
  }

  .main-logo img {
    height: 5rem;
    margin-inline-start: 35px;
    margin: -15px auto;
  }

  .app .app-sidebar {
    inset-inline-start: -250px;
  }
}

.side-menu__label1 {
  display: none !important;
}

.sidetab-menu .menutabs-content,
.sidetab-menu .tab-menu-heading {
  /* display: nones; */
}

.sidetab-menu .panel-body {
  background: transparent;
}

@media (max-width: 991px) {
  .app .app-sidebar .main-sidemenu #side2,
  .app .app-sidebar .main-sidemenu #side3,
  .app .app-sidebar .main-sidemenu #side4,
  .app .app-sidebar .main-sidemenu #side6,
  .app .app-sidebar .main-sidemenu #side7,
  .app .app-sidebar .main-sidemenu #side9,
  .app .app-sidebar .main-sidemenu #side10,
  .app .app-sidebar .main-sidemenu #side12,
  .app .app-sidebar .main-sidemenu #side13,
  .app .app-sidebar .main-sidemenu #side15,
  .app .app-sidebar .main-sidemenu #side16,
  .app .app-sidebar .main-sidemenu #side18,
  .app .app-sidebar .main-sidemenu #side19,
  .app .app-sidebar .main-sidemenu #side21,
  .app .app-sidebar .main-sidemenu #side22,
  .app .app-sidebar .main-sidemenu #side24,
  .app .app-sidebar .main-sidemenu #side25,
  .app .app-sidebar .main-sidemenu #side27,
  .app .app-sidebar .main-sidemenu #side28,
  .app .app-sidebar .main-sidemenu #side30,
  .app .app-sidebar .main-sidemenu #side31,
  .app .app-sidebar .main-sidemenu #side32,
  .app .app-sidebar .main-sidemenu #side34,
  .app .app-sidebar .main-sidemenu #side35,
  .app .app-sidebar .main-sidemenu #side36,
  .app .app-sidebar .main-sidemenu #side38,
  .app .app-sidebar .main-sidemenu #side39,
  .app .app-sidebar .main-sidemenu #side40,
  .app .app-sidebar .main-sidemenu #side42,
  .app .app-sidebar .main-sidemenu #side43,
  .app .app-sidebar .main-sidemenu #side44,
  .app .app-sidebar .main-sidemenu #side46,
  .app .app-sidebar .main-sidemenu #side47,
  .app .app-sidebar .main-sidemenu #side48,
  .app .app-sidebar .main-sidemenu #side50,
  .app .app-sidebar .main-sidemenu #side51,
  .app .app-sidebar .main-sidemenu #side52,
  .app .app-sidebar .main-sidemenu #side54,
  .app .app-sidebar .main-sidemenu #side55,
  .app .app-sidebar .main-sidemenu #side56 {
    display: none !important;
  }

  .app .app-sidebar .main-sidemenu .tab-content > .tab-pane {
    display: block !important;
  }
  .dTFFld {
    font-size: 18px !important;
    font-weight: 600 !important;
  }
}

.app-sidebar .side-item.side-item-category:not(:first-child) {
  margin-top: 15px;
}

.app-sidebar .side-item.side-item-category {
  color: var(--color);
  opacity: 0.7;
  font-size: 14px;
  font-weight: 500;
  margin-block-end: 5px;
  padding: 0 35px 0 20px;
}

.slide-item.active {
  color: var(--primary-bg-color);
}

.sub-side-menu__item.active {
  color: var(--primary-bg-color);
}

/*---  Sidemenu  ---*/

/*---  Height  ---*/
.ht-5 {
  height: 5px;
}

.ht-10 {
  height: 10px;
}

.ht-15 {
  height: 15px;
}

.h-100 {
  height: 100%;
}

.ht-20 {
  height: 20px;
}

.ht-25 {
  height: 25px;
}

.ht-30 {
  height: 30px;
}

.ht-35 {
  height: 35px;
}

.ht-40 {
  height: 40px;
}

.ht-45 {
  height: 45px;
}

.ht-50 {
  height: 50px;
}

.ht-60 {
  height: 60px;
}

.ht-70 {
  height: 70px;
}

.ht-75 {
  height: 75px;
}

.ht-80 {
  height: 80px;
}

.ht-90 {
  height: 90px;
}

.ht-100 {
  height: 100px;
}

.ht-100p {
  height: 100%;
}

.ht-150 {
  height: 150px;
}

.ht-200 {
  height: 200px;
}

.ht-250 {
  height: 250px;
}

.ht-300 {
  height: 300px;
}

.ht-350 {
  height: 350px;
}

.ht-100v {
  height: 100vh;
}

.ht-1 {
  height: 1px;
}

.ht-2 {
  height: 2px;
}

.ht-3 {
  height: 3px;
}

.ht-4 {
  height: 4px;
}

.ht-6 {
  height: 6px;
}

.ht-7 {
  height: 7px;
}

.ht-8 {
  height: 8px;
}

.ht-4r {
  height: 4rem;
}

.ht-auto {
  height: auto;
}

.h-25 {
  height: 25% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

@media (min-width: 576px) {
  .ht-sm-30 {
    height: 30px;
  }

  .ht-sm-300 {
    height: 300px;
  }
}

@media (min-width: 992px) {
  .ht-lg-40 {
    height: 40px;
  }

  .ht-lg-400 {
    height: 400px;
  }
}

@media (min-width: 768px) {
  .ht-md-40 {
    height: 40px;
  }

  .ht-md-400 {
    height: 400px;
  }
}

/*---  Height  ---*/
/*---  Margin  ---*/
.mg-0 {
  margin: 0px;
}

.mg-t-0 {
  margin-block-start: 0px;
}

.mg-b-0 {
  margin-block-end: 0px;
}

.mg-1 {
  margin: 1px;
}

.mg-s-auto {
  margin-inline-start: auto;
}

.mg-s-10 {
  margin-inline-start: 10px;
}

.mg-s-20 {
  margin-inline-start: 20px;
}

.mg-s-30 {
  margin-inline-start: 30px;
}

.mg-s-40 {
  margin-inline-start: 40px;
}

.mg-t-1 {
  margin-block-start: 1px;
}

.mg-e-1 {
  margin-inline-end: 1px;
}

.mg-b-1 {
  margin-block-end: 1px;
}

.mg-s-1 {
  margin-inline-start: 1px;
}

.mg-2 {
  margin: 2px;
}

.mg-x-2 {
  margin-inline-start: 2px;
  margin-inline-end: 2px;
}

.mg-t-2 {
  margin-block-start: 2px;
}

.mg-b-2 {
  margin-block-end: 2px;
}

.mg-s-2 {
  margin-inline-start: 2px;
}

.mg-3 {
  margin: 3px;
}

.mg-t-3 {
  margin-block-start: 3px;
}

.mg-b-3 {
  margin-block-end: 3px;
}

.mg-s-3 {
  margin-inline-start: 3px;
}

.mg-4 {
  margin: 4px;
}

.mg-t-4 {
  margin-block-start: 4px;
}

.mg-b-4 {
  margin-block-end: 4px;
}

.mg-s-4 {
  margin-inline-start: 4px;
}

.mg-5 {
  margin: 5px;
}

.mg-t-5 {
  margin-block-start: 5px;
}

.mg-e-5 {
  margin-inline-end: 5px;
}

.mg-b-5 {
  margin-block-end: 5px;
}

.mg-s-5 {
  margin-inline-start: 5px;
}

.mg-6 {
  margin: 6px;
}

.mg-e-6 {
  margin-inline-end: 6px;
}

.mg-b-6 {
  margin-block-end: 6px;
}

.mg-7 {
  margin: 7px;
}

.mg-t-10 {
  margin-block-start: 10px;
}

.mg-b-10 {
  margin-block-end: 10px;
}

.mg-s-10 {
  margin-inline-start: 10px;
}

.mg-t-15 {
  margin-block-start: 15px;
}

.mg-e-15 {
  margin-inline-end: 15px;
}

.mg-b-15 {
  margin-block-end: 15px;
}

.mg-b-15-f {
  margin-block-end: 15px !important;
}

.mg-x-20 {
  margin-inline-start: 20px;
  margin-inline-end: 20px;
}

.mg-x-20-f {
  margin-inline-start: 20px !important;
  margin-inline-end: 20px !important;
}

.mg-t-20 {
  margin-block-start: 20px;
}

.mg-b-20 {
  margin-block-end: 20px;
}

.mg-s-20 {
  margin-inline-start: 20px;
}

.mg-t-25 {
  margin-block-start: 25px;
}

.mg-b-25 {
  margin-block-end: 25px;
}

.mg-t-30 {
  margin-block-start: 30px;
}

.mg-t-40 {
  margin-block-start: 40px;
}

.mg-b-40 {
  margin-block-end: 40px;
}

.mg-s-40 {
  margin-inline-start: 40px;
}

.mg-e-60 {
  margin-inline-end: 60px;
}

.mg-t-auto {
  margin-block-start: auto;
}

.mg-e-auto {
  margin-inline-end: auto;
}

.mg-b-auto {
  margin-block-end: auto;
}

.mg-s-auto {
  margin-inline-start: auto;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-block-start: 0 !important;
}

.me-0 {
  margin-inline-end: 0 !important;
}

.mb-0,
.my-0 {
  margin-block-end: 0 !important;
}

.ms-0 {
  margin-inline-start: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-block-start: 0.25rem !important;
}

.me-1 {
  margin-inline-end: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-block-end: 0.25rem !important;
}

.ms-1 {
  margin-inline-start: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-block-start: 0.5rem !important;
}

.me-2,
.mx-2 {
  margin-inline-end: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-block-end: 0.5rem !important;
}

.ms-2,
.mx-2 {
  margin-inline-start: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-block-start: 0.75rem !important;
}

.me-3 {
  margin-inline-end: 1rem !important;
}

.mb-3,
.my-3 {
  margin-block-end: 0.75rem !important;
}

.ms-3 {
  margin-inline-start: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-block-start: 1.5rem !important;
}

.me-4,
.mx-4 {
  margin-inline-end: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-block-end: 1.5rem !important;
}

.ms-4,
.mx-4 {
  margin-inline-start: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5 {
  margin-block-start: 3rem !important;
}

.me-5 {
  margin-inline-end: 3rem !important;
}

.mb-5 {
  margin-block-end: 3rem !important;
}

.ms-5 {
  margin-inline-start: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-block-start: auto !important;
}

.me-auto,
.mx-auto {
  margin-inline-end: auto !important;
}

.mb-auto,
.my-auto {
  margin-block-end: auto !important;
}

.ms-auto,
.mx-auto {
  margin-inline-start: auto !important;
}

@media (min-width: 576px) {
  .mt-sm-0,
  .my-sm-0 {
    margin-block-start: 0 !important;
  }

  .mb-sm-0,
  .my-sm-0 {
    margin-block-end: 0 !important;
  }

  .mg-sm-t-0 {
    margin-block-start: 0px;
  }

  .mg-sm-b-0 {
    margin-block-end: 0px;
  }

  .mg-sm-l-1 {
    margin-inline-start: 1px;
  }

  .mg-sm-t-2 {
    margin-block-start: 2px;
  }

  .mg-sm-r-2 {
    margin-inline-end: 2px;
  }

  .mg-sm-b-2 {
    margin-block-end: 2px;
  }

  .mg-sm-l-2 {
    margin-inline-start: 2px;
  }

  .mg-sm-l-3 {
    margin-inline-start: 3px;
  }

  .mg-sm-l-10 {
    margin-inline-start: 10px;
  }

  .mg-sm-r-20 {
    margin-inline-end: 20px;
  }

  .mg-sm-b-20 {
    margin-block-end: 20px;
  }

  .mg-sm-l-20 {
    margin-inline-start: 20px;
  }

  .mg-sm-t-25 {
    margin-block-start: 25px;
  }

  .mg-sm-l-30 {
    margin-inline-start: 30px;
  }
}

@media (min-width: 992px) {
  .mt-lg-0 {
    margin-block-start: 0 !important;
  }

  .mb-lg-0 {
    margin-block-end: 0 !important;
  }

  .mb-lg-2 {
    margin-block-end: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-inline-start: 1rem !important;
  }

  .mb-lg-4 {
    margin-block-end: 1.5rem !important;
  }
}

@media (min-width: 1200px) {
  .mt-xl-0 {
    margin-block-start: 0 !important;
  }

  .ms-xl-1 {
    margin-inline-start: 0.25rem !important;
  }

  .mg-xl-t-0 {
    margin-block-start: 0px;
  }
}

@media (min-width: 768px) {
  .actionIcon {
    gap: 15px;
    cursor: pointer;
    flex-wrap: wrap;
    margin: 10px 0px;
    justify-content: center;
  }
  .mb-md-0 {
    margin-block-end: 0 !important;
  }

  .ms-md-auto {
    margin-inline-start: auto !important;
  }

  .mg-md-t-0 {
    margin-block-start: 0px;
  }
}

.me-1 {
  margin-inline-end: 0.25rem !important;
}

@media (min-width: 992px) {
  .mg-lg-t-0 {
    margin-block-start: 0px;
  }
}

/*---  Margin  ---*/
/*---  Opacity  ---*/
.op-0 {
  opacity: 0;
}

.op-1 {
  opacity: 0.1;
}

.op-2 {
  opacity: 0.2;
}

.op-3 {
  opacity: 0.3;
}

.op-4 {
  opacity: 0.4;
}

.op-5 {
  opacity: 0.5;
}

.op-6 {
  opacity: 0.6;
}

.op-7 {
  opacity: 0.7;
}

.op-8 {
  opacity: 0.8;
}

.op-9 {
  opacity: 0.9;
}

@media (min-width: 480px) {
  .op-xs-0 {
    opacity: 0;
  }

  .op-xs-0-f {
    opacity: 0 !important;
  }

  .op-xs-1 {
    opacity: 0.1;
  }

  .op-xs-1-f {
    opacity: 0.1 !important;
  }

  .op-xs-2 {
    opacity: 0.2;
  }

  .op-xs-2-f {
    opacity: 0.2 !important;
  }

  .op-xs-3 {
    opacity: 0.3;
  }

  .op-xs-3-f {
    opacity: 0.3 !important;
  }

  .op-xs-4 {
    opacity: 0.4;
  }

  .op-xs-4-f {
    opacity: 0.4 !important;
  }

  .op-xs-5 {
    opacity: 0.5;
  }

  .op-xs-5-f {
    opacity: 0.5 !important;
  }

  .op-xs-6 {
    opacity: 0.6;
  }

  .op-xs-6-f {
    opacity: 0.6 !important;
  }

  .op-xs-7 {
    opacity: 0.7;
  }

  .op-xs-7-f {
    opacity: 0.7 !important;
  }

  .op-xs-8 {
    opacity: 0.8;
  }

  .op-xs-8-f {
    opacity: 0.8 !important;
  }

  .op-xs-9 {
    opacity: 0.9;
  }

  .op-xs-9-f {
    opacity: 0.9 !important;
  }
}

/*---  Opacity  ---*/
/*---  Padding  ---*/
.pd-0 {
  padding: 0px;
}

.pd-t-0 {
  padding-block-start: 0px;
}

.pd-e-0 {
  padding-inline-end: 0px;
}

.pd-t-0-f {
  padding-block-start: 0px !important;
}

.pd-1 {
  padding: 1px;
}

.pd-2 {
  padding: 2px;
}

.pd-y-2 {
  padding-block-start: 2px;
  padding-block-end: 2px;
}

.pd-x-2 {
  padding-inline-start: 2px;
  padding-inline-end: 2px;
}

.pd-3 {
  padding: 3px;
}

.pd-y-3 {
  padding-block-start: 3px;
  padding-block-end: 3px;
}

.pd-x-3 {
  padding-inline-start: 3px;
  padding-inline-end: 3px;
}

.pd-s-3 {
  padding-inline-start: 3px;
}

.pd-4 {
  padding: 4px;
}

.pd-5 {
  padding: 5px;
}

.pd-s-5 {
  padding-inline-start: 5px;
}

.pd-e-8 {
  padding-inline-end: 8px;
}

.pd-10 {
  padding: 10px;
}

.pd-20 {
  padding: 20px;
}

.pd-y-20 {
  padding-block-start: 20px;
  padding-block-end: 20px;
}

.pd-x-20 {
  padding-inline-start: 20px;
  padding-inline-end: 20px;
}

.pd-25 {
  padding: 25px;
}

.pd-x-25 {
  padding-inline-start: 25px;
  padding-inline-end: 25px;
}

.pd-30 {
  padding: 30px;
}

.pd-y-30 {
  padding-block-start: 30px;
  padding-block-end: 30px;
}

.pd-x-30 {
  padding-inline-start: 30px;
  padding-inline-end: 30px;
}

.pd-s-30 {
  padding-inline-start: 30px;
}

.pd-45 {
  padding: 45px;
}

.pd-s-50 {
  padding-inline-start: 50px;
}

.pd-e-80 {
  padding-inline-end: 80px;
}

.pe-0,
.px-0 {
  padding-inline-end: 0 !important;
}

.pb-0 {
  padding-block-end: 0 !important;
}

.ps-0,
.px-0 {
  padding-inline-start: 0 !important;
}

.pt-0 {
  padding-block-start: 0;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-block-start: 0.25rem !important;
}

.px-1 {
  padding-inline-end: 0.25rem !important;
}

.py-1 {
  padding-block-end: 0.25rem !important;
}

.px-1 {
  padding-inline-start: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-block-start: 0.5rem !important;
}

.px-2 {
  padding-inline-end: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-block-end: 0.5rem !important;
}

.px-2 {
  padding-inline-start: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-block-start: 0.75rem !important;
}

.pe-3,
.px-3 {
  padding-inline-end: 1rem !important;
}

.pb-3,
.py-3 {
  padding-block-end: 0.75rem !important;
}

.ps-3,
.px-3 {
  padding-inline-start: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-block-start: 1.5rem !important;
}

.pe-4,
.px-4 {
  padding-inline-end: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-block-end: 1.5rem !important;
}

.ps-4,
.px-4 {
  padding-inline-start: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.py-5 {
  padding-block-start: 1rem !important;
}

.pe-5 {
  padding-inline-end: 1.5rem !important;
}

.pb-5,
.py-5 {
  padding-block-end: 1rem !important;
}

.ps-5 {
  padding-inline-start: 1.5rem !important;
}

@media (min-width: 576px) {
  .pe-sm-2 {
    padding-inline-end: 0.5rem !important;
  }

  .ps-sm-2 {
    padding-inline-start: 0.5rem !important;
  }

  .pd-sm-40 {
    padding: 40px;
  }
}

@media (min-width: 768px) {
  .p-md-0 {
    padding: 0 !important;
  }

  .pe-md-0 {
    padding-inline-end: 0 !important;
  }

  .pe-md-2 {
    padding-inline-end: 0.5rem !important;
  }

  .ps-md-2 {
    padding-inline-start: 0.5rem !important;
  }
}

/*---  Padding  ---*/

/* side bar hide show==========================> */

.main-content.side-content {
  /* background: var(--mid-background); */
  background: #fff;
}

.sidebar {
  position: fixed;
  top: 0;
  left: -250px;
  height: 100%;
  transition: left 0.5s ease-in-out;
}

.open {
  left: 0;
}

/* ================================================= */
/* onclick dark light toggle ======================================> */
.switch input {
  display: none;
}

.switch {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 45px;
  height: 20px;
  background: #4d4d4d;
  border-radius: 100px;
  padding: 0 2px;
  transition: 0.3s;
  cursor: pointer;
  position: relative;
}

.slider {
  display: flex;
  align-items: center;
  width: 16px;
  height: 16px;
  background: white;
  border-radius: 100px;
  transition: 0.3s;
  border: 1px solid transparent;
  z-index: 1000;
}

.icon {
  display: block;
  position: absolute;
  transition: 0.3s;
}

.sun {
  right: 2px;
  opacity: 1;
}

.moon {
  left: 2px;
  opacity: 0;
}

.switch:hover .slider,
.switch:focus .slider {
  border: 1px solid #4d4d4d;
  /* box-shadow: 0 0 2px 3px #86d46b; */
}

.switch:active .slider {
  /* box-shadow: 0 0 5px 5px #86d46b; */
}

.switch input:checked + .slider {
  transform: translateX(26px);
}

.switch input:checked ~ .moon {
  opacity: 1;
}

.switch input:checked ~ .sun {
  opacity: 0;
}

/* ======================================================================> */
/*  */
.form-control:focus {
  box-shadow: none;
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  font-size: 18px;
  padding: 5px 0px;
}

.form-control:focus {
  box-shadow: none;
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  font-size: 24px;
}

.main-container-org {
  display: flex;
  align-items: center;
  height: 100vh;
  justify-content: center;
}

.bx-shadow {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.07);
}

/* .organization-div{
  height: 100vh;
  justify-content: center;
  align-items: center;
} */
.choose-color {
  align-items: center;
  padding-top: 20px;
}

.color-input {
  width: 20px;
  height: 20px;
}

.color-show {
  width: 30%;
  text-align: center;
  border-radius: 2px;
}

.mtb-20 {
  margin: 20px 0px;
}

.main-view {
  display: flex;
  align-items: center;
  height: 100vh;
}

.equal-form-space {
  margin-bottom: 15px;
}

.main-footer.text-center {
  position: fixed;
  width: 100%;
  bottom: 0;
}

.dashboardcard {
  border-radius: 11px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #e8e8f7;
  margin-top: 25px;
  border: 0;
  /* box-shadow: -7.829px 11.607px 20px 0 #e4e3f5; */
  background-color: var(--primary-bg-color) !important;
}

.dashboardcardbody {
  flex: 1 1 auto;
  padding: 15px 0px;
}

.img-bg {
  background: url(https://nextjs.spruko.com/spruha/preview/_next/static/media/Stars.d277efc1.png);
  background-position: 100%;
  background-size: auto;
  background-repeat: no-repeat;
}

.dashboardcardbody img {
  position: absolute;
  bottom: 0px;
  left: -15px;
  width: inherit;
}

.main-content {
  /* padding-top: 75px!important; */
}

@media (min-width: 992px) {
  .main-content {
    margin-left: 240px;
    /* padding-top: 150px!important; */
  }
}

.dashboardquote {
  font-family: "Dancing Script", cursive;
  color: #f0f0f5;
  font-size: 15px;
  /* padding-right: 80px; */
}

.highlight_dashboard {
  background-image: linear-gradient(
    140deg,
    hsl(181deg 63% 23%) 0%,
    hsl(184deg 89% 25%) 10%,
    hsl(187deg 100% 29%) 21%,
    hsl(190deg 100% 35%) 31%,
    hsl(192deg 100% 42%) 41%,
    hsl(195deg 92% 51%) 51%,
    hsl(192deg 100% 43%) 61%,
    hsl(190deg 100% 38%) 71%,
    hsl(187deg 100% 33%) 80%,
    hsl(184deg 100% 28%) 90%,
    hsl(181deg 85% 26%) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.mt-90 {
  margin-top: 90px;
}

.mainCardContainer {
  margin: 0px 10px;
  padding: 40px 40px;
  padding-bottom: 80px;
  background-color: #fff;
}

.employeeTitle {
  /* padding: 18px 0px; */
  font-weight: 600;
  color: var(--color);
  background-image: linear-gradient(
    140deg,
    hsl(181deg 63% 23%) 0%,
    hsl(184deg 89% 25%) 10%,
    hsl(187deg 100% 29%) 21%,
    hsl(190deg 100% 35%) 31%,
    hsl(192deg 100% 42%) 41%,
    hsl(195deg 92% 51%) 51%,
    hsl(192deg 100% 43%) 61%,
    hsl(190deg 100% 38%) 71%,
    hsl(187deg 100% 33%) 80%,
    hsl(184deg 100% 28%) 90%,
    hsl(181deg 85% 26%) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.actionIcon {
  gap: 15px;
  cursor: pointer;
}

.actionIcon i {
  font-size: 15px;
}

.hcgwbo {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 18px;
  color: #000;
}

.sc-hLseeU > div {
  font-size: 16px;
  color: #000;
}

.border-radius5 {
  border-radius: 5px;
}

.ptb-20 {
  padding: 20px 0px;
}

.cssbuttons-io-button {
  border: 1px solid #dddd;
  color: #2d2d2d;
  font-family: inherit;
  padding: 0.35em;
  padding-left: 1em;
  font-size: 17px;
  font-weight: 500;
  border-radius: 5px;
  /* border: none; */
  letter-spacing: 0.05em;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  height: 37px;
  padding-right: 3em;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;*/
}

.cssbuttons-io-button .icon {
  background: #165f60;
  margin-left: 1em;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.7em;
  width: 2.2em;
  border-radius: 5px;
  right: 0.3em;
  transition: all 0.3s;
}

.cssbuttons-io-button:hover .icon {
  width: calc(100% - 0.6em);
}

.cssbuttons-io-button .icon svg {
  width: 1.1em;
  transition: transform 0.3s;
  color: #df262b;
}

.cssbuttons-io-button:hover .icon svg {
  transform: translateX(0.1em);
}

.cssbuttons-io-button:active .icon {
  transform: scale(0.95);
}

.plr-20 {
  padding: 0px 20px;
}

.button-scan {
  backface-visibility: hidden;
  position: relative;
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
  background: none;
  border-width: 0px 0px 0px 0px;
  padding: 7px 15px 7px 40px;
  color: #2d2d2d;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  font-style: normal;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.button-scan > div {
  color: #999;
  font-size: 10px;
  font-family: Helvetica Neue;
  font-weight: initial;
  font-style: normal;
  text-align: center;
  margin: 0px 0px 0px 0px;
}

.button-scan > i {
  color: #616060;
  font-size: 1em;
  background: #fff;
  border-radius: 500px;
  border: 0px solid transparent;
  border-width: 0px 0px 0px 0px;
  padding: 7px 7px 7px 7px;
  margin: 5px 5px 5px 5px;
  position: absolute;
  top: 0px;
  left: 0px;
}

.button-scan > .ld {
  font-size: initial;
}

.reactDatatable {
  margin: 15px 0px;
}
.reactDatatable ::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
  background-color: #d1d1d1 !important;
  border-radius: 1rem !important;
}
.reactDatatable ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #5a5a5a;
  cursor: pointer !important;
}
/* modal pop up css===========================================================> */

.cards {
  display: flex;
  /* flex-wrap: wrap; */
  /* align-items: flex-start; */
  flex-wrap: wrap;
  justify-content: center;
  /* gap: 2.5rem; */
  /* width: 90%; */
  /* max-width: 1000px; */
}

.card-pop {
  border-radius: 16px;
  box-shadow: 0 30px 30px -25px rgba(65, 51, 183, 0.25);
  max-width: 300px;
}

.card-pop-branch {
  border-radius: 16px;
  box-shadow: 0 30px 30px -25px rgba(65, 51, 183, 0.25);
  max-width: 400px;
}

.change-pwd-pop {
  border-radius: 16px;
  box-shadow: 0 30px 30px -25px rgba(65, 51, 183, 0.25);
  min-width: 600px;
}
.information {
  background-color: var(--white);
  padding: 1.5rem;
}

.pwd-err {
  color: red;
  font-weight: 400;
}
.information .tag {
  display: inline-block;
  background-color: var(--c-titan-white);
  color: var(--c-indigo);
  font-weight: 600;
  font-size: 0.875rem;
  padding: 0.5em 0.75em;
  line-height: 1;
  border-radius: 6px;
}

.information .tag + * {
  margin-top: 1rem;
}

.information .title {
  font-size: 1.4rem;
  color: var(--black);
  line-height: 1.25;
  text-align: center;
}

.information .title + * {
  margin-top: 1rem;
}

.information .info {
  color: var(--black);
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  margin-top: 20px;
}

.information .info + * {
  margin-top: 1.25rem;
}

.information .button {
  font: inherit;
  line-height: 1;
  background-color: var(--c-white);
  border: 2px solid var(--c-indigo);
  color: var(--c-indigo);
  padding: 0.5em 1em;
  border-radius: 6px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
}

.information .button:hover,
.information .button:focus {
  background-color: var(--c-indigo);
  color: var(--c-white);
}

.information .details {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.information .details div {
  /* padding: 0.75em 1em; */
  background-color: var(--c-titan-white);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  /* flex-direction: column-reverse; */
  /* gap: 0.125em; */
  /* flex-basis: 50%;*/
}

.information .details dt {
  font-size: 0.875rem;
  color: var(--c-cold-purple);
}

.information .details dd {
  color: var(--c-indigo);
  font-weight: 600;
  font-size: 1.25rem;
}

.plan {
  padding: 10px;
  background-color: var(--c-white);
  color: var(--c-del-rio);
}

.plan strong {
  font-weight: 600;
  color: var(--c-coffee);
}

.plan .inner {
  padding: 20px;
  padding-top: 40px;
  background-color: var(--c-fair-pink);
  border-radius: 12px;
  position: relative;
  overflow: hidden;
}

.plan .pricing {
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--c-apricot);
  border-radius: 99em 0 0 99em;
  display: flex;
  align-items: center;
  padding: 0.625em 0.75em;
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--c-coffee);
}

.plan .pricing small {
  color: var(--c-del-rio);
  font-size: 0.75em;
  margin-left: 0.25em;
}

.plan .title {
  font-weight: 600;
  font-size: 1.25rem;
  color: var(--c-coffee);
}

.plan .title + * {
  margin-top: 0.75rem;
}

.plan .info + * {
  margin-top: 1rem;
}

.plan .features {
  display: flex;
  flex-direction: column;
}

.plan .features li {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.plan .features li + * {
  margin-top: 0.75rem;
}

.plan .features .icon {
  background-color: var(--c-java);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--c-white);
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.plan .features .icon svg {
  width: 14px;
  height: 14px;
}

.plan .features + * {
  margin-top: 1.25rem;
}

.plan button {
  font: inherit;
  background-color: var(--c-indigo);
  border-radius: 6px;
  color: var(--c-white);
  font-weight: 500;
  font-size: 1.125rem;
  width: 100%;
  border: 0;
  padding: 1em;
}

.plan button:hover,
.plan button:focus {
  background-color: var(--c-governor);
}

/* popup css ends =======================================================> */
.bg-none {
  background: none !important;
}

.modal-suc .modal-content {
  background: none !important;
  box-shadow: none !important;
}

.dayImg {
  position: inherit !important;
  width: 20% !important;
}

.search-box {
  width: 300px;
  position: absolute;
  display: flex;
  top: 4%;
  right: 0%;
}

.search-input {
  width: 100%;
  font-size: 16px;
  padding: 7px 45px 7px 15px;
  color: #6c6c6c;
  border-radius: 6px;
  border: none;
  transition: all 0.4s;
  border: 1px solid #ddd;
}

.search-input:focus {
  /* border:none; */
  outline: none;
  /* box-shadow: 0 1px 12px #b8c6db;
  -moz-box-shadow: 0 1px 12px #b8c6db;
  -webkit-box-shadow: 0 1px 12px #b8c6db; */
}

.search-btn {
  background-color: transparent;
  font-size: 18px;
  padding: 6px 9px;
  margin-left: -45px;
  border: none;
  color: #6c6c6c;
  transition: all 0.4s;
  z-index: 10;
}

.search-btn:hover {
  transform: scale(1.2);
  cursor: pointer;
  color: black;
}

.search-btn:focus {
  outline: none;
  color: black;
}

.ewhdCu {
  padding: 25px;
}

.huwXap {
  border-radius: 10px 10px 0px 0px;
  box-shadow: 0 30px 30px -25px rgba(65, 51, 183, 0.25);
}

/* 0 30px 30px -25px rgba(65, 51, 183, 0.25) */
.cOhUY {
  border-radius: 0px 0px 10px 10px;
  box-shadow: 0 30px 30px -25px rgba(65, 51, 183, 0.25);
}

.cardDashboardProfile {
  box-shadow: 0 3px 3px rgba(56, 65, 74, 0.1);
  margin-top: 1rem;
}

.b-radius {
  border-radius: 5px;
}

.avatar-sm {
  height: 3rem;
  width: 3rem;
  margin: 10px;
}

.bg-soft-warning {
  background-color: #165f60 !important;
}

.profileSectionDashboard {
  padding: 0px 10px;
  padding-bottom: 10px;
}

.ProfileDesig {
  color: #2d2d2d;
  font-weight: 500;
  font-size: 14px;
}

.avatar-title {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #405189;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 500;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
}

.fs-3 {
  font-size: 1.421875rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.profileNameD {
  font-weight: 600;
  /* color: #df262b; */
  font-size: 18px;
  background-image: linear-gradient(
    140deg,
    hsl(181deg 63% 23%) 0%,
    hsl(184deg 89% 25%) 10%,
    hsl(187deg 100% 29%) 21%,
    hsl(190deg 100% 35%) 31%,
    hsl(192deg 100% 42%) 41%,
    hsl(195deg 92% 51%) 51%,
    hsl(192deg 100% 43%) 61%,
    hsl(190deg 100% 38%) 71%,
    hsl(187deg 100% 33%) 80%,
    hsl(184deg 100% 28%) 90%,
    hsl(181deg 85% 26%) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.iconDash {
  font-size: 14px;
  color: #2d2d2d;
}

.cardViewProfile {
  --color: #165f60;
  font-family: inherit;
  display: inline-block;
  width: 7.5em;
  height: 2.3em;
  line-height: 1.7em;
  overflow: hidden;
  /* margin: 20px; */
  font-size: 13px;
  z-index: 1;
  color: #2d2d2d;
  border: 2px solid #ddd;
  border-radius: 3px;
  position: relative;
  font-weight: 600;
}

.cardViewProfile::before {
  position: absolute;
  content: "";
  background: var(--color);
  width: 150px;
  height: 200px;
  z-index: -1;
  border-radius: 50%;
}

.cardViewProfile:hover {
  color: white;
}

.cardViewProfile:before {
  top: 100%;
  left: 100%;
  transition: 0.3s all;
}

.cardViewProfile:hover::before {
  top: -30px;
  left: -30px;
}

.brd-none {
  border: none;
}

.card-animate {
  margin-top: 15px;
  box-shadow: 0 3px 3px rgba(56, 65, 74, 0.1);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.card-animate:hover {
  -webkit-transform: translateY(calc(-1.5rem / 5));
  transform: translateY(calc(-1.5rem / 5));
  -webkit-box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12);
  box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12);
}

.bg-iconSub {
  background: rgba(64, 81, 137, 0.18) !important;
}

.iconmainSub {
  color: #405189;
}

.counterDvalue {
  color: #495057;
  font-size: 28px;
  font-weight: 700;
}

.text-cardMain {
  font-size: 18px;
  font-weight: 500;
}

.smallArrow {
  font-size: 13px;
}

.badge {
  background-color: #ebecec;
}

.btn-badge {
  border: none;
  background: #dde0ea;
  padding: 0px 8px;
  font-size: 13px;
  color: #405189;
  font-weight: 500;
}

.asideCards1 {
  padding-left: 0px !important;
}

.asideCards2 {
  padding-right: 0px !important;
}

.btnEmply {
  border: 2px solid green;
  padding: 2px 7px;
  border-radius: 3px;
  color: #666666;
  font-size: 15px;
}

.brdRed {
  border: 2px solid #df262b;
}

/* test=====================================> */
.cardBranch {
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 5px;
  text-align: center;
  background-color: #fff;
  border-radius:10px; */
  cursor: pointer;
}

.cardBranch .profile {
  border-radius: 10px;
  transition: 0.2s;
}

.cardBranch:hover .profile {
  /* transform:scale(1.4); */
  border-top-right-radius: 50px;
  border-top-left-radius: 50px;
}

.cardBranch .content {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.cardBranch .content .back {
  position: absolute;
  width: 130px;
  height: 130px;
  background-color: #272727b3;
  transition: 0.5s;
  z-index: 1;
  padding: 10px;
  border-radius: 16px;
}

.from-left {
  top: 20%;
  left: -100%;
}

.cardBranch:hover .content .from-left {
  left: 18%;
  top: 18%;
}

.branch-modal-head {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.branch-modal-head h4 {
  margin: 0;
  font-size: 1.5rem;
}

.modal-title-branch {
  font-size: 1.5rem;
}

.bx-icon-branch-close {
  font-size: 1.5rem;
  cursor: pointer;
}

.btn-delete {
  border-radius: 6px;
  font-family: Arial;
  color: #ffffff;
  background: #d93434;
  padding: 6px 20px;
  text-decoration: none;
  border: none;
}

.btn-cancel {
  border-radius: 6px;
  font-family: Arial;
  color: #000000;
  background: transparent;
  padding: 6px 20px;
  text-decoration: none;
  border: none;
}

.modal-btns {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
}

.no-data {
  display: flex;
  justify-content: center;
}

.no-data img {
  height: auto;
  width: 100%;
}

.w-5percent {
  width: 4%;
}

.eploye_mont {
  background-image: linear-gradient(
    140deg,
    hsl(181deg 63% 23%) 0%,
    hsl(184deg 89% 25%) 10%,
    hsl(187deg 100% 29%) 21%,
    hsl(190deg 100% 35%) 31%,
    hsl(192deg 100% 42%) 41%,
    hsl(195deg 92% 51%) 51%,
    hsl(192deg 100% 43%) 61%,
    hsl(190deg 100% 38%) 71%,
    hsl(187deg 100% 33%) 80%,
    hsl(184deg 100% 28%) 90%,
    hsl(181deg 85% 26%) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 24px;
  font-weight: 600;
  margin-left: 0.5rem;
}
/* 
.top-17m {
  top: -17%;
} */

.react-datepicker__view-calendar-icon input {
  padding: 6px 10px 5px 15px;
  border-radius: 5px;
  width: 50%;
}

.date-label {
  font-size: 14px;
  color: #2d2d2d;
  font-weight: 500;
}

.calender-icon {
  position: absolute;
  left: 1%;
  top: 60%;
  font-size: 18px;
}

.branchFilter {
  width: 100%;
  padding: 5px 20px;
  border-radius: 4px;
  height: 35px;
}

.dropdown-menu {
  display: block !important;
}

.main-notification-title {
  font-size: 1.4rem;
}

.logOut {
  font-size: 25px;
  font-weight: 500;
}

.width100 {
  width: 100%;
}

.react-datepicker-popper {
  z-index: +9999 !important;
}

.rmdp-input {
  padding: 0px 10px !important;
  height: 35px !important;
}

.react-datepicker__input-container input {
  height: 35px !important;
  border-radius: 5px !important;
  padding: 0px 10px !important;
}

.profileMain {
  height: 55vh;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  margin: 0px 15px;
  position: relative;
}

.profileSub {
  height: 25vh;
  background-image: linear-gradient(
    140deg,
    hsl(181deg 63% 23%) 0%,
    hsl(184deg 89% 25%) 10%,
    hsl(187deg 100% 29%) 21%,
    hsl(190deg 100% 35%) 31%,
    hsl(192deg 100% 42%) 41%,
    hsl(195deg 92% 51%) 51%,
    hsl(192deg 100% 43%) 61%,
    hsl(190deg 100% 38%) 71%,
    hsl(187deg 100% 33%) 80%,
    hsl(184deg 100% 28%) 90%,
    hsl(181deg 85% 26%) 100%
  );
}

.ProfileImage {
  position: absolute;
  top: 30%;
  left: 4%;
}
.ProfileImage img {
  width: 18%;
  background: #f0f0f5;
  border-radius: 100px;
}

.CountryCode {
  display: flex;
  margin-top: 10px;
  align-items: center;
  gap: 15px;
}
.ProfileTitle h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: #2d2d2d;
}
.CountryCode p {
  font-size: 16px;
  font-weight: 600;
  color: #2d2d2d;
}
.CountryCode img {
  width: 3%;
  border-radius: 0px;
}
.ProfileDetail {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 10px;
  flex-wrap: wrap;
}
.ProfileDetail p {
  font-size: 16px;
  font-weight: 600;
  color: #2d2d2d;
}

.DesignationProfile {
  display: flex;
  gap: 15px;
  align-items: center;
}
.DesignationProfile img {
  width: 3%;
  border-radius: 0px;
}
.DesignationProfile p {
  font-size: 16px;
  font-weight: 600;
  color: #2d2d2d;
}

.progress-ring__circle {
  transition: 0.35s stroke-dashoffset;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.scanImg {
  width: 90%;
  position: absolute;
  left: 2%;
  top: 5%;
}
.w-30p {
  width: 30%;
}
.mainScanTrigger {
  display: flex;
  justify-content: center;
}
.TextMsgOnscan {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #2d2d2d;
  margin-top: 10px;
}
.mainHomeModal {
  background: #fff;
  border-radius: 5px;
  padding: 15px;
}
.wDemo {
  width: 100%;
  height: 500px;
}

.head-extra {
  /* justify-content: center; */
  /* gap: 2rem; */
}

.head-d-gap {
  gap: 2rem;
}

.d-grid {
  display: grid;
}

nav.primary-navigation {
  margin: 0 auto;
  display: block;
  /* padding: 120px 0 0 0; */
  text-align: center;
  font-size: 16px;
}
nav.primary-navigation ul li {
  list-style: none;
  margin: 0 auto;
  /* border-left: 2px solid #3ca0e7; */
  display: inline-block;
  padding: 0 30px;
  position: relative;
  text-decoration: none;
  text-align: center;
}
nav.primary-navigation li a {
  color: black;
}
nav.primary-navigation li a:hover {
  color: #e76d3c;
}
nav.primary-navigation li:hover {
  cursor: pointer;
}
nav.primary-navigation ul li ul {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  padding-left: 0;
  left: 0;
  display: none;
  background: white;
}
nav.primary-navigation ul li:hover > ul,
nav.primary-navigation ul li ul:hover {
  visibility: visible;
  opacity: 1;
  display: block;
  min-width: 130px;
  text-align: left;
  padding-top: 20px;
  box-shadow: 0px 3px 5px -1px #ccc;
}
nav.primary-navigation ul li ul li {
  clear: both;
  width: 100%;
  text-align: left;
  margin-bottom: 20px;
  border-style: none;
}
nav.primary-navigation ul li ul li a:hover {
  padding-left: 10px;
  /* border-left: 2px solid #3ca0e7; */
  transition: all 0.3s ease;
}

a {
  text-decoration: none;
}
a:hover {
  color: #e76d3c;
}

ul li ul li a {
  transition: all 0.5s ease;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.fingerShowImg {
  width: 25%;
  position: absolute;
  top: -10%;
  left: 10%;
}
.dTFFld {
  flex: 1 0 auto;
  font-weight: 400;
  background-image: linear-gradient(
    140deg,
    hsl(181deg 63% 23%) 0%,
    hsl(184deg 89% 25%) 10%,
    hsl(187deg 100% 29%) 21%,
    hsl(190deg 100% 35%) 31%,
    hsl(192deg 100% 42%) 41%,
    hsl(195deg 92% 51%) 51%,
    hsl(192deg 100% 43%) 61%,
    hsl(190deg 100% 38%) 71%,
    hsl(187deg 100% 33%) 80%,
    hsl(184deg 100% 28%) 90%,
    hsl(181deg 85% 26%) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 24px;
  font-weight: 600 !important;
}
.titleDash {
  font-size: 25px;
}
.ImgBackend {
  width: 10%;
  height: 60px;
  margin-top: 14px;
  border-radius: 5px;
}
.brdblue {
  border: 2px solid #3226df;
}
.gradientTitle {
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-image: linear-gradient(
    140deg,
    #165e60,
    #077178 10%,
    #008394 21%,
    #0095b3 31%,
    #00abd6 41%,
    #0fbcf5 51%,
    #00afdb 61%,
    #00a1c2 71%,
    #0095a8 80%,
    #00858f 90%,
    #0a797b
  );
  font-size: 24px !important;
  font-weight: 600;
}
.modal-90w {
  max-width: none !important;
  width: 90% !important;
}
.rmdp-container input {
  border: 1px solid #ddd !important;
  width: 100%;
}
.bgLogo {
  background: #165f60;
}
.mainTablepdf {
  margin: 0px;
  padding: 30px 0px;
}
.Pdftable {
  margin-top: 20px;
  border: 1px solid #ddd;
  background: #fff;
}
.Pdftable thead {
  border: 1px solid #ddd;
}
.Pdftable thead tr {
  padding: 10px 0px;
  display: table-row;
}
.Pdftable thead tr th {
  padding: 10px 5px;
  border-right: 1px solid #ddd;
}
.Pdftable tbody {
}
.Pdftable tbody tr {
  border: 1px solid #ddd;
  display: table-row;
}
.Pdftable tbody tr td {
  padding: 10px 5px;
  /*width: 100px !important;*/
  border-right: 1px solid #ddd;
}
.plr1poin5 {
  padding: 0rem 1.5rem;
}
.pdfList li {
  margin-bottom: 0.5rem;
}
.uniquepdf {
  color: #2d2d2d;
  font-weight: 600;
}
.rmdp-container {
  display: block !important;
}
.pdfLogo {
  width: 250px;
  margin-left: -30px;
}
