.wrapper_fnf {
  /* max-width: 960px;
  width: 100%;
  margin: 30px auto;
  transform: scale(0.8); */
}

.landing-page_fnf {
  /* max-width: 960px;
  height: 475px; */
  margin: 0;
  /* box-shadow: 0px 0px 8px 1px #ccc; */
  /* background: #fafafa; */
  border-radius: 8px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.w-1000 {
  width: 100%;
}
.icon__download svg {
  width: 50%;
  max-height: 225px;
  height: auto;
  margin: 0 0 15px;
}

.landing-page_fnf h1 {
  font-size: 48px;
  margin: 0;
}

.landing-page_fnf p {
  font-size: 16px;
  width: 35%;
  margin: 16px auto 24px;
  text-align: center;
}

.landing-page_fnf button {
  border-radius: 50px;
  padding: 8px 24px;
  font-size: 16px;
  cursor: pointer;
  background: #62ad9b;
  color: #fff;
  border: none;
  box-shadow: 0 4px 8px 0 #ccc;
}
