@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
:root {
  --primary-color: #1676e2;
  --primary-gradient: linear-gradient(73deg, #115eb4, #1676e2, #1aa5c9);
  --primary-shadow: 0 16px 20px 0 rgba(22, 118, 226, 0.3),
    0 6px 10px 0 rgba(22, 118, 226, 0.3);
  --third-blue: #2196f3;
}

/* color variable  */
/* font size variable */
/*font weight varibale*/
/*box-shadow varibale*/
:root {
  --primary-color: #1676e2;
  --primary-gradient: linear-gradient(73deg, #115eb4, #1676e2, #1aa5c9);
  --primary-shadow: 0 16px 20px 0 rgba(22, 118, 226, 0.3),
    0 6px 10px 0 rgba(22, 118, 226, 0.3);
  --third-blue: #2196f3;
}

/* color variable  */
/* font size variable */
/*font weight varibale*/
/*box-shadow varibale*/
/* font size */
.font-62 {
  font-size: 62px !important;
}

.font-42 {
  font-size: 62px !important;
}

.font-32 {
  font-size: 32px !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-13 {
  font-size: 13px !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-11 {
  font-size: 11px !important;
}

.font-10 {
  font-size: 10px !important;
}

/* font style */
.display-5 {
  font-weight: 900;
  font-size: 62px;
  color: #2a333e;
}

.display-4 {
  font-weight: 900;
  font-size: 42px;
  color: #2a333e;
}

.display-3 {
  font-weight: 300;
  font-size: 42px;
  color: #2a333e;
}

.display-2 {
  font-weight: 700;
  font-size: 32px;
  color: #2a333e;
  line-height: 22px;
}

.display-1 {
  font-weight: 300;
  font-size: 32px;
  color: #2a333e;
}

.title-2 {
  font-weight: 300;
  font-size: 24px;
  color: #2a333e;
}

.title-1 {
  font-weight: 500;
  font-size: 18px;
  color: #2a333e;
}

.subheader {
  font-weight: 500;
  font-size: 16px;
  color: #2a333e;
}

.body {
  font-weight: 400;
  font-size: 14px;
  color: #2a333e;
}

.font-regular {
  font-weight: 400;
  font-size: 14px;
  color: #2a333e;
}

.smaller-header-bold {
  font-weight: 500;
  font-size: 13px;
  color: #2a333e;
}

.smaller-header-regular {
  font-weight: 400;
  font-size: 13px;
  color: #2a333e;
}

.table-header {
  font-weight: 500;
  font-size: 12px;
  color: #2a333e;
  text-transform: uppercase;
}

.buttons {
  font-weight: 500;
  font-size: 12px;
  color: #2a333e;
  text-transform: uppercase;
}

.caption {
  font-weight: 400;
  font-size: 12px;
  color: #2a333e;
}

.smaller-case {
  font-weight: 400;
  font-size: 11px;
  color: #2a333e;
}

.reg-header {
  font-weight: 500;
  font-size: 16px;
  color: #2a333e;
  text-transform: capitalize;
}

:root {
  --primary-color: #1676e2;
  --primary-gradient: linear-gradient(73deg, #115eb4, #1676e2, #1aa5c9);
  --primary-shadow: 0 16px 20px 0 rgba(22, 118, 226, 0.3),
    0 6px 10px 0 rgba(22, 118, 226, 0.3);
  --third-blue: #2196f3;
}

/* color variable  */
/* font size variable */
/*font weight varibale*/
/*box-shadow varibale*/
:root {
  --primary-color: #1676e2;
  --primary-gradient: linear-gradient(73deg, #115eb4, #1676e2, #1aa5c9);
  --primary-shadow: 0 16px 20px 0 rgba(22, 118, 226, 0.3),
    0 6px 10px 0 rgba(22, 118, 226, 0.3);
  --third-blue: #2196f3;
}

/* color variable  */
/* font size variable */
/*font weight varibale*/
/*box-shadow varibale*/
/* font size */
.font-62 {
  font-size: 62px !important;
}

.font-42 {
  font-size: 62px !important;
}

.font-32 {
  font-size: 32px !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-13 {
  font-size: 13px !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-11 {
  font-size: 11px !important;
}

.font-10 {
  font-size: 10px !important;
}

/* font style */
.display-5 {
  font-weight: 900;
  font-size: 62px;
  color: #2a333e;
}

.display-4 {
  font-weight: 900;
  font-size: 42px;
  color: #2a333e;
}

.display-3 {
  font-weight: 300;
  font-size: 42px;
  color: #2a333e;
}

.display-2 {
  font-weight: 700;
  font-size: 32px;
  color: #2a333e;
  line-height: 22px;
}

.display-1 {
  font-weight: 300;
  font-size: 32px;
  color: #2a333e;
}

.title-2 {
  font-weight: 300;
  font-size: 24px;
  color: #2a333e;
}

.title-1 {
  font-weight: 500;
  font-size: 18px;
  color: #2a333e;
}

.subheader {
  font-weight: 500;
  font-size: 16px;
  color: #2a333e;
}

.body {
  font-weight: 400;
  font-size: 14px;
  color: #2a333e;
}

.font-regular {
  font-weight: 400;
  font-size: 14px;
  color: #2a333e;
}

.smaller-header-bold {
  font-weight: 500;
  font-size: 13px;
  color: #2a333e;
}

.smaller-header-regular {
  font-weight: 400;
  font-size: 13px;
  color: #2a333e;
}

.table-header {
  font-weight: 500;
  font-size: 12px;
  color: #2a333e;
  text-transform: uppercase;
}

.buttons {
  font-weight: 500;
  font-size: 12px;
  color: #2a333e;
  text-transform: uppercase;
}

.caption {
  font-weight: 400;
  font-size: 12px;
  color: #2a333e;
}

.smaller-case {
  font-weight: 400;
  font-size: 11px;
  color: #2a333e;
}

.reg-header {
  font-weight: 500;
  font-size: 16px;
  color: #2a333e;
  text-transform: capitalize;
}

/*@import url("../fonts-darwinbox.css");*/
html {
  font-family: roboto;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  background: #fff;
  color: #2a333e;
  height: 100%;
}

.modal,
.modalmask,
.modalxxx {
  z-index: 9999 !important;
}

#sb-container {
  z-index: 99999 !important;
}

a {
  text-decoration: none !important;
  cursor: pointer;
}

.dispaly-none,
.display-none {
  display: none !important;
}

.dispaly-block,
.display-block {
  display: block !important;
}

.inline-block {
  display: inline-block !important;
}

.display-table {
  display: table !important;
}

.display-flex {
  display: flex !important;
}

.display-inline-flex {
  display: inline-flex !important;
}

.display-tablecell {
  display: table-cell;
}

.vis-hidden {
  visibility: hidden;
}

.vertical-align-middle {
  vertical-align: middle !important;
}

.vertical-align-top {
  vertical-align: top !important;
}

.center-column {
  float: none;
  margin: 0px auto;
}

.divider-line {
  border-bottom: 1px solid #e8ecf1;
}

.text-default {
  text-align: initial !important;
}

.text-transform-initial {
  text-transform: initial !important;
}

.text-elipses {
  overflow: hidden;
  width: 80%;
  display: inline-block;
  text-overflow: ellipsis;
}

.overflow-ellipsis {
  overflow: hidden;
  width: 95%;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.row_15 {
  margin-left: -15px !important;
  margin-right: -15px !important;
}

.app-footer {
  position: fixed;
  right: 0;
  bottom: 0px;
  left: 0;
  padding: 1rem;
  text-align: center;
  background-color: #fff;
}

.icon-circle {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: #bcc6d6;
  text-align: center;
  line-height: 26px;
}

.list-bullets li {
  list-style-type: initial !important;
}

textarea {
  resize: vertical;
}

.emp-avatar img {
  width: 30px !important;
  height: 30px !important;
  border-radius: 50%;
}

.over-hidden {
  overflow: hidden;
}

.over-auto {
  overflow: auto;
}

.fixed-80 {
  position: fixed;
  top: 80px;
}

.text-initial {
  text-align: initial !important;
}

.rotate_90 {
  transform: rotate(90deg) !important;
}

/***** flex css ***/
.pflex {
  display: flex;
}

.flex-b {
  -webkit-align-self: flex-end;
  align-self: flex-end;
}

.flex-c {
  -webkit-align-self: center;
  align-self: center;
}

.flex-1 {
  flex-grow: 1;
  flex-basis: 0;
}

.flex1 {
  flex: 1;
}

.flex2 {
  flex: 2 !important;
}

.flex3 {
  flex: 3 !important;
}

.flex4 {
  flex: 4 !important;
}

.align-c {
  align-items: center;
  justify-content: center;
}

/***** Border css ***/
.border-tb {
  border-top: 1px solid #e8ecf1;
  border-bottom: 1px solid #e8ecf1;
}

.border-t {
  border-top: 1px solid #e8ecf1;
}

.border-t-n {
  border-top: none !important;
}

.border-b {
  border-bottom: 1px solid #e8ecf1;
}

.border-b-n {
  border-bottom: none !important;
}

.border-l {
  border-left: 1px solid #e8ecf1;
}

.border-l-n {
  border-left: none !important;
}

.border-r {
  border-right: 1px solid #e8ecf1;
}

.border-r-n {
  border-right: none !important;
}

.border-all {
  border: 1px solid #e8ecf1;
}

.border-n {
  border: none !important;
}

.border-primary-blue {
  border: 1px solid var(--primary-color) !important;
}

.border-primary-green {
  border: 1px solid #1ac964 !important;
}

.border-tint-blue-1 {
  border: 1px solid #c4dcf7 !important;
}

.border-r-0 {
  border-radius: 0px !important;
}

.border-r-2 {
  border-radius: 2px !important;
}

/* font color */
.primary-blue {
  color: var(--primary-color) !important;
}

.primary-red {
  color: #f44336 !important;
}

.primary-green {
  color: #1ac964 !important;
}
.pr-15 {
  padding-right: 15px !important;
}

.primary-orange {
  color: #ff8d21 !important;
}

.primary-grey {
  color: #7d8d9f !important;
}

.primary-yellow {
  color: #ffbb04 !important;
}

.primary-white {
  color: #fff !important;
}

.tint-blue-1 {
  color: #c4dcf7 !important;
}

.tint-red-1 {
  color: #fbd0cd !important;
}

.tint-green-1 {
  color: #caf2db !important;
}

.tint-orange-1 {
  color: #ffdcbc !important;
}

.tint-yellow-1 {
  color: #ffe6a5 !important;
}

.tint-blue-2 {
  color: #edf4fd !important;
}

.tint-red-2 {
  color: #fdedec !important;
}

.tint-green-2 {
  color: #e8f9ef !important;
}

.tint-orange-2 {
  color: #fff3e8 !important;
}

.tint-yellow-2 {
  color: #fff8e5 !important;
}

.shade-blue {
  color: #115eb4 !important;
}

.shade-red {
  color: #c3352b !important;
}

.shade-green {
  color: #2ca860 !important;
}

.shade-orange {
  color: #e07220 !important;
}

.shade-yellow {
  color: #f4a21b !important;
}

.base-1 {
  color: #2a333e !important;
}

.base-2 {
  color: #4b5c72 !important;
}

.base-3 {
  color: #7d8d9f !important;
}

.base-4 {
  color: #bcc6d6 !important;
}

.base-5 {
  color: #dbe1e8 !important;
}

.base-6 {
  color: #e8ecf1 !important;
}

.base-7 {
  color: #f7f8fa !important;
}

/* background color */

.bg-primary-blue {
  background-color: var(--primary-color) !important;
}

.bg-primary-red {
  background-color: #f44336 !important;
}

.bg-primary-green {
  background-color: #1ac964 !important;
}

.bg-primary-orange {
  background-color: #ff8d21 !important;
}

.bg-primary-orange-1 {
  background-color: #ff7a28 !important;
}

.bg-primary-yellow {
  background-color: #ffbb04 !important;
}

.bg-primary-skyblue {
  background-color: #01c7e5 !important;
}

.bg-primary-white {
  background-color: #fff !important;
}

.bg-primary-gray {
  background-color: #eee !important;
}

.bg-primary-violet {
  background-color: #876eef !important;
}

.bg-tint-blue-1 {
  background-color: #c4dcf7 !important;
}

.bg-tint-red-1 {
  background-color: #fbd0cd !important;
}

.bg-tint-green-1 {
  background-color: #caf2db !important;
}

.bg-tint-orange-1 {
  background-color: #ffdcbc !important;
}

.bg-tint-yellow-1 {
  background-color: #ffe6a5 !important;
}

.bg-tint-blue-2 {
  background-color: #edf4fd !important;
}

.bg-tint-red-2 {
  background-color: #fdedec !important;
}

.bg-tint-green-2 {
  background-color: #e8f9ef !important;
}

.bg-tint-orange-2 {
  background-color: #fff3e8 !important;
}

.bg-tint-yellow-2 {
  background-color: #fff8e5 !important;
}

.bg-shade-blue {
  background-color: #115eb4 !important;
}

.bg-shade-red {
  background-color: #c3352b !important;
}

.bg-shade-green {
  background-color: #2ca860 !important;
}

.bg-shade-orange {
  background-color: #e07220 !important;
}

.bg-shade-yellow {
  background-color: #f4a21b !important;
}

.bg-base-1 {
  background-color: #2a333e !important;
}

.bg-base-2 {
  background-color: #4b5c72 !important;
}

.bg-base-3 {
  background-color: #7d8d9f !important;
}

.bg-base-4 {
  background-color: #bcc6d6 !important;
}

.bg-base-5 {
  background-color: #dbe1e8 !important;
}

.bg-base-6 {
  background-color: #e8ecf1 !important;
}

.bg-base-7 {
  background-color: #f7f8fa !important;
}

.bg-gradient-blue-green {
  background: var(--primary-gradient) !important;
}

/*** flex  ***/
.flex-p {
  display: flex;
}

.flex-c {
  flex: 1;
}

.flex-cont-c {
  display: flex;
  justify-content: center;
  text-align: center !important;
}

.flex-cont-sb {
  display: flex;
  justify-content: space-between;
}

/******* ######### ******
 font weights && font family
******* ######### ******/
.f-extrabold {
  font-weight: 900 !important;
}

.f-bold {
  font-weight: 700 !important;
}

.f-medium {
  font-weight: 500 !important;
}

.f-regular {
  font-weight: 400 !important;
}

.f-light {
  font-weight: 300 !important;
}

.f-family-roboto {
  font-family: Roboto !important;
}

.f-family-lato {
  font-family: "latoregular" !important;
}

/******* ######### ******
        Buttons
******* ######### ******/
.db-btn {
  margin: 0;
  border: 1px solid;
  border-radius: 2px !important;
  overflow: visible;
  font: inherit;
  color: #ffffff;
  text-transform: none;
  display: inline-block;
  box-sizing: border-box;
  padding: 0 15px;
  vertical-align: middle;
  font-size: 12px !important;
  font-weight: 600 !important;
  line-height: 34px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  -webkit-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;
  -webkit-transition-property: color, background-color, border-color;
  transition-property: color, background-color, border-color;
  position: relative;
  overflow: hidden;
}
.db-btn.db-add-leftIcon-btn {
  padding-left: 30px;
}
.db-btn.db-add-leftIcon-btn:before {
  content: "\e902";
  font-family: "darwinbox" !important;
  position: absolute;
  left: 12px;
  font-size: 16px;
}

.db-btn-sm {
  line-height: 26px !important;
  font-size: 11px;
}

.db-btn.btn-curved {
  border-radius: 40px !important;
}

.db-btn:focus,
.db-btn:active {
  background-color: var(--primary-color) !important;
  color: #fff !important;
  border: 1px solid var(--primary-color) !important;
}

.db-btn.btn-primary:hover:not(:disabled) {
  box-shadow: 0 16px 20px 0 rgba(22, 118, 226, 0.3),
    0 6px 10px 0 rgba(22, 118, 226, 0.3);
  border: 1px solid var(--primary-color) !important;
}

.db-btn.btn-primary:active {
  background-color: #115eb4;
  color: #ffffff;
  box-shadow: 0 3px 8px 0 rgba(22, 118, 226, 0.5);
}

.db-btn.btn-primary:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.db-btn.btn-secondary {
  color: #1676e2; /* Backup colour for IE */
}

.db-btn.btn-secondary {
  background-color: #ffffff;
  color: var(--primary-color);
  border: 1px solid #dbe1e8;
  box-shadow: none;
}

.db-btn.btn-secondary:hover:not(:disabled) {
  border: 1px solid var(--primary-color);
}
.db-btn.btn-secondary:hover:not(:disabled).filled-hover {
  box-shadow: 0 16px 20px 0 rgba(22, 118, 226, 0.3),
    0 6px 10px 0 rgba(22, 118, 226, 0.3);
  border: 1px solid var(--primary-color) !important;
  background-color: var(--primary-color);
  color: #fff;
}
.db-btn.btn-secondary:hover:not(:disabled).filled-hover span {
  color: #fff;
}

.db-btn.btn-secondary:focus {
  background-color: #ffffff;
  color: var(--primary-color);
  box-shadow: none;
}

.db-btn.btn-secondary:active {
  border: 1px solid var(--primary-color);
  background-color: #edf4fd;
  color: #ffffff;
  box-shadow: none;
}

.db-btn.btn-secondary:disabled {
  background-color: #ffffff;
  color: #bcc6d6;
  border-color: #dbe1e8;
  cursor: not-allowed;
}

.btn-text:disabled {
  background-color: #ffffff;
  color: #bcc6d6;
  cursor: not-allowed;
}

.btn-text-hover:disabled:hover {
  border-color: #dbe1e8;
}

.db-btn.btn-danger {
  background-color: #ffffff;
  color: #f44336;
  border: 1px solid #dbe1e8;
  box-shadow: none;
}

.db-btn.btn-danger:hover:not(:disabled) {
  border: 1px solid #f44336;
  background-color: #ffffff;
  color: #f44336;
}

.db-btn.btn-danger:focus {
  background-color: #ffffff;
  color: #f44336;
  box-shadow: none;
}

.db-btn.btn-danger:active {
  border: 1px solid #f44336;
  background-color: #fdedec;
  color: #f44336;
  box-shadow: none;
}

.db-btn.btn-danger:disabled {
  background-color: #ffffff;
  color: #fbd0cd;
  border-color: #fbd0cd;
}

.db-btn.btn-radius {
  border-radius: 50px !important;
  border: solid 1px #c4dcf7;
  background: #edf4fd;
  color: var(--primary-color);
  width: auto;
}

.db-btn.btn-tertiary {
  background-color: #ffffff;
  color: var(--primary-color);
  border: none;
}

.db-btn.btn-tertiary:hover:not(:disabled) {
  border: none;
  background-color: #edf4fd;
  color: var(--primary-color);
}

.db-btn.btn-tertiary:focus {
  background-color: #edf4fd;
  color: var(--primary-color);
  box-shadow: none;
}

.db-btn.btn-tertiary:active {
  border: none;
  background-color: #edf4fd;
  color: var(--primary-color);
  box-shadow: none;
}

.db-btn.btn-default {
  background-color: transparent;
  color: #222;
  border: 1px solid #e5e5e5;
}

.db-btn.btn-attachment {
  background-color: #edf4fd;
  color: var(--primary-color);
  border: 1px solid #c4dcf7;
  font-size: 13px;
  padding: 0 20px;
}

.db-btn.ripple:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  opacity: 0;
  border-radius: 100%;
  transform: scale(1, 1) translate(-50%);
  transform-origin: 50% 50%;
}

.db-btn.btn-default.ripple:after {
  background: rgba(0, 0, 0, 0.2);
}

.db-btn.btn-primary.ripple:after {
  background: rgba(0, 0, 0, 0.2);
}

.db-btn.btn-secondary.ripple:after {
  background: rgba(22, 118, 226, 0.4);
}

.db-btn.btn-danger.ripple:after {
  background: rgba(244, 67, 54, 0.4);
}

.db-btn.btn-tertiary.ripple:after {
  background: rgba(22, 118, 226, 0.4);
}

@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 0.8;
  }
  20% {
    transform: scale(15, 15);
    opacity: 0.6;
  }
  100% {
    opacity: 0;
    transform: scale(30, 30);
  }
}
.db-btn.ripple:focus:not(:active)::after {
  animation: ripple 0.9s ease-out;
}

.ripple-effect {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}
.ripple-effect:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, #000 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform 0.5s, opacity 1s;
}
.ripple-effect:active:after {
  transform: scale(0, 0);
  opacity: 0.2;
  transition: 0s;
}

.btn-clockin {
  border-radius: 2px;
  border: solid 1px rgba(255, 255, 255, 0.5);
  color: #fff;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 10px 15px;
  position: relative;
  transition: all 0.5s ease;
  min-height: 28px;
  line-height: 1.75;
}
.btn-clockin:before {
  content: "\e918";
  font-family: darwinbox;
  position: absolute;
  left: 8px;
  font-size: 14px;
  top: 1px;
}

.clock-in-out-btn {
  padding: 5px 10px;
  border-radius: 2px;
  border: solid 1px rgba(255, 255, 255, 0.5);
  font-size: 10px;
  font-weight: 500;
}

.show_clock_popover span.tool_tip_btn {
  position: absolute;
  bottom: -39px;
  right: 38px;
  left: -22px;
  z-index: 999;
  background: #fff;
  padding: 6px 10px;
  border: 1px solid #ddd;
  transition: all 0.5s ease-in;
  font-size: 12px;
  color: var(--primary-color) !important;
  min-width: 75px;
  text-align: center;
  border-radius: 4px;
  display: none;
}
.show_clock_popover span.tool_tip_btn:before {
  content: "";
  width: 0;
  height: 0;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
}
.show_clock_popover:hover span.tool_tip_btn {
  display: block;
  transition: all 0.5s ease-in;
}

.btn-with-icon {
  color: #2a333e;
  padding: 9px;
  border: 1px solid #e8ecf1;
  border-radius: 2px;
  background-color: #f7f8fa;
}
.btn-with-icon .icon {
  font-size: 20px;
  position: absolute;
}
.btn-with-icon .text {
  font-size: 13px;
  padding-left: 24px;
  padding-right: 6px;
}

.btn-status {
  background: #7d8d9f;
  border-radius: 9px;
  font-size: 10px;
  display: inline-block;
  color: #fff;
  white-space: nowrap;
  padding: 2px 10px 2px 10px;
  line-height: 17px;
  text-transform: uppercase;
  font-weight: 600;
}
.btn-status.no-radius {
  border-radius: initial !important;
}
.btn-status.radius-2 {
  border-radius: 2px !important;
}

.btn_attachment_view {
  border-radius: 2px;
  border: solid 1px #e8ecf1;
  background-color: #f7f8fa;
  padding: 6px 15px;
  display: inline-block;
  font-size: 13px;
  min-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.btn_attachment_view df {
  font-size: 18px;
  color: var(--primary-color);
  top: 3px;
  position: relative;
  margin-right: 5px;
}

.btn-status {
  background: #7d8d9f;
}

.btn_attachment input {
  display: none;
}
.btn_attachment label {
  cursor: pointer;
  background: #f7f8fa;
  border: 1px solid #dbe1e8;
  font-size: 12px;
  color: #7d8d9f;
  padding: 0px 8px 0px 2px;
}
.btn_attachment label span.uploded_file_name {
  position: relative;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
.btn_attachment label df {
  font-size: 25px;
  position: relative;
  top: 2px;
}

.btn-status {
  background: #7d8d9f;
  border-radius: 9px;
  font-size: 10px;
  display: inline-block;
  color: #fff;
  white-space: nowrap;
  padding: 0px 10px 0px 10px;
  line-height: 17px;
  text-transform: uppercase;
  font-weight: 500;
  height: 16px;
}

.circular_dot {
  width: 10px;
  height: 10px;
  background-color: #ffab2b;
  display: inline-block;
  border-radius: 50%;
}

/********* Circle Button **********/
.db-non-circle-btn {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  text-align: center;
  line-height: 28px;
  /*border: 1px solid $base-3;*/
  cursor: pointer;
  color: #7d8d9f;
  font-size: 20px;
}

.btn-animation-link .icon-wrapper {
  right: 2px;
  top: -2px;
  transition: all 0.5s ease;
}
.btn-animation-link:hover .icon-wrapper {
  right: -10px;
  transition: all 0.5s ease;
}
.btn-animation-link .link-arrow {
  position: relative;
  top: 3px;
}

.icon-left-link-btn {
  font-size: 12px;
  font-weight: 500;
}
.icon-left-link-btn .icon-wrapper {
  position: relative;
  top: 3px;
  font-size: 17px;
}

.status_label {
  font-size: 10px;
  font-weight: 500;
  color: #fff;
  background: #7d8d9f;
  border-radius: 2px;
  padding: 5px 5px 4px 5px;
  text-transform: uppercase;
}
.status_label.label_circle {
  padding: 5px 10px;
  border-radius: 25px !important;
}

.no-text-transform {
  text-transform: none;
}

.font-normal {
  font-weight: normal !important;
}

.font-500 {
  font-weight: 500 !important;
}

.btn-circular {
  background: #fff3e8;
  font-size: 12px;
  font-family: roboto;
  padding: 5px 10px;
  display: inline-block;
  border-radius: 25px;
  border: 1px solid #ffdcbc;
  color: #2a333e;
}

/******************
    search input
********************/
.db-search {
  /* background: #fff url("../../../../../images/Icons_latest/search-1.png") no-repeat 260px 10px !important; */
  background-position: 10px !important;
  padding-left: 36px !important;
}

/******************
    password
********************/
.db-password {
  /* background: #fff url(../../../../../images/Icons_latest/show-password.png) no-repeat right !important; */
  background-position: calc(100% - 8px) 10px !important;
  padding-right: 46px !important;
  letter-spacing: 1px !important;
}

.showpassword {
  position: relative;
}
.showpassword df.show-password {
  position: absolute;
  right: 10px;
  top: 9px;
  font-size: 20px;
  cursor: pointer;
  color: #bcc6d6;
}
.showpassword df.show-password:hover {
  color: #4b5c72;
}

/******************
    datepicker
********************/
.db-datepicker {
  /* background: #fff url("../../images/calendar.png") no-repeat 260px 10px !important; */
  background-position: calc(100% - 3px) !important;
  padding-right: 28px !important;
}

.datepicker-days {
  padding: 0px;
}
.datepicker-days table {
  width: 100%;
}

.dow {
  font-size: 10px;
  padding: 15px !important;
}

.table-condensed thead tr:first-child {
  border-bottom: 1px solid #bcc6d6;
  border-collapse: inherit;
}

.day {
  color: #354052;
  padding: 7px !important;
  height: 36px !important;
  width: 36px !important;
}

.day:hover {
  background-color: #e8ecf1 !important;
}

.active.day {
  background-color: var(--primary-color) !important;
  color: #fff;
  box-shadow: 0 16px 20px 0 rgba(22, 118, 226, 0.3),
    0 6px 10px 0 rgba(22, 118, 226, 0.3) !important;
}
.active.day:hover {
  background-color: var(--primary-color) !important;
  color: #fff;
  box-shadow: 0 16px 20px 0 rgba(22, 118, 226, 0.3),
    0 6px 10px 0 rgba(22, 118, 226, 0.3) !important;
}

.datepicker-switch {
  color: #354052;
  font-size: 13px;
}

.today {
  display: inline-block;
  background-color: #fff !important;
  border: 2px solid var(--primary-color) !important;
  border-radius: 2px !important;
  border-collapse: initial !important;
  box-shadow: 0 16px 20px 0 rgba(22, 118, 226, 0.2),
    0 3px 10px 0 rgba(22, 118, 226, 0.2);
}
.today:hover {
  background-color: #fff !important;
}

table.table-condensed {
  /* border-collapse: initial; */
}
table.table-condensed thead tr:first-child {
  border-bottom: 1px solid #bcc6d6 !important;
}
table.table-condensed td.today {
  border-collapse: initial;
}

.tab-datePiker input {
  border: none !important;
  box-shadow: none !important;
  font-size: 14px !important;
  color: #2a333e !important;
  max-width: 80px;
}
.tab-datePiker df {
  font-size: 28px;
  color: #e8ecf1;
  position: relative;
  top: 5px;
}

/******* ######### ******
        Time Picker
******* ######### ******/
.db-timepicker {
  /* background: #fff url(../../../../../images/Icons_latest/clock.png) no-repeat right !important; */
  background-position: calc(100% - 5px) 6px !important;
  padding-right: 38px !important;
  letter-spacing: 1px !important;
  opacity: 0.9;
}

.bootstrap-timepicker-widget.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: initial;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
.bootstrap-timepicker-widget td input {
  width: 30px;
}

/******* ######### ******
        Input
******* ######### ******/
.db-label {
  color: #4b5c72 !important;
  font-size: 12px;
  font-weight: normal;
}

.form-group label {
  color: #4b5c72 !important;
  font-size: 12px;
  font-weight: normal;
  margin-bottom: 4px;
}

.db-label-legend {
  z-index: 1;
  margin-bottom: 0;
  position: relative;
  left: 8px;
  top: 8px;
  font-size: 10px;
  background-color: white;
  padding: 0 4px;
}

input.db-input,
select.db-select,
textarea.db-textarea {
  font-size: 14px;
  padding: 7px 12px;
  border-radius: 4px !important;
  border: 1px solid #bcc6d6 !important;
  color: #353f4c !important;
  -webkit-text-fill-color: inherit;
  box-shadow: none;
  caret-color: var(--primary-color);
  background: #fff;
  transition: all 0.5s ease;
  height: auto;
  padding-right: 36px;
}

input.db-input,
select.db-select,
input.form-control {
  /* height: 36px !important; */
}
input.db-input.db-input-sm,
select.db-select.db-input-sm,
input.form-control.db-input-sm {
  height: 30px !important;
}

input::placeholder,
select::placeholder,
textarea::placeholder {
  color: #bfc8d5 !important;
}

input.db-input:hover:not(:disabled),
select.db-select:hover:not(:disabled),
textarea.db-textarea:hover:not(:disabled) {
  border: 1px solid #bcc6d6;
  transition: all 0.5s ease;
}

input.db-input:focus,
select.db-select:focus,
textarea.db-textarea:focus,
.tokenfield.db-input:focus {
  outline: 0;
  border-color: var(--primary-color) !important;
  box-shadow: 0 3px 8px 0 rgba(22, 118, 226, 0.16);
  -webkit-box-shadow: none;
  transition: all 0.5s ease;
}

input.db-input:disabled,
select.db-select:disabled,
textarea.db-textarea:disabled {
  background: #f7f8fa !important;
}

.db-input.error,
.db-textarea.error,
.searchabledropdown.error,
.form-control.error,
input.form-check-input[type="checkbox"].error,
input.form-check-input[type="radio"].error {
  border: 1px solid #f44336 !important;
}
.db-input.error:hover,
.db-input.error:focus,
.db-textarea.error:hover,
.db-textarea.error:focus,
.searchabledropdown.error:hover,
.searchabledropdown.error:focus,
.form-control.error:hover,
.form-control.error:focus,
input.form-check-input[type="checkbox"].error:hover,
input.form-check-input[type="checkbox"].error:focus,
input.form-check-input[type="radio"].error:hover,
input.form-check-input[type="radio"].error:focus {
  border: 1px solid #f44336 !important;
}

.db-error-msg {
  font-size: 12px;
  color: #f44336;
  margin-top: 8px;
  display: block;
}

.db-success-msg {
  font-size: 12px;
  color: #1ac964;
  margin-top: 8px;
  display: block;
}

.wrap-text {
  width: 100%;
  word-break: break-all;
}

.clear-both {
  clear: both;
}

.amplify-force-hidden {
  display: none !important;
}

.error-mesg {
  font-size: 12px;
  color: #f44336;
  margin-top: 8px;
  display: block;
}

.copy-info-msg {
  font-size: 12px;
  color: #bcc6d4;
  margin-top: 8px;
  display: block;
}

.db-chosen-select .chosen-container {
  display: block;
}

/******* ######### ******
        float input
******* ######### ******/
.db-float-input {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0px;
  padding: 9.5px 12px !important;
  display: block;
  width: 100%;
}
.db-float-input:focus {
  border-radius: 0px;
}

.db-float-input:focus {
  box-shadow: none !important;
}

.db-float-input:hover {
  border-top: none;
  border-left: none;
  border-right: none;
}

.db-float-input-group {
  position: relative;
}

.db-float-input-group .db-form-label {
  color: #bfc8d5;
  font-size: 14px;
  position: absolute;
  pointer-events: none;
  font-weight: normal;
  left: 2px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.db-float-input-group .db-float-input:focus ~ .db-form-label,
.db-float-input-group .db-float-input:valid ~ .db-form-label {
  top: -12px;
  left: 2px;
  color: #7d8d9f;
  font-size: 13px;
}

.db-float-input-group .bar {
  position: relative;
  display: block;
}

.db-float-input-group .bar:before,
.db-float-input-group .bar:after {
  content: "";
  /*height: 1px;*/
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #4285f4;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.db-float-input-group .bar:before {
  left: 50%;
}

.db-float-input-group .bar:after {
  right: 50%;
}

.db-float-input-group .db-float-input:focus ~ .bar:before,
.db-float-input-group .db-float-input:focus ~ .bar:after {
  width: 50%;
}

.db-float-input-group-icon .db-form-icon {
  position: absolute;
  top: 10px;
  right: 0px;
  font-size: 18px;
  color: #4b5c72;
}

input.db-input.db-float-input {
  border: none !important;
  border-bottom: 1px solid #dbe1e8 !important;
}

input.db-input-small {
  height: 28px !important;
}

/******* ######### ******
    dot navigation
******* ######### ******/
.db-dotnav {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}

.db-dotnav > * {
  padding-left: 12px;
}

.db-dotnav > * > * {
  display: block;
  box-sizing: border-box;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  text-indent: 100%;
  overflow: hidden;
  white-space: nowrap;
  background: #bcc6d4;
  border: 1px solid #bcc6d4;
}

.db-dotnav > * > :hover,
.db-dotnav > * > :focus {
  background-color: var(--primary-color);
}

.db-dotnav > .db-active > *,
.db-dotnav > * > :active {
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.db-radio-group-btn .btn {
  border-radius: 2px;
  background-color: #f7f8fa !important;
  border: solid 1px #e8ecf1 !important;
  box-shadow: none !important;
  color: #bcc6d6 !important;
  text-transform: uppercase;
  font-size: 12px !important;
  padding: 8px 16px;
  /*border: transparent !important;*/
}
.db-radio-group-btn .btn.active {
  border-color: var(--primary-color) !important;
  background-color: #fff !important;
  color: var(--primary-color) !important;
}
/******* ######### ******
    step navigation
******* ######### ******/
.db-stepnavigation-group {
  padding: 0px;
  margin: 0px auto;
  text-align: center;
  display: flex;
  width: 100%;
}
.db-stepnavigation-group li {
  position: relative;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  list-style-type: none;
}
.db-stepnavigation-group li:after {
  content: "";
  width: 100%;
  height: 1px;
  background: #e8ecf1;
  position: absolute;
  left: 0;
  right: 0;
  top: 20px;
}
.db-stepnavigation-group li:first-child:after {
  width: 50%;
  left: initial !important;
}
.db-stepnavigation-group li:last-child:after {
  width: 50%;
  right: initial !important;
}
.db-stepnavigation-group li .db-step-icon {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  border: 1px solid #dbe1e8;
  margin: auto;
  background-color: white;
  text-align: center;
  line-height: 32px;
  font-size: 14px;
  position: relative;
  margin-left: calc(50% - 20px);
  z-index: 2;
  cursor: pointer;
}
.db-stepnavigation-group li .db-step-name {
  padding-top: 44px;
  font-size: 14px;
  color: #7d8d9f;
  text-align: center;
  word-wrap: break-word;
}
.db-stepnavigation-group li .db-line-navigation-comman,
.db-stepnavigation-group li .db-line-left,
.db-stepnavigation-group li .db-line-middle,
.db-stepnavigation-group li .db-line-right {
  position: absolute;
  height: 1px;
  background-color: #e8ecf1;
  top: 19px;
  z-index: 1;
}
.db-stepnavigation-group li .db-line-right {
  width: 50%;
  right: 0px;
}
.db-stepnavigation-group li .db-line-middle {
  width: 100%;
  right: 0px;
}
.db-stepnavigation-group li .db-line-left {
  width: 50%;
  left: 0px;
}
.db-stepnavigation-group .db-active-navigator .db-step-icon {
  background-color: #4b5c72;
  border: 1px solid #4b5c72;
  color: white;
}
.db-stepnavigation-group .db-active-navigator .db-step-name {
  color: #2a333e;
}
.db-stepnavigation-group .db-active-navigator .step_text {
  display: block;
}
.db-stepnavigation-group .db-done-navigator .db-step-icon {
  background-color: #e8ecf1;
}
.db-stepnavigation-group .db-done-navigator .db-step-icon:before {
  content: "\e954";
  font-family: darwinbox;
  font-size: 12px;
  color: #ffffff;
  font-weight: bolder;
  background: #6ab94c;
  width: 12px;
  height: 12px;
  top: -2px;
  right: 0px;
  position: absolute;
  line-height: 12px;
  text-align: center;
  border-radius: 50%;
}

/******* ######### ******
        checkbox
******* ######### ******/
.db-checkbox [type="checkbox"]:checked,
.db-checkbox [type="checkbox"]:not(:checked) {
  position: absolute;
  left: -9999px;
  opacity: 0;
}
.db-checkbox [type="checkbox"]:checked + label,
.db-checkbox [type="checkbox"]:not(:checked) + label {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  line-height: 18px;
  display: inline-block;
  color: #4b5c72;
  font-weight: normal;
}
.db-checkbox [type="checkbox"]:checked + label:before,
.db-checkbox [type="checkbox"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  /*border: 2px solid $base-5;*/
  border-radius: 2px;
  background: #fff;
  border: 2px solid #dbe1e8;
}
.db-checkbox [type="checkbox"]:checked + label:before {
  /*border: 2px solid $primary-blue;*/
  border: 2px solid var(--primary-color);
}
.db-checkbox [type="checkbox"]:checked + label:after,
.db-checkbox [type="checkbox"]:not(:checked) + label:after {
  content: "\e954";
  font-family: darwinbox;
  width: 18px;
  height: 18px;
  /*background: $primary-blue;
  color: #fff;*/
  position: absolute;
  left: 0px;
  text-align: center;
  border-radius: 2px;
  font-size: 18px;
  font-weight: 700;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  background: var(--primary-color);
  color: #fff;
}
.db-checkbox [type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.db-checkbox [type="checkbox"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.db-checkbox [type="checkbox"][disabled]:checked + label:before {
  opacity: 0.3;
  border: 1px solid transparent !important;
}
.db-checkbox [type="checkbox"][disabled]:checked + label:after {
  opacity: 0.3;
}
.db-checkbox.checkbox-blue [type="checkbox"]:checked + label:before,
.db-checkbox.checkbox-blue [type="checkbox"]:not(:checked) + label:before {
  border: 2px solid var(--primary-color);
}
.db-checkbox.checkbox-blue [type="checkbox"]:checked + label:before {
  border: 2px solid var(--primary-color);
}
.db-checkbox.checkbox-blue [type="checkbox"]:checked + label:after,
.db-checkbox.checkbox-blue [type="checkbox"]:not(:checked) + label:after {
  background: var(--primary-color);
  color: #fff;
}
.db-checkbox.checkbox-yellow [type="checkbox"]:checked + label:before,
.db-checkbox.checkbox-yellow [type="checkbox"]:not(:checked) + label:before {
  border: 2px solid #ffbb04;
}
.db-checkbox.checkbox-yellow [type="checkbox"]:checked + label:before {
  border: 2px solid #ffbb04;
}
.db-checkbox.checkbox-yellow [type="checkbox"]:checked + label:after,
.db-checkbox.checkbox-yellow [type="checkbox"]:not(:checked) + label:after {
  background: #ffbb04;
  color: #fff;
}
.db-checkbox.checkbox-red [type="checkbox"]:checked + label:before,
.db-checkbox.checkbox-red [type="checkbox"]:not(:checked) + label:before {
  border: 2px solid #f44336;
}
.db-checkbox.checkbox-red [type="checkbox"]:checked + label:before {
  border: 2px solid #f44336;
}
.db-checkbox.checkbox-red [type="checkbox"]:checked + label:after,
.db-checkbox.checkbox-red [type="checkbox"]:not(:checked) + label:after {
  background: #f44336;
  color: #fff;
}
.db-checkbox.checkbox-green [type="checkbox"]:checked + label:before,
.db-checkbox.checkbox-green [type="checkbox"]:not(:checked) + label:before {
  border: 2px solid #1ac964;
}
.db-checkbox.checkbox-green [type="checkbox"]:checked + label:before {
  border: 2px solid #1ac964;
}
.db-checkbox.checkbox-green [type="checkbox"]:checked + label:after,
.db-checkbox.checkbox-green [type="checkbox"]:not(:checked) + label:after {
  background: #1ac964;
  color: #fff;
}
.db-checkbox.checkbox-orange [type="checkbox"]:checked + label:before,
.db-checkbox.checkbox-orange [type="checkbox"]:not(:checked) + label:before {
  border: 2px solid #ff8d21;
}
.db-checkbox.checkbox-orange [type="checkbox"]:checked + label:before {
  border: 2px solid #ff8d21;
}
.db-checkbox.checkbox-orange [type="checkbox"]:checked + label:after,
.db-checkbox.checkbox-orange [type="checkbox"]:not(:checked) + label:after {
  background: #ff8d21;
  color: #fff;
}
.db-checkbox.checkbox-pink [type="checkbox"]:checked + label:before,
.db-checkbox.checkbox-pink [type="checkbox"]:not(:checked) + label:before {
  border: 2px solid #da2d66;
}
.db-checkbox.checkbox-pink [type="checkbox"]:checked + label:before {
  border: 2px solid #da2d66;
}
.db-checkbox.checkbox-pink [type="checkbox"]:checked + label:after,
.db-checkbox.checkbox-pink [type="checkbox"]:not(:checked) + label:after {
  background: #da2d66;
  color: #fff;
}
.db-checkbox.checkbox-second-blue [type="checkbox"]:checked + label:before,
.db-checkbox.checkbox-second-blue
  [type="checkbox"]:not(:checked)
  + label:before {
  border: 2px solid #274261;
}
.db-checkbox.checkbox-second-blue [type="checkbox"]:checked + label:before {
  border: 2px solid #274261;
}
.db-checkbox.checkbox-second-blue [type="checkbox"]:checked + label:after,
.db-checkbox.checkbox-second-blue
  [type="checkbox"]:not(:checked)
  + label:after {
  background: #274261;
  color: #fff;
}
.db-checkbox.checkbox-second-yellow [type="checkbox"]:checked + label:before,
.db-checkbox.checkbox-second-yellow
  [type="checkbox"]:not(:checked)
  + label:before {
  border: 2px solid #cca028;
}
.db-checkbox.checkbox-second-yellow [type="checkbox"]:checked + label:before {
  border: 2px solid #cca028;
}
.db-checkbox.checkbox-second-yellow [type="checkbox"]:checked + label:after,
.db-checkbox.checkbox-second-yellow
  [type="checkbox"]:not(:checked)
  + label:after {
  background: #cca028;
  color: #fff;
}
.db-checkbox.checkbox-second-red [type="checkbox"]:checked + label:before,
.db-checkbox.checkbox-second-red
  [type="checkbox"]:not(:checked)
  + label:before {
  border: 2px solid #8d261e;
}
.db-checkbox.checkbox-second-red [type="checkbox"]:checked + label:before {
  border: 2px solid #8d261e;
}
.db-checkbox.checkbox-second-red [type="checkbox"]:checked + label:after,
.db-checkbox.checkbox-second-red [type="checkbox"]:not(:checked) + label:after {
  background: #8d261e;
  color: #fff;
}
.db-checkbox.checkbox-second-green [type="checkbox"]:checked + label:before,
.db-checkbox.checkbox-second-green
  [type="checkbox"]:not(:checked)
  + label:before {
  border: 2px solid #209852;
}
.db-checkbox.checkbox-second-green [type="checkbox"]:checked + label:before {
  border: 2px solid #209852;
}
.db-checkbox.checkbox-second-green [type="checkbox"]:checked + label:after,
.db-checkbox.checkbox-second-green
  [type="checkbox"]:not(:checked)
  + label:after {
  background: #209852;
  color: #fff;
}
.db-checkbox.checkbox-second-orange [type="checkbox"]:checked + label:before,
.db-checkbox.checkbox-second-orange
  [type="checkbox"]:not(:checked)
  + label:before {
  border: 2px solid #b8712f;
}
.db-checkbox.checkbox-second-orange [type="checkbox"]:checked + label:before {
  border: 2px solid #b8712f;
}
.db-checkbox.checkbox-second-orange [type="checkbox"]:checked + label:after,
.db-checkbox.checkbox-second-orange
  [type="checkbox"]:not(:checked)
  + label:after {
  background: #b8712f;
  color: #fff;
}
.db-checkbox.checkbox-second-pink [type="checkbox"]:checked + label:before,
.db-checkbox.checkbox-second-pink
  [type="checkbox"]:not(:checked)
  + label:before {
  border: 2px solid #8a2b4b;
}
.db-checkbox.checkbox-second-pink [type="checkbox"]:checked + label:before {
  border: 2px solid #8a2b4b;
}
.db-checkbox.checkbox-second-pink [type="checkbox"]:checked + label:after,
.db-checkbox.checkbox-second-pink
  [type="checkbox"]:not(:checked)
  + label:after {
  background: #8a2b4b;
  color: #fff;
}

.db-checkbox.inline-block {
  margin-right: 10px;
}

.db-checkbox.inline-block:first-child {
  margin-left: 0px;
}

/******* ######### ******
        radio buttin
******* ######### ******/
.db-radio [type="radio"]:checked,
.db-radio [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
  opacity: 0;
}
.db-radio [type="radio"]:checked + label,
.db-radio [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  line-height: 18px;
  display: inline-block;
  color: #4b5c72;
  font-weight: normal;
}
.db-radio [type="radio"]:checked + label:before,
.db-radio [type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 2px solid #ddd;
  border-radius: 100%;
  background: #fff;
  box-sizing: border-box !important;
}
.db-radio [type="radio"]:checked + label:before {
  border: 2px solid var(--primary-color);
}
.db-radio [type="radio"]:checked + label:after,
.db-radio [type="radio"]:not(:checked) + label:after {
  content: "";
  width: 8px;
  height: 8px;
  background: var(--primary-color);
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.db-radio [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.db-radio [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.db-radio.inline-block {
  margin-right: 10px;
}

.db-radio.inline-block:first-child {
  margin-left: 0px;
}

.radio input[type="radio"]:focus + label::before {
  outline: 0 !important;
}

/******* ######### ******
    Editable conatiner
******* ######### ******/
.editable-wrapper .editable-data-wrapper {
  position: relative;
  display: inline-block;
  margin: 0px;
  border-radius: 2px;
  padding-right: 35px;
  transition: all 0.5s ease;
}
.editable-wrapper .editable-data-wrapper .editable-icon {
  position: absolute;
  bottom: -13px;
  right: 0px;
}
.editable-wrapper .editable-data-wrapper .editable-icon df {
  font-size: 20px;
}
.editable-wrapper .editable-data-wrapper:hover {
  background-color: #e8ecf1;
  padding: 12px 6px;
  padding-right: 36px;
}
.editable-wrapper .editable-data-wrapper:hover .editable-icon {
  bottom: -1px;
}
.editable-wrapper .titlechange-action {
  padding: 6px;
  border-radius: 2px;
  margin-top: 2px;
  /*border: 1px solid $base-5;*/
  color: var(--primary-color);
  font-weight: 700;
  box-shadow: 0px 3px 6px rgba(10, 10, 10, 0.1),
    0px 0px 1px rgba(10, 10, 10, 0.3);
  font-size: 18px;
}
.editable-wrapper .titlechange-action:hover {
  background-color: #f7f8fa;
}

/******* ######### ******
        chips
******* ######### ******/
.chips-one {
  position: relative;
  display: inline-block;
  padding: 0px 10px;
  padding-right: 24px;
  font-size: 12px;
  background: #f7f8fa;
  border: 1px solid #dbe1e8;
  border-radius: 12px;
  min-width: 10px;
  white-space: nowrap;
  line-height: 20px;
  margin-bottom: 5px;
  max-width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #2a333e;
}
.chips-one .close-this {
  position: absolute;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background: #dbe1e8;
  color: #7d8d9f;
  right: 1px;
  top: 1px;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  cursor: pointer;
}
.chips-one:hover {
  background: #4b5c72;
  border: 1px solid #4b5c72;
  color: #e8ecf1;
}
.chips-one:hover .close-this {
  background: #bcc6d6;
  color: #fff;
}

.chips-two {
  position: relative;
  display: inline;
  padding: 7px 25px;
  padding-left: 32px;
  font-size: 12px;
  background: #f7f8fa;
  border: 1px solid #dbe1e8;
  border-radius: 24px;
  min-width: 10px;
}
.chips-two .name-letter {
  position: absolute;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  color: #7d8d9f;
  background: #dbe1e8;
  border: 1px solid #dbe1e8;
  left: 2px;
  top: 2px;
  font-size: 12px;
  line-height: 25px;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
}
.chips-two .close-this {
  position: absolute;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  color: #7d8d9f;
  right: 1px;
  top: 5px;
  font-size: 13px;
  line-height: 22px;
  text-align: center;
  cursor: pointer;
}
.chips-two:hover {
  color: #f7f8fa;
  background: #4b5c72;
  border: 1px solid #4b5c72;
}
.chips-two:hover .name-letter {
  background: #bcc6d6;
  border: 1px solid #bcc6d6;
}
.chips-two:hover .close-this {
  color: #fff;
}

.chips {
  padding: 5px 12px;
  border-radius: 14px;
  font-size: 12px;
  border: 1px solid #e8ecf1;
  display: inline-block;
  color: #7d8d9f;
  -webkit-transition: 0.5s;
  transition: all 0.5s ease;
  position: relative;
  margin-right: 8px;
  margin-bottom: 5px;
}
.chips .close-icon {
  font-size: 16px;
  position: absolute;
  top: 4px;
  right: 6px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.5s;
  transition: all 0.5s ease;
  cursor: pointer;
}
.chips:hover {
  background-color: #f7f8fa;
  padding-right: 24px;
}
.chips:hover .close-icon {
  visibility: visible;
  opacity: 1;
  -webkit-transition: 0.5s;
  transition: all 0.5s ease;
}

.chip-square {
  border-radius: 2px;
  border: solid 1px #dbe1e8;
  background-color: #f7f8fa;
  padding: 2px 10px;
  font-size: 14px;
  color: #4b5c72;
  display: inline-block;
  margin-bottom: 5px;
}

.chip-square-2 {
  display: inline-block;
  vertical-align: top;
  margin: 0 10px 10px 0;
  border-radius: 2px;
  border: solid 1px #e8ecf1;
}
.chip-square-2 a {
  display: inline-block;
  background-color: #ffffff;
  padding: 8px 14px;
  font-size: 11px;
  line-height: 1;
  color: #7d8d9f;
}
.chip-square-2:last-child {
  margin: 0;
}

/******* ######### ******
        switchwes
******* ######### ******/
.db-switche [type="checkbox"] {
  display: none !important;
}
.db-switche [type="checkbox"]:checked,
.db-switche [type="checkbox"]:not(:checked) {
  position: relative;
  /*left: -9999px;
  opacity: 0;*/
}
.db-switche [type="checkbox"]:checked + label {
  color: #4b5c72 !important;
}
.db-switche [type="checkbox"]:checked + label,
.db-switche [type="checkbox"]:not(:checked) + label {
  position: relative;
  padding-left: 45px;
  cursor: pointer;
  display: inline-block;
  color: #4b5c72;
  font-weight: normal;
  top: 0px;
  left: -18px;
}
.db-switche [type="checkbox"]:checked + label:before,
.db-switche [type="checkbox"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0px;
  width: 36px;
  height: 19px;
  border-radius: 10px;
  background: #dbe1e8;
}
.db-switche [type="checkbox"]:checked + label:before {
  background: var(--primary-color);
}
.db-switche [type="checkbox"]:checked + label:after,
.db-switche [type="checkbox"]:not(:checked) + label:after {
  content: "";
  width: 15px;
  height: 15px;
  background: white;
  position: absolute;
  top: 2px;
  left: 19px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.db-switche [type="checkbox"]:not(:checked) + label:after {
  content: "";
  width: 15px;
  height: 15px;
  background: white;
  position: absolute;
  top: 2px;
  left: 2px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.db-switche [type="checkbox"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.db-switche [type="checkbox"]:disabled + label:before {
  background: #f7f8fa;
}
.db-switche.switch-right-align [type="checkbox"]:checked + label,
.db-switche.switch-right-align [type="checkbox"]:not(:checked) + label {
  padding-left: initial !important;
  padding-right: 42px;
}
.db-switche.switch-right-align [type="checkbox"]:checked + label:before,
.db-switche.switch-right-align [type="checkbox"]:not(:checked) + label:before {
  left: initial;
  right: 0px !important;
}
.db-switche.switch-right-align [type="checkbox"]:checked + label:after {
  top: 0px;
  left: initial;
  right: 2px !important;
}
.db-switche.label_left [type="checkbox"]:not(:checked) + label:after {
  left: initial;
  right: 18px !important;
}
.db-switche.label_left [type="checkbox"]:checked + label:before,
.db-switche.label_left [type="checkbox"]:not(:checked) + label:before {
  content: "";
  left: initial !important;
  top: 2px !important;
  right: 0px !important;
}
.db-switche.label_left [type="checkbox"]:checked + label,
.db-switche.label_left [type="checkbox"]:not(:checked) + label {
  padding-left: initial;
  padding-right: 42px;
  left: initial !important;
}
.db-switche.label_left [type="checkbox"]:checked + label:after,
.db-switche.label_left [type="checkbox"]:not(:checked) + label:after {
  left: initial;
  right: 3px;
  top: 4px;
}
.db-switche.left_0 [type="checkbox"]:checked + label,
.db-switche.left_0 [type="checkbox"]:not(:checked) + label {
  left: 0px !important;
}
.db-switche.appyl_reject_sw [type="checkbox"]:not(:checked) + label:before {
  background: #f44336 !important;
}

.db-switche.inline-block {
  margin-left: 10px;
}

.db-switche.inline-block:first-child {
  margin-left: 0px;
}

.db-switche-uppercase [type="checkbox"] + label {
  font-size: 12px;
  font-weight: 500 !important;
}

/******* ######### ******
      And Or switch
******* ######### ******/
.and_or_switch input[type="radio"] {
  display: none !important;
}
.and_or_switch input[type="radio"]:checked + label {
  color: #fff;
  border: solid 1px var(--primary-color);
  position: relative;
  background: var(--primary-color);
  box-shadow: 0 6px 10px 0 rgba(22, 118, 226, 0.3);
}
.and_or_switch label {
  border-radius: 2px;
  border: solid 1px #dbe1e8;
  display: inline-block;
  padding: 8px 15px;
  min-width: 80px;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  margin-right: -5px;
  text-transform: uppercase;
}

.multiselect-native-select button {
  line-height: none !important;
  padding: 5px 12px !important;
  min-height: 36px !important;
  max-height: 36px !important;
  transform: initial !important;
  min-width: 100% !important;
  padding-right: 30px !important;
  border: 1px solid #e6e6e6 !important;
  max-width: 100% !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: normal;
}
.multiselect-native-select button:hover {
  border-color: rgba(34, 36, 38, 0.35) !important;
}
.multiselect-native-select .btn-group.open button {
  border-color: rgba(34, 36, 38, 0.35) !important;
}
.multiselect-native-select .caret {
  opacity: 0.4;
  top: calc(50% + 10px);
  transform: translateY(-50%);
  right: 10px !important;
  border-top: 6px dashed #bcc6d6;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  position: absolute !important;
}
.multiselect-native-select .multiselect-container {
  padding: 3px 0px;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.24), 0 6px 10px 0 rgba(0, 0, 0, 0.08),
    0 1px 3px 0 rgba(0, 0, 0, 0.08) !important;
}
.multiselect-native-select .multiselect-container li a label {
  padding-top: 6px;
  padding-bottom: 6px;
}
.multiselect-native-select
  .multiselect-container
  li
  a
  label
  input[type="checkbox"] {
  margin-top: 2px;
}

/******* ######### ******
       dropdown
******* ######### ******/
.db-dropdown {
  display: block !important;
}
.db-dropdown.hidden {
  display: none !important;
}

.db-dropdown.ui.searchabledropdown {
  border: 1px solid #dbe1e8;
  border-radius: 2px !important;
  /*input.search:focus{
      outline: 0;
      border-color: $primary-blue;
      box-shadow: 0 3px 8px 0 rgba(22, 118, 226, 0.16) !important;
      -webkit-box-shadow: none;
  }*/
}
.db-dropdown.ui.searchabledropdown i.searchabledropdown.icon {
  color: #677789;
  opacity: 0.4;
  font-size: 20px;
  top: 50%;
  transform: translateY(-50%);
  line-height: 0;
  margin: 0;
  right: 10px;
  pointer-events: none;
}
.db-dropdown.ui.searchabledropdown i.searchabledropdown.icon:focus {
  outline: none !important;
}
.db-dropdown.ui.searchabledropdown.border-n {
  border: 1px solid transparent !important;
}
.db-dropdown.ui.searchabledropdown:hover {
  border: 1px solid #bcc6d6;
}
.db-dropdown.ui.searchabledropdown:hover .searchabledropdown.icon {
  opacity: 1;
}
.db-dropdown.ui.searchabledropdown .clearIcon {
  position: absolute;
  background: #fff;
  right: 2px;
  padding: 4px;
  z-index: 9;
  top: 3px;
  font-weight: 700;
  font-size: 18px;
  color: #bcc6d6;
}
.db-dropdown.ui.searchabledropdown .clearIcon:hover {
  color: #2a333e;
}
.db-dropdown.ui.searchabledropdown > .text {
  height: 17px;
  line-height: 16px;
  overflow: hidden;
  width: 100% !important;
}
.db-dropdown.ui.searchabledropdown > .text .sub-item {
  display: none;
}
.db-dropdown.ui.searchabledropdown.multiple > .text {
  top: 3px !important;
}
.db-dropdown.ui.searchabledropdown.disabled {
  background: #f7f8fa;
  opacity: 1;
}

.db-dropdown.ui.selection.searchabledropdown.db-dropdown-sm {
  min-height: 30px !important;
  max-height: 30px !important;
  padding: 7px 9px !important;
  font-size: 12px;
}

.db-dropdown.db-dropdown-sm .ui.searchabledropdown i.searchabledropdown.icon {
  font-size: 14px !important;
}

.db-dropdown.ui.selection.active.searchabledropdown .menu {
  border: none !important;
  padding: 4px 0;
  border-radius: 4px;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.24), 0 6px 10px 0 rgba(0, 0, 0, 0.08),
    0 1px 3px 0 rgba(0, 0, 0, 0.08) !important;
}

.db-dropdown.ui.selection.searchabledropdown {
  line-height: none !important;
  padding: 9px 12px 5px 12px !important;
  min-height: 36px !important;
  max-height: 36px !important;
  transform: initial !important;
  min-width: 100% !important;
  padding-right: 30px !important;
  border: 1px solid #bcc6d6;
  border-radius: 4px !important;
}
.db-dropdown.ui.selection.searchabledropdown:focus {
  border: 1px solid var(--primary-color) !important;
}
.db-dropdown.ui.selection.searchabledropdown.multiple {
  max-height: initial !important;
  height: auto;
}
.db-dropdown.ui.selection.searchabledropdown.multiple.tint-blue-chip > a.label {
  height: 28px !important;
  padding: 3px 12px !important;
  margin: 4px !important;
  background: #edf4fd !important;
  border: 1px solid #c4dcf7 !important;
  border-radius: 50px !important;
  color: #4b5c72;
  box-shadow: none;
}
.db-dropdown.ui.selection.searchabledropdown.multiple.tint-blue-chip
  > a.label
  i {
  vertical-align: middle !important;
}
.db-dropdown.ui.selection.searchabledropdown.multiple.tint-blue-chip
  > a.label
  i:after {
  content: "" !important;
  top: 0px;
  left: 9px;
  font-style: normal;
  width: 20px;
  height: 20px;
  display: inline-block;
  /* background-image: url("/images/Icons_latest/close-with-circle.svg"); */
}
.db-dropdown.ui.selection.searchabledropdown.filter-select {
  padding-left: 40px !important;
}
.db-dropdown.ui.selection.searchabledropdown.filter-select:before {
  content: "\e92a";
  position: absolute;
  left: 7px;
  font-family: "darwinbox" !important;
  font-size: 24px;
  color: #7d8d9f;
}
.db-dropdown.ui.selection.searchabledropdown .menu .header.optgroup {
  position: relative;
  text-align: center;
}
.db-dropdown.ui.selection.searchabledropdown .menu .header.optgroup span {
  background: #fff;
  padding: 5px 15px;
}
.db-dropdown.ui.selection.searchabledropdown .menu .header.optgroup:before {
  content: "";
  width: 100%;
  position: absolute;
  height: 1px;
  background: #ddd;
  left: 0;
  z-index: -1;
  top: 50%;
  transform: translateY(-50%);
}

.ui.multiple.search.dropdown > .text {
  padding: 1px 6px !important;
}

.ui.multiple.search.dropdown > input.search {
  margin: 0px !important;
  line-height: 1 !important;
  height: 26px;
}

.ui.multiple.search.dropdown > .text {
  padding: 1px 6px !important;
}

.ui.multiple.search.searchabledropdown > .text {
  padding: 1px 6px !important;
}

.db-dropdown.ui.selection.searchabledropdown .menu > .item .sub-item {
  font-size: 12px;
  color: #7d8d9f;
  margin-top: 4px;
}

.db-dropdown.ui.searchabledropdown .menu > .item {
  padding-right: 44px !important;
}
.db-dropdown.ui.searchabledropdown .menu > .item:hover {
  background: #e8ecf1 !important;
}

.db-dropdown.ui.searchabledropdown .menu .active.item {
  background: #f7f8fa;
  font-weight: 400 !important;
  padding-right: 40px !important;
}
.db-dropdown.ui.searchabledropdown .menu .active.item::after {
  content: "";
  border-right: 2.5px solid #1575e1;
  border-bottom: 2.5px solid #1575e1;
  position: absolute;
  height: 12px;
  width: 6px;
  transform: rotate(45deg);
  right: 16px;
  top: 11px;
}

.db-dropdown.ui.multiple.searchabledropdown > a.label {
  font-size: 12px !important;
  font-weight: 400 !important;
  background: #dbe1e8 !important;
  color: #2a333e !important;
  border-radius: 2px;
  height: auto !important;
  padding: 7px 8px !important;
  margin: 6px !important;
}
.db-dropdown.ui.multiple.searchabledropdown > a.label i {
  font-size: 10px;
}

.ui.selection.searchabledropdown .menu > .item a.ui.label i:before {
  content: unset !important;
}
.ui.selection.searchabledropdown .menu > .item a.ui.label i:after {
  content: "\e919";
  font-family: "darwinbox" !important;
  font-size: 16px;
  color: #4b5c72;
  position: relative;
  top: 3px;
  left: 4px;
  font-style: normal;
}

.ui.selection.searchabledropdown .menu > .item {
  border-top: none;
  font-size: 14px;
  word-wrap: break-word;
}

.ui.selection.active.searchabledropdown {
  /*border-color: none !important;
  box-shadow: none !important; */
  outline: 0;
  border-color: var(--primary-color);
  box-shadow: 0 3px 8px 0 rgba(22, 118, 226, 0.16) !important;
  -webkit-box-shadow: none;
  padding-right: 30px !important;
  /* input{
       outline: 0;
       border-color: $primary-blue;
       box-shadow: 0 3px 8px 0 rgba(22, 118, 226, 0.16) !important;
       -webkit-box-shadow: none;
   }*/
}

.db-dropdown.ui.searchabledropdown.multiple {
  padding: 5px 30px 4px 6px !important;
  max-height: max-content !important;
}
.db-dropdown.ui.searchabledropdown.multiple input.search:focus {
  box-shadow: none !important;
}
.db-dropdown.ui.searchabledropdown.multiple a.more-link-dropdown {
  color: var(--primary-color);
  font-weight: 400;
  font-size: 12px;
  cursor: pointer;
}

.search-select.db-dropdown.selection.multiple a.ui.label i:before {
  content: unset !important;
}
.search-select.db-dropdown.selection.multiple a.ui.label i:after {
  content: "\e919";
  font-family: "darwinbox" !important;
  font-size: 16px;
  color: #4b5c72;
  position: relative;
  top: 3px;
  left: 4px;
  font-style: normal;
}

.search-select.db-dropdown.selection.multiple a.ui.label i:before {
  content: unset !important;
}
.search-select.db-dropdown.selection.multiple a.ui.label i:after {
  content: "\e919";
  font-family: "darwinbox" !important;
  font-size: 16px;
  color: #4b5c72;
  position: relative;
  top: 3px;
  left: 4px;
  font-style: normal;
}

.more-name-show {
  cursor: pointer;
}

.btn-prev-page {
  font-family: Roboto;
  font-size: 12px;
  color: #7d8d9f;
  background: none;
  border: initial;
  transition: all 0.8s ease;
  padding: 1px 15px 8px 0px;
  border-radius: 25px;
}
.btn-prev-page df {
  font-size: 24px;
  position: relative;
  top: 7px;
  margin-right: 5px;
}
.btn-prev-page:hover {
  transition: all 0.8s ease;
  background: #e8ecf1;
  padding: 1px 15px 8px 10px;
}
.btn-prev-page:focus {
  outline: none;
}

/**************** ######### *************
  Bootstrap Dropdown in select formate
***************** ######### *************/
.select_dropdown .select_btn {
  height: 36px;
  border-radius: 2px;
  border: solid 1px #dbe1e8;
  background-color: #ffffff;
  padding: 0px 25px 0px 10px;
  line-height: 36px;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  color: #2a333e;
}
.select_dropdown .select_btn .caret {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
}
.select_dropdown .dropdown-menu {
  border: none;
}
.select_dropdown .dropdown-menu li a:hover {
  background-color: #f7f8fa !important;
  color: #2a333e !important;
}

/** new-alert-css */
.new-alert-danger {
  color: #4b5c72;
  background-color: #fff2f3;
  border-color: #ebccd1;
}

.db-alert-orange {
  padding: 12px 16px;
  font-size: 13px;
  color: #e07220;
  line-height: 1;
  background-color: #fff8e5;
  border-radius: 2px;
  border: dotted 2px #ffbb04;
}

/******* ######### ******
       alert badge
******* ######### ******/
.alert-badge {
  padding: 0px 20px 6px 0px;
  float: left;
}
.alert-badge .badge-icon {
  font-size: 30px;
  position: relative;
  width: fit-content;
}
.alert-badge .badge-icon .db-badge {
  box-sizing: border-box;
  min-width: 16px;
  height: 16px;
  line-height: 15px;
  padding: 0px 5px;
  text-align: center;
  border-radius: 30px;
  vertical-align: middle;
  background: #f44336;
  color: #ffffff;
  font-size: 10px;
  font-weight: 500;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  position: absolute;
  top: 10px;
  left: 14px;
}

.badge-icon {
  font-size: 30px;
  position: relative;
  width: fit-content;
}
.badge-icon .db-badge {
  box-sizing: border-box;
  min-width: 16px;
  height: 16px;
  line-height: 15px;
  padding: 0px 5px;
  text-align: center;
  border-radius: 30px;
  vertical-align: middle;
  background: #f44336;
  color: #ffffff;
  font-size: 10px;
  font-weight: 500;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  position: absolute;
  top: 10px;
  left: 14px;
}

/****** ######### ******
    Process badges
****** ######### ******/
.pbadge {
  font-size: 10px;
  background: #e8ecf1;
  padding: 5px 10px;
  border-radius: 14px;
}

.pon-going {
  background: #ffe6a5;
}

.pnot-start {
  background: #fbd0cd;
}

/****** ######### ******
    Global Search
****** ######### ******/
.dashboard-top {
  position: fixed;
  display: block;
  width: 100%;
  z-index: 101;
  top: 0px;
  background: #ffffff;
  height: 70px;
}
.dashboard-top .dashboard-icon {
  font-size: 24px;
  color: #fff;
  cursor: pointer;
}
.dashboard-top .dashboard-icon df {
  padding-right: 12px;
  cursor: pointer;
  line-height: 68px;
}
.dashboard-top .main-logo-admin {
  line-height: 58px;
  /*border-left: 1px solid rgba(255, 255, 255, 0.3);*/
  padding: 5px 10px;
}
.dashboard-top .main-logo-admin img {
  /* max-width: 150px; */
  /* max-height: 58px; */
  /*margin-left: 12px;*/
  width: 50%;
  position: absolute;
  top: -10%;
  left: 10%;
}

.search-header .searbar-data-wrapper {
  background-color: #fff;
  display: none;
  min-height: 10px;
  width: calc(100% - 20px);
  position: absolute;
  z-index: 9;
  border-radius: 2px;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.2), 0 30px 30px 0 rgba(0, 0, 0, 0.1);
  padding: 20px 0px;
}
.search-header .searbar-data-wrapper ul {
  padding: 0px;
  margin: 0px;
}
.search-header .searbar-data-wrapper ul li {
  list-style-type: none;
  padding: 10px 30px;
}
.search-header .searbar-data-wrapper ul li .page-name {
  font-size: 16px;
}
.search-header .searbar-data-wrapper ul li .arrow {
  font-size: 20px;
}
.search-header .searbar-data-wrapper ul li .main-type {
  font-size: 14px;
  padding-top: 2px;
}
.search-header .searbar-data-wrapper ul li a.action {
  text-decoration: none;
  color: var(--primary-color);
  font-size: 14px;
}

.searchbar-top-headre:before {
  content: "\e956";
  font-family: "darwinbox" !important;
  color: #fff;
  font-size: 28px;
  position: absolute;
  left: 20px;
  top: 1px;
}
.searchbar-top-headre input:hover {
  color: #2a333e !important;
  opacity: 0.7;
  transition: all 1s ease;
}
.searchbar-top-headre input:hover::-webkit-input-placeholder {
  color: #bcc6d6 !important;
}
.searchbar-top-headre input:focus {
  color: #2a333e !important;
  opacity: 1 !important;
  transition: all 1s ease;
}
.searchbar-top-headre input:focus::-webkit-input-placeholder {
  color: #bcc6d6 !important;
}
.searchbar-top-headre input:focus .searbar-data-wrapper {
  display: block;
}
.searchbar-top-headre input.global-search {
  height: 50px;
  padding-left: 48px;
  font-size: 16px;
  font-weight: normal;
  color: #fff;
  background-position: 16px 12px;
  background-size: 16px;
}
.searchbar-top-headre .searbar-data-wrapper {
  top: 41px;
}
.searchbar-top-headre.focusIcon:before {
  color: #bcc6d6;
}

.searchbar-home {
  position: relative;
  margin: 9px 0;
}
.searchbar-home:before {
  content: "\e956";
  font-family: "darwinbox" !important;
  color: #fff;
  font-size: 30px;
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
}
.searchbar-home input:focus {
  color: #4b5c72 !important;
  background-position: 24px 15px;
  background-size: 20px;
  opacity: 1 !important;
  background: #fff;
}
.searchbar-home input:focus::-webkit-input-placeholder {
  color: #bcc6d6 !important;
}
.searchbar-home input:focus .searbar-data-wrapper {
  display: block;
}
.searchbar-home .searbar-data-wrapper {
  top: 51px;
}
.searchbar-home.focusIcon:before {
  color: #bcc6d6;
}

input.global-search {
  border-radius: 2px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: none;
  border: 1px solid transparent;
  height: 50px;
  width: 100%;
  padding-left: 60px;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  background-position: 24px 15px;
  background-size: 20px;
  font-family: "Roboto", sans-serif;
  transition: none !important;
}

input.global-search:focus {
  outline: none;
}

.global-search::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #ffffff;
  font-weight: 400;
}

.global-search::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(255, 255, 255, 0.6588235294);
  font-weight: 400;
}

.global-search.global-search:-ms-input-placeholder {
  /* IE 10+ */
  color: rgba(255, 255, 255, 0.6588235294);
  font-weight: 400;
}

.global-search:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(255, 255, 255, 0.6588235294);
  font-weight: 400;
}

/****** ######### ******
   dashboard left menu bar
****** ######### ******/
#full-wrapper-dashboard-header {
  position: fixed;
  z-index: 9999;
  /* width: 100%; */
  /* height: 100vh; */
  /* background-color: rgba(0, 0, 0, 0.5); */
  top: 70px;
  left: 0;
  /* display: none; */
}
#full-wrapper-dashboard-header .admine-access-links {
  border-top: 1px solid #e8ecf1;
}
#full-wrapper-dashboard-header .notice-dashboard-wrapper {
  background-color: #fff;
  height: 100vh;
  width: 250px;
  position: relative;
  left: 0px;
  overflow: auto;
}
#full-wrapper-dashboard-header
  .notice-dashboard-wrapper
  .close-dashboard-header
  df {
  font-size: 24px;
  position: absolute;
  right: 25px;
  top: 25px;
  cursor: pointer;
  color: #bcc6d6;
}
#full-wrapper-dashboard-header
  .notice-dashboard-wrapper
  .each-type-notice-wrapper {
  border-bottom: 1px solid #e8ecf1;
}
#full-wrapper-dashboard-header
  .notice-dashboard-wrapper
  .each-type-notice-wrapper
  ul {
  padding: 0px;
  margin: 0px;
}
#full-wrapper-dashboard-header
  .notice-dashboard-wrapper
  .each-type-notice-wrapper
  ul
  li {
  list-style-type: none;
  padding: 0px 5px 0px 0px;
  display: inline-block;
}
#full-wrapper-dashboard-header
  .notice-dashboard-wrapper
  .each-type-notice-wrapper
  ul
  li
  img {
  border: 1px solid #dbe1e8;
  color: #dbe1e8;
  text-align: center;
  text-transform: uppercase;
  line-height: 30px;
  height: 30px;
  width: 30px;
  border-radius: 30px;
  background-color: #f7f8fa;
  cursor: pointer;
}
#full-wrapper-dashboard-header
  .notice-dashboard-wrapper
  .each-type-notice-wrapper
  ul
  li
  .img-name {
  border: 1px solid #dbe1e8;
  color: #7d8d9f;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  line-height: 30px;
  height: 30px;
  width: 30px;
  border-radius: 30px;
  background-color: #f7f8fa;
  cursor: pointer;
}
#full-wrapper-dashboard-header
  .notice-dashboard-wrapper
  .each-type-notice-wrapper
  .view-more-wrapper
  a {
  text-decoration: none;
  color: var(--primary-color);
  font-size: 12px;
  font-weight: normal;
}

/****** ######### ******
   Margins && Paddings
****** ######### ******/
.m-auto {
  margin: auto !important;
}

.m-0 {
  margin: 0px !important;
}

.m-2 {
  margin: 2px !important;
}

.m-4 {
  margin: 4px !important;
}

.m-8 {
  margin: 8px !important;
}

.m-12 {
  margin: 12px !important;
}

.m-16 {
  margin: 16px !important;
}

.m-24 {
  margin: 24px !important;
}

.m-32 {
  margin: 32px !important;
}

.m-40 {
  margin: 40px !important;
}

.m-50 {
  margin: 50px !important;
}

.m-78 {
  margin: 78px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-2 {
  margin-top: 2px !important;
}

.mt-4 {
  margin-top: 4px !important;
}

.mt-6 {
  margin-top: 6px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

.mt-32 {
  margin-top: 32px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-78 {
  margin-top: 78px !important;
}

.mtn-0 {
  margin-top: 0px !important;
}

.mtn-2 {
  margin-top: -2px !important;
}

.mtn-4 {
  margin-top: -4px !important;
}

.mtn-8 {
  margin-top: -8px !important;
}

.mtn-10 {
  margin-top: -10px !important;
}

.mtn-12 {
  margin-top: -12px !important;
}

.mtn-16 {
  margin-top: -16px !important;
}

.mtn-24 {
  margin-top: -24px !important;
}

.mtn-32 {
  margin-top: -32px !important;
}

.mtn-40 {
  margin-top: -40px !important;
}

.mtn-44 {
  margin-top: -44px !important;
}

.mtn-50 {
  margin-top: -50px !important;
}

.mtn-55 {
  margin-top: -55px !important;
}

.mtn-60 {
  margin-top: -60px !important;
}

.mtn-64 {
  margin-top: -64px !important;
}

.mtn-78 {
  margin-top: -78px !important;
}

.mbn-0 {
  margin-bottom: 0px !important;
}

.mbn-2 {
  margin-bottom: -2px !important;
}

.mbn-4 {
  margin-bottom: -4px !important;
}

.mbn-8 {
  margin-bottom: -8px !important;
}

.mbn-12 {
  margin-bottom: -12px !important;
}

.mbn-16 {
  margin-bottom: -16px !important;
}

.mbn-20 {
  margin-bottom: -20px !important;
}

.mbn-24 {
  margin-bottom: -24px !important;
}

.mbn-32 {
  margin-bottom: -32px !important;
}

.mbn-40 {
  margin-bottom: -40px !important;
}

.mbn-50 {
  margin-bottom: -50px !important;
}

.mbn-78 {
  margin-bottom: -78px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-2 {
  margin-bottom: 2px !important;
}

.mb-4 {
  margin-bottom: 4px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.mb-32 {
  margin-bottom: 32px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mb-150 {
  margin-bottom: 150px !important;
}

.mtb-0 {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

.mtb-2 {
  margin-bottom: 2px !important;
  margin-top: 2px !important;
}

.mtb-4 {
  margin-bottom: 4px !important;
  margin-top: 4px !important;
}

.mtb-8 {
  margin-bottom: 8px !important;
  margin-top: 8px !important;
}

.mtb-12 {
  margin-bottom: 12px !important;
  margin-top: 12px !important;
}

.mtb-16 {
  margin-bottom: 16px !important;
  margin-top: 16px !important;
}

.mtb-20 {
  margin-bottom: 20px !important;
  margin-top: 20px !important;
}

.mtb-24 {
  margin-bottom: 24px !important;
  margin-top: 24px !important;
}

.mtb-32 {
  margin-bottom: 32px !important;
  margin-top: 32px !important;
}

.mtb-40 {
  margin-bottom: 40px !important;
  margin-top: 40px !important;
}

.mtb-50 {
  margin-bottom: 50px !important;
  margin-top: 50px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.ml-2 {
  margin-left: 2px !important;
}

.ml-4 {
  margin-left: 4px !important;
}

.ml-8 {
  margin-left: 8px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-12 {
  margin-left: 12px !important;
}

.ml-16 {
  margin-left: 16px !important;
}

.ml-18 {
  margin-left: 18px !important;
}

.ml-24 {
  margin-left: 24px !important;
}

.ml-32 {
  margin-left: 32px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.ml-50 {
  margin-left: 50px !important;
}

.ml-60 {
  margin-left: 60px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mr-2 {
  margin-right: 2px !important;
}

.mr-4 {
  margin-right: 4px !important;
}

.mr-8 {
  margin-right: 8px !important;
}

.mr-12 {
  margin-right: 12px !important;
}

.mr-16 {
  margin-right: 16px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-24 {
  margin-right: 24px !important;
}

.mr-32 {
  margin-right: 32px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.mr-50 {
  margin-right: 50px !important;
}

.mlr-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.mlr-2 {
  margin-left: 2px !important;
  margin-right: 2px !important;
}

.mlr-4 {
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.mlr-8 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.mlr-10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.mlr-12 {
  margin-left: 12px !important;
  margin-right: 12px !important;
}

.mlr-16 {
  margin-left: 16px !important;
  margin-right: 16px !important;
}

.mlr-20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.mlr-24 {
  margin-left: 24px !important;
  margin-right: 24px !important;
}

.mlr-32 {
  margin-left: 32px !important;
  margin-right: 32px !important;
}

.mlr-40 {
  margin-left: 40px !important;
  margin-right: 40px !important;
}

.mlr-40 {
  margin-left: 40px !important;
  margin-right: 40px !important;
}

.mlr-50 {
  margin-left: 50px !important;
  margin-right: 50px !important;
}

.p-0 {
  padding: 0px !important;
}

.p-2 {
  padding: 2px !important;
}

.p-4 {
  padding: 4px !important;
}

.p-8 {
  padding: 8px !important;
}

.p-12 {
  padding: 12px !important;
}

.p-16 {
  padding: 16px !important;
}

.p-20 {
  padding: 20px !important;
}

.p-24 {
  padding: 24px !important;
}

.p-32 {
  padding: 32px !important;
}

.p-50 {
  padding: 40px !important;
}

.p-50 {
  padding: 50px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pt-2 {
  padding-top: 2px !important;
}

.pt-4 {
  padding-top: 4px !important;
}

.pt-8 {
  padding-top: 8px !important;
}

.pt-12 {
  padding-top: 12px !important;
}

.pt-16 {
  padding-top: 16px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-24 {
  padding-top: 24px !important;
}

.pt-32 {
  padding-top: 32px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.pt-68 {
  padding-top: 68px !important;
}

.pt-78 {
  padding-top: 78px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pb-2 {
  padding-bottom: 2px !important;
}

.pb-4 {
  padding-bottom: 4px !important;
}

.pb-8 {
  padding-bottom: 8px !important;
}

.pb-12 {
  padding-bottom: 12px !important;
}

.pb-16 {
  padding-bottom: 16px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-24 {
  padding-bottom: 24px !important;
}

.pb-32 {
  padding-bottom: 32px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pb-65 {
  padding-bottom: 65px !important;
}

.ptb-0 {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

.ptb-2 {
  padding-bottom: 2px !important;
  padding-top: 2px !important;
}

.ptb-4 {
  padding-bottom: 4px !important;
  padding-top: 4px !important;
}

.ptb-8 {
  padding-bottom: 8px !important;
  padding-top: 8px !important;
}

.ptb-12 {
  padding-bottom: 12px !important;
  padding-top: 12px !important;
}

.ptb-16 {
  padding-bottom: 16px !important;
  padding-top: 16px !important;
}

.ptb-20 {
  padding-bottom: 20px !important;
  padding-top: 20px !important;
}

.ptb-24 {
  padding-bottom: 24px !important;
  padding-top: 24px !important;
}

.ptb-32 {
  padding-bottom: 32px !important;
  padding-top: 32px !important;
}

.ptb-40 {
  padding-bottom: 40px !important;
  padding-top: 40px !important;
}

.ptb-50 {
  padding-bottom: 50px !important;
  padding-top: 50px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.pl-2 {
  padding-left: 2px !important;
}

.pl-4 {
  padding-left: 4px !important;
}

.pl-8 {
  padding-left: 8px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pl-12 {
  padding-left: 12px !important;
}

.pl-16 {
  padding-left: 16px !important;
}

.pl-24 {
  padding-left: 24px !important;
}

.pl-32 {
  padding-left: 32px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

.pl-50 {
  padding-left: 50px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.pr-2 {
  padding-right: 2px !important;
}

.pr-4 {
  padding-right: 4px !important;
}

.pr-8 {
  padding-right: 8px !important;
}

.pr-12 {
  padding-right: 12px !important;
}

.pr-16 {
  padding-right: 16px !important;
}

.pr-24 {
  padding-right: 24px !important;
}

.pr-32 {
  padding-right: 32px !important;
}

.pr-40 {
  padding-right: 40px !important;
}

.pr-50 {
  padding-right: 50px !important;
}

.plr-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.plr-2 {
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.plr-4 {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.plr-8 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.plr-12 {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.plr-16 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.plr-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.plr-24 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.plr-32 {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.plr-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.plr-50 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.br-1 {
  border-radius: 1px !important;
}

.br-2 {
  border-radius: 2px !important;
}

.br-3 {
  border-radius: 3px !important;
}

.br-4 {
  border-radius: 4px !important;
}

.br-5 {
  border-radius: 5px !important;
}

.br-6 {
  border-radius: 6px !important;
}

.br-7 {
  border-radius: 7px !important;
}

.br-8 {
  border-radius: 8px !important;
}

.br-9 {
  border-radius: 9px !important;
}

.br-10 {
  border-radius: 10px !important;
}

.br-11 {
  border-radius: 11px !important;
}

.br-12 {
  border-radius: 12px !important;
}

.br-13 {
  border-radius: 13px !important;
}

.br-14 {
  border-radius: 14px !important;
}

.br-15 {
  border-radius: 15px !important;
}

.br-16 {
  border-radius: 16px !important;
}

.br-17 {
  border-radius: 17px !important;
}

.br-18 {
  border-radius: 18px !important;
}

.br-19 {
  border-radius: 19px !important;
}

.br-20 {
  border-radius: 20px !important;
}

.br-21 {
  border-radius: 21px !important;
}

.br-22 {
  border-radius: 22px !important;
}

.br-23 {
  border-radius: 23px !important;
}

.br-24 {
  border-radius: 24px !important;
}

.br-25 {
  border-radius: 25px !important;
}

.br-26 {
  border-radius: 26px !important;
}

.br-27 {
  border-radius: 27px !important;
}

.br-28 {
  border-radius: 28px !important;
}

.br-29 {
  border-radius: 29px !important;
}

.br-30 {
  border-radius: 30px !important;
}

.br-31 {
  border-radius: 31px !important;
}

.br-32 {
  border-radius: 32px !important;
}

.br-33 {
  border-radius: 33px !important;
}

.br-34 {
  border-radius: 34px !important;
}

.br-35 {
  border-radius: 35px !important;
}

.br-36 {
  border-radius: 36px !important;
}

.br-37 {
  border-radius: 37px !important;
}

.br-38 {
  border-radius: 38px !important;
}

.br-39 {
  border-radius: 39px !important;
}

.br-40 {
  border-radius: 40px !important;
}

.br-41 {
  border-radius: 41px !important;
}

.br-42 {
  border-radius: 42px !important;
}

.br-43 {
  border-radius: 43px !important;
}

.br-44 {
  border-radius: 44px !important;
}

.br-45 {
  border-radius: 45px !important;
}

.br-46 {
  border-radius: 46px !important;
}

.br-47 {
  border-radius: 47px !important;
}

.br-48 {
  border-radius: 48px !important;
}

.br-49 {
  border-radius: 49px !important;
}

.br-50 {
  border-radius: 50px !important;
}

.br-51 {
  border-radius: 51px !important;
}

.br-52 {
  border-radius: 52px !important;
}

.br-53 {
  border-radius: 53px !important;
}

.br-54 {
  border-radius: 54px !important;
}

.br-55 {
  border-radius: 55px !important;
}

.br-56 {
  border-radius: 56px !important;
}

.br-57 {
  border-radius: 57px !important;
}

.br-58 {
  border-radius: 58px !important;
}

.br-59 {
  border-radius: 59px !important;
}

.br-60 {
  border-radius: 60px !important;
}

.br-61 {
  border-radius: 61px !important;
}

.br-62 {
  border-radius: 62px !important;
}

.br-63 {
  border-radius: 63px !important;
}

.br-64 {
  border-radius: 64px !important;
}

.br-65 {
  border-radius: 65px !important;
}

.br-66 {
  border-radius: 66px !important;
}

.br-67 {
  border-radius: 67px !important;
}

.br-68 {
  border-radius: 68px !important;
}

.br-69 {
  border-radius: 69px !important;
}

.br-70 {
  border-radius: 70px !important;
}

.br-71 {
  border-radius: 71px !important;
}

.br-72 {
  border-radius: 72px !important;
}

.br-73 {
  border-radius: 73px !important;
}

.br-74 {
  border-radius: 74px !important;
}

.br-75 {
  border-radius: 75px !important;
}

.br-76 {
  border-radius: 76px !important;
}

.br-77 {
  border-radius: 77px !important;
}

.br-78 {
  border-radius: 78px !important;
}

.br-79 {
  border-radius: 79px !important;
}

.br-80 {
  border-radius: 80px !important;
}

.br-81 {
  border-radius: 81px !important;
}

.br-82 {
  border-radius: 82px !important;
}

.br-83 {
  border-radius: 83px !important;
}

.br-84 {
  border-radius: 84px !important;
}

.br-85 {
  border-radius: 85px !important;
}

.br-86 {
  border-radius: 86px !important;
}

.br-87 {
  border-radius: 87px !important;
}

.br-88 {
  border-radius: 88px !important;
}

.br-89 {
  border-radius: 89px !important;
}

.br-90 {
  border-radius: 90px !important;
}

.br-91 {
  border-radius: 91px !important;
}

.br-92 {
  border-radius: 92px !important;
}

.br-93 {
  border-radius: 93px !important;
}

.br-94 {
  border-radius: 94px !important;
}

.br-95 {
  border-radius: 95px !important;
}

.br-96 {
  border-radius: 96px !important;
}

.br-97 {
  border-radius: 97px !important;
}

.br-98 {
  border-radius: 98px !important;
}

.br-99 {
  border-radius: 99px !important;
}

.br-100 {
  border-radius: 100px !important;
}

.br-101 {
  border-radius: 101px !important;
}

.br-102 {
  border-radius: 102px !important;
}

.br-103 {
  border-radius: 103px !important;
}

.br-104 {
  border-radius: 104px !important;
}

.br-105 {
  border-radius: 105px !important;
}

.br-106 {
  border-radius: 106px !important;
}

.br-107 {
  border-radius: 107px !important;
}

.br-108 {
  border-radius: 108px !important;
}

.br-109 {
  border-radius: 109px !important;
}

.br-110 {
  border-radius: 110px !important;
}

.br-111 {
  border-radius: 111px !important;
}

.br-112 {
  border-radius: 112px !important;
}

.br-113 {
  border-radius: 113px !important;
}

.br-114 {
  border-radius: 114px !important;
}

.br-115 {
  border-radius: 115px !important;
}

.br-116 {
  border-radius: 116px !important;
}

.br-117 {
  border-radius: 117px !important;
}

.br-118 {
  border-radius: 118px !important;
}

.br-119 {
  border-radius: 119px !important;
}

.br-120 {
  border-radius: 120px !important;
}

.br-121 {
  border-radius: 121px !important;
}

.br-122 {
  border-radius: 122px !important;
}

.br-123 {
  border-radius: 123px !important;
}

.br-124 {
  border-radius: 124px !important;
}

.br-125 {
  border-radius: 125px !important;
}

.br-126 {
  border-radius: 126px !important;
}

.br-127 {
  border-radius: 127px !important;
}

.br-128 {
  border-radius: 128px !important;
}

.br-129 {
  border-radius: 129px !important;
}

.br-130 {
  border-radius: 130px !important;
}

.br-131 {
  border-radius: 131px !important;
}

.br-132 {
  border-radius: 132px !important;
}

.br-133 {
  border-radius: 133px !important;
}

.br-134 {
  border-radius: 134px !important;
}

.br-135 {
  border-radius: 135px !important;
}

.br-136 {
  border-radius: 136px !important;
}

.br-137 {
  border-radius: 137px !important;
}

.br-138 {
  border-radius: 138px !important;
}

.br-139 {
  border-radius: 139px !important;
}

.br-140 {
  border-radius: 140px !important;
}

.br-141 {
  border-radius: 141px !important;
}

.br-142 {
  border-radius: 142px !important;
}

.br-143 {
  border-radius: 143px !important;
}

.br-144 {
  border-radius: 144px !important;
}

.br-145 {
  border-radius: 145px !important;
}

.br-146 {
  border-radius: 146px !important;
}

.br-147 {
  border-radius: 147px !important;
}

.br-148 {
  border-radius: 148px !important;
}

.br-149 {
  border-radius: 149px !important;
}

.br-150 {
  border-radius: 150px !important;
}

.w-1 {
  width: 1px !important;
}

.w-2 {
  width: 2px !important;
}

.w-3 {
  width: 3px !important;
}

.w-4 {
  width: 4px !important;
}

.w-5 {
  width: 5px !important;
}

.w-6 {
  width: 6px !important;
}

.w-7 {
  width: 7px !important;
}

.w-8 {
  width: 8px !important;
}

.w-9 {
  width: 9px !important;
}

.w-10 {
  width: 10px !important;
}

.w-11 {
  width: 11px !important;
}

.w-12 {
  width: 12px !important;
}

.w-13 {
  width: 13px !important;
}

.w-14 {
  width: 14px !important;
}

.w-15 {
  width: 15px !important;
}

.w-16 {
  width: 16px !important;
}

.w-17 {
  width: 17px !important;
}

.w-18 {
  width: 18px !important;
}

.w-19 {
  width: 19px !important;
}

.w-20 {
  width: 20px !important;
}

.w-21 {
  width: 21px !important;
}

.w-22 {
  width: 22px !important;
}

.w-23 {
  width: 23px !important;
}

.w-24 {
  width: 24px !important;
}

.w-25 {
  width: 25px !important;
}

.w-26 {
  width: 26px !important;
}

.w-27 {
  width: 27px !important;
}

.w-28 {
  width: 28px !important;
}

.w-29 {
  width: 29px !important;
}

.w-30 {
  width: 30px !important;
}

.w-31 {
  width: 31px !important;
}

.w-32 {
  width: 32px !important;
}

.w-33 {
  width: 33px !important;
}

.w-34 {
  width: 34px !important;
}

.w-35 {
  width: 35px !important;
}

.w-36 {
  width: 36px !important;
}

.w-37 {
  width: 37px !important;
}

.w-38 {
  width: 38px !important;
}

.w-39 {
  width: 39px !important;
}

.w-40 {
  width: 40px !important;
}

.w-41 {
  width: 41px !important;
}

.w-42 {
  width: 42px !important;
}

.w-43 {
  width: 43px !important;
}

.w-44 {
  width: 44px !important;
}

.w-45 {
  width: 45px !important;
}

.w-46 {
  width: 46px !important;
}

.w-47 {
  width: 47px !important;
}

.w-48 {
  width: 48px !important;
}

.w-49 {
  width: 49px !important;
}

.w-50 {
  width: 50px !important;
}

.w-51 {
  width: 51px !important;
}

.w-52 {
  width: 52px !important;
}

.w-53 {
  width: 53px !important;
}

.w-54 {
  width: 54px !important;
}

.w-55 {
  width: 55px !important;
}

.w-56 {
  width: 56px !important;
}

.w-57 {
  width: 57px !important;
}

.w-58 {
  width: 58px !important;
}

.w-59 {
  width: 59px !important;
}

.w-60 {
  width: 60px !important;
}

.w-61 {
  width: 61px !important;
}

.w-62 {
  width: 62px !important;
}

.w-63 {
  width: 63px !important;
}

.w-64 {
  width: 64px !important;
}

.w-65 {
  width: 65px !important;
}

.w-66 {
  width: 66px !important;
}

.w-67 {
  width: 67px !important;
}

.w-68 {
  width: 68px !important;
}

.w-69 {
  width: 69px !important;
}

.w-70 {
  width: 70px !important;
}

.w-71 {
  width: 71px !important;
}

.w-72 {
  width: 72px !important;
}

.w-73 {
  width: 73px !important;
}

.w-74 {
  width: 74px !important;
}

.w-75 {
  width: 75px !important;
}

.w-76 {
  width: 76px !important;
}

.w-77 {
  width: 77px !important;
}

.w-78 {
  width: 78px !important;
}

.w-79 {
  width: 79px !important;
}

.w-80 {
  width: 80px !important;
}

.w-81 {
  width: 81px !important;
}

.w-82 {
  width: 82px !important;
}

.w-83 {
  width: 83px !important;
}

.w-84 {
  width: 84px !important;
}

.w-85 {
  width: 85px !important;
}

.w-86 {
  width: 86px !important;
}

.w-87 {
  width: 87px !important;
}

.w-88 {
  width: 88px !important;
}

.w-89 {
  width: 89px !important;
}

.w-90 {
  width: 90px !important;
}

.w-91 {
  width: 91px !important;
}

.w-92 {
  width: 92px !important;
}

.w-93 {
  width: 93px !important;
}

.w-94 {
  width: 94px !important;
}

.w-95 {
  width: 95px !important;
}

.w-96 {
  width: 96px !important;
}

.w-97 {
  width: 97px !important;
}

.w-98 {
  width: 98px !important;
}

.w-99 {
  width: 99px !important;
}

.w-100 {
  width: 100px !important;
}

.w-101 {
  width: 101px !important;
}

.w-102 {
  width: 102px !important;
}

.w-103 {
  width: 103px !important;
}

.w-104 {
  width: 104px !important;
}

.w-105 {
  width: 105px !important;
}

.w-106 {
  width: 106px !important;
}

.w-107 {
  width: 107px !important;
}

.w-108 {
  width: 108px !important;
}

.w-109 {
  width: 109px !important;
}

.w-110 {
  width: 110px !important;
}

.w-111 {
  width: 111px !important;
}

.w-112 {
  width: 112px !important;
}

.w-113 {
  width: 113px !important;
}

.w-114 {
  width: 114px !important;
}

.w-115 {
  width: 115px !important;
}

.w-116 {
  width: 116px !important;
}

.w-117 {
  width: 117px !important;
}

.w-118 {
  width: 118px !important;
}

.w-119 {
  width: 119px !important;
}

.w-120 {
  width: 120px !important;
}

.w-121 {
  width: 121px !important;
}

.w-122 {
  width: 122px !important;
}

.w-123 {
  width: 123px !important;
}

.w-124 {
  width: 124px !important;
}

.w-125 {
  width: 125px !important;
}

.w-126 {
  width: 126px !important;
}

.w-127 {
  width: 127px !important;
}

.w-128 {
  width: 128px !important;
}

.w-129 {
  width: 129px !important;
}

.w-130 {
  width: 130px !important;
}

.w-131 {
  width: 131px !important;
}

.w-132 {
  width: 132px !important;
}

.w-133 {
  width: 133px !important;
}

.w-134 {
  width: 134px !important;
}

.w-135 {
  width: 135px !important;
}

.w-136 {
  width: 136px !important;
}

.w-137 {
  width: 137px !important;
}

.w-138 {
  width: 138px !important;
}

.w-139 {
  width: 139px !important;
}

.w-140 {
  width: 140px !important;
}

.w-141 {
  width: 141px !important;
}

.w-142 {
  width: 142px !important;
}

.w-143 {
  width: 143px !important;
}

.w-144 {
  width: 144px !important;
}

.w-145 {
  width: 145px !important;
}

.w-146 {
  width: 146px !important;
}

.w-147 {
  width: 147px !important;
}

.w-148 {
  width: 148px !important;
}

.w-149 {
  width: 149px !important;
}

.w-150 {
  width: 150px !important;
}

.mw-1 {
  max-width: 1px !important;
}

.mw-2 {
  max-width: 2px !important;
}

.mw-3 {
  max-width: 3px !important;
}

.mw-4 {
  max-width: 4px !important;
}

.mw-5 {
  max-width: 5px !important;
}

.mw-6 {
  max-width: 6px !important;
}

.mw-7 {
  max-width: 7px !important;
}

.mw-8 {
  max-width: 8px !important;
}

.mw-9 {
  max-width: 9px !important;
}

.mw-10 {
  max-width: 10px !important;
}

.mw-11 {
  max-width: 11px !important;
}

.mw-12 {
  max-width: 12px !important;
}

.mw-13 {
  max-width: 13px !important;
}

.mw-14 {
  max-width: 14px !important;
}

.mw-15 {
  max-width: 15px !important;
}

.mw-16 {
  max-width: 16px !important;
}

.mw-17 {
  max-width: 17px !important;
}

.mw-18 {
  max-width: 18px !important;
}

.mw-19 {
  max-width: 19px !important;
}

.mw-20 {
  max-width: 20px !important;
}

.mw-21 {
  max-width: 21px !important;
}

.mw-22 {
  max-width: 22px !important;
}

.mw-23 {
  max-width: 23px !important;
}

.mw-24 {
  max-width: 24px !important;
}

.mw-25 {
  max-width: 25px !important;
}

.mw-26 {
  max-width: 26px !important;
}

.mw-27 {
  max-width: 27px !important;
}

.mw-28 {
  max-width: 28px !important;
}

.mw-29 {
  max-width: 29px !important;
}

.mw-30 {
  max-width: 30px !important;
}

.mw-31 {
  max-width: 31px !important;
}

.mw-32 {
  max-width: 32px !important;
}

.mw-33 {
  max-width: 33px !important;
}

.mw-34 {
  max-width: 34px !important;
}

.mw-35 {
  max-width: 35px !important;
}

.mw-36 {
  max-width: 36px !important;
}

.mw-37 {
  max-width: 37px !important;
}

.mw-38 {
  max-width: 38px !important;
}

.mw-39 {
  max-width: 39px !important;
}

.mw-40 {
  max-width: 40px !important;
}

.mw-41 {
  max-width: 41px !important;
}

.mw-42 {
  max-width: 42px !important;
}

.mw-43 {
  max-width: 43px !important;
}

.mw-44 {
  max-width: 44px !important;
}

.mw-45 {
  max-width: 45px !important;
}

.mw-46 {
  max-width: 46px !important;
}

.mw-47 {
  max-width: 47px !important;
}

.mw-48 {
  max-width: 48px !important;
}

.mw-49 {
  max-width: 49px !important;
}

.mw-50 {
  max-width: 50px !important;
}

.mw-51 {
  max-width: 51px !important;
}

.mw-52 {
  max-width: 52px !important;
}

.mw-53 {
  max-width: 53px !important;
}

.mw-54 {
  max-width: 54px !important;
}

.mw-55 {
  max-width: 55px !important;
}

.mw-56 {
  max-width: 56px !important;
}

.mw-57 {
  max-width: 57px !important;
}

.mw-58 {
  max-width: 58px !important;
}

.mw-59 {
  max-width: 59px !important;
}

.mw-60 {
  max-width: 60px !important;
}

.mw-61 {
  max-width: 61px !important;
}

.mw-62 {
  max-width: 62px !important;
}

.mw-63 {
  max-width: 63px !important;
}

.mw-64 {
  max-width: 64px !important;
}

.mw-65 {
  max-width: 65px !important;
}

.mw-66 {
  max-width: 66px !important;
}

.mw-67 {
  max-width: 67px !important;
}

.mw-68 {
  max-width: 68px !important;
}

.mw-69 {
  max-width: 69px !important;
}

.mw-70 {
  max-width: 70px !important;
}

.mw-71 {
  max-width: 71px !important;
}

.mw-72 {
  max-width: 72px !important;
}

.mw-73 {
  max-width: 73px !important;
}

.mw-74 {
  max-width: 74px !important;
}

.mw-75 {
  max-width: 75px !important;
}

.mw-76 {
  max-width: 76px !important;
}

.mw-77 {
  max-width: 77px !important;
}

.mw-78 {
  max-width: 78px !important;
}

.mw-79 {
  max-width: 79px !important;
}

.mw-80 {
  max-width: 80px !important;
}

.mw-81 {
  max-width: 81px !important;
}

.mw-82 {
  max-width: 82px !important;
}

.mw-83 {
  max-width: 83px !important;
}

.mw-84 {
  max-width: 84px !important;
}

.mw-85 {
  max-width: 85px !important;
}

.mw-86 {
  max-width: 86px !important;
}

.mw-87 {
  max-width: 87px !important;
}

.mw-88 {
  max-width: 88px !important;
}

.mw-89 {
  max-width: 89px !important;
}

.mw-90 {
  max-width: 90px !important;
}

.mw-91 {
  max-width: 91px !important;
}

.mw-92 {
  max-width: 92px !important;
}

.mw-93 {
  max-width: 93px !important;
}

.mw-94 {
  max-width: 94px !important;
}

.mw-95 {
  max-width: 95px !important;
}

.mw-96 {
  max-width: 96px !important;
}

.mw-97 {
  max-width: 97px !important;
}

.mw-98 {
  max-width: 98px !important;
}

.mw-99 {
  max-width: 99px !important;
}

.mw-100 {
  max-width: 100px !important;
}

.mw-101 {
  max-width: 101px !important;
}

.mw-102 {
  max-width: 102px !important;
}

.mw-103 {
  max-width: 103px !important;
}

.mw-104 {
  max-width: 104px !important;
}

.mw-105 {
  max-width: 105px !important;
}

.mw-106 {
  max-width: 106px !important;
}

.mw-107 {
  max-width: 107px !important;
}

.mw-108 {
  max-width: 108px !important;
}

.mw-109 {
  max-width: 109px !important;
}

.mw-110 {
  max-width: 110px !important;
}

.mw-111 {
  max-width: 111px !important;
}

.mw-112 {
  max-width: 112px !important;
}

.mw-113 {
  max-width: 113px !important;
}

.mw-114 {
  max-width: 114px !important;
}

.mw-115 {
  max-width: 115px !important;
}

.mw-116 {
  max-width: 116px !important;
}

.mw-117 {
  max-width: 117px !important;
}

.mw-118 {
  max-width: 118px !important;
}

.mw-119 {
  max-width: 119px !important;
}

.mw-120 {
  max-width: 120px !important;
}

.mw-121 {
  max-width: 121px !important;
}

.mw-122 {
  max-width: 122px !important;
}

.mw-123 {
  max-width: 123px !important;
}

.mw-124 {
  max-width: 124px !important;
}

.mw-125 {
  max-width: 125px !important;
}

.mw-126 {
  max-width: 126px !important;
}

.mw-127 {
  max-width: 127px !important;
}

.mw-128 {
  max-width: 128px !important;
}

.mw-129 {
  max-width: 129px !important;
}

.mw-130 {
  max-width: 130px !important;
}

.mw-131 {
  max-width: 131px !important;
}

.mw-132 {
  max-width: 132px !important;
}

.mw-133 {
  max-width: 133px !important;
}

.mw-134 {
  max-width: 134px !important;
}

.mw-135 {
  max-width: 135px !important;
}

.mw-136 {
  max-width: 136px !important;
}

.mw-137 {
  max-width: 137px !important;
}

.mw-138 {
  max-width: 138px !important;
}

.mw-139 {
  max-width: 139px !important;
}

.mw-140 {
  max-width: 140px !important;
}

.mw-141 {
  max-width: 141px !important;
}

.mw-142 {
  max-width: 142px !important;
}

.mw-143 {
  max-width: 143px !important;
}

.mw-144 {
  max-width: 144px !important;
}

.mw-145 {
  max-width: 145px !important;
}

.mw-146 {
  max-width: 146px !important;
}

.mw-147 {
  max-width: 147px !important;
}

.mw-148 {
  max-width: 148px !important;
}

.mw-149 {
  max-width: 149px !important;
}

.mw-150 {
  max-width: 150px !important;
}

.mw-1-p {
  max-width: 1% !important;
}

.mw-2-p {
  max-width: 2% !important;
}

.mw-3-p {
  max-width: 3% !important;
}

.mw-4-p {
  max-width: 4% !important;
}

.mw-5-p {
  max-width: 5% !important;
}

.mw-6-p {
  max-width: 6% !important;
}

.mw-7-p {
  max-width: 7% !important;
}

.mw-8-p {
  max-width: 8% !important;
}

.mw-9-p {
  max-width: 9% !important;
}

.mw-10-p {
  max-width: 10% !important;
}

.mw-11-p {
  max-width: 11% !important;
}

.mw-12-p {
  max-width: 12% !important;
}

.mw-13-p {
  max-width: 13% !important;
}

.mw-14-p {
  max-width: 14% !important;
}

.mw-15-p {
  max-width: 15% !important;
}

.mw-16-p {
  max-width: 16% !important;
}

.mw-17-p {
  max-width: 17% !important;
}

.mw-18-p {
  max-width: 18% !important;
}

.mw-19-p {
  max-width: 19% !important;
}

.mw-20-p {
  max-width: 20% !important;
}

.mw-21-p {
  max-width: 21% !important;
}

.mw-22-p {
  max-width: 22% !important;
}

.mw-23-p {
  max-width: 23% !important;
}

.mw-24-p {
  max-width: 24% !important;
}

.mw-25-p {
  max-width: 25% !important;
}

.mw-26-p {
  max-width: 26% !important;
}

.mw-27-p {
  max-width: 27% !important;
}

.mw-28-p {
  max-width: 28% !important;
}

.mw-29-p {
  max-width: 29% !important;
}

.mw-30-p {
  max-width: 30% !important;
}

.mw-31-p {
  max-width: 31% !important;
}

.mw-32-p {
  max-width: 32% !important;
}

.mw-33-p {
  max-width: 33% !important;
}

.mw-34-p {
  max-width: 34% !important;
}

.mw-35-p {
  max-width: 35% !important;
}

.mw-36-p {
  max-width: 36% !important;
}

.mw-37-p {
  max-width: 37% !important;
}

.mw-38-p {
  max-width: 38% !important;
}

.mw-39-p {
  max-width: 39% !important;
}

.mw-40-p {
  max-width: 40% !important;
}

.mw-41-p {
  max-width: 41% !important;
}

.mw-42-p {
  max-width: 42% !important;
}

.mw-43-p {
  max-width: 43% !important;
}

.mw-44-p {
  max-width: 44% !important;
}

.mw-45-p {
  max-width: 45% !important;
}

.mw-46-p {
  max-width: 46% !important;
}

.mw-47-p {
  max-width: 47% !important;
}

.mw-48-p {
  max-width: 48% !important;
}

.mw-49-p {
  max-width: 49% !important;
}

.mw-50-p {
  max-width: 50% !important;
}

.mw-51-p {
  max-width: 51% !important;
}

.mw-52-p {
  max-width: 52% !important;
}

.mw-53-p {
  max-width: 53% !important;
}

.mw-54-p {
  max-width: 54% !important;
}

.mw-55-p {
  max-width: 55% !important;
}

.mw-56-p {
  max-width: 56% !important;
}

.mw-57-p {
  max-width: 57% !important;
}

.mw-58-p {
  max-width: 58% !important;
}

.mw-59-p {
  max-width: 59% !important;
}

.mw-60-p {
  max-width: 60% !important;
}

.mw-61-p {
  max-width: 61% !important;
}

.mw-62-p {
  max-width: 62% !important;
}

.mw-63-p {
  max-width: 63% !important;
}

.mw-64-p {
  max-width: 64% !important;
}

.mw-65-p {
  max-width: 65% !important;
}

.mw-66-p {
  max-width: 66% !important;
}

.mw-67-p {
  max-width: 67% !important;
}

.mw-68-p {
  max-width: 68% !important;
}

.mw-69-p {
  max-width: 69% !important;
}

.mw-70-p {
  max-width: 70% !important;
}

.mw-71-p {
  max-width: 71% !important;
}

.mw-72-p {
  max-width: 72% !important;
}

.mw-73-p {
  max-width: 73% !important;
}

.mw-74-p {
  max-width: 74% !important;
}

.mw-75-p {
  max-width: 75% !important;
}

.mw-76-p {
  max-width: 76% !important;
}

.mw-77-p {
  max-width: 77% !important;
}

.mw-78-p {
  max-width: 78% !important;
}

.mw-79-p {
  max-width: 79% !important;
}

.mw-80-p {
  max-width: 80% !important;
}

.mw-81-p {
  max-width: 81% !important;
}

.mw-82-p {
  max-width: 82% !important;
}

.mw-83-p {
  max-width: 83% !important;
}

.mw-84-p {
  max-width: 84% !important;
}

.mw-85-p {
  max-width: 85% !important;
}

.mw-86-p {
  max-width: 86% !important;
}

.mw-87-p {
  max-width: 87% !important;
}

.mw-88-p {
  max-width: 88% !important;
}

.mw-89-p {
  max-width: 89% !important;
}

.mw-90-p {
  max-width: 90% !important;
}

.mw-91-p {
  max-width: 91% !important;
}

.mw-92-p {
  max-width: 92% !important;
}

.mw-93-p {
  max-width: 93% !important;
}

.mw-94-p {
  max-width: 94% !important;
}

.mw-95-p {
  max-width: 95% !important;
}

.mw-96-p {
  max-width: 96% !important;
}

.mw-97-p {
  max-width: 97% !important;
}

.mw-98-p {
  max-width: 98% !important;
}

.mw-99-p {
  max-width: 99% !important;
}

.mw-100-p {
  max-width: 100% !important;
}

.mw-101-p {
  max-width: 101% !important;
}

.mw-102-p {
  max-width: 102% !important;
}

.mw-103-p {
  max-width: 103% !important;
}

.mw-104-p {
  max-width: 104% !important;
}

.mw-105-p {
  max-width: 105% !important;
}

.mw-106-p {
  max-width: 106% !important;
}

.mw-107-p {
  max-width: 107% !important;
}

.mw-108-p {
  max-width: 108% !important;
}

.mw-109-p {
  max-width: 109% !important;
}

.mw-110-p {
  max-width: 110% !important;
}

.mw-111-p {
  max-width: 111% !important;
}

.mw-112-p {
  max-width: 112% !important;
}

.mw-113-p {
  max-width: 113% !important;
}

.mw-114-p {
  max-width: 114% !important;
}

.mw-115-p {
  max-width: 115% !important;
}

.mw-116-p {
  max-width: 116% !important;
}

.mw-117-p {
  max-width: 117% !important;
}

.mw-118-p {
  max-width: 118% !important;
}

.mw-119-p {
  max-width: 119% !important;
}

.mw-120-p {
  max-width: 120% !important;
}

.mw-121-p {
  max-width: 121% !important;
}

.mw-122-p {
  max-width: 122% !important;
}

.mw-123-p {
  max-width: 123% !important;
}

.mw-124-p {
  max-width: 124% !important;
}

.mw-125-p {
  max-width: 125% !important;
}

.mw-126-p {
  max-width: 126% !important;
}

.mw-127-p {
  max-width: 127% !important;
}

.mw-128-p {
  max-width: 128% !important;
}

.mw-129-p {
  max-width: 129% !important;
}

.mw-130-p {
  max-width: 130% !important;
}

.mw-131-p {
  max-width: 131% !important;
}

.mw-132-p {
  max-width: 132% !important;
}

.mw-133-p {
  max-width: 133% !important;
}

.mw-134-p {
  max-width: 134% !important;
}

.mw-135-p {
  max-width: 135% !important;
}

.mw-136-p {
  max-width: 136% !important;
}

.mw-137-p {
  max-width: 137% !important;
}

.mw-138-p {
  max-width: 138% !important;
}

.mw-139-p {
  max-width: 139% !important;
}

.mw-140-p {
  max-width: 140% !important;
}

.mw-141-p {
  max-width: 141% !important;
}

.mw-142-p {
  max-width: 142% !important;
}

.mw-143-p {
  max-width: 143% !important;
}

.mw-144-p {
  max-width: 144% !important;
}

.mw-145-p {
  max-width: 145% !important;
}

.mw-146-p {
  max-width: 146% !important;
}

.mw-147-p {
  max-width: 147% !important;
}

.mw-148-p {
  max-width: 148% !important;
}

.mw-149-p {
  max-width: 149% !important;
}

.mw-150-p {
  max-width: 150% !important;
}

.mln-1 {
  margin-left: -1px !important;
}

.mln-2 {
  margin-left: -2px !important;
}

.mln-3 {
  margin-left: -3px !important;
}

.mln-4 {
  margin-left: -4px !important;
}

.mln-5 {
  margin-left: -5px !important;
}

.mln-6 {
  margin-left: -6px !important;
}

.mln-7 {
  margin-left: -7px !important;
}

.mln-8 {
  margin-left: -8px !important;
}

.mln-9 {
  margin-left: -9px !important;
}

.mln-10 {
  margin-left: -10px !important;
}

.mln-11 {
  margin-left: -11px !important;
}

.mln-12 {
  margin-left: -12px !important;
}

.mln-13 {
  margin-left: -13px !important;
}

.mln-14 {
  margin-left: -14px !important;
}

.mln-15 {
  margin-left: -15px !important;
}

.mln-16 {
  margin-left: -16px !important;
}

.mln-17 {
  margin-left: -17px !important;
}

.mln-18 {
  margin-left: -18px !important;
}

.mln-19 {
  margin-left: -19px !important;
}

.mln-20 {
  margin-left: -20px !important;
}

.mln-21 {
  margin-left: -21px !important;
}

.mln-22 {
  margin-left: -22px !important;
}

.mln-23 {
  margin-left: -23px !important;
}

.mln-24 {
  margin-left: -24px !important;
}

.mln-25 {
  margin-left: -25px !important;
}

.mln-26 {
  margin-left: -26px !important;
}

.mln-27 {
  margin-left: -27px !important;
}

.mln-28 {
  margin-left: -28px !important;
}

.mln-29 {
  margin-left: -29px !important;
}

.mln-30 {
  margin-left: -30px !important;
}

.mln-31 {
  margin-left: -31px !important;
}

.mln-32 {
  margin-left: -32px !important;
}

.mln-33 {
  margin-left: -33px !important;
}

.mln-34 {
  margin-left: -34px !important;
}

.mln-35 {
  margin-left: -35px !important;
}

.mln-36 {
  margin-left: -36px !important;
}

.mln-37 {
  margin-left: -37px !important;
}

.mln-38 {
  margin-left: -38px !important;
}

.mln-39 {
  margin-left: -39px !important;
}

.mln-40 {
  margin-left: -40px !important;
}

.mln-41 {
  margin-left: -41px !important;
}

.mln-42 {
  margin-left: -42px !important;
}

.mln-43 {
  margin-left: -43px !important;
}

.mln-44 {
  margin-left: -44px !important;
}

.mln-45 {
  margin-left: -45px !important;
}

.mln-46 {
  margin-left: -46px !important;
}

.mln-47 {
  margin-left: -47px !important;
}

.mln-48 {
  margin-left: -48px !important;
}

.mln-49 {
  margin-left: -49px !important;
}

.mln-50 {
  margin-left: -50px !important;
}

.mln-51 {
  margin-left: -51px !important;
}

.mln-52 {
  margin-left: -52px !important;
}

.mln-53 {
  margin-left: -53px !important;
}

.mln-54 {
  margin-left: -54px !important;
}

.mln-55 {
  margin-left: -55px !important;
}

.mln-56 {
  margin-left: -56px !important;
}

.mln-57 {
  margin-left: -57px !important;
}

.mln-58 {
  margin-left: -58px !important;
}

.mln-59 {
  margin-left: -59px !important;
}

.mln-60 {
  margin-left: -60px !important;
}

.mln-61 {
  margin-left: -61px !important;
}

.mln-62 {
  margin-left: -62px !important;
}

.mln-63 {
  margin-left: -63px !important;
}

.mln-64 {
  margin-left: -64px !important;
}

.mln-65 {
  margin-left: -65px !important;
}

.mln-66 {
  margin-left: -66px !important;
}

.mln-67 {
  margin-left: -67px !important;
}

.mln-68 {
  margin-left: -68px !important;
}

.mln-69 {
  margin-left: -69px !important;
}

.mln-70 {
  margin-left: -70px !important;
}

.mln-71 {
  margin-left: -71px !important;
}

.mln-72 {
  margin-left: -72px !important;
}

.mln-73 {
  margin-left: -73px !important;
}

.mln-74 {
  margin-left: -74px !important;
}

.mln-75 {
  margin-left: -75px !important;
}

.mln-76 {
  margin-left: -76px !important;
}

.mln-77 {
  margin-left: -77px !important;
}

.mln-78 {
  margin-left: -78px !important;
}

.mln-79 {
  margin-left: -79px !important;
}

.mln-80 {
  margin-left: -80px !important;
}

.mln-81 {
  margin-left: -81px !important;
}

.mln-82 {
  margin-left: -82px !important;
}

.mln-83 {
  margin-left: -83px !important;
}

.mln-84 {
  margin-left: -84px !important;
}

.mln-85 {
  margin-left: -85px !important;
}

.mln-86 {
  margin-left: -86px !important;
}

.mln-87 {
  margin-left: -87px !important;
}

.mln-88 {
  margin-left: -88px !important;
}

.mln-89 {
  margin-left: -89px !important;
}

.mln-90 {
  margin-left: -90px !important;
}

.mln-91 {
  margin-left: -91px !important;
}

.mln-92 {
  margin-left: -92px !important;
}

.mln-93 {
  margin-left: -93px !important;
}

.mln-94 {
  margin-left: -94px !important;
}

.mln-95 {
  margin-left: -95px !important;
}

.mln-96 {
  margin-left: -96px !important;
}

.mln-97 {
  margin-left: -97px !important;
}

.mln-98 {
  margin-left: -98px !important;
}

.mln-99 {
  margin-left: -99px !important;
}

.mln-100 {
  margin-left: -100px !important;
}

.mln-101 {
  margin-left: -101px !important;
}

.mln-102 {
  margin-left: -102px !important;
}

.mln-103 {
  margin-left: -103px !important;
}

.mln-104 {
  margin-left: -104px !important;
}

.mln-105 {
  margin-left: -105px !important;
}

.mln-106 {
  margin-left: -106px !important;
}

.mln-107 {
  margin-left: -107px !important;
}

.mln-108 {
  margin-left: -108px !important;
}

.mln-109 {
  margin-left: -109px !important;
}

.mln-110 {
  margin-left: -110px !important;
}

.mln-111 {
  margin-left: -111px !important;
}

.mln-112 {
  margin-left: -112px !important;
}

.mln-113 {
  margin-left: -113px !important;
}

.mln-114 {
  margin-left: -114px !important;
}

.mln-115 {
  margin-left: -115px !important;
}

.mln-116 {
  margin-left: -116px !important;
}

.mln-117 {
  margin-left: -117px !important;
}

.mln-118 {
  margin-left: -118px !important;
}

.mln-119 {
  margin-left: -119px !important;
}

.mln-120 {
  margin-left: -120px !important;
}

.mln-121 {
  margin-left: -121px !important;
}

.mln-122 {
  margin-left: -122px !important;
}

.mln-123 {
  margin-left: -123px !important;
}

.mln-124 {
  margin-left: -124px !important;
}

.mln-125 {
  margin-left: -125px !important;
}

.mln-126 {
  margin-left: -126px !important;
}

.mln-127 {
  margin-left: -127px !important;
}

.mln-128 {
  margin-left: -128px !important;
}

.mln-129 {
  margin-left: -129px !important;
}

.mln-130 {
  margin-left: -130px !important;
}

.mln-131 {
  margin-left: -131px !important;
}

.mln-132 {
  margin-left: -132px !important;
}

.mln-133 {
  margin-left: -133px !important;
}

.mln-134 {
  margin-left: -134px !important;
}

.mln-135 {
  margin-left: -135px !important;
}

.mln-136 {
  margin-left: -136px !important;
}

.mln-137 {
  margin-left: -137px !important;
}

.mln-138 {
  margin-left: -138px !important;
}

.mln-139 {
  margin-left: -139px !important;
}

.mln-140 {
  margin-left: -140px !important;
}

.mln-141 {
  margin-left: -141px !important;
}

.mln-142 {
  margin-left: -142px !important;
}

.mln-143 {
  margin-left: -143px !important;
}

.mln-144 {
  margin-left: -144px !important;
}

.mln-145 {
  margin-left: -145px !important;
}

.mln-146 {
  margin-left: -146px !important;
}

.mln-147 {
  margin-left: -147px !important;
}

.mln-148 {
  margin-left: -148px !important;
}

.mln-149 {
  margin-left: -149px !important;
}

.mln-150 {
  margin-left: -150px !important;
}

.mrn-1 {
  margin-right: -1px !important;
}

.mrn-2 {
  margin-right: -2px !important;
}

.mrn-3 {
  margin-right: -3px !important;
}

.mrn-4 {
  margin-right: -4px !important;
}

.mrn-5 {
  margin-right: -5px !important;
}

.mrn-6 {
  margin-right: -6px !important;
}

.mrn-7 {
  margin-right: -7px !important;
}

.mrn-8 {
  margin-right: -8px !important;
}

.mrn-9 {
  margin-right: -9px !important;
}

.mrn-10 {
  margin-right: -10px !important;
}

.mrn-11 {
  margin-right: -11px !important;
}

.mrn-12 {
  margin-right: -12px !important;
}

.mrn-13 {
  margin-right: -13px !important;
}

.mrn-14 {
  margin-right: -14px !important;
}

.mrn-15 {
  margin-right: -15px !important;
}

.mrn-16 {
  margin-right: -16px !important;
}

.mrn-17 {
  margin-right: -17px !important;
}

.mrn-18 {
  margin-right: -18px !important;
}

.mrn-19 {
  margin-right: -19px !important;
}

.mrn-20 {
  margin-right: -20px !important;
}

.mrn-21 {
  margin-right: -21px !important;
}

.mrn-22 {
  margin-right: -22px !important;
}

.mrn-23 {
  margin-right: -23px !important;
}

.mrn-24 {
  margin-right: -24px !important;
}

.mrn-25 {
  margin-right: -25px !important;
}

.mrn-26 {
  margin-right: -26px !important;
}

.mrn-27 {
  margin-right: -27px !important;
}

.mrn-28 {
  margin-right: -28px !important;
}

.mrn-29 {
  margin-right: -29px !important;
}

.mrn-30 {
  margin-right: -30px !important;
}

.mrn-31 {
  margin-right: -31px !important;
}

.mrn-32 {
  margin-right: -32px !important;
}

.mrn-33 {
  margin-right: -33px !important;
}

.mrn-34 {
  margin-right: -34px !important;
}

.mrn-35 {
  margin-right: -35px !important;
}

.mrn-36 {
  margin-right: -36px !important;
}

.mrn-37 {
  margin-right: -37px !important;
}

.mrn-38 {
  margin-right: -38px !important;
}

.mrn-39 {
  margin-right: -39px !important;
}

.mrn-40 {
  margin-right: -40px !important;
}

.mrn-41 {
  margin-right: -41px !important;
}

.mrn-42 {
  margin-right: -42px !important;
}

.mrn-43 {
  margin-right: -43px !important;
}

.mrn-44 {
  margin-right: -44px !important;
}

.mrn-45 {
  margin-right: -45px !important;
}

.mrn-46 {
  margin-right: -46px !important;
}

.mrn-47 {
  margin-right: -47px !important;
}

.mrn-48 {
  margin-right: -48px !important;
}

.mrn-49 {
  margin-right: -49px !important;
}

.mrn-50 {
  margin-right: -50px !important;
}

.mrn-51 {
  margin-right: -51px !important;
}

.mrn-52 {
  margin-right: -52px !important;
}

.mrn-53 {
  margin-right: -53px !important;
}

.mrn-54 {
  margin-right: -54px !important;
}

.mrn-55 {
  margin-right: -55px !important;
}

.mrn-56 {
  margin-right: -56px !important;
}

.mrn-57 {
  margin-right: -57px !important;
}

.mrn-58 {
  margin-right: -58px !important;
}

.mrn-59 {
  margin-right: -59px !important;
}

.mrn-60 {
  margin-right: -60px !important;
}

.mrn-61 {
  margin-right: -61px !important;
}

.mrn-62 {
  margin-right: -62px !important;
}

.mrn-63 {
  margin-right: -63px !important;
}

.mrn-64 {
  margin-right: -64px !important;
}

.mrn-65 {
  margin-right: -65px !important;
}

.mrn-66 {
  margin-right: -66px !important;
}

.mrn-67 {
  margin-right: -67px !important;
}

.mrn-68 {
  margin-right: -68px !important;
}

.mrn-69 {
  margin-right: -69px !important;
}

.mrn-70 {
  margin-right: -70px !important;
}

.mrn-71 {
  margin-right: -71px !important;
}

.mrn-72 {
  margin-right: -72px !important;
}

.mrn-73 {
  margin-right: -73px !important;
}

.mrn-74 {
  margin-right: -74px !important;
}

.mrn-75 {
  margin-right: -75px !important;
}

.mrn-76 {
  margin-right: -76px !important;
}

.mrn-77 {
  margin-right: -77px !important;
}

.mrn-78 {
  margin-right: -78px !important;
}

.mrn-79 {
  margin-right: -79px !important;
}

.mrn-80 {
  margin-right: -80px !important;
}

.mrn-81 {
  margin-right: -81px !important;
}

.mrn-82 {
  margin-right: -82px !important;
}

.mrn-83 {
  margin-right: -83px !important;
}

.mrn-84 {
  margin-right: -84px !important;
}

.mrn-85 {
  margin-right: -85px !important;
}

.mrn-86 {
  margin-right: -86px !important;
}

.mrn-87 {
  margin-right: -87px !important;
}

.mrn-88 {
  margin-right: -88px !important;
}

.mrn-89 {
  margin-right: -89px !important;
}

.mrn-90 {
  margin-right: -90px !important;
}

.mrn-91 {
  margin-right: -91px !important;
}

.mrn-92 {
  margin-right: -92px !important;
}

.mrn-93 {
  margin-right: -93px !important;
}

.mrn-94 {
  margin-right: -94px !important;
}

.mrn-95 {
  margin-right: -95px !important;
}

.mrn-96 {
  margin-right: -96px !important;
}

.mrn-97 {
  margin-right: -97px !important;
}

.mrn-98 {
  margin-right: -98px !important;
}

.mrn-99 {
  margin-right: -99px !important;
}

.mrn-100 {
  margin-right: -100px !important;
}

.mrn-101 {
  margin-right: -101px !important;
}

.mrn-102 {
  margin-right: -102px !important;
}

.mrn-103 {
  margin-right: -103px !important;
}

.mrn-104 {
  margin-right: -104px !important;
}

.mrn-105 {
  margin-right: -105px !important;
}

.mrn-106 {
  margin-right: -106px !important;
}

.mrn-107 {
  margin-right: -107px !important;
}

.mrn-108 {
  margin-right: -108px !important;
}

.mrn-109 {
  margin-right: -109px !important;
}

.mrn-110 {
  margin-right: -110px !important;
}

.mrn-111 {
  margin-right: -111px !important;
}

.mrn-112 {
  margin-right: -112px !important;
}

.mrn-113 {
  margin-right: -113px !important;
}

.mrn-114 {
  margin-right: -114px !important;
}

.mrn-115 {
  margin-right: -115px !important;
}

.mrn-116 {
  margin-right: -116px !important;
}

.mrn-117 {
  margin-right: -117px !important;
}

.mrn-118 {
  margin-right: -118px !important;
}

.mrn-119 {
  margin-right: -119px !important;
}

.mrn-120 {
  margin-right: -120px !important;
}

.mrn-121 {
  margin-right: -121px !important;
}

.mrn-122 {
  margin-right: -122px !important;
}

.mrn-123 {
  margin-right: -123px !important;
}

.mrn-124 {
  margin-right: -124px !important;
}

.mrn-125 {
  margin-right: -125px !important;
}

.mrn-126 {
  margin-right: -126px !important;
}

.mrn-127 {
  margin-right: -127px !important;
}

.mrn-128 {
  margin-right: -128px !important;
}

.mrn-129 {
  margin-right: -129px !important;
}

.mrn-130 {
  margin-right: -130px !important;
}

.mrn-131 {
  margin-right: -131px !important;
}

.mrn-132 {
  margin-right: -132px !important;
}

.mrn-133 {
  margin-right: -133px !important;
}

.mrn-134 {
  margin-right: -134px !important;
}

.mrn-135 {
  margin-right: -135px !important;
}

.mrn-136 {
  margin-right: -136px !important;
}

.mrn-137 {
  margin-right: -137px !important;
}

.mrn-138 {
  margin-right: -138px !important;
}

.mrn-139 {
  margin-right: -139px !important;
}

.mrn-140 {
  margin-right: -140px !important;
}

.mrn-141 {
  margin-right: -141px !important;
}

.mrn-142 {
  margin-right: -142px !important;
}

.mrn-143 {
  margin-right: -143px !important;
}

.mrn-144 {
  margin-right: -144px !important;
}

.mrn-145 {
  margin-right: -145px !important;
}

.mrn-146 {
  margin-right: -146px !important;
}

.mrn-147 {
  margin-right: -147px !important;
}

.mrn-148 {
  margin-right: -148px !important;
}

.mrn-149 {
  margin-right: -149px !important;
}

.mrn-150 {
  margin-right: -150px !important;
}

/******* ######### ******
     Line Heights
******* ######### ******/
.lnh-24 {
  line-height: 24px;
}

.lnh-28 {
  line-height: 28px;
}

.lnh-32 {
  line-height: 32px;
}

/******* ######### ******
       Postions
******* ######### ******/
.posi-t-1 {
  top: 1px !important;
}

.posi-t-2 {
  top: 2px !important;
}

.posi-t-3 {
  top: 3px !important;
}

.posi-t-4 {
  top: 4px !important;
}

.posi-t-5 {
  top: 5px !important;
}

.posi-t-6 {
  top: 6px !important;
}

.posi-t-7 {
  top: 7px !important;
}

.posi-t-8 {
  top: 8px !important;
}

.posi-t-9 {
  top: 9px !important;
}

.posi-t-10 {
  top: 10px !important;
}

.posi-t-11 {
  top: 11px !important;
}

.posi-t-12 {
  top: 12px !important;
}

.posi-t-13 {
  top: 13px !important;
}

.posi-t-14 {
  top: 14px !important;
}

.posi-t-15 {
  top: 15px !important;
}

.posi-t-16 {
  top: 16px !important;
}

.posi-t-17 {
  top: 17px !important;
}

.posi-t-18 {
  top: 18px !important;
}

.posi-t-19 {
  top: 19px !important;
}

.posi-t-20 {
  top: 20px !important;
}

.posi-t-21 {
  top: 21px !important;
}

.posi-t-22 {
  top: 22px !important;
}

.posi-t-23 {
  top: 23px !important;
}

.posi-t-24 {
  top: 24px !important;
}

.posi-t-25 {
  top: 25px !important;
}

.posi-t-26 {
  top: 26px !important;
}

.posi-t-27 {
  top: 27px !important;
}

.posi-t-28 {
  top: 28px !important;
}

.posi-t-29 {
  top: 29px !important;
}

.posi-t-30 {
  top: 30px !important;
}

.posi-t-31 {
  top: 31px !important;
}

.posi-t-32 {
  top: 32px !important;
}

.posi-t-33 {
  top: 33px !important;
}

.posi-t-34 {
  top: 34px !important;
}

.posi-t-35 {
  top: 35px !important;
}

.posi-t-36 {
  top: 36px !important;
}

.posi-t-37 {
  top: 37px !important;
}

.posi-t-38 {
  top: 38px !important;
}

.posi-t-39 {
  top: 39px !important;
}

.posi-t-40 {
  top: 40px !important;
}

.posi-t-41 {
  top: 41px !important;
}

.posi-t-42 {
  top: 42px !important;
}

.posi-t-43 {
  top: 43px !important;
}

.posi-t-44 {
  top: 44px !important;
}

.posi-t-45 {
  top: 45px !important;
}

.posi-t-46 {
  top: 46px !important;
}

.posi-t-47 {
  top: 47px !important;
}

.posi-t-48 {
  top: 48px !important;
}

.posi-t-49 {
  top: 49px !important;
}

.posi-t-50 {
  top: 50px !important;
}

.posi-t-51 {
  top: 51px !important;
}

.posi-t-52 {
  top: 52px !important;
}

.posi-t-53 {
  top: 53px !important;
}

.posi-t-54 {
  top: 54px !important;
}

.posi-t-55 {
  top: 55px !important;
}

.posi-t-56 {
  top: 56px !important;
}

.posi-t-57 {
  top: 57px !important;
}

.posi-t-58 {
  top: 58px !important;
}

.posi-t-59 {
  top: 59px !important;
}

.posi-t-60 {
  top: 60px !important;
}

.posi-t-61 {
  top: 61px !important;
}

.posi-t-62 {
  top: 62px !important;
}

.posi-t-63 {
  top: 63px !important;
}

.posi-t-64 {
  top: 64px !important;
}

.posi-t-65 {
  top: 65px !important;
}

.posi-t-66 {
  top: 66px !important;
}

.posi-t-67 {
  top: 67px !important;
}

.posi-t-68 {
  top: 68px !important;
}

.posi-t-69 {
  top: 69px !important;
}

.posi-t-70 {
  top: 70px !important;
}

.posi-t-71 {
  top: 71px !important;
}

.posi-t-72 {
  top: 72px !important;
}

.posi-t-73 {
  top: 73px !important;
}

.posi-t-74 {
  top: 74px !important;
}

.posi-t-75 {
  top: 75px !important;
}

.posi-t-76 {
  top: 76px !important;
}

.posi-t-77 {
  top: 77px !important;
}

.posi-t-78 {
  top: 78px !important;
}

.posi-t-79 {
  top: 79px !important;
}

.posi-t-80 {
  top: 80px !important;
}

.posi-t-81 {
  top: 81px !important;
}

.posi-t-82 {
  top: 82px !important;
}

.posi-t-83 {
  top: 83px !important;
}

.posi-t-84 {
  top: 84px !important;
}

.posi-t-85 {
  top: 85px !important;
}

.posi-t-86 {
  top: 86px !important;
}

.posi-t-87 {
  top: 87px !important;
}

.posi-t-88 {
  top: 88px !important;
}

.posi-t-89 {
  top: 89px !important;
}

.posi-t-90 {
  top: 90px !important;
}

.posi-t-91 {
  top: 91px !important;
}

.posi-t-92 {
  top: 92px !important;
}

.posi-t-93 {
  top: 93px !important;
}

.posi-t-94 {
  top: 94px !important;
}

.posi-t-95 {
  top: 95px !important;
}

.posi-t-96 {
  top: 96px !important;
}

.posi-t-97 {
  top: 97px !important;
}

.posi-t-98 {
  top: 98px !important;
}

.posi-t-99 {
  top: 99px !important;
}

.posi-t-100 {
  top: 100px !important;
}

.posi-t-101 {
  top: 101px !important;
}

.posi-t-102 {
  top: 102px !important;
}

.posi-t-103 {
  top: 103px !important;
}

.posi-t-104 {
  top: 104px !important;
}

.posi-t-105 {
  top: 105px !important;
}

.posi-t-106 {
  top: 106px !important;
}

.posi-t-107 {
  top: 107px !important;
}

.posi-t-108 {
  top: 108px !important;
}

.posi-t-109 {
  top: 109px !important;
}

.posi-t-110 {
  top: 110px !important;
}

.posi-t-111 {
  top: 111px !important;
}

.posi-t-112 {
  top: 112px !important;
}

.posi-t-113 {
  top: 113px !important;
}

.posi-t-114 {
  top: 114px !important;
}

.posi-t-115 {
  top: 115px !important;
}

.posi-t-116 {
  top: 116px !important;
}

.posi-t-117 {
  top: 117px !important;
}

.posi-t-118 {
  top: 118px !important;
}

.posi-t-119 {
  top: 119px !important;
}

.posi-t-120 {
  top: 120px !important;
}

.posi-t-121 {
  top: 121px !important;
}

.posi-t-122 {
  top: 122px !important;
}

.posi-t-123 {
  top: 123px !important;
}

.posi-t-124 {
  top: 124px !important;
}

.posi-t-125 {
  top: 125px !important;
}

.posi-t-126 {
  top: 126px !important;
}

.posi-t-127 {
  top: 127px !important;
}

.posi-t-128 {
  top: 128px !important;
}

.posi-t-129 {
  top: 129px !important;
}

.posi-t-130 {
  top: 130px !important;
}

.posi-t-131 {
  top: 131px !important;
}

.posi-t-132 {
  top: 132px !important;
}

.posi-t-133 {
  top: 133px !important;
}

.posi-t-134 {
  top: 134px !important;
}

.posi-t-135 {
  top: 135px !important;
}

.posi-t-136 {
  top: 136px !important;
}

.posi-t-137 {
  top: 137px !important;
}

.posi-t-138 {
  top: 138px !important;
}

.posi-t-139 {
  top: 139px !important;
}

.posi-t-140 {
  top: 140px !important;
}

.posi-t-141 {
  top: 141px !important;
}

.posi-t-142 {
  top: 142px !important;
}

.posi-t-143 {
  top: 143px !important;
}

.posi-t-144 {
  top: 144px !important;
}

.posi-t-145 {
  top: 145px !important;
}

.posi-t-146 {
  top: 146px !important;
}

.posi-t-147 {
  top: 147px !important;
}

.posi-t-148 {
  top: 148px !important;
}

.posi-t-149 {
  top: 149px !important;
}

.posi-t-150 {
  top: 150px !important;
}

.posi-tn-1 {
  top: -1px !important;
}

.posi-tn-2 {
  top: -2px !important;
}

.posi-tn-3 {
  top: -3px !important;
}

.posi-tn-4 {
  top: -4px !important;
}

.posi-tn-5 {
  top: -5px !important;
}

.posi-tn-6 {
  top: -6px !important;
}

.posi-tn-7 {
  top: -7px !important;
}

.posi-tn-8 {
  top: -8px !important;
}

.posi-tn-9 {
  top: -9px !important;
}

.posi-tn-10 {
  top: -10px !important;
}

.posi-tn-11 {
  top: -11px !important;
}

.posi-tn-12 {
  top: -12px !important;
}

.posi-tn-13 {
  top: -13px !important;
}

.posi-tn-14 {
  top: -14px !important;
}

.posi-tn-15 {
  top: -15px !important;
}

.posi-tn-16 {
  top: -16px !important;
}

.posi-tn-17 {
  top: -17px !important;
}

.posi-tn-18 {
  top: -18px !important;
}

.posi-tn-19 {
  top: -19px !important;
}

.posi-tn-20 {
  top: -20px !important;
}

.posi-tn-21 {
  top: -21px !important;
}

.posi-tn-22 {
  top: -22px !important;
}

.posi-tn-23 {
  top: -23px !important;
}

.posi-tn-24 {
  top: -24px !important;
}

.posi-tn-25 {
  top: -25px !important;
}

.posi-tn-26 {
  top: -26px !important;
}

.posi-tn-27 {
  top: -27px !important;
}

.posi-tn-28 {
  top: -28px !important;
}

.posi-tn-29 {
  top: -29px !important;
}

.posi-tn-30 {
  top: -30px !important;
}

.posi-tn-31 {
  top: -31px !important;
}

.posi-tn-32 {
  top: -32px !important;
}

.posi-tn-33 {
  top: -33px !important;
}

.posi-tn-34 {
  top: -34px !important;
}

.posi-tn-35 {
  top: -35px !important;
}

.posi-tn-36 {
  top: -36px !important;
}

.posi-tn-37 {
  top: -37px !important;
}

.posi-tn-38 {
  top: -38px !important;
}

.posi-tn-39 {
  top: -39px !important;
}

.posi-tn-40 {
  top: -40px !important;
}

.posi-tn-41 {
  top: -41px !important;
}

.posi-tn-42 {
  top: -42px !important;
}

.posi-tn-43 {
  top: -43px !important;
}

.posi-tn-44 {
  top: -44px !important;
}

.posi-tn-45 {
  top: -45px !important;
}

.posi-tn-46 {
  top: -46px !important;
}

.posi-tn-47 {
  top: -47px !important;
}

.posi-tn-48 {
  top: -48px !important;
}

.posi-tn-49 {
  top: -49px !important;
}

.posi-tn-50 {
  top: -50px !important;
}

.posi-tn-51 {
  top: -51px !important;
}

.posi-tn-52 {
  top: -52px !important;
}

.posi-tn-53 {
  top: -53px !important;
}

.posi-tn-54 {
  top: -54px !important;
}

.posi-tn-55 {
  top: -55px !important;
}

.posi-tn-56 {
  top: -56px !important;
}

.posi-tn-57 {
  top: -57px !important;
}

.posi-tn-58 {
  top: -58px !important;
}

.posi-tn-59 {
  top: -59px !important;
}

.posi-tn-60 {
  top: -60px !important;
}

.posi-tn-61 {
  top: -61px !important;
}

.posi-tn-62 {
  top: -62px !important;
}

.posi-tn-63 {
  top: -63px !important;
}

.posi-tn-64 {
  top: -64px !important;
}

.posi-tn-65 {
  top: -65px !important;
}

.posi-tn-66 {
  top: -66px !important;
}

.posi-tn-67 {
  top: -67px !important;
}

.posi-tn-68 {
  top: -68px !important;
}

.posi-tn-69 {
  top: -69px !important;
}

.posi-tn-70 {
  top: -70px !important;
}

.posi-tn-71 {
  top: -71px !important;
}

.posi-tn-72 {
  top: -72px !important;
}

.posi-tn-73 {
  top: -73px !important;
}

.posi-tn-74 {
  top: -74px !important;
}

.posi-tn-75 {
  top: -75px !important;
}

.posi-tn-76 {
  top: -76px !important;
}

.posi-tn-77 {
  top: -77px !important;
}

.posi-tn-78 {
  top: -78px !important;
}

.posi-tn-79 {
  top: -79px !important;
}

.posi-tn-80 {
  top: -80px !important;
}

.posi-tn-81 {
  top: -81px !important;
}

.posi-tn-82 {
  top: -82px !important;
}

.posi-tn-83 {
  top: -83px !important;
}

.posi-tn-84 {
  top: -84px !important;
}

.posi-tn-85 {
  top: -85px !important;
}

.posi-tn-86 {
  top: -86px !important;
}

.posi-tn-87 {
  top: -87px !important;
}

.posi-tn-88 {
  top: -88px !important;
}

.posi-tn-89 {
  top: -89px !important;
}

.posi-tn-90 {
  top: -90px !important;
}

.posi-tn-91 {
  top: -91px !important;
}

.posi-tn-92 {
  top: -92px !important;
}

.posi-tn-93 {
  top: -93px !important;
}

.posi-tn-94 {
  top: -94px !important;
}

.posi-tn-95 {
  top: -95px !important;
}

.posi-tn-96 {
  top: -96px !important;
}

.posi-tn-97 {
  top: -97px !important;
}

.posi-tn-98 {
  top: -98px !important;
}

.posi-tn-99 {
  top: -99px !important;
}

.posi-tn-100 {
  top: -100px !important;
}

.posi-tn-101 {
  top: -101px !important;
}

.posi-tn-102 {
  top: -102px !important;
}

.posi-tn-103 {
  top: -103px !important;
}

.posi-tn-104 {
  top: -104px !important;
}

.posi-tn-105 {
  top: -105px !important;
}

.posi-tn-106 {
  top: -106px !important;
}

.posi-tn-107 {
  top: -107px !important;
}

.posi-tn-108 {
  top: -108px !important;
}

.posi-tn-109 {
  top: -109px !important;
}

.posi-tn-110 {
  top: -110px !important;
}

.posi-tn-111 {
  top: -111px !important;
}

.posi-tn-112 {
  top: -112px !important;
}

.posi-tn-113 {
  top: -113px !important;
}

.posi-tn-114 {
  top: -114px !important;
}

.posi-tn-115 {
  top: -115px !important;
}

.posi-tn-116 {
  top: -116px !important;
}

.posi-tn-117 {
  top: -117px !important;
}

.posi-tn-118 {
  top: -118px !important;
}

.posi-tn-119 {
  top: -119px !important;
}

.posi-tn-120 {
  top: -120px !important;
}

.posi-tn-121 {
  top: -121px !important;
}

.posi-tn-122 {
  top: -122px !important;
}

.posi-tn-123 {
  top: -123px !important;
}

.posi-tn-124 {
  top: -124px !important;
}

.posi-tn-125 {
  top: -125px !important;
}

.posi-tn-126 {
  top: -126px !important;
}

.posi-tn-127 {
  top: -127px !important;
}

.posi-tn-128 {
  top: -128px !important;
}

.posi-tn-129 {
  top: -129px !important;
}

.posi-tn-130 {
  top: -130px !important;
}

.posi-tn-131 {
  top: -131px !important;
}

.posi-tn-132 {
  top: -132px !important;
}

.posi-tn-133 {
  top: -133px !important;
}

.posi-tn-134 {
  top: -134px !important;
}

.posi-tn-135 {
  top: -135px !important;
}

.posi-tn-136 {
  top: -136px !important;
}

.posi-tn-137 {
  top: -137px !important;
}

.posi-tn-138 {
  top: -138px !important;
}

.posi-tn-139 {
  top: -139px !important;
}

.posi-tn-140 {
  top: -140px !important;
}

.posi-tn-141 {
  top: -141px !important;
}

.posi-tn-142 {
  top: -142px !important;
}

.posi-tn-143 {
  top: -143px !important;
}

.posi-tn-144 {
  top: -144px !important;
}

.posi-tn-145 {
  top: -145px !important;
}

.posi-tn-146 {
  top: -146px !important;
}

.posi-tn-147 {
  top: -147px !important;
}

.posi-tn-148 {
  top: -148px !important;
}

.posi-tn-149 {
  top: -149px !important;
}

.posi-tn-150 {
  top: -150px !important;
}

/******* ######### ******
    Widths Percentage
******* ######### ******/
.w-1-p {
  width: 1% !important;
}

.w-2-p {
  width: 2% !important;
}

.w-3-p {
  width: 3% !important;
}

.w-4-p {
  width: 4% !important;
}

.w-5-p {
  width: 5% !important;
}

.w-6-p {
  width: 6% !important;
}

.w-7-p {
  width: 7% !important;
}

.w-8-p {
  width: 8% !important;
}

.w-9-p {
  width: 9% !important;
}

.w-10-p {
  width: 10% !important;
}

.w-11-p {
  width: 11% !important;
}

.w-12-p {
  width: 12% !important;
}

.w-13-p {
  width: 13% !important;
}

.w-14-p {
  width: 14% !important;
}

.w-15-p {
  width: 15% !important;
}

.w-16-p {
  width: 16% !important;
}

.w-17-p {
  width: 17% !important;
}

.w-18-p {
  width: 18% !important;
}

.w-19-p {
  width: 19% !important;
}

.w-20-p {
  width: 20% !important;
}

.w-21-p {
  width: 21% !important;
}

.w-22-p {
  width: 22% !important;
}

.w-23-p {
  width: 23% !important;
}

.w-24-p {
  width: 24% !important;
}

.w-25-p {
  width: 25% !important;
}

.w-26-p {
  width: 26% !important;
}

.w-27-p {
  width: 27% !important;
}

.w-28-p {
  width: 28% !important;
}

.w-29-p {
  width: 29% !important;
}

.w-30-p {
  width: 30% !important;
}

.w-31-p {
  width: 31% !important;
}

.w-32-p {
  width: 32% !important;
}

.w-33-p {
  width: 33% !important;
}

.w-34-p {
  width: 34% !important;
}

.w-35-p {
  width: 35% !important;
}

.w-36-p {
  width: 36% !important;
}

.w-37-p {
  width: 37% !important;
}

.w-38-p {
  width: 38% !important;
}

.w-39-p {
  width: 39% !important;
}

.w-40-p {
  width: 40% !important;
}

.w-41-p {
  width: 41% !important;
}

.w-42-p {
  width: 42% !important;
}

.w-43-p {
  width: 43% !important;
}

.w-44-p {
  width: 44% !important;
}

.w-45-p {
  width: 45% !important;
}

.w-46-p {
  width: 46% !important;
}

.w-47-p {
  width: 47% !important;
}

.w-48-p {
  width: 48% !important;
}

.w-49-p {
  width: 49% !important;
}

.w-50-p {
  width: 50% !important;
}

.w-51-p {
  width: 51% !important;
}

.w-52-p {
  width: 52% !important;
}

.w-53-p {
  width: 53% !important;
}

.w-54-p {
  width: 54% !important;
}

.w-55-p {
  width: 55% !important;
}

.w-56-p {
  width: 56% !important;
}

.w-57-p {
  width: 57% !important;
}

.w-58-p {
  width: 58% !important;
}

.w-59-p {
  width: 59% !important;
}

.w-60-p {
  width: 60% !important;
}

.w-61-p {
  width: 61% !important;
}

.w-62-p {
  width: 62% !important;
}

.w-63-p {
  width: 63% !important;
}

.w-64-p {
  width: 64% !important;
}

.w-65-p {
  width: 65% !important;
}

.w-66-p {
  width: 66% !important;
}

.w-67-p {
  width: 67% !important;
}

.w-68-p {
  width: 68% !important;
}

.w-69-p {
  width: 69% !important;
}

.w-70-p {
  width: 70% !important;
}

.w-71-p {
  width: 71% !important;
}

.w-72-p {
  width: 72% !important;
}

.w-73-p {
  width: 73% !important;
}

.w-74-p {
  width: 74% !important;
}

.w-75-p {
  width: 75% !important;
}

.w-76-p {
  width: 76% !important;
}

.w-77-p {
  width: 77% !important;
}

.w-78-p {
  width: 78% !important;
}

.w-79-p {
  width: 79% !important;
}

.w-80-p {
  width: 80% !important;
}

.w-81-p {
  width: 81% !important;
}

.w-82-p {
  width: 82% !important;
}

.w-83-p {
  width: 83% !important;
}

.w-84-p {
  width: 84% !important;
}

.w-85-p {
  width: 85% !important;
}

.w-86-p {
  width: 86% !important;
}

.w-87-p {
  width: 87% !important;
}

.w-88-p {
  width: 88% !important;
}

.w-89-p {
  width: 89% !important;
}

.w-90-p {
  width: 90% !important;
}

.w-91-p {
  width: 91% !important;
}

.w-92-p {
  width: 92% !important;
}

.w-93-p {
  width: 93% !important;
}

.w-94-p {
  width: 94% !important;
}

.w-95-p {
  width: 95% !important;
}

.w-96-p {
  width: 96% !important;
}

.w-97-p {
  width: 97% !important;
}

.w-98-p {
  width: 98% !important;
}

.w-99-p {
  width: 99% !important;
}

.w-100-p {
  width: 100% !important;
}

.w-101-p {
  width: 101% !important;
}

.w-102-p {
  width: 102% !important;
}

.w-103-p {
  width: 103% !important;
}

.w-104-p {
  width: 104% !important;
}

.w-105-p {
  width: 105% !important;
}

.w-106-p {
  width: 106% !important;
}

.w-107-p {
  width: 107% !important;
}

.w-108-p {
  width: 108% !important;
}

.w-109-p {
  width: 109% !important;
}

.w-110-p {
  width: 110% !important;
}

.w-111-p {
  width: 111% !important;
}

.w-112-p {
  width: 112% !important;
}

.w-113-p {
  width: 113% !important;
}

.w-114-p {
  width: 114% !important;
}

.w-115-p {
  width: 115% !important;
}

.w-116-p {
  width: 116% !important;
}

.w-117-p {
  width: 117% !important;
}

.w-118-p {
  width: 118% !important;
}

.w-119-p {
  width: 119% !important;
}

.w-120-p {
  width: 120% !important;
}

.w-121-p {
  width: 121% !important;
}

.w-122-p {
  width: 122% !important;
}

.w-123-p {
  width: 123% !important;
}

.w-124-p {
  width: 124% !important;
}

.w-125-p {
  width: 125% !important;
}

.w-126-p {
  width: 126% !important;
}

.w-127-p {
  width: 127% !important;
}

.w-128-p {
  width: 128% !important;
}

.w-129-p {
  width: 129% !important;
}

.w-130-p {
  width: 130% !important;
}

.w-131-p {
  width: 131% !important;
}

.w-132-p {
  width: 132% !important;
}

.w-133-p {
  width: 133% !important;
}

.w-134-p {
  width: 134% !important;
}

.w-135-p {
  width: 135% !important;
}

.w-136-p {
  width: 136% !important;
}

.w-137-p {
  width: 137% !important;
}

.w-138-p {
  width: 138% !important;
}

.w-139-p {
  width: 139% !important;
}

.w-140-p {
  width: 140% !important;
}

.w-141-p {
  width: 141% !important;
}

.w-142-p {
  width: 142% !important;
}

.w-143-p {
  width: 143% !important;
}

.w-144-p {
  width: 144% !important;
}

.w-145-p {
  width: 145% !important;
}

.w-146-p {
  width: 146% !important;
}

.w-147-p {
  width: 147% !important;
}

.w-148-p {
  width: 148% !important;
}

.w-149-p {
  width: 149% !important;
}

.w-150-p {
  width: 150% !important;
}

/******* ######### ******
       line height
******* ######### ******/
.line-height-normal {
  line-height: normal;
}

/******* ######### ******
       line height
******* ######### ******/
.cursor-pointer {
  cursor: pointer !important;
}

.cursor-auto {
  cursor: auto !important;
}

/******* ######### ******
       list-inline
******* ######### ******/
.list-inline {
  padding: 0px;
  margin: 0px;
}
.list-inline > li {
  display: inline-block;
  padding: 0px 5px;
}

/******* ######### ******
    Notification Card
******* ######### ******/
.notification-card {
  background: #fff;
  padding: 12px 18px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  height: initial;
  border-radius: 2px;
}
.notification-card .table-header {
  background: none;
}
.notification-card p {
  line-height: initial;
}
.notification-card:after {
  content: "";
  clear: both;
  display: block;
}
.notification-card .owl-item {
  display: block;
}
.notification-card .each-type-notice-wrapper .icon-name {
  display: table;
}
.notification-card .each-type-notice-wrapper .icon-name .icon {
  display: table-cell;
  vertical-align: middle;
}
.notification-card .each-type-notice-wrapper .icon-name .name {
  vertical-align: middle;
  display: table-cell;
  font-weight: normal;
}
.notification-card .each-type-notice-wrapper ul {
  padding: 0px;
  margin: 0px;
}
.notification-card .each-type-notice-wrapper ul li {
  list-style-type: none;
  padding: 0px 5px 0px 0px;
  display: inline-block;
}
.notification-card .each-type-notice-wrapper ul li img {
  border: 1px solid #dbe1e8;
  color: #dbe1e8;
  text-align: center;
  text-transform: uppercase;
  line-height: 36px;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  background-color: #f7f8fa;
  cursor: pointer;
}
.notification-card .each-type-notice-wrapper ul li .img-name {
  border: 1px solid #dbe1e8;
  color: #7d8d9f;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  line-height: 36px;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  background-color: #f7f8fa;
  cursor: pointer;
}
.notification-card .each-type-notice-wrapper .notice-details {
  padding-right: 100px;
  position: relative;
}
.notification-card .each-type-notice-wrapper .notice-details .readmore {
  position: absolute;
  padding-left: 10px;
}
.notification-card .each-type-notice-wrapper .notice-details .viewall {
  position: absolute;
  right: 0px;
}
.notification-card .owl-theme .owl-controls {
  /* margin-top: 10px; */
  text-align: center;
  background: #fff;
  position: absolute;
  top: -36px;
  right: 0px;
}
.notification-card .owl-theme .owl-controls .owl-page span {
  height: 8px;
  width: 8px;
  margin: 3px 0px;
  border-radius: 50%;
  background-color: #bcc6d6;
}
.notification-card .owl-theme .owl-controls .owl-page.active span {
  background-color: #7d8d9f;
}
.notification-card .owl-theme .owl-controls .owl-page {
  padding-left: 6px;
}
.notification-card .owl-theme .owl-controls {
  width: initial !important;
}

/******* ######### ******
    Fancy dropdown
******* ######### ******/
/*.fancy-dropdown ul {
    width: 100%;
    border-radius: initial !important;
    border: none;
    position: absolute;
    top: 0px;
    display: none;*/
/*-webkit-transform: scaleY(0);
-ms-transform: scaleY(0);
transform: scaleY(0);
display: initial;
opacity: 0;
transition: all 0.8s ease;
min-height: 200px;*/
/*padding: 20px;
    padding: 34px;
    box-shadow: 0 10px 80px 0 rgba(42,51,62,0.2),0 30px 30px 0 rgba(42,51,62,0.2);
}
.fancy-dropdown.open ul.dropdown-menu {*/
/*opacity: 1;
-webkit-transform: scaleY(1);
-ms-transform: scaleY(1);
transform: scaleY(1);*/
/*transition: all 0.8s ease;
    padding: 34px;
    box-shadow: 0 10px 80px 0 rgba(42,51,62,0.2),0 30px 30px 0 rgba(42,51,62,0.2);
}*/
/******* ######### ******
        Main menu
******* ######### ******/
.single-menu-item {
  text-align: center;
  padding: 20px 0px;
}
.single-menu-item .module-icon-wrapper {
  width: 130px;
  height: 130px;
  border-radius: 16px;
  background-color: #f7f8fa;
  border: solid 1px #dbe1e8;
  margin: 0 auto 5px;
  transition: all 0.5s ease;
  position: relative;
}
.single-menu-item .module-icon-wrapper .db-badge {
  box-sizing: border-box;
  min-width: 22px;
  height: 22px;
  line-height: 15px;
  padding: 0px 5px;
  text-align: center;
  border-radius: 30px;
  vertical-align: middle;
  background: #f44336;
  color: #ffffff;
  font-size: 10px;
  font-weight: 500;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  position: absolute;
  top: -7px;
  right: -7px;
}
.single-menu-item .module-icon-wrapper:hover {
  box-shadow: 0 10px 30px 0 rgba(42, 51, 62, 0.08),
    0 30px 30px 0 rgba(42, 51, 62, 0.08);
  transition: box-shadow 0.5s ease;
}
.single-menu-item a {
  position: relative;
  display: inline-block;
}
.single-menu-item img {
  max-width: 130px;
  margin-bottom: 5px;
}
.single-menu-item p {
  font-size: 14px;
  color: #2a333e;
}

/******* ######### ******
    menu dropdown
******* ######### ******/
.user-profile ul {
  visibility: hidden;
  position: absolute;
  padding: 10px 0px;
  margin: 0px;
  top: 100px;
  opacity: 0;
  z-index: 1;
  transition: all 0.5s ease;
  right: 10px;
  background: #fff;
  min-width: 260px;
  border-radius: 2px;
  border: none !important;
  box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.1), 0 30px 30px 0 rgba(0, 0, 0, 0.08),
    0 8px 16px 0 rgba(0, 0, 0, 0.08);
}

.user-profile.open ul {
  visibility: visible;
  top: 40px;
  left: -215px;
  opacity: 1;
  transition: all 0.5s ease;
}
.user-profile.open ul > li {
  position: relative;
}
.user-profile.open ul > li ul.sub_dropdown {
  position: absolute;
  width: 100%;
  left: initial !important;
  right: 100%;
  top: -80px !important;
  display: none;
}
.user-profile.open ul > li ul.sub_dropdown > li {
  padding: 10px;
}

.profile-img img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
}

.profile-dropdown {
  padding: 0px !important;
  list-style-type: none;
  background: #fff;
  z-index: 9999 !important;
}
.profile-dropdown:before {
  content: " ";
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #fff;
  position: absolute;
  top: -6px;
  right: 12px;
}
.profile-dropdown li .view-profile-sec {
  padding: 0px 20px;
}
.profile-dropdown li > a {
  padding: 10px 20px;
}
.profile-dropdown li > a:hover {
  background-color: #f7f8fa !important;
  color: #2a333e;
}
.profile-dropdown li:hover {
  background: #f7f8fa;
}
.profile-dropdown li .menu-link {
  color: #2a333e;
  padding-left: 63px;
  position: relative;
  display: block;
  background-size: 20px 20px !important;
  background-position: 25px 11px !important;
}
.profile-dropdown li .switch-to-admin {
  /* background: url("../../images/switch-to-admin.svg") no-repeat; */
  background-position: 25px 16px !important;
}
.profile-dropdown li .calender {
  /* background: url("../../images/calendar_menu.svg") no-repeat; */
}
.profile-dropdown li .settings {
  /* background: url("../../images/settings.svg") no-repeat; */
}
.profile-dropdown li .account {
  /* background: url("../../images/account.svg") no-repeat; */
}
.profile-dropdown li .mobile-qr-code {
  /* background: url("../../images/mobile-qr-code.svg") no-repeat; */
}
.profile-dropdown li .change-password {
  /* background: url("../../images/change-password.svg") no-repeat; */
}
.profile-dropdown li .email-settings {
  /* background: url("../../images/email-settings.svg") no-repeat; */
}
.profile-dropdown li .logout {
  /* background: url(../../images/logout.svg) no-repeat; */
  padding: 16px 20px 16px 63px;
  background-position: 26px 14px !important;
}
.profile-dropdown li .language {
  /* background: url("../../images/language-icon.svg") no-repeat; */
}

.dispaly-picture img {
  width: 60px;
  height: 60px !important;
  overflow: hidden;
  border-radius: 50%;
}

/*** Header Css ***/
header.top-header {
  padding: 0px 10px;
  box-shadow: 1px 1px 2px rgba(51, 51, 51, 0.2);
}

/******* ######### ******
    positions
******* ######### ******/
.pos-fixed {
  position: fixed;
}

.pos-relative {
  position: relative;
}

.pos-absolute {
  position: absolute;
}

/******* ######### ******
       left  nav bar
******* ######### ******/
.left-section {
  width: 230px;
  height: calc(100vh - 60px);
  padding: 20px 0px 20px 0px;
  margin-top: 60px;
  box-shadow: 1px 0 0 0 rgba(42, 51, 62, 0.1);
  overflow-y: auto;
  position: fixed;
}
.left-section nav.side-navigation ul {
  padding: 0px;
}
.left-section nav.side-navigation ul li {
  list-style-type: none;
}
.left-section nav.side-navigation ul li a {
  display: block;
  padding: 8px 20px 8px 30px;
  font-size: 13px;
  text-decoration: none;
  border-right: 2px solid transparent;
  opacity: 0.5;
  color: #2a333e;
}
.left-section nav.side-navigation ul li a:hover {
  /*border-right: 2px solid $primary-blue;*/
  opacity: 1;
  background: #f7f8fa;
}
.left-section nav.side-navigation ul li.active {
  border-right: 2px solid var(--primary-color);
}
.left-section nav.side-navigation ul li.active a {
  opacity: 1;
}

.sidebar_full_hight .page-sidebar {
  height: calc(100vh - 69px) !important;
  overflow: auto !important;
}
.sidebar_full_hight .page-content {
  height: initial !important;
}
.sidebar_full_hight .app-footer {
  margin-left: 230px;
}

/******* ######### ******
left nav right container bar
******* ######### ******/
.header-wrapper .rs-header-one {
  display: block;
  /*border-bottom: 2px solid $base-6;*/
}
.header-wrapper .rs-header-one .title {
  /*padding-left: 15px;*/
  font-size: 16px;
  font-weight: normal;
}
.header-wrapper .rs-header-two {
  display: block;
  /*border-bottom: 2px solid $base-6;*/
  min-height: 36px;
}
.header-wrapper .rs-header-two .first-col-checkbox {
  margin-top: -4px;
  margin-left: -3px !important;
}
.header-wrapper .rs-header-two .first-col-checkbox label {
  padding-left: 10px;
}
.header-wrapper .rs-header-three {
  display: block;
  /*border-bottom: 2px solid $base-6;*/
  padding-bottom: 7px;
}
.header-wrapper .rs-header-three .back-btn-wrapper .title-icon {
  text-decoration: none;
  color: #2a333e;
  display: inline-block;
  min-width: 102px;
  width: 100%;
}
.header-wrapper .rs-header-three .back-btn-wrapper .title-icon .icon,
.header-wrapper .rs-header-three .back-btn-wrapper .title-icon itext {
  float: left;
}
.header-wrapper .rs-header-three .back-btn-wrapper .title-icon .icon {
  margin-bottom: -5px;
  padding-right: 10px;
}
.header-wrapper .rs-header-three .back-btn-wrapper .title-icon .text {
  line-height: 28px;
}

/*********************
  Sidebar right side
***********************/
.right_sidebar {
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  display: none;
}
.right_sidebar .sidebar_show_section {
  background: #fff;
  height: 100vh;
  margin: 0;
  padding: 0;
  width: 700px;
  right: -700px;
  position: absolute;
  overflow: auto;
  transition: all 2.5sec ease;
  z-index: 99;
}

.sidebar-opened {
  overflow: hidden !important;
}

.for_sidebar {
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  display: none;
}
.for_sidebar .sidebar_show_section_show_show {
  background: #fff;
  height: 100vh;
  margin: 0;
  padding: 0;
  width: 445px;
  right: -500px;
  position: absolute;
  overflow: auto;
  transition: all 2.5sec ease;
  z-index: 99;
}

.sidebar-opened {
  overflow: hidden !important;
}

/***************
    Table Search
******************/
.search-bar-animation-wrapper input {
  outline: none;
}

.search-bar-animation-wrapper input[type="text"] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  font-family: inherit;
  font-size: 100%;
}

.search-bar-animation-wrapper input::-webkit-search-decoration,
.search-bar-animation-wrapper input::-webkit-search-cancel-button {
  display: none;
}

.search-bar-animation-wrapper input[type="text"] {
  /* background: url(../../../../../images/Icons_latest/search-1.png) no-repeat 9px center; */
  border: solid 1px #ccc;
  padding: 6px 12px;
  background-size: 14px;
  width: 55px;
  border: none;
  -webkit-border-radius: 10em;
  -moz-border-radius: 10em;
  border-radius: 0px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}

.search-bar-animation-wrapper input[type="text"]:focus {
  width: 130px;
  background-color: #fff;
  border-color: var(--primary-color);
  box-shadow: 0 3px 8px 0 rgba(22, 118, 226, 0.16);
  -webkit-box-shadow: none;
  border-bottom: 1px solid var(--primary-color);
  background-position: left;
}

.search-bar-animation-wrapper input:-moz-placeholder {
  color: #999;
}

.search-bar-animation-wrapper input::-webkit-input-placeholder {
  color: #999;
}

/* Demo 2 */
.search-bar-click-animation input[type="text"] {
  width: 15px;
  padding-left: 10px;
  color: transparent;
  cursor: pointer;
  box-shadow: none;
}

.search-bar-click-animation input[type="text"]:hover {
  background-color: #fff;
}

.search-bar-click-animation input[type="text"]:focus {
  width: 130px;
  padding-left: 32px;
  color: #000;
  background-color: #fff;
  cursor: auto;
}

.search-bar-click-animation input:-moz-placeholder {
  color: transparent;
}

.search-bar-click-animation input::-webkit-input-placeholder {
  color: transparent;
}

.db-btn.btn-tertiary.reject-table-row-btn {
  color: #f44336;
}
.db-btn.btn-tertiary.reject-table-row-btn:hover {
  color: #f44336;
}

.btn-prev-page {
  font-family: Roboto;
  font-size: 12px;
  color: #7d8d9f;
  background: none;
  border: initial;
  transition: all 0.8s ease;
  padding: 1px 15px 8px 0px;
  border-radius: 25px;
}
.btn-prev-page df {
  font-size: 18px;
  position: relative;
  top: 3px;
  margin-right: 5px;
}
.btn-prev-page:hover {
  transition: all 0.8s ease;
  background: #e8ecf1;
  padding: 1px 15px 3px 10px;
}
.btn-prev-page:focus {
  outline: none;
}

/*****************
    Table New css
******************/
.table-db {
  width: 100%;
}
.table-db thead {
  border-radius: 2px;
  background-color: #f7f8fa;
  border: solid 1px #e8ecf1;
}
.table-db thead tr th {
  font-size: 12px;
  font-weight: 500;
  color: #7d8d9f;
  padding: 9px 10px;
}
.table-db thead.thead-borderd tr th {
  border: solid 1px #e8ecf1;
  padding: 10px 15px !important;
}
.table-db thead.b-bottom {
  border: initial;
  border-bottom: solid 2px #e8ecf1;
}
.table-db tbody tr {
  box-shadow: inset 0 -1px 0 0 #e8ecf1;
}
.table-db tbody tr td {
  padding: 15px 10px;
  font-size: 14px;
  color: #2a333e;
}

.db-search {
  /* background: #fff url("../../../../../images/Icons_latest/search-1.png") no-repeat 260px 10px !important; */
  background-position: 10px !important;
  padding-left: 36px !important;
  transition: all 0.5s;
}
.db-search.bottom-border {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}
.db-search.bottom-border:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  border-bottom: 1px solid var(--primary-color) !important;
}
.db-search.db-search-sm {
  padding: 0px !important;
  padding-left: 27px !important;
  background-position: 0px !important;
  height: 28px !important;
  background-size: 14px !important;
}
.db-search.search-expand {
  width: 40px;
  transition: all 0.5s;
  float: right;
}
.db-search.search-expand:focus {
  width: 200px;
  transition: all 0.5s;
}

.form-control.db-search.only-icon {
  border: hidden !important;
  background-position: 0px !important;
  padding-left: 12px !important;
  width: 25px !important;
  box-shadow: none !important;
  box-sizing: border-box;
  transition: all 0.4s ease-in-out !important;
}

.form-control.db-search.only-icon:focus {
  padding-left: 26px !important;
  border-bottom: 1px solid var(--primary-color) !important;
  width: 200px !important;
  box-shadow: none !important;
}

.table-fixed-layout {
  table-layout: fixed;
}

/******* ######### ******
        Bulk Action
******* ######### ******/
.bulk-action-wrapper {
  cursor: pointer;
  position: relative;
}

/******* ######### ******
        filter Action
******* ######### ******/
.filter-action-wrapper {
  cursor: pointer;
  position: relative;
}
.filter-action-wrapper df {
  font-size: 20px !important;
  color: #7d8d9f;
}
.filter-action-wrapper .filter-action-option-wrapper {
  min-height: 20px;
  background: white;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.5s ease;
}
.filter-action-wrapper .filter-action-option-wrapper ul {
  padding: 15px 0px;
}
.filter-action-wrapper .filter-action-option-wrapper ul li {
  list-style-type: none;
  padding: 7px 20px;
  color: #2a333e;
}
.filter-action-wrapper .filter-action-option-wrapper ul li span {
  display: inline-block;
}
.filter-action-wrapper .filter-action-option-wrapper ul li .icon {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  margin-right: 15px;
}
.filter-action-wrapper .filter-action-option-wrapper ul li:first-child {
  text-align: center;
}
.filter-action-wrapper .filter-action-option-wrapper.active {
  top: -8px;
  transition: all 0.5 ease;
}

/******* ######### ******
        Tables
******* ######### ******/
table.db-table-one {
  width: 100%;
  /*margin-left: 2px;*/
  margin-bottom: 2px;
  /*margin-top: 2px;*/
}
table.db-table-one.no-row-border tr {
  border-bottom: none !important;
}
table.db-table-one.text-center tr th,
table.db-table-one.text-center tr td {
  text-align: center;
}
table.db-table-one.border-n tr {
  border: none !important;
}
table.db-table-one.border-n tr th,
table.db-table-one.border-n tr td {
  border: none !important;
}
table.db-table-one thead tr {
  background: #f7f8fa;
  border: 1px solid #e8ecf1;
}
table.db-table-one thead tr th {
  padding: 8px 12px;
  color: #7d8d9f !important;
  font-size: 12px;
  font-weight: 600 !important;
}
table.db-table-one thead tr th .sortArrow {
  margin-left: 4px !important;
  border-bottom-color: #7d8d9f !important;
  border-top-color: #7d8d9f !important;
}
table.db-table-one.dark_bg_table thead tr {
  background: #dbe1e8;
  border: 1px solid #dbe1e8;
}
table.db-table-one tbody tr {
  /*box-shadow: inset 0 -1px 0 0 #e8ecf1;*/
  border-bottom: 1px solid #e8ecf1;
  transition: all 0.5 ease;
}
table.db-table-one tbody tr td {
  font-size: 14px;
  color: #4b5c72;
  padding: 15px 12px;
  vertical-align: top;
  word-wrap: break-word;
}
table.db-table-one
  tbody
  tr
  td
  .first-col-checkbox
  .db-checkbox
  [type="checkbox"]:checked
  + label:before,
table.db-table-one
  tbody
  tr
  td
  .first-col-checkbox
  .db-checkbox
  [type="checkbox"]:not(:checked)
  + label:before,
table.db-table-one
  tbody
  tr
  td
  .first-col-checkbox
  .db-checkbox
  [type="checkbox"]:checked
  + label:after,
table.db-table-one
  tbody
  tr
  td
  .first-col-checkbox
  .db-checkbox
  [type="checkbox"]:not(:checked)
  + label:after {
  top: -7px;
}
table.db-table-one tbody tr td a.anchar-column {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #2a333e;
}
table.db-table-one tbody tr td .onhover-row-show {
  /*display: none;*/
  position: absolute;
  right: 30px;
  visibility: hidden;
  opacity: 0;
  min-width: 230px;
}
table.db-table-one tbody tr td .onhover-row-show button.db-btn,
table.db-table-one tbody tr td .onhover-row-show .openrequest {
  cursor: pointer;
  margin-top: -30px;
  transition: all 0.2s ease;
}
table.db-table-one tbody tr td .action_btns {
  visibility: hidden;
  transition: all 0.2s ease;
}
table.db-table-one tbody tr:hover {
  background: #f7f8fa;
}
table.db-table-one tbody tr:hover .action_btns {
  visibility: visible;
  transition: all 0.2s ease;
}

table.db-table-one.hover tbody tr {
  transition: all 0.5s ease;
}
table.db-table-one.hover tbody tr:hover {
  /*border-bottom: transparent;
  box-shadow: $shadow-3;*/
  background-color: #f7f8fa;
  transition: all 0.5s ease;
}
table.db-table-one.hover tbody tr:hover td:last-child {
  padding: 0px;
  padding-right: 15px;
}
table.db-table-one.hover tbody tr:hover td:last-child a {
  font-size: 20px;
}
table.db-table-one.hover tbody tr:hover .onhover-row-show {
  /*//display: block;*/
  visibility: visible;
  opacity: 1;
  margin-top: -11px;
}
table.db-table-one.hover
  tbody
  tr:hover
  .onhover-row-show
  button.db-btn:first-child {
  /*margin-top: -15px;*/
  transition: all 0.2s ease;
}
table.db-table-one.hover
  tbody
  tr:hover
  .onhover-row-show
  button.db-btn:last-child {
  /*margin-top: -15px;*/
  transition: all 0.2s ease;
}
table.db-table-one.hover tbody tr:hover .onhover-row-show .openrequest {
  margin-top: 0px;
  transition: all 0.2s ease;
}
table.db-table-one.hover tbody tr:hover .onhover-row-hide {
  display: none;
}

table.row-table-border {
  width: calc(100% - 10px);
  margin-right: 2px;
  border: 1px solid #e8ecf1;
}

table.full-table-border tbody {
  border: 1px solid #e8ecf1;
}
table.full-table-border th,
table.full-table-border td {
  border-right: 1px solid #e8ecf1;
}

/******* ######### ******
        onhover Drop
******* ######### ******/
.db-drop {
  position: absolute;
  top: 70px;
  visibility: hidden;
  opacity: 0;
  background-color: #fff;
  transition: all 0.1s ease;
  z-index: 9;
  color: #2a333e;
  min-width: 260px;
  border-radius: 2px;
  box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.1), 0 30px 30px 0 rgba(0, 0, 0, 0.08),
    0 8px 16px 0 rgba(0, 0, 0, 0.08);
}

.db-drop:before {
  content: " ";
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #fff;
  position: absolute;
}

.menu-db-drop.db-drop:before {
  top: -6px;
  left: 4px;
}

.header-alerts-db-drop.db-drop:before {
  top: -6px;
  right: 4px;
}

.each_notification .directory-avatar {
  float: left;
  margin-right: 8px;
}
.each_notification .noti-content {
  overflow: hidden;
}
.each_notification img.img-rounded {
  width: 32px;
  height: 32px !important;
  border-radius: 50%;
  margin-right: 15px;
}

.db-drop.header-alerts-db-drop {
  right: 6px;
}

.db-drop-visibale:hover .db-drop {
  top: 35px;
  visibility: visible;
  opacity: 1;
  transition: all 0.5s ease;
}

/******* ######### ******
Menu in header left side of logo
******* ######### ******/
.menu-db-drop .menu-link-wrapper {
  padding: 20px 30px 0px 30px;
  width: 631px;
}
.menu-db-drop .single-menu-item {
     /* float: left; */
     padding: 5px;
     width: 100px;
     min-height: 113px;
}
.menu-db-drop .single-menu-item img {
  max-height: 64px;
  border-radius: 12px;
  background-color: #f7f8fa;
  border: solid 1px #dbe1e8;
  margin-bottom: 5px;
  transition: all 0.5s ease;
}
.menu-db-drop .single-menu-item img:hover {
  box-shadow: 0 10px 30px 0 rgba(42, 51, 62, 0.08),
    0 30px 30px 0 rgba(42, 51, 62, 0.08);
  transition: box-shadow 0.5s ease;
}
.menu-db-drop .single-menu-item p {
  font-size: 12px;
  -webkit-margin-before: -6px;
  -webkit-margin-after: 0px;
  padding-top: 6px;
}

.header-alerts-db-drop {
  width: 320px !important;
  max-height: 420px;
  background-color: #fff;
  position: absolute;
  left: -280px !important;
  display: none;
  border: none;
  border-radius: 2px !important;
}
.header-alerts-db-drop:before {
  content: " ";
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #fff;
  position: absolute;
  right: 9px;
  top: -8px;
}
.header-alerts-db-drop .wrapper-alerts {
  max-height: 420px;
  overflow-y: auto;
  overflow-x: hidden;
}
.header-alerts-db-drop .wrapper-alerts::-webkit-scrollbar {
  width: 8px;
}
.header-alerts-db-drop .wrapper-alerts::-webkit-scrollbar-track {
  background: transparent;
}
.header-alerts-db-drop .wrapper-alerts::-webkit-scrollbar-thumb {
  background: #e8ecf1;
  border-radius: 10px;
}
.header-alerts-db-drop .wrapper-alerts::-webkit-scrollbar-thumb:hover {
  background: #dbe1e8;
}
.header-alerts-db-drop ul {
  display: table;
  width: 100%;
  padding: 0px;
  overflow: auto;
  overflow-x: hidden;
}
.header-alerts-db-drop ul li {
  display: inline-table;
  list-style-type: none;
  padding: 15px 15px;
  box-shadow: 0 1px 0 0 #e8ecf1;
  vertical-align: middle;
  position: relative;
  width: 320px;
}
.header-alerts-db-drop ul li .indicator {
  position: absolute;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  top: 20px;
  /*float: left;*/
  right: 22px;
}
.header-alerts-db-drop ul li .msg {
  padding: 0px 15px;
  padding-right: 30px;
  /*float: left;*/
  padding-left: 0px;
}
.header-alerts-db-drop ul li .msg a {
  color: var(--primary-color);
}
.header-alerts-db-drop ul li .time {
  margin-top: 4px;
}
.header-alerts-db-drop ul li:hover {
  background: #f7f8fa;
}
.header-alerts-db-drop ul li.no-hover:hover {
  background: initial !important;
}
.header-alerts-db-drop ul li:last-child {
  box-shadow: none;
}

/******* ######### ******
       position
******* ######### ******/
.ps-relative {
  position: relative;
}

.ps-absolute {
  position: absolute;
}

.ps-fixed {
  position: fixed;
}

/******* ######### ******
        Avtar
******* ######### ******/
.avtar-icon-name {
  /*width: 100%;*/
  /*min-width: 140px;*/
  width: initial;
}
.avtar-icon-name .small-avtar {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #e8ecf1;
  color: #4b5c72;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  line-height: 36px;
}
.avtar-icon-name .small-avtar img {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  overflow: hidden;
}
.avtar-icon-name .avtar-name {
  width: calc(100% - 36px);
}
.avtar-icon-name .username-circle {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  background-color: #e8ecf1;
  color: #4b5c72;
}

/******* ######### ******
    time stamp with icon
******* ######### ******/
.time-stamp-icon .icon {
  font-size: 16px;
  padding-right: 15px;
}
.time-stamp-icon a {
  text-decoration: none;
}

/******* ######### ******
    bottom btn action
******* ######### ******/
.bottom-btns-action-wrapper {
  position: fixed;
  display: none;
  background: #fff;
  z-index: 10;
  bottom: 0px;
  padding: 22px 0px;
  width: 100%;
  border-top: 1px solid #e8ecf1;
}

/******* ######### ******
   information container
******* ######### ******/
.db-info-container {
  padding: 14px 0px;
  border-bottom: 1px solid #e8ecf1;
}

/******* ######### ******
   tabs
******* ######### ******/
.db-top-tabs .nav-tabs {
  border-bottom: none !important;
}
.db-top-tabs .nav-tabs li {
  padding-left: 15px;
}
.db-top-tabs .nav-tabs li:first-child {
  padding-left: 0px;
}
.db-top-tabs .nav-tabs li a {
  text-decoration: none;
  color: #4b5c72;
  text-transform: uppercase;
  /*border-bottom: 2px solid transparent;*/
  margin-top: 5px;
  font-size: 13px;
  padding-left: 0px;
  padding-right: 0px;
  border: none;
  font-weight: 500;
}
.db-top-tabs .nav-tabs li a:hover {
  border-color: #fff #fff #fff #fff;
  border-bottom: none;
  background-color: white;
  /*border-bottom: 2px solid #e8ecf1 !important;*/
}
.db-top-tabs .nav-tabs a.active {
  border: none;
  border-bottom: 2px solid var(--primary-color);
  color: var(--primary-color);
  margin-top: 5px;
}
.db-top-tabs .right-icons {
  display: block;
  float: right;
  margin-top: 6px;
}
.db-top-tabs
  .right-icons
  .search-bar-click-animation
  input[type="search"]:focus {
  margin-top: 1px;
}
.db-top-tabs .right-icons .db-more-data-wrapper {
  top: 30px;
  right: 8px;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.24), 0 6px 10px 0 rgba(0, 0, 0, 0.08),
    0 1px 3px 0 rgba(0, 0, 0, 0.08);
  border-radius: 2px;
  padding: 10px 0px;
}
.db-top-tabs .right-icons .db-more-data-wrapper img {
  position: absolute;
  transform: rotate(180deg);
  top: -10px;
  right: 5px;
}
.db-top-tabs .right-icons .db-more-data-wrapper ul {
  margin: 0px;
  padding: 0px;
}
.db-top-tabs .right-icons .db-more-data-wrapper ul li {
  list-style-type: none;
}
.db-top-tabs .right-icons .db-more-data-wrapper ul li a {
  display: block;
  width: 100%;
  padding: 6px 20px;
  color: #2a333e;
  text-decoration: none;
}
.db-top-tabs .right-icons .db-more-data-wrapper ul li a:hover {
  background-color: #f7f8fa;
}

/******* ######### ******
   label status
******* ######### ******/
.db-lable-status {
  padding: 2px 9px;
  color: #2a333e;
}

/******* ######### ******
   Circles
******* ######### ******/
.vs-circle {
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

/******* ######### ******
   notification indicator
******* ######### ******/
.notification-indicator:after {
  content: "";
  position: absolute;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: #f44336;
  top: -3px;
  right: -3px;
}

/******* ######### ******
   notification indicator
******* ######### ******/
.previous-navigation {
  margin: 0px;
  padding: 0px;
}
.previous-navigation li {
  list-style-type: none;
  display: inline-block;
}
.previous-navigation li:before {
  content: "\e914";
  font-family: "darwinbox" !important;
  font-size: 20px;
  position: relative;
  top: 5px;
}
.previous-navigation li:first-child {
  padding-left: 0px;
}
.previous-navigation li:first-child:before {
  content: "";
}
.previous-navigation li a {
  text-decoration: none;
  color: #2a333e;
}
.previous-navigation li.active {
  color: #7d8d9f !important;
}

/******* ######### ******
wrapper-versionshow
******* ######### ******/
.wrapper-version {
  display: none;
}
.wrapper-version .wrapper-versionshow {
  min-height: 10px;
  border: 1px solid #c4dcf7;
  background: #edf4fd;
  padding: 10px 20px;
  display: flex;
  position: relative;
}
.wrapper-version .wrapper-versionshow .each-dateversion-wrapper {
  padding: 10px;
  float: left;
}
.wrapper-version
  .wrapper-versionshow
  .each-dateversion-wrapper
  .each-dateversion {
  font-size: 12px;
  padding: 4px 12px;
  background: #fff;
  border: 1px solid #c4dcf7;
  border-radius: 15px;
  text-align: center;
  cursor: pointer;
}
.wrapper-version
  .wrapper-versionshow
  .each-dateversion-wrapper
  .each-dateversion.active {
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
}
.wrapper-version .wrapper-versionshow:after {
  content: " ";
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #edf4fd;
  position: absolute;
  top: -7px;
  right: 34px;
}
.wrapper-version .wrapper-versionshow:before {
  content: " ";
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 9px solid #c4dcf7;
  position: absolute;
  top: -9px;
  right: 33px;
}

/******* ######### ******
    data table with hreade and column fixed
******* ######### ******/
.dataTables_wrapper {
  padding-bottom: 50px;
}

.db-data-Table-one-wrapper table.DTFC_Cloned thead tr,
.db-data-Table-one-wrapper
  .dataTables_scrollHeadInner
  .db-data-Table-one.dataTable,
.db-data-Table-one-wrapper .dataTables_scrollHeadInner {
  background: #f7f8fa;
}
.db-data-Table-one-wrapper .dataTables_scroll,
.db-data-Table-one-wrapper .DTFC_LeftHeadWrapper .db-data-Table-one.dataTable {
  border: 1px solid #e8ecf1;
  border-bottom: none;
}
.db-data-Table-one-wrapper
  .DTFC_LeftBodyWrapper
  .DTFC_LeftBodyLiner
  .db-data-Table-one.dataTable {
  border-left: 1px solid #e8ecf1;
}
.db-data-Table-one-wrapper table.dataTable thead th,
.db-data-Table-one-wrapper table.dataTable thead td,
.db-data-Table-one-wrapper .dataTables_wrapper.no-footer .dataTables_scrollBody,
.db-data-Table-one-wrapper .DTFC_LeftHeadWrapper {
  border-bottom: 1px solid #e8ecf1;
}
.db-data-Table-one-wrapper .dataTables_scrollHead td,
.db-data-Table-one-wrapper th {
  border-right: 1px solid #e8ecf1;
}
.db-data-Table-one-wrapper .DTFC_LeftHeadWrapper .db-data-Table-one.dataTable {
  border-right: none;
  border-bottom: none;
}
.db-data-Table-one-wrapper .dataTables_scrollHead td:last-child,
.db-data-Table-one-wrapper .dataTables_scrollHead th:last-child,
.db-data-Table-one-wrapper
  .dataTables_scrollHead
  .dataTables_scrollHeadInner
  tr
  th:nth-child(2) {
  border-right: none !important;
}
.db-data-Table-one-wrapper .DTFC_LeftHeadWrapper .db-data-Table-one.dataTable {
  border-top: none;
}
.db-data-Table-one-wrapper .dataTables_scrollBody tbody tr,
.db-data-Table-one-wrapper .DTFC_LeftBodyLiner tbody tr {
  border-bottom: 1px solid #e8ecf1;
}
.db-data-Table-one-wrapper table.dataTable {
  border-collapse: collapse;
}
.db-data-Table-one-wrapper .DTFC_LeftHeadWrapper table,
.db-data-Table-one-wrapper .DTFC_LeftHeadWrapper tr,
.db-data-Table-one-wrapper .DTFC_LeftHeadWrapper th,
.db-data-Table-one-wrapper .DTFC_LeftHeadWrapper td,
.db-data-Table-one-wrapper .DTFC_LeftHeadWrapper thead,
.db-data-Table-one-wrapper .DTFC_LeftHeadWrapper tbody {
  border-bottom: transparent !important;
}
.db-data-Table-one-wrapper .DTFC_LeftWrapper {
  top: 1px !important;
}
.db-data-Table-one-wrapper .dataTables_scrollBody::-webkit-scrollbar-track {
  background-color: #f7f8fa;
  width: 10px !important;
}
.db-data-Table-one-wrapper .dataTables_scrollBody::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}
.db-data-Table-one-wrapper .dataTables_scrollBody::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}
.db-data-Table-one-wrapper .dataTables_scrollBody::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);*/
  background-color: #e8ecf1 !important;
  /* border: none !important; */
  border-left: 2px solid #f7f8fa;
  border-right: 2px solid #f7f8fa;
  border-top: none !important;
  border-bottom: none !important;
}
.db-data-Table-one-wrapper .dataTables_scrollBody::-webkit-scrollbar-track {
  /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
  background-color: #f7f8fa;
}
.db-data-Table-one-wrapper .dataTables_scrollBody::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  background-color: #f5f5f5;
}
.db-data-Table-one-wrapper .dataTables_scrollBody::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}
.db-data-Table-one-wrapper
  .dataTables_scroll
  .dataTables_scrollBody::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);*/
  background-color: #e8ecf1 !important;
  /* border: none !important; */
  border-top: 2px solid #f7f8fa !important;
  border-bottom: 2px solid #f7f8fa !important;
  border-left: 2px solid #f7f8fa !important;
  border-right: 2px solid #f7f8fa !important;
}
.db-data-Table-one-wrapper .DTFC_LeftHeadWrapper th,
.db-data-Table-one-wrapper .dataTables_scrollHead th {
  font-size: 11px;
  font-weight: 500;
  color: #2a333e;
}
.db-data-Table-one-wrapper .DTFC_LeftBodyLiner th,
.db-data-Table-one-wrapper .DTFC_LeftBodyLiner td,
.db-data-Table-one-wrapper .dataTables_scrollBody th,
.db-data-Table-one-wrapper .dataTables_scrollBody td {
  font-size: 13px;
  font-weight: normal;
}
.db-data-Table-one-wrapper .mb-3 {
  margin-bottom: 3px !important;
}
.db-data-Table-one-wrapper .DTFC_LeftBodyWrapper {
  overflow: hidden !important;
}

/******* ######### ******
    data table normal
******* ######### ******/
.db-dataTable-container .dataTables_wrapper .table.dataTable {
  box-shadow: inset 0 -2px 0 0 #dbe1e8;
}
.db-dataTable-container .dataTables_wrapper .table.dataTable thead {
  box-shadow: inset 0 -2px 0 0 #dbe1e8;
}
.db-dataTable-container .dataTables_wrapper .table.dataTable thead tr {
  background-color: #f7f8fa;
}
.db-dataTable-container .dataTables_wrapper .table.dataTable thead tr th {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #7d8d9f;
  position: relative;
}
.db-dataTable-container
  .dataTables_wrapper
  .table.dataTable
  thead
  tr
  th.sorting {
  /*&:after{
      display: none;
  }*/
}
.db-dataTable-container
  .dataTables_wrapper
  .table.dataTable
  thead
  tr
  th.sorting:before {
  content: "\e904";
  font-family: "darwinbox" !important;
  font-size: 16px;
  position: absolute;
  top: calc(50% - 10px);
  right: 5px;
  color: #7d8d9f;
  opacity: 0.6;
}
.db-dataTable-container
  .dataTables_wrapper
  .table.dataTable
  thead
  tr
  th.sorting:after {
  content: "\e905";
  font-family: "darwinbox" !important;
  font-size: 16px;
  position: absolute;
  top: calc(50% - 15px);
  color: #7d8d9f;
  opacity: 0.6;
  right: 5px;
}
.db-dataTable-container
  .dataTables_wrapper
  .table.dataTable
  thead
  tr
  th.sorting_desc:after {
  content: "\e904";
  font-family: "darwinbox" !important;
  font-size: 16px;
  position: absolute;
  top: calc(50% - 15px);
  color: #7d8d9f;
  opacity: 1;
}
.db-dataTable-container
  .dataTables_wrapper
  .table.dataTable
  thead
  tr
  th.sorting_asc:after {
  content: "\e905";
  font-family: "darwinbox" !important;
  font-size: 16px;
  position: absolute;
  top: calc(50% - 15px);
  color: #7d8d9f;
  opacity: 1;
}
.db-dataTable-container
  .dataTables_wrapper
  .table.dataTable
  tbody
  tr:hover
  .data_table_act_btns {
  display: block !important;
}
.db-dataTable-container
  .dataTables_wrapper
  .table.dataTable
  tbody
  tr
  td:last-child {
  position: relative;
}
.db-dataTable-container
  .dataTables_wrapper
  .table.dataTable
  tbody
  tr
  td:last-child
  .data_table_act_btns {
  position: absolute;
  right: -35px;
  top: 50%;
  transform: translateY(-50%);
  display: none;
}
.db-dataTable-container
  .dataTables_wrapper
  .table.dataTable
  tbody
  tr
  td:last-child
  a.onhover-show {
  font-size: 18px;
}
.db-dataTable-container .dataTables_wrapper .table.dataTable tr th,
.db-dataTable-container .dataTables_wrapper .table.dataTable tr td {
  border-left: none !important;
  border-top: none !important;
  border-bottom: 1px solid #e8ecf1;
}
.db-dataTable-container .dataTables_wrapper .dataTable-header {
  margin-top: 8px;
}
.db-dataTable-container
  .dataTables_wrapper
  .dataTable-header
  .dataTables_filter {
  float: left !important;
}
.db-dataTable-container .dataTables_wrapper .dataTable-header .dataTables_info {
  padding: 0px;
  clear: unset;
  float: right;
  padding-right: 10px !important;
  padding-top: 8px;
  font-size: 13px !important;
  color: #4b5c72 !important;
}
.db-dataTable-container
  .dataTables_wrapper
  .dataTable-header
  .dataTables_paginate {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.db-dataTable-container
  .dataTables_wrapper
  .dataTable-header
  .dataTables_paginate
  .paginate_button {
  border: none !important;
  text-align: center;
  font-size: 16px;
  color: #4b5c72 !important;
  padding: 0px 4px !important;
  /*cursor: pointer;*/
}
.db-dataTable-container
  .dataTables_wrapper
  .dataTable-header
  .dataTables_paginate
  .paginate_button:hover {
  background-color: transparent !important;
}
.db-dataTable-container
  .dataTables_wrapper
  .dataTable-header
  .dataTables_paginate
  .paginate_button
  i.fa {
  width: 36px;
  height: 36px;
  position: relative;
  transition: border-color 0.2s ease-in-out;
  border-radius: 100%;
  line-height: 38px;
  text-align: center;
  display: inline-block;
  line-height: 32px;
  font-size: 24px;
}
.db-dataTable-container
  .dataTables_wrapper
  .dataTable-header
  .dataTables_paginate
  .paginate_button
  i.fa:after {
  content: " ";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0px;
  height: 0px;
  background: rgba(75, 92, 114, 0.15);
  transition: all 0.2s ease-in-out;
  border-radius: 100%;
}
.db-dataTable-container
  .dataTables_wrapper
  .dataTable-header
  .dataTables_paginate
  .paginate_button
  .fa
  > i {
  color: #e8ecf1;
}
.db-dataTable-container
  .dataTables_wrapper
  .dataTable-header
  .dataTables_paginate
  .paginate_button
  i.fa:hover:after {
  top: -2px;
  left: 0px;
  width: 36px;
  height: 36px;
}
.db-dataTable-container
  .dataTables_wrapper
  .dataTable-header
  .dataTables_paginate
  span {
  display: none !important;
}
.db-dataTable-container
  .dataTables_wrapper
  .dataTable-header
  .dataTables_filter
  input {
  font-size: 14px;
  padding: 3px 12px;
  border-radius: 2px;
  border: 1px solid #dbe1e8;
  color: #353f4c;
  -webkit-text-fill-color: inherit;
  box-shadow: none;
  caret-color: var(--primary-color);
  background: #fff;
  /* background: #fff url("../../../../../images/Icons_latest/search-1.png") no-repeat 260px 10px !important; */
  padding-left: 36px !important;
  border: hidden !important;
  background-position: 0px !important;
  padding-left: 12px !important;
  width: 25px !important;
  box-sizing: border-box;
  transition: all 0.4s ease-in-out !important;
}
.db-dataTable-container
  .dataTables_wrapper
  .dataTable-header
  .dataTables_filter
  input:hover {
  cursor: pointer;
}
.db-dataTable-container
  .dataTables_wrapper
  .dataTable-header
  .dataTables_filter
  input:focus {
  padding-left: 26px !important;
  border-bottom: 1px solid var(--primary-color) !important;
  width: 150px !important;
  cursor: text !important;
}
.db-dataTable-container .dataTables_wrapper .footer-toolbar {
  width: 100%;
  float: left;
}
.db-dataTable-container
  .dataTables_wrapper
  .footer-toolbar
  .dataTables_paginate {
  float: unset !important;
}
.db-dataTable-container
  .dataTables_wrapper
  .footer-toolbar
  .dataTables_paginate.paging_simple_numbers {
  margin: auto !important;
  text-align: center;
}
.db-dataTable-container
  .dataTables_wrapper
  .footer-toolbar
  .dataTables_paginate
  .paginate_button {
  border: none !important;
  height: 36px;
  width: 36px;
  text-align: center;
  line-height: 24px;
  font-size: 12px;
  color: #4b5c72 !important;
  /*cursor: pointer !important;*/
}
.db-dataTable-container
  .dataTables_wrapper
  .footer-toolbar
  .dataTables_paginate
  .paginate_button:hover {
  border: none !important;
  background-color: transparent !important;
}
.db-dataTable-container
  .dataTables_wrapper
  .footer-toolbar
  .dataTables_paginate
  .paginate_button.previous,
.db-dataTable-container
  .dataTables_wrapper
  .footer-toolbar
  .dataTables_paginate
  .paginate_button.next {
  font-size: 18px !important;
  padding: 0px !important;
}
.db-dataTable-container
  .dataTables_wrapper
  .footer-toolbar
  .dataTables_paginate
  span {
  display: inline-block;
  position: relative;
  top: -5px;
}
.db-dataTable-container
  .dataTables_wrapper
  .footer-toolbar
  .dataTables_paginate
  span.ellipsis {
  top: 5px !important;
}
.db-dataTable-container
  .dataTables_wrapper
  .footer-toolbar
  .dataTables_paginate
  span
  a.paginate_button {
  margin: 0px 4px;
  padding: 6px 0px;
}
.db-dataTable-container
  .dataTables_wrapper
  .footer-toolbar
  .dataTables_paginate
  span
  a.paginate_button:hover {
  background-color: #e8ecf1 !important;
  border-radius: 50%;
}
.db-dataTable-container
  .dataTables_wrapper
  .footer-toolbar
  .dataTables_paginate
  span
  a.paginate_button.current {
  border: 1px solid var(--primary-color) !important;
  color: var(--primary-color) !important;
  background-color: #fff !important;
  border-radius: 50%;
}
.db-dataTable-container.arrowright-bigdata table.dataTable thead tr th {
  padding-right: 22px !important;
}

.db-design-table table.dataTable thead tr th {
  position: relative;
}
.db-design-table table.dataTable thead tr th.sorting {
  /*&:after{
      display: none;
  }*/
}
.db-design-table table.dataTable thead tr th.sorting:before {
  content: "\e904";
  font-family: "darwinbox" !important;
  font-size: 16px;
  position: absolute;
  top: calc(50% - 10px);
  right: 5px;
  color: #7d8d9f;
  opacity: 0.6;
}
.db-design-table table.dataTable thead tr th.sorting:after {
  content: "\e905";
  font-family: "darwinbox" !important;
  font-size: 16px;
  position: absolute;
  top: calc(50% - 15px);
  color: #7d8d9f;
  opacity: 0.6;
  right: 5px;
}
.db-design-table table.dataTable thead tr th.sorting_desc:after {
  content: "\e904";
  font-family: "darwinbox" !important;
  font-size: 16px;
  position: absolute;
  top: calc(50% - 15px);
  color: #7d8d9f;
  opacity: 1;
}
.db-design-table table.dataTable thead tr th.sorting_asc:after {
  content: "\e905";
  font-family: "darwinbox" !important;
  font-size: 16px;
  position: absolute;
  top: calc(50% - 15px);
  color: #7d8d9f;
  opacity: 1;
}

/******* ######### ******
    Icon hover effect
******* ######### ******/
.fill-circle {
  width: 36px;
  height: 36px;
  position: relative;
  transition: border-color 0.2s ease-in-out;
  border-radius: 100%;
  line-height: 36px;
  text-align: center;
  display: inline-block;
  font-size: 24px;
}
.fill-circle:after {
  content: " ";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0px;
  height: 0px;
  background: rgba(75, 92, 114, 0.15);
  transition: all 0.2s ease-in-out;
  border-radius: 100%;
}

.fill-circle:hover:after {
  top: 0;
  left: 0px;
  width: 36px;
  height: 36px;
}

/*span.fill-circle label {
z-index: 9;
left: 8px;
top: -2px;
}*/
/******* ######### ******
    Loader
******* ######### ******/
#loader-wrapper {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.8);
  top: 0px;
  left: 0px;
  z-index: 9999;
  text-align: center;
  opacity: 1;
  display: none;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.loader {
  margin: auto;
  margin-top: 20%;
  font-size: 4px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgb(22, 118, 226);
  border-right: 1.1em solid rgba(22, 118, 226, 0.2);
  border-bottom: 1.1em solid rgba(22, 118, 226, 0.2);
  border-left: 1.1em solid rgb(22, 118, 226);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/******* ######### ******
    Custom model
******* ######### ******/
.db-custom-modal {
  padding-right: 0px !important;
}
.db-custom-modal .db-custom-modal-header {
  border-bottom: none;
  padding: 0px;
}
.db-custom-modal .db-custom-modal-header .close-modal {
  font-size: 20px;
  position: absolute;
  top: 15px;
  right: 15px;
  color: #7d8d9f;
  cursor: pointer;
}
.db-custom-modal .modal-dialog {
  background: #fff;
  padding: 40px;
}
.db-custom-modal .modal-dialog.modal-full {
  width: 100%;
  margin: 0;
}
.db-custom-modal .modal-content {
  padding: 0px;
  box-shadow: none;
}

.db-custom-modal.fade.in {
  background: rgba(75, 92, 114, 0.6) !important;
}

.db-tooltip .tooltip.fade.in {
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.24), 0 6px 10px 0 rgba(0, 0, 0, 0.08),
    0 1px 3px 0 rgba(0, 0, 0, 0.08) !important;
  background-color: #ffffff !important;
  border-radius: 2px !important;
}
.db-tooltip .tooltip .tooltip-inner {
  background-color: #ffffff !important;
  color: #2a333e !important;
}
.db-tooltip .tooltip .tooltip-arrow {
  border-bottom-color: transparent !important;
  border-top-color: transparent !important;
}

.db-popover.fade.in {
  border-radius: 0 !important;
  box-shadow: 0 2px 24px 0 rgba(188, 198, 214, 0.5) !important;
  border: none !important;
  min-width: 200px;
}
/******* ######### ******
      Resume Upload
******* ######### ******/
.resume-upload label {
  font-size: 12px;
  text-transform: uppercase;
  color: var(--primary-color);
  cursor: pointer;
  font-weight: 500;
  margin: 0px;
}
.resume-upload label df.db-Upload {
  font-size: 22px;
  position: relative;
  top: 3px;
  margin-right: 5px;
}
.resume-upload input[type="file"] {
  display: none;
}
.resume-upload .slected-filename {
  display: none;
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.resume-upload-bulk label {
  font-size: 12px;
  text-transform: uppercase;
  color: var(--primary-color);
  cursor: pointer;
  font-weight: 500;
  margin: 0px;
}
.resume-upload-bulk label df.db-Upload {
  font-size: 22px;
  position: relative;
  top: 3px;
  margin-right: 5px;
}
.resume-upload-bulk input[type="file"] {
  display: none;
}
.resume-upload-bulk .slected-filename {
  display: none;
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
}

/******* ######### ******
   Popover tooltip
******* ######### ******/
.popover-tooltip .arrow {
  display: none;
}
.popover-tooltip .popover {
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.24), 0 6px 10px 0 rgba(0, 0, 0, 0.08),
    0 1px 3px 0 rgba(0, 0, 0, 0.08) !important;
}
.popover-tooltip .popover.top {
  margin-top: -4px !important;
}
.popover-tooltip .popover.top .popover-content {
  padding: 6px 12px !important;
  color: #4b5c72 !important;
}
.popover-tooltip .tooltip-container {
  color: #4b5c72 !important;
  font-size: 12px !important;
  line-height: 1.42 !important;
}

.db-tootip .tooltip.fade.in {
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.24), 0 6px 10px 0 rgba(0, 0, 0, 0.08),
    0 1px 3px 0 rgba(0, 0, 0, 0.08) !important;
  background-color: #ffffff !important;
  border-radius: 2px !important;
}
.db-tootip .tooltip .tooltip-inner {
  background-color: #ffffff !important;
  color: #2a333e !important;
}
.db-tootip .tooltip .tooltip-arrow {
  border-bottom-color: transparent !important;
  border-top-color: transparent !important;
}

/******* ######### ******
  left-sidebar-layout
******* ######### ******/
.sidbar-layout {
  /*padding-top: 70px;*/
}

.page-content {
  margin-left: 230px;
  min-height: 100%;
  margin-top: 0;
  padding: 0;
  overflow-y: auto;
  position: relative;
  min-height: 100%;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  height: calc(100vh - 135px);
  padding: 20px 20px;
}

.page-sidebar {
  height: 100%;
  font-family: roboto;
  -webkit-backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  display: inline-block;
  float: left;
  position: fixed;
  width: 230px;
  background: #f7f8fa;
  height: calc(100vh - 128px);
  overflow: hidden;
  border-right: 1px solid #dbe1e8;
  padding: 20px;
  overflow: auto;
}
.page-sidebar::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
  background-color: #f7f8fa !important;
  transition: all 0.5 ease;
}
.page-sidebar::-webkit-scrollbar-thumb {
  background-color: #dbe1e8 !important;
  border: 4px solid transparent !important;
  border-radius: 9px !important;
  background-clip: content-box !important;
  transition: all 0.5 ease;
}
.page-sidebar:hover::-webkit-scrollbar-thumb {
  border: initial !important;
  transition: all 0.5 ease;
}

.left-sidebar:after {
  content: "";
  clear: both;
  display: block;
}
.left-sidebar .max-width-150 {
  width: 150px;
  margin: 0px auto;
}
.left-sidebar .user-profile-details {
  text-align: center;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #dbe1e8;
}
.left-sidebar .user-profile-details .profile_avatar img {
  width: 60px;
  height: 60px;
  border-radius: 50% !important;
}
.left-sidebar .user-profile-details .user-profile-info h3,
.left-sidebar .user-profile-details .user-profile-info p {
  font-family: "Roboto", sans-serif;
  transition: all 0.5s ease;
}
.left-sidebar .user-profile-details .user-profile-info h3:hover {
  color: var(--primary-color) !important;
  transition: all 0.5s ease;
}
.left-sidebar #side-nav > ul > li > a {
  font-size: 14px;
  color: #2a333e;
  padding: 8px 0px 8px 32px;
  position: relative;
  transition: all 0.5s ease;
}
.left-sidebar #side-nav > ul > li > a:before {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat !important;
  left: 0px;
}
.left-sidebar #side-nav > ul > li > a:hover {
  color: var(--primary-color) !important;
  transition: all 0.5s ease;
}
.left-sidebar #side-nav > ul > li > a.active {
  font-weight: 500;
  color: #2a333e;
}
.left-sidebar #side-nav > ul > li > a.dbProfile:before {
  /* background: url("../../images/sideNav/profile.svg"); */
  left: 2px;
}
.left-sidebar #side-nav > ul > li > a.dbCompensation:before {
  /* background: url("../../images/sideNav/compensation.svg"); */
  top: 5px;
  left: -2px;
}
.left-sidebar #side-nav > ul > li > a.dbAttendance:before {
  /* background: url("../../images/sideNav/attendance.svg"); */
  top: 5px;
}
.left-sidebar #side-nav > ul > li > a.dbLeave:before {
  /* background: url("../../images/sideNav/leaves.svg"); */
  top: 3px;
}
.left-sidebar #side-nav > ul > li > a.dbAssignedPolicies:before {
  /* background: url("../../images/sideNav/PolicyAssign.svg"); */
  top: 5px;
}
.left-sidebar #side-nav > ul > li > a.dbReimbursement:before {
  /* background: url("../../images/sideNav/reim.svg"); */
  top: 5px;
}
.left-sidebar #side-nav > ul > li > a.dbEmployeeLifeCycle:before {
  /* background: url("../../images/sideNav/Employee_life_cycle.svg"); */
  top: 5px;
}
.left-sidebar #side-nav > ul > li > a.dbBenefits:before {
  /* background: url("../../images/sideNav/benifits.svg"); */
}
.left-sidebar #side-nav > ul > li > a.dbFutureTransactions:before {
  /* background: url("../../images/sideNav/Future_transaction.svg"); */
  top: 5px;
}
.left-sidebar #side-nav > ul > li > a.dbPerformance:before {
  /* background: url("../../images/sideNav/performance.svg"); */
}
.left-sidebar #side-nav > ul > li > a.dbRecognition:before {
  /* background: url("../../images/sideNav/randr.svg"); */
  top: 3px;
}
.left-sidebar #side-nav > ul > li > a.dbProject:before {
  /* background: url("../../images/sideNav/project-goals.svg"); */
  top: 3px;
}
.left-sidebar #side-nav > ul > li > a.dbTalentAssesment:before {
  /* background: url("../../images/sideNav/talent-assessment.svg"); */
  top: 3px;
}
.left-sidebar #side-nav > ul > li > a.dbTravel:before {
  /* background: url("../../images/sideNav/travel.svg"); */
  top: 3px;
}
.left-sidebar #side-nav > ul > li > a.dbTimesheet:before {
  /* background: url("../../images/sideNav/timesheet.svg"); */
  top: 3px;
}
.left-sidebar #side-nav > ul > li > a.dbPermissions:before {
  /* background: url("../../images/sideNav/permissions-icon.svg"); */
  top: 3px;
}
.left-sidebar #side-nav > ul li.has-submenu > a {
  position: relative;
}
.left-sidebar #side-nav > ul li.has-submenu > a:after {
  content: "\e914";
  position: absolute;
  font-family: "darwinbox";
  font-size: 18px;
  font-weight: 100;
  top: 7px;
  right: 0;
  transition: all 0.5s ease;
}
.left-sidebar #side-nav > ul li.has-submenu > a:hover:after {
  right: -4px;
  transition: all 0.5s ease;
}
.left-sidebar #side-nav > ul li.has-submenu > a.open:after {
  content: "\e901" !important;
}
.left-sidebar #side-nav ul {
  padding: 0px;
  margin: 0px;
}
.left-sidebar #side-nav ul li {
  list-style-type: none !important;
}
.left-sidebar #side-nav ul li a {
  display: block;
  font-family: "Roboto", sans-serif;
}
.left-sidebar #side-nav ul li .sub-nav {
  display: none;
}
.left-sidebar #side-nav ul li .sub-nav li a {
  padding: 5px 13px;
  font-size: 12px;
  color: #4b5c72;
  transition: all 0.5s ease;
  margin-left: 18px;
  border-radius: 25px;
  font-family: "Roboto", sans-serif;
}
.left-sidebar #side-nav ul li .sub-nav li a:hover {
  background: #e8ecf1;
  transition: all 0.5s ease;
}
.left-sidebar #side-nav ul li .sub-nav li a.active {
  color: var(--primary-color);
  font-weight: 700;
}

.social-links {
  /*padding: 20px 0 0 0;*/
}
.social-links a {
  margin: 0px 5px;
  padding: 0px;
  display: inline-block;
  color: #fff;
  font-family: "Roboto", sans-serif;
  display: iniline-block;
}
.social-links a i {
  padding: 0px;
}
.social-links a.facebook {
  background: #395196;
}
.social-links a.linkedin {
  background: #3d83d9;
}
.social-links a.resume {
  background: #7d8d9f;
}
.social-links a.no-file {
  background: #ddd !important;
  color: #b1b1b1 !important;
}

.level-three-nav ul {
  padding: 0px;
  margin-bottom: 24px;
}
.level-three-nav ul li {
  display: inline-block;
}
.level-three-nav ul li a {
  text-transform: uppercase;
  font-size: 12px;
  color: #2a333e;
  font-weight: 500;
  position: relative;
  padding: 5px 10px;
  display: inline-block;
  font-family: "Roboto", sans-serif;
}
.level-three-nav ul li a.active {
  color: var(--primary-color);
}
.level-three-nav ul li a.active:after {
  content: "";
  border-bottom: 2px solid var(--primary-color);
  position: absolute;
  width: calc(100% - 20px);
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0px auto;
  z-index: 2;
}
.level-three-nav ul li a .count_label {
  position: absolute;
  top: 0;
  right: -12px;
  background: #f44336;
  color: #fff;
  min-width: 18px;
  text-align: center;
  border-radius: 18px;
  height: 18px;
  line-height: 18px;
  padding: 0px 5px;
}

.dbt-change-menu {
  height: 30px;
  position: relative;
  z-index: 1;
}
.dbt-change-menu ul {
  padding: 0px;
}
.dbt-change-menu ul li {
  display: inline-block;
  margin-right: -3px;
}
.dbt-change-menu ul li a {
  border-radius: 2px;
  background-color: #e8ecf1;
  padding: 5px 15px;
  color: #4b5c72;
  font-size: 13px;
  border: solid 1px #dbe1e8;
  background-color: #ffffff;
}
.dbt-change-menu ul li a.active {
  background: var(--primary-color) !important;
  border: solid 1px var(--primary-color);
  color: #ffffff;
  box-shadow: 0 16px 20px 0 rgba(22, 118, 226, 0.3),
    0 6px 10px 0 rgba(22, 118, 226, 0.3);
  position: relative;
  z-index: 1;
}

/******* ######### ******
        progress
******* ######### ******/
.db-progress {
  padding: 0px;
  background: #dbe1e8;
  height: 6px;
  box-shadow: initial !important;
}
.db-progress .progress-bar {
  background: var(--primary-color);
  padding: 0px;
}
.db-progress.pg-green {
  background: #caf2db;
}
.db-progress.pg-green .progress-bar {
  background: #1ac964;
}

.cvicon-sizechange img {
  width: 32px;
}

/******* ######### ******
   DB custom Modal
******* ######### ******/
.db-customized-modal {
  background: rgba(75, 92, 114, 0.6) !important;
  padding-right: 0px;
}
.db-customized-modal .modal-sm {
  width: 460px;
}
.db-customized-modal .full-page-modal {
  width: 100% !important;
  margin: 0px !important;
  max-width: none;
}
.db-customized-modal .full-page-modal .modal-content {
  height: 100vh;
  overflow: auto;
}
.db-customized-modal .modal-content {
  padding: 35px 40px;
  border-radius: 2px !important;
}
.db-customized-modal .modal-content .modal-header {
  display: block;
  border-bottom: none !important;
}
.db-customized-modal .modal-content .modal-header h4.title-1 {
  margin-bottom: 20px;
}
.db-customized-modal .modal-content .modal-header .close {
  top: 10px !important;
  right: 5px !important;
}
.db-customized-modal .modal-content .modal-header .close df {
  font-size: 24px !important;
  color: #4b5c72 !important;
}
.db-customized-modal .modal-content .modal-header .close:focus {
  outline: none !important;
}
.db-customized-modal .modal-content .modal-body {
  padding-top: 0px !important;
}
.db-customized-modal .modal-content .chosen-container.chosen-container-multi {
  width: 100% !important;
}
.db-customized-modal
  .modal-content
  .chosen-container.chosen-container-multi
  ul.chosen-choices {
  font-size: 14px;
  padding: 5px 10px !important;
  border-radius: 2px;
  border: 1px solid #dbe1e8;
}
.db-customized-modal
  .modal-content
  .chosen-container.chosen-container-multi
  ul.chosen-choices
  li.search-choice {
  font-size: 14px !important;
  border: none !important;
  background: #e8ecf1 !important;
  padding: 8px 25px 8px 10px !important;
}
.db-customized-modal
  .modal-content
  .chosen-container.chosen-container-multi
  ul.chosen-choices
  li.search-choice
  .search-choice-close {
  top: 9px !important;
  right: 8px !important;
}
.db-customized-modal .modal-content .chosen-container-active .chosen-choices {
  outline: 0;
  border-color: var(--primary-color) !important;
  box-shadow: 0 3px 8px 0 rgba(22, 118, 226, 0.16);
  -webkit-box-shadow: none;
  transition: all 0.5s ease;
}
.db-customized-modal .modal-footer {
  padding: 0 !important;
  text-align: right;
}
.db-customized-modal .modal-footer .text-initial {
  text-align: initial !important;
}
.db-customized-modal .db-textarea {
  height: inherit !important;
}
.db-customized-modal label.star {
  font-size: 20px;
  color: #dbe1e8 !important;
}
.db-customized-modal label.star:before {
  content: "\f005";
}
.db-customized-modal input.star:checked ~ label.star:before {
  content: "\f005";
  color: #ffbb04;
  transition: all 0.25s;
}
.db-customized-modal .cont {
  text-align: initial !important;
}

.sucess_modal .modal-dialog.modal-sm {
  width: 520px;
  text-align: center;
}
.sucess_modal .modal-dialog .modal-content {
  box-shadow: 0 2px 34px 0 rgba(0, 0, 0, 0.3);
  background-image: linear-gradient(132deg, #305be4, #7b63eb 35%, #b878fe);
  border-radius: 12px;
}

/******* ######### ******
   Custom alerts
******* ######### ******/
.db-alert-info {
  padding: 7px 14px 7px 16px;
  border: 2px dotted #ffbb04;
  display: inline-block;
  border-radius: 2px;
  background-color: #fff8e5;
}
.db-alert-info .icon {
  color: #e07220;
  font-size: 18px;
  line-height: 1;
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
}
.db-alert-info .text {
  color: #e07220;
  font-size: 13px;
  display: inline-block;
  vertical-align: middle;
}

/******* ######### ******
   Custom Scrollbar
******* ######### ******/
.db-customscrollbar::-webkit-scrollbar {
  width: 8px !important;
  height: 6px !important;
  background-color: #f7f8fa !important;
}
.db-customscrollbar::-webkit-scrollbar-thumb {
  background-color: #dbe1e8 !important;
  border: 2px solid transparent !important;
  border-radius: 9px !important;
  background-clip: content-box !important;
}
.db-customscrollbar::-webkit-scrollbar-thumb:hover {
  border: initial !important;
}

.dashboard-circle {
  width: 30px;
  height: 30px;
  position: relative;
  transition: border-color 0.2s ease-in-out;
}
.dashboard-circle:hover {
  border-color: #55acee;
}
.dashboard-circle:hover:after {
  top: -6px;
  left: -6px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
}
.dashboard-circle:after {
  content: " ";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0px;
  height: 0px;
  background: rgba(8, 47, 90, 0.16);
  transition: all 0.2s ease-in-out;
}

/******* ######### ******
     Bread Crumb
******* ######### ******/
.db-breadCrumb ul {
  padding: 0px;
  margin: 0px;
}
.db-breadCrumb ul li {
  display: inline-block;
  padding-right: 30px;
  position: relative;
}
.db-breadCrumb ul li:not(:last-child):after {
  content: "\e914";
  font-family: "darwinbox" !important;
  position: absolute;
  right: 3px;
  top: -5px;
  font-size: 24px;
  color: #bcc6d6;
}
.db-breadCrumb ul li a {
  color: #2a333e;
  font-size: 12px;
  font-family: "Roboto";
}
.db-breadCrumb ul li a[href="#"] {
  cursor: text;
}
.db-breadCrumb ul li:last-child a {
  color: #7d8d9f;
}
.db-breadCrumb ul li:last-child:after {
  content: " ";
}

/******* ######### ******
      Boxed Filter
******* ######### ******/
.boxed_filter ul {
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-wrap: wrap;
}
.boxed_filter ul li {
  flex: 1;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  color: #2a333e;
  border: 1px solid #e8ecf1;
  margin: 0px 10px;
}
.boxed_filter ul li.active {
  color: var(--primary-color);
  border-bottom: 2px solid var(--primary-color);
}
.boxed_filter ul li:first-child {
  margin-left: 0px;
}
.boxed_filter ul li:last-child {
  margin-right: 0px;
}
.boxed_filter ul li span.count {
  font-size: 32px;
  font-weight: 300;
}
.boxed_filter ul li p {
  font-size: 14px;
}

.boxed_filter_md ul {
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-wrap: wrap;
}
.boxed_filter_md ul li {
  /* flex: 1 1; */
  /* border-radius: 2px; */
  /* padding: 16px; */
  color: #2a333e;
  /* border: 1px solid #dbe1e8; */
  /* margin: 0px 10px; */
  cursor: pointer;
}
.boxHome {
  border: 2px solid #dbe1e8;
  padding: 25px 10px;
  border-radius: 4px;
  height: 100%;
}
.boxed_filter_md ul li.active {
  color: var(--primary-color);
  border-bottom: 2px solid var(--primary-color);
}
.boxed_filter_md ul li.active p {
  color: var(--primary-color);
}
.boxed_filter_md ul li:first-child {
  margin-left: 0px;
}
.boxed_filter_md ul li:last-child {
  margin-right: 0px;
}
.boxed_filter_md ul li span.count {
  font-size: 32px;
  font-weight: 300;
}
.boxed_filter_md ul li p {
  font-size: 14px;
  color: #4b5c72;
}

/******* ######### ******
     scroll bar
******* ######### ******/
.scroll-to-nav ul {
  padding: 0px;
  margin: 0px;
}
.scroll-to-nav ul li a {
  font-size: 13px;
  color: #7d8d9f;
  padding: 10px;
  display: block;
}
.scroll-to-nav ul li.active a,
.scroll-to-nav ul li:hover a {
  color: #2a333e !important;
  position: relative;
}
.scroll-to-nav ul li.active a:before,
.scroll-to-nav ul li:hover a:before {
  content: "";
  position: absolute;
  width: 2px;
  height: calc(100% - 16px);
  background: var(--primary-color);
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}

/******* ######### ******
      mobile responsive classes 
******* ######### ******/
@media screen and (max-width: 768px) {
  .mtx-0 {
    margin-top: 0px !important;
  }
  .mtx-2 {
    margin-top: 2px !important;
  }
  .mtx-4 {
    margin-top: 4px !important;
  }
  .mtx-8 {
    margin-top: 8px !important;
  }
  .mtx-12 {
    margin-top: 12px !important;
  }
  .mtx-16 {
    margin-top: 16px !important;
  }
  .mtx-24 {
    margin-top: 24px !important;
  }
  .mtx-32 {
    margin-top: 32px !important;
  }
  .mtx-40 {
    margin-top: 40px !important;
  }
  .mtx-50 {
    margin-top: 50px !important;
  }
  .mtx-78 {
    margin-top: 78px !important;
  }
  .mbx-0 {
    margin-bottom: 0px !important;
  }
  .mbx-4 {
    margin-bottom: 4px !important;
  }
  .mbx-8 {
    margin-bottom: 8px !important;
  }
  .mbx-12 {
    margin-bottom: 12px !important;
  }
  .mbx-16 {
    margin-bottom: 16px !important;
  }
  .mbx-24 {
    margin-bottom: 24px !important;
  }
  .mbx-32 {
    margin-bottom: 32px !important;
  }
  .mbx-40 {
    margin-bottom: 40px !important;
  }
  .mbx-50 {
    margin-bottom: 50px !important;
  }
  .mtbx-12 {
    margin-bottom: 12px !important;
    margin-top: 12px !important;
  }
  .mtbx-24 {
    margin-bottom: 24px !important;
    margin-top: 24px !important;
  }
  .mlx-0 {
    margin-left: 0px !important;
  }
  .mlx-4 {
    margin-left: 4px !important;
  }
  .mlx-8 {
    margin-left: 8px !important;
  }
  .mlx-12 {
    margin-left: 12px !important;
  }
  .mlx-16 {
    margin-left: 16px !important;
  }
  .mlx-32 {
    margin-left: 32px !important;
  }
  .mlx-50 {
    margin-left: 50px !important;
  }
  .mrx-0 {
    margin-right: 0 !important;
  }
  .mlrx-4 {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }
  .mlrx-8 {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }
  .mlrx-12 {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }
  .px-0 {
    padding: 0px !important;
  }
  .px-8 {
    padding: 8px !important;
  }
  .px-12 {
    padding: 12px !important;
  }
  .px-24 {
    padding: 24px !important;
  }
  .ptx-0 {
    padding-top: 0px !important;
  }
  .ptx-2 {
    padding-top: 2px !important;
  }
  .ptx-4 {
    padding-top: 4px !important;
  }
  .ptx-8 {
    padding-top: 8px !important;
  }
  .ptx-12 {
    padding-top: 12px !important;
  }
  .ptx-16 {
    padding-top: 16px !important;
  }
  .ptx-24 {
    padding-top: 24px !important;
  }
  .ptx-32 {
    padding-top: 32px !important;
  }
  .ptx-40 {
    padding-top: 40px !important;
  }
  .pbx-0 {
    padding-bottom: 0px !important;
  }
  .pbx-2 {
    padding-bottom: 2px !important;
  }
  .pbx-4 {
    padding-bottom: 4px !important;
  }
  .pbx-12 {
    padding-bottom: 12px !important;
  }
  .pbx-32 {
    padding-bottom: 32px !important;
  }
  .ptbx-0 {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
  }
  .ptbx-8 {
    padding-bottom: 8px !important;
    padding-top: 8px !important;
  }
  .ptbx-12 {
    padding-bottom: 12px !important;
    padding-top: 12px !important;
  }
  .ptbx-16 {
    padding-bottom: 16px !important;
    padding-top: 16px !important;
  }
  .ptbx-24 {
    padding-bottom: 24px !important;
    padding-top: 24px !important;
  }
  .plx-0 {
    padding-left: 0px !important;
  }
  .plx-2 {
    padding-left: 2px !important;
  }
  .plx-4 {
    padding-left: 4px !important;
  }
  .plx-10 {
    padding-left: 10px !important;
  }
  .plx-32 {
    padding-left: 32px !important;
  }
  .prx-0 {
    padding-right: 2px !important;
  }
  .plrx-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .plrx-8 {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .plrx-12 {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  .text-initial_x {
    text-align: initial !important;
  }
  .text-left_x {
    text-align: left !important;
  }
  .text-center_x {
    text-align: center !important;
  }
  .display-none_x {
    display: none !important;
  }
  .display-block_x {
    display: block;
  }
  .inline-block_x {
    display: inline-block !important;
  }
  .border-all_x {
    border: 1px solid #e8ecf1;
  }
  .border-b_x {
    border-bottom: 1px solid #e8ecf1;
  }
  .primary-blue_x {
    color: var(--primary-color) !important;
  }
  .base-1_x {
    color: #2a333e !important;
  }
  .bg-white_x {
    background-color: #fff !important;
  }
  .bg-base-7_x {
    background-color: #f7f8fa !important;
  }
  .f-medium_x {
    font-weight: 500 !important;
  }
  .pos-relative_x {
    position: relative;
  }
  .pos-absolute_x {
    position: absolute;
  }
  .pos-initial_x {
    position: initial;
  }
  .pull-right_x {
    float: right !important;
  }
  .pull-left_x {
    float: left !important;
  }
  .font-14_x {
    font-size: 14px;
  }
  .width-ini_x {
    width: initial !important;
  }
  .width-100_x {
    width: 100% !important;
  }
  .overflowX-scroll_x {
    overflow-x: auto;
  }
  .min-height-ini_x {
    min-height: initial !important;
  }
  .float-none_x {
    float: none !important;
  }
  .border-n_x {
    border: none;
  }
}
/******* ######### ******
          card 
******* ######### ******/
.card {
  /* border: 1px solid #e8ecf1; */
  /* padding: 30px; */
  cursor: pointer;
  transition: all 0.5s ease;
}
.card:hover {
  /* box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.1), 0 16px 30px 0 rgba(0, 0, 0, 0.03), 0 6px 16px 0 rgba(0, 0, 0, 0.05);
  transition: all 0.5s ease;
  border: 1px solid transparent; */
}

/******* ######### ******
     Attachment
******* ######### ******/
.db-attachment label {
  border-radius: 2px;
  border: solid 1px #e8ecf1;
  background-color: #f7f8fa;
  font-size: 14px;
  padding: 6px 15px;
  cursor: pointer;
}
.db-attachment label input {
  display: none;
}
.db-attachment label df {
  font-size: 24px;
  position: relative;
  top: 5px;
}
.db-attachment label span.primary-blue {
  position: relative;
  top: -2px;
  font-size: 12px;
}
.db-attachment label span.file_uploaded {
  max-width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  position: relative;
  top: 4px;
}
.db-attachment.no-border label {
  border: none !important;
  padding: 0;
  margin: 0;
}
.db-attachment .uploaded_token {
  background: #fff;
  border: 1px solid #ddd;
  padding: 3px 10px;
  border-radius: 15px;
  margin-top: 5px;
  display: inline-block;
  font-size: 12px;
}
.db-attachment .uploaded_token .file_name {
  display: inline-block;
  white-space: nowrap;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  top: 3px;
}
.db-attachment .uploaded_token .file_name .remove_att {
  font-size: 16px !important;
  position: relative;
  top: 2px;
  right: -3px;
  cursor: pointer;
}

.btn-attachment {
  border-radius: 2px;
  border: solid 1px #e8ecf1;
  background-color: #f7f8fa;
  font-family: Roboto;
  font-size: 13px;
  font-weight: normal;
  padding: 0px 8px 9px 8px;
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  color: #4b5c72;
}
.btn-attachment df {
  font-size: 25px;
  position: relative;
  top: 7px;
  color: var(--primary-color);
}

/******* ######### ******
      Card Design 
******* ######### ******/
.db-card {
  background: #fff !important;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(47, 83, 151, 0.25);
  background-color: #ffffff;
}
.db-card.active {
  box-shadow: 0 2px 6px 0 rgba(22, 118, 226, 0.2),
    0 1px 3px 0 rgba(22, 118, 226, 0.08);
  border: solid 1px var(--primary-color);
}

/******* ######### ******
      Tabs Css
******* ######### ******/
.db-tabs ul.nav {
  border-bottom: none !important;
}
.db-tabs ul.nav li {
  margin-left: -2px;
}
.db-tabs ul.nav li:first-child a {
  border-top-left-radius: 2px !important;
  border-bottom-left-radius: 2px !important;
}
.db-tabs ul.nav li:last-child a {
  border-top-right-radius: 2px !important;
  border-bottom-right-radius: 2px !important;
}
.db-tabs ul.nav li a {
  border: solid 1px #dbe1e8;
  background: #ffffff;
  padding: 9px 20px;
  border-radius: 0;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  color: #4b5c72;
}
.db-tabs ul.nav li a:hover {
  border: solid 1px #dbe1e8;
}
.db-tabs ul.nav li a:hover::after {
  content: none;
}
.db-tabs ul.nav li.active a {
  box-shadow: 0 6px 10px 0 rgba(22, 118, 226, 0.3);
  background: var(--primary-color);
  color: #fff !important;
  border: 1px solid var(--primary-color);
}
.db-tabs ul.nav li.active a:hover {
  color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}
.db-tabs ul.nav li.active a:focus {
  color: #fff !important;
  border: 1px solid var(--primary-color);
}
.db-tabs ul.nav li.active a::after {
  content: none;
}
.db-tabs .tab-content .fade.in {
  background: transparent;
}
.db-tabs .two_tab {
  padding: 0px;
  margin: 10px 0 0 0;
}
.db-tabs .two_tab li {
  float: left;
}
.db-tabs .two_tab li:first-child a {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.db-tabs .two_tab li:last-child a {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.db-tabs .two_tab li a {
  border: solid 1px #dbe1e8;
  background-color: #f7f8fa;
  padding: 10px 20px;
  text-transform: uppercase;
  font-size: 12px;
  color: #7d8d9f;
  font-weight: 500;
  display: inline-block;
}
.db-tabs .two_tab li a.active {
  background: #fff;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

/******* ######### ******
       Empty state
******* ######### ******/
.empty_state_text {
  text-align: center;
  margin-top: 6vh;
}
.empty_state_text img {
  margin-bottom: 32px;
  max-width: 350px;
  width: 100%;
}
.empty_state_text h3 {
  line-height: 26px;
}

/******* ############# ******
    Summernote toolbar css
******* ############## ******/
.note-editor.note-frame {
  border: solid 1px #e8ecf1 !important;
  border-radius: 2px !important;
}
.note-editor.note-frame .panel-heading {
  background-color: #fff !important;
  border: none;
  box-shadow: 0px 1px 2px #ddd;
}
.note-editor.note-frame .note-editing-area .note-editable {
  min-height: 100px !important;
  margin: 0 !important;
  padding: 10px;
  background-color: #ffffff;
}
.note-editor.note-frame
  .note-editing-area
  .note-editable[contenteditable="false"] {
  background-color: #f7f8fa;
}

.note-btn-group.btn-group .btn-default {
  border-color: #fff !important;
  color: #2a333e !important;
  padding: 5px 12px;
  margin-right: 2px !important;
}
.note-btn-group.btn-group .btn-default:hover {
  background-color: #f7f8fa !important;
  color: #2a333e !important;
  border-radius: 2px !important;
  box-shadow: none !important;
}
.note-btn-group.btn-group .btn-default.active {
  border-color: #e8ecf1 !important;
  background-color: #e8ecf1 !important;
  color: #2a333e !important;
  border-radius: 2px !important;
  box-shadow: none !important;
}
.note-btn-group.btn-group .btn-default.active:hover {
  border-color: #e8ecf1 !important;
  background-color: #e8ecf1 !important;
  color: #2a333e !important;
  border-radius: 2px !important;
}

.user-profile_details .directory-avatar {
  float: left;
  margin-right: 20px;
  background: #e8ecf1 !important;
  color: #4b5c72;
  font-size: 12px;
  font-weight: 500;
  font-family: Roboto;
  width: 36px;
  height: 36px;
  line-height: 36px;
}

/******* ############################### ******
    vartical tabs  active border bottom style 
******* ################################ ******/
.vertical_tabs {
  padding: 0px;
  border-bottom: 1px solid #dbe1e8;
}
.vertical_tabs li {
  display: inline-block;
  padding-right: 30px;
}
.vertical_tabs li a {
  color: #2a333e;
  padding: 0px 0px 4px 0px;
  display: inline-block;
  font-size: 13px;
}
.vertical_tabs li a.active {
  color: var(--primary-color);
  position: relative;
}
.vertical_tabs li a.active:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -1px;
  background: var(--primary-color);
  left: 0;
}
.vertical_tabs li a span {
  font-size: 13px;
  font-weight: 700;
  font-family: roboto;
  margin-left: 4px;
}

.chips_tabs {
  padding: 0px !important;
  margin: 0px !important;
}
.chips_tabs li {
  display: inline-block;
  padding-right: 2px;
}
.chips_tabs li a {
  border-radius: 30px;
  border: solid 1px #e8ecf1;
  background-color: #ffffff;
  padding: 5px 20px;
  display: block;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  color: #4b5c72;
}
.chips_tabs li a.active {
  border-radius: 30px;
  border: solid 1px #e8ecf1;
  background-color: var(--primary-color);
  color: #fff !important;
}

/******* ############################### ******
  Horizantal tabs  active border bottom style 
******* ################################ ******/
.horizontal_tabs {
  padding: 0px;
  width: 200px !important;
  float: left;
  border-right: 1px solid rgba(221, 221, 221, 0.8666666667);
  margin-right: 20px;
}
.horizontal_tabs li {
  display: block;
  margin-bottom: 15px;
}
.horizontal_tabs li a {
  color: #4b5c72;
  padding: 0px 0px 4px 0px;
  display: inline-block;
  font-size: 13px;
}
.horizontal_tabs li a.active {
  color: var(--primary-color);
  position: relative;
}
.horizontal_tabs li a.active:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -1px;
  background: var(--primary-color);
  left: 0;
}
.horizontal_tabs li a span {
  font-size: 13px;
  font-weight: 700;
  font-family: roboto;
  margin-left: 4px;
}

.horizontal_right {
  float: left;
  width: calc(100% - 240px);
}

/******* ######### ******
        legends
******* ######### ******/
.legends_list li {
  display: inline-block;
  font-size: 12px;
  letter-spacing: 0.4px;
  color: #7d8d9f;
  margin-right: 15px;
}
.legends_list li:last-child {
  margin-right: 0px !important;
}

table.sortable
  th:not(.sorttable_sorted):not(.sorttable_sorted_reverse):not(
    .sorttable_nosort
  ):after {
  content: " ▴▾";
}

.sidebar-layout .page_left_section {
  width: 350px;
  border: solid 1px #e8ecf1;
  background-color: #ffffff;
  height: calc(100vh - 67px);
  margin-bottom: 0px;
  padding: 0px !important;
  overflow: auto;
  position: fixed;
}
.sidebar-layout .page_right_section {
  margin-left: 350px;
  min-height: 100%;
  margin-top: 0;
  padding: 0;
  overflow-y: auto;
  position: relative;
  min-height: 100%;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  height: auto !important;
  padding-bottom: 80px;
}
.sidebar-layout .app-footer {
  margin-left: 350px;
}

/*********** ######### **********
      custom dropdown menu
*********** ######### ***********/
.db-dropdown-menu {
  width: auto;
  border-radius: 2px;
  padding: 6px 0px !important;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.24), 0 6px 10px 0 rgba(0, 0, 0, 0.08),
    0 1px 3px 0 rgba(0, 0, 0, 0.08);
  border: none !important;
  min-width: 180px;
}
.db-dropdown-menu li {
  padding: 6px 12px;
  font-size: 14px;
  color: #4b5c72;
  min-width: 160px;
  cursor: pointer;
  position: relative;
}
.db-dropdown-menu li:hover {
  background-color: #f7f8fa;
}
.db-dropdown-menu li:hover ul.db-dropdown-sub-menu {
  display: block;
}
.db-dropdown-menu ul.db-dropdown-sub-menu {
  position: absolute;
  display: none;
  transition: all 0.5s ease;
  right: -106%;
  background: #fff;
  padding: 6px 0px !important;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.24), 0 6px 10px 0 rgba(0, 0, 0, 0.08),
    0 1px 3px 0 rgba(0, 0, 0, 0.08);
  top: 0;
}
.db-dropdown-menu ul.db-dropdown-sub-menu:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-right: 8px solid #dbe1e8;
  border-bottom: 8px solid transparent;
  border-top: 8px solid transparent;
  left: -8px;
}
.db-dropdown-menu ul.db-dropdown-sub-menu:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-right: 7px solid #fff;
  border-bottom: 7px solid transparent;
  border-top: 7px solid transparent;
  left: -7px;
  top: 7px;
}

.ajax_select_dropdown {
  position: relative;
}
.ajax_select_dropdown .search_sec {
  font-size: 14px;
  border-radius: 2px;
  border: 1px solid #dbe1e8;
  color: #353f4c;
  -webkit-text-fill-color: inherit;
  box-shadow: none;
  caret-color: var(--primary-color);
  background: #fff;
  transition: all 0.5s ease;
  padding: 0;
}
.ajax_select_dropdown .search_sec input {
  border: none !important;
  box-shadow: none;
}
.ajax_select_dropdown .search_sec .selected_elements .tag {
  display: inline-block;
  vertical-align: top;
  font-size: 13px;
  padding: 2px 6px;
  background-color: #fafafa;
  color: #333;
  border-radius: 2px;
  border: solid 1px #ebebeb;
  margin: 0px 4px 4px 0px;
}
.ajax_select_dropdown .searched_options {
  position: absolute;
  border: 1px solid #ddd;
  width: 100%;
  background: #fff;
  max-height: 200px;
  overflow: auto;
  display: none;
  z-index: 9 !important;
}
.ajax_select_dropdown .searched_options li {
  padding: 5px 5px;
  cursor: pointer;
  border-bottom: 1px solid #f3f3f3;
}

p.rounded_number {
  border: 1px solid #ddd;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
}

.db-card-new {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.07);
  border: 1px solid #e8ecf1;
  background-color: #ffffff;
}
.db-card-new img {
  max-width: 100% !important;
}

/*********** ######### **********
           Inline List
*********** ######### ***********/
ul.inline-list {
  padding: 0px;
  margin: 0;
}
ul.inline-list li {
  display: inline-block;
  padding: 0px 5px;
  position: relative;
}
ul.inline-list li:not(:last-child)::after {
  content: "";
  height: calc(100% - 7px) !important;
  position: absolute;
  right: 0;
  width: 1px;
  top: 6px;
  z-index: 999;
  background: rgba(221, 221, 221, 0.8666666667);
}

/*********** ######### **********
          sortable list
*********** ######### ***********/
ul.sortable_list {
  padding: 0px;
  margin: 0px;
}
ul.sortable_list li {
  background: none;
  padding: 4px 9px;
  margin-bottom: 5px;
  cursor: move;
}

.modal-1020 {
  width: 1020px !important;
}

/*********** ######### **********
          search expandable 
*********** ######### ***********/
.expand_search {
  margin-bottom: 2px;
}
.expand_search input {
  outline: none;
}
.expand_search input[type="search"] {
  /* background: url("../../../../../images/Icons_latest/search-1.png") no-repeat 9px center; */
  padding: 9px 10px 9px 32px;
  width: 40px;
  border: none !important;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
  box-shadow: none !important;
}
.expand_search input[type="search"]:focus {
  width: 200px;
  padding-left: 32px;
  color: #000;
  background-color: #fff;
  cursor: auto;
  border-bottom: 1px solid #ddd !important;
}

/*********** ######### **********
    Bootstrap Update Changes 
*********** ######### ***********/
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9 {
  /* float: left; */
}

.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}

.text-right {
  text-align: right;
}

.form-group {
  margin-bottom: 15px;
}

.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9 {
  float: left;
}

.col-md-offset-1 {
  margin-left: 8.33333333%;
}

.h6,
h6 {
  font-size: 0.75rem;
}

.h2,
h2 {
  font-size: 30px;
}

.form-check .form-check-input[type="checkbox"] {
  border-radius: 0em;
  height: 17px;
  width: 17px;
  top: -1px;
}

.form-check-inline {
  margin-bottom: 15px;
}

.form-check-inline .form-check-input[type="checkbox"],
.form-check-inline .form-check-input[type="radio"] {
  height: 17px;
  width: 17px;
  margin-top: 2px;
  box-shadow: none;
  border: 1px solid #bcc6d6 !important;
}

.form-horizontal .control-label {
  padding-top: 7px;
  margin-bottom: 0;
  text-align: right;
}

.form-horizontal .form-check,
.form-horizontal .form-check-inline,
.form-horizontal .radio,
.form-horizontal .radio-inline {
  padding-top: 7px;
  margin-top: 0;
  margin-bottom: 0;
}

.form-check,
.radio {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border-style: none;
}

.form-select[disabled],
fieldset[disabled] .form-select {
  cursor: not-allowed;
}

.form-horizontal .form-check,
.form-horizontal .radio {
  min-height: 27px;
}

.form-horizontal .form-check,
.form-horizontal .checkbox-inline,
.form-horizontal .radio,
.form-horizontal .radio-inline {
  padding-top: 7px;
  margin-top: 0;
  margin-bottom: 0;
}

.input-group {
  margin-bottom: 15px;
  display: block;
}

.form-check-input {
  outline: none !important;
  margin-top: 0em;
}

.form-check input[type="checkbox"],
.form-check-inline input[type="checkbox"],
.form-check input[type="radio"],
.form-check-inline input[type="radio"] {
  position: absolute;
  margin-top: 4px\9;
}

.modal-lg {
  max-width: 1000px;
}

.close {
  float: right;
  font-size: 21px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
}

.modal-header .close {
  margin-top: -38px;
  margin-right: -34px;
}

button.close {
  -webkit-appearance: none;
  padding: 0;
  cursor: pointer;
  background: 0;
  border: 0;
}

.h1,
.h2,
.h3,
h1,
h2,
h3 {
  margin-bottom: 10px;
}

.input-group > .form-control,
.input-group > .form-select {
  width: 100%;
}

[className="modal-dialog"] {
  width: 600px;
  max-width: none;
}

.user-profile > .profile-dropdown.show {
  visibility: visible;
  top: 40px;
  left: -222px;
  opacity: 1;
  transition: all 0.5s ease;
}

.form-control[disabled],
fieldset[disabled] .form-control {
  cursor: not-allowed;
}

.dataTables_filter {
  padding-bottom: 5px;
}

.form-check label,
.form-check-inline label {
  min-height: 20px;
  display: inline-block;
  padding-left: 5px;
  margin-bottom: 0;
  font-weight: 400;
  cursor: pointer;
  vertical-align: middle;
}

.form-check-inline label {
  display: inline-block;
  padding-left: 25px;
}

textarea.form-control {
  height: auto;
}

.pull-right {
  float: right !important;
}

body .position_switch .db-switche [type="checkbox"]:checked + label,
body .position_switch .db-switche [type="checkbox"]:not(:checked) + label {
  top: -13px !important;
}

.hide {
  display: none !important;
}

.weeklyoffcheckbox .form-check {
  margin-top: 0%;
  margin-bottom: 0%;
}

.carousel-inner > .item > a > img,
.carousel-inner > .item > img,
.img-responsive,
.thumbnail a > img,
.thumbnail > img {
  display: block;
  max-width: 100%;
  height: auto;
}

.modal-footer {
  border: none;
  padding: 0 15px 15px 15px;
  text-align: right;
}

.fade.in {
  opacity: 1;
  background: rgba(222, 222, 222, 0.63);
}

.col-md-offset-6 {
  margin-left: 50%;
}

.modal-sm {
  max-width: 600px;
}

.form-check-input[type="checkbox"] {
  border-radius: 0%;
}

.form-check input[type="checkbox"]:disabled:not(:checked),
.form-check input[type="radio"]:disabled:not(:checked) {
  background-color: #eee;
  cursor: not-allowed;
}

.nav-tabs > li {
  float: left;
  margin-bottom: -1px;
}

.nav-tabs > li > a {
  margin-right: 2px;
  line-height: 1.42857143;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
}

.nav-tabs > li > a.active {
  margin-right: 2px;
  line-height: 1.42857143;
  color: var(--primary-color) !important;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
  border-bottom: 2px solid var(--primary-color);
}

.nav > li > a {
  /* position: relative;
  display: block;
  padding: 10px 15px; */
}

.dropdown-menu {
  width: 100%;
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-size: 14px;
}

.mb-5 {
  margin-bottom: 0em !important;
}

.dropdown-menu > li > a {
  display: block;
  padding: 8px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}

.form-check-input[type="checkbox"] {
  border-radius: 2px !important;
  width: 18px !important;
  height: 18px !important;
}

p {
  margin: 0 0 10px;
}

.col-md-offset-2 {
  margin-left: 16.66666667%;
}

.row {
  /* display: block; */
}

.dropdown-menu {
  top: 100%;
}

ul.nav.nav-tabs.payrolldashboard {
  margin-bottom: 0px;
}

.nav-tabs.payrolldashboard {
  border-bottom: 0px;
}

.row {
  /* display: block; */
}

.dropdown-menu > li > a {
  display: block;
  padding: 8px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}

.col-md-offset-2 {
  margin-left: 16.66666667%;
}

.toggle-container
  .toggle-list
  .toggle-body
  .ui-sortable
  li:hover
  .form-check
  span {
  display: block;
}

.toggle-container .toggle-list .toggle-body .ui-sortable li .form-check span {
  position: absolute;
  display: none;
  right: 0;
  top: 1px;
}

.toggle-container
  .toggle-list
  .toggle-body
  .ui-sortable
  li
  .form-check
  span
  df {
  display: block;
  line-height: 0.4;
  color: #7d8d9f;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2p;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropdown-toggle::after {
  content: none;
}

.col-xs-2 {
  /* width: 16.66666667%; */
}

.col-xs-3:not(.col-md-8) {
  /* width: 25%;
  float: left; */
}

.col-xs-4 {
  /* width: 33.33333333%; */
}

.col-xs-5 {
  /* width: 41.66666667%; */
}

.col-xs-6 {
  /* width: 50%; */
}

.col-xs-7 {
  /* width: 58.33333333%; */
}

.col-xs-8 {
  /* width: 66.66666667%; */
}

.col-xs-10 {
  /* width: 83.33333333%; */
}

.col-xs-12 {
  /* width: 100%; */
}

.col-sm-5 {
  /* width: 41.66666667%; */
}

.col-sm-6 {
  /* width: 50%; */
}

.col-sm-8 {
  /* width: 66.66666667%; */
}

.col-md-1 {
  /* width: 8.33333333%; */
}

.col-md-2 {
  /* width: 16.66666667%; */
}

.col-md-3 {
  /* width: 25%; */
}

.col-md-4 {
  /* width: 33.33333333%; */
}

.col-md-5 {
  /* flex: 0 0 auto;
  width: 41.66666667%; */
}

.col-md-6 {
  /* width: 50%; */
}

.col-md-8 {
  /* width: 66.66666667%; */
}

.col-md-9 {
  /* width: 75%; */
}

.col-md-10 {
  /* width: 83.33333333%; */
}

.col-md-11 {
  /* width: 91.66666667%; */
}

.col-md-12 {
  /* width: 100%; */
}

.col-lg-3 {
  /* width: 25%; */
}

.col-lg-2 {
  /* width: 16.66666667%; */
}

.col-lg-3 {
  /* width: 25%; */
}

.input-group-btn {
  display: table-cell;
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
  position: relative;
  font-size: 0;
}

.search-input-wrapper .input-group {
  display: table;
  margin-bottom: none;
}

.fade.in {
  opacity: 1;
  background: rgba(222, 222, 222, 0.63);
}

.container:before {
  display: table;
  content: " ";
}

.container:after {
  clear: both;
  display: table;
  content: " ";
}

.container-fluid:before {
  display: table;
  content: " ";
}

.container-fluid:after {
  clear: both;
  display: table;
  content: " ";
}

.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.alert > p,
.alert > ul {
  margin-bottom: 0;
}

.hidden {
  display: none !important;
}

label {
  margin-bottom: 5px;
}

.row > * {
  /* flex-shrink: 0; */
  /* width: 100%; */
  /* max-width: 100%; */
  padding-right: none;
  padding-left: none;
}

.input-group-btn > .btn {
  position: relative;
}

.dropdown-menu .divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}

.employee-dd {
  top: 70%;
}

.dropdown-menu.employee-dd {
  width: 100%;
}

.posil {
  top: none;
}

.employee-dd.dropdown-menu[data-bs-popper] {
  top: 70%;
  left: 0;
  margin-top: 0;
}

.caption.mb-5 {
  margin-bottom: 0;
}

.dropdown-menu.padding-40.advance-filter-dropdown {
  width: 500px;
  right: 0px;
  left: unset;
  border: none;
}

.dropdown-menu {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.18);
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.nav.bg-primary-white {
  display: flex;
}

.col-md-offset-4 {
  margin-left: 33.33333333%;
}

.col-xs-5.text-center {
  width: 41.66666667%;
  float: left;
}

.col-xs-5.text-center {
  width: 41.66666667%;
}

.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  float: left;
}

.sr-only {
  position: absolute;
  width: 10px;
  height: 10px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.modal-content {
  height: fit-content;
}

.modal-dialog {
  margin: 30px auto;
}

.panel-heading {
  padding: 10px 15px;
}

.panel-body {
  padding: 15px;
}

.modal-footer {
  display: block;
}

.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: 0px;
}

.btn-toolbar > .btn,
.btn-toolbar > .btn-group,
.btn-toolbar > .input-group {
  margin-left: 5px;
}

.col-xs-3.col-sm-5 {
  width: 41.66666667% !important;
}

.table-hover > tbody > tr:hover {
  background-color: #f5f5f5;
}

.label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}

.label-info {
  background-color: #5bc0de;
}

.form-inline .form-select {
  display: inline-block;
  width: auto;
  vertical-align: middle;
}

.form-check.no-margin {
  margin: 0px;
}

.label-danger {
  background-color: #d9534f;
}

.label-success {
  background-color: #5cb85c;
}

.form-check.no-margin-bottom {
  margin-bottom: 0px;
}

.list-group {
  display: block;
}

.nav {
  display: flex;
}

.note-link-popover,
.note-image-popover,
.note-table-popover {
  display: none;
}

.modal-fullscreen-xxl-down .modal-body {
  overflow-y: unset;
}

.label-warning {
  background-color: #f0ad4e;
}

.modal-dialog {
  width: 600px;
  margin: 30px auto;
  max-width: none;
}

.padding-top-bottom {
  padding-bottom: 25px;
  padding-top: 25px;
}

.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before,
.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: var(--primary-color) !important;
}

.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before,
.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: var(--primary-color) !important;
}

.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before,
.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: var(--primary-color) !important;
  left: 5px;
}

.modal-dialog.full-page-modal {
  max-width: none;
}

.modal-lg {
  width: 900px;
}

.col-xs-3.col-md-2 {
  width: 16.66666667%;
}

.multiselect-container.dropdown-menu.show > li > a {
  padding: 0px;
}

.help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #737373;
}

.input-group.inline-block {
  display: inline-block;
}

.modal-footer > * {
  margin: 0rem;
}

.form-check .form-check-input[type="checkbox"],
.form-check .form-check-input[type="radio"] {
  height: 17px;
  width: 17px;
  box-shadow: none;
  border: 1px solid #bcc6d6 !important;
}

.bi.bi-chevron-up {
  /* background: url("../../images/chevron-up.svg"); */
  padding-right: 15px;
}

.bi.bi-chevron-down {
  /* background: url("../../images/chevron-down.svg"); */
  padding-right: 15px;
}

.bi.bi-plus-lg {
  /* background: url("../../images/plus-lg.svg"); */
  padding-right: 15px;
}

.bi.bi-dash-lg {
  /* background: url("../../images/dash-lg.svg"); */
  padding-right: 15px;
}

.no-top-bottom-margin {
  margin-top: 0px;
  margin-bottom: 0px;
}

.db-customized-modal .modal-fullscreen .modal-content {
  height: 100vh;
  overflow: auto;
}

.modal-dialog.modal-fullscreen {
  margin: unset;
}

.container-fluid {
  width: auto;
}

.show + .dropdown-menu {
  display: block;
  left: auto;
  width: auto;
  position: absolute;
  border: none;
  padding-bottom: 0px;
  padding-top: 0px;
}

.dropdown-menu.activity-post-status.main_status_list {
  width: auto;
}

input[type="file"] {
  display: block;
  height: 33px !important;
}

.form-control::-webkit-file-upload-button {
  height: 33px;
}

.input-group-addon,
.input-group-btn {
  display: table-cell;
}

.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9 {
  /* float: left; */
}

.btn-group {
  display: inline-block;
}

ul.dropdown-menu.show {
  inset: unset;
  transform: unset;
}

.col-md-offset-2 {
  margin-left: 16.66666667%;
}

.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5);
}

.alert-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}

.panel-title {
  margin-top: 0;
  margin-bottom: 0;
}

.hr-accordian {
  padding: 0;
}

.input-group.percentage-icon {
  display: table;
  margin-bottom: unset;
}

.input-group.amount-fld {
  display: flex;
  margin-bottom: unset;
}

.col-md-offset-3 {
  margin-left: 25%;
}

.modal-fullscreen .modal-content {
  padding: 0px;
}

.panel-title > .small,
.panel-title > .small > a,
.panel-title > a,
.panel-title > small,
.panel-title > small > a {
  color: inherit;
}

.db-customized-modal .modal-fullscreen {
  width: 100% !important;
  margin: 0px !important;
}

.db-customized-modal .modal-content {
  padding: 35px 40px;
}

.col-md-offset-8 {
  margin-left: 66.66666667%;
}

.col-xs-offset-8 {
  margin-left: 66.66666667%;
}

th a {
  color: unset;
}

.popover.fade.show {
  color: #1676e2;
}

.popover {
  z-index: 1000000;
}

.popover-body {
  color: unset;
  font-size: 14px;
}

a.label:hover {
  color: white;
}

.hr-accordian > .nav {
  display: block;
}

.checkbox-inline,
.radio-inline {
  display: inline-block;
  margin: 0;
}

a.label:hover {
  color: white;
  margin: 0;
}

table {
  border-spacing: 0;
}

table {
  --bs-table-hover-bg: none !important;
}

legend {
  margin-bottom: 20px;
  border-bottom: 1px solid #e5e5e5;
}

.left-topic-list {
  padding: 0;
  position: absolute;
}

.ob-form-section input.form-check-input[type="radio"] {
  width: 17px !important;
}

.ctc-action-wrapper > .dropdown-menu {
  transform: translate(0px, 0px) !important;
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  cursor: not-allowed;
}

.form-horizontal .input-group:before {
  display: table;
  content: " ";
}

.form-horizontal .input-group:after {
  display: table;
  content: " ";
  clear: both;
}

.panel-default {
  border-color: #ddd;
}

.panel-default > .panel-heading {
  color: #333;
  background-color: #f5f5f5;
  border-color: #ddd;
}

.person-icon {
  /* background: url("../../images/person-fill.svg"); */
  padding-right: 15px;
}

.input-group label {
  color: #4b5c72 !important;
  font-size: 12px;
  font-weight: normal;
  text-transform: capitalize;
}

.language_drop li a {
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
}
.language_drop li a.active {
  border: none !important;
  color: white !important;
  background-color: #1676e2 !important;
  color: white;
}

.view-profile {
  display: block;
  width: max-content;
  margin: auto;
}

.dropdown {
  float: unset !important;
  z-index: 9;
}

.warn-message {
  background-color: #f44336;
  color: azure;
  text-align: center;
  height: 22px;
}

#language.language_drop {
  position: absolute;
  padding: 10px 0px;
  margin: 0px;
  top: 223px;
  z-index: 1;
  transition: all 0.5s ease;
  right: 260px;
  background: #fff;
  min-width: 260px;
  border-radius: 2px;
  border: none !important;
  box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.1), 0 30px 30px 0 rgba(0, 0, 0, 0.08),
    0 8px 16px 0 rgba(0, 0, 0, 0.08);
}

.white-header .global-search.ui-autocomplete-input {
  background: rgba(0, 0, 0, 0.1) !important;
}
.white-header .global-search.ui-autocomplete-input::placeholder {
  color: rgba(0, 0, 0, 0.75) !important;
}
.white-header .global-search.ui-autocomplete-input:focus {
  background: #ffffff !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.white-header .global-search.ui-autocomplete-input:focus::placeholder {
  color: #bcc6d6 !important;
}
.white-header .searchbar-home.focusIcon:before {
  color: rgba(0, 0, 0, 0.75) !important;
}
.white-header df {
  color: #000000 !important;
}
.white-header .clock-in-out-label {
  color: #000000 !important;
}
.white-header .clock-in-out-btn {
  border-color: rgba(0, 0, 0, 0.5) !important;
  color: #000000 !important;
}
.white-header .btn-clockin {
  border-color: rgba(0, 0, 0, 0.5) !important;
  color: #000000 !important;
}

.black-header .global-search.ui-autocomplete-input {
  background: rgba(255, 255, 255, 0.1) !important;
}
.black-header .global-search.ui-autocomplete-input::placeholder {
  color: rgba(255, 255, 255, 0.75) !important;
}
.black-header .global-search.ui-autocomplete-input:focus {
  background: #ffffff !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.black-header .global-search.ui-autocomplete-input:focus::placeholder {
  color: #bcc6d6 !important;
}
.black-header .searchbar-home.focusIcon:before {
  color: rgba(255, 255, 255, 0.75) !important;
}
.black-header df {
  color: #ffffff !important;
}
.black-header .clock-in-out-label {
  color: #ffffff !important;
}
.black-header .btn-clockin {
  color: #ffffff !important;
  border-color: rgba(255, 255, 255, 0.5) !important;
  color: #ffffff !important;
}
.black-header .clock-in-out-btn {
  border-color: rgba(255, 255, 255, 0.5) !important;
  color: #ffffff !important;
}

.focusIn.searchbar-home.focusIcon:before {
  color: #bcc6d6 !important;
}

.showEmployeeDetails {
  position: relative;
}
.showEmployeeDetails .quick-profile {
  background-color: #fff;
  display: none;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 rgba(74, 144, 226, 0.2);
  padding: 15px;
  min-width: 280px;
  min-height: 180px;
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
}
.showEmployeeDetails .quick-profile .profile-container {
  display: flex;
  align-items: flex-start;
  padding-bottom: 10px;
  font-weight: 500;
}
.showEmployeeDetails .quick-profile .profile-container .profile-content {
  padding-left: 5px;
  padding-top: 10px;
}
.showEmployeeDetails .quick-profile .profile-container .profile-content .name {
  font-size: 14px;
  letter-spacing: 0.1px;
  color: #1676e2;
  display: inline-block;
}
.showEmployeeDetails .quick-profile .profile-container .user-pic {
  display: inline-block;
  height: 40px;
  width: 40px;
  margin-right: 10px;
  margin-top: 10px;
  position: relative;
}
.showEmployeeDetails .quick-profile .profile-container .user-pic .avatar {
  display: inline-block;
  height: 40px;
  width: 40px;
  margin-right: 10px;
  background-color: #e8ecf1;
  font-weight: 500;
  color: #4b5c72;
  border-radius: 50%;
  font-size: 18px;
  padding: 11.5px 0;
  line-height: 1;
}
.showEmployeeDetails .quick-profile .profile-container .user-pic img {
  border-radius: 50%;
  display: inline-block;
  height: 40px !important;
  width: 40px !important;
  position: absolute;
}
.showEmployeeDetails .quick-profile .mail {
  padding: 10px;
  background-color: #ecf2fe;
}
.showEmployeeDetails .quick-profile .mail img {
  margin-right: 7px;
}
.showEmployeeDetails .quick-profile .mail .clipboard {
  filter: brightness(0);
  opacity: 0.5;
}
.showEmployeeDetails .quick-profile .mail .clipboard:hover {
  opacity: 1;
  filter: brightness(1);
  cursor: pointer;
}
.showEmployeeDetails .quick-profile .mt15 {
  margin-top: 20px;
}
.showEmployeeDetails .quick-profile .mt15 img {
  margin-top: -4px;
}
.showEmployeeDetails .quick-profile .lh {
  line-height: 15px !important;
  color: #626e7e !important;
  font-size: 11px !important;
}
.showEmployeeDetails .quick-profile .cd {
  margin: 15px 10px;
  display: flex;
  align-items: center;
}
.showEmployeeDetails .quick-profile .cd img {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
.showEmployeeDetails .quick-profile .cd span {
  color: #4b5c72;
  font-size: 12px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
.showEmployeeDetails .quick-profile .user-pic img {
  width: 45px !important;
  height: 45px !important;
  max-width: unset;
}
.showEmployeeDetails .quick-profile .department {
  line-height: 16px;
}
.showEmployeeDetails .quick-profile .small {
  color: #4b5c72 !important;
  font-size: 10px;
}
.showEmployeeDetails .quick-profile .mailTxt {
  font-size: 12px;
  font-weight: 500;
  color: #4b5c72 !important;
  white-space: nowrap;
  width: 140px;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  text-overflow: ellipsis;
}
.showEmployeeDetails .quick-profile .ml29 {
  margin-left: 29px;
}
.showEmployeeDetails .quick-profile .ph {
  margin-top: 2px;
}
.showEmployeeDetails .quick-profile p {
  margin-bottom: 0;
  padding-bottom: 4px;
}
.showEmployeeDetails .quick-profile .flx {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}
.showEmployeeDetails .quick-profile .custom-theme-color {
  color: var(--primary-color) !important;
}
.showEmployeeDetails:hover {
  overflow: visible !important;
}
.showEmployeeDetails:hover .quick-profile {
  display: block;
}

/* reponsive css======================================================== */
:root {
  --primary-color: #1676e2;
  --primary-gradient: linear-gradient(73deg, #115eb4, #1676e2, #1aa5c9);
  --primary-shadow: 0 16px 20px 0 rgba(22, 118, 226, 0.3),
    0 6px 10px 0 rgba(22, 118, 226, 0.3);
  --third-blue: #2196f3;
}

/* color variable  */
/* font size variable */
/*font weight varibale*/
/*box-shadow varibale*/
:root {
  --primary-color: #1676e2;
  --primary-gradient: linear-gradient(73deg, #115eb4, #1676e2, #1aa5c9);
  --primary-shadow: 0 16px 20px 0 rgba(22, 118, 226, 0.3),
    0 6px 10px 0 rgba(22, 118, 226, 0.3);
  --third-blue: #2196f3;
}

/* color variable  */
/* font size variable */
/*font weight varibale*/
/*box-shadow varibale*/
/* font size */
.font-62 {
  font-size: 62px !important;
}

.font-42 {
  font-size: 62px !important;
}

.font-32 {
  font-size: 32px !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-13 {
  font-size: 13px !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-11 {
  font-size: 11px !important;
}

.font-10 {
  font-size: 10px !important;
}

/* font style */
.display-5 {
  font-weight: 900;
  font-size: 62px;
  color: #2a333e;
}

.display-4 {
  font-weight: 900;
  font-size: 42px;
  color: #2a333e;
}

.display-3 {
  font-weight: 300;
  font-size: 42px;
  color: #2a333e;
}

.display-2 {
  font-weight: 700;
  font-size: 32px;
  color: #2a333e;
  line-height: 22px;
}

.display-1 {
  font-weight: 300;
  font-size: 32px;
  color: #2a333e;
}

.title-2 {
  font-weight: 300;
  font-size: 24px;
  color: #2a333e;
}

.title-1 {
  font-weight: 500;
  font-size: 18px;
  color: #2a333e;
  line-height: 32px;
}

.subheader {
  font-weight: 500;
  font-size: 16px;
  color: #2a333e;
}

.body {
  font-weight: 400;
  font-size: 14px;
  color: #2a333e;
}

.font-regular {
  font-weight: 400;
  font-size: 14px;
  color: #2a333e;
}

.smaller-header-bold {
  font-weight: 500;
  font-size: 13px;
  color: #2a333e;
}

.smaller-header-regular {
  font-weight: 400;
  font-size: 13px;
  color: #2a333e;
}

.table-header {
  font-weight: 500;
  font-size: 12px;
  color: #2a333e;
  text-transform: uppercase;
}

.buttons {
  font-weight: 500;
  font-size: 12px;
  color: #2a333e;
  text-transform: uppercase;
}

.caption {
  font-weight: 400;
  font-size: 12px;
  color: #2a333e;
}

.smaller-case {
  font-weight: 400;
  font-size: 11px;
  color: #2a333e;
}

.reg-header {
  font-weight: 500;
  font-size: 16px;
  color: #2a333e;
  text-transform: capitalize;
}

div.mobileDisplay {
  display: none;
}

table.mobShowEntry {
  width: 100% !important;
}

.FixedHeader_Cloned.fixedHeader.FixedHeader_Header {
  z-index: 9 !important;
}

.open-left-sidebar {
  display: none;
}

@media screen and (min-width: 1024px) {
  ul.nav.nav-tabs.mobileNavTab {
    display: inline-block;
  }
  div.mobRTnavContainer {
    line-height: 10px;
  }
}
@media screen and (max-width: 768px) {
  .FixedHeader_Cloned.fixedHeader.FixedHeader_Header {
    position: static !important;
  }
  body {
    padding: 0;
  }
  .font-10_x {
    font-size: 10px;
  }
  .display-inline_x {
    display: inline !important;
  }
  nav.main-navigation.mt-x {
    margin-top: 70px;
  }
  div.mobile-inlineBlock {
    text-align: center;
  }
  div.mobile-inlineBlock div.inBlock {
    display: inline-block;
  }
  div.how-text-center {
    text-align: center;
  }
  div.dashboard-top.bg-gradient-blue-green div.top-header {
    text-align: center;
  }
  div.dashboard-top.bg-gradient-blue-green div.mobile-inlineBlock {
    display: inline-block;
    padding-bottom: 20px;
    width: 100%;
  }
  div.mobileDisplay {
    display: block;
  }
  div.desktopDisplay {
    display: none;
  }
  div#pulse-modal div.mobileWidth-x {
    width: 95%;
    left: initial;
    right: initial;
  }
  div#pulse-modal.topRight {
    top: 0;
    right: 0;
    left: 0;
  }
  div#pulse-modal div.mobileWidth-x div.modal-body {
    padding: 65px 20px;
  }
  div.container.mobRTnavContainer {
    position: fixed;
    width: 100%;
    z-index: 10;
    background: #fff;
  }
  div.right-icons.mobileRTfilter {
    width: 100%;
  }
  div.right-icons.mobileRTfilter div.filter-action-wrapper div.filter_dropdown {
    width: 300px;
  }
  ul.nav.nav-tabs.mobileNavTab li.mobileWidth-x {
    text-align: center;
    width: 50%;
  }
  ul.nav-tabs.mobileNavTab li.mobileWidth-x:first-child {
    margin-right: 0;
  }
  ul.nav-tabs.mobileNavTab li.mobileWidth-x {
    padding-left: 0;
  }
  ul.nav-tabs.mobileNavTab li.mobileWidth-x span.db-badge {
    right: 0px;
  }
  li.liMobileRequestTab div.desc-title a:first-child {
    margin-right: 4px;
    margin-bottom: 2px;
  }
  li.liMobileRequestTab div.desc-title a:nth-child(2) {
    display: block;
    margin-bottom: 4px;
    margin-top: 2px;
  }
  li.liMobileRequestTab div.msg-time {
    margin-bottom: 4px;
  }
  li.liMobileRequestTab .btn-hoverlayer {
    display: block;
    position: initial;
    margin-top: 12px;
  }
  li.liMobileRequestTab:hover .btn-hoverlayer {
    margin-top: 12px;
  }
  div.more-messages div.more-button.mobileShowMore {
    text-align: center;
    margin-bottom: 50px;
    padding-bottom: 12px;
  }
  div.more-messages div.more-button.mobileShowMore a#showmore {
    border: solid 1px #0173c5 !important;
  }
  ul#mobileRTnav.sticky {
    position: fixed;
    top: 0;
    width: 100%;
  }
  li.liMobileRequestTab span.mobileReqSpan {
    width: 100%;
    padding-left: 55px;
  }
  li.liMobileRequestTab span.mobileReqSpan div.acion-btns {
    margin-top: 12px;
    margin-bottom: 8px;
    text-align: left;
  }
  li.liMobileRequestTab span.mobileReqSpan div.approval-flow-sec {
    padding: 0;
  }
  li.liMobileRequestTab span.mobileReqSpan div.mobPolicyCons ul {
    padding-left: 0;
  }
  li.liMobileRequestTab span.mobileReqSpan div.mobPolicyCons ul li {
    padding-left: 0;
  }
  li.liMobileRequestTab span.mobileReqSpan div.request.event {
    padding-left: 0;
  }
  li.liMobileRequestTab span.mobileReqSpan div.request.event .pdl-0 {
    padding-left: 0;
  }
  li.liMobileRequestTab
    span.mobileReqSpan
    div.request.event
    span.eventtime-att-log {
    padding-left: 0;
  }
  li.liMobileRequestTab span.mobileReqSpan div.request.event .mgl-0 {
    margin-left: 0 !important;
  }
  textarea.leave-textarea {
    width: 100%;
  }
  div.mobReqSepDisplay span.eventtime-att-log {
    padding-left: 0;
  }
  div.mobReqSepDisplay .pdl-36 {
    padding-left: 36px !important;
  }
  div.mobReqSepDisplay .pdl-32 {
    padding-left: 32px !important;
  }
  div.mobReqSepDisplay .mgl-24 {
    margin-left: 24px !important;
  }
  div.mobReqSepDisplay .mgb-18 {
    margin-bottom: 18px !important;
  }
  div.mobReqSepDisplay textarea.leave-textarea {
    width: 100%;
  }
  div.mobReqSepDisplay div.eventdetails {
    margin-left: 36px;
  }
  table.mobShowEntry + div.dataTables_info {
    text-align: left !important;
  }
  div.mobShowEntrySelect div#assign_weekly_off_length {
    text-align: left !important;
  }
  div.mobShowEntrySelect div#assign_weekly_off_filter {
    text-align: left !important;
    margin-bottom: 8px;
  }
  div.fixednav.mobTaskNav df#mobTaskNavaccor {
    -moz-transition: transform 1s;
    -webkit-transition: transform 1s;
    transition: transform 1s;
  }
  div.fixednav.mobTaskNav df#mobTaskNavaccor.flip {
    transform: rotate(-180deg);
  }
  .mobBoxShadow {
    box-shadow: 0 2px 6px 0 rgba(47, 83, 151, 0.1) !important;
  }
  div.profile-main-panel div.user-avathar {
    transform: translateY(25%);
    float: left;
  }
  div.profile-main-panel div.user-main-deatails {
    overflow: hidden;
  }
  div.personal-details-sec.ptbx-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  div.each-detail-action a.btn.btn-secondary.db-btn-sm {
    top: -25px;
  }
  .hover-edit .edithover,
  .hover-edit .edithover1 {
    display: block !important;
    z-index: 1 !important;
    width: 24px !important;
    float: right !important;
    position: absolute !important;
  }
  .hover-edit a.edithover.top-3 {
    top: -38px !important;
  }
  table.db-table-one tbody tr:hover {
    background: #fff !important;
  }
  div.mobClockBlock select.width-x {
    width: 46% !important;
  }
  div.leavesModule .col-xs-12 {
    width: 100% !important;
  }
  #full-wrapper-dashboard-header .notice-dashboard-wrapper {
    width: 72%;
  }
  div.mobLeaveSticky {
    position: fixed;
    width: 100%;
    z-index: 10;
  }
  div.mobLeaveSticky .line-ht-18 {
    line-height: 18px;
  }
  div.mobLeaveSticky div.transY-15 {
    transform: translateY(15%);
  }
  div.mobLeaveSticky div.transY-33 {
    transform: translateY(33%);
  }
  .db-customized-modal .modal-content .modal-header .close df,
  .full-screen-modal df.db-Close {
    top: -32px;
    right: -40px;
  }
  .db-Close.unselectDate.fill-circle {
    right: 0;
  }
  div.mobProfileSubNav ul.mobProfileNavUl {
    list-style-type: none;
    padding: 0;
    overflow: hidden;
  }
  div.mobProfileSubNav ul.mobProfileNavUl li {
    border-radius: 18px;
    padding: 7px 11px;
  }
  div.mobProfileSubNav ul.mobProfileNavUl li.active {
    background: var(--primary-color) !important;
    border: 1px solid var(--primary-color) !important;
  }
  div.mobProfileSubNav ul.mobProfileNavUl li.active a {
    color: #fff !important;
  }
  div.mobProfileSubNav ul.mobProfileNavUl li:nth-child(2) {
    margin-bottom: 10px;
  }
  div.mobProfileSubNav ul.mobProfileNavUl li:nth-child(4) {
    margin-bottom: 4px;
  }
  div.orgStructWrapper div.user-org-chart {
    width: max-content;
    width: -moz-max-content; /* firefox */
    width: -webkit-max-content; /* chrome and safari */
    width: -o-max-content; /* opera */
    width: -ms-max-content; /* internet explorer */
    padding: 0 2%;
    margin: 0 auto;
  }
  div.orgStructWrapper div.user-org-chart div.translateY-25 {
    transform: translateY(25%);
  }
  ul.ui-autocomplete.global-search-autocomplete {
    width: 89% !important;
  }
  div.mobTaskNavWrapper ul.mobTasktoggled li {
    margin-bottom: 6px;
    border-radius: 18px;
    border: solid 1px #e8ecf1;
    background-color: #f7f8fa;
  }
  div.mobTaskNavWrapper ul.mobTasktoggled li a {
    color: #4b5c72;
    font-size: 12px;
    text-transform: capitalize;
  }
  div.mobTaskNavWrapper ul.mobTasktoggled li a.active {
    border-radius: 18px;
    color: #fff;
    background: var(--primary-color);
    font-weight: 600;
  }
  div.fixednav.mobTaskNav {
    position: fixed;
    width: 100%;
    z-index: 10;
  }
  div.mobGoBackWrapper df.db-Chevron-left {
    font-size: 32px !important;
    top: 6px;
    left: -8px;
  }
  div.currentDaySubmit {
    bottom: 0;
  }
  div.left-sidebar {
    overflow: auto;
    visibility: visible;
    position: fixed;
    top: 140px;
    width: 60%;
    left: -60%;
    z-index: 99 !important;
    padding-bottom: 85px;
  }
  div.left-sidebar .open-left-sidebar {
    display: inline-block;
    position: fixed;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5019607843);
    color: white;
  }
  div.left-sidebar .open-left-sidebar > df {
    font-size: 16px;
    font-weight: 700;
    vertical-align: text-bottom;
  }
  div.left-sidebar .open-left-sidebar.open {
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
    transform: scaleX(-1);
  }
  div.right-content.page-content.mtx-100 {
    margin-top: 100px;
  }
  .widget-left .span12 {
    margin-left: 0px !important;
  }
  table#salary-widget-dash tbody tr td {
    padding: 6px 6px 6px 50% !important;
  }
  table#salary-widget-dash tbody tr td:nth-of-type(2) {
    padding-left: 0 !important;
  }
  table#salary-widget-dash > tbody > tr:nth-child(2) td:nth-child(1) {
    padding-left: 8% !important;
  }
  table#salary-widget-dash > tbody > tr:nth-child(2) td:nth-child(1):before {
    content: "" !important;
  }
  table#salary-widget-dash-innerTable tbody tr td:nth-of-type(2) {
    padding-left: 50% !important;
  }
  table#leaveSummaryTable,
  table#holidayListModal,
  table#salary-widget-dash,
  table#salary-widget-dash-innerTable,
  table#taskTables {
    width: 100%;
    border-collapse: collapse;
    /* Zebra striping */
  }
  table#leaveSummaryTable tr:nth-of-type(odd),
  table#holidayListModal tr:nth-of-type(odd),
  table#salary-widget-dash tr:nth-of-type(odd),
  table#salary-widget-dash-innerTable tr:nth-of-type(odd),
  table#taskTables tr:nth-of-type(odd) {
    background: #eee;
  }
  table#leaveSummaryTable th,
  table#holidayListModal th,
  table#salary-widget-dash th,
  table#salary-widget-dash-innerTable th,
  table#taskTables th {
    background: #333;
    color: white;
    font-weight: bold;
  }
  table#leaveSummaryTable td,
  table#leaveSummaryTable th,
  table#holidayListModal td,
  table#holidayListModal th,
  table#salary-widget-dash td,
  table#salary-widget-dash th,
  table#salary-widget-dash-innerTable td,
  table#salary-widget-dash-innerTable th,
  table#taskTables td,
  table#taskTables th {
    padding: 6px;
    border: 1px solid #ccc;
    text-align: left;
  }
  div.fc-view.fc-view-month.fc-grid {
    min-width: 100%;
    overflow-x: auto;
  }
  table.fc-border-separate {
    width: 500px !important;
  }
  table.fc-border-separate td {
    min-width: 80px;
  }
  .header-alerts-db-drop {
    max-height: inherit;
    left: -242px;
  }
  .header-alerts-db-drop:before {
    right: 48px;
  }
  .fnt-total {
    font-size: 18px;
  }
  div.attachedFileNameShowWrapper {
    overflow: hidden;
  }
  a.attendViewBtns {
    padding: 5px 10px !important;
  }
  .howmany-days {
    font-size: 28px !important;
  }
  div.mobUserProfile img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
  }
  div.badge_empty_state {
    display: block !important;
  }
  div#info_message div.center_auto {
    width: 100%;
  }
  input.global-search::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    white-space: pre-line;
    position: relative;
    top: -7px;
  }
  input.global-search::-moz-placeholder {
    /* Firefox 19+ */
    white-space: pre-line;
    position: relative;
    top: -7px;
  }
  input.global-search:-ms-input-placeholder {
    /* IE 10+ */
    white-space: pre-line;
    position: relative;
    top: -7px;
  }
  input.global-search:-moz-placeholder {
    /* Firefox 18- */
    white-space: pre-line;
    position: relative;
    top: -7px;
  }
  .responsive_modal #pulse-modal .modal-dialog.modal-large {
    width: 95% !important;
  }
  .responsive_modal #pulse-modal .modal-body {
    padding: 25px;
  }
  #attendance_log_filter label {
    margin-top: 25px;
    padding-right: 12px;
    float: left;
  }
  .table-container {
    min-width: 100%;
    overflow-x: auto;
    padding: 0px 12px;
  }
  #attendance_log thead {
    display: table-caption;
  }
}
@media screen and (max-width: 450px) {
  body #dashboardtasks .eachtaskcard {
    padding: 17px 12px !important;
    box-shadow: 0 2px 6px 0 rgba(47, 83, 151, 0.1) !important;
    margin: 5px 0 !important;
  }
  body #dashboardtasks .eachtaskcard:hover {
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.24), 0 10px 30px 0 rgba(0, 0, 0, 0.08),
      0 0 3px 0 rgba(0, 0, 0, 0.08) !important;
  }
  body #dashboardtasks .eachtaskcard .text {
    font-size: 16px !important;
    font-weight: 500 !important;
    width: calc(100% - 70px) !important;
    margin-left: 18px !important;
  }
  body #dashboardtasks .eachtaskcard .count {
    padding-right: 6px !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    padding-top: 3px !important;
  }
  body #dashboardtasks .eachtaskcard .count:after {
    top: 22px !important;
    margin-right: -6px !important;
    visibility: visible !important;
    opacity: 1 !important;
    font-size: 24px;
  }
  .hover-edit a.edithover.top-3 {
    top: -38px !important;
  }
  .list-inline2 > li {
    padding: 0px !important;
  }
  .dashboard-top .main-logo-admin img {
    max-width: 70px;
  }
}
@media only screen and (max-width: 320px) {
  .btn-clockin:before {
    content: "";
  }
  .btn-clockin.plr-x {
    padding: 5px 6px 5px !important;
  }
  .dashboard-top .main-logo-admin img {
    max-width: 48px;
  }
  #full-wrapper-dashboard-header .notice-dashboard-wrapper {
    width: 85%;
  }
  input.global-search::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-size: 12px;
  }
  input.global-search::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 12px;
  }
  input.global-search:-ms-input-placeholder {
    /* IE 10+ */
    font-size: 12px;
  }
  input.global-search:-moz-placeholder {
    /* Firefox 18- */
    font-size: 12px;
  }
}
@media only screen and (min-width: 667px) and (max-width: 736px) {
  .col-xs-2_x {
    width: 17%;
  }
  div.dashboard-top.bg-gradient-blue-green div.mobile-inlineBlock {
    width: 58%;
  }
}
@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  table#leaveSummaryTable,
  table#holidayListModal,
  table#salary-widget-dash,
  table#salary-widget-dash-innerTable,
  table#taskTables {
    padding: 20px;
  }
  table#salary-widget-dash-innerTable {
    padding-top: 40px;
  }
  /* Force table to not be like tables anymore */
  table#leaveSummaryTable,
  table#holidayListModal,
  table#leaveSummaryTable thead,
  table#holidayListModal thead,
  table#leaveSummaryTable tbody,
  table#holidayListModal tbody,
  table#leaveSummaryTable th,
  table#holidayListModal th,
  table#leaveSummaryTable td,
  table#holidayListModal td,
  table#leaveSummaryTable tr,
  table#holidayListModal tr {
    display: block;
  }
  table#salary-widget-dash,
  table#salary-widget-dash-innerTable,
  table#salary-widget-dash thead,
  table#salary-widget-dash-innerTable thead,
  table#salary-widget-dash tbody,
  table#salary-widget-dash-innerTable tbody,
  table#salary-widget-dash th,
  table#salary-widget-dash-innerTable th,
  table#salary-widget-dash td,
  table#salary-widget-dash-innerTable td,
  table#salary-widget-dash tr,
  table#salary-widget-dash-innerTable tr {
    display: block;
  }
  table#taskTables table,
  table#taskTables thead,
  table#taskTables tbody,
  table#taskTables th,
  table#taskTables td,
  table#taskTables tr {
    display: block;
  }
  /* Hide table headers (but not display: none;, for accessibility) */
  table#leaveSummaryTable thead tr,
  table#holidayListModal thead tr,
  table#salary-widget-dash thead tr,
  table#salary-widget-dash-innerTable thead tr,
  table#taskTables thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  table#leaveSummaryTable tr,
  table#holidayListModal tr,
  table#salary-widget-dash tr,
  table#salary-widget-dash-innerTable tr,
  table#taskTables tr {
    border: 1px solid #ccc;
  }
  table#leaveSummaryTable td,
  table#holidayListModal td,
  table#salary-widget-dash td,
  table#salary-widget-dash-innerTable td,
  table#taskTables td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50% !important;
  }
  table#leaveSummaryTable td:before,
  table#holidayListModal td:before,
  table#salary-widget-dash td:before,
  table#salary-widget-dash-innerTable td:before,
  table#taskTables td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }
  /*
  leave summay table
     */
  table#leaveSummaryTable td:nth-of-type(1):before {
    content: "TYPE";
  }
  table#leaveSummaryTable td:nth-of-type(2):before {
    content: "JAN";
  }
  table#leaveSummaryTable td:nth-of-type(3):before {
    content: "FEB";
  }
  table#leaveSummaryTable td:nth-of-type(4):before {
    content: "MAR";
  }
  table#leaveSummaryTable td:nth-of-type(5):before {
    content: "APR";
  }
  table#leaveSummaryTable td:nth-of-type(6):before {
    content: "MAY";
  }
  table#leaveSummaryTable td:nth-of-type(7):before {
    content: "JUN";
  }
  table#leaveSummaryTable td:nth-of-type(8):before {
    content: "JUL";
  }
  table#leaveSummaryTable td:nth-of-type(9):before {
    content: "AUG";
  }
  table#leaveSummaryTable td:nth-of-type(10):before {
    content: "SEP";
  }
  table#leaveSummaryTable td:nth-of-type(11):before {
    content: "OCT";
  }
  table#leaveSummaryTable td:nth-of-type(12):before {
    content: "NOV";
  }
  table#leaveSummaryTable td:nth-of-type(13):before {
    content: "DEC";
  }
  table#holidayListModal td:nth-of-type(1):before {
    content: "S.no";
  }
  table#holidayListModal td:nth-of-type(2):before {
    content: "Occasion";
  }
  table#holidayListModal td:nth-of-type(3):before {
    content: "Date";
  }
  table#holidayListModal td:nth-of-type(4):before {
    content: "Day";
  }
  table#salary-widget-dash td:nth-of-type(1):before {
    content: "Name";
  }
  table#salary-widget-dash td:nth-of-type(2):before {
    content: "Items";
  }
  table#salary-widget-dash-innerTable td:nth-of-type(1):before {
    content: "Description";
  }
  table#salary-widget-dash-innerTable td:nth-of-type(2):before {
    content: "Type";
  }
  table#salary-widget-dash-innerTable td:nth-of-type(3):before {
    content: "Date";
  }
  table#salary-widget-dash-innerTable td:nth-of-type(4):before {
    content: "Currency";
  }
  table#salary-widget-dash-innerTable td:nth-of-type(5):before {
    content: "Unit";
  }
  table#salary-widget-dash-innerTable td:nth-of-type(6):before {
    content: "Amount";
  }
  table#salary-widget-dash-innerTable td:nth-of-type(7):before {
    content: "Attachments";
  }
  table#taskTables td:nth-of-type(1):before {
    content: "Title";
  }
  table#taskTables td:nth-of-type(2):before {
    content: "Date";
  }
  table#taskTables td:nth-of-type(3):before {
    content: "Actions";
  }
}
@media (min-width: 1px) and (max-width: 360px) {
  .menu-db-drop .single-menu-item {
    width: 110px !important;
  }
  input[placeholder] {
    font-size: 10px !important;
  }
  ::-moz-placeholder {
    font-size: 10px !important;
  } /* firefox 19+ */
  input:-moz-placeholder {
    font-size: 10px !important;
  }
}
.title-2 {
  font-weight: 500;
  font-size: 24px !important;
  color: #2a333e;
}

.green {
  color: #2ca860 !important;
}
.display-property-1 {
  display: flex;
  justify-content: end;
  gap: 5px;
}

/* -------------------  organization css -------------------- */

.mt-100 {
  margin-top: 100px;
}
.form-card {
  width: 100%;
  padding: 30px;
  background: gray;
}
.desktopflex {
  display: flex;
}

@media only screen and (max-width: 675px) and (min-width: 320px) {
  .main-header-left.horizontal {
    position: absolute;
    right: 0px;
    top: 15%;
  }
  .main-logo img {
    height: 5rem;
  }
  .mbNone {
    display: none;
  }
  .mbDflex {
    display: flex;
  }
  .mbalignItems {
    align-items: center;
  }
  .mbPad0 {
    padding: 0 !important;
  }
  /* .mainCardContainer {
    margin: 0px 20px !important;
    padding-top: 100px !important;
    padding-bottom: 80px;
  } */
  .mbpt5rem {
    padding-top: 5rem;
  }
  .branch-head-item h4 {
    padding: 0.9rem !important;
    font-size: 0.8rem;
  }
  .cssbuttons-io-button {
    font-size: 0.8rem !important;
    height: 30px !important;
  }
  .mbFooter {
    font-size: 0.6rem;
  }
  .main-footer.text-center {
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: +999;
    background: #fff;
  }
  .mbBranch {
    height: 100vh;
  }
  .branch-menu-item p {
    font-size: 10px;
    color: #2a333e;
  }
  .content {
    padding: 10px;
    padding-top: 0px;
  }
  body {
    height: auto !important;
  }
  .card-title {
    margin-bottom: var(--bs-card-title-spacer-y);
    font-size: 16px !important;
  }
  label {
    font-size: 12px !important;
  }
  .loginTitle h3 {
    margin: 20px 0px;
    font-size: 18px;
    color: #fff;
  }
  .loginTitle p {
    color: #fff;
    padding: 0px 20px;
  }
  .inputLogin {
    padding: 1rem;
    width: 290px;
  }
  .input-groupLogin {
    position: relative;
    margin: 20px 0px;
    /* display: flex; */
    
    justify-content: center;
  }
  .LoginBtn {
    padding: 15px 120px;
    font-size: 12px;
  }
  .cardLogin {
    width: 100%;
    height: 110vh;
  }
 
    .search-box {
      width: 125px !important;
      /* top: -20px !important; */
      /* right: 0% !important; */
      height: 35px !important;
  
  }
  .leave-head-item h4 {
    
    font-size: 16px;
    font-weight: 600;
}
.titleDash {
  font-size: 18px;
}
.mainCardContainer {
  margin: 0px 20px !important;
  padding: 80px 10px !important;
  padding-bottom: 80px;
  background-color: #fff;
}
  .search-input {
    width: 100% !important;
    font-size: 9px !important;
  }
  .titleDash {
    font-size: 1.5rem;
  }
  .dTFFld {
    flex: 1 0 auto;
    font-weight: 400;
    font-size: 1.2rem;
  }
  .cardsPop {
    justify-content: start !important;
  }
  .eploye_mont {
    font-size: 15px !important;
  }
  .btnEmply {
    border: 1px solid green !important;
    margin-top: 5px;
    font-size: 13px !important;
    width: 80%;
    font-weight: 500;
  }
  .brdRed {
    border: 1px solid #df262b !important;
    margin-bottom: 5px !important;
  }
  .actionIcon i {
    font-size: 12px;
  }
  .actionIcon {
    gap: 0px !important;
    cursor: pointer;
    flex-wrap: wrap !important;
  }
}
.alignAdminProfile{
  display: flex;
  align-items: center;
  height: 70px;
  justify-content: end;
}
.profile-container {
  position: relative;
  display: inline-block;
}

.profile-image {
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 50%;
  font-weight: 600;
  cursor: pointer;
  color: #2d2d2d;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
}

.profile-options {
  position: absolute;
  top: 60px;
  right: 0;
  width: 120px;
  /* background-color: #f5f5f5; */
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px 10px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.profile-container:hover .profile-options {
  opacity: 1;
  visibility: visible;
  color: #0173c5;
}

.profile-options p {
  display: block;
  padding: 5px 0;
  text-decoration: none;
  color: #2d2d2d;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.dropdown-item:hover {
  background: none !important;
  color: #165f60;
  font-weight: 600;
 
}