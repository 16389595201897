@-ms-viewport {
  width: device-width;
  height: device-height;
}

:root {
  --primary-color: #2986ce;
  --white: #fff;
  --text-primary: #222;
  --text-secondary: #777;
  --text-danger: #fa5f5f;
  --text-hint: #afafaf;
  --bg-color-hover: #f8f8f9;
  --bg-slate-20: #f3f4f7;
  --bg-slate-700: #4a5363;
  --bg-accent-violet: #9785c2;
  --input-border-color: #d8dde6;
  --border-color: #e4e6e9;
}

p {
  margin-bottom: 0 !important;
}

.bg-login {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.bg-login img {
  /* flex-shrink: 0; */
  width: 100%;
  height: 100%;
  /* object-fit: cover;
  object-position: top left; */
}

.login-content {
  width: 500px;
  /* padding: 30px 80px; */
}

.btn {
  width: 100%;
  height: 48px;
}

.btn-primary:hover {
  background-color: var(--primary-color) !important;
}

.w-86 {
  width: 86px;
}

.w-85 {
  width: 85%;
}

.w-100-500 {
  width: calc(100vw - 500px);
  height: 100vh;
}

.h-50px {
  height: 50px;
}

.max-h-50px {
  max-height: 50px;
}

.text-x-large {
  font-size: 18px;
}

.text-small {
  font-size: 12px;
}

.text-x-small {
  font-size: 11px !important;
}

.text-secondary {
  color: var(--text-secondary) !important;
}

.bg-hover:hover {
  background-color: var(--bg-color-hover) !important;
}

.bg-accent-violet {
  background-color: var(--bg-accent-violet);
}

.p-30 {
  padding: 30px;
}

.mt-6 {
  margin-top: 0.6px;
}

.border-secondary {
  border-color: #a3a3a3 !important;
}

/* .form-control,
.form-control[readonly] {
  padding: 12px 12px 7px 12px !important;
  background-color: transparent;
  height: 58px;
} */

button {
  background-color: var(--white);
}

.account-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  padding: 8px 16px;
  cursor: pointer;
  width: 100%;
  text-align: left;
  border-radius: 3px;
  border: 1px solid var(--border-color);
}

.account-btn:hover {
  background-color: var(--bg-color-hover);
}

.login-container {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin: 0 -5px;
}



input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

@media only screen and (max-width: 600px) {
  html,
  body {
    background-color: #fff;
  }

  .login-content {
    width: 100%;
    padding: 0px;
  }

  .bg-login {
    display: none;
  }

  .apps-store {
    display: none !important;
  }
}

@media only screen and (max-width: 340px) {
  .login-option {
    min-width: 150px;
  }
}


 
.screen-1 {
    /* background: #000; */
    display: flex;
    justify-content: center;
    padding: 2rem;
}
.screen-1 .logo {
  margin-top: -3em;
}
 
.cardLogin {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-color: #165f60;
  /* border-radius: 8px; */
  z-index: 1;
  padding-bottom: 20px;
 }
 
 .tools {
  display: flex;
  align-items: center;
  padding: 9px;
 }
 
 .circle {
  padding: 0 4px;
 }
 
 .box {
  display: inline-block;
  align-items: center;
  width: 10px;
  height: 10px;
  padding: 1px;
  border-radius: 50%;
 }
 
 .red {
  background-color: #ff605c;
 }
 
 .yellow {
  background-color: #ffbd44;
 }
 
 .greenLOgin {
  background-color: #00ca4e;
 }
 
 .input-groupLogin {
  position: relative;
  margin: 40px 0px;

 }
 
 .inputLogin {
  border: solid 1.5px #9e9e9e;
  border-radius: 0.5rem;
  background: none;
  padding: 1.3rem;
  font-size: 1rem;
  color: #f5f5f5;
  transition: border 150ms cubic-bezier(0.4,0,0.2,1);
  width: 350px;
 }
 
 .user-label {
  position: absolute;
  left: 15px;
  color: #e8e8e8;
  pointer-events: none;
  transform: translateY(1rem);
  transition: 150ms cubic-bezier(0.4,0,0.2,1);
 }
 
 .inputLogin:focus, input:valid {
  outline: none;
  border: 1.5px solid #ddd;
 }
 
 .inputLogin:focus ~ label, input:valid ~ label {
  transform: translateY(-50%) scale(0.8);
  background-color: #165f60;
  padding: 0 0.2em;
  color: #fff;
  font-size: 16px;
  letter-spacing: 1px;
 }
 .inputLogin:active, .inputLogin:target {
  background: none !important;
 }
 input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    /* -webkit-text-fill-color: white !important; */
}
.inputLogin:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: white !important;
}
 
.formField{
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.loginTitle {
  text-align: center;
}
.loginTitle h3 {
  margin: 30px 0px;
  font-size: 28px;
  color: #fff;
  margin-top: 0px;
}
.loginTitle p {
  color: #fff;
  padding: 0px 70px;
}
.LoginBtn {
  padding: 15px 0px;
  border-radius: 5px;
  border: 0;
  background-color: white;
  box-shadow: rgb(0 0 0 / 5%) 0 0 8px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  font-size: 15px;
  transition: all .5s ease;
  font-weight: 600;
  width: 290px;

  /* background-image: linear-gradient( 164deg, rgba(223, 38, 43, 1) 0%, rgba(241, 140, 50, 1) 45%, rgba(160, 92, 28, 1) 70% );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
 }
 
 /* .LoginBtn:hover {
      letter-spacing: 3px;
    background-color: hsl(28.44deg 88.07% 57.25%);
    color: hsl(0, 0%, 100%);
 } */
 
 .LoginBtn:active {
  letter-spacing: 3px;
  background-color: #165f60;
  color: hsl(0, 0%, 100%);
  box-shadow: #165f60;
  transform: translateY(0px);
  transition: 100ms;
 }
 .btnPart {
  text-align: center;
}
.forgotPass{
  margin-top: 30px;
  text-align: center;
}
.forgotPass a{
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  text-decoration: underline !important;
}
.notfoundLogin{
  width: 200px;
}
.LogoColorRed{
  color: #e3e1e1;
}
.LogoColorgreen{
  color: green;
}







.spinner{
  animation-name: spin;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  color: #165f60;
  fill: #165f60;
}



/* new loader==============================================================> */
.loading {
  --speed-of-animation: 0.9s;
  --gap: 6px;
  --first-color: #4c86f9;
  --second-color: #49a84c;
  --third-color: #f6bb02;
  --fourth-color: #f6bb02;
  --fifth-color: #2196f3;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  gap: 6px;
  height: 100px;
}

.loading span {
  width: 4px;
  height: 50px;
  background: var(--first-color);
  animation: scale var(--speed-of-animation) ease-in-out infinite;
}

.loading span:nth-child(2) {
  background: var(--second-color);
  animation-delay: -0.8s;
}

.loading span:nth-child(3) {
  background: var(--third-color);
  animation-delay: -0.7s;
}

.loading span:nth-child(4) {
  background: var(--fourth-color);
  animation-delay: -0.6s;
}

.loading span:nth-child(5) {
  background: var(--fifth-color);
  animation-delay: -0.5s;
}

@keyframes scale {

  0%,
  40%,
  100% {
    transform: scaleY(0.05);
  }

  20% {
    transform: scaleY(1);
  }
}
/* =====================================================================================================================>>>> */
.loader_align{
display: flex;
justify-content: center;
align-items: center;
height: 100vh;
}
.w-350{
  width: 350px;
}