.leave-head-item {
  width: 100%;
  border-radius: 0.5rem;

  margin: 1rem auto;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.leave-head-item h4 {
  padding: 0.5rem 0rem;
  background-image:linear-gradient(
    140deg,
    hsl(181deg 63% 23%) 0%,
    hsl(184deg 89% 25%) 10%,
    hsl(187deg 100% 29%) 21%,
    hsl(190deg 100% 35%) 31%,
    hsl(192deg 100% 42%) 41%,
    hsl(195deg 92% 51%) 51%,
    hsl(192deg 100% 43%) 61%,
    hsl(190deg 100% 38%) 71%,
    hsl(187deg 100% 33%) 80%,
    hsl(184deg 100% 28%) 90%,
    hsl(181deg 85% 26%) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 24px;
  font-weight: 600;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  width: 100%;
}

.react-datepicker__input-container input {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.Demo {
  width: 100px !important;
}

.leave-card {
  border-radius: 0.3rem;
  padding: 1rem 1rem 0 1rem;
  background-color: #f7f8fa;
  border: solid 1px #dbe1e8;
}

.leave-desc {
  padding: 0.3rem;
}

.desc-font {
  font-size: 1.3rem;
}

.update-leave{
  display: flex;
align-items: center;
justify-content: flex-start;
margin-top: 2rem;
}

.leave-flex{
  display: flex;
    /* align-items: center; */
    flex-direction: column;
    justify-content: space-between;
}

.leave-header{
  display: flex;
    align-items: center;
    justify-content: space-between;
}