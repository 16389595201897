* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.branch-menu-item {
  text-align: center;
  padding: 20px 0px;
}
.branch-menu-item .module-icon-wrapper {
  width: 130px;
  height: 130px;
  border-radius: 16px;
  background-color: #f7f8fa;
  border: solid 1px #dbe1e8;
  margin: 0 auto 5px;
  transition: all 0.5s ease;
  position: relative;
}
.branch-menu-item .module-icon-wrapper .db-badge {
  box-sizing: border-box;
  min-width: 22px;
  height: 22px;
  line-height: 15px;
  padding: 0px 5px;
  text-align: center;
  border-radius: 30px;
  vertical-align: middle;
  background: #f44336;
  color: #ffffff;
  font-size: 10px;
  font-weight: 500;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  position: absolute;
  top: -7px;
  right: -7px;
}

.branch-menu-item a {
  position: relative;
  display: inline-block;
}
.branch-menu-item img {
  max-width: 130px;
  margin-bottom: 5px;
}
.branch-menu-item p {
  font-size: 14px;
  color: #2a333e;
}

.branch-head-item {
  width: 100%;
  border-radius: 0.5rem;
  background-color: #f7f8fa;
  border: solid 1px #dbe1e8;
  margin: 1rem auto;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.branch-head-item h4 {
  padding: 1.5rem;
}

.blurbg {
  border-radius: 1.2rem;
  position: absolute;
  height: 130px;
  width: 130px;
  background: rgba(0, 0, 0, 0.4);
  top: 20px;
  left: 21px;
  transition: all 0.5s ease-in-out;
  display: none;
}
.bg-tut {
  padding: 0.2rem;
  background: white;
  border-radius: 0.3rem;
}
.edit-del-icons {
  display: flex;
    justify-content: center;
    /* flex-direction: column; */
    /* align-items: flex-end; */
    gap: 0.3rem;
    padding: 0.3rem 0.5rem;
    align-items: center;
    height: 110px;
}
.branch-menu-item .module-icon-wrapper:hover .blurbg {
  /* box-shadow: 0 10px 30px 0 rgba(42, 51, 62, 0.08),
    0 30px 30px 0 rgba(42, 51, 62, 0.08);
  transition: box-shadow 0.5s ease; */
  transition: all 0.5s ease-in-out;
  display: block;
}
