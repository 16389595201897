/*--- Custom-styles ---*/
/* body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: none;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    -webkit-font-feature-settings: "liga" 0;
    font-feature-settings: "liga" 0;
    height: 100%;
    overflow-y: scroll;
    overflow-x: clip;
    position: relative;
} */
.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
/* body *::-webkit-scrollbar {
    width: 3px;
    height: 4px;
    transition: 0.3s;
} */
/* *:hover::-webkit-scrollbar-thumb {
    background: #d2d4d9;
} */
.box-shadow {
  box-shadow: 0 8px 16px 0 rgba(162, 169, 204, 0.24);
}
.box-shadow-0 {
  box-shadow: none !important;
}
/* .custom-card {
    margin-block-end: 20px;
    border: 0;
    box-shadow: 0 8px 16px 0 rgba(162, 169, 204, 0.24);
} */
.card-aside {
  -ms-flex-direction: row;
  flex-direction: row;
}
.card-aside-column {
  min-width: 5rem;
  width: 30%;
  -ms-flex: 0 0 30%;
  flex: 0 0 30%;
  background: no-repeat center/cover;
  border-radius: 3px;
}
.card-options {
  margin-inline-start: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-order: 100;
  order: 100;
  margin-inline-end: -0.5rem;
  color: #99a6b7;
  -ms-flex-item-align: center;
  align-self: center;
}
.card-options a:not(.btn) {
  margin-inline-start: 0.5rem;
  color: #99a6b7;
  display: inline-block;
  min-width: 1rem;
}
.card-options a:not(.btn):hover {
  text-decoration: none;
}
.card-fullscreen {
  position: fixed;
  inset-block-start: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  inset-block-end: 0;
  z-index: 9999;
  margin: 0;
}
.card-collapsed > :not(.card-header):not(.card-status) {
  display: none;
}
.card-options a:not(.btn) i {
  font-size: 1rem;
  vertical-align: middle;
}
.card-options .dropdown-toggle:after {
  display: none;
}
.cover-image {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover !important;
}
.custom-card-header {
  background: #000;
  display: flex;
  line-height: 0;
}
.card-collapsed .card-options-collapse i:before {
  content: "\e92d";
}
.header-brand-img {
  height: 3.1rem;
  line-height: 2.5rem;
  vertical-align: middle;
  width: auto;
  margin-block-start: 4px;
}
.card-body + .card-body {
  border-block-start: 1px solid;
}
.header-navheading {
  padding-block-start: 20px;
  padding-inline-end: 20px;
  padding-block-end: 15px;
  padding-inline-start: 20px;
  text-align: center;
}
.header-navheading .badge {
  border-radius: 10rem;
}
.main-navbar .nav-sub-link:before {
  content: "\e936";
  font-family: "feather";
  margin-inline-end: 8px;
  color: #000;
  font-size: 8px;
}
.page-header .breadcrumb {
  background: none;
  padding-inline-start: 0 !important;
  padding: 0;
  margin-block-end: 0;
}
.page-header .breadcrumb-item a {
  color: var(--color);
}
.page-header .breadcrumb-item.active {
  color: blue;
}
.page-header {
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-block-start: 1.5rem;
  margin-inline-end: 0;
  margin-block-end: 1.5rem;
  margin-inline-start: 0;
  justify-content: space-between;
  padding: 0;
  position: relative;
  min-height: 50px;
}
.card-sub-title {
  font-size: 13px;
  margin-block-start: -4px;
}
.user-card .main-img-user {
  margin: 0 auto;
}
.flot-chart {
  width: 100%;
  height: 180px;
}
.flot-chart1 {
  width: 100%;
  height: 300px;
}
.flot-chart-wrapper {
  position: relative;
  margin-inline-start: -16px;
  margin-inline-end: -30px;
}
.chart-wrapper {
  position: absolute;
  inset-inline-end: -8px;
  inset-block-end: 5px;
  inset-inline-start: -8px;
}
.dash-20 {
  font-size: 20px;
}
.dash-25 {
  font-size: 25px;
}
.dash-30 {
  font-size: 30px;
}
.fs-10 {
  font-size: 10px;
}
.fs-11 {
  font-size: 11px;
}
.fs-12 {
  font-size: 12px;
}
.fs-13 {
  font-size: 13px;
}
.fs-14 {
  font-size: 14px;
}
.fs-15 {
  font-size: 15px;
}
.fs-16 {
  font-size: 16px;
}
.fs-17 {
  font-size: 17px;
}
.fs-18 {
  font-size: 18px;
}
.fs-19 {
  font-size: 19px;
}
.fs-20 {
  font-size: 20px;
}
.fs-30 {
  font-size: 30px;
}
.fs-40 {
  font-size: 40px;
}
.fs-50 {
  font-size: 50px;
}
#chartBar5 {
  height: 135px;
}
.flag-icon {
  font-size: 24px;
  line-height: 1;
  border-radius: 100%;
}
/* Back-top-button*/
#back-to-top {
  color: #fff;
  position: fixed;
  inset-block-end: 20px;
  inset-inline-end: 20px;
  z-index: 99;
  display: none;
  text-align: center;
  z-index: 10000;
  height: 30px;
  width: 30px;
  background-repeat: no-repeat;
  border: 2px solid transparent;
  border-radius: 3px;
  background: blue;
  background-position: center;
  transition: background-color 0.1s linear;
  -moz-transition: background-color 0.1s linear;
  -webkit-transition: background-color 0.1s linear;
  -o-transition: background-color 0.1s linear;
}
#back-to-top i {
  padding-block-start: 0px;
  font-size: 14px;
  line-height: 1.8rem;
}
#back-to-top:hover {
  color: blue !important;
  border: 2px solid blue;
  background: #fff !important;
}
.contact-icon {
  display: inline-flex;
  width: 35px;
  height: 35px;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 50% !important;
  font-size: 16px;
}
.contact-icon:hover {
  background: #000;
}
.accordion-color .card-header a,
.accordion-color .card-header a:hover,
.accordion-color .card-header a:focus {
  color: #000;
}
.accordion-color .card-header a.collapsed {
  background-color: blue;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 500;
  letter-spacing: -0.1px;
}
.accordion-color .card-header a.collapsed:hover,
.accordion-color .card-header a.collapsed:focus {
  background-color: blue;
  color: #fff;
}
.custom-leftnav .main-content-left-components {
  position: -webkit-sticky;
  position: sticky;
  border-inline-end: 0;
  inset-block-start: 4rem;
  margin-block-end: 20px;
}
.component-item a:before {
  content: "\e92f";
  margin-inline-end: 8px;
  position: relative;
  font-family: "feather" !important;
  color: #000;
  float: float-start;
}
.square {
  border-radius: 0 !important;
}
.radius {
  border-radius: 3px !important;
}
.icons-list {
  list-style: none;
  margin-block-start: 0;
  margin-inline-end: -1px;
  margin-block-end: -1px;
  margin-inline-start: 0;
  padding: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.icons-list-item {
  flex: 1 0 1.8rem;
  height: 3rem;
  text-align: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  border: 1px solid;
  margin: 4px;
  background-color: #000;
  color: #000;
}
.component-itemcomponent-item i {
  font-size: 1.05rem;
  color: #656585;
}
.flag {
  width: 1.6rem;
  height: 1.2rem;
  display: inline-block;
  background-repeat: no-repeat !important;
  background-size: cover;
  vertical-align: bottom;
  font-style: normal;
  box-shadow: 0 0 1px 1px #000;
  border-radius: 2px;
}
.flag-icon {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 100%;
  background-repeat: no-repeat !important;
  background-size: cover;
  vertical-align: bottom;
  font-style: normal;
}
.icons-list > li {
  flex: 1 0 3rem;
  border-radius: 3px;
}
.description-block {
  display: block;
  margin: 10px 0;
  text-align: center;
}
.skill-tags a {
  float: var(--float-start);
  margin-inline-end: 5px;
  margin-block-end: 5px;
  padding: 5px 10px;
  border-radius: 3px;
  color: #99a6b7;
  font-size: 12px;
  border: 1px solid;
}
.reviewnavs {
  padding-inline-start: 0;
  list-style: none;
  display: inline-flex;
}
.reviewnavs li {
  margin-inline-end: 5px;
}
.reviewnavs li a {
  color: #99a6b7;
}
.header-brand-img.icon-logo {
  display: none;
}
.main-header-right .nav-link.icon {
  font-size: 20px;
  color: var(--color);
  cursor: pointer;
  line-height: 0.9;
}
.dash-icon {
  position: absolute;
  inset-inline-end: 10px;
  font-size: 3rem;
  opacity: 0.5;
  color: #d3d9e4;
  inset-block-start: 10px;
}
.sidebar-body h5 {
  padding: 10px 10px;
  font-size: 14px;
  border-block-end: 1px solid;
  text-align: start;
}
.price-img {
  padding-block-start: 2rem;
}
.price-img i {
  font-size: 3rem;
}
.price-1 .list-unstyled li {
  padding: 7px 0;
}
.card-pricing h1 {
  font-size: 2.5rem !important;
  padding: 15px 30px;
  text-align: center;
}
.pricing-title {
  text-align: center;
  padding-block-start: 20px;
  font-size: 20px;
  font-weight: 500;
}
.card-pricing2 h1 {
  font-size: 2.5rem !important;
  padding: 15px 30px;
  text-align: center;
}
.card-pricing3 h1 {
  font-size: 2.5rem !important;
  padding: 15px 30px;
  text-align: center;
}
.card-pricing4 h1 {
  font-size: 2.5rem !important;
  padding: 15px 30px;
  text-align: center;
}
.card-pricing .list-unstyled li {
  padding: 0.5rem 0;
  border-block-end: 0;
}
.card-pricing2 .list-unstyled li {
  padding: 0.5rem 0;
  border-block-end: 0;
}
.card-pricing3 .list-unstyled li {
  padding: 0.5rem 0;
  border-block-end: 0;
}
.card-pricing4 .list-unstyled li {
  padding: 0.5rem 0;
  border-block-end: 0;
}
.pricing-tabs .pri-tabs-heading {
  padding: 15px;
  text-align: center;
  margin: 0 auto;
  display: inline-table;
}
.pricing-tabs .nav-price li a {
  padding-block-start: 10px;
  padding-inline-end: 20px;
  padding-block-end: 10px;
  padding-inline-start: 20px;
  color: #000;
  background: #000;
  border: 1px solid;
}
.pricing-tabs .nav-price li .active {
  background-color: blue !important;
  color: #fff !important;
}
.pricing-tabs .nav-price li .active {
  background: #000;
  color: #000;
}
.pricing-tabs .tab-content {
  padding: 15px;
  margin-block-start: 1.5rem;
}
.pricing1 .price {
  font-size: 2rem;
}
/* Timeline */
/*--- Vertical --*/
.vtimeline {
  list-style: none;
  padding: 0;
  position: relative;
  margin-block-end: 20px;
}
.vtimeline:before {
  inset-block-start: 0;
  inset-block-end: 0;
  position: absolute;
  content: " ";
  width: 3px;
  background-color: #000;
  inset-inline-start: 50%;
  margin-inline-start: -1.5px;
}
.vtimeline .timeline-wrapper {
  display: block;
  margin-block-end: 20px;
  position: relative;
  width: 100%;
  padding-inline-end: 90px;
}
.vtimeline .timeline-wrapper:before {
  content: " ";
  display: table;
}
.vtimeline .timeline-wrapper:after {
  content: " ";
  display: table;
  clear: both;
}
.vtimeline .timeline-wrapper .timeline-panel {
  border-radius: 2px;
  padding: 20px;
  position: relative;
  background-color: #000;
  border-radius: 0px;
  box-shadow: 0px 9px 16px 0 #000;
  width: 35%;
  margin-inline-start: 15%;
}
.vtimeline .timeline-wrapper.timeline-inverted .timeline-panel {
  box-shadow: 0px 9px 16px 0 #000;
}
.vtimeline .timeline-wrapper .timeline-panel:before {
  position: absolute;
  inset-block-start: 0;
  width: 100%;
  height: 2px;
  content: "";
  inset-inline-start: 0;
  inset-inline-end: 0;
}
.vtimeline .timeline-wrapper .timeline-panel:after {
  position: absolute;
  inset-block-start: 25px;
  inset-inline-end: -18px;
  display: inline-block;
  border-block-start: 10px solid transparent;
  border-inline-start: 18px solid var(--light);
  border-inline-end: 0 solid var(--light);
  border-block-end: 10px solid transparent;
  content: " ";
}
.vtimeline .timeline-wrapper .timeline-panel .timeline-title {
  font-size: 1rem;
  font-weight: 700;
  margin-block-start: 0;
  margin-inline-end: 0;
  margin-block-end: 0.625rem;
  margin-inline-start: 0;
}
.vtimeline .timeline-wrapper .timeline-panel .timeline-body p + p {
  margin-block-start: 5px;
}
.vtimeline .timeline-wrapper .timeline-panel .timeline-body ul {
  margin-block-end: 0;
}
.vtimeline .timeline-wrapper .timeline-panel .timeline-footer span {
  font-size: 0.6875rem;
}
.vtimeline .timeline-wrapper .timeline-badge {
  width: 14px;
  height: 14px;
  position: absolute;
  inset-block-start: 16px;
  inset-inline-start: calc(50% - 7px);
  z-index: 0;
  border-start-end-radius: 50%;
  border-start-start-radius: 50%;
  border-end-end-radius: 50%;
  border-end-start-radius: 50%;
  border: 2px solid #fff;
}
.vtimeline .timeline-wrapper .timeline-badge i {
  color: #fff;
}
.vtimeline .timeline-wrapper.timeline-inverted {
  padding-inline-end: 0;
  padding-inline-start: 90px;
}
.vtimeline .timeline-wrapper.timeline-inverted .timeline-panel {
  margin-inline-start: auto;
  margin-inline-end: 15%;
}
.vtimeline .timeline-wrapper.timeline-inverted .timeline-panel:after {
  border-inline-start-width: 0;
  border-inline-end-width: 14px;
  inset-inline-start: -14px;
  inset-inline-end: auto;
}
.timeline-wrapper-primary .timeline-panel:before {
  background: blue;
}
.timeline-wrapper-primary .timeline-badge {
  background: blue;
}
.timeline-wrapper-secondary .timeline-panel:before {
  background: #eb6f33;
}
.timeline-wrapper-secondary .timeline-badge {
  background: #eb6f33;
}
.timeline-wrapper-success .timeline-panel:before {
  background: #03c895;
}
.timeline-wrapper-success .timeline-badge {
  background: #03c895;
}
.timeline-wrapper-info .timeline-panel:before {
  background: #01b8ff;
}
.timeline-wrapper-info .timeline-badge {
  background: #01b8ff;
}
.timeline-wrapper-warning .timeline-panel:before {
  background: #fcd539;
}
.timeline-wrapper-warning .timeline-badge {
  background: #fcd539;
}
.timeline-wrapper-danger .timeline-panel:before {
  background: #ff473d;
}
.timeline-wrapper-danger .timeline-badge {
  background: #ff473d;
}
.timeline-wrapper-light .timeline-panel:before {
  background: #f1f2f9;
}
.timeline-wrapper-light .timeline-badge {
  background: #f1f2f9;
}
.timeline-wrapper-dark .timeline-panel:before {
  background: #1c273c;
}
.timeline-wrapper-dark .timeline-badge {
  background: #1c273c;
}
.text-wrap {
  font-size: 14px;
  line-height: 1.66;
}
.example {
  padding: 1.5rem;
  border: 1px solid;
  font-size: 0.9375rem;
  border-radius: 3px;
}
.example + .highlight {
  border-block-start: none;
  margin-block-start: 0;
}
.text-wrap > :last-child {
  margin-block-end: 0;
}
.highlight {
  margin-block-start: 1rem;
  margin-inline-end: 0;
  margin-block-end: 2rem;
  margin-inline-start: 0;
  border: 1px solid;
  border-radius: 3px;
  font-size: 0.9375rem;
  max-height: 20rem;
  background: #edeff7;
  overflow: auto;
}
.clip-widget {
  position: relative;
}
.clipboard-icon {
  position: absolute;
  inset-block-start: 10px;
  inset-inline-end: 10px;
  font-size: 14px;
  background: #fff;
  border: 1px solid;
  padding: 5px 10px;
  border-radius: 0px;
  cursor: pointer;
}
.icon-service {
  display: inline-flex;
  width: 3.5rem;
  height: 3.5rem;
  padding: 1.1rem;
  margin-block-end: 0.5rem;
}
.icon-service i {
  font-size: 1.2rem;
}
.our-team .picture {
  height: 100px;
  width: 100px;
  margin: 0 auto;
  z-index: 0;
  position: relative;
  overflow: hidden;
}
.our-team:hover .picture::before {
  height: 100%;
}
.our-team .picture::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: blue;
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  z-index: -1;
}
.our-team .picture img {
  width: 100%;
  height: auto;
  border-radius: 50%;
  transform: scale(1);
  transition: all 0.9s ease 0s;
}
.our-team:hover .picture img {
  box-shadow: 0 0 0 3px;
  transform: scale(0.8);
}
.iti__hide {
  display: none;
}
.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string,
.token.variable {
  background: none;
}
.user-lock img {
  width: 70px;
  height: 70px;
  margin: 0 auto;
}
.eamil-body {
  margin-block-start: 30px;
}
/*-- rating--*/
.rating-stars {
  width: 100%;
  text-align: center;
  font-size: 30px;
}
.rating-stars .rating-stars-container {
  font-size: 0px;
}
.rating-stars .rating-stars-container .rating-star {
  display: inline-block;
  font-size: 30px;
  cursor: pointer;
  padding: 4px 8px;
  color: #000;
}
.rating-stars .rating-stars-container .rating-star.is--active .fa-heart,
.rating-stars .rating-stars-container .rating-star.is--hover .fa-heart {
  color: #fb0d00;
}
.rating-stars .rating-stars-container .rating-star.sm {
  display: inline-block;
  font-size: 14px;
  color: #eaedf1;
  cursor: pointer;
  padding: 5px;
}
.rating-stars .rating-stars-container .rating-star.is--active,
.rating-stars .rating-stars-container .rating-star.is--hover {
  color: #f1c40f;
}
.rating-stars .rating-stars-container .rating-star.is--no-hover {
  color: #f1f1f9;
}
.rating-stars .rating-stars-container .rating-star .fa-heart .is--no-hover {
  color: #f1f1f9;
}
.rating-stars input {
  display: block;
  margin: 0 auto;
  text-align: center;
  padding: 0.375rem 0.75rem;
  font-size: 0.9375rem;
  line-height: 1.6;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #eaedf1;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.task-list {
  list-style: none;
  position: relative;
  margin: 0;
  padding-block-start: 28px;
  padding-inline-end: 0;
  padding-block-end: 0;
  padding-inline-start: 0;
}
.task-list:after {
  content: "";
  position: absolute;
  background: #000;
  height: 100%;
  width: 1px;
  inset-block-start: 0;
  inset-inline-start: 30px;
  z-index: 1;
}
.task-list li {
  margin-block-end: 20px;
  padding-inline-start: 55px;
  position: relative;
}
.task-list li:last-child {
  margin-block-end: 0;
}
.task-list li .task-icon {
  position: absolute;
  inset-inline-start: 22px;
  inset-block-start: 13px;
  border-radius: 50%;
  padding: 2px;
  width: 17px;
  height: 17px;
  z-index: 2;
}
.task-list li h6 {
  margin-block-end: 5px;
}
.btn-group-sm > .btn,
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 3px;
  min-height: 0;
}
.item-card {
  overflow: hidden;
}
.item-card::before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  opacity: 0;
  transition: all ease 0.3s;
}
.item-card:hover::before,
.item-card:hover .product-info {
  opacity: 1;
}
.item-card:hover .product-info {
  inset-block-end: 45%;
}
.item-card .product-info {
  padding: 0;
  margin: 0;
  opacity: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  text-align: center;
  position: absolute;
  inset-block-end: -30px;
  z-index: 1;
  transition: all 0.5s ease 0s;
}
.product_price li {
  display: inline-block;
  padding: 0 10px;
  font-weight: 500;
  font-size: 16px;
}
.product_price .old_price {
  text-decoration: line-through !important;
  color: #99a6b7;
}
.productdesc .list-unstyled li {
  border-block-end: 0 !important;
  margin-block-end: 18px;
}
.productdesc .list-unstyled li:last-child {
  margin-block-end: 0 !important;
}
.error-bg {
  /* background-image: url(../img/pngs/15.png); */
  background-position: center;
  background-size: cover;
}
.breadcrumb-3 ol {
  position: relative;
  display: block;
  width: 100%;
  text-align: center;
}
.breadcrumb-3 li {
  display: contents;
  text-align: center;
}
.breadcrumb-4 ol {
  position: relative;
  display: block;
  width: 100%;
  text-align: right;
}
.breadcrumb-4 li {
  display: contents;
  text-align: right;
}
/* Thumb Carousel */
.carousel-slider #carousel {
  margin: 0;
}
.carousel-slider #thumbcarousel {
  margin-block-start: 10px;
  margin-inline-end: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  padding: 0;
}
.carousel-slider #thumbcarousel .carousel-item {
  text-align: center;
}
.carousel-slider #thumbcarousel .carousel-item .thumb {
  width: 100%;
  margin: 0 2px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  max-width: 100px;
}
.carousel-slider #thumbcarousel .carousel-item .thumb:hover {
  border-color: #000;
}
.carousel-slider .carousel-item img {
  width: 100%;
  height: auto;
}
.carousel-control:active,
.carousel-control:focus,
.carousel-control:hover {
  color: #333;
}
.carousel-caption,
.carousel-control .fa {
  font: normal normal normal 30px/26px FontAwesome;
}
#thumbcarousel .carousel-control-prev {
  inset-inline-start: 0;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 3px;
}
#thumbcarousel .carousel-control-next {
  inset-inline-end: 0;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 3px;
}
.owl-carousel .owl-item {
  position: relative;
  /* cursor: url(../plugins/owl-carousel/cursor.png), move; */
  overflow: hidden;
}
.owl-nav {
  display: block;
}
.owl-carousel {
  position: relative;
}
.owl-nav .owl-prev {
  position: absolute;
  inset-block-start: 50%;
  inset-inline-start: -19px;
  inset-inline-end: -1.5em;
  margin-block-start: -1.65em;
}
.owl-nav .owl-next {
  position: absolute;
  inset-block-start: 50%;
  inset-inline-end: -19px;
  margin-block-start: -1.65em;
}
.owl-nav button {
  display: block;
  font-size: 1.3rem !important;
  line-height: 2em;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  text-align: center;
  background: rgba(255, 255, 255, 0.5) !important;
  border: 1px solid #e8ebf3;
  z-index: 99;
  box-shadow: 0 4px 15px rgba(67, 67, 67, 0.15);
}
.owl-nav button:before {
  content: "";
  position: absolute;
  z-index: -1;
  inset-block-start: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  inset-block-end: 0;
  background: #2098d1;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
/* .owl-carousel:hover .owl-nav button {
    background: !important;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    animation: sonarEffect 1.3s ease-out 75ms;
} */
.owl-nav > div i {
  margin: 0;
}
.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  inset-block-end: 0.65em;
  inset-inline-start: 0;
  inset-inline-end: 0;
  z-index: 99;
}
.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
}
.owl-theme .owl-dots .owl-dot span {
  width: 1em;
  height: 1em;
  margin: 5px 7px;
  background: rgba(0, 0, 0, 0.3);
  display: block;
  transition: opacity 0.2s ease;
  border-radius: 30px;
}
.product-carousel .thumb {
  border: 1px solid;
  padding: 5px;
  border-radius: 3px;
}
.btn-list {
  margin-block-end: -0.5rem;
  font-size: 0;
  padding: 0;
}
.btn-list > .btn:not(:last-child),
.btn-list > .dropdown:not(:last-child) {
  margin-inline-end: 0.5rem;
}
.btn-list > .btn,
.btn-list > .dropdown {
  margin-block-end: 0.5rem;
}
.echart-height {
  height: 250px;
}

/* Input Height */
.input-lg {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
}
.input-sm {
  height: 28px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}
/*----Custom Switch-----*/
.custom-switch {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;
  padding-inline-start: 0;
}
.custom-switch-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-switches-stacked {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}
.custom-switches-stacked .custom-switch {
  margin-block-end: 0.5rem;
}
.custom-switch-indicator {
  display: inline-block;
  height: 1.25rem;
  width: 2.25rem;
  background: #000;
  border-radius: 50px;
  position: relative;
  vertical-align: bottom;
  border: 1px solid;
  transition: 0.3s border-color, 0.3s background-color;
}
.custom-switch-indicator:before {
  content: "";
  position: absolute;
  height: calc(1.25rem - 4px);
  width: calc(1.25rem - 4px);
  inset-block-start: 1px;
  inset-inline-start: 1px;
  background: #fff;
  border-radius: 50%;
  transition: 0.3s left;
  box-shadow: none;
}
.custom-switch-input:checked ~ .custom-switch-indicator {
  background: blue;
}
.custom-switch-input:checked ~ .custom-switch-indicator:before {
  inset-inline-start: calc(1rem + 1px);
}
.custom-switch-input:focus ~ .custom-switch-indicator {
  box-shadow: none;
  border-color: blue;
}
.custom-switch-indicator-md {
  display: inline-block;
  height: 1.5rem;
  width: 3rem;
  background: #000;
  border-radius: 50px;
  position: relative;
  vertical-align: bottom;
  border: 1px solid;
  transition: 0.3s border-color, 0.3s background-color;
}
.custom-switch-indicator-md::before {
  content: "";
  position: absolute;
  height: calc(1.5rem - 4px);
  width: calc(1.5rem - 4px);
  inset-block-start: 1px;
  inset-inline-start: 1px;
  background: #fff;
  border-radius: 50%;
  transition: 0.3s left;
  box-shadow: none;
}
.custom-switch-input:checked
  ~ .custom-switch-indicator.custom-switch-indicator-md:before {
  inset-inline-start: calc(1.46rem + 1px);
}
.custom-switch-indicator-lg {
  display: inline-block;
  height: 2rem;
  width: 4rem;
  background: #000;
  border-radius: 50px;
  position: relative;
  vertical-align: bottom;
  border: 1px solid;
  transition: 0.3s border-color, 0.3s background-color;
}
.custom-switch-indicator-lg::before {
  content: "";
  position: absolute;
  height: calc(2rem - 4px);
  width: calc(2rem - 4px);
  inset-block-start: 1px;
  inset-inline-start: 1px;
  background: #fff;
  border-radius: 50%;
  transition: 0.3s left;
  box-shadow: none;
}
.custom-switch-input:checked
  ~ .custom-switch-indicator.custom-switch-indicator-lg:before {
  inset-inline-start: calc(1.96rem + 1px);
}
.custom-control-label-md {
  padding-inline-start: 10px;
  padding-block-start: 6px;
}
.custom-control-label-md::before {
  inset-block-start: 0.25rem;
  width: 1.5rem;
  height: 1.5rem;
}
.custom-control-label-md::after {
  inset-block-start: 0.25rem;
  width: 1.5rem;
  height: 1.5rem;
}
.custom-control-label-lg {
  padding-inline-start: 16px;
  padding-block-start: 10px;
}
.custom-control-label-lg::before {
  inset-block-start: 0.25rem;
  width: 2rem;
  height: 2rem;
}
.custom-control-label-lg::after {
  inset-block-start: 0.25rem;
  width: 2rem;
  height: 2rem;
}
.custom-controls-stacked .custom-control-lg {
  margin-block-end: 1.25rem;
}
.custom-controls-stacked .custom-control-md {
  margin-block-end: 0.75rem;
}
.custom-switch-description {
  margin-inline-start: 0.5rem;
  color: #99a6b7;
  transition: 0.3s color;
}
.custom-switch-input:checked ~ .custom-switch-description {
  color: #000;
}
/*----Select Group-----*/
.selectgroup {
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.selectgroup-item {
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
}
.selectgroup-item + .selectgroup-item {
  margin-inline-start: -1px;
}
.selectgroup-input {
  opacity: 0;
  position: absolute;
  z-index: -1;
  inset-block-start: 0;
  inset-inline-start: 0;
}
.selectgroup-button {
  display: block;
  border: 1px solid;
  text-align: center;
  padding: 0.375rem 1rem;
  position: relative;
  cursor: pointer;
  border-radius: 3px;
  color: #686868;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.selectgroup-button-icon {
  padding-inline-start: 0.5rem;
  padding-inline-end: 0.5rem;
  font-size: 1rem;
}
.selectgroup-input:checked + .selectgroup-button {
  border-color: blue;
  z-index: 1;
  color: blue;
  background: #000;
}
.selectgroup-input:focus + .selectgroup-button {
  border-color: blue;
  z-index: 2;
  color: blue;
  box-shadow: none;
}
.selectgroup-pills {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: start;
  align-items: flex-start;
}
.selectgroup-pills .selectgroup-item {
  margin-inline-end: 0.5rem;
  -ms-flex-positive: 0;
  flex-grow: 0;
}
.colorinput {
  margin-inline-end: 5px;
  position: relative;
  cursor: pointer;
}
.colorinput-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.colorinput-color {
  display: inline-block;
  width: 1.75rem;
  height: 1.75rem;
  border: 1px solid transparent;
  color: #fff;
  box-shadow: none;
}
.colorinput-color:before {
  content: "";
  opacity: 0;
  position: absolute;
  inset-block-start: 0.25rem;
  inset-inline-start: 0.25rem;
  height: 1.25rem;
  width: 1.25rem;
  transition: 0.3s opacity;
  /* background: url("data:image/svg+xml; */
}
.colorinput-input:checked ~ .colorinput-color:before {
  opacity: 1;
}
.acc-step-number {
  padding: 5px 10px;
  font-size: 14px;
}
/* Tags */
.tags {
  margin-block-end: -0.5rem;
  font-size: 0;
}
.tags > .tag:not(:last-child) {
  margin-inline-end: 0.5rem;
}
.tag-addon {
  display: inline-block;
  padding: 0 0.3rem;
  color: inherit;
  text-decoration: none;
  margin-block-start: 0;
  margin-inline-end: -0.5rem;
  margin-block-end: 0;
  margin-inline-start: 0.5rem;
  text-align: center;
  min-width: 1.5rem;
  border-inline-start: 1px solid #fff;
}
.tag-addon-1 {
  display: inline-block;
  padding: 0 0.5rem;
  color: inherit;
  text-decoration: none;
  margin-block-start: 0;
  margin-inline-end: -0.5rem;
  margin-block-end: 0;
  margin-inline-start: 0.5rem;
  text-align: center;
  min-width: 1.5rem;
  border-start-start-radius: 0;
  border-start-end-radius: 3px;
  border-end-end-radius: 3px;
  border-end-start-radius: 0px;
}
.tag-addon-1:hover {
  background: #000;
  color: #000;
}
.tag-addon-1 i {
  vertical-align: middle;
  margin: 0 -0.25rem;
  line-height: 1.7;
}
.tag-addon i {
  vertical-align: middle;
  margin: 0 -0.25rem;
  line-height: 1.6;
}
.tag {
  font-size: 0.75rem;
  border-radius: 3px;
  padding: 0 0.5rem;
  line-height: 1.5rem;
  display: -ms-inline-flexbox;
  display: inline-flex;
  cursor: default;
  font-weight: 400;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #000;
}
.tag-pill {
  padding-inline-end: 0.6em;
  padding-inline-start: 0.6em;
  border-radius: 50px;
}
.tag-default {
  background-color: #000;
  color: #000;
}
.tag-dark {
  background-color: #000;
  color: #000;
}
.tag-primary {
  background-color: blue;
}
.tag-secondary {
  background-color: #eb6f33;
}
.tag-info {
  background-color: #01b8ff;
}
.tag-warning {
  background-color: #f3b90b;
}
.tag-danger {
  background-color: #ff473d;
}
.tag-success {
  background-color: #03c895;
}
.tag-pink {
  background-color: #f10075;
}
.tag-teal {
  background-color: #0cc;
}
.tags .tag-addon-close i {
  content: "×";
  padding: 3px 3px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  font-size: 11px;
}
.tags .tag-addon-close i:hover {
  background-color: rgba(0, 0, 0, 0.3);
}
.tags .tag-radius {
  border-start-start-radius: 50px !important;
  border-start-end-radius: 0 !important;
  border-end-end-radius: 0 !important;
  border-end-start-radius: 50px !important;
}
.tags .tag-outline-primary {
  background-color: rgba(236, 233, 245, 1) !important;
  color: blue !important;
  border: 1px solid blue;
}
.tags .tag-outline-secondary {
  background-color: rgba(249, 235, 228, 1) !important;
  color: #eb6f33 !important;
  border: 1px solid #eb6f33;
}
.tags .tag-outline-warning {
  background-color: rgba(255, 162, 43, 0.1) !important;
  color: #f3b90b !important;
  border: 1px solid #f3b90b;
}
.tags .tag-outline-danger {
  background-color: rgba(255, 56, 43, 0.1) !important;
  color: #ff473d !important;
  border: 1px solid #ff473d;
}
.tags .tag-outline-info {
  background-color: #d5edf7 !important;
  color: #01b8ff !important;
  border: 1px solid #01b8ff;
}
.tags .tag-outline-success {
  background-color: rgba(0, 230, 130, 0.1) !important;
  color: #03c895 !important;
  border: 1px solid #03c895;
}
.tags .tag-outline-pink {
  background-color: rgba(250, 58, 151, 0.1) !important;
  color: #f10075 !important;
  border: 1px solid #f10075;
}
.tags .tag-outline-teal {
  background-color: rgba(97, 248, 248, 0.1) !important;
  color: #0cc !important;
  border: 1px solid #0cc;
}
.tags .tag-outline-primary::before {
  border: 1px solid blue;
}
.tags .tag-outline-secondary::before {
  border: 1px solid #eb6f33;
}
.tags .tag-outline-success::before {
  border: 1px solid #03c895;
}
.tags .tag-outline-warning::before {
  border: 1px solid #f3b90b;
}
.tags .tag-outline-danger::before {
  border: 1px solid #ff473d;
}
.tags .tag-round::before {
  content: "";
  width: 8px;
  height: 8px;
  background-color: #fff;
  border-radius: 50%;
  margin-block-start: 8px;
  margin-inline-end: 5px;
}
.tags a.tag {
  cursor: pointer;
  color: #000;
}
.tags a.tag:hover {
  background-color: #000;
}
.tags .tag .tag-avatar {
  width: 1.5rem;
  height: 1.5rem;
  border-start-start-radius: 3px !important;
  border-start-end-radius: 0 !important;
  border-end-end-radius: 0 !important;
  border-end-start-radius: 3px !important;
  margin-block-start: 0 !important;
  margin-inline-end: 0.5rem !important;
  margin-block-end: 0 !important;
  margin-inline-start: -0.5rem !important;
}
.tags .tags .tag-round::before {
  content: "";
  width: 8px;
  height: 8px;
  background-color: #fff;
  border-radius: 50%;
  margin-block-start: 8px;
  margin-inline-end: 5px;
}
.tags .tag-icon i {
  margin-block-start: 6px !important;
}
.bootstrap-tagsinput .badge {
  border-radius: 3px !important;
}
/* Mapel map */
.mapael .map {
  margin-block-end: 10px;
  position: relative;
}
.mapael .zoomButton {
  background-color: #292929;
  border: 1px solid #292929;
  color: #fff;
  width: 15px;
  height: 15px;
  line-height: 15px;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
  position: absolute;
  inset-block-start: 0;
  font-weight: bold;
  inset-inline-start: 10px;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.mapael .zoomReset {
  inset-block-start: 10px;
}
.mapael .zoomIn {
  inset-block-start: 30px;
}
.mapael .zoomOut {
  inset-block-start: 50px;
}
.mapael .mapTooltip {
  position: absolute;
  background-color: #474c4b;
  opacity: 0.7;
  filter: alpha(opacity=70);
  border-radius: 10px;
  padding: 10px;
  z-index: 1000;
  max-width: 200px;
  display: none;
  color: #fff;
}
/* * Loaders * ---------------------- */
/*--circleloader--*/
.lds-circle {
  display: inline-block;
  transform: translateZ(1px);
}
.lds-circle > div {
  display: inline-block;
  width: 51px;
  height: 51px;
  margin: 6px;
  border-radius: 50%;
  animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  background: blue;
}
@keyframes lds-circle {
  0%,
  100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
  }
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(1800deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
  }
  100% {
    transform: rotateY(3600deg);
  }
}
/*---ring loaders --*/
.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  animation: lds-dual-ring 1.2s linear infinite;
  border: 5px solid blue;
  border-color: blue transparent blue transparent;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*-- lines loader --*/
.lds-facebook {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  inset-inline-start: 6px;
  width: 13px;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  background: blue;
}
.lds-facebook div:nth-child(1) {
  inset-inline-start: 6px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  inset-inline-start: 26px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  inset-inline-start: 45px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    inset-block-start: 6px;
    height: 51px;
  }
  50%,
  100% {
    inset-block-start: 19px;
    height: 26px;
  }
}
/*--heart loader --*/
.lds-heart {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  transform: rotate(45deg);
  transform-origin: 32px 32px;
}
.lds-heart div {
  inset-block-start: 23px;
  inset-inline-start: 19px;
  position: absolute;
  width: 26px;
  height: 26px;
  animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
  background: blue;
}
.lds-heart div:after,
.lds-heart div:before {
  content: " ";
  position: absolute;
  display: block;
  width: 26px;
  height: 26px;
  background: blue;
}
.lds-heart div:before {
  inset-inline-start: -17px;
  border-start-start-radius: 50%;
  border-start-end-radius: 0;
  border-end-end-radius: 0;
  border-end-start-radius: 50%;
}
.lds-heart div:after {
  inset-block-start: -17px;
  border-start-start-radius: 50%;
  border-start-end-radius: 50%;
  border-end-end-radius: 0;
  border-end-start-radius: 0;
}
@keyframes lds-heart {
  0% {
    transform: scale(0.95);
  }
  5% {
    transform: scale(1.1);
  }
  39% {
    transform: scale(0.85);
  }
  45% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
  }
}
/**-ripple --*/
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ripple div {
  position: absolute;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  border: 4px solid blue;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    inset-block-start: 28px;
    inset-inline-start: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    inset-block-start: -1px;
    inset-inline-start: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}
/*-- Spinner loader --*/
.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-spinner div {
  transform-origin: 32px 32px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  inset-block-start: 3px;
  inset-inline-start: 29px;
  width: 5px;
  height: 14px;
  border-radius: 20%;
  background: blue;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.dt-button-collection .dropdown-item.active,
.dt-button-collection .dropdown-item:active {
  color: #000;
  text-decoration: none;
}
.img-thumbnail .caption {
  padding: 15px;
  padding-inline-start: 0;
}
.sidebar-icon {
  position: absolute;
  inset-inline-end: 8px;
  inset-block-start: 3px;
}
/* Animations */
.pulse {
  display: block;
  position: absolute;
  inset-block-start: 7px;
  inset-inline-end: 12px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #ff473d;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(255, 71, 61, 0.9);
  animation: pulse 2s infinite;
}
.pulse:hover {
  animation: none;
}
@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 71, 61, 0.9);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(255, 71, 61, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 71, 61, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 71, 61, 0.9);
    box-shadow: 0 0 0 0 rgba(255, 71, 61, 0.7);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(255, 71, 61, 0);
    box-shadow: 0 0 0 10px rgba(255, 71, 61, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 71, 61, 0);
    box-shadow: 0 0 0 0 rgba(255, 71, 61, 0);
  }
}
.ripple {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  cursor: pointer;
  border-radius: 3px;
}
.ripple:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  inset-block-start: 0;
  inset-inline-start: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, #000 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform 0.5s, opacity 1s;
}
.ripple:active:after {
  transform: scale(0, 0);
  opacity: 0.2;
  transition: 0s;
}
@keyframes fadein {
  from {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 1;
    transform: none;
    transform: translate3d(0, 0, 0);
  }
}
.main-profile-menu.show .dropdown-menu {
  display: block;
}
.main-profile-menu .dropdown-menu {
  padding-block-start: 0px;
  position: absolute;
  inset-block-start: 51px;
  inset-inline-start: auto;
  inset-inline-end: -19px;
  inset-block-end: auto;
  border-radius: 0.5rem !important;
  width: 140px;
  /* border-color: #000; */
  border-width: 1px;
  box-shadow: none !important;
  /* box-shadow: 0 8px 16px 0 rgba(162, 169, 204, 0.24);
  box-shadow:  16px 16px 31px #c5c5c5,
             16px 16px 31px #fbfbfb; */
}
.fadein,
.main-header-notification.show .dropdown-menu,
.main-profile-menu.show .dropdown-menu,
.header-search.show .dropdown-menu {
  animation-name: fadein;
  -webkit-animation-duration: 0.2s;
  -moz-animation-duration: 0.2s;
  -ms-animation-duration: 0.2s;
  -o-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
}
.main-header-notification.show .dropdown-menu::before,
.header-search.show .dropdown-menu::before {
  content: "";
  width: 13px;
  height: 13px;
  background: #fff;
  position: absolute;
  inset-block-start: -7px;
  inset-inline-end: 28px;
  transform: rotate(45deg);
  border-block-start: 1px solid;
  border-inline-start: 1px solid;
}
.main-profile-menu.show .dropdown-menu::before {
  content: "";
  width: 13px;
  height: 13px;
  background: #fff;
  position: absolute;
  inset-block-start: -7px;
  inset-inline-end: 34px;
  transform: rotate(45deg);
  border-block-start: 1px solid;
  border-inline-start: 1px solid;
}
.header-search.show .dropdown-menu {
  display: block;
  margin: 0;
}
.header-search .dropdown-menu {
  width: 300px;
  position: absolute;
  inset-block-start: 50px;
  inset-inline-start: auto;
  inset-inline-end: -10px;
  inset-block-end: auto;
  padding: 0;
  border-color: #000;
  border-width: 1px;
  box-shadow: 0 8px 16px 0 rgba(162, 169, 204, 0.24);
}
.main-form-search {
  flex: 1;
  width: 100%;
  position: relative;
}
.main-form-search .form-control {
  border-color: #000;
  border-width: 1px;
  background-color: var(--white);
  transition: none;
  height: 40px;
}
.main-form-search .form-control:focus {
  border-color: #000;
  box-shadow: none !important;
}
.main-form-search .form-control:focus + .btn,
.main-form-search .sp-container .form-control:focus + button,
.sp-container .main-form-search .form-control:focus + button {
  color: #99a6b7;
}
.main-form-search .btn,
.main-form-search .sp-container button,
.sp-container .main-form-search button {
  position: absolute;
  inset-block-start: 8px;
  inset-inline-end: 5px;
  background-color: transparent;
  height: 40px;
  color: #b4bdce;
  transition: none;
  font-size: 16px;
  padding-inline-end: 13px;
}
.main-form-search .btn:hover,
.main-form-search .sp-container button:hover,
.sp-container .main-form-search button:hover,
.main-form-search .btn:focus,
.main-form-search .sp-container button:focus,
.sp-container .main-form-search button:focus {
  color: #000;
}
.theme-logo {
  display: none !important;
}
/*------ Charts Heights ------*/
.chart-height1 {
  min-height: 15rem;
  overflow: hidden;
}
.chart-height2 {
  height: 16rem;
  overflow: hidden;
}
.chart-height3 {
  height: 17rem;
  overflow: hidden;
}
.chart-height4 {
  height: 18rem;
  overflow: hidden;
}
.chart-height5 {
  height: 20rem;
  overflow: hidden;
}
.chart-height6 {
  height: 22rem;
  overflow: hidden;
}
.img-flag {
  width: 25px;
  height: 12px;
  margin-block-start: -4px;
}
/*Advanced Filter */
.advanced-search {
  background: #000;
  padding: 1.25rem;
  box-shadow: 0 8px 16px 0 rgba(162, 169, 204, 0.24);
  margin-block-end: 20px;
}
.theme-logos {
  display: none;
}
.dot-label {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100% !important;
  margin-inline-end: 5px;
}
.contact-info .contact-icon:hover {
  color: #000;
}
.scroll-widget {
  width: 100%;
  overflow: hidden;
}
.activity-block .task-list li {
  padding-inline-start: 25px;
  margin-block-end: 20px;
}
.activity-block .task-list {
  padding-block-start: 0;
}
.activity-block h6 {
  margin-block-end: 0px;
}
.activity-block .task-list:after {
  inset-inline-start: 5px;
  z-index: 0;
}
.activity-block .task-list li .task-icon {
  inset-block-start: 0;
  inset-inline-start: 0px;
  width: 12px;
  height: 12px;
}
.card-blog-overlay1 {
  /* background: url("../img/media/3.jpg"); */
  background-size: cover;
}
.card-blog-overlay2 {
  /* background: url("../img/media/1.jpg"); */
  background-size: cover;
}
.z-index2 {
  z-index: 2;
}
.card-blog-overlay {
  /* background: url("../img/media/2.jpg"); */
  background-size: cover;
  position: relative;
}
.card-blog-overlay:before,
.card-blog-overlay1:before,
.card-blog-overlay2:before {
  content: "";
  display: block;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  inset-inline-end: 0;
  inset-block-start: 0;
  border-radius: 3px;
}
.card-blog-overlay .custom-card-header,
.card-blog-overlay1 .custom-card-header,
.card-blog-overlay2 .custom-card-header {
  background: none;
  border-block-end: 1px solid rgba(255, 255, 255, 0.2);
}
.card-blog-overlay .card-footer,
.card-blog-overlay1 .card-footer,
.card-blog-overlay2 .card-footer {
  background: none;
  border-block-start: 1px solid rgba(255, 255, 255, 0.2);
}
div.dtr-modal div.dtr-modal-content {
  box-shadow: none;
}
.table.dtr-details {
  margin-block-end: 0;
  border: 1px solid;
}
.main-notification-text .badge {
  line-height: 1.5;
}
/*loader*/
#global-loader {
  position: fixed;
  z-index: 50000;
  background: #fff;
  inset-inline-start: 0;
  inset-block-start: 0;
  inset-inline-end: 0;
  inset-block-end: 0;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  display: block;
}
.loader-img {
  position: absolute;
  inset-inline-end: 0;
  inset-block-end: 0;
  inset-block-start: 43%;
  inset-inline-start: 0;
  margin: 0 auto;
  text-align: center;
}
.user-info {
  list-style: none;
  margin-block-end: 0;
  padding-inline-start: 0;
}
.side-header .main-profile-menu .dropdown-menu {
  inset-block-start: 51px;
}
.side-header .header-search .dropdown-menu {
  inset-block-start: 50px;
}
#example-input .form-control::placeholder,
#example-input .form-control {
  color: #fff !important;
}
/* ----- center logo ----- */
.main-message-list .media {
  padding-block-start: 10px;
  padding-inline-end: 10px;
  padding-block-end: 0px;
  padding-inline-start: 15px;
}
.main-header-flags {
  position: relative;
}
.fadein,
.main-header-flags.show .dropdown-menu {
  animation-name: fadein;
  -webkit-animation-duration: 0.2s;
  -moz-animation-duration: 0.2s;
  -ms-animation-duration: 0.2s;
  -o-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
}
.main-header-flags.show .dropdown-menu {
  display: block;
}
.main-header-flags .dropdown-menu {
  width: 200px;
  position: absolute;
  inset-block-start: 48px;
  inset-inline-end: auto;
  inset-inline-start: -10px;
  inset-block-end: auto;
  padding: 0;
  border-color: #000;
  border-width: 1px;
  box-shadow: 0 8px 16px 0 rgba(162, 169, 204, 0.24);
}
.main-header-flags.show .dropdown-menu::before {
  content: "";
  width: 13px;
  height: 13px;
  background: #fff;
  position: absolute;
  inset-block-start: -7px;
  inset-inline-start: 28px;
  transform: rotate(45deg);
  border-block-start: 1px solid;
  border-inline-start: 1px solid;
}
.main-flags-list .media {
  padding-block-start: 10px;
  padding-inline-end: 10px;
  padding-block-end: 0;
  padding-inline-start: 15px;
}
.main-header-flags .dropdown-footer {
  text-align: center;
  padding: 10px;
  font-size: 13px;
  border-block-start: 1px solid rgba(123, 65, 216, 0.1);
}
.header-flag {
  width: 1.7rem;
}
.main-flags-list img {
  width: 1.7rem;
}
.hor-header.hor-top-header .nav-link-badge {
  position: absolute;
  inset-block-start: 0px;
  inset-inline-start: 11px;
  display: block !important;
  padding: 3px 5px !important;
  border-radius: 50px;
  font-size: 10px;
}
.hor-header.hor-top-header .main-message-list p {
  font-size: 12px;
}
@media (max-width: 991px) {
  .hor-header.hor-top-header .main-logo {
    inset-inline-start: 0px;
    margin-inline-end: auto;
  }
}
.valid-feedback {
  display: none;
  width: 100%;
  margin-block-start: 0.25rem;
  font-size: 80%;
  color: #000;
}
.valid-tooltip {
  position: absolute;
  inset-block-start: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-block-start: 0.1rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  color: #000;
  background-color: rgba(59, 176, 1, 0.9);
  border-radius: 3px;
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-block-start: 0.25rem;
  font-size: 80%;
  color: red;
}
.invalid-tooltip {
  position: absolute;
  inset-block-start: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-block-start: 0.1rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  color: #000;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 3px;
}
input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}
.fade {
  transition: opacity 0.15s linear;
}
.fade:not(.show) {
  opacity: 0;
}
.collapse:not(.show) {
  display: none;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}
@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.btn-close {
  float: float-end;
  font-size: 1.3125rem;
  font-weight: 700;
  line-height: 1;
  color: inherit;
  text-shadow: 0 1px 0 #000;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
}
a.btn-close.disabled {
  pointer-events: none;
}
.toast {
  width: auto;
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
  background-color: #000;
  border-color: #000;
  box-shadow: 0 2px 3px rgba(28, 39, 60, 0.06);
  border-radius: 3px;
}
.toast:not(:last-child) {
  margin-block-end: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}
.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  background-color: #000;
  background-clip: padding-box;
  border-block-end: 1px solid rgba(0, 0, 0, 0.05);
  border-block-end-color: #000;
  color: #000;
}
.toast-body {
  padding: 0.75rem;
}
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  inset-block-start: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.embed-responsive-21by9::before {
  padding-block-start: 42.85714%;
}
.embed-responsive-16by9::before {
  padding-block-start: 56.25%;
}
.embed-responsive-4by3::before {
  padding-block-start: 75%;
}
.embed-responsive-1by1::before {
  padding-block-start: 100%;
}
.overflow-auto {
  overflow: auto !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.fixed-top {
  position: fixed;
  inset-block-start: 0;
  inset-inline-end: 0;
  inset-inline-start: 0;
  z-index: 1030;
}
.fixed-bottom {
  position: fixed;
  inset-inline-end: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  z-index: 1030;
}
@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    inset-block-start: 0;
    z-index: 1020;
  }
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
#inputags .sr-only {
  display: none !important;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}
.toast-header .btn-close {
  font-weight: 300;
  color: #000;
}
.step-equal-width > .steps > ul {
  display: flex;
  display: flex;
}
.step-equal-width > .steps > ul > li {
  flex: 1;
  width: auto;
  float: none;
  flex: 1;
  width: auto;
  float: none;
}
.morris-hover.morris-default-style {
  border-radius: 0;
}
.ps {
  overflow: hidden;
}
.ps > .ps__rail-y {
  width: 5px;
  background-color: rgba(28, 39, 60, 0.04);
  position: absolute;
  inset-inline-start: auto !important;
  inset-inline-end: 0;
  opacity: 0;
  transition: opacity 0.2s;
}
.ps.ps--active-y:hover > .ps__rail-y,
.ps.ps--active-y:focus > .ps__rail-y {
  opacity: 1;
  inset-inline-end: -1px !important;
}
.main-toggle {
  width: 60px;
  height: 25px;
  background-color: #000;
  padding: 2px;
  position: relative;
  overflow: hidden;
}
.main-toggle span {
  position: absolute;
  inset-block-start: 3px;
  inset-block-end: 3px;
  inset-inline-start: 3px;
  display: block;
  width: 20px;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border-radius: 3px;
}
.main-toggle span::before,
.main-toggle span::after {
  position: absolute;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #000;
  inset-block-start: 4px;
  line-height: 1.38;
}
.main-toggle span::before {
  content: "on";
  inset-inline-start: -23px;
}
.main-toggle span::after {
  content: "off";
  inset-inline-end: -27px;
}
.main-toggle.on {
  background-color: blue;
}
.main-toggle.on span {
  inset-inline-start: 37px;
}
.main-toggle-secondary.on {
  background-color: #000;
}
.main-toggle-success.on {
  background-color: #000;
}
.main-toggle-dark.on {
  background-color: #000;
}
.main-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}
.main-list-item + .main-list-item {
  border-block-start: 1px solid;
}
.main-list-item:last-child {
  padding-block-end: 0;
}
.main-list-item h6 {
  margin-block-end: 2px;
  font-weight: 600;
}
.main-list-item > div:first-child h6 {
  color: #000;
}
.main-list-item > div span {
  color: #000;
  font-size: 12px;
}
.main-list-item > div:last-child {
  text-align: right;
}
.main-list-item > div:last-child h6 {
  line-height: 1;
}
.main-tab-pane {
  display: none;
}
.main-tab-pane.active {
  display: block;
}
.main-body,
.main-dashboard {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}
.main-body::before {
  content: "main";
  position: fixed;
  inset-block-start: 45%;
  inset-inline-start: 70%;
  transform: translate3d(-50%, -58%, 0);
  font-size: 1300px;
  font-weight: 600;
  letter-spacing: -10px;
  line-height: 0.5;
  opacity: 0.02;
  z-index: -1;
  display: none;
}
/* ----- 6.8 Sidebar ----- */
.main-toggle-group-demo {
  display: flex;
}
.main-toggle-group-demo .main-toggle + .main-toggle {
  margin-inline-start: 10px;
}
.morris-wrapper-demo {
  height: 200px;
  position: relative;
  margin-inline-start: -15px;
  margin-inline-end: -15px;
}
.morris-donut-wrapper-demo {
  height: 200px;
}
.chartjs-wrapper-demo {
  height: 250px;
}
.chartist-wrapper-demo {
  height: 200px;
}
.main-syntax-wrapper {
  position: relative;
}
.main-syntax-wrapper .badge {
  padding: 4px 5px;
  position: absolute;
  inset-block-start: 5px;
  inset-inline-end: 5px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 9px;
  letter-spacing: normal;
}
.main-syntax-wrapper pre {
  border-color: #000;
  background-color: #000;
  padding: 15px;
  font-size: 13px;
}
.main-syntax-wrapper code[class*="language-"],
.main-syntax-wrapper pre[class*="language-"] {
  font-family: "Roboto Mono", monospace;
}
.demo-static-toast .toast {
  opacity: 1;
}
.main-msg-wrapper {
  position: relative;
  padding: 10px 15px;
  font-size: 13px;
  margin-block-end: 5px;
  display: inline-block;
  border-radius: 3px;
}
.main-msg-wrapper:before,
.main-msg-wrapper:after {
  border-color: transparent #000 transparent transparent;
  border-image: none;
  border-style: solid;
  border-width: medium;
  content: " ";
  height: 0;
  pointer-events: none;
  position: absolute;
  inset-inline-end: 100%;
  inset-block-start: 2%;
  width: 0;
  border-width: 8px;
}
.main-msg-send {
  font-size: 20px;
  color: blue;
}
/* ----- 7.5 Invoice ----- */
.main-traffic-detail-item > div:first-child {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  margin-block-end: 7px;
}
.main-traffic-detail-item > div:first-child > span:first-child {
  color: #000;
}
.main-traffic-detail-item > div:first-child > span:last-child {
  font-size: 11px;
  font-weight: 700;
  color: #000;
}
.main-traffic-detail-item > div:first-child > span:last-child span {
  color: #000;
  font-weight: 400;
}
.main-traffic-detail-item + .main-traffic-detail-item {
  margin-block-start: 18px;
}
/* ----- 7.11 Error ----- */
.main-error-wrapper {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  text-align: center;
}
.main-error-wrapper h1 {
  line-height: 1;
  font-size: 8rem;
  font-weight: 700;
  color: #000;
}
.main-error-wrapper h2 {
  font-weight: 500;
  color: #000;
  letter-spacing: -0.5px;
  margin-block-end: 15px;
}
.main-error-wrapper h6 {
  margin-block-end: 40px;
  font-size: 14px;
}
.main-error-wrapper .btn,
.main-error-wrapper .sp-container button {
  border-width: 2px;
  font-weight: 400;
}
.sp-container .main-error-wrapper button {
  border-width: 2px;
  font-weight: 400;
}
.main-star-group {
  display: flex;
  align-items: center;
}
.main-star-group span:last-child {
  display: block;
  font-weight: 500;
  font-size: 11px;
  margin-inline-start: 5px;
  color: #000;
}
.main-star-item {
  color: blue;
  font-size: 14px;
  position: relative;
}
.main-star-item + .main-star-item {
  margin-inline-start: 3px;
}
.overflow-y-auto {
  overflow-y: auto;
}
.img-fit-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.main-img-user {
  display: block;
  position: relative;
  /* width: 36px; */
  height: 30px;
  border-radius: 100%;
  font-size: 25px;
  color: #fff;
}
.main-img-user img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
  padding: 0.2rem;
  /* background-color: #f18d34; */
  margin-top: 5px;
}
.main-img-user .error-bg {
  /* background-image: url(../img/pngs/15.png); */
  background-position: center;
  background-size: cover;
}
.main-signin-wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
}
.main-profile-overview .main-img-user {
  width: 100px;
  height: 100px;
  margin-block-end: 20px;
  margin: 0 auto;
}
.main-profile-work-list .media-body {
  margin-inline-start: 20px;
}
.main-profile-contact-list .media {
  align-items: center;
}
.main-profile-contact-list .media-body {
  margin-inline-start: 25px;
}
.main-profile-work-list .media-body p {
  margin-block-end: 0;
  font-size: 12px;
  color: #99a6b7;
}
.main-profile-work-list .media + .media {
  margin-block-start: 25px;
}
.b-img {
  height: 197px !important;
}
@media print {
  * {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
    border: 1px solid;
    page-break-inside: avoid;
  }
  blockquote {
    border: 1px solid;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body,
  .container {
    min-width: 992px !important;
  }
}
@media (prefers-reduced-motion: reduce) {
  .fade,
  .collapsing,
  .ps > .ps__rail-y {
    transition: none;
  }
  .main-accordion .accordion-item,
  .main-accordion .accordion-title {
    transition: none;
  }
  .main-toggle span {
    transition: none;
  }
}
@media (max-width: 330px) {
  .chartjs-wrapper-demo {
    width: 290px;
  }
}
@media (min-width: 320px) {
  #ui_notifIt.notifit-ui {
    width: 310px !important;
    margin-inline-start: 44px !important;
  }
  #ui_notifIt.default {
    width: 310px !important;
    margin-inline-start: 18px !important;
  }
  #ui_notifIt.success {
    width: 310px !important;
    margin-inline-start: 18px !important;
  }
  .tags-attachment-lg {
    padding-block-start: 1px;
    padding-inline-end: 2px;
    padding-block-end: 1px;
    padding-inline-start: 2px;
  }
}
@media (max-width: 380px) {
  .iti.iti--allow-dropdownP,
  .iti--allow-dropdown input[type="tel"] {
    padding-inline-end: 0px !important;
    padding-inline-start: 52px !important;
  }
  .input-group {
    flex-wrap: wrap;
  }
  .main-nav-line-chat .nav-link {
    font-size: 12px;
  }
  .main-mail-header > div:last-child {
    display: none;
  }
  .responsive-navbar.navbar .navbar-collapse {
    padding-block-start: 5px !important;
    padding-inline-end: 0px !important;
    padding-block-end: 5px !important;
    padding-inline-start: 0px !important;
  }
  [class*="btn-outline-"] {
    padding: 0.3rem;
  }
  #thumbcarousel .carousel-control-next {
    inset-inline-end: -15px;
  }
  #thumbcarousel .carousel-control-prev {
    inset-inline-start: -15px;
  }
  .carousel-control-next,
  .carousel-control-prev {
    inset-block-start: 65px;
    width: 7%;
    height: 20%;
  }
  .ui-datepicker {
    width: 16rem !important;
    inset-inline-start: 23px !important;
  }
}
@media (max-width: 575.98px) {
  .fc-col-header thead th {
    padding: 10px 0px !important;
  }
  .main-logo .header-brand-img.icon-logo {
    display: block;
  }
  .flot-chart1 {
    height: 200px !important;
  }
  .main-contact-info-header .media-body .nav-link {
    font-size: 12px;
  }
  .modal.pos-static .modal-footer {
    display: block;
  }
  .modal.pos-static .modal-footer .btn {
    width: 100%;
  }
  .modal.pos-static .modal-footer > :not(:first-child) {
    margin-inline-start: 0;
    margin-block-start: 10px;
  }
  #checkoutsteps > .actions a {
    padding: 7px 20px;
  }
  .checkout-steps.wrapper {
    padding: 0px;
  }
  .main-signin-wrapper .custom-layout {
    inset-block-start: 11px;
    inset-inline-end: 20px;
  }
  .main-signin-wrapper .nav-link.icon {
    padding: 5px;
  }
}
@media (min-width: 576px) {
  .files {
    height: 148px;
  }
  .morris-wrapper-demo {
    height: 300px;
  }
  .morris-donut-wrapper-demo {
    height: 250px;
  }
  .demo-avatar-group .main-img-user + .main-img-user,
  .demo-avatar-group .main-img-user + .main-avatar {
    margin-inline-start: 10px;
  }
  .demo-avatar-group .main-avatar + .main-img-user,
  .demo-avatar-group .main-avatar + .main-avatar {
    margin-inline-start: 10px;
  }
  .main-column-signup-left {
    padding: 40px;
  }
  .main-column-signup {
    padding: 40px;
    width: 500px;
  }
}
.nav.nav-item.header-icons.navbar-nav-right {
  align-items: center;
}
@media (max-width: 767px) {
  .ui-datepicker {
    width: 18rem !important;
    inset-inline-start: 23px !important;
  }
  .main-content-body-contacts {
    border-block-start: 1px solid;
  }
  .card-option {
    display: inline-block !important;
  }
  .card-option-title {
    margin-block-end: 10px;
  }
  .header-search.dropdown {
    position: initial !important;
  }
  .header-search.show .dropdown-menu::before {
    display: none;
  }
  .timeline .btn {
    font-size: 12px;
  }
  .vtimeline:before {
    inset-inline-start: 6px !important;
  }
  .vtimeline .timeline-wrapper .timeline-badge {
    inset-inline-start: 0 !important;
  }
  .vtimeline .timeline-wrapper {
    padding-inline-end: 0 !important;
  }
  .vtimeline .timeline-wrapper .timeline-panel {
    width: auto !important;
    margin-inline-start: 10% !important;
  }
  .vtimeline .timeline-wrapper .timeline-panel:after {
    border-right: 14px solid var(--light) !important;
    border-left: 0 solid transparent !important;
    left: -14px !important;
    right: auto !important;
  }
  .vtimeline .timeline-wrapper.timeline-inverted {
    padding-inline-start: 0 !important;
  }
  .vtimeline .timeline-wrapper.timeline-inverted .timeline-panel {
    margin-inline-end: 0 !important;
    width: auto !important;
  }
}
@media (min-width: 768px) {
  .chartist-wrapper-demo {
    height: 300px;
  }
}
@media (min-width: 992px) {
  .main-body-sidebar {
    display: flex;
    flex-direction: row;
  }
  .main-body-sidebar .main-header .container-fluid {
    padding-inline-start: 20px;
    padding-inline-end: 20px;
  }
  .chartjs-wrapper-demo {
    height: 300px;
  }
  .main-signup-wrapper {
    justify-content: flex-end;
  }
  .main-column-signup-left {
    display: flex;
  }
  .main-column-signup {
    border-inline-start: 1px solid;
  }
}
@media (max-width: 991px) {
  .jumps-prevent {
    padding-block-start: 0 !important;
  }
  .page-header {
    display: block;
  }
  .page-header .breadcrumb {
    margin-block-end: 1rem;
  }
  #mailContent .main-mail-list {
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  #mailContent .main-mail-item {
    -webkit-overflow-scrolling: touch;
  }
  .main-chat-footer {
    position: inherit;
  }
  .main-header-notification .dropdown-menu {
    inset-block-start: 46px;
  }
  .main-header-notification > a::after {
    inset-block-start: 40px;
  }
  .main-profile-menu .dropdown-menu {
    inset-block-start: 51px;
  }
  .main-profile-menu > .main-img-user::before {
    inset-block-end: -20px;
  }
  .main-navbar .nav {
    padding: 0;
    margin-block-end: 0;
  }
  .main-navbar .nav-link {
    height: auto;
  }
  .main-navbar .nav-sub {
    border-block-start: 0;
  }
  .main-navbar .nav-item .nav-sub li:first-child {
    margin-block-start: 10px;
  }
  .main-navbar .nav-item .nav-sub li.nav-sub-item:first-child {
    margin-block-start: 0px;
  }
  .main-navbar .nav-sub-mega .nav li:not(.nav-sub-item):first-of-type {
    margin-block-start: 10px !important;
  }
  .main-navbar .nav-sub-mega .nav li:not(.nav-sub-item) {
    margin-inline-start: 0;
  }
  .main-header {
    position: fixed;
    box-shadow: none;
    border-block-end: 1px solid;
    border-block-start: 0;
  }
  .main-sidebar-hide .sidemenu-logo {
    padding: 5px;
  }
  .main-content.side-content .page-header {
    padding-block-start: 63px !important;
  }
  .main-content .page-header {
    padding-block-start: 63px !important;
  }
  .horizontal-content.side-content .page-header {
    padding-block-start: 63px !important;
  }
  .horizontal-content .page-header {
    padding-block-start: 63px !important;
  }
  .main-content .page-header .btn.btn-list {
    padding-inline-start: 0;
    text-align: left;
  }
  #example-input.dataTable > tbody > tr.child ul.dtr-details > li {
    display: flex !important;
  }
}
@media (min-width: 992px) {
  .main-header.side-header {
    position: sticky;
    inset-block-start: 0;
    width: 100%;
  }
  .main-content {
    padding-block-start: 58px;
  }

  .main-content-app,
  .main-content-calendar,
  .main-content-contacts {
    display: flex;
  }
  .main-sidebar-hide .main-content.side-content {
    margin-inline-start: 0;
  }
  .main-error-wrapper h1 {
    font-size: 12rem;
  }
}
@media (max-width: 1024px) and (min-width: 768px) {
  .main-header-right {
    margin-inline-end: 15px;
  }
}
@media (min-width: 1200px) {
  *::-ms-backdrop,
  .main-navbar .nav-sub-mega .nav {
    min-width: 0 !important;
  }
}
@media (max-width: 1200px) {
  .carousel-slider #thumbcarousel .carousel-item .thumb {
    margin: 5px !important;
  }
}
.main-icon-group {
  text-align: center;
  padding: 20px;
  color: #3b4863;
}
.main-icon-list {
  padding: 10px;
  border: 1px solid;
}
*::-ms-backdrop,
html.fullscreenie {
  width: 100%;
}
*::-ms-backdrop,
html.fullscreenie .app-content {
  overflow: scroll;
  overflow-x: hidden;
  height: 100vh;
}
*::-ms-backdrop,
html.fullscreenie .main-content {
  overflow: scroll;
  overflow-x: hidden;
  height: 100vh;
}
*::-ms-backdrop,
.btn,
.sp-container button {
  padding: 0.375rem 0.75rem;
}
*::-ms-backdrop,
.breadcrumb-3 li {
  display: -ms-inline-flexbox;
}
*::-ms-backdrop,
.breadcrumb-4 li {
  display: -ms-inline-flexbox;
}
*::-ms-backdrop,
body.horizontalmenu {
  flex-direction: column;
}
*::-ms-backdrop,
.main-content {
  min-height: 85vh;
}
@media (min-width: 992px) {
  *::-ms-backdrop,
  .main-header.side-header {
    position: fixed;
  }
}
.placeholder {
  background-color: transparent;
  opacity: 1;
}
x hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.13;
}
.daterangepicker.dropdown-menu {
  inset-inline-start: auto !important;
  z-index: 3 !important;
}
.list-group-item + .list-group-item {
  border-block-start-width: 1px;
}
.blockquote-footer {
  margin-block-start: 0px;
}
.form-select {
  color: #99a6b7;
  background-size: 12px 12px !important;
}
table.table-bordered.dataTable thead tr:first-child th,
table.table-bordered.dataTable thead tr:first-child td {
  border-block-start-width: 0px;
}
#example1,
#example2 {
  border-inline-start: 0;
}
table.dataTable {
  width: 100% !important;
  border-radius: 3px;
}
.dataTables_length .select2-container {
  width: 55px !important;
  border-radius: 3px;
}
.dataTables_length .select2-container--default .select2-selection--single {
  border-radius: 3px;
}
.main-icon-list {
  border-radius: 3px;
}
div.dt-button-collection {
  position: initial !important;
}
.select2-container--default .select2-selection--single {
  border-radius: 3px;
}
.dropify-wrapper .dropify-message p {
  font-size: 20px;
}
.modal-content {
  border-radius: 3px;
}
.card-header {
  border-radius: 3px !important;
}
.breadcrumb {
  border-radius: 3px;
}
.lSPrev {
  border-start-start-radius: 3px !important;
}
.lSNext {
  border-start-end-radius: 3px !important;
}
.select2-container--default .select2-selection--multiple {
  border-radius: 3px !important;
}
.dropify-wrapper {
  border-radius: 3px !important;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  border-radius: 3px !important;
}
.dropify-wrapper .dropify-clear {
  border-radius: 3px !important;
}
.ql-snow.ql-toolbar {
  border-start-start-radius: 3px !important;
  border-start-end-radius: 3px !important;
  border-end-end-radius: 0px !important;
  border-end-start-radius: 0px !important;
}
.ql-snow.ql-container {
  border-start-start-radius: 0px !important;
  border-start-end-radius: 0px !important;
  border-end-end-radius: 3px !important;
  border-end-start-radius: 3px !important;
}
.navbar-toggler {
  padding-block-start: 0.4rem;
  padding-inline-end: 0;
  padding-block-end: 0.45rem;
  padding-inline-start: 0.75rem;
  font-size: 1.09375rem;
  color: #272746;
  position: relative;
  line-height: 35px;
  outline: none;
  height: 37px;
  width: 37px;
  text-align: center;
  border: 0px solid #dfd7e9;
}
.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none;
}
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 2em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
  color: #000;
}
.navbar-toggler:focus {
  box-shadow: none;
}
@media (max-width: 991px) {
  .responsive-navbar.navbar .navbar-collapse {
    width: 100%;
    background: #fff;
    margin-block-start: 0;
    z-index: 999;
    inset-block-start: 64px;
    border-block-end: 1px solid;
    border-block-start: 1px solid;
    position: fixed;
    inset-inline-start: 0;
    inset-inline-end: 0;
  }
  .responsive-navbar.navbar .new.nav-link {
    margin: 10px 6px !important;
  }
  .main-header-right .nav-link.icon {
    margin-block-start: 5px;
  }
  .main-header-right .dropdown {
    position: inherit;
  }
  .main-header-right .dropdown .dropdown-menu {
    width: 95%;
    inset-inline-start: 10px;
    inset-inline-end: 5px;
    inset-block-start: 48px;
  }
  .main-header-right .dropdown .dropdown-menu::before {
    display: none;
  }
  .sidemenu-logo {
    display: none;
  }
  .main-sidebar-body {
    margin-block-start: 0 !important;
  }
  .main-sidebar {
    inset-block-start: 65px !important;
  }
  .main-sidebar-hide .side-header {
    inset-inline-start: 0px;
    inset-inline-end: 0px;
  }
  .side-header {
    position: fixed !important;
    visibility: visible;
  }
  .responsive-logo .mobile-logo {
    display: block;
  }
  .responsive-logo .mobile-logo-light {
    display: none;
  }
}
@media (min-width: 992px) {
  .responsive-logo {
    display: none;
  }
  .navbar-toggler.navresponsive-toggler {
    display: none;
  }
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg.responsive-navbar .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
    background: transparent;
  }
  .main-sidebar {
    position: relative;
    display: flex;
    transform: none;
    z-index: 9999;
    border-inline-end-width: 1px;
    visibility: visible;
  }

  .side-header {
    position: fixed !important;
    inset-inline-end: 0;
    padding-inline-start: 240px;
  }
  .main-sidebar-hide .side-header {
    padding-inline-start: 100px;
  }
  .main-footer {
    padding-block-start: 20px;
    padding-inline-end: 20px;
    padding-block-end: 20px;
    padding-inline-start: 270px;
    font-size: 14px;
    font-weight: 400;
    background-color: #fff;
    margin-inline-start: 0px !important;
  }
  .main-sidebar-hide .main-footer {
    padding: 20px 20px 20px 80px;
    padding-block-start: 20px;
    padding-inline-end: 20px;
    padding-block-end: 20px;
    padding-inline-start: 80px;
  }
}
.main-header-right .nav-link.icon {
  margin-block-start: 5px;
}
@media (max-width: 991px) {
  .main-sidebar-hide .sidemenu-logo {
    padding: 12px;
  }
}
/* file manager */
.file-image-1.file-image-md {
  width: 150px;
  height: 150px;
}
.file-image-1.file-image-lg {
  width: 200px;
  height: 200px;
}
.file-image .icons {
  padding: 0;
  margin: 0;
  list-style: none;
  opacity: 0;
  inset-inline-end: 27px;
  inset-block-start: 10px;
  text-align: center;
  position: absolute;
  inset-block-end: 0px;
  z-index: 1;
  transition: all 0.5s ease 0s;
  text-align: right;
}
.file-image:hover .icons {
  opacity: 1;
  inset-block-end: 33px;
}
.file-image-1 {
  width: 110px;
  height: 100px;
  display: inline-table;
  margin: 4px;
  position: relative;
  border: 1px solid #e9edf4;
  margin-block-end: 20px;
  border-radius: 3px;
}
.file-image-1 .icons {
  padding: 0;
  margin: 0;
  list-style: none;
  opacity: 0;
  inset-inline-end: 7px;
  inset-block-start: 6px;
  position: absolute;
  inset-block-end: 0;
  z-index: 1;
  transition: all 0.5s ease 0s;
  text-align: right;
}
.file-image-1:hover .icons {
  opacity: 1;
  inset-block-end: 33px;
}
.file-image-1 .icons li a {
  font-size: 12px;
  line-height: 28px;
  text-align: center;
  height: 25px;
  width: 25px;
  margin: 7px -11px;
  padding: 0;
  display: block;
  position: relative;
  transition: all 0.3s ease-in-out;
  color: #fff;
  border-radius: 3px;
}
.file-image .icons li a {
  font-size: 15px;
  line-height: 35px;
  text-align: center;
  height: 35px;
  width: 35px;
  margin: 6px 6px;
  padding: 0;
  display: block;
  position: relative;
  transition: all 0.3s ease-in-out;
  border-radius: 3px;
}
.file-image-1 .file-name-1 {
  position: absolute;
  inset-inline-end: 0;
  inset-inline-start: 0;
  inset-block-end: -28px;
  color: #524d4d;
  font-size: 12px;
  text-align: center;
  font-weight: 500;
}
.tag:not(:last-child) {
  margin-right: 0.5rem !important;
}
.tags > .tag {
  margin-block-end: 0.5rem;
}
.tag-outline {
  border: 1px solid #e8e8f7;
  color: #1d212f;
  background-color: transparent;
}
.file-radius-attachments {
  padding: 4px 12px;
  border-radius: 50px;
  font-size: 15px;
}
.file-square-attachments {
  padding: 4px 12px;
  border-radius: 3px;
  font-size: 15px;
}
.file-transparent-rounded {
  border-radius: 50px !important;
}
.file-name {
  position: absolute;
  inset-block-end: 20px;
  inset-inline-start: 0;
  inset-inline-end: 0;
  color: #fff;
  font-size: 16px;
  text-align: center;
  font-weight: 500;
}
.list-group-transparent .list-group-item {
  background: none;
}
.file-sm {
  font-size: 12px !important;
  padding: 0px 11px;
}
.file-md {
  font-size: 14px !important;
  padding: 3px 13px;
}
.file-lg {
  font-size: 16px !important;
  padding: 6px 15px;
}
.dropdown-menu.show {
  display: block;
  margin: 0;
  border: 1px solid var(--white);
  box-shadow: 0 3px 9px 0 #000;
  border-radius: 0px 0px 5px 5px;
  z-index: 9;
}
.dropdown-menu > li > a {
  display: block;
  padding: 6px 12px !important;
  clear: both;
  font-weight: 400;
  line-height: 1.428571429;
  color: #76839a;
  white-space: nowrap;
}
.open-file {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  inset-inline-end: 0;
  inset-inline-start: 0;
  inset-block-start: 0;
}
.dropdown-menu > li > a:hover {
  text-decoration: none;
  color: blue !important;
  background-color: none;
}
.file-dropdown {
  z-index: 2;
}
.file-manager-image {
  background: #e5bc4a 40;
  border-radius: 3px;
  margin-block-end: 25px;
  padding: 0px;
  width: 60px;
  margin-block-start: 0px;
}
.file-image-lg .icons li a {
  font-size: 15px;
  line-height: 35px;
  height: 35px;
  width: 35px;
}
.file-image-md .icons li a {
  font-size: 13px;
  line-height: 30px;
  height: 28px;
  width: 28px;
}
.d-flex .list-group-item {
  border: none !important;
}
.file-list .list-group-item {
  border: none !important;
}
.lg-actions .lg-prev {
  border: 1px solid #a5a1dd;
}
.file-opt span {
  width: 30px;
  height: 30px;
  box-shadow: 0 0 0 2px #fff;
  margin-inline-end: -2px !important;
  border-radius: 5px;
}
/* file manager */
/* file attachments */
/* search page */
.masonry .brick {
  width: auto;
  margin-block-end: 20px;
}
.smartphoto {
  z-index: 9999 !important;
  background-color: rgba(0, 0, 0, 0.8) !important;
}
.smartphoto-arrows li {
  padding: 8px;
  border-radius: 5px;
  background: blue;
  border: 1px solid #a5a1dd;
}
.smartphoto-dismiss {
  width: 15px !important;
  height: 15px !important;
  inset-inline-end: 22px !important;
  inset-block-start: 18px !important;
}
.smartphoto-arrow-right {
  inset-inline-end: 15px !important;
}
.smartphoto-arrow-left {
  inset-inline-start: 15px !important;
}
.smartphoto-arrows a {
  width: 50% !important;
  height: 50% !important;
  margin: 7px;
}
/* search page */
/*footers */
.footer {
  background: transparent;
  font-size: 0.875rem;
  padding: 1.25rem 1.25rem;
  border-block-start: 1px solid rgba(0, 0, 0, 0.1);
}
.footer .social ul li a {
  width: 29px;
  height: 29px;
  display: block;
  border: 1px solid blue;
  line-height: 25px;
  border-radius: 3px;
  color: blue;
}
.social .social-icon i {
  font-size: 14px;
  line-height: 2;
}
.footer .social ul li {
  float: left;
  padding: 7px;
}
.privacy .btn-link {
  text-decoration: underline;
}
.privacy .btn-link:hover {
  color: blue;
}
.top-footer a {
  color: #99a6b7;
  line-height: 2;
}
.top-footer a:hover {
  color: blue;
}
.top-footer p {
  color: #99a6b7;
}
.top-footer p:hover {
  color: blue;
}
.footer-social-list li:not(:last-child) {
  margin-inline-end: 1.5rem;
}
.footer-social-list li {
  display: inline-block;
}
.footer-social-list {
  padding-inline-start: 0;
  list-style: none;
  margin: 0;
  line-height: 1.25rem;
}
.top-footer {
  width: 100%;
  line-height: 1.25rem;
  opacity: 1;
  transform: translate(0px, 0px);
  font-size: 14px;
  flex: 0 0 auto;
}
.top-footer img {
  width: 100px;
  border: 1px solid;
  padding: 2px;
  height: 80px;
  border-radius: 3px;
  margin: 2px 0px;
}
.top-footer img:hover {
  color: #838585;
  opacity: 0.6;
}
.footer-payments {
  padding-inline-start: 0;
  list-style: none;
  margin: 0;
  line-height: 1.25rem;
}
.footer-payments a {
  color: #9c9db9;
  text-decoration: none;
  font-size: 2rem;
}
.footer-payments li:not(:last-child) {
  margin-inline-end: 1.5rem;
}
.footer-payments li {
  display: inline-block;
}
@media (max-width: 991px) {
  .footer-social-list {
    text-align: center;
    margin-block-start: 1rem;
  }
}
@media (max-width: 767px) {
  .footer .privacy {
    text-align: center !important;
  }
}
/*footers */
/*crypto-currencies */
.w-4 {
  width: 1rem !important;
}
.h-4 {
  height: 1rem !important;
}
/*crypto-currencies */
.blog-head {
  position: relative;
}
.blog-head .badge {
  position: absolute;
  inset-inline-start: 10px;
  inset-block-start: 10px;
}
.handle-counter {
  overflow: hidden;
  display: flex;
}
.handle-counter input {
  float: left;
  text-align: center;
  height: 34px;
  border-radius: 0;
  width: 50px;
  background: none;
  color: #000;
  outline: none;
  border: none;
}
.img-sm {
  width: 64px;
  min-width: 64px;
  height: 64px;
  border-radius: 3px;
  margin-inline-end: 20px;
  border: 1px solid;
  background: #000;
  padding: 10px;
}
.table-shopping-cart .media {
  display: flex;
  align-items: flex-start;
  padding: 0.75rem;
}
.counter-minus.btn {
  width: 35px;
  height: 30px;
  border-radius: 50px;
  border: 1px solid;
  min-height: 33px;
  min-width: 33px;
  line-height: 3px;
}
.counter-plus.btn {
  width: 35px;
  height: 30px;
  border-radius: 50px;
  border: 1px solid;
  min-height: 33px;
  min-width: 33px;
  line-height: 3px;
}
.counter-plus.btn i {
  margin: -8px;
}
.counter-minus.btn i {
  margin: -8px;
}
#checkoutsteps > .steps {
  position: relative;
  display: block;
  width: 100%;
  border-radius: 3px;
  padding: 10px;
}
#checkoutsteps .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-block-end: 30px;
}
#checkoutsteps .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-block-end: 30px;
}
#checkoutsteps .item .left {
  display: flex;
  align-items: center;
}
#checkoutsteps .item .thumb {
  display: inline-flex;
  width: 100px;
  height: 90px;
  justify-content: center;
  align-items: center;
}
#checkoutsteps .total {
  float: var(--float-end);
}
#checkoutsteps .item .purchase {
  display: inline-block;
  margin-inline-start: 21px;
  text-decoration: none;
}
#checkoutsteps .checkoutline {
  height: 3px;
  -webkit-transform: translateY(1.7rem);
  transform: translateY(1.7rem);
  margin: 0 8.9%;
  position: relative;
  z-index: 0;
  inset-block-start: 19px;
}
#checkoutsteps ul[role="tablist"] li {
  display: flex;
  z-index: 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-preferred-size: auto;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  flex-basis: auto;
  font-weight: 500;
  font-size: 1.1rem;
}
#checkoutsteps > .steps a {
  display: block;
  width: auto;
  background-color: transparent;
  color: #000;
  font-size: 16px;
  font-weight: 400;
  padding: 14px 0;
  text-decoration: none;
  border-radius: 30px;
  cursor: default;
  text-align: center;
  outline: none;
}
#checkoutsteps > .steps .number {
  margin: 15px auto;
}
.table td {
  vertical-align: middle;
}
.table-bordered thead th {
  padding-block-start: 0.75rem;
  padding-block-end: 0.75rem;
}
.card-item-desc .card-item-desc-1 dt {
  font-weight: 400;
  font-size: 13px;
  display: inline-block;
}
.card-item-desc .card-item-desc-1 dd {
  font-size: 13px;
  color: #737f9e;
  margin-block-end: 0;
}
dd {
  margin-inline-start: 0;
  display: inline-block;
}
@media (max-width: 767px) {
  #checkoutsteps > .steps {
    padding: 0;
  }
  #checkoutsteps .checkoutline {
    height: 3px;
    -webkit-transform: translateY(1.7rem);
    transform: translateY(1.7rem);
    margin: 0 10%;
    position: relative;
    z-index: 0;
    inset-block-start: 18px;
  }
  #checkoutsteps > .steps a {
    font-size: 12px !important;
  }
}
@media (max-width: 567px) {
  .card-pay .tabs-menu li {
    width: 100% !important;
  }
}
/*styles */
.richText {
  background-color: #fff !important;
  border-inline-end: solid 1px;
  border-inline-start: solid 1px;
}
.richText .richText-toolbar {
  border-block-end: solid 1px;
  border-block-start: solid 1px;
}
.richText .richText-toolbar ul li a {
  border-inline-end: solid 1px;
}
@media (max-width: 1460px) {
  .richText .richText-toolbar ul li a {
    border-inline-end: none;
  }
}
[class^="ri-"],
[class*=" ri-"] {
  font-family: "remixicon" !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
}
.feature .feature-service {
  position: relative;
  display: inline-block;
  width: 2.5em;
  height: 2.5em;
  line-height: 2em;
  vertical-align: middle;
  padding-block-start: 5px;
  border-radius: 3px;
  color: #fff;
}
.service .item-box i {
  font-size: 30px;
}
.settings-icon {
  border: 1px solid #fff;
  border-radius: 3px;
  padding: 7px 10px;
  font-size: 20px;
}
.display-5 {
  font-size: calc(1.425rem + 2.1vw);
}
.user-social-detail .social-profile {
  height: 35px;
  width: 35px;
  font-size: 16px;
  line-height: 2.3;
  border-radius: 3px !important;
}
.row.user-social-detail {
  margin: 0 auto;
  justify-content: center;
}
.services-statistics .counter-icon {
  margin-block-end: 1.5rem;
  margin-block-start: 14px;
  display: inline-flex;
  width: 3rem;
  height: 3rem;
  padding: 12px 10px;
  border-radius: 3px;
  text-align: center;
  border: 1px solid;
}
.counter-icon {
  line-height: 4rem;
  font-size: 24px;
}
.card-body span.avatar {
  margin: 0 auto;
}
.light-layout {
  display: none;
}
.btn-sm {
  min-width: 1.62rem;
}
.table > thead > tr > th {
  font-size: 12px;
}
.product-grid6 .icons-wishlist {
  padding: 0;
  margin: 0;
  list-style: none;
  inset-inline-end: 25px;
  inset-block-start: 30px;
  position: absolute;
  z-index: 1;
  transition: all 0.5s ease 0s;
  text-align: right;
  opacity: 1;
  inset-block-end: 33px;
}
.product-grid6 .icons-wishlist li a {
  font-size: 15px;
  line-height: 35px;
  text-align: center;
  height: 35px;
  width: 35px;
  margin: 2px 7px;
  padding: 0;
  border-radius: 50px;
  display: block;
  position: relative;
  transition: all 0.3s ease-in-out;
}
.product-grid6 .price span {
  color: #76839a;
  font-size: 15px;
  font-weight: 400;
  text-decoration: line-through;
  display: inline-block;
}
.product-grid6 .price {
  font-size: 18px;
  font-weight: 600;
}
.card-pay .tabs-menu {
  margin-block-end: 25px;
  border-radius: 3px;
  overflow: hidden;
}
.card-pay .tabs-menu li {
  width: 33.3%;
  display: block;
}
.card-pay .tabs-menu li a.active {
  background: blue;
  color: #fff;
  text-align: center;
  border-radius: 3px;
  border: 1px solid blue;
}
.card-pay .tabs-menu li a {
  padding: 0.7rem 1rem;
  display: block;
  border: 1px solid;
}
.card-pay .tabs-menu li a {
  padding: 0.7rem 1rem;
  display: block;
  text-align: center;
  background: #000;
  color: blue;
  font-size: 11px;
}
.payment-icon.active svg {
  fill: #fff;
  margin-inline-end: 5px;
}
.payment-icon svg {
  margin-inline-end: 5px;
}
.rating-stars i {
  padding: 5px;
}
.main-profile-contact-list .media-body div {
  font-weight: 500;
  color: #000;
}
.main-profile-work-list .media-body span {
  margin-block-end: 4px;
}
.main-profile-work-list .media-body h6 {
  margin-block-end: 2px;
}
.main-profile-work-list .media-body span {
  display: block;
  margin-block-end: 5px;
}
.lg-outer .lg-image {
  border-radius: 3px;
}
.ms-drop {
  z-index: 2 !important;
}
.smartphoto-img {
  border-radius: 3px;
}
@media (max-width: 480px) {
  .page-link {
    padding: 4px;
  }
}
@media (max-width: 480px) {
  .pagination-circled .page-link {
    width: 35px;
    height: 35px;
  }
}
@media (max-width: 480px) {
  .bs-popover-top,
  .bs-popover-auto[data-popper-placement^="top"] {
    margin-block-end: -0.2rem !important;
  }
}
@media (max-width: 320px) {
  .main-toggle.on span {
    inset-inline-start: 30px;
  }
}
@media (max-width: 767px) {
  .main-footer {
    padding: 15px;
  }
}
@media (max-width: 767px) {
  .img-thumbnail {
    margin-block-end: 20px;
  }
}
@media (max-width: 330px) {
  .chartjs-wrapper-demo {
    width: 250px;
  }
}
.remove-button .text-danger {
  padding: 5px;
  background-color: rgba(255, 71, 61, 0.2);
  border-radius: 3px;
}
.remove-button .text-info {
  padding: 5px;
  background-color: rgba(1, 184, 255, 0.2);
  border-radius: 3px;
}
@media (min-width: 992px) {
  .horizontal .main-content.side-content {
    margin-inline-start: 0;
  }
  .horizontal.horizontal-hover .nav-link.with-sub {
    pointer-events: none;
  }
}
@media (max-width: 991px) {
  .horizontal .main-navbar .nav-sub1 {
    margin-inline-start: 16px;
  }
  .horizontal .main-navbar .nav-sub2 {
    margin-inline-start: 15px;
  }
}
.lg-actions .lg-next,
.lg-actions .lg-prev,
.lg-toolbar,
.sweet-alert button {
  background-color: blue !important;
}
.custom-layout {
  position: absolute;
  inset-block-start: 50px;
  inset-inline-end: 50px;
  z-index: 1;
  cursor: pointer;
}
.nav-link.icon {
    /* margin: 5px; */
    padding: 9px 11px;
    text-align: center;
    height: 3.3rem;
    font-size: 1.2rem;
    position: relative;
    color: #fff;
    font-size: 25px;
}


@media (max-width: 576px) {
  .nav-link.icon {
    margin: 0px;
    padding: 9px 9px;
  }
}
.main-signin-wrapper .custom-layout .nav-link.icon i {
  color: blue !important;
  padding: 10px;
  border-radius: 30px;
}
table.table-bordered.dataTable thead tr:first-child th {
  border-block-start-width: 0px !important;
}
.hover-white:hover {
  color: #000;
}
.file-attachments-btns .btn i {
  font-size: 1rem;
  line-height: 1.5;
}
.ql-snow.ql-toolbar button i {
  font-size: 15px !important;
}
.ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button {
  color: #fff !important;
}
.error-page.error-bg {
  min-height: 80vh;
}
.input-group .btn {
  border-radius: 0px 3px 3px 0px;
}
.angle,
.angle1,
.angle2 {
  margin-inline-end: 0px;
  font-size: 12px;
}
/* --- custom styles ---*/
.theme-container .active {
  border: 1px solid blue;
  padding: 5px 10px;
  background: #000;
  border-radius: 5px;
  color: blue;
}
.theme-container1 .active {
  border: 1px solid;
  padding: 5px 10px;
  background: #000;
  border-radius: 5px;
  color: #000;
}
.theme-container2 .active {
  border: 1px solid #05c3fb;
  padding: 5px 10px;
  background: #000;
  border-radius: 5px;
  color: #05c3fb;
}
@media (max-width: 355px) {
  .ui-datepicker {
    width: 15.6rem !important;
    inset-inline-start: 23px !important;
  }
  .ui-datepicker .ui-datepicker-calendar th {
    padding: 6px 6px;
  }
}
@media (max-width: 330px) {
  .main-toggle.on span {
    inset-inline-start: 30px;
  }
}
.modal-backdrop {
  flex: 1 1 auto;
}
.select2-dropdown {
  direction: ltr;
}
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"],
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"] {
  background-repeat: no-repeat;
}
.side-menu .side-menu__item:hover .side-menu__label {
  margin-inline-start: 5px;
  transition: margin-left 0.45s ease;
}
.side-menu__label {
  transition: margin-left 0.45s ease;
}
.latest-timeline .timeline {
  list-style-type: none;
  position: relative;
  padding: 0;
}
.latest-timeline .timeline .event-text {
  margin: 20px 0;
  padding-inline-start: 2rem;
}
.latest-timeline .timeline .event-text:before {
  content: " ";
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid var(--primary-bg-color);
  inset-inline-start: 1px;
  width: 15px;
  height: 15px;
  z-index: 400;
}
.latest-timeline .timeline .event-text:first-child:before {
  border: 3px solid blue;
}
.latest-timeline .timeline .event-text:nth-child(2):before {
  border: 3px solid;
}
.latest-timeline .timeline .event-text:nth-child(3):before {
  border: 3px solid blue;
}
.latest-timeline .timeline .event-text:nth-child(4):before {
  border: 3px solid;
}
.latest-timeline .timeline .event-text .last-child:before {
  border: 3px solid red;
}
.activity {
  position: relative;
  border-inline-start: 1px solid;
  margin-inline: 16px;
}
.activity .img-activity {
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 34px;
  border-radius: 50%;
  position: absolute;
  inset-inline-start: -20px;
}
.activity .item-activity {
  margin-inline-start: 32px;
  margin-block-end: 10px;
}
.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--background);
  color: var(--color);
}
.offcanvas {
  background-color: #000;
}
.offcanvas.show {
  visibility: visible;
}
.accordion-item {
  color: #000;
  border: 1px solid #000;
}
.accordion-button {
  color: #000;
}
.card-body .switch_section span {
  color: #fff !important;
  font-size: 15px;
  font-weight: 500;
}
.email-attch a {
  display: inline-block;
}
@media (max-width: 576px) {
  .wizard.vertical > .steps .current a .title {
    display: none !important;
  }
  .wizard > .steps > ul li .title {
    display: none !important;
  }
}
.select2-sm
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 13px;
}
.notification {
  list-style-type: none;
  padding: 0;
  position: relative;
}
.notification:before {
  content: "";
  position: absolute;
  top: 40px;
  bottom: 5px;
  width: 4px;
  background-color: blue-02;
  left: 20%;
  margin-left: -2.5px;
}
.notification > li {
  position: relative;
  min-height: 50px;
  padding: 15px 0;
}
.notification .notification-time {
  position: absolute;
  left: 0;
  width: 18%;
  text-align: right;
  top: 30px;
}
.notification .notification-time .date,
.notification .notification-time .time {
  display: block;
  font-weight: 500;
}
.notification .notification-time .date {
  line-height: 16px;
  font-size: 11px;
  margin-bottom: 4px;
  color: #7987a1;
}
.notification .notification-time .time {
  line-height: 24px;
  font-size: 18px;
  color: #7987a1;
}
.notification .notification-icon {
  left: 15%;
  position: absolute;
  width: 10%;
  text-align: center;
  top: 51px;
}
.notification .notification-icon a {
  text-decoration: none;
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 50%;
  background: #fff;
  line-height: 10px;
  color: #fff;
  font-size: 14px;
  border: 3px solid blue;
  transition: border-color 0.2s linear;
}
.notification .notification-body {
  box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.05);
  margin-left: 24%;
  margin-right: 18%;
  background: #fff;
  position: relative;
  padding: 14px 20px;
  border-radius: 6px;
}
.notification .notification-body:before {
  content: "";
  display: block;
  position: absolute;
  border: 10px solid transparent;
  border-right-color: #000;
  left: -20px;
  top: 32px;
}
.notification .notification-body > div + div {
  margin-top: 15px;
}
.notification-badge {
  background-color: #eff1f5;
  color: #7987a1;
}
@media (max-width: 576px) {
  .notification .notification-body:before {
    display: none;
  }
  .notification .notification-icon a {
    display: none;
  }
  .notification:before {
    display: none;
  }
  .notification-body .media {
    flex-direction: column;
  }
  .notification-body .media .main-img-user {
    margin-bottom: 10px !important;
    margin-inline-start: 70px;
  }
  .notification .notification-time {
    z-index: 99;
    width: 100%;
    right: 5px !important;
    position: absolute;
    top: 20px !important;
  }
  .notification .notification-body {
    margin-left: 0px;
    margin-right: 0px;
    position: relative;
  }
  .notification-badge {
    position: absolute;
    left: 10px;
    top: 8px;
  }
  .notification .notification-time .date,
  .notification .notification-time .time {
    display: inline;
  }
  .notification .notification-time .time {
    line-height: 16px;
    font-size: 11px;
    margin-left: 5px;
    margin-right: 10px;
    color: #b6bfcf;
  }
}
.product-grid6 .card-footer a i {
  display: inline-flex;
}
.daterangepicker.ltr.show-calendar {
  right: 1001px !important;
}
.sa-confirm-button-container .confirm {
  margin: 5px;
}
@media (max-width: 375px) {
  .list-group-horizontal {
    flex-direction: column;
  }
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-right-width: 1px;
}
.user-info .fe {
  display: inline-block;
}
.breadcrumb1 a:hover {
  color: #000;
}
.breadcrumb-item .fe {
  display: inline-block;
}
.breadcrumb-item1 .fe {
  display: inline-block;
}
.tab-content .card {
  border-radius: 15px;
}
.pricing1 .price {
  font-size: 2.55rem;
}
.tab-content .pricing-icon {
  border-radius: 50%;
  height: 60px;
  width: 60px;
  margin-inline: auto;
  align-items: center;
  justify-content: center;
  display: flex;
}
.pricing-tabs .pri-tabs-heading {
  padding: 14px 7px;
  background-color: #000;
  border-radius: 40px;
}
.pricing-tabs .nav-price li a {
  background-color: #000;
  border: none;
}
.pricing-body ul li i {
  display: inline-block;
}
.mapcontainer.mapael svg {
  width: 100%;
}
.mapcontainer1.mapael svg {
  width: 100%;
}
.mapcontainer2.mapael svg {
  width: 100%;
}
.mapcontainer3.mapael svg {
  width: 100%;
}
.mapcontainer4.mapael svg {
  width: 100%;
}
.list-group-item {
  background-color: #000;
}
.form-control::placeholder {
  color: #000;
}
.ribbone-card .fa {
  color: #000;
}
.ribbone-card span {
  color: #000;
}
.ribbone-card .fe {
  color: #000;
}
.table.dataTable > tbody > tr.child ul.dtr-details > li {
  border-block-end-color: #000;
}
.is-countdown ul li {
  border-radius: 8px;
}
.btn-check:active + .btn-outline-secondary,
.btn-check:checked + .btn-outline-secondary,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show,
.btn-outline-secondary:active {
  color: #fff !important;
  background-color: #000;
  border-color: #000;
}
/*--- Custom-styles ---*/

/*====================================== switcher css ==============================*/

.logo a img {
  display: none;
}

.bg_dark {
  background: #0a080e;
  color: #fff;
}

.styleswitch.layoutswitch {
  background: none repeat scroll 0 0 #f4f4f4;
  border: medium none !important;
  color: #fff !important;
  display: inline-block;
  height: auto;
  margin: 3px 0;
  padding: 2px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  text-decoration: none !important;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.styleswitch.layoutswitch:hover {
  background: #000 !important;
}

.styleswitch {
  background-color: #e425e9;
  display: inline-block;
  margin: 4px 8px;
  width: 25px;
  height: 25px;
  border-radius: 5px;
}

.styleswitch:active,
.styleswitch:focus {
  border: 5px solid #000;
}

#switcher-body .container {
  background: #fff;
  margin: 50px auto;
  border: 1px solid #dedede;
  padding: 40px;
}

.switcher {
  width: 50px;
  height: 50px;
  display: inline-block;
  margin: 4px 8px;
  border-radius: 5px;
  border: 0px solid #000;
}

.demo_changer {
  z-index: 99999;
  position: fixed;
  right: -250px;
  font-weight: 400 !important;
  height: 100%;
}

.purchase {
  text-decoration: underline;
  color: #4bacc6;
}

.demoPanel {
  position: relative;
}

.evo-pop {
  z-index: 10000;
  width: 204px;
  padding: 3px 3px 0;
}

.evo-pop-ie {
  z-index: 10000;
  width: 212px;
  padding: 3px;
}

.evo-palette td {
  font-size: 1px;
  border: solid 1px #c0c0c0;
  padding: 7px;
  cursor: pointer;
}

.evo-palette tr.top td {
  border-bottom: 0;
}

.evo-palette tr.in td {
  border-top: 0;
  border-bottom: 0;
}

.evo-palette tr.bottom td {
  border-top: 0;
}

.evo-palette div.sep {
  height: 3px;
}

.evo-palette,
.evo-palette-ie {
  border-collapse: separate;
  border-spacing: 4px 0;
  *border-collapse: expression("separate", cellSpacing= "2px");
}

.evo-palette th,
.evo-palette-ie th {
  border: 0;
  padding: 5px 3px;
  text-align: left;
  font-weight: normal;
  background: transparent !important;
}

.evo-palette-ie td {
  font-size: 1px;
  border: solid 1px #c0c0c0;
  padding: 7px;
  cursor: pointer;
}

.evo-palette2,
.evo-palette2-ie {
  margin: auto;
  border-collapse: collapse;
}

.evo-palette2 td,
.evo-palette2-ie td {
  font-size: 1px;
  cursor: pointer;
}

.evo-palette2 td {
  padding: 6px 7px;
}

.evo-palette2-ie td {
  padding: 5px;
}

.evo-palcenter {
  padding: 5px;
  text-align: center;
}

.evo-colorind,
.evo-colorind-ie,
.evo-colorind-ff {
  border: solid 1px #c3c3c3;
  width: 20px;
  height: 20px;
  float: right;
}

.evo-colorind {
  position: relative;
  top: 2px;
}

.evo-colorind-ie {
  position: relative;
  top: -23px;
}

.evo-colorbox-ie {
  font-size: 8px;
  padding: 3px 9px !important;
}

.evo-colortxt-ie {
  position: relative;
  top: -6px;
}

.evo-pop:after,
.evo-pop-ie:after,
.evo-colorind:after,
.evo-colorind-ie:after,
.evo-colorind-ff:after,
.evo-color span:after,
.evo-cHist:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
  font-size: 0;
}

.evo-color {
  width: 94px;
  padding: 1px 3px 0 4px;
}

.evo-color div {
  border: solid 1px #808080;
  border-right: solid 1px #c0c0c0;
  border-bottom: solid 1px #c0c0c0;
  padding: 3px;
  margin-bottom: 5px;
  width: 10px;
  height: 10px;
  float: left;
}

.evo-color span {
  font-size: 15px;
  margin: 1px 0 4px 3px;
  float: left;
}

.evo-sep {
  height: 10px;
  font-size: 0;
}

.evo-more {
  padding: 4px 5px 4px;
  font-size: smaller;
}

.evo-cHist {
  padding: 3px;
}

.evo-cHist div {
  cursor: pointer;
  border: solid 1px #c0c0c0;
  padding: 3px;
  margin: 5px;
  width: 10px;
  height: 10px;
  float: left;
}

a.evo-hist {
  margin-left: 6px;
}

.evo-pointer {
  cursor: pointer;
}

.evo-pop {
  background: #ffffff;
  border: 1px solid #dedede;
  padding: 10px !important;
  width: 225px !important;
  z-index: 10000;
}

.evo-colorind,
.evo-colorind-ie,
.evo-colorind-ff {
  border: 1px solid #c3c3c3;
  height: 20px;
  position: absolute;
  top: 11px;
  right: 5px;
  width: 20px;
}

.demo_changer p {
  margin: 10px 0;
}

.demo_changer .dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  background: #fff;
}

.demo_changer h4 {
  color: #545454 !important;
  margin: 10px 0px !important;
  text-transform: uppercase;
  border-bottom: 1px solid var(--border);
  border-top: 1px solid var(--border);
  background-color: var(--background);
}

.demo_changer select:active,
.demo_changer select:focus,
.demo_changer select:hover,
.demo_changer .button:active,
.demo_changer .button:focus,
.demo_changer .button:hover,
.demo_changer .btn:active,
.demo_changer .btn:focus,
.demo_changer .btn:hover,
.demo_changer .button:active,
.demo_changer .button:focus,
.demo_changer .button:hover {
  box-shadow: none !important;
}

.demo_changer .button {
  -moz-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid #ddd;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: normal;
  background: #fff;
  line-height: 1.42857;
  margin-bottom: 0;
  width: 145px !important;
  padding: 6px 12px;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
}

.demo_changer .img-thumbnail {
  height: 35px;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-bottom: 5px;
  width: 46% !important;
  padding: 2px;
}

.form_holder img {
  border: 4px solid #e1e1e1;
  height: 40px;
  margin: 2px !important;
  width: 40px !important;
}

.demo_changer hr {
  border-color: #e0e0e0;
}

.demo_changer,
.demo_changer p {
  color: #545454 !important;
}

.demo_changer h4 {
  color: var(--color) !important;
  font-size: 14px;
  padding-bottom: 0;
  text-align: left;
  padding: 10px 18px;
  font-weight: 600;
}

.demo_changer i {
  color: #fff;
  font-size: 21px;
  margin-top: 10px;
}

/*.demo_changer .btn {
	margin-top: 10px;
}*/
.demo_changer .demo-icon {
  cursor: pointer;
  text-align: center;
  padding: 2px;
  float: left;
  height: 40px;
  line-height: 38px;
  width: 40px;
  border-radius: 0px 0 0 0px !important;
  top: 17rem;
  position: relative;
}

@media (max-width: 992px) {
  .demo_changer .form_holder {
    height: 100vh;
  }
}

@media (min-width: 992px) {
  .demo_changer .form_holder {
    height: 100vh;
  }
}

.demo_changer .form_holder {
  background: #fff;
  /* backdrop-filter: blur(50px); */
  border-radius: 0;
  cursor: pointer;
  float: right;
  font-size: 12px;
  padding: 0 10px;
  width: 250px;
  box-shadow: 0 0 0 1px rgba(61, 119, 180, 0.12),
    0 8px 16px 0 rgba(91, 139, 199, 0.24);
}

.demo_changer .form_holder p {
  font-size: 12px;
}

.color_display_2 {
  clear: both;
  color: #ffffff;
  display: inline-block;
  font-size: 12px;
  width: 31px !important;
  height: 31px;
  margin-bottom: 7px;
  padding: 4px 10px;
  text-align: center;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3);
  text-transform: uppercase;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.3) inset,
    0 0 2px rgba(255, 255, 255, 0.3) inset, 0 1px 2px rgba(0, 0, 0, 0.29);
}

.color_display_2 a {
  color: #fff;
  text-decoration: none;
}

.color_display_2 a:focus,
.color_display_2 a:hover {
  color: #ededed !important;
  text-decoration: none;
}

.demoPanel {
  margin: 0;
  padding: 0;
  width: 125px;
}

.predefined_styles {
  padding: 0;
  text-align: center;
  background: var(--white);
}

@media only screen and (min-width: 768px) {
  .demo_changer {
    top: 0;
  }

  .demo_changer .demo-icon {
    height: 40px;
    width: 40px;
  }

  .demo_changer i {
    font-size: 17px;
  }
}

@media only screen and (max-width: 768px) {
  .demo_changer {
    top: 0px;
  }

  .demo_changer i {
    font-size: 21px;
    margin-top: 8px;
  }

  .demo_changer .demo-icon {
    height: 40px;
    width: 40px;
  }

  .demo_changer {
    display: block;
  }
}

.bootstrap-select.show-menu-arrow.open > .btn {
  z-index: 2051;
}

.bootstrap-select .btn:focus {
  outline: thin dotted #333333 !important;
  outline: 5px auto -webkit-focus-ring-color !important;
  outline-offset: -2px;
}

.bootstrap-select.btn-group .btn .filter-option {
  overflow: hidden;
  position: absolute;
  left: 12px;
  right: 25px;
  text-align: left;
}

.bootstrap-select.btn-group .btn .caret {
  position: absolute;
  top: 50%;
  right: 12px;
  margin-top: -2px;
  vertical-align: middle;
}

.bootstrap-select.btn-group > .disabled,
.bootstrap-select.btn-group .dropdown-menu li.disabled > a {
  cursor: not-allowed;
}

.bootstrap-select.btn-group > .disabled:focus {
  outline: none !important;
}

.bootstrap-select.btn-group[class*="span"] .btn {
  width: 100%;
}

.bootstrap-select.btn-group .dropdown-menu {
  min-width: 100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.bootstrap-select.btn-group .dropdown-menu.inner {
  position: static;
  border: 0;
  padding: 0;
  margin: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.bootstrap-select.btn-group .dropdown-menu dt {
  display: block;
  padding: 3px 20px;
  cursor: default;
}

.bootstrap-select.btn-group .div-contain {
  overflow: hidden;
}

.bootstrap-select.btn-group .dropdown-menu li {
  position: relative;
}

.bootstrap-select.btn-group .dropdown-menu li > a.opt {
  position: relative;
  padding-left: 35px;
}

.bootstrap-select.btn-group .dropdown-menu li > a {
  cursor: pointer;
}

.bootstrap-select.btn-group .dropdown-menu li > dt small {
  font-weight: normal;
}

.bootstrap-select.btn-group.show-tick
  .dropdown-menu
  li.selected
  a
  i.check-mark {
  display: inline-block;
  position: absolute;
  right: 15px;
  margin-top: 2.5px;
}

.bootstrap-select.btn-group .dropdown-menu li a i.check-mark {
  display: none;
}

.bootstrap-select.btn-group.show-tick .dropdown-menu li a span.text {
  margin-right: 34px;
}

.bootstrap-select.btn-group .dropdown-menu li small {
  padding-left: 0.5em;
}

.bootstrap-select.btn-group .dropdown-menu li:not(.disabled) > a:hover small,
.bootstrap-select.btn-group .dropdown-menu li:not(.disabled) > a:focus small,
.bootstrap-select.btn-group .dropdown-menu li.active:not(.disabled) > a small {
  color: #64b1d8;
  color: rgba(255, 255, 255, 0.4);
}

.bootstrap-select.btn-group .dropdown-menu li > dt small {
  font-weight: normal;
}

.bootstrap-select.show-menu-arrow .dropdown-toggle:before {
  content: "";
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: -4px;
  left: 9px;
  display: none;
}

.bootstrap-select.show-menu-arrow .dropdown-toggle:after {
  content: "";
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid white;
  position: absolute;
  bottom: -4px;
  left: 10px;
  display: none;
}

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle:before {
  bottom: auto;
  top: -3px;
  border-top: 7px solid #ccc;
  border-bottom: 0;
  border-top-color: rgba(0, 0, 0, 0.2);
}

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle:after {
  bottom: auto;
  top: -3px;
  border-top: 6px solid #ffffff;
  border-bottom: 0;
}

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle:before {
  right: 12px;
  left: auto;
}

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle:after {
  right: 13px;
  left: auto;
}

.bootstrap-select.show-menu-arrow.open > .dropdown-toggle:before,
.bootstrap-select.show-menu-arrow.open > .dropdown-toggle:after {
  display: block;
}

.bootstrap-select.btn-group .no-results {
  padding: 3px;
  background: #f5f5f5;
  margin: 0 5px;
}

.mobile-device {
  position: absolute;
  top: 0;
  left: 0;
  display: block !important;
  width: 100%;
  height: 100% !important;
  opacity: 0;
}

.bootstrap-select.fit-width {
  width: auto !important;
}

.bootstrap-select.btn-group.fit-width .btn .filter-option {
  position: static;
}

.bootstrap-select.btn-group.fit-width .btn .caret {
  position: static;
  top: auto;
  margin-top: -1px;
}

.control-group.error .bootstrap-select .dropdown-toggle {
  border-color: #b94a48;
}

.bootstrap-select-searchbox {
  padding: 4px 8px;
}

.bootstrap-select-searchbox input {
  margin-bottom: 0;
}

.horizontal .vertical-switcher {
  display: none;
}

@media (max-width: 991px) {
  .vertical-switcher {
    display: none;
  }
}

/* .horizontal .vertical-images {
	display: none;
} */

/* RTL */

.rtl .demo_changer {
  transition: all 0.3s ease;
  left: -270px;
  right: auto !important;
}

.rtl .demo_changer.active {
  transition: all 0.3s ease;
  right: auto !important;
  left: 0;
}

.rtl .demo_changer .form_holder {
  float: left;
}

.rtl .demo_changer h4 {
  text-align: right;
}

.rtl .onoffswitch2-label:before {
  left: 13px;
  right: inherit;
}

.rtl .onoffswitch2-checkbox:checked + .onoffswitch2-label:before {
  left: 0px;
  right: inherit;
}

.rtl .ps > .ps__rail-y {
  left: 0 !important;
  right: auto !important;
}
.error-page1 .switcher-nav {
  display: none;
}
.error-page1 .horizontal-styles {
  display: none;
}
.error-page1 .leftmenu-styles {
  display: none;
}
.error-page1 .header-styles {
  display: none;
}
.error-page1 .vertical-images {
  display: none;
}
.error-page1 .switcher-layout {
  display: none;
}
.error-page1 .vertical-switcher {
  display: none;
}
.card-body .predefined_styles {
  text-align: Center !important;
}
/* licenses */
.tx-26 {
  font-size: 26px;
}
.tx-14 {
  font-size: 14px;
}
.tx-12 {
  font-size: 12px;
}
.font-weight-semibold {
  font-weight: 500;
}
.w-50 {
  width: 50% !important;
}

.w-55 {
  width: 50% !important;
}

.w-xs {
  min-width: 90px;
}

.w-sm {
  min-width: 110px;
}

.w-md {
  min-width: 130px;
}

.w-lg {
  min-width: 150px;
}
.w-xl {
  min-width: 190px;
}

.buynow .money-bag,
.free-img {
  margin: 0 auto;
  display: block;
  margin-top: -20px;
}

.buynow .dropdown-item {
  display: block;
  width: 100%;
  padding: 0.45rem 0.8rem;
  clear: both;
  font-weight: 400;
  color: #576074;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  font-size: 15px;
}
.buynow .dropdown-item:hover {
  background-color: #f5f4ff !important;
  border-radius: 5px;
}
.buynow .btn {
  padding: 0.375rem 0.75rem;
}
.buynow .dropdown-menu {
  border-radius: 5px !important;
  background-color: #ffffff;
  border: 1px solid #ffffff8a;
  box-shadow: 0 0px 90px rgb(155 153 153 / 48%) !important;
}
.buynow .card-block {
  border: 1px solid rgb(231 229 245 / 11%) !important;
  box-shadow: 0 0.15rem 1.75rem 0 rgb(255 255 255 / 6%);
  top: 0;
  bottom: 0;
  background: #6a1cbb69;
  left: 0;
  border-radius: 7px;
  width: 100%;
  height: 100%;
  position: absolute;
  inset-inline-end: 0;
  bottom: 0;
  inset-inline-start: 0;
}

@media (max-width: 480px) {
  .buynow .card .btn {
    display: initial !important;
  }
}
.buynow.modal .btn-close:focus {
  box-shadow: none;
}
.buynow .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #000000a8;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
  outline: 0;
}

.buynow .modal-dialog .image-top {
  margin-top: -10px !important;
}
.buynow .modal-dialog .card-block {
  background: #6a1cbbbf;
}
.buynow .regular-license {
  z-index: 9;
}
.border-0 {
  border: 0px !important;
}
.modal .modal-header {
  padding: 10px 20px;
}
@media (max-width: 480px) {
  .buynow .modal-dialog .modal-body {
    margin: 15px;
  }
}
.buynow .modal-dialog .btn-close {
  position: absolute;
  float: right;
  margin-right: auto;
  right: 30px;
  top: 35px;
  padding: 4px !important;
  background-image: none;
  border: 1px solid #ffffff47;
  border-radius: 5px;
  line-height: 1;
}
.buynow .modal-dialog .btn-close span {
  color: #fff;
  font-size: 21px;
  padding: 9px 1px;
  line-height: 0.6;
  font-weight: 400;
}
.lh-xs {
  line-height: 0.75 !important;
  margin-top: 6px;
}
.buynow .license-view {
  text-align: center;
  text-decoration: underline;
}
.buynow .license-view a {
  padding: 10px;
  border-radius: 5px;
}
.buynow .license-view a:hover {
  padding: 10px;
  background-color: rgb(255 255 255 / 12%);
  border-radius: 5px;
}
.bg-image-style {
  /* background-image: url(../img/bg3.jpg); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  inset-block-start: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  position: relative;
  border-radius: 0px;
}
.bg-image-style::before {
  content: "";
  background-color: #ffffffa6;
  width: 100%;
  height: 100%;
  left: 0%;
  top: 0;
  position: absolute;
}
.buynow .modal-backdrop {
  background-color: #000000b8;
}
.buynow .card-body.imag-list {
  overflow: visible;
}
.buynow .imag-list {
  position: relative;
  content: "";
  left: 0;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
  margin-block-end: 15px;
  width: 100%;
  height: 100%;
  border-radius: 7px;
  overflow: hidden;
  z-index: 1;
}
.buynow .imag-list::before {
  content: "";
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  top: 0;
  left: 0;
  right: 0;
  border-radius: 7px;
  position: absolute;
  background: #6a1cbb82;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.buynow .modal-content {
  z-index: 0;
  border: 0px #000 solid !important;
}
.buynow .modal-content::before {
  content: "";
  content: "";
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  top: 0;
  left: 0;
  right: 0;
  border-radius: 1rem;
  position: absolute;
  background: #000000b3;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.buynow .Licenses-img {
  border: 1px solid #ffffff2e;
  border-radius: 7px;
}
.modal.buynow .modal-body .card {
  background: transparent;
}
.license-view a:hover {
  color: #fff;
}
/*  */
