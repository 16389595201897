/* .switchtog {
    --false: #E81B1B;
    --true: #009068;
   }
   
   .tog[type=checkbox] {
    appearance: none;
    height: 2rem;
    width: 3.5rem;
    background-color: #fff;
    position: relative;
    border-radius: .2em;
    cursor: pointer;
   }
   
   .tog[type=checkbox]::before {
    content: '';
    display: block;
    height: 1.9em;
    width: 1.9em;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: calc(1.9em/2 + .3em);
    background-color: var(--false);
    border-radius: .2em;
    transition: .3s ease;
   }
   
   .tog[type=checkbox]:checked::before {
    background-color: var(--true);
    left: calc(100% - (1.9em/2 + .3em));
   } */


   
   .tog[type="checkbox"] {
    position: relative;
    width: 40px;
    height: 20px;
    appearance: none;
    background: #165f60;
    outline: none;
    border-radius: 2rem;
    cursor: pointer;
    box-shadow: inset 0 0 5px rgb(0 0 0 / 50%);
  }
  
  .tog[type="checkbox"]::before {
    content: "";
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: #fff;
    position: absolute;
    top: 0;
    left: 3%;
    transition: 0.5s;
  }
  
  .tog[type="checkbox"]:checked::before {
    transform: translateX(100%);
    background: #fff;
  }
  
  .tog[type="checkbox"]:checked {
    background: #1bb7b9;
  }
  .subbtn {
    cursor: pointer;
    font-weight: 500;
    font-family: Helvetica,"sans-serif";
    transition: all .2s;
    padding: 1px 17px;
    border-radius: 100px;
    background: #df262b;
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    font-size: 13px;
    color: #fff;
    letter-spacing: 1px;
  }
  
  .subbtn:hover {
    background: #f18c32;
  }
  
  .subbtn > svg {
    width: 25px;
    margin-left: 10px;
    transition: transform .3s ease-in-out;
  }
  
  .subbtn:hover svg {
    transform: translateX(5px);
  }
  
  .subbtn:active {
    transform: scale(0.95);
  }
  
  .button {
    --width: 130px;
    --height: 35px;
    /* --tooltip-height: 35px; */
    /* --tooltip-width: 90px; */
    --gap-between-tooltip-to-button: 18px;
    --button-color: #df262b;
    --tooltip-color: #fff;
    width: var(--width);
    height: var(--height);
    background: var(--button-color);
    position: relative;
    text-align: center;
    border-radius: 0.45em;
    transition: background 0.3s;
  }
  
  .button::before {
    position: absolute;
    content: attr(data-tooltip);
    width: var(--tooltip-width);
    height: var(--tooltip-height);
    background-color: var(--tooltip-color);
    font-size: 0.9rem;
    color: #111;
    border-radius: .25em;
    line-height: var(--tooltip-height);
    bottom: calc(var(--height) + var(--gap-between-tooltip-to-button) + 10px);
    left: calc(50% - var(--tooltip-width) / 2);
  }
  
  .button::after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: var(--tooltip-color);
    left: calc(50% - 10px);
    bottom: calc(100% + var(--gap-between-tooltip-to-button) - 10px);
  }
  
  .button::after,.button::before {
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s;
  }
  
  .text {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .button-wrapper,.text,.icon {
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    color: #fff;
  }
  
  .text {
    top: 0
  }
  
  .text,.icon {
    transition: top 0.5s;
  }
  
  .icon {
    color: #fff;
    top: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .icon svg {
    width: 50px;
    height: 50px;
  }
  
  .button:hover {
    background: #f68825;
  }
  
  .button:hover .text {
    top: -90%;
  }
  
  .button:hover .icon {
    top: 0;
  }
  
  .button:hover:before,.button:hover:after {
    opacity: 1;
    visibility: visible;
  }
  
  .button:hover:after {
    bottom: calc(var(--height) + var(--gap-between-tooltip-to-button) - 20px);
  }
  
  .button:hover:before {
    bottom: calc(var(--height) + var(--gap-between-tooltip-to-button));
  }
