.site-color3 {
  color: #fff;
}
.site-color4 {
  color: #777;
}
.site-color5 {
  color: #666;
}
.site-color6 {
  color: #999;
}
.site-color7 {
  color: #acacac;
}
.site-color9 {
  color: #333;
}
.site-color8 {
  color: #fff !important;
}
.fnt-size32 {
  font-size: 32px;
  font-weight: bold;
}
.fnt-size-l-32 {
  font-size: 32px;
}
.fnt-size26 {
  font-size: 26px;
}
.fnt-size15 {
  font-size: 15px;
}
.fnt-size13 {
  font-size: 13px;
}
.fnt-size38 {
  font-size: 38px;
}
.fnt-size42 {
  font-size: 42px;
}
.padding-whole {
  padding: 15px 30px;
}
.margin-top30 {
  margin-top: 30px;
}
.font-bold {
  font-weight: bold;
}
.font-light {
  font-weight: 300;
}
.font-regular {
  font-weight: 400;
}
.employee-list {
  padding-top: 50px;
  padding-bottom: 20px;
  background: var(--primary-color);
}
.importicons {
  padding: 13px 39px !important;
  border: 1px solid #fff;
  color: #fff;
}
.importicons1 {
  padding: 13px 30px !important;
  border: 1px solid #fff;
  color: #fff;
}
.alphabetic-order ul {
  padding: 0;
  display: inline-block;
  margin: 9px 0 0 4px;
}
.alphabetic-order ul li {
  list-style: none;
  float: left;
  display: inline-block;
  padding: 0 9px;
}
.alphabetic-order ul li a {
  text-decoration: none;
  color: #b5b1b1;
  font-size: 15px;
  font-weight: bold;
}
.alphabetic-order ul li a:hover {
  color: var(--primary-color);
  font-weight: 700;
}
.employee-id {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}
.serchbar1 {
  /* background: #fff url(../images/search.png) right no-repeat; */
  background-position: 165px;
  height: 40px;
  margin-top: 20px;
  width: 200px;
}
.tr-bg {
  background: #eee;
}
.table-res th {
  color: var(--primary-color) !important;
}
.table-res td {
  color: #333 !important;
}
.table-res td a {
  color: #666 !important;
}
.selectedemp {
  margin-top: 9px;
  display: inline-block;
  font-size: 14px;
  color: #aaa;
}
.sub-navigation2 {
  text-align: center;
  margin-bottom: 0;
  padding: 0;
}
.sub-navigation2 li {
  display: inline-block;
}
.sub-navigation2 li a {
  color: #a9a7a7;
  text-decoration: none;
  padding: 12px 20px;
  display: block;
  text-transform: capitalize;
  position: relative;
}
.sub-navigation2 li a:hover {
  color: #fff;
}
.sub-navigation2 li a.active {
  color: #fff;
}
.sub-navigation2 li a.active:before {
  width: 10px;
  position: absolute;
  content: "";
  height: 11px;
  border-left: 6px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 8px solid var(--primary-color);
  text-align: center;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.affix .sub-navigation2 li a.active:before {
  width: 10px;
  position: absolute;
  content: "";
  height: 11px;
  border-left: 6px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 8px solid #fff;
  text-align: center;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.devider1:before {
  height: 1px;
  background: #ccc;
  width: 465px;
  margin: 0 auto;
}
.before1:before {
  content: "";
  position: absolute;
  height: 14px;
  background: #ccc;
  width: 1px;
  left: 0;
  right: 0;
  margin: auto;
  top: -14px;
}
.before2:before {
  content: "";
  position: absolute;
  height: 66px;
  background: #ccc;
  width: 1px;
  left: 0;
  right: 0;
  margin: auto;
  top: -66px;
}
.before3:after {
  content: "";
  position: absolute;
  height: 1px;
  background: #ccc;
  width: 132px;
  left: -23.8em;
  right: 0;
  margin: auto;
  top: 28px;
}
.before4:after {
  content: "";
  position: absolute;
  height: 1px;
  background: #ccc;
  width: 132px;
  left: 0;
  right: -23.8em;
  margin: auto;
  top: 28px;
}
.before5:after {
  content: "";
  position: absolute;
  height: 20px;
  background: var(--primary-color);
  width: 1px;
  left: 0;
  right: 0;
  margin: auto;
  top: 81px;
}
.before-l:before {
  content: "";
  position: absolute;
  height: 35px;
  background: transparent;
  width: 1px;
  left: 0;
  right: 0;
  margin: auto;
  top: -35px;
  border-left: 1px solid var(--primary-color);
}
.beforenew:before {
  content: "";
  position: absolute;
  height: 34px;
  background: transparent;
  width: 1px;
  left: 0;
  right: 0;
  margin: auto;
  top: -34px;
  border-left: 1px solid var(--primary-color);
}
.beforenew:after {
  content: "";
  position: absolute;
  height: 20px;
  background: transparent;
  width: 1px;
  left: 0;
  right: 0;
  margin: auto;
  top: 80px;
  border-left: 1px solid var(--primary-color);
}
.before6:after {
  content: "";
  position: absolute;
  height: 1px;
  width: 132px;
  left: -23.8px;
  right: 0;
  margin: auto;
  top: 28px;
  border: 1px dotted #ccc;
}
.clear-results a {
  color: #efefef;
  color: rgba(255, 255, 255, 0.62);
  margin-right: 24px;
  margin-left: 10px;
}
.clear-results span {
  color: rgba(255, 255, 255, 0.62);
}
.drop-menu ul li {
  list-style: none;
}
.drop-menu h6 {
  color: var(--primary-color);
}
.drop-menu ul li a {
  text-decoration: none;
  color: #666;
}
.drop-menu .dropdown-menu:after {
  content: "";
  border-bottom: 13px solid #fff;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  position: absolute;
  top: -10px;
  right: 48px;
  z-index: 10;
}
.drop-menu .dropdown-menu:before {
  content: "";
  border-bottom: 10px solid #ccc;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  position: absolute;
  top: -10px;
  right: 52px;
  z-index: 10;
  left: 155px;
}
.width-drp {
  min-width: 65px;
}
.pull-lft-dropdwn {
  right: 70px;
}
.cal-events p {
  border-top: 1px solid #eee;
  color: #333;
  margin-top: 10px;
  padding-top: 10px;
  font-size: 13px;
}
.cal-events p .fa {
  margin-left: 5px;
  margin-right: 10px;
}
.cal-events .event-icon {
  color: #03a9f4;
}
.cal-events .holiday-icon {
  color: #4caf50;
}
.cal-events .birthday-icon {
  color: #ff5722;
}
.cal-events .leaves-icon {
  color: #00796b;
}
.cal-events .work-aniv-icon {
  color: #303f9f;
}
.cal-events .wed-aniv-icon {
  color: #e91e63;
}
.cal-events p:first-child {
  border-top: 0;
}
.cal-events a {
  display: block;
  color: #757575;
  margin-left: 25px;
  font-size: 13px;
  margin-bottom: 10px;
}
.cal-events a span {
  color: #ccc;
}
.cal-events a img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 5px;
}
.chev-arrow a {
  color: #dadada;
  padding: 0 3px;
}
.circle-btn {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  display: inline-block;
  color: #777;
  border: 1px solid #777;
  margin-right: 12px;
}
.circle-btn:hover {
  color: var(--primary-color);
  border-color: var(--primary-color);
}
.circle-btn:focus {
  background: var(--primary-color);
  color: #fff;
}
.stream-searchbar {
  background: #000;
  padding: 10px 0;
}
.serchbar {
  /* background: #fff url(../images/search.png) left no-repeat; */
  padding-left: 50px !important;
  background-position: 20px;
  height: 50px !important;
  color: #333 !important;
}
.stream-searchbar a.filtericons {
  color: #bababa;
  padding: 8px;
}
.profile-main-section {
  background: #f8f8f8;
  padding: 8px 0;
  color: #555;
}
.profile {
  margin: 20px 0;
}
.profile-sidebar {
  padding: 20px 0 10px 0;
}
.profile-userpic img {
  margin: 0 auto;
  width: 120px;
  height: 120px;
}
.profile-usertitle {
  text-align: center;
  margin-top: 20px;
  color: #333;
}
.profile-usertitle-name {
  font-size: 18px;
  font-weight: 700;
}
.profile-usertitle-job {
  color: #aaa;
  font-size: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e8e8e8;
}
.profile-usermenu ul li {
  border-bottom: 1px solid #e8e8e8;
}
.profile-usermenu ul li:last-child {
  border-bottom: 0;
}
.profile-usermenu ul li a {
  color: #acacac;
  font-size: 15px;
  font-weight: 100;
}
.profile-usermenu ul li a i {
  margin-top: 5px;
  font-size: 15px;
}
.profile-usermenu ul li a:hover {
  background: 0;
  color: var(--primary-color);
  font-weight: 700;
}
.profile-usermenu ul li a.active {
  background: 0;
  color: var(--primary-color);
}
.profile-usermenu ul li a:focus {
  background: 0;
}
.badge-danger {
  background: #ff8000;
}
.badge.bg-edit {
  min-width: 28px;
  height: 28px;
  margin-right: 10px;
}
.profile-usermenu ul li.active a {
  color: #5b9bd1;
}
.sub-tab ul li {
  padding-left: 41px;
  border-bottom: none !important;
}
.profile-content {
  padding: 20px;
  background: #fff;
}
.panel-tabs {
  position: relative;
  clear: both;
  background: #fff;
  text-align: center;
}
.panel-tabs>li {
    float: left;
    width: 100%;
    min-height: 50px
}
.panel-tabs > li > a {
  border: 1px solid transparent;
  color: #555;
  min-height: 50px;
  line-height: 2em;
}
.panel-tabs > li > a:hover {
  border-color: transparent;
  color: #999;
  background-color: rgba(241, 241, 241, 0.4);
}
.panel-tabs > li.active > a,
.panel-tabs > li.active > a:hover,
.panel-tabs > li.active > a:focus {
  color: #fff;
  cursor: default;
  background-color: var(--primary-color);
  border-bottom-color: transparent;
  min-height: 50px;
  line-height: 2em;
}
.border-black {
  border: 0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}
.post-btn {
  text-align: center;
  padding: 10px 27px !important;
}
.post-btn1 {
  text-align: center;
  padding: 5px 26px !important;
}
textarea#comment {
  border: 0;
}
input#comment {
  border: 0;
}
.no-border {
  border: none !important;
  box-shadow: none;
  border-color: #fff !important;
}
.no-border.form-control:focus {
  border: none !important;
  box-shadow: none;
  border-color: #fff !important;
}
.editor-items ul {
  padding: 0;
  margin: 0 auto;
}
.editor-items ul li {
  list-style: none;
  display: inline-block;
  padding: 0 3px;
  font-size: 13px;
}
.editor-items ul li a {
  color: #999;
  font-size: 11px;
}
.editor-items ul li a.active {
  color: var(--primary-color);
}
.adminname {
  font-size: 12px;
  color: #bababa;
}
.whole-padd {
  padding: 20px 30px 15px 30px;
}
.button-grp {
  border-top: 1px solid #bfbfbf;
  padding-top: 20px;
  padding-bottom: 11px;
}
.streams-btn {
  padding: 6px 62px;
}
.streams-btn1 {
  padding: 6px 49px;
}
.divide-line {
  border-bottom: 1px solid #ddd;
}
.user-posts {
  display: inline-block;
  background: #fff;
  min-height: 100px;
  padding-top: 10px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  margin-bottom: 15px;
  width: 100%;
}
img.post-pic {
  width: 40px;
  height: 40px;
}
h5.div-line,
span.div-line {
  padding-left: 17px;
  padding-right: 17px;
  word-wrap: break-word !important;
  line-height: 1.3;
}
.comments > .nav > li > a:hover,
.comments > .nav > li > a:focus {
  background: none !important;
}
.comments ul {
  padding: 0;
  margin: 0 auto;
  border-bottom: 1px solid #ddd;
}
.comments ul li {
  list-style: none;
  display: inline;
  float: left;
}
.comments ul li a {
  color: #999;
  font-size: 13px;
}
.comments ul li a span {
  font-size: 16px;
}
.people-comments {
  background: #f7f8f8;
}
.comments-count {
  padding: 8px;
  font-size: 13px;
  background-color: #f7f8f8;
}
img.comment-pic {
  width: 30px;
  height: 30px;
}
.user-posts span.badge.bg-edit {
  width: 34px;
  height: 33px;
}
.user-posts1 {
  background: #fff;
  min-height: 100px;
  padding-top: 10px;
}
.editor-items1 ul {
  padding: 0;
  margin: 0 auto;
}
.editor-items1 ul li {
  list-style: none;
  display: inline-block;
  padding: 7px 9px;
}
.editor-items1 ul li a {
  color: #999;
  font-size: 14px;
}
.editor-items1 ul li a.active {
  color: var(--primary-color);
}
.placeholder-icon {
  position: relative;
}
.placeholder-icon input {
  text-indent: 32px;
}
.placeholder-icon .fa-calendar,
.placeholder-icon .fa-clock-o,
.placeholder-icon .fa-map-marker {
  position: absolute;
  top: 12px;
  left: 10px;
  font-size: 16px;
}
.add-question {
  padding-bottom: 1em;
}
.table-res1 {
  max-height: 350px;
  overflow-y: scroll;
}
a.btn.importicons:hover {
  color: var(--primary-color);
  background: #fff;
}
a.btn.importicons1:hover {
  color: var(--primary-color);
  background: #fff;
}
h4.modal-title.site-color {
  font-size: 20px;
  font-family: latolight;
}
.modal-content {
  padding: 30px;
  border: 0;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
}
.full-div {
  margin-top: 60px;
}
.padd-right {
  padding-right: 80px;
}
.user-posts a span i {
  font-size: 22px;
  margin-top: 3px;
}
.newhire-icon {
  width: 15px;
  height: 15px;
  display: inline-block;
}
.new-hire {
  /* background: url(../images/employeewhite.png); */
  background-size: 100%;
}
.importicons1:hover .new-hire {
  /* background: url(../images/employeeblue.png); */
  background-size: 100%;
}
.importImg-icon {
  width: 15px;
  height: 15px;
  display: inline-block;
}
.importImg {
  /* background: url(../images/csv-white.png); */
  background-size: 100%;
}
.importicons:hover .importImg {
  /* background: url(../images/csv.png); */
  background-size: 100%;
}
.border-topnone {
  border-top: 0 !important;
}
a.accordion-toggle span {
  color: var(--primary-color);
}
.topborder {
  border-top: 1px solid #eee;
}
.top-line {
  border-top: 1px solid #eee;
}
.bottomborder {
  border-bottom: 1px solid #eee !important;
}
h4.panel-font span {
  font-size: 16px;
}
h4.accordian-heading span {
  font-size: 18px;
  color: #333;
}
.seting-accordian a.accordion-toggle {
  display: block;
  font-family: latolight;
}
.panel-heading-edit {
  font-size: 13px;
}
.no-bg {
  background: 0;
}
.fnt-size {
  font-size: 12px;
}
.margin-lft {
  margin-left: 10px;
}
.margin-rgt {
  margin-right: 10px;
}
.total-amount span {
  color: var(--primary-color);
}
.text-color1 {
  color: #e8c05a;
}
.text-color2 {
  color: #fe9000;
}
.text-color3 {
  color: #ef472e;
}
.text-color4 {
  color: #913533;
}
.text-color5 {
  color: #000084;
}
.text-color6 {
  color: #fc9;
}
.shift-section1 {
  padding: 20px 0;
  background: #e8e8e8;
  min-height: 69px;
}
.no-bg1 {
  background: 0;
  border: 1px solid #bbb !important;
}
ul.policy {
  float: right;
}
.policy li {
  list-style: none;
  display: inline-block;
  padding: 0 10px;
}
.policy li a {
  text-decoration: none;
  color: white;
  font-size: 13px;
}
.employee-list1 {
  padding-top: 20px;
  padding-bottom: 40px;
  background: var(--primary-color);
}
.sub-navigation3 {
  text-align: center;
  margin-bottom: 0;
  padding: 0;
}
.sub-navigation3 li {
  display: inline-block;
}
.sub-navigation3 li a {
  color: #a9a7a7;
  text-decoration: none;
  padding: 12px 12px;
  display: block;
  text-transform: capitalize;
  font-size: 14px;
  position: relative;
}
.sub-navigation3 li a:hover {
  color: #fff;
}
.sub-navigation3 li a.active {
  color: #fff;
}
.sub-navigation3 li a.active:before {
  width: 10px;
  position: absolute;
  content: "";
  height: 11px;
  border-left: 6px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 8px solid #fff;
  text-align: center;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.inline-style ul li {
  display: inline-block;
  list-style: none;
}
tr.tr-bg1 {
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
}
tr.tr-bg1 th {
  color: #7d8d9f;
  font-weight: 500;
  font-size: 12px;
}
.table caption + thead tr:first-child th,
.table caption + thead tr:first-child td,
.table colgroup + thead tr:first-child th,
.table colgroup + thead tr:first-child td,
.table thead:first-child.tr-bg1 {
  border: 0;
}
.form-table {
  width: 200px;
}
.employee-list3 {
  padding-top: 50px;
  padding-bottom: 20px;
}
.nav-tabs {
  border-bottom: 2px solid #ddd;
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
  border-width: 0;
}
.nav-tabs > li > a {
  border: 0;
  color: #666;
}
.nav-tabs > li > a:hover {
  border: 0;
  color: var(--primary-color) !important;
  background: transparent;
}
.nav-tabs > li > a.active::after {
  content: "";
  background: var(--primary-color);
  height: 2px;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -1px;
  transition: all 250ms ease 0;
  transform: scale(0);
  border-bottom: 2px solid var(--primary-color);
}
.nav-tabs > li.active > a::after,
.nav-tabs > li:hover > a::after {
  transform: scale(1);
}
.tab-nav > li > a::after {
  background: #21527d none repeat scroll 0 0;
  color: #fff;
}
.steps-padd {
  padding: 30px 0;
}
.black-section {
  background: #333;
  padding: 15px 0;
  margin-bottom: 5px;
}
.white-section {
  background: #fff;
  padding: 20px 0;
}
a.bulk-btn {
  margin-right: 15px;
}
.bulk-btn:hover {
  background: #ccc !important;
  border: 1px solid #ccc !important;
}
.file-heading {
  font-family: latolight;
}
.upload-btn {
  padding: 13px 73px;
  font-size: 20px;
}
.margin-tb {
  margin-top: 109px;
  margin-bottom: 50px;
}
.view-upload a {
  text-decoration: underline;
  color: var(--primary-color);
}
.margin-bottom80 {
  margin-bottom: 108px;
}
.red-color a {
  color: #ec4040;
}
.red-color span.badge {
  background: #ec4040;
}
.tr-bg1 {
  background-color: #f1f1f1;
}
.file-loader {
  top: 0;
  left: 0;
  margin: 0 auto;
  color: #333;
}
.tr-bg1 th {
  color: #888 !important;
}
.table caption + thead tr:first-child th,
.table caption + thead tr:first-child td,
.table colgroup + thead tr:first-child th,
.table colgroup + thead tr:first-child td,
.table thead:first-child tr:first-child th,
.table thead:first-child.tr-bg1 {
  border-top: 1px solid #eee !important;
}
a.tooltips {
  position: relative;
  display: inline;
}
a.tooltips span {
  position: absolute;
  width: 250px;
  color: #666;
  background: #fff;
  height: 75px;
  line-height: 15px;
  padding: 5px 10px;
  visibility: hidden;
  border-radius: 0;
  box-shadow: 0 2px 3px 1px #ccc;
}
a.tooltips span:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -12px;
  width: 0;
  height: 0;
  border-right: 9px solid #eee;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
}
a.tooltips span:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-right: 8px solid #fff;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
}
a.tooltips span p {
  color: var(--primary-color);
}
a:hover.tooltips span {
  visibility: visible;
  opacity: 1;
  left: 0;
  top: 0;
  margin-top: -33px;
  margin-left: 0;
  z-index: 999;
}
.file-heading i {
  font-size: 40px;
}
.error-msg h3 {
  color: #fff;
}
.error-msg a {
  color: #fff;
  margin-right: 30px;
  margin-left: 20px;
}
.error-msg a i {
  font-size: 45px;
}
.error-msg {
  border: 1px solid #ec4040;
  background: #ec4040;
  margin-top: 40px;
}
.db-error .searchabledropdown,
.db-error .db-label,
.db-error .error,
.db-error input.db-input,
.db-error textarea.db-textarea {
  color: #f44336 !important;
  border-color: #f44336 !important;
}
.margin-tb30 {
  margin: 30px 0;
}
.border {
  border: 1px solid #eee;
  margin-bottom: 12px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}
.spend-heading {
  font-size: 13px;
  font-weight: bold;
  margin-top: 3px;
}
.paymain .col-md-3,
.paymain .col-md-6 {
  padding: 0 6px;
}
.paddingleft0 {
  padding-left: 0 !important;
}
.paddingright0 {
  padding-right: 0 !important;
}
.padding6 {
  padding: 0 6px;
}
.caption {
  padding-bottom: 11px;
}
.bold {
  font-weight: bold;
  color: #666;
}
.color777 {
  color: #333;
}
.floatleft {
  float: left;
  width: 72%;
}
.floatright {
  float: left;
  width: 28%;
  padding-left: 6px;
}
.payrol-top {
  margin: 0 auto;
  padding: 0;
}
.payrol-top .col-sm-8 {
  padding: 0;
}
.payrol-top .col-sm-4 {
  padding: 0;
}
.select-emp-details {
  float: left;
  width: 20%;
  border-right: 1px solid #ddd;
  min-height: 50px;
  padding: 0px 10px;
}
.select-emp-details:last-child {
  border-right: 0;
}
.select-emp-details h1 {
  float: left;
  width: 44%;
  text-align: center;
  font-family: latolight;
  font-size: 38px;
  margin: 0;
}
.select-emp-details h4 {
  float: left;
  width: 56%;
  font-size: 14px;
  margin: 0;
  margin-top: 5px;
  color: #777;
}
.border-right-none {
  border-right: 0;
}
.mrgintop5 {
  margin-top: 12px !important;
}
.payroll-heading {
  font-family: latolight;
  font-size: 38px;
}
.payroll-subheading {
  font-size: 20px;
}
.content {
  padding: 10px;
  padding-top: 20px;
}
.border-right1 {
  border-right: 1px solid #eee;
}
.box-center {
  height: 140px;
  margin: 0 auto;
  min-height: 129px;
  margin-top: 60px;
}
ul.gender-graph {
  margin: 0 !important;
  padding: 0;
}
ul.gender-graph li {
  display: inline-block;
  list-style: none;
  text-decoration: none;
  margin-right: 25px;
}
.payroll-overflow table {
  width: 100%;
}
.paytable {
  padding: 10px;
}
a.refresh {
  color: #000;
}
a.refresh:hover {
  color: var(--primary-color);
}
table.height-line {
  font-size: 13px;
}
.height-line tr {
  line-height: 2.5em;
}
.box-full {
  border: 1px solid #eee;
}
.divider-line2 {
  border-bottom: 1px solid #eee;
}
.padd-left {
  padding-left: 0 !important;
}
.padd-right {
  padding-right: 0 !important;
}
.padd-top {
  padding-top: 0 !important;
}
.border-top0 {
  border-top: 0 !important;
}
.border-top1 {
  border-top: 1px solid #bcc6d6 !important;
}
.padd-bottom {
  padding-bottom: 0 !important;
}
.margin-lft15 {
  margin-left: 15px;
}
.margin-rht15 {
  margin-right: 15px;
}
.margin-lft20 {
  margin-left: 20px;
}
.margin-rht20 {
  margin-right: 20px;
}
.padd-lft25 {
  padding-left: 25px;
}
.padd-lft9 {
  padding: 9px 20px !important;
}
.margin-top10 {
  margin-top: 10px;
}
.stepwizard-step p {
  margin-top: 10px;
  text-decoration: none;
  font-size: 12px;
  color: #7d8d9f;
  margin-bottom: 0px;
  margin-top: 0px;
}
.stepwizard-row {
  display: table-row;
}
.stepwizard {
  display: table;
  width: 100%;
  position: relative;
}
.stepwizard-step button[disabled] {
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
}
.stepwizard-step {
  display: inline-block;
  text-align: center;
  position: relative;
  min-width: 165px;
}
.stepwizard-step:before {
  content: "";
  position: absolute;
  top: 30%;
  width: 100%;
  height: 1px;
  background: #e8ecf1;
  left: 0;
}
.stepwizard-step:first-child:before {
  width: 50%;
  right: 0;
  left: initial;
}
.stepwizard-step:last-child:before {
  width: 50%;
  left: 0;
}

.btn-circle1 {
  width: 40px;
  height: 40px;
  text-align: center;
  padding: 8px 0 !important;
  font-size: 14px;
  line-height: 25px;
  border-radius: 50% !important;
  background: #fff !important;
  border: 1px solid #eee;
  color: #333;
  margin: 0px auto 5px auto;
  font-size: 14px;
  position: relative;
  z-index: 1;
  display: inline-block;
}
.active .btn-circle1 {
  border-color: var(--primary-color) !important;
  background: var(--primary-color) !important;
  color: #fff !important;
}
.active p.payroll-2 {
  color: var(--primary-color);
}
.ok-done a {
  border: 1px solid var(--primary-color);
  color: var(--primary-color) !important;
}
.shift-section2 {
  padding: 20px 0;
  background: #f7f8f8;
  margin: 10px 0;
}
.hover-edit2 {
  position: relative;
}
.hover-edit2 .edithover2 {
  display: none;
  position: absolute;
  right: 10px;
}
.hover-edit2:hover .edithover2 {
  color: #584e4e;
  display: block;
  z-index: 999;
}
.hover-edit2:hover {
  background: #f9f9f9;
}
.padd-full0 {
  padding: 0;
  margin-bottom: 0;
  max-height: 320px !important;
}
.payroll-overflow {
  max-height: 615px;
  overflow-y: auto;
}
.payroll-middleblock {
  max-height: 320px !important;
}
.payroll-button {
  margin-right: 4px !important;
}
.paryroll_caret {
  margin-left: 10px;
}
.payroll_run_button {
  width: 150px;
  height: 40px;
}
.halfleft {
  float: left;
  background: #f7f7f7;
  width: 50%;
  min-height: 400px;
  padding: 40px;
  box-shadow: 0 2px 2px #ccc;
}
.halfright {
  float: left;
  width: 50%;
  min-height: 400px;
}
.payroll-count-det {
  width: 100%;
}
.payroll-count-det tr {
  border-bottom: 1px solid #ddd;
}
.payroll-count-det tr td {
  padding: 12px 0;
  min-height: 50px;
  line-height: 25px;
}
.payroll-count-det tr td i {
  margin-right: 5px;
}
.small-half {
  width: 350px;
  margin: 0 auto;
  margin-top: 7em;
}
.small-half h3 {
  font-family: latolight;
  font-size: 24px;
}
.small-half p {
  color: #aaa;
}
.small-half .btn {
  padding: 14px 20px;
  margin-right: 30px;
  margin-top: 30px;
  min-width: 120px;
}
.small-half .btn:hover img {
  background: #fff;
}
.border-section-bottom {
  border-bottom: 1px solid #eee;
  margin: 10px 0 30px 0;
}
.bs-wizard {
  border-bottom: solid 1px #e0e0e0;
  padding: 0 0 10px 0;
}
.bs-wizard > .bs-wizard-step {
  padding: 0;
  position: relative;
}
.bs-wizard-step {
  width: 33.33%;
  float: left;
}
.bs-wizard > .bs-wizard-step .bs-wizard-info {
  color: #999;
  font-size: 14px;
}
.bs-wizard > .bs-wizard-step > .bs-wizard-dot {
  position: absolute;
  width: 40px;
  height: 40px;
  display: block;
  background: #fff;
  border: 1px solid #eee;
  color: #969393;
  top: 2px;
  left: 50%;
  margin-left: -15px;
  border-radius: 50%;
  line-height: 40px;
}
.bs-wizard > .bs-wizard-step > .progress {
  position: relative;
  border-radius: 0;
  height: 1px;
  box-shadow: none;
  margin: 25px 0;
}
.bs-wizard > .bs-wizard-step > .progress > .progress-bar {
  width: 0;
  box-shadow: none;
  background: #eee;
}
.bs-wizard > .bs-wizard-step:first-child > .progress {
  left: 50%;
  width: 50%;
}
.bs-wizard > .bs-wizard-step:last-child > .progress {
  width: 50%;
}
.active .bs-wizard-dot {
  background: var(--primary-color) !important;
  color: #fff !important;
  border: 1px solid var(--primary-color) !important;
}
.active .bs-wizard-info {
  color: var(--primary-color) !important;
}
.ok .bs-wizard-dot {
  border-color: var(--primary-color) !important;
  color: var(--primary-color) !important;
}
.col1 {
  width: 360px;
}
.col2 {
  width: 130px;
}
.col3 {
  width: 180px;
}
.col32 {
  width: 50px;
  margin-top: 32px;
}
.col1,
.col2,
.col3,
.col32 {
  float: left;
  padding-left: 10px;
  padding-right: 10px;
}
.colmd12 {
  width: 91%;
  margin: 0 auto;
}
.table-res11 {
  max-height: 550px;
  overflow-y: scroll;
  overflow-x: scroll;
  max-width: 1312px;
}
.hr-document-main {
  width: 400px;
  min-height: 200px;
  margin: 3em auto;
  background: #fff;
  padding: 4em 2em;
}
.hr-document-main h3 {
  font-family: latolight;
  font-size: 24px;
  line-height: 50px;
}
.hr-document-main p {
  color: #aaa;
}
.hr-document-main .btn {
  padding: 14px 20px;
  margin-top: 30px;
  min-width: 160px;
}
.hr-singlebox {
  min-height: 153px;
  padding: 20px;
  background: #fff;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
}
.hrmargin-right {
  margin-right: 10px;
}
.bottom-hr-btns a.btn {
  background: #eee;
  border: 1px solid #ddd;
  font-weight: 100;
  font-size: 12px;
  padding: 6px 13px;
  margin-bottom: 5px;
  color: #666;
}
.bottom-hr-btns {
  margin-top: 50px;
}
.hr-singlebox h3 {
  margin: 0 0 10px 0;
  color: var(--primary-color);
  font-family: "latolight";
  font-size: 24px;
}
.hr-singlebox p {
  margin: 0 0 5px 0;
}
.hrmain-container {
  text-align: center;
  padding: 2em 0;
}
.hr-document-main1 {
  width: 400px;
  min-height: 200px;
  display: inline-block;
  background: #fff;
  padding: 4em 2em;
  margin: 1em;
}
.hr-document-main1 h3 {
  font-family: latolight;
  font-size: 24px;
  line-height: 50px;
}
.hr-document-main1 p {
  color: #aaa;
}
.hr-document-main1 .btn {
  padding: 14px 20px;
  margin-top: 30px;
  min-width: 160px;
}
.hr-doc {
  background: #fff;
  padding: 30px;
  overflow: auto;
  max-height: 600px;
  font-weight: bold;
  font-size: 12px;
}
.hr-doc ul {
  padding: 0;
}
.hr-doc ul h1 {
  font-size: 30px;
  margin-bottom: 40px;
}
.hr-doc ul li {
  list-style: none;
}
.hr-doc ul li h3 {
  margin: 10px 0;
  font-size: 24px;
}
.hr-doc ul li ul {
  padding-left: 40px;
}
.hr-doc ul li ul li {
  list-style: disc;
  line-height: 16px;
}
.hr-doc span {
  color: var(--primary-color);
  text-decoration: underline;
}
.hr-doc1 {
  background: #fff;
  padding: 30px;
  font-size: 12px;
}
.hr-doc1 ul {
  padding: 0;
}
.hr-doc1 ul h1 {
  font-size: 30px;
  margin-bottom: 40px;
}
.hr-doc1 ul li {
  list-style: none;
}
.hr-doc1 ul li h3 {
  margin: 10px 0;
  font-size: 24px;
}
.hr-doc1 ul li ul {
  padding-left: 40px;
}
.hr-doc1 ul li ul li {
  list-style-type: lower-alpha;
  line-height: 16px;
}
.hr-doc1 span {
  color: var(--primary-color);
  text-decoration: underline;
}
.hr-doc-create {
  width: 72px;
  height: 72px;
  border: 1px solid #666;
  background: #666;
  border-radius: 50%;
  margin-left: 135px;
}
.hr-doc-create a i {
  font-size: 24px;
  color: #fff;
  margin-top: 24px;
}
.hr-icon-color a {
  background: #fff;
  color: var(--primary-color);
  height: 30px;
  width: 30px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50px;
  padding: 4px;
  font-size: 18px;
  text-align: center;
}
.hr-icon-color i {
  font-size: 16px;
}
.icon-bg {
  width: 45px;
  height: 32px;
  border: 1px solid #f1f1f1;
  background: #f1f1f1;
  float: left;
  margin-right: 10px;
}
.icon-bg a img {
  margin-left: 11px;
  margin-top: 5px;
  width: 20px;
  height: 20px;
}
.hr-word .white-section1 .col-md-12 {
  padding-left: 0;
  padding-right: 0;
  margin: 0;
}
.white-section1 {
  background: #fff;
  padding: 20px 0;
}
.white-section2 {
  background: #fff;
  padding: 0 15px;
}
.white-section2 h3 {
  font-family: "roboto";
}
.white-section3 {
  background: #fff;
  padding: 0 15px;
}
.hr-accordian li {
  border-bottom: 1px solid #eee;
}
.hr-accordian li a {
  color: #333;
}
.white-section2 h4.panel-font span {
  color: #4b5c72;
  font-size: 14px;
  font-family: "roboto";
}
.hr-accordian {
  padding: 0 !important;
}
.hr-accordian li {
  padding: 6px 0 6px 15px;
}
.close {
  line-height: 1.2;
}
.gray-section1 {
  background: #f1f1f1;
  padding: 20px 0;
  box-shadow: inset 1px 1px 3px #e2dddd, 6px -29px 24px -100px #ddd;
  -webkit-box-shadow: inset 1px 1px 3px #e2dddd, 6px -29px 24px -100px #ddd;
  -moz-box-shadow: inset 1px 1px 3px #e2dddd, 6px -29px 24px -100px #ddd;
}
.box-full-hr {
  margin: 2px;
}
.letter_select-hr {
  margin: 6px 0;
}
.colmd2 {
  width: 17.4%;
  float: left;
}
.col-md3 {
  width: 24.2%;
  float: left;
}
.padd10 {
  padding: 10px;
}
.posts-info li {
  min-width: 33%;
  border-right: 1px solid #eee;
}
.posts-info i {
  color: var(--primary-color);
}
.hover-edit-icon1 {
  position: relative;
}
.hover-edit-icon1 .editimg-con {
  display: none;
}
.hover-edit-icon1:hover .editimg-con {
  display: block;
  width: 120px;
  background: rgba(0, 0, 0, 0.36);
  height: 120px;
  position: absolute;
  top: 0;
  border-radius: 50%;
  text-align: center;
  line-height: 120px;
  color: #fff;
  left: 45px;
}
.drop-filter .dropdown-menu:before {
  display: none;
}
.drop-filter .dropdown-menu:after {
  display: none;
}
.drop-filter li a {
  color: #555;
}
.drop-filter li a:hover {
  color: #ccc;
}
.drop-filter li {
  margin-top: 15px;
  margin-right: 10px;
}
ul.drpdwn-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  width: 26%;
  padding: 0;
  margin: 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  box-shadow: 0;
}
ul.drpdwn-menu li {
  border-bottom: 1px solid #ccc;
  background-color: #f6f7f8;
}
ul.drpdwn-menu li a {
  padding: 15px 25px;
}
ul.drpdwn-menu a i.globe-i {
  font-size: 20px;
}
.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: #fff !important;
}
.nav .open > #dash-drop-menu,
#dash-drop-menu:focus,
#dash-drop-menu:hover {
  background: transparent !important;
  color: #666 !important;
}
.privacy-drop {
  min-height: 30px;
  border-bottom: 1px solid #eee;
  margin: 5px 0;
}
.privacy-drop a {
  color: #999;
}
.editor-items2 ul {
  padding: 0;
  margin: 0 auto;
}
.editor-items2 ul li {
  list-style: none;
  display: inline-block;
  padding: 9px 9px;
}
.editor-items2 ul li a {
  color: #999;
  font-size: 14px;
}
.post-poll {
  margin-top: 10px;
  margin-left: 10px;
}
.post-poll a {
  color: #555;
}
.vote-percent {
  margin-top: 33px;
}
.progress1 {
  height: 12px;
  overflow: hidden;
  background-color: #fff;
  border: 1px solid #eee;
}
.progress-bar-warn {
  background-color: #ffd451;
}
.event-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--primary-color);
}
.event-pic img {
  margin-left: 8px;
  margin-top: 6px;
}
.create-gp-image {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 1px solid #ccc;
}
.create-gp-image a {
  color: #ccc;
  position: absolute;
  top: 0;
  line-height: 120px;
  left: 60px;
  font-size: 23px;
}
.no-marginbottom {
  margin-bottom: 0 !important;
}
.panel-icons li a i {
  font-size: 16px;
}
.count-attend {
  font-size: 13px;
  color: #aaa;
}
.editor-items1 label,
.editor-items2 label {
  color: #999;
}
.new-members {
  padding: 9px 0;
  min-height: 60px;
}
.posts-info i {
  font-size: 16px;
}
.table-td td {
  border-left: 0 !important;
}
.dropdown-menu {
  border-radius: 0 !important;
}
.marginleft {
  margin-left: 30px;
}
.padd0 {
  padding: 15px 7px !important;
}
.panel-tabs1 {
  position: relative;
  clear: both;
  border-bottom: 1px solid #e4e2e2;
  background: #fff;
  text-align: center;
}
.panel-tabs1 > li {
  float: left;
  width: 25%;
  min-height: 50px;
}
.panel-tabs1 > li > a {
  border: 1px solid transparent;
  color: #555;
  min-height: 50px;
  line-height: 2em;
}
.panel-tabs1 > li > a:hover {
  border-color: transparent;
  color: #ccc;
  background-color: transparent;
}
.panel-tabs1 > li.active > a,
.panel-tabs1 > li.active > a:hover,
.panel-tabs1 > li.active > a:focus {
  color: #fff;
  cursor: default;
  background-color: var(--primary-color);
  border-bottom-color: transparent;
  min-height: 50px;
  line-height: 2em;
}
.no-borderbottom {
  border-bottom: none !important;
}
.user-image {
  float: left;
  padding-left: 10px;
}
.user-desc {
  float: left;
  padding-left: 10px;
}
.user-desc1 {
  float: left;
}
.no-vibe {
  min-height: 150px;
  background: #fff;
  padding: 15px 20px;
}
.hoop {
  font-size: 25px;
  margin-top: -21px;
}
.Combo {
  font-size: 15px;
}
.Dribble {
  font-size: 20px;
  margin-top: -24px;
  margin-left: -95px;
}
.Backshot {
  font-size: 25px;
  margin-top: -37px;
  margin-right: 15px;
}
.whole-padd1 {
  padding: 13px 12px 12px 12px;
  color: #acacac !important;
}
.Basketball {
  font-size: 40px;
  margin-top: -18px;
  font-weight: 600;
  margin-left: 26px;
  color: #acacac;
}
.Forward {
  font-size: 15px;
  margin-top: -38px;
  margin-right: 10px;
}
.Slam {
  font-size: 30px;
  margin-top: -15px;
}
.Cornerman {
  margin-right: 31px;
  margin-top: -13px;
  color: var(--primary-color);
}
.Coach {
  margin-top: -19px;
}
.Combo {
  margin-top: -17px;
}
.center {
  margin-top: -18px;
  margin-left: -28px;
}
.layup {
  margin-right: 59px;
  margin-top: -21px;
}
.Layup1 {
  margin-top: -23px;
  font-size: 15px;
}
.caption1 {
  padding-bottom: 11px;
}
.caption1 img {
  min-height: 153px;
}
img.reports-2image {
  margin-top: 29px;
}
.top-mg1 {
  margin-top: 66px;
}
.top-mg {
  margin-top: 66px;
}
.top-mg .separate-image {
  padding-left: 20px;
}
.top-mg h1 {
  padding-left: 20px;
}
.top-mg h3 {
  padding-left: 20px;
}
ul.generate-btn {
  top: 30px;
  left: 16px;
  width: 92%;
}
.generate-btn li {
  padding: 4px 0;
}
.dot-color1 {
  color: #00b2b2;
}
.dot-color2 {
  color: #b1ebff;
}
.dot-color3 {
  color: #ffd451;
}
.dot-color4 {
  color: #ff834d;
}
.dot-color5 {
  color: #868bb8;
}
.margin-top22 {
  margin-top: 22px;
}
.padd-bottom14 {
  padding-bottom: 14px;
}
.employee-list2 {
  padding-top: 20px;
  padding-bottom: 20px;
  background: var(--primary-color);
}
.back-arrow {
  margin-top: 25px;
  padding-left: 10px;
}
.col5 {
  padding: 0 50px;
}
.form-custom {
  background: transparent;
  border: 1px solid #fff;
  color: #fff !important;
}
.form-custom:hover {
  border: 1px solid #fff !important;
}
.form-custom:active {
  box-shadow: none !important;
}
.form-custom:focus {
  box-shadow: none !important;
  border: 1px solid #fff !important;
}
.form-custom1 {
  background: transparent;
  border: 1px solid #fff;
  color: #fff !important;
  padding: 10px 14px;
  font-weight: 100 !important;
}
.form-custom1 i {
  font-size: 18px;
}
.form-custom1:hover {
  border: 1px solid #fff;
  color: #fff !important;
}
.form-custom1:active {
  box-shadow: none !important;
}
.form-custom1:focus {
  box-shadow: none !important;
  border: 1px solid #fff !important;
}
.form-custom2 {
  background: #fff !important;
  border: 1px solid #fff !important;
  color: var(--primary-color) !important;
  padding: 10px 14px;
  margin-top: 25px;
}
.form-custom2:hover {
  border: 1px solid #fff !important;
  color: #fff !important;
}
.form-custom2:active {
  box-shadow: none !important;
}
.form-custom2:focus {
  box-shadow: none !important;
  border: 1px solid #fff;
}
.share-icon i {
  font-size: 17px;
}
.report-cloud {
  margin-top: 10px;
}
.report-table td {
  color: #666 !important;
}
.report-table th {
  color: var(--primary-color) !important;
}
.contain-1 {
  width: 100%;
}
.emp-reports-table .col-md-12 {
  padding-left: 0;
  padding-right: 0;
}
.white-color {
  color: #fff !important;
}
.employee-list2 .radio-infoo {
  margin: 29.5px 0;
}
.select-critiria {
  border: 1px solid rgba(255, 255, 255, 0.5);
  min-height: 125px;
  border-radius: 0;
}
.select-critiria ul li {
  float: left;
  margin: 5px;
  padding: 0;
  background: 0;
  border: 0;
}
.select-critiria ul li a {
  background: #379fdc;
  padding: 3px 8px;
  font-size: 14px;
  color: #fff;
}
.list-arrows {
  padding-top: 60px;
  padding-left: 0;
  padding-right: 0;
  width: 31px;
}
.list-arrows button {
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.5) !important;
  margin-bottom: 5px;
  padding: 3px 6px;
  clear: both;
  border-radius: 0;
}
.list-group-item.active a {
  background: #87b7e0;
  color: rgba(255, 255, 255, 0.58);
}
.form-custom3 {
  background: #fff;
  border: 1px solid #fff;
  color: var(--primary-color);
  padding: 9px 23px;
  margin-top: 25px;
}
.form-custom3:hover {
  border: 1px solid #fff;
  color: #fff;
}
.form-custom3:active {
  box-shadow: none !important;
}
.form-custom3:focus {
  box-shadow: none !important;
  border: 1px solid #fff;
}
.border1 {
  border: 1px solid #eee;
  margin-bottom: 12px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  height: 280px;
}
.filter-color {
  color: #aaa;
}
.borderless {
  border-style: none;
  background-color: inherit;
}
.borderless:focus {
  outline: 0;
  border-color: none !important;
  box-shadow: none !important;
}
.leave-btn {
  background: #fff;
  border: 1px solid #eee;
  color: #999;
  padding: 10px 14px;
  font-weight: 100 !important;
}
.leave-btn i {
  font-size: 18px;
}
.leave-btn:hover {
  border: 1px solid #eee;
  color: #999;
  background: #fff;
}
.leave-btn:active {
  border: 1px solid #eee !important;
  color: #999;
  background: #fff !important;
}
@media (max-width: 740px) {
  .organizers:before,
  .devider:before,
  .devider1:before,
  .before1:before,
  .before2:before,
  .before3:after,
  .before4:after,
  .before-l:before,
  .before:before {
    content: none;
  }
}
@media (max-width: 1300px) {
  ul.generate-btn {
    left: 2px;
    width: 99% !important;
  }
  .caption1 img {
    min-height: 153px;
  }
}
@media (max-width: 1280px) {
  ul.generate-btn {
    left: 5px;
    width: 97% !important;
  }
  .caption1 img {
    min-height: 153px;
  }
}
@media (max-width: 1180px) {
  .caption1 img {
    min-height: 167px;
  }
}
.totalLeave th {
  font-size: 14px !important;
  font-weight: normal !important;
  color: #000 !important;
  padding: 12px !important;
}
.totalLeave:hover {
  background-color: #f5f5f5;
}
.row-margin {
  margin-right: -10px;
  margin-left: -10px;
}
.row-margin2 {
  margin-right: -5px;
  margin-left: -15px;
}
.rsvp_response span {
  padding-left: 15px;
}
.margin-top3 {
  margin-top: 3px;
}
.msg-actions {
  padding: 10px;
}
.post-btn2 {
  text-align: center;
  padding: 6px 15px;
}
.progress-color {
  background-color: #ff8000;
}
.table-nomargin {
  margin-bottom: 0 !important;
}
.table-tax-particulars th,
.table-tax-particulars td {
  background: #f7f7f7 !important;
}
.table-tax-particulars table.htCore {
  width: 100% !important;
}
.table-tax-particulars th,
.table-tax-particulars td {
  border-right: 0 !important;
}
.table-tax td {
  border-right: 0 !important;
  border-bottom: 1px solid #eee !important;
}
.table-tax th {
  color: #888 !important;
}
.table-tax table.htCore {
  width: 100% !important;
  table-layout: auto !important;
}
.table-tax tr:first-child {
  border-right: 1px solid #ccc !important;
  background-color: #f1f1f1 !important;
}
.handsontable th {
  background-color: #f1f1f1 !important;
  color: #888 !important;
}
.table-tax-particulars th {
  background-color: #ddd !important;
}
.table-tax-particulars tr:first-child {
  border-right: 1px solid #ccc !important;
}
.table-tax-particulars td {
  border-bottom: 1px solid #ddd !important;
  border-right: 1px solid #ddd !important;
  background-color: #f1f1f1 !important;
}
.table-tax-particulars tr:first-child td {
  border-top: 0 !important;
}
.table-tax-particulars tr td:last-child {
  border-right: 0 !important;
}
.table-tax .wtSpreader {
  width: auto !important;
}
.table-tax-particulars .wtSpreader {
  width: auto !important;
}
.handsontable thead th .relative {
  text-align: left;
}
.dataTables_paginate {
  float: right;
  text-align: right;
}
.dataTables_paginate .ui-button {
  margin-right: -0.1em !important;
}
.paging_two_button .ui-button {
  float: left;
}
.paginate_disabled_previous,
.paginate_enabled_previous,
.paginate_disabled_next,
.paginate_enabled_next {
  height: 19px;
  float: left;
  cursor: pointer;
  *cursor: hand;
  color: #111 !important;
}
.paginate_disabled_previous:hover,
.paginate_enabled_previous:hover,
.paginate_disabled_next:hover,
.paginate_enabled_next:hover {
  text-decoration: none !important;
}
.paginate_disabled_previous:active,
.paginate_enabled_previous:active,
.paginate_disabled_next:active,
.paginate_enabled_next:active {
  outline: 0;
}
.paginate_disabled_previous,
.paginate_disabled_next {
  color: #666 !important;
}
.paginate_disabled_previous,
.paginate_enabled_previous {
  padding-left: 23px;
}
.paginate_disabled_next,
.paginate_enabled_next {
  padding-right: 23px;
  margin-left: 10px;
}
.paginate_enabled_previous {
  /* background: url('../images/back_enabled.png') no-repeat top left */
}
.paginate_enabled_previous:hover {
  /* background: url('../images/back_enabled_hover.png') no-repeat top left */
}
.paginate_disabled_previous {
  /* background: url('../images/back_disabled.png') no-repeat top left */
}
.paginate_enabled_next {
  /* background: url('../images/forward_enabled.png') no-repeat top right */
}
.paginate_enabled_next:hover {
  /* background: url('../images/forward_enabled_hover.png') no-repeat top right */
}
.paginate_disabled_next {
  /* background: url('../images/forward_disabled.png') no-repeat top right */
}

/* Fancy table plagination START */
table tfoot tr td.pag {
  text-align: center;
  padding-top: 12px;
}
table tfoot tr td.pag a {
  margin: 0px 4px;
  border: 1px solid transparent !important;
  height: 36px;
  width: 36px;
  display: inline-block;
  padding: 6px 12px;
  text-align: center;
  line-height: 24px;
  font-size: 12px;
  border-radius: 50%;
  font-weight: normal !important;
  color: #4b5c72 !important;
}
table tfoot tr td.pag a.active {
  border: 1px solid var(--primary-color) !important;
  color: var(--primary-color) !important;
  background-color: #fff !important;
  border-radius: 50%;
  box-shadow: none;
}
/* Fancy table plagination END */
.actions-dash {
  margin-left: 3px;
}
.border-bottom-dash {
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 15px;
}
h5.div-line-dash {
  line-height: 1.3;
}
.placeholder-icon select {
  text-indent: 10px;
}
.multi-poll-data {
  border-radius: 0;
  border: 1px solid #ccc;
  padding: 5px;
  margin-bottom: 10px;
}
.streams-groups-title {
  padding: 10px 10px 0 10px;
}
.streams-groups-title h5 {
  padding-bottom: 10px;
  color: #999;
  font-weight: bold;
  margin-bottom: 0;
}
.group-box {
  padding: 10px 0;
  margin: 0 10px;
  border-bottom: 1px solid #ddd;
}
.group-vibe-hover:hover {
  background: #eee;
}
.groupicon {
  padding: 0;
}
.group-head a {
  color: var(--primary-color);
  font-weight: bold;
  font-size: 16px;
}
.salary-head {
  padding: 10px;
}
.rsvp_response + .rsvp_response {
  margin-top: 10px;
}
.eventdetails .rsvp_response span {
  padding: 0;
}
.marginevenet {
  margin-left: 10px !important;
}
.eventdetails .rsvp_response {
  margin-left: 15px;
  margin-top: 10px;
}
#custom_fields_container tbody tr td {
  border-left: 0;
}
.cursor_pointer {
  cursor: pointer;
}
.margin-right-5 {
  margin-right: 5px;
}
.margin-right-3 {
  margin-right: 3px;
}
.read-less a {
  margin-left: 2.5%;
  color: var(--primary-color);
}
.read-more a {
  color: var(--primary-color);
}
.email-wrap {
  width: 200px;
  word-wrap: break-word;
}
.groupheader {
  background: #fff !important;
}
.created-group {
  padding: 15px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  margin-bottom: 15px;
}
.badge-yellow {
  background: #fdd758;
  color: #000;
}
.table-total {
  border-top: 1px solid #eee;
}
.btn-right-margin10 {
  margin-right: 10px;
}
.btn-joins {
  margin-right: -5px;
}
.declaration-table td input {
  padding: 12px;
  box-shadow: none;
  border-radius: 0;
}
.dept-bus-style {
  margin-left: 10px;
  color: #b5b1b1;
}
.weeklyoffcheckbox {
  margin-top: 5px;
  padding-left: 0;
}
.padding-0 {
  padding-left: 0;
}
.margin-left0 {
  margin-left: 0 !important;
}
.event-dash-table td {
  font-size: 12px !important;
  color: #b3aeae !important;
  font-weight: none !important;
  padding: 2px 12px !important;
  line-height: 25px !important;
  vertical-align: middle !important;
  border-top: 1px solid #ccc !important;
  border-bottom: 1px solid #ccc !important;
}
.border-right-event {
  border-right: 1px solid #ccc !important;
}
.events-column {
  margin-top: 67px !important;
  border-top: 1px solid #ddd !important;
}
.events-column p {
  font-weight: bold;
}
.cal-events div {
  margin-bottom: 20px;
}
.profile-browse-img {
  padding: 6px 8px;
}
.main-sidebar-right {
  background: var(--primary-color);
}
.main-sidebar-right ul {
  margin-top: 50px;
  padding: 0;
}
.main-sidebar-right ul li {
  list-style: none;
  padding-left: 12px;
}
li.close-sb:hover {
  background-color: #64b5f6;
}
.main-sidebar-right ul li a {
  padding: 10px;
  color: #fff !important;
  display: block;
  font-size: 18px;
  font-family: latolight;
  padding-left: 15px;
}
.main-sidebar-right ul li a img {
  width: 22px;
  height: 22px;
  margin-right: 10px;
}
.sidebar-line {
  width: 100%;
  padding: 0;
}
.divider-line4 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.profile_image {
  padding: 10px;
}
.profile_image img {
  margin-bottom: 0;
  width: 30px;
  height: 30px;
}
.profile_image span {
  margin-top: 0;
}
.halfleft1 {
  float: left;
  width: 50%;
  min-height: 400px;
  padding: 40px;
  box-shadow: 3px 0 3px 0 rgba(0, 0, 0, 0.2);
}
.halfleft1 p {
  margin: 0 !important;
}
.halfleft1 h3.inner-heading {
  padding: 0 !important;
}
.halfright1 {
  float: left;
  background: #f7f7f7;
  width: 49.64%;
  min-height: 400px;
  padding: 40px;
  margin-left: 4px;
}
.halfleft1.center-alined {
  float: initial;
  margin: 0px auto;
  box-shadow: 0px 0 3px 0 rgba(0, 0, 0, 0.2) !important;
}

.halfright1 p {
  margin: 0 !important;
}
.halfright1 h3.inner-heading {
  padding: 0 !important;
}
.border-app {
  margin-bottom: 12px;
  border: 1px solid #ddd;
  min-height: 200px;
  position: relative;
}
.marginBottom-10-event-dash {
  margin-bottom: 10px;
}
.marginTop-10-event-dash {
  margin-top: 10px;
}
.marginLeft-10-event-dash {
  margin-left: 10px;
}
.event-privacy-drop {
  border-bottom: 0;
}
.textarea-noresize-vibe {
  height: 70px !important;
  overflow: auto;
  resize: unset;
}
.select-critiria-content {
  background: var(--primary-color);
  color: #fff;
}
.progress-bar-blue {
  backgound: var(--primary-color);
}
.progress-profile {
  height: 8px;
  overflow: hidden;
  background-color: #fff;
  border: 1px solid var(--primary-color);
  width: 200px;
}
h6.profile-complete {
  color: var(--primary-color);
  margin-bottom: 5px;
}
#salary-widget-dash thead:first-child tr:first-child th,
#salary-widget-dash tbody tr td:first-child {
  border-left: 1px solid #eee !important;
}
.dash-clock {
  margin-top: 8px;
  margin-right: 10px;
}
.pending-tabs-dash {
  border-bottom: 0;
  height: 44px;
  display: inline-block;
}
.with-nav-tabs .panel-heading {
  border-bottom: 0;
}
.clock-out-dash {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: #fff;
}
.tool-tip-event {
  background-color: #fff;
}
.popover {
  padding: 0 !important;
}
.org-box-str {
  border: 1px solid var(--primary-color);
}
.message-file-list li {
  list-style: none;
}
.hover-down-preview {
  border: 1px solid #eee;
  padding: 18px;
}
.hover-down-preview .down-hover {
  display: none;
  position: absolute;
  top: 39%;
  left: 22%;
  right: 0;
  color: #ccc;
  font-size: 20px;
  border: 1px solid #ccc;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  line-height: 27px;
  background: #fff;
  text-align: center;
  z-index: 999;
}
.hover-down-preview .preview-hover {
  display: none;
  position: absolute;
  top: 39%;
  left: 53%;
  right: 0;
  color: #ccc;
  font-size: 20px;
  border: 1px solid #ccc;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  line-height: 27px;
  background: #fff;
  text-align: center;
  z-index: 999;
}
.hover-down-preview:hover .down-hover {
  display: block;
}
.hover-down-preview:hover .preview-hover {
  display: block;
}
.down-hover:hover {
  color: white;
  background: var(--primary-color);
  border-color: var(--primary-color);
}
.preview-hover:hover {
  color: white;
  background: var(--primary-color);
  border-color: var(--primary-color);
}
.name-over {
  max-width: 135px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 8px;
}
.borderImage:hover {
  opacity: 0.1;
}
.benefit-user {
  width: 120px;
  height: 120px;
  margin: 26px;
  margin-left: 50px;
}
.benefit-link {
  padding-left: 20px;
  text-decoration: underline;
  color: var(--primary-color);
}
.benefit-padd {
  padding: 40px 0;
}
.benefit-padd p {
  padding: 8px 0;
}
.hiddenRow {
  padding: 0 !important;
  background: #eee;
}
.benefit-p {
  font-size: 14px;
  padding: 27px;
  margin: 0;
}
.benefit-margin-lft50 {
  margin-left: 50px;
}
.row-margin {
  margin-left: -10px;
  margin-right: -10px;
}
.circle-btn-i {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  display: inline-block;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}
.circle-btn-i:focus {
  background: var(--primary-color);
  color: #fff;
}
.accured-lta {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 20px 0 12px 0;
  margin-bottom: 15px;
}
.accured-lta p {
  margin: 0;
}
.previous-yy {
  border-right: 1px solid #eee;
}
.current-mm {
  border-right: 1px solid #eee;
}
.current-yy {
  border-right: 1px solid #eee;
}
.remaining-block {
  background: #eee;
  min-height: 30px;
}
.total-amount-remain {
  padding: 10px 0;
}
.benefit-border-top {
  border-top: 1px solid #eee;
  padding: 20px 0 12px 0;
}
.apply-lta .col-md-12 {
  padding: 0;
}
.apply-lta a {
  margin: 20px 0;
}
.tax-lta .col-md-12 {
  padding: 0;
}
.tax-lta .col-md-8 {
  padding: 0;
}
.tax-lta .col-md-4 {
  padding: 0;
}
.benefit-panel {
  text-align: center;
  padding: 25px 0 20px 0;
}
.benefit-modal {
  padding-left: 0 !important;
  padding-bottom: 5px !important;
  border-bottom: 1px solid #ccc;
}
.modal-margin-bottom {
  margin-bottom: 5px;
}
.modal-padd {
  padding: 10px 0;
}
.benefit-under {
  text-decoration: underline !important;
}
.benefit-row-margin {
  margin-top: 20px;
}
.benefit-img-bottom {
  margin-bottom: 7px;
}
.benefit-padd-border {
  border-bottom: 1px solid #eee;
  padding-bottom: 22px;
}
.col12-benefit .col-md-12 {
  padding-left: 0;
}
.col12-benefit .col-md-8 {
  padding-left: 0;
}
.withdraw-details {
  border: 1px solid #eee;
  min-height: 80px;
  margin-bottom: 30px;
}
.drop-dwn-pf .col-md-9 {
  padding-left: 0;
}
.drop-dwn-pf {
  background: #eee;
  padding: 10px 15px;
  min-height: 80px;
}
.guideline-benefit h5 {
  margin-bottom: 0;
  font-size: 12px;
  line-height: 1.5;
}
.col12-benefit.col-md-9 {
  padding-right: 0;
}
.date-joining-pf {
  padding: 22px;
}
.date-joining-pf h5 {
  margin-top: 0;
}
.guide-ppt {
  color: var(--primary-color);
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
}
.benefit-withdraw-h5 {
  color: var(--primary-color);
  border-bottom: 1px solid #ddd;
  font-weight: bold;
  padding-bottom: 6px;
}
.alert-benefit {
  color: #d80000;
  border-radius: 0;
}
.benefit-ol {
  padding-left: 20px;
}
.rupee-icon {
  /* background: #fff url(../images/rupee.png) no-repeat; */
  background-position: 98%;
}
.pincode-benefit {
  width: 31.5% !important;
}
.benefit-padd2 {
  margin-top: 30px;
}
.directory-avatar {
  background: #e8ecf1;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  font-size: 12px;
  font-weight: 500;
  color: #4b5c72;
  text-align: center;
  display: inline-block;
  line-height: 38px;
  vertical-align: middle;
  font-family: Roboto;
}

.selectBox {
  margin: 0;
  padding: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 101;
}
.select-dropdown {
  width: 170px;
  height: 28px;
  line-height: 28px;
  overflow: hidden;
  /* background: url(../images/dropdown.png) no-repeat right #fefefe; */
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 9;
  cursor: pointer;
}
.select-dropdown-event {
  width: 170px;
  min-height: 50px;
  line-height: 16px;
  overflow: hidden;
  /* background: url(../images/dropdown.png) no-repeat right #fefefe; */
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 100;
  cursor: pointer;
}
.selectDefault {
  padding-left: 8px;
}
.select-dropdown select:focus {
  outline: 0;
}
.structure-value {
  word-wrap: break-word;
}
.vibe-left {
  width: 19%;
  margin-left: 15px;
}
.vibe-center {
  width: 56%;
  float: left;
  padding-right: 25px;
  padding-left: 25px;
}
.vibe-right {
  width: 22.5%;
}
.organizers .directory-avatar {
  float: left;
  margin-right: 7px;
}
.org-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 120px;
}
.event-rsvp-users {
  margin-bottom: 105px;
}
.vibe-avatar {
  background: #bcbcbc;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  font-family: latolight;
  font-size: 50px;
  color: #fff;
  text-align: center;
  display: inline-block;
  line-height: 120px;
  vertical-align: middle;
}
.org-chart {
  background: #bcbcbc;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  font-family: latolight;
  font-size: 15px;
  color: #fff;
  text-align: center;
  vertical-align: middle;
}
.groupsimgchange {
  background: #bcbcbc;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-family: latolight;
  font-size: 15px;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  line-height: 30px;
  display: inline-block;
  margin-right: 8px;
}
.colh3 {
  width: 50%;
  float: left;
}
.colcaret {
  width: 50%;
  margin-top: 10px;
  float: left;
}
.caret-drop {
  width: 88%;
}
.caret-mm {
  margin: 25px 0 12px 30px;
  width: 77%;
}
.caret-yyyy {
  margin: 25px 30px 12px 0;
  width: 77%;
}
.caret-done {
  margin-right: 27px;
  margin-top: 10px;
  margin-bottom: 25px;
}
.eventtime-att-log {
  padding-left: 15px;
  margin-bottom: 5px;
  display: block;
}
.mg10 {
  margin-bottom: 10px;
}
.btn-primary {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}
/* .btn-primary-approve {
    background-color: #2CA860 !important;
    border-color: #2CA860 !important
}
.btn-primary-reject {
    background-color: #f44336 !important;
    border-color: #f44336 !important
}
.btn-secondary-approve{
    color: #2CA860 !important;
}
.btn-secondary-reject{
    color: #f44336 !important;
}
.db-btn.btn-secondary-reject:hover:not(:disabled) {
    border: 1px solid #f44336 !important;
}
.db-btn.btn-secondary-approve:hover:not(:disabled) {
    border: 1px solid #2CA860 !important;
}
.db-btn.btn-secondary-approve:active,
.db-btn.btn-secondary-approve:focus{
    border: 1px solid #2CA860 !important;
    background-color: #2CA860 !important;
}
.db-btn.btn-secondary-reject:active,
.db-btn.btn-secondary-reject:focus{
    border: 1px solid #f44336 !important;
    background-color: #f44336 !important;
} */
.btn-primary:hover {
  color: #fff;
  background-color: var(--primary-color) !important;
  opacity: 0.9;
  border-color: var(--primary-color) !important;
}
.padding-lft-rgt0 {
  padding: 0 !important;
}
.padding-lft-rgt10 {
  padding: 10px !important;
}
.form-custom option {
  background-color: #fff;
  color: #333;
}
.noti_Container {
  position: relative;
  width: 16px;
  height: 16px;
}
.noti_bubble {
  position: absolute;
  top: -1px;
  right: -9px;
  padding: 1px 2px 1px 2px;
  font-size: 12px;
}
.vibe-group-list ul li a {
  padding: 4px 10px !important;
}
.vibe-group-list ul li:last-child a {
  padding-bottom: 15px !important;
}
.sub-tab-name {
  color: var(--primary-color) !important;
}
.paddingTop0 {
  padding-top: 0;
}
.marginTopbottomh5 {
  margin-top: 5px;
  margin-bottom: 8px;
}
.messageLike + .messageLike {
  vertical-align: middle;
}
.vibe-noBorder-img {
  padding: 0;
}
.vibe-noBorder-img img {
  width: 100%;
  max-width: 200px;
  max-height: 200px;
}
.panell {
  margin-bottom: 15px;
  background-color: #fff;
  border: 1px solid transparent;
}
.panell-default {
  width: 512px;
  height: 392px;
  padding: 40px 0;
  margin: auto;
}
.panell-body {
  padding: 0 5em;
}
.panell-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 40px;
  color: #333;
  font-family: latolight;
}
.panell-heading {
  margin-bottom: 20px;
}
.body-bg {
  background-color: var(--primary-color);
}
.company-logo-login {
  margin-top: 18px;
}
.company-logo-login span {
  color: #999;
  font-size: 12px;
}
.forgot-passwrd {
  color: var(--primary-color);
  font-size: 14px;
}
.line-divide {
  border-bottom: 1px solid #ccc;
  margin: 19px 0;
}
.footer-line {
  padding-bottom: 16px;
  widh: 100%;
}
.footer-line a {
  color: #fff;
  font-size: 12px;
  cursor: pointer;
}
.btn-sign {
  padding: 10px 38px;
}
.forgot-mail {
  margin-top: 70px;
  text-align: center;
}
.back-color {
  position: relative;
  height: 95%;
}
.logo-section {
  position: absolute;
  width: 100%;
  background: #fff;
  z-index: 1;
}
.logoheader > a > img {
  max-height: 65px;
  max-width: 195px;
}
.register-email {
  margin: 65px 0 25px 0;
  text-align: center;
  font-size: 16px;
}
.register-email-final-msg {
  margin: 65px 0 25px 0;
  text-align: center;
  font-size: 16px;
  color: var(--primary-color);
}
.error-page-content {
  position: relative;
  width: 100%;
}
.error-page-block {
  position: absolute;
  top: 5em;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.error-text h1 {
  font-size: 60px;
  color: #fff;
  margin-top: 120px;
  margin-bottom: 70px;
  font-family: latolight;
}
.btn-error {
  border: 1px solid #fff;
  color: #fff;
  padding: 11px 62px;
  font-size: 20px;
}
.btn-error:hover {
  border: 1px solid #fff;
  color: #fff;
}
.error-text h2 {
  font-size: 400px;
  color: rgba(204, 204, 204, 0.2);
}
.image404 {
  margin-top: 80px;
}
.oops404 {
  font-size: 100px;
  margin-top: 80px;
}
.find404 {
  font-size: 60px;
}
.font400 {
  font-size: 400px;
  opacity: 0.2;
}
.btn404 {
  position: absolute;
  margin-top: 20%;
  z-index: 9999;
  margin-left: -11%;
  padding: 5px 25px;
  font-size: 20px;
}
.login-paddingTop {
  padding-top: 150px;
}
.footer-padding-top {
  padding-top: 92px;
}
.generate-input .search-field input {
  height: 40px;
}
.right-margin0 {
  margin-right: 0;
}
.grp-members-list {
  padding-left: 0;
}
.grp-members-list li {
  margin-top: 16px;
  list-style-type: none;
}
.grp-members-list li .directory-avatar {
  background: #bcbcbc;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-family: latolight;
  font-size: 15px;
  color: #fff;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  line-height: 30px;
  margin-right: 8px;
}
.no-margin-form-group .form-group {
  margin-left: 0;
  margin-right: 0;
}
.popover.fade.in {
  background: #fff;
  color: #4b5c72 !important;
  border-radius: 3px;
  border: none;
  font-family: "roboto";
  z-index: 9999;
}
.popover.top .arrow::after {
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
  display: none;
}
.popover.top > .arrow {
  border-top-color: #ffffff;
}
.popover.top .popover-content {
  padding: 2px !important;
}
.popover.top > .arrow {
  border-top-color: #fff !important;
}
.tooltip.fade.show {
  background: var(--primary-color) !important;
  padding: 2px !important;
  border-radius: 5px;
}
.tooltip.bottom .tooltip-arrow {
  border-bottom-color: var(--primary-color) !important;
  top: -5px !important;
}
.tooltip-inner {
  background: #fff;
}
.tooltip-inner a {
  color: #fff !important;
}
.ui-autocomp-people {
  border-bottom: 1px solid #ccc;
  padding: 4px;
}
.autocomp-item {
  margin-bottom: 5px !important;
  margin-left: 4px !important;
  margin-top: 5px !important;
}
.autocomp-item span {
  font-size: 13px;
  padding: 4px;
  display: inline-block;
}
.autocomp-item .directory-avatar {
  width: 25px;
  height: 25px;
  padding-top: 3px;
}
.autocomp-item a {
  cursor: pointer;
}
.dash-att span {
  display: block;
}
.message_reply_body_container .mention {
  color: var(--primary-color);
}
.marginBottom-clocked {
  margin-bottom: 5px;
}
#salary-widget-dash {
  margin-top: 20px;
}
#salary-widget-dash td {
  padding: 5px;
}
.reimb-textarea {
  width: 49%;
}
.leave-textarea {
  width: 65%;
}
.message-file-list li {
  border: 0;
  padding: 10px 0;
}
.message-file-list li:hover {
  background: transparent;
}
.payroll-label-data {
  background: #fff;
}
.payroll-label-data a {
  color: var(--primary-color);
}
.people-comments.message-replies-container .directory-avatar {
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
}
.font12 {
  font-size: 12px;
  word-wrap: break-word;
}
.hover-no-border {
  border: 0;
}
.custom-reports .modal-dialog {
  width: 1000px;
}
.custom-heading {
  margin-top: 0;
  color: #777;
}
.dragdroph {
  color: var(--primary-color);
}
.welldrop {
  border: 1px dashed #aaa;
}
input[type="text"].reports-dates {
  color: #fff !important;
}
.chzn-container-multi {
  width: 100% !important;
}
.chzn-drop {
  width: 100% !important;
}
.search-field input[type="text"] {
  width: 100% !important;
}
.errorMessage,
.errorSummary,
.register-email {
  color: #f44336;
  margin-top: 7px;
}
.colmd3 {
  word-wrap: break-word !important;
}
.leave_response {
  margin-top: 15px;
}
.tooltip {
  max-width: 275px !important;
  word-wrap: break-word !important;
}
.leave-overflow {
  min-height: 500px;
  max-height: 500px;
  overflow: auto;
}
.module-heading {
  font-size: 26px;
  margin-bottom: 20px;
  color: #acacac;
  font-family: latolight;
}
.module-switch p {
  margin-bottom: 35px;
  color: #acacac;
}
/* .switch {
    margin-top: 40px
} */
.cmn-toggle {
  position: absolute;
  margin-left: -9999px;
  visibility: hidden;
}
.cmn-toggle + label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
input.cmn-toggle-round-flat + label {
  padding: 2px;
  width: 50px;
  height: 30px;
  background-color: #ddd;
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  -ms-border-radius: 60px;
  -o-border-radius: 60px;
  border-radius: 60px;
  -webkit-transition: background 0.4s;
  -moz-transition: background 0.4s;
  -o-transition: background 0.4s;
  transition: background 0.4s;
}
input.cmn-toggle-round-flat + label:before,
input.cmn-toggle-round-flat + label:after {
  display: block;
  position: absolute;
  content: "";
}
input.cmn-toggle-round-flat + label:before {
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  background-color: #ddd;
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  -ms-border-radius: 60px;
  -o-border-radius: 60px;
  border-radius: 60px;
  -webkit-transition: background 0.4s;
  -moz-transition: background 0.4s;
  -o-transition: background 0.4s;
  transition: background 0.4s;
}
input.cmn-toggle-round-flat + label:after {
  top: 4px;
  left: 4px;
  bottom: 4px;
  width: 22px;
  background-color: #fff;
  -webkit-border-radius: 52px;
  -moz-border-radius: 52px;
  -ms-border-radius: 52px;
  -o-border-radius: 52px;
  border-radius: 52px;
  -webkit-transition: margin 0.4s, background 0.4s;
  -moz-transition: margin 0.4s, background 0.4s;
  -o-transition: margin 0.4s, background 0.4s;
  transition: margin 0.4s, background 0.4s;
}
input.cmn-toggle-round-flat:checked + label {
  background-color: var(--primary-color);
}
input.cmn-toggle-round-flat:checked + label:after {
  margin-left: 22px;
  background-color: #fff;
}
input.cmn-toggle-round-flat:checked + label:before {
  background-color: var(--primary-color);
}
.expenses-heading .col-sm-8 {
  padding-left: 0;
  padding-right: 0;
}
#expense_actions .col-md-6 {
  padding-left: 0;
  padding-right: 0;
}
.btn {
  display: inline-block;
  padding: 4px 20px;
  font-weight: bold !important;
  white-space: nowrap;
  border-radius: 0;
}
.btn-default {
  color: #1676e2 !important; /* Backup colour for IE */
  border-color: #1676e2; /* Backup colour for IE */
}
.btn-default {
  color: var(--primary-color) !important;
  font-weight: bold !important;
  border-color: var(--primary-color);
  background: rgba(255, 255, 255, 0);
}
.btn-default:hover {
  color: #fff !important;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color);
}
.badge {
  min-width: 20px;
  height: 20px;
  line-height: 16px;
  padding: 2px !important;
  border-radius: 2px !important;
  margin-right: 10px;
}
.db-badge {
  background: #f44336;
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  height: 8px;
  width: 8px;
  border-radius: 50%;
}

.btn-sm {
  padding: 5px 15px !important;
  border-radius: 0 !important;
  font-size: 12px !important;
  line-height: 1.5 !important;
  box-shadow: none;
}
.form-group {
  position: relative;
}
.alert {
  margin-bottom: 15px;
}
.form-group .select-sm.search-select.searchabledropdown.db-dropdown {
  padding: 5px 10px !important;
  padding-right: 30px !important;
  height: 30px !important;
  min-height: 30px !important;
  font-size: 14px !important;
}
.form-group .select-sm.search-select.searchabledropdown.db-dropdown .search {
  padding: 5px 10px !important;
  padding-right: 30px !important;
}
.label {
  font-size: 14px !important;
  font-weight: 100 !important;
}
.form-select {
  height: 40px;
}
.form-control {
  height: 36px;
  color: #555;
  border: 1px solid #e6e6e6;
  border-radius: 0 !important;
  transition: none !important;
}
 
.btn-primary {
  color: #fff !important;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}
.btn-primary:hover {
  color: #fff !important;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}
.btn-erp {
  color: #666 !important;
  background-color: #eee !important;
  border-color: #ccc !important;
}
.btn-erp:hover {
  color: #fff !important;
  background-color: #777 !important;
  border-color: #777 !important;
}
table.dataTable thead .sorting_desc {
  background-position-y: calc(50% - 5px) !important;
}
.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  line-height: 24px !important;
  vertical-align: middle !important;
  border-top: 1px solid #eee;
  font-size: 14px !important;
  border-left: 1px solid #eee !important;
}
table > tbody > tr > td:first-child,
table > tbody > tr > th:first-child,
table > tfoot > tr > td:first-child,
table > tfoot > tr > th:first-child,
table > thead > tr > td:first-child,
table > thead > tr > th:first-child {
  border-left: 0 !important;
}
.table-bordered > tbody > tr > td:first-child,
.table-bordered > tbody > tr > th:first-child,
.table-bordered > tfoot > tr > td:first-child,
.table-bordered > tfoot > tr > th:first-child,
.table-bordered > thead > tr > td:first-child,
.table-bordered > thead > tr > th:first-child {
  border-left: 1px solid #eee !important;
}
.table > thead:first-child > tr:first-child > th {
  boder-top: 1px solid #ddd;
}
.table > thead > tr > th:first-child {
  border-left: 0 !important;
}
.table > thead > tr > th {
  font-size: 12px !important;
  color: #b3aeae;
  font-weight: bold !important;
  padding: 2px 12px !important;
}
.table > tbody > tr > td {
  padding: 12px;
}
.no-of-likes {
  vertical-align: middle;
}
.tags-middle {
  vertical-align: middle;
}
.comment-middle {
  vertical-align: middle;
}
.unlike-middle {
  vertical-align: middle;
}
.btn-erp-calendar {
  padding: 9px 20px !important;
}
.emoticons-main li.active {
  background: #ff7f00;
}
.dropdown-width {
  text-align: center;
}
.number {
  font-size: 26px;
}
.febfont {
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
}
.dropdown-menu-width {
  min-width: 160px;
  font-size: 15px;
  box-shadow: none !important;
  margin-top: 10px;
}
.btndiv {
  text-align: center;
}
.mapicon-color {
  color: #ff8000;
  width: 13px;
  height: 20px;
  margin-right: 10px;
}
.date {
  min-width: 130px;
  text-align: center;
  border-bottom-style: none;
}
.checkin-edit {
  margin-top: 25px;
  display: none;
  position: absolute;
  right: 156px;
}
.checkin-hover:hover .checkin-edit {
  display: block;
  z-index: 999;
  color: #333;
}
.checkin-hover:hover a.checkin-location {
  text-decoration: underline;
}
.dropdown-menu-width:before {
  content: "";
  border-bottom: 10px solid #ccc;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  position: absolute;
  top: -10px;
  right: 98px;
  z-index: 10;
  left: 39px;
}
.dropdown-menu-width:after {
  content: "";
  border-bottom: 13px solid #fff;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  position: absolute;
  top: -10px;
  right: 98px;
  z-index: 10;
}
.emp-details-current {
  float: left;
  width: 20%;
  word-break: normal;
}
.job-desc {
  margin-top: 36px;
}
.currentjob-desc {
  background: #fff;
  padding: 30px 0;
  font-size: 14px;
}
.currentjob-desc ul.main-desc {
  margin: 0;
  padding: 0;
  justify-content: center;
}
.currentjob-desc ul li {
  list-style: disc;
}
.currentjob-desc ol li {
  list-style: decimal;
}
.currentjob-desc ul li ul li {
  list-style: disc;
}
.border-right-none {
  border-right: none !important;
}
.main-navigation1 {
  border-bottom: 5px solid var(--primary-color);
}
.main-navigation1 .col-md-2 {
  padding-left: 0;
}
.logo1 a img {
  width: 150px;
}
.shift-section3 {
  padding: 10px 20px 20px 20px;
  background: #f7f8f8;
  margin: 10px 0;
}
a.job-opening {
  color: var(--primary-color);
}
a.job-opening:hover {
  text-decoration: underline;
}
.job-opening-title {
  font-size: 30px;
}
.table-job-current td a {
  color: #666;
}
.jobcurrent-title {
  color: var(--primary-color) !important;
}
.jobcurrent-title:hover {
  text-decoration: underline;
}
.back-darwinbox {
  margin-top: 47px;
}
.thanks-job {
  padding-left: 10px;
}
.jobopening-search {
  color: var(--primary-color);
}
.stagethree ul li {
  position: relative;
}
.new-cand {
  width: 80px;
  height: 80px;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 50%;
  margin: 0 54% 30px;
}
.stage0:after {
  top: 80px !important;
  left: 20% !important;
}
.stageFinal:after {
  height: 62px !important;
}
.stage:after {
  content: "";
  position: absolute;
  background: transparent none repeat scroll 0 0;
  width: 1px;
  left: -40px;
  right: 0;
  margin: auto;
  border-left: 1px solid var(--primary-color);
  top: 51px;
  height: 30px;
}
.interview-hired {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background: rgba(16, 206, 19, 0.25);
  line-height: 90px;
  display: inline-block;
  color: #333;
}
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  background-color: #eee;
  border: 1px solid #eee !important;
  color: #333 !important;
  background-image: none;
  box-shadow: none;
}
.sort-js ul li {
  list-style: none;
  margin: 20px 0 30px !important;
  padding: 14px !important;
  height: auto !important;
  font-size: 14px !important;
  min-height: 52px;
}
.sort-js {
  margin: 20px 0 10px 0 !important;
}
.ui-state-default:hover {
  background-color: rgba(1, 115, 197, 0.2) !important;
}
#sortable {
  width: 100% !important;
  padding-left: 40px !important;
}
.interview-type p {
  font-size: 18px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.hover-interview-type {
  display: none;
}
.interview-type:hover .hover-interview-type {
  display: block;
}
.hover-interview-type i {
  cursor: pointer;
}
.interview-span {
  margin-top: 5px;
}
.cog-setting {
  display: none;
}
.candidate-tab tr:hover .cog-setting {
  display: block;
}
a.cog-setting:hover {
  color: var(--primary-color) !important;
}
@media (max-width: 420px) {
  .shift-section3 {
    padding: 10px 0 20px 0;
    background: #f7f8f8;
    margin: 10px 0;
  }
}
.job-expand-drp {
  padding: 5px 15px;
}
.job-expand-drpmenu {
  width: 98% !important;
  top: 26px !important;
  left: 0;
  text-align: center;
}
.job-expand-drpmenu li a {
  padding: 8px 10px !important;
  margin: 0 !important;
}
.hiring-lead {
  margin-bottom: 10px;
  min-height: 40px;
  float: left;
  min-width: 130px;
}
.expand-job .col-md-12 {
  padding-left: 0;
  padding-right: 0;
}
.expand-job .col-md-8 {
  padding-left: 0;
  padding-right: 0;
}
.expand-job .col-md-7 {
  padding-left: 0;
  padding-right: 0;
}
.expand-job .col-md-5 {
  padding-left: 0;
  padding-right: 0;
}
.expand-job .col-md-4 {
  padding-left: 0;
}
.expand-job .col-md-2 {
  padding-left: 0;
  padding-right: 0;
}
.expand-job .col-md-10 {
  padding-left: 0;
  padding-right: 0;
}
.expand-jobopening td a {
  color: #333;
}
.expand-jobopening tr:hover .cog-setting {
  display: block;
  color: var(--primary-color) !important;
}
.hiring-steps {
  width: 18.65%;
  float: left;
  margin-right: 16px;
  margin-bottom: 10px;
  padding: 12px 0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  color: #445;
  border: 1px solid #eee;
}
.hiring-steps:hover {
  background: #fafafa;
  color: #333;
}
a.hiring-steps-block {
  font-size: 30px;
  font-family: latolight;
}
.app-file i {
  font-size: 18px;
}
.shift-sec3 {
  padding: 1px 0;
}
.emp-details-bg {
  background: #f7f8f8;
  padding: 10px 0;
  min-height: 60px;
}
.wrapper-content,
.wrapper-content1 {
  padding: 20px 0 60px 0px;
  min-height: 355px;
}
tr.job-status-head > td {
  padding: 3px 12px !important;
}
.expand-candidate-name a {
  color: var(--primary-color) !important;
}
.left-hand-resume {
  width: 70%;
  float: left;
}
.right-hand-application {
  width: 30%;
  float: left;
}
.select-resume {
  border: 2px dotted #aaa;
  padding: 20px;
}
.emp-details-phone {
  float: left;
  width: 15%;
  word-break: normal;
}
.emp-details-email {
  float: left;
  width: 25%;
  word-break: normal;
}
.btn-cand-status {
  padding: 1px 4px;
  margin-top: -10px;
  font-size: 10px;
}
.stars i {
  color: #ffbe01;
  font-size: 20px;
}
.colcaret-job {
  width: 100%;
  margin-top: 10px;
}
.job-drop {
  padding: 15px;
  margin-top: 15px;
}
.job-drop:before {
  content: "";
  border-bottom: 10px solid #ccc;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  position: absolute;
  top: -10px;
  right: 48px;
  z-index: 10;
}
.job-drop:after {
  content: "";
  border-bottom: 13px solid #fff;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  position: absolute;
  top: -10px;
  right: 48px;
  z-index: 10;
}
.job-app1 {
  margin-right: 50px;
}
.mynotes-position {
  position: relative;
}
.mynotes-btn {
  position: absolute;
  bottom: 34px;
  right: 20px;
}
.recruitment-above,
.helpdesk-above {
  border-top: 1px solid #eee;
}
.border-left1 {
  border-left: 1px solid #eee;
}
.search-job1 a {
  vertical-align: middle;
  line-height: 33px;
}
.margin-vertical {
  margin: 1px;
}
.recruitment-add {
  padding: 7px 0;
}
.recruit-filter {
  height: 33px;
  border: none !important;
}
.recruit-filter:focus {
  border: 0;
  box-shadow: none;
}
.search-job-recruit {
  vertical-align: middle;
  line-height: 35px;
}
.form-group-recruit {
  padding: 10px;
  border: 1px solid #eee;
  background: #fafafa;
}
.employee-comment {
  min-height: 50px;
  padding: 10px 0;
  background-color: #f7f8f8;
  border-bottom: 1px solid #ddd;
  margin: 0 18px;
}
.recruit-share {
  margin-top: 10px;
}
.recruit-textarea {
  width: 86%;
  margin-bottom: 10px;
}
.directory-avatar-follow {
  display: inline-block !important;
  background: #bcbcbc;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  font-family: latolight;
  font-size: 12px;
  color: #fff;
  text-align: center;
  display: inline-block;
  line-height: 25px;
  vertical-align: middle;
}
.circle-btn-recruit {
  width: 50px;
  height: 50px;
  border: 1px solid green;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  line-height: 58px;
  vertical-align: middle;
  color: green;
  margin-bottom: 10px;
}
.set-input-width {
  width: 40px;
}
.set-table-input-width input {
  width: 40px;
}
.bottom {
  margin-bottom: 20px;
}
.btn-small-circle {
  width: 25px;
  height: 25px;
  text-align: center;
  padding: 7px 0;
  font-size: 11px;
  line-height: 9px;
  border-radius: 50%;
  position: absolute;
  background: #fff;
  border: 1px solid #eee;
  color: #333;
}
.btn-small-circle1 {
  width: 18px;
  height: 18px;
  text-align: center;
  padding: 5px 0;
  font-size: 8px;
  line-height: 0;
  border-radius: 50%;
  position: absolute;
  background: #fff;
  border: 1px solid #eee;
  color: #333;
}
.small-width {
  min-width: 50px !important;
}
.small-width1 {
  min-width: 25px !important;
}
.color-green {
  color: green;
  border-color: green;
}
.color-orange {
  color: orange;
  border-color: orange;
}
.color-red {
  color: red;
  border-color: red;
}
.stepwizard-row1 {
  display: table-row;
}
.stepwizard-row1:before {
  top: 26px;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 41%;
  left: 16%;
  height: 1px;
  background-color: #ccc;
}
.extra-row1:before {
  top: 28px;
  bottom: 0;
  position: absolute;
  content: " ";
  /*width: 60%;*/
  left: 16%;
  height: 1px;
  background-color: #ccc;
}
.stepwizard-row2 {
  display: table-row;
}
.stepwizard-row2:before {
  top: 26px;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 41%;
  left: 13%;
  height: 1px;
  background-color: #eee;
}
.height {
  height: 14px !important;
}
.background-green {
  background: green !important;
}
.new-process-head {
  font-size: 20px !important;
  color: #000 !important;
}
.icon-ul {
  width: 100%;
  text-align: center;
  margin-top: 30px;
  margin-left: 0 !important;
}
.icon-ul li {
  width: 20%;
}
.padding {
  padding: 20px;
}
.pms-height {
  min-height: 176px;
}
.padding1 {
  padding: 10px;
}
.padding5 {
  padding: 5px;
}
.add-icon .btn {
  padding: 4px 11px !important;
}
.no-padd {
  padding: 0 !important;
}
.font-small {
  font-size: 13px !important;
}
.font-small1 {
  font-size: 18px !important;
}
.mar {
  margin: 0 !important;
}
.appaisal-row:before {
  top: 19px;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 70%;
  left: 13%;
  height: 1px;
  background-color: #eee;
  z-order: 0;
}
.pms-stepwizard:before {
  left: 13%;
  width: 70%;
  top: 20px;
}
.progress-goal {
  height: 5px !important;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: #eee;
  border-radius: 4px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}
.one {
  position: absolute;
  margin-top: -3px;
  z-index: 1;
  height: 10px;
  width: 10px;
  border-radius: 25px;
}
.two {
  position: absolute;
  margin-top: -3px;
  z-index: 1;
  height: 10px;
  width: 10px;
  border-radius: 25px;
}
.three {
  position: absolute;
  margin-top: -3px;
  z-index: 1;
  height: 10px;
  width: 10px;
  border-radius: 25px;
}
.four {
  position: absolute;
  margin-top: -3px;
  z-index: 1;
  height: 10px;
  width: 10px;
  border-radius: 25px;
}
.one {
  left: 25%;
}
.two {
  left: 50%;
}
.three {
  left: 75%;
}
.primary-color {
  background-color: #4989bd;
}
.success-color {
  background-color: #5cb85c;
}
.danger-color {
  background-color: #d9534f;
}
.warning-color {
  background-color: #f0ad4e;
}
.info-color {
  background-color: #5bc0de;
}
.no-color {
  background-color: inherit;
}
.goal-post {
  border: 1px solid #ddd;
  min-height: 60px;
}
.goal-post:before {
  content: "";
  border-bottom: 10px solid #ccc;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  position: absolute;
  z-index: 10;
  margin-top: -10px;
  left: 128px;
}
.goal-post:after {
  content: "";
  border-bottom: 8px solid #fff;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  position: absolute;
  z-index: 10;
  margin-top: -128px;
  left: 130px;
}
.goal-post1 {
  border: 1px solid #ddd;
  min-height: 60px;
}
.goal-post1:before {
  content: "";
  border-bottom: 10px solid #ccc;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  position: absolute;
  z-index: 10;
  margin-top: -21px;
  left: 128px;
}
.goal-post1:after {
  content: "";
  border-bottom: 8px solid #fff;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  position: absolute;
  z-index: 10;
  margin-top: -143px;
  left: 130px;
}
.my-notes-border {
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
}
.note-btn {
  padding-left: 10px;
}
.organizers-modal:before {
  content: "";
  position: absolute;
  height: Calc(100% - 148px);
  background: #ccc;
  width: 1px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 0;
  margin-top: 15px;
}
.organizers-modal.even-peers:before {
  content: "";
  position: absolute;
  height: Calc(100% - 262px);
  background: #ccc;
  width: 1px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 0;
  margin-top: 15px;
}
.progress-bar-blue {
  background: var(--primary-color);
}
.progress-bar-green {
  background: green !important;
}
.progress-bar-orange {
  background: #f0ad4e;
}
.progress-profile {
  height: 8px;
  overflow: hidden;
  background-color: #fff;
  border: 1px solid var(--primary-color);
}
.progress-profile1 {
  height: 15px;
  overflow: hidden;
  background-color: #fff;
  border: 1px solid gray;
}
.progress-profile2 {
  height: 15px;
  overflow: hidden;
  border: 1px solid #f0ad4e;
}
.progress-profile4 {
  height: 15px;
  overflow: hidden;
  border: 1px solid green;
}
.progress-profile3 {
  height: 15px;
  overflow: hidden;
  background-color: #fff;
  border: 1px solid var(--primary-color);
}
h6.profile-complete {
  color: var(--primary-color);
  margin-bottom: 5px;
}
h6.profile-complete1 {
  margin-bottom: 5px;
}
h6.profile-complete2 {
  margin-bottom: 5px;
}
h6.profile-complete3 {
  margin-bottom: 5px;
}
h6.profile-complete4 {
  margin-bottom: 5px;
}
.arrow-img {
  width: 15px;
  margin-top: -5px;
}
.progress-profile6 {
  width: 200px;
  overflow: hidden;
  border: 1px solid green;
  background-color: #fff;
}
.mynotes-position {
  position: relative;
}
.mynotes-btn {
  position: absolute;
  bottom: 34px;
  right: 20px;
}
.job-posting-row:before {
  width: 68% !important;
}
.competency-block-hover {
  display: none;
  background: rgba(51, 51, 51, 0.8);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.competency-div {
  position: relative;
}
.competency-div:hover .competency-block-hover {
  display: block;
}
.competency-block-hover a {
  padding: 0 10px;
  color: #fff;
  font-size: 20px;
}
.competency-block-hover {
  padding: 85px;
}
/*.btn-breadcrumb .btn:not(:last-child):after {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    border-top: 32px solid transparent;
    border-bottom: 30px solid transparent;
    border-left: 10px solid white;
    position: absolute;
    margin-top: -51px;
    left: 100%;
    z-index: 3
}
.btn-breadcrumb .btn:not(:last-child):before {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    border-top: 43px solid transparent;
    border-bottom: 40px solid transparent;
    border-left: 10px solid #adadad;
    position: absolute;
    margin-top: -22px;
    margin-left: 1px;
    left: 100%;
    z-index: 3
}
.btn-breadcrumb .btn {
    padding: 6px 12px 6px 24px
}
.btn-breadcrumb .btn:first-child {
    padding: 6px 6px 6px 10px
}
.btn-breadcrumb .btn:last-child {
    padding: 6px 18px 6px 24px
}
.btn-breadcrumb .btn.btn-default:not(:last-child):after {
    border-left: 10px solid #fff
}
.btn-breadcrumb .btn.btn-default:not(:last-child):before {
    border-left: 10px solid #ccc
}
.btn-breadcrumb .btn.btn-default:hover:not(:last-child):after {
    border-left: 10px solid #ebebeb
}
.btn-breadcrumb .btn.btn-default:hover:not(:last-child):before {
    border-left: 10px solid #adadad
}
.btn-breadcrumb .btn.btn-primary:not(:last-child):after {
    border-left: 10px solid var(--primary-color)
}
.btn-breadcrumb .btn.btn-primary:not(:last-child):before {
    border-left: 11px solid #fff
}
.btn-breadcrumb .btn.btn-primary:hover:not(:last-child):after {
    border-left: 10px solid var(--primary-color)
}
.btn-breadcrumb .btn.btn-primary:hover:not(:last-child):before {
    border-left: 11px solid #fff
}
.btn-bread {
    padding: 10px 20px !important;
    border-top: 0 !important;
    border-bottom: 0 !important;
    min-width: 180px;
    max-width: 200px;
    text-overflow: ellipsis
}
.btn-breadcrumb .btn.btn-erp:not(:last-child):after {
    border-left: 10px solid #eee
}
.btn-breadcrumb .btn.btn-erp:not(:last-child):before {
    border-left: 11px solid #fff
}
.btn-breadcrumb .btn.btn-erp:hover:not(:last-child):after {
    border-left: 10px solid #777
}
.btn-breadcrumb .btn.btn-erp:hover:not(:last-child):before {
    border-left: 11px solid #fff
    }*/
.self-view-comment {
  text-align: justify;
}
/*.drop-here-reports {
    margin-top: 40%
    }*/
.drop-from-reports {
  border-left: 1px solid #e8ecf1;
}
li.search-field {
  width: 400px;
}
.border_left_right_txtbx {
  border-left: 0;
  border-right: 0;
  padding: 8px;
}
.marginlft8 {
  margin-left: 8px;
}
.btn-twitter {
  background-color: #00acee;
  border-color: #00acee;
  color: #fff;
}
.btn-facebook {
  background-color: #3b5998;
  border-color: #3b5998;
  color: #fff;
}
.btn-linkedin {
  background-color: #0e76a8;
  border-color: #0e76a8;
  color: #fff;
}
.paddingtopbtm10 {
  padding: 10px 0;
}
.fnt-size12 {
  font-size: 12px !important;
}
.margintop10 {
  margin-top: 10px;
}
.margintop20 {
  margin-top: 20px;
}
.margintop40 {
  margin-top: 40px;
}
.margintop12 {
  margin-top: 12px;
}
.marginlft10 {
  margin-left: 10px;
}
.cand-img span i {
  margin-top: 10px;
  font-size: 40px;
  color: #999;
  margin-left: 14px;
}
.hired-final i {
  margin-top: 10px;
  font-size: 40px;
  margin-left: 5px;
  color: #999;
}
.modal-xlg {
  width: 80%;
}
.carousel-control.left {
  background-image: none;
}
.carousel-control.right {
  background-image: none;
}
.slide-item .col-md-8 {
  padding-left: 0;
}
.slide-item .col-md-4 {
  padding-right: 0;
}
.resume-type {
  height: 100%;
  width: 100%;
}
.resume-type img {
  width: 100%;
  height: 700px;
}
.resume-overflow {
  max-height: 682px;
  overflow: auto;
}
.glyph-modal {
  color: #333;
}
.carousal-width {
  width: 3%;
}
.btn-green {
  color: #4cae4c;
  border-color: #4cae4c;
}
.btn-green:hover {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}
.bulk-menu_rec::before {
  border-bottom: 10px solid #ccc;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  content: "";
  position: absolute;
  right: 22px;
  top: -9px;
  z-index: 10;
}
.bulk-menu_rec::after {
  border-bottom: 13px solid #fff;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  content: "";
  position: absolute;
  right: 22px;
  top: -9px;
  z-index: 10;
}
.bulk-menu_rec {
  top: 143%;
  right: -17px;
  width: auto;
  left: auto;
}
.step-job-wizard::before {
  left: 22% !important;
}
.step-job-wizard {
  text-align: right;
}
.padding0 {
  padding: 0;
}
.padding-top0 {
  padding-top: 0;
}
.inline-heading {
  display: inline;
  margin-right: 10px;
}
.schedule-btn {
  background: #fff;
  color: var(--primary-color);
}
.marginlft20 {
  margin-left: 20px;
}
.paddingtb6 {
  padding-bottom: 6px;
  padding-top: 6px;
}
.fontlight {
  font-weight: lighter !important;
}
.marginbottom20 {
  margin-bottom: 20px;
}
.marginbottom10 {
  margin-bottom: 10px;
}
.email-cand {
  padding-left: 5px !important;
}
.margintb5 {
  margin: 5px 0;
}
.displayInline {
  display: inline;
}
.displayblock {
  display: block;
}
.positionRelative {
  position: relative;
}
.positionStatic {
  position: static !important;
}
.positionAbsolute {
  position: absolute;
  z-index: 1;
}
.top-right-10 {
  top: 10px;
  right: 10px;
}
.top-0-right-10 {
  top: 0;
  right: 10px;
}
.top-right-5 {
  top: 5px;
  right: 5px;
}
.rec_stages .stepwizard-step {
  min-width: 170px !important;
}
.cand-stages-rec .interview-type {
  left: 20% !important;
  width: 500px !important;
}
.stage span {
  left: 4%;
  top: 4%;
}
a.nish {
  cursor: pointer;
}
.widget-left .span12 {
  margin-left: 45px !important;
}
.dash-att .eventtime-att-log {
  padding-left: 0;
  color: #666;
}
.hiring-steps h4 {
  font-size: 14px;
  color: #445 !important;
}
.expand-job-icon a {
  border: 1px solid #777;
  border-radius: 50%;
  color: #777;
  display: inline-block;
  font-size: 13px;
  height: 30px;
  line-height: 21px;
  padding: 3px 5px 5px;
  margin-right: 5px;
  text-align: center;
  width: 30px;
}
.expand-job-icon a:hover {
  border-color: var(--primary-color);
  color: var(--primary-color);
}
.nish {
  cursor: pointer;
  margin-left: 83.3%;
  width: 19% !important;
}
.padding10 {
  padding: 10px;
}
.screener-modal-profile .profile-details {
  padding: 10px 0px 15px 15px !important;
  word-break: break-all;
  display: block;
  max-height: 275px;
  overflow: auto;
  direction: rtl;
}
.screener-modal .modal-content {
  padding: 0 !important;
  position: relative !important;
}
.screener-modal .modal-header {
  border-bottom: 0 !important;
  padding: 0 !important;
  right: 10px !important;
  position: absolute !important;
}
.screener-modal .modal-header .close {
  margin-right: auto;
  margin-top: auto;
}
.input-group label {
  color: #888;
}
input.form-control {
  color: #000 !important;
  font-size: 14px;
  border-radius: 2px !important;
  border: 1px solid #dbe1e8 !important;
  color: #353f4c !important;
  -webkit-text-fill-color: inherit;
  box-shadow: none !important;
  caret-color: #1676e2;
  background: #fff;
  transition: all 0.5s ease;
}
    .drop-from-reports {
        border-left: 1px solid #e8ecf1;
    }
    li.search-field {
        width: 400px
    }
    .border_left_right_txtbx {
        border-left: 0;
        border-right: 0;
        padding: 8px
    }
    .marginlft8 {
        margin-left: 8px
    }
    .btn-twitter {
        background-color: #00acee;
        border-color: #00acee;
        color: #fff
    }
    .btn-facebook {
        background-color: #3b5998;
        border-color: #3b5998;
        color: #fff
    }
    .btn-linkedin {
        background-color: #0e76a8;
        border-color: #0e76a8;
        color: #fff
    }
    .paddingtopbtm10 {
        padding: 10px 0
    }
    .fnt-size12 {
        font-size: 12px !important;
    }
    .margintop10 {
        margin-top: 10px
    }
    .margintop20 {
        margin-top: 20px
    }
    .margintop40 {
        margin-top: 40px
    }
    .margintop12 {
        margin-top: 12px
    }
    .marginlft10 {
        margin-left: 10px
    }
    .cand-img span i {
        margin-top: 10px;
        font-size: 40px;
        color: #999;
        margin-left: 14px
    }
    .hired-final i {
        margin-top: 10px;
        font-size: 40px;
        margin-left: 5px;
        color: #999
    }
    .modal-xlg {
        width: 80%
    }
    .carousel-control.left {
        background-image: none
    }
    .carousel-control.right {
        background-image: none
    }
    .slide-item .col-md-8 {
        padding-left: 0
    }
    .slide-item .col-md-4 {
        padding-right: 0
    }
    .resume-type {
        height: 100%;
        width: 100%
    }
    .resume-type img {
        width: 100%;
        height: 700px
    }
    .resume-overflow {
        max-height: 682px;
        overflow: auto
    }
    .glyph-modal {
        color: #333
    }
    .carousal-width {
        width: 3%
    }
    .btn-green {
        color: #4cae4c;
        border-color: #4cae4c
    }
    .btn-green:hover {
        color: #fff;
        background-color: #5cb85c;
        border-color: #4cae4c
    }
    .bulk-menu_rec::before {
        border-bottom: 10px solid #ccc;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        content: "";
        position: absolute;
        right: 22px;
        top: -9px;
        z-index: 10
    }
    .bulk-menu_rec::after {
        border-bottom: 13px solid #fff;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        content: "";
        position: absolute;
        right: 22px;
        top: -9px;
        z-index: 10
    }
    .bulk-menu_rec {
        top: 143%;
        right: -17px;
        width: auto;
        left: auto
    }
    .step-job-wizard::before {
        left: 22% !important
    }
    .step-job-wizard {
        text-align: right
    }
    .padding0 {
        padding: 0
    }
    .padding-top0 {
        padding-top: 0
    }
    .inline-heading {
        display: inline;
        margin-right: 10px
    }
    .schedule-btn {
        background: #fff;
        color: var(--primary-color)
    }
    .marginlft20 {
        margin-left: 20px
    }
    .paddingtb6 {
        padding-bottom: 6px;
        padding-top: 6px
    }
    .fontlight {
        font-weight: lighter !important
    }
    .marginbottom20 {
        margin-bottom: 20px
    }
    .marginbottom10 {
        margin-bottom: 10px
    }
    .email-cand {
        padding-left: 5px !important
    }
    .margintb5 {
        margin: 5px 0
    }
    .displayInline {
        display: inline
    }
    .displayblock {
        display: block;
    }
    .positionRelative {
        position: relative
    }
    .positionStatic {
        position: static !important;
    }
    .positionAbsolute {
        position: absolute;
        z-index: 1;
    }
    .top-right-10{
        top: 10px;
        right: 10px;
    }
    .top-0-right-10 {
        top: 0;
        right: 10px;
    }
    .top-right-5{
        top: 5px;
        right: 5px;
    }
    .rec_stages .stepwizard-step {
        min-width: 170px !important
    }
    .cand-stages-rec .interview-type {
        left: 20% !important;
        width: 500px !important
    }
    .stage span {
        left: 4%;
        top: 4%
    }
    a.nish {
        cursor: pointer
    }
    .widget-left .span12 {
        margin-left: 45px !important
    }
    .dash-att .eventtime-att-log {
        padding-left: 0;
        color: #666
    }
    .hiring-steps h4 {
        font-size: 14px;
        color: #445 !important
    }
    .expand-job-icon a {
        border: 1px solid #777;
        border-radius: 50%;
        color: #777;
        display: inline-block;
        font-size: 13px;
        height: 30px;
        line-height: 21px;
        padding: 3px 5px 5px;
        margin-right: 5px;
        text-align: center;
        width: 30px
    }
    .expand-job-icon a:hover {
        border-color: var(--primary-color);
        color: var(--primary-color)
    }
    .nish {
        cursor: pointer;
        margin-left: 83.3%;
        width: 19% !important;
    }
    .padding10 {
        padding: 10px
    }
    .screener-modal-profile .profile-details {
        padding: 10px 0px 15px 15px !important;
        word-break: break-all;
        display: block;
        max-height: 275px;
        overflow: auto;
        direction: rtl;
    }
    .screener-modal .modal-content {
        padding: 0 !important;
        position: relative !important
    }
    .screener-modal .modal-header {
        border-bottom: 0 !important;
        padding: 0 !important;
        right: 10px !important;
        position: absolute !important
    }
    .screener-modal .modal-header .close {
        margin-right: auto;
        margin-top: auto
    }
    .input-group label {
        color: #888;
    }
  
    .col-recruit {
        position: relative
    }
    .previously-sent-to {
        color: var(--primary-color);
        position: absolute;
        top: 36px;
        left: 0;
        font-size: 12px
    }
    .previously-sent-to:hover {
        text-decoration: underline
    }
    .hover-interview-type a {
        border: 1px solid #777;
        border-radius: 50%;
        color: #777;
        display: inline-block;
        font-size: 13px;
        height: 22px;
        line-height: 18px;
        margin-right: 5px;
        text-align: center;
        width: 22px
    }
    .hover-interview-type a:hover {
        border-color: var(--primary-color);
        color: var(--primary-color)
    }
    .stage-trash {
        margin: 0
    }
    .hover-interview-type i {
        margin-left: 2px;
        margin-top: 4px
    }
    .joom {
        margin-left: 10px
    }
    .evaluate-name {
        color: #666
    }
    .rating {
        border: 0;
        float: left
    }
    .rating>input {
        display: none
    }
    .rating>label:before {
        margin: 5px;
        font-size: 1.25em;
        font-family: FontAwesome;
        display: inline-block;
        content: "\f005"
    }
    .reviewer_comments {
        width: 100%
    }
    .rating>.half:before {
        content: "\f089";
        position: absolute
    }
    .rating>label {
        color: #888;
        float: right;
        cursor: pointer
    }
    .rating>input:checked ~ label,
    .rating:not(:checked)>label:hover,
    .rating:not(:checked)>label:hover ~ label {
        color: #888
    }
    .rating>input:checked+label:hover,
    .rating>input:checked ~ label:hover,
    .rating>label:hover ~ input:checked ~ label,
    .rating>input:checked ~ label:hover ~ label,
    .rating>input:checked ~ label:active ~ label {
        color: #ffd700 !important
    }
    .marginright60 {
        margin-right: 60px
    }
    .marginright20 {
        margin-right: 20px
    }
    .marginleft20 {
        margin-left: 20px
    }
    .invitation-textarea {
        min-height: 280px
    }
    .paddingtop10 {
        padding-top: 10px
    }
    #referral-comment {
        font-size: 22px;
        color: var(--primary-color) !important
    }
    .lineHeight {
        line-height: 1.5
    }
    .label {
        border-radius: 0
    }
    input[type="checkbox"],
    input[type="radio"] {
        margin-right: 10px
    }
    ul.fileicon-list {
        margin: 0;
        padding: 0
    }
    #message_poll_options {
        margin-top: 10px
    }
    .messageLike:hover,
    .messageLike:focus {
        color: #333;
        text-decoration: underline
    }
    a.open_reply:hover,
    a.open_reply:focus {
        color: #333;
        text-decoration: underline
    }
    a.message_reply_tags_toggle:hover,
    a.message_reply_tags_toggle:focus {
        color: #333;
        text-decoration: underline
    }
    .modal-header {
        padding: 15px 15px 15px 0;
        display: block;
    }
    .modal-body {
        padding: 15px 0
    }
    .side-menu-head {
        padding-top: 10px
    }
    select.form-select {
        color: #999 !important
    }
    .control-label {
        margin-bottom: 12px
    }
    legend {
        font-size: 15px;
        text-transform: uppercase
    }
    #monthworkingdays_table h5 {
        line-height: 1.6
    }
    .padding-topbtm20 {
        padding: 20px 0
    }
    .payroll-model:hover {
        text-decoration: underline
    }
    .btn {
        text-transform: uppercase
    }
    .customFieldOptions textarea {
        resize: none
    }
    .vibe-delete-edit {
        display: none
    }
    .vibe-hover-content:hover .vibe-delete-edit {
        display: block
    }
    .comments-delete-icon {
        display: none
    }
    .comment-delete-hover:hover .comments-delete-icon {
        display: block
    }
    .payroll-sidecontent {
        height: 620px
    }
    .cand-status {
        background-color: var(--primary-color);
        color: #fff
    }
    .emp-det {
        margin-left: 40px
    }
    .emp-det div {
        display: inline;
        margin-right: 10px
    }
    .year-dropdown {
        width: 20%;
        display: inline
    }
    .star_hide {
        display: none
    }
    .star_show {
        display: inline;
        color: #888
    }
    .cand-emp-list a.circle-btn:focus {
        color: #fff !important;
        border: 1px solid var(--primary-color)
    }
    .thanks-job-refer {
        margin: 0;
        padding: 10px;
        color: #666
    }
    .margintop_emp {
        margin-bottom: 1%
    }
    .circle-btn>i {
        color: #777
    }
    .circle-btn:hover>i {
        color: var(--primary-color)
    }
    .autocomplete-suggestions {
        border: 1px solid #999;
        background: #FFF;
        cursor: default;
        overflow: auto;
        -webkit-box-shadow: 1px 4px 3px rgba(50, 50, 50, 0.64);
        -moz-box-shadow: 1px 4px 3px rgba(50, 50, 50, 0.64);
        box-shadow: 1px 4px 3px rgba(50, 50, 50, 0.64)
    }
    .autocomplete-suggestion {
        padding: 2px 5px;
        white-space: nowrap;
        overflow: hidden
    }
    .autocomplete-no-suggestion {
        padding: 2px 5px
    }
    .autocomplete-selected {
        background: #f0f0f0
    }
    .autocomplete-suggestions strong {
        font-weight: bold;
        color: #000
    }
    .autocomplete-group {
        padding: 2px 5px
    }
    .autocomplete-group strong {
        font-weight: bold;
        font-size: 16px;
        color: #000;
        display: block;
        border-bottom: 1px solid #000
    }
    ._sree_dropdown_menu>input {
        font-size: 14px;
        padding: 5px;
        border: 1px solid #CCC;
        display: block
    }
    ._sree_dropdown_menu>li>a {
        padding: 0 14px;
        color: #999
    }
    ._sree_auto_list>.dropdown-menu>li>a:focus,
    .dropdown-menu>li>a:hover {
        color: #fff;
        background-color: var(--primary-color)
    }
    ._sree_dropdown_menu .divider {
        margin: 4px 0
    }
    ._sree_modal>.modal-dialog {
        position: relative;
        display: table; overflow-y:auto;overflow-x:auto;width:auto;min-width:300px}
        .autocomplete-suggestions{min-width:200px !important; width: auto !important; }
        .four-rows::before{width: 65% !important;left:12% !important;}
        input[type="search"], .search {
            /* border-radius: 0px !important;
            border: 1px solid #B9B9B9;
            padding: 7px; */
          }
            .totalLeave th{font-size: 14px !important}
            .boxActions-dropdown{
                width: 35% !important;
            }
            .padding-left-0{
              padding-left: 0;
          }
          .padding-right-10{
              padding-right: 10px;
          }
          .padding-left-right-10{
              padding-left: 10px;
              padding-right: 10px;
          }
          .padding-right-0{
              padding-right: 0;
          }
          .padding-top-20{
              padding-top: 20px;
          }
          .padding-left-10{
              padding-left: 10px;
          }
          .padding-left-30{
              padding-left: 30px;
          }
          .padding-left-35{
            padding-left: 35px;
        }
        .padding-left-25{
            padding-left: 25px;
        }
        .padding-left-50{
            padding-left: 50px;
        }
        .padding-top-btm-0{
          padding-top: 0;
          padding-bottom: 0;
      }
      .padding-left-right-12 {
        padding-left: 12px;
        padding-right: 12px;
    }
    .padding-bottom-10{
      padding-bottom: 10px;
  }
  .btn-hover-border:hover{
      border: solid 1px var(--primary-color) !important;
  }
  .task-tiles-box{
      width: 30.65%;
      float: left;
      margin-right: 16px;
      margin-bottom: 20px;
      padding: 12px 0;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.20);
      color: #445;
      border: 1px solid #eee;
      height: 110px;
  }
  .task-tiles-box h4{
      font-family: latolight;
      font-size: 30px;
      font-weight: normal;
  }

.col-recruit {
  position: relative;
}
.previously-sent-to {
  color: var(--primary-color);
  position: absolute;
  top: 36px;
  left: 0;
  font-size: 12px;
}
.previously-sent-to:hover {
  text-decoration: underline;
}
.hover-interview-type a {
  border: 1px solid #777;
  border-radius: 50%;
  color: #777;
  display: inline-block;
  font-size: 13px;
  height: 22px;
  line-height: 18px;
  margin-right: 5px;
  text-align: center;
  width: 22px;
}
.hover-interview-type a:hover {
  border-color: var(--primary-color);
  color: var(--primary-color);
}
.stage-trash {
  margin: 0;
}
.hover-interview-type i {
  margin-left: 2px;
  margin-top: 4px;
}
.joom {
  margin-left: 10px;
}
.evaluate-name {
  color: #666;
}
.rating {
  border: 0;
  float: left;
}
.rating > input {
  display: none;
}
.rating > label:before {
  margin: 5px;
  font-size: 1.25em;
  font-family: FontAwesome;
  display: inline-block;
  content: "\f005";
}
.reviewer_comments {
  width: 100%;
}
.rating > .half:before {
  content: "\f089";
  position: absolute;
}
.rating > label {
  color: #888;
  float: right;
  cursor: pointer;
}
.rating > input:checked ~ label,
.rating:not(:checked) > label:hover,
.rating:not(:checked) > label:hover ~ label {
  color: #888;
}
.rating > input:checked + label:hover,
.rating > input:checked ~ label:hover,
.rating > label:hover ~ input:checked ~ label,
.rating > input:checked ~ label:hover ~ label,
.rating > input:checked ~ label:active ~ label {
  color: #ffd700 !important;
}
.marginright60 {
  margin-right: 60px;
}
.marginright20 {
  margin-right: 20px;
}
.marginleft20 {
  margin-left: 20px;
}
.invitation-textarea {
  min-height: 280px;
}
.paddingtop10 {
  padding-top: 10px;
}
#referral-comment {
  font-size: 22px;
  color: var(--primary-color) !important;
}
.lineHeight {
  line-height: 1.5;
}
.label {
  border-radius: 0;
}
input[type="checkbox"],
input[type="radio"] {
  margin-right: 10px;
}
ul.fileicon-list {
  margin: 0;
  padding: 0;
}
#message_poll_options {
  margin-top: 10px;
}
.messageLike:hover,
.messageLike:focus {
  color: #333;
  text-decoration: underline;
}
a.open_reply:hover,
a.open_reply:focus {
  color: #333;
  text-decoration: underline;
}
a.message_reply_tags_toggle:hover,
a.message_reply_tags_toggle:focus {
  color: #333;
  text-decoration: underline;
}
.modal-header {
  padding: 15px 15px 15px 0;
  display: block;
}
.modal-body {
  padding: 15px 0;
}
.side-menu-head {
  padding-top: 10px;
}
select.form-select {
  color: #999 !important;
}
.control-label {
  margin-bottom: 12px;
}
legend {
  font-size: 15px;
  text-transform: uppercase;
}
#monthworkingdays_table h5 {
  line-height: 1.6;
}
.padding-topbtm20 {
  padding: 20px 0;
}
.payroll-model:hover {
  text-decoration: underline;
}
.btn {
  text-transform: uppercase;
}
.customFieldOptions textarea {
  resize: none;
}
.vibe-delete-edit {
  display: none;
}
.vibe-hover-content:hover .vibe-delete-edit {
  display: block;
}
.comments-delete-icon {
  display: none;
}
.comment-delete-hover:hover .comments-delete-icon {
  display: block;
}
.payroll-sidecontent {
  height: 620px;
}
.cand-status {
  background-color: var(--primary-color);
  color: #fff;
}
.emp-det {
  margin-left: 40px;
}
.emp-det div {
  display: inline;
  margin-right: 10px;
}
.year-dropdown {
  width: 20%;
  display: inline;
}
.star_hide {
  display: none;
}
.star_show {
  display: inline;
  color: #888;
}
.cand-emp-list a.circle-btn:focus {
  color: #fff !important;
  border: 1px solid var(--primary-color);
}
.thanks-job-refer {
  margin: 0;
  padding: 10px;
  color: #666;
}
.margintop_emp {
  margin-bottom: 1%;
}
.circle-btn > i {
  color: #777;
}
.circle-btn:hover > i {
  color: var(--primary-color);
}
.autocomplete-suggestions {
  border: 1px solid #999;
  background: #fff;
  cursor: default;
  overflow: auto;
  -webkit-box-shadow: 1px 4px 3px rgba(50, 50, 50, 0.64);
  -moz-box-shadow: 1px 4px 3px rgba(50, 50, 50, 0.64);
  box-shadow: 1px 4px 3px rgba(50, 50, 50, 0.64);
}
.autocomplete-suggestion {
  padding: 2px 5px;
  white-space: nowrap;
  overflow: hidden;
}
.autocomplete-no-suggestion {
  padding: 2px 5px;
}
.autocomplete-selected {
  background: #f0f0f0;
}
.autocomplete-suggestions strong {
  font-weight: bold;
  color: #000;
}
.autocomplete-group {
  padding: 2px 5px;
}
.autocomplete-group strong {
  font-weight: bold;
  font-size: 16px;
  color: #000;
  display: block;
  border-bottom: 1px solid #000;
}
._sree_dropdown_menu > input {
  font-size: 14px;
  padding: 5px;
  border: 1px solid #ccc;
  display: block;
}
._sree_dropdown_menu > li > a {
  padding: 0 14px;
  color: #999;
}
._sree_auto_list > .dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover {
  color: #fff;
  background-color: var(--primary-color);
}
._sree_dropdown_menu .divider {
  margin: 4px 0;
}
._sree_modal > .modal-dialog {
  position: relative;
  display: table;
  overflow-y: auto;
  overflow-x: auto;
  width: auto;
  min-width: 300px;
}
.autocomplete-suggestions {
  min-width: 200px !important;
  width: auto !important;
}
.four-rows::before {
  width: 65% !important;
  left: 12% !important;
}
input[type="search"],
.search {
  /* border-radius: 0px !important;
  border: 1px solid #b9b9b9;
  padding: 7px; */
}
.totalLeave th {
  font-size: 14px !important;
}
.boxActions-dropdown {
  width: 35% !important;
}
.padding-left-0 {
  padding-left: 0;
}
.padding-right-10 {
  padding-right: 10px;
}
.padding-left-right-10 {
  padding-left: 10px;
  padding-right: 10px;
}
.padding-right-0 {
  padding-right: 0;
}
.padding-top-20 {
  padding-top: 20px;
}
.padding-left-10 {
  padding-left: 10px;
}
.padding-left-30 {
  padding-left: 30px;
}
.padding-left-35 {
  padding-left: 35px;
}
.padding-left-25 {
  padding-left: 25px;
}
.padding-left-50 {
  padding-left: 50px;
}
.padding-top-btm-0 {
  padding-top: 0;
  padding-bottom: 0;
}
.padding-left-right-12 {
  padding-left: 12px;
  padding-right: 12px;
}
.padding-bottom-10 {
  padding-bottom: 10px;
}
.btn-hover-border:hover {
  border: solid 1px var(--primary-color) !important;
}
.task-tiles-box {
  width: 30.65%;
  float: left;
  margin-right: 16px;
  margin-bottom: 20px;
  padding: 12px 0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  color: #445;
  border: 1px solid #eee;
  height: 110px;
}
.task-tiles-box h4 {
  font-family: latolight;
  font-size: 30px;
  font-weight: normal;
}

.task-num-color-grey {
  color: #aaa;
}
.task-num-color-red {
  color: red;
}
.task-num-color-green {
  color: green;
}
.task-num-color-blue {
  color: var(--primary-color);
}
a#attendance-logger-widget {
  position: relative !important;
  z-index: 900 !important;
}

.tiles-task-title {
  color: rgb(1, 115, 197);
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}
.margin-right-5 {
  margin-right: 5px;
}
.circle-btn-3 {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  font-size: 13px;
  line-height: 20.5px;
  text-align: center;
  display: inline-block;
  color: #777;
  border: 1px solid #777;
  margin-right: 12px;
  margin-left: 12px;
}
.height-tile-214 {
  height: 214px;
}
.border-none {
  border: 0 !important;
}
.date-label-left {
  text-align: left;
  padding-left: 25px;
  font-family: latoregular;
  color: #aaa;
}
.date-border {
  padding: 5px;
  border: 1px solid #efefef;
  margin-bottom: 10px;
  text-align: center;
}
.padding-top-45 {
  padding-top: 45px;
}
.margin-top-0 {
  margin-top: 0px;
}
.margin-top-5 {
  margin-top: 5px;
}
.padding-left-20 {
  padding-left: 20px;
}
.padding-left-40 {
  padding-left: 40px;
}
.margin-top-25 {
  margin-top: 25px;
}
.padding-left-10 {
  padding-left: 10px !important;
}
.stepwizard-margin {
  margin-top: 40px;
  margin-bottom: 20px;
}
/*Breadcrumb Style*/
.btn-breadcrumb .btn:not(:last-child):after {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
  border-left: 10px solid var(--primary-color);
  position: absolute;
  top: 50%;
  margin-top: -17px;
  left: 100%;
  z-index: 3;
}
.btn-breadcrumb .btn:not(:last-child):before {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
  border-left: 10px solid var(--primary-color);
  position: absolute;
  top: 50%;
  margin-top: -17px;
  margin-left: 1px;
  left: 100%;
  z-index: 3;
}
.btn-breadcrumb .btn.last-child-arrow:after {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
  border-left: 10px solid var(--primary-color);
  position: absolute;
  top: 50%;
  margin-top: -17px;
  left: 100%;
  z-index: 3;
}
.btn-breadcrumb .btn.last-child-arrow:before {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
  border-left: 10px solid var(--primary-color);
  position: absolute;
  top: 50%;
  margin-top: -17px;
  margin-left: 1px;
  left: 100%;
  z-index: 3;
}
.btn-breadcrumb .btn.last-child-arrow {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-breadcrumb .btn {
  padding: 6px 12px 6px 24px;
  border-right: 2px solid transparent;
}
.btn-breadcrumb .btn:first-child {
  padding: 6px 6px 6px 10px;
}
.btn-breadcrumb .btn:last-child {
  padding: 6px 18px 6px 24px;
}
/** Default button **/
.btn-breadcrumb .btn.btn-default:after {
  border-left: 10px solid white;
}
.btn-breadcrumb .btn.btn-default:before {
  border-left: 10px solid var(--primary-color);
}
.btn-breadcrumb .btn.btn-default:hover:after {
  border-left: 10px solid var(--primary-color);
}
.btn-breadcrumb .btn.btn-default:hover:before {
  border-left: 10px solid var(--primary-color);
}
.btn-breadcrumb .btn.btn-default.bread-active-crumb {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: #fff !important;
  font-size: 13px;
}
.btn-breadcrumb .btn.btn-default.bread-active-crumb:after {
  border-left: 10px solid var(--primary-color);
}
.btn-breadcrumb .btn.btn-default.bread-active-crumb:before {
  border-left: 10px solid var(--primary-color);
}

/* The responsive part */
.btn-breadcrumb > * > div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.btn-breadcrumb > *:nth-child(n + 2) {
  display: none;
}
.btn-breadcrumb > *:nth-last-child(-n + 6) {
  display: block;
}
.btn-breadcrumb > * div {
  max-width: 170px;
}
.btn-breadcrumb .btn.btn-default.btn-dbl-line-bread-crumb:after,
.btn-breadcrumb .btn.btn-default.btn-dbl-line-bread-crumb:before {
  border-top: 27px solid transparent;
  border-bottom: 27px solid transparent;
  margin-top: -27px;
}
.btn-breadcrumb .btn.btn-default.btn-dbl-line-bread-crumb {
  padding: 6px 30px 6px 30px;
}
.btn-breadcrumb .btn.btn-default.btn-dbl-line-bread-crumb.bread-crumb-4 {
  padding: 6px 25px 6px 25px;
}
.border-color-blue {
  border-color: var(--primary-color);
}
.font-size-13 {
  font-size: 13px;
}
.margin-center-horizontal {
  margin-left: auto;
  margin-right: auto;
}
.text-align-center {
  text-align: center;
}
/*Changes For Reports Selecet Styles*/
.selector-section {
  background-color: var(--primary-color);
  padding-top: 20px;
  padding-left: 40px;
  padding-bottom: 40px;
}
.dropdown-label {
  color: #544646 !important;
}
.dropdown-label-report {
  color: #fff !important;
  font-size: 16px !important;
  font-weight: bold;
}
.report-options {
  background-color: #eee;
}
.report-section {
  background-color: #fff;
}
.form-select-style {
  appearance: none !important;
  -moz-appearance: none !important; /* Firefox */
  -webkit-appearance: none !important; /* Safari and Chrome */
  /* background: url(../images/dropdown.png) no-repeat !important; */
  /*background-position-x: 10px;*/
  background-position: 97% 50% !important;
  padding-right: 30px !important;
}
.form-custom-report-design {
  background-color: #fff !important;
  border: 1px solid #333;
  color: #333 !important;
}
.form-custom-report-design:hover {
  border: 1px solid #333 !important;
}
.form-custom-report-design:active {
  box-shadow: none !important;
}
.form-custom-report-design:focus {
  box-shadow: none !important;
  border: 1px solid #333 !important;
}
/*Coming Soon Header*/
.coming-soon-header {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: times, Times New Roman, times-roman, georgia, serif;
  font-size: 72px;
  line-height: 40px;
  letter-spacing: -1px;
  color: #444;
  margin: 0;
  padding: 0;
}
/*Styles For Reports Date Picker*/
.reports-date-style {
  padding-left: 15px !important;
  background-color: #fff !important;
}

.chosen-container.chosen-container-single {
  width: 190px !important;
}
.form-custom-report-design {
  background-color: #fff !important;
  border: 1px solid #333;
  color: #333 !important;
}
.form-custom-report-design:hover {
  border: 1px solid #333 !important;
}
.form-custom-report-design:active {
  box-shadow: none !important;
}
.form-custom-report-design:focus {
  box-shadow: none !important;
  border: 1px solid #333 !important;
}
/*Coming Soon Header*/
.coming-soon-header {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: times, Times New Roman, times-roman, georgia, serif;
  font-size: 72px;
  line-height: 40px;
  letter-spacing: -1px;
  color: #444;
  margin: 0;
  padding: 0;
}
/*Styles For Reports Date Picker*/
.reports-date-style {
  padding-left: 15px !important;
  background-color: #fff !important;
}
.chosen-container.chosen-container-multi {
  width: 250px !important;
}
.resize-none {
  resize: none;
}
.resize-vertical {
  resize: vertical;
}
.form-label-color {
  color: #888;
}
.margin-top-10 {
  margin-top: 10px;
}
/*Styles for star rating*/
.cont {
  width: 176px;
  /*max-width: 350px;*/
  text-align: center;
  /*margin: 4% auto;*/
  padding: 0 0;
  /*background: #f5f5f5;*/
  color: #eee;
  border-radius: 5px;
  overflow: hidden;
  /*box-shadow: 0px 2px 5px #aaa;*/
}
div.stars {
  min-width: 20px;
  display: inline-block;
}
input.star {
  display: none;
}
label.star {
  float: right;
  /*padding: 5px;*/
  padding: 5px 5px 0 5px;
  font-size: 23px;
  color: #444;
  transition: all 0.2s;
  margin-bottom: 0;
}
input.star:checked ~ label.star:before {
  content: "\f005";
  color: var(--primary-color);
  transition: all 0.25s;
}
input.star-5:checked ~ label.star:before {
  color: var(--primary-color);
}
input.star-1:checked ~ label.star:before {
  color: var(--primary-color);
}
label.star:hover {
  transform: rotate(-15deg) scale(1.3);
}
label.star:before {
  content: "\f006";
  font-family: FontAwesome;
}
/* Accordian Styles*/
.accordion,
.accordion * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.accordion {
  overflow: hidden;
  /*box-shadow:0px 1px 3px rgba(0,0,0,0.25);*/
  border-radius: 3px;
  background: #fcfcfc;
}

/*----- Section Titles -----*/
.accordion-section-title {
  width: 100%;
  padding: 10px 15px;
  display: inline-block;
  border-bottom: 1px solid #fff;
  background: #ffffff;
  transition: all linear 0.15s;
  /* Type */
  font-size: 1.2em;
  /*  text-shadow:0px 1px 0px #1a1a1a;*/
  color: #757575;
}
.accordion-section-title > span {
  font-family: "latolight";
}

.accordion-section-title.active,
.accordion-section-title:hover {
  background: #f4f4f4;
  color: #333;
  /* Type */
  text-decoration: none;
}
.accordion-section {
  border-bottom: 1px solid #e1e1e1;
}
.accordion-section:last-child .accordion-section-title {
  border-bottom: none;
}

/*----- Section Content -----*/
.accordion-section-content {
  padding: 15px;
  display: none;
  color: #a0a0a0;
  /*border: 1px solid #eee;
    border-bottom: none;*/
}
.accordion-section-content h4 {
  font-size: 16px;
}
.bs-callout {
  /*    padding: 20px;*/
  padding: 5px 15px;
  /*margin: 10px 0;*/
  /*border: 1px solid #eee;*/
  /*border-left-width: 5px;*/
  border-radius: 3px;
  /*background-color: #eee;*/
}

.vdivide::after {
  background: #eee;
  width: 1px;
  height: 122px;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  min-height: 70px;
}
.vdivide.top30::after {
  top: 30px;
}
.vdivide.top23n::after {
  top: -23px;
}
.vdivide.top20::after {
  top: 20px;
}
.vdivide.top15::after {
  top: 20px;
}
.vdivide.top53n::after {
  top: -53px;
}
.margin-18-auto {
  margin: 18px auto;
}
.text-align-justify {
  text-align: justify;
}
.chosen-choices {
  overflow-y: auto !important;
  /*max-width: 560px;*/
  resize: horizontal;
  max-height: 150px;
  z-index: 5;
}
#tenant_signing_settings_chosen {
  width: 350px !important;
}
#tenant_leaves_settings_cc_chosen {
  width: 350px !important;
}
#TenantLeavesCompoff_valid_for_chosen {
  width: 285px !important;
}
#exculde_from_payroll_chosen,
#advance_tax_chosen {
  width: 400px !important;
}
#useradminrights_chosen {
  width: 100% !important;
  margin-right: 15px;
}
#parent_dept_load_chosen,
#state_load_chosen,
#companies_multi_chosen,
#notice_for_chosen,
#week_days_chosen,
#permission_group_restrictions_chosen,
#permission_group_users_chosen,
#designation_add_chosen,
#assign_to_modal_chosen,
#TenantCompetencyMapping_assign_to_chosen,
#exclude_users_chosen,
#exclude_users_modal_chosen,
#TenantPmsAssign_designation_chosen,
#assign_modal_chosen,
#WorkFlowForm_assign_to_chosen,
#generate_for_chosen,
#sel_filter_chosen {
  width: 100% !important;
}
.text-align-left {
  text-align: left !important;
}
.tooltiptext {
  visibility: hidden;
  min-width: 120px;
  width: auto;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  position: absolute;
  z-index: 1;
}
.org-name:hover .tooltiptext {
  visibility: visible;
}
.tr-bg-f1 {
  background-color: #f1f1f1;
}
.site-color-imp {
  color: var(--primary-color) !important;
}
.circle-btn-2 {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  display: inline-block;
  color: #777;
  border: 1px solid #777;
  margin-right: 8px;
  cursor: pointer;
}
.circle-btn-2-green {
  color: #46c646;
  border: 1px solid #46c646;
}
.circle-btn-2-red {
  color: #f44336;
  border: 1px solid #f44336;
}
.height-30 {
  height: 30px;
}
.font-size-16 {
  font-size: 16px;
}
.font-size-15 {
  font-size: 15px;
}
.font-size-13 {
  font-size: 13px;
}
.border-eee {
  border: 1px solid #eee !important;
}
.border-right-eee {
  border-right: 1px solid #eee !important;
}
.padding-15 {
  padding: 15px;
}
.font-size-30 {
  font-family: latolight;
  font-size: 30px;
}
.margin-top-20 {
  margin-top: 20px;
}
.margin-top-25 {
  margin-top: 25px;
}
.margin-bottom-10 {
  margin-bottom: 10px;
}
.margin-bottom-20 {
  margin-bottom: 20px;
}
.total_comments .directory-avatar {
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.height-full {
  height: 100%;
}
.br-theme-bars-pill .br-widget {
  white-space: nowrap;
  border-radius: 20px;
  border: solid 1px #caf2db;
  display: -webkit-inline-box;
  display: inline-block;
}
.br-theme-bars-pill .br-widget a {
  padding: 4px 8px;
  background: #e8f9ef;
  color: #4b5c72;
  text-decoration: none;
  font-size: 13px;
  line-height: 1.4;
  text-align: center;
  font-weight: 400;
  width: 36px;
  height: 25px;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 600;
  text-transform: uppercase;
}
a.br-selected ~ a {
  background-color: #7cead1;
  color: white;
}
.br-theme-bars-pill .br-widget a:first-child {
  -webkit-border-top-left-radius: 99px;
  /*-webkit-border-bottom-left-radius:099px;*/
  -moz-border-radius-topleft: 99px;
  -moz-border-radius-bottomleft: 99px;
  border-top-left-radius: 99px;
  border-bottom-left-radius: 99px;
}
.br-theme-bars-pill .br-widget a:last-child {
  -webkit-border-top-right-radius: 99px;
  -webkit-border-bottom-right-radius: 99px;
  -moz-border-radius-topright: 99px;
  -moz-border-radius-bottomright: 99px;
  border-top-right-radius: 99px;
  border-bottom-right-radius: 99px;
}
.br-theme-bars-pill .br-widget a.br-active,
.br-theme-bars-pill .br-widget a.br-selected {
  position: relative;
  z-index: 9;
}

.br-theme-bars-pill .br-widget a.br-active:after,
.br-theme-bars-pill .br-widget a.br-selected:after {
  position: absolute;
  content: "";
  width: 36px;
  height: 36px;
  background: #fff;
  top: -6px;
  left: 0px;
  z-index: -1;
  border-radius: 50%;
  box-shadow: 0 2px 6px 0 rgba(42, 51, 62, 0.2), 0 0 1px 0 rgba(42, 51, 62, 0.3);
  box-shadow: 0 2px 6px 0 var(--dark-20), 0 0 1px 0 rgba(42, 51, 62, 0.3);
  background-color: #ffffff;
  border: solid 1px #1ac964;
}

.br-theme-bars-pill .br-readonly a {
  cursor: default;
}
.br-theme-bars-pill .br-readonly a.br-active,
.br-theme-bars-pill .br-readonly a.br-selected {
  background-color: #7cead1;
}
.bar-pill-txt {
  display: block;
  font-size: 12px;
  color: #1ac964;
  font-weight: 500;
  margin-top: 10px;
}
.btn-floating {
  display: inline-block;
  color: var(--primary-color);
  position: relative;
  overflow: hidden;
  z-index: 1;
  width: 40px;
  height: 40px;
  line-height: 40px;
  padding: 0;
  background-color: #fff;
  border-radius: 50%;
  transition: 0.3s;
  cursor: pointer;
  vertical-align: middle;
  text-align: center;
}
.btn-floating {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
}
.yellow {
  background-color: #ffeb3b !important;
}
.yellow.darken-1 {
  background-color: #fdd835 !important;
}
.red {
  background-color: #f44336 !important;
}
.blue {
  background-color: var(--third-blue) !important;
}
.green {
  /* background-color: #4CAF50 !important; */
}
.fixed-box {
  display: inline-block;
  position: fixed;
  z-index: 1010;
  bottom: 150px;
  right: 25px;
}
.btn-breadcrumb .btn.btn-default:hover {
  color: #fff !important;
  background-color: #ccc !important;
  border-color: var(--primary-color);
}
.btn-breadcrumb .btn.btn-default:hover::before {
  border-left: 10px solid var(--primary-color);
}
.btn-breadcrumb .btn.btn-default:hover::after {
  border-left: 10px solid #ccc;
}
a.pagination-button {
  color: black;
  float: left;
  padding: 2px 12px;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 1px solid #ddd;
  font-size: 22px;
  border-radius: 2px;
}
a.pagination-button:hover:not(.active) {
  background-color: #ddd;
}
/*CONTINIOUS FEEDBACK CHANGES*/
div.skill_rating {
  border: 1px solid #eee;
  padding: 10px 5px 6px;
}
#skill_modal_chosen {
  width: 100% !important;
}
.resize-vertical {
  resize: vertical;
}
/*CONTINIOUS FEEDBACK CHANGES*/
.row-height {
  display: table;
  table-layout: fixed;
  height: 100%;
  width: 100%;
}
.col-height {
  display: table-cell;
  float: none;
  height: 100%;
}
.col-top {
  vertical-align: top;
}
.col-middle {
  vertical-align: middle;
}
.col-bottom {
  vertical-align: bottom;
}
.text-elipsis {
  text-overflow: ellipsis;
}
.text-left {
  text-align: left !important;
}
.margin-top-bottom-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}
/*.avatar-small .directory-avatar{
    width: 25px;
    height: 25px;
    padding-top: 3px; 
    }*/
.wizard-small-row-element-1:before {
  width: 0 !important;
}
.wizard-small-row-element-2:before {
  width: 20% !important;
}
.wizard-small-row-element-3:before {
  width: 40% !important;
}
.wizard-small-row-element-4:before {
  width: 60% !important;
}
.padding-right-15 {
  padding-right: 15px;
}
.margin-bottom-15 {
  margin-bottom: 15px;
}
.color-blue-circle {
  color: var(--primary-color);
  border-color: var(--primary-color);
}
.collection {
  margin: 0.5rem 0 1rem 0;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
}
.collection .collection-item {
  background-color: #fff;
  line-height: 1.5rem;
  padding: 10px 20px;
  margin: 0;
}
.collection .collection-item {
  padding-right: 50px;
  position: relative;
}
.collection .collection-item .secondary-content {
  position: absolute;
  top: 16px;
  right: 16px;
}
.collection .collection-item .secondary-content2 {
  position: absolute;
  top: 10px;
  right: 16px;
}
.secondary-content,
.secondary-content2 {
  float: right;
}
.collection .collection-item:last-child {
  border-bottom: none;
}
.collection .collection-item p {
  margin: 0;
  color: rgba(0, 0, 0, 0.71);
  padding: 0;
  font-size: 15px;
}
.collection .collection-item .title {
  font-size: 15px;
}
.site-color-orange {
  /*color:  #ff5722;*/
  color: #26a69a;
}
.cursor-pointer {
  cursor: pointer;
}
.margin-bottom30 {
  margin-bottom: 30px;
}
.feedback-list {
  padding: 10px;
  min-height: 80px;
}
.rating-number {
  width: 40px;
  /*float: right;*/
  border-radius: 2px;
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  padding: 3px;
}
.rating-number i {
  font-size: 16px;
  opacity: 0.5;
}
.level-1 {
  background: #ff7043;
  border-color: #ff7043;
}
.level-2 {
  background: #ffca28;
  border-color: #ffca28;
}
.level-3 {
  background: #9ccc65;
  border-color: #9ccc65;
}
.secondary-content .total-rating {
  display: block;
  margin-top: 2px;
  color: #aaa;
  font-size: 12px;
}
.feedback-header {
  font-size: 1.2em;
  color: #979797;
}
.msg-category {
  font-size: 14px;
  margin-top: 4px;
  margin-bottom: 12px;
}
.color-grey {
  color: #aaa;
}
.ribbon-wrapper-green {
  width: 85px;
  height: 88px;
  overflow: hidden;
  position: absolute;
  top: -3px;
  right: -3px;
  z-index: 10;
}
.ribbon-green {
  font: 14px "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #fff;
  text-align: center;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  position: relative;
  padding: 7px 0;
  left: -5px;
  top: 15px;
  width: 120px;
}
.comment-color {
  color: #c0c0c0;
}
.center-div {
  float: none;
  margin: auto;
}
.add-btn {
  color: #7d7d7d;
}
.add-btn:hover {
  color: var(--primary-color);
}
/*styles for rating momdal*/
li {
  list-style: none;
}
li.list-style-disc {
  list-style: disc !important;
}
.rating-bar-section .rating-li.cursor-pointer {
  cursor: pointer;
}
.rating-bar-section .rating-li {
  padding: 1px 0 0;
}
.rating-bar-section .rating-number-star {
  text-align: center;
}
.rating-bar-section .rating-number-star .rating-scale {
  font-size: 12px;
  font-weight: 500;
  color: #212121;
  padding: 2px 0;
}
.rating-bar-section .rating-number-star .rating-star-new {
  font-size: 12px;
  color: #212121;
  padding: 0 0 0 2px;
}
.rating-bar-new {
  height: 5px;
  position: relative;
  background: #f0f0f0;
  margin-top: 7px;
  margin-left: 7px;
}
.rating-bar-9,
.rating-bar-10 {
  background-color: #66bb6a;
}
.rating-bar-7,
.rating-bar-8 {
  background-color: #9ccc65;
}
.rating-bar-5,
.rating-bar-6 {
  background-color: #ffca28;
}
.rating-bar-3,
.rating-bar-4 {
  background-color: #ff9800;
}
.rating-bar-1,
.rating-bar-2 {
  background-color: #ff7043;
}
.rating-bar-new .progress-bar-new {
  left: 0;
  position: absolute;
  width: 0;
  height: 5px;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: left center;
  transform-origin: left center;
  transition: -webkit-transform 0.4s cubic-bezier(0, 0, 0.3, 1) 0.3s;
  transition: transform 0.4s cubic-bezier(0, 0, 0.3, 1) 0.3s;
  transition: transform 0.4s cubic-bezier(0, 0, 0.3, 1) 0.3s,
    -webkit-transform 0.4s cubic-bezier(0, 0, 0.3, 1) 0.3s;
}
.rating-bar-section .total-rating-number {
  font-size: 12px;
  color: #878787;
  padding: 0 0 0 12px;
}
.rating-bar-section .rating-li.cursor-pointer {
  cursor: pointer;
}
.rating-bar-section .rating-li {
  padding: 1px 0 0;
}
.rating-bar-section .rating-li {
  padding: 1px 0 0;
}
.rating-bar-section .rating-number-star {
  text-align: center;
}
.rating-bar-section .rating-number-star .rating-scale {
  font-size: 12px;
  font-weight: 500;
  color: #212121;
  padding: 2px 0;
}
.rating-num-big,
.rating-star-big {
  display: inline-block;
  color: #212121;
}
.rating-num-big {
  font-size: 32px;
  margin: 4px 0 6px;
  font-weight: 500;
}
.rating-star-big {
  font-size: 28px;
  padding: 0 0 0 4px;
  position: relative;
  top: -3px;
}
.rating-num-big,
.rating-star-big {
  display: inline-block;
  color: #212121;
}
.rating-total-big {
  font-size: 14px;
  color: #878787;
  width: 100%;
  padding: 7px 0 0;
  font-weight: 500;
}
.rating-li div {
  padding-left: 0;
  padding-right: 0;
}
.comment-section {
  padding: 15px 8px;
  width: 100%;
  -webkit-filter: none !important;
}
.comment-section .E_uFuv {
  margin-top: 3px;
}
.comment-rating-badge {
  line-height: normal;
  display: inline-block;
  color: #fff;
  padding: 2px 4px;
  border-radius: 3px;
  font-weight: 500;
  font-size: 12px;
  vertical-align: middle;
}
.comment-section .comment-header {
  padding-left: 12px;
  padding-top: 3px;
  font-size: 14px;
  color: #212121;
  font-weight: 500;
}
.comment-section .comment-footer,
.comment-section ._19inI8 {
  font-size: 12px;
  color: #878787;
  padding: 0 6px 0 0;
}
.modal-comments {
  line-height: 1.4;
  font-size: 14px;
  color: #212121;
  margin: 0 0 5px 0;
  overflow: hidden;
  text-align: justify;
}
/*.comment-section ._2pclJg {
    position: relative;
    }*/
/*styles for rating momdal*/
.dataTables_scrollBody thead {
  display: none !important;
}
.info-icon {
  margin-left: 8px;
  font-size: 16px;
  color: #7e7e7e;
  position: relative;
  top: -4px;
}
.imgSpan.employee-id ~ a {
  margin-left: 4px;
}
.imgSpan.employee-id img {
  float: none !important;
}

/*style for reimbursement*/
/*@font-face {
    font-family: 'Lato-Black';
    src: url('../fonts/Lato-Black/Lato-Black.eot?#iefix') format('embedded-opentype'), url('../fonts/Lato-Black/Lato-Black.woff') format('woff'), url('../fonts/Lato-Black/Lato-Black.ttf') format('truetype'), url('../fonts/Lato-Black/Lato-Black.svg#Lato-Black') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto-Light';
    src: url('../fonts/Roboto-Light/Roboto-Light.eot?#iefix') format('embedded-opentype'), url('../fonts/Roboto-Light/Roboto-Light.woff') format('woff'), url('../fonts/Roboto-Light/Roboto-Light.ttf') format('truetype'), url('../fonts/Roboto-Light/Roboto-Light.svg#Roboto-Light') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Lato-Italic';
    src: url('../fonts/Lato-Italic/Lato-Italic.eot?#iefix') format('embedded-opentype'), url('../fonts/Lato-Italic/Lato-Italic.woff') format('woff'), url('../fonts/Lato-Italic/Lato-Italic.ttf') format('truetype'), url('../fonts/Lato-Italic/Lato-Italic.svg#Lato-Italic') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Lato-Light';
    src: url('../fonts/Lato-Light/Lato-Light.eot?#iefix') format('embedded-opentype'), url('../fonts/Lato-Light/Lato-Light.woff') format('woff'), url('../fonts/Lato-Light/Lato-Light.ttf') format('truetype'), url('../fonts/Lato-Light/Lato-Light.svg#Lato-Light') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Lato-BoldItalic';
    src: url('../fonts/Lato-BoldItalic/Lato-BoldItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/Lato-BoldItalic/Lato-BoldItalic.woff') format('woff'), url('../fonts/Lato-BoldItalic/Lato-BoldItalic.ttf') format('truetype'), url('../fonts/Lato-BoldItalic/Lato-BoldItalic.svg#Lato-BoldItalic') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto-Bold';
    src: url('../fonts/Roboto-Bold/Roboto-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/Roboto-Bold/Roboto-Bold.woff') format('woff'), url('../fonts/Roboto-Bold/Roboto-Bold.ttf') format('truetype'), url('../fonts/Roboto-Bold/Roboto-Bold.svg#Roboto-Bold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto-Italic';
    src: url('../fonts/Roboto-Italic/Roboto-Italic.eot?#iefix') format('embedded-opentype'), url('../fonts/Roboto-Italic/Roboto-Italic.woff') format('woff'), url('../fonts/Roboto-Italic/Roboto-Italic.ttf') format('truetype'), url('../fonts/Roboto-Italic/Roboto-Italic.svg#Roboto-Italic') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto-Medium';
    src: url('../fonts/Roboto-Medium/Roboto-Medium.eot?#iefix') format('embedded-opentype'), url('../fonts/Roboto-Medium/Roboto-Medium.woff') format('woff'), url('../fonts/Roboto-Medium/Roboto-Medium.ttf') format('truetype'), url('../fonts/Roboto-Medium/Roboto-Medium.svg#Roboto-Medium') format('svg');
    font-weight: normal;
    font-style: normal;
    }*/

.body_bg {
  background: #f8f8f8;
}
p {
  word-wrap: break-word;
}
h1,
h2,
h3,
h4,
h5 {
  word-wrap: break-word;
}
.list_of_hotels_container,
.rapp_container {
  width: 90%;
  padding: 0;
}
.afr_col {
  text-align: center;
}
.afr_box {
  padding: 16px;
  /*padding-top: 82px;
    padding-bottom: 82px;*/
}
.box_bg {
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
}
.afr_col h2 {
  margin-top: 7px;
  font-family: Lato;
  font-size: 10px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1.2px;
  text-align: center;
  color: #78909e;
}
.list_box {
  padding: 16px;
}
.list_box_heading {
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
}
.oval {
  float: left;
  width: 6px;
  height: 6px;
  background-color: #f5a623;
  border-radius: 50%;
}
.list_box li::before {
  content: "â€¢ ";
  color: #f5a623;
  font-size: 11px;
}
.aprvd li::before {
  content: "\2022";
  color: #7fc881;
  font-size: 11px;
  padding-right: 3px;
}
.rejected li::before {
  content: "â€¢ ";
  color: #ff6555;
  font-size: 11px;
}
.aprvd_3col li::before {
  content: "\2022";
  color: #7fc881;
  font-size: 11px;
  padding-right: 3px;
}
.list_box ul li {
  list-style-type: none;
}
.list_box ul li {
  font-family: Lato;
  font-size: 10px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #f5a623;
}
.pa_info {
  font-family: "Lato";
  font-size: 11px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9b9b9b;
  padding-bottom: 2px;
  /*height: 33px;*/
}
.cd {
  font-family: "Lato";
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9b9b9b;
  margin-bottom: 6px;
}
.tmc {
  width: 100%;
}
.tmc span {
  float: right;
}
.tmc p {
  font-family: "Lato";
  font-size: 11px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9b9b9b;
  margin-bottom: 6px;
}
.aprvd ul li {
  font-family: Lato;
  font-size: 11px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1px;
  color: #7fc881;
}
.rejected ul li {
  ffont-family: Lato;
  font-size: 10px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1px;
  color: #ff6555;
}
.aprvd_3col ul li {
  ffont-family: Lato;
  font-size: 10px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1px;
  color: #ff6555;
  color: #657d8c;
}
.filter_hotels label {
  font-family: "roboto";
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1px;
  color: #7d8d9f;
}
.filter_hotels {
  /*text-align: right;*/
  padding-top: 20px;
  padding-bottom: 20px;
}
.filter_hotels select {
  background: transparent;
  border: none;
}
.filter_hotels select {
  font-family: "roboto";
  font-size: 12px;
  font-weight: 400;
  /* font-style: italic; */
  font-stretch: normal;
  line-height: normal;
  /* letter-spacing: 0.6px; */
  color: #7d8d9f;
}
.filter_hotels .form-inline {
  padding-right: 15px;
}
@media (min-width: 768px) {
  div.col-sm-7.five-three {
    width: 60% !important;
    padding-left: 0;
    padding-right: 0;
  }
  div.col-sm-5.five-two {
    width: 40% !important;
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 1024px) {
  .list_of_hotels_container .col-sm-12 .col-sm-7 .col-sm-4,
  .list_of_hotels_container .col-sm-12 .col-sm-5 .col-sm-6 {
    padding-left: 5px;
    padding-right: 5px;
  }
  .list_of_hotels_container .col-sm-12 {
    margin-bottom: -9px;
  }
}
/*application*/

.rapp_section {
  padding-top: 20px;
  padding-bottom: 20px;
}
.rapp_section h2 {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
  margin-top: 0px;
}
.rapp_section span {
  border-left: 1px solid;
  margin-left: 10px;
  padding-left: 10px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
}
.svd {
  text-align: right;
}
.pb {
  border-radius: 3px;
  border: solid 1px #78909e;
  background: transparent;
  border-color: #78909e !important;
}
.rapp_section a.pb {
  font-family: "Roboto-Bold";
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #78909e !important;
  border-radius: 3px;
  border: solid 1px #78909e !important;
  padding: 10px 15px;
  margin-right: 15px;
  background: transparent !important;
  text-transform: capitalize;
}
.rapp_section .pb_two {
  font-family: "Roboto-Bold";
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff !important;
  border-radius: 3px;
  background-color: #4990e2 !important;
  border: none;
  padding: 10px 15px;
  text-transform: capitalize;
}
.rapp_section a.pb:hover {
  background: none;
}
.select_hotel_row {
  height: 127px;
}
.select_hotel_row select {
  width: 158px;
  height: 39px;
  background-color: #ffffff;
  border: solid 1px #78909e;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #78909e;
}
.select_hotel_col {
  display: inline-flex;
}
.select_hotel_col select {
  padding-left: 15px;
  font-family: "Lato";
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #78909e;
}
.select_hotel_col select.inr-type {
  padding-left: 0;
  border: none;
  /*font-family: 'Roboto-Medium';
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;*/
  color: #78909e;
  background-color: transparent;
  /*text-align: center;
    width: 50px;*/
}
.ex-inr-span {
  width: 42px;
  display: inline-block;
  border-right: 1px solid #78909e;
  height: 22px;
  margin-left: 4px;
}
.search-title input {
  font-family: "Lato";
  border: none;
  background: transparent;
  font-size: 24px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
  -webkit-box-shadow: inherit !important;
  box-shadow: inherit !important;
  border-radius: 0;
  border-bottom: 1.4px solid #000;
}
.search-title {
  float: left;
}
.search-title input {
  /*width: 100%;*/
}
.inr {
  font-family: "Lato";
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
  float: left;
  /*text-transform: uppercase;*/
  margin-top: 6px;
  border-right: 1px solid #000;
  /* margin-left: 10px; */
  padding-right: 14px;
}
/*.search-title input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */

/* color: pink;
    } */
@media (min-width: 1024px) {
  .svd {
    padding-right: 0px;
  }
}
textarea:focus,
input:focus {
  outline: none;
}
.select_hotel_section {
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
  margin-bottom: 23px;
  padding: 20px;
}
.display_total_expenses_row {
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
  min-height: 150px;
}
/*application2*/

.t_b input {
  background-color: #ffffff;
  border: solid 1px #78909e;
  font-family: "Lato";
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #78909e;
  border-radius: 0;
}
.t_b input::-webkit-input-placeholder {
  color: #78909e !important;
}
.t_b_i_d input {
  background-color: #ffffff;
  /*border: solid 1px #78909e;*/
  font-family: "Lato";
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #78909e !important;
  border-radius: 0;
}
select.dropdown-modified {
  /*border: 0 !important;*/ /*Removes border*/
  -webkit-appearance: none; /*Removes default chrome and safari style*/
  -moz-appearance: none; /* Removes Default Firefox style*/
  /* background: url('../images/dropdown.png') no-repeat;         Adds background-image */
  background-position: 97%; /*Position of the background-image*/
  width: 100%;
}
.search-title input::placeholder {
  color: #d8d8d8;
}
.t_b_i_d input::placeholder {
  color: #78909e;
}
.t_b_i_d {
  /*padding-top: 8px !important;*/
  /*border: solid 1px #78909e;*/
  border: 1px solid #e6e6e6;
  margin-left: 15px;
  /* padding-bottom: 10px !important; */
  /*width: 62px;*/
}
.t_b_i_d span {
  padding-top: 8px;
  display: inline-block;
  height: 100%;
}
.t_b_i_d.group-input {
  width: 135px;
}
.inone {
  padding-left: 15px !important;
  margin-left: 25px;
  width: 96px;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.inone_i_d {
  padding-left: 7px !important;
  /*margin-left: 15px;*/
  width: 50px;
  border: none !important;
  /*border-right: 1px solid #78909e !important;*/
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
  /*color: #78909e !important;*/
  /*padding-top: 10px !important;*/
  /*padding-bottom: 10px !important;*/
  border-right: none !important;
}
span.ex-read-only input,
span.ex-read-only i {
  color: #9b9b9b !important;
}
.te {
  border-right: none !important;
}
.se_domestic {
  margin-left: 15px;
}
.deci {
  width: 100%;
}
.marc_n {
  width: 150px;
}
.second_col_select {
  text-align: right;
}
/*mgr*/

.mgr_section {
  background-color: #ffffff;
  /*box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);*/
  padding: 20px;
}
.ese h2,
.ese_s h2 {
  font-family: "Lato";
  font-size: 12px;
  font-weight: bold;
  font-style: italic;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
  margin-bottom: 7px;
  margin-top: 0px;
  /*text-transform: uppercase;*/
}
.ese p {
  font-family: "Lato";
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
}
.ese_s p {
  font-family: "Lato";
  font-size: 12px;
}
/* .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
} */
/* .switch input {
    display: none;
} */
/* .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}
.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}
input:checked + .slider {
    background-color: var(--third-blue);
}
input:focus + .slider {
    box-shadow: 0 0 1px var(--third-blue);
}
input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
} */
/* Rounded sliders */

/* .slider.round {
    border-radius: 34px;
}
.slider.round:before {
    border-radius: 50%;
} */
.tre {
  padding-top: 17px;
}
.ese_s a {
  font-family: "Lato";
  font-size: 10px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  color: #9b9b9b;
  text-transform: uppercase;
}
.ese_s img {
  margin-left: 5px;
  margin-right: 5px;
}
.mgr_bg {
  background-color: rgba(120, 143, 158, 0.17);
}
.pfd {
  text-transform: lowercase !important;
}
.aprv h2 {
  font-family: Lato;
  font-size: 10px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  border-radius: 100px;
  background-color: #7fc881;
  text-align: center;
  padding: 6px;
}
.rjc h2 {
  font-family: Lato;
  font-size: 10px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  border-radius: 100px;
  background-color: #ff6555;
  text-align: center;
  padding: 6px;
}
.ul {
  border-bottom: 2px solid #4a4a4a;
  width: 18%;
  margin-bottom: 14px;
  margin-top: 14px;
}
.mgr_bg img {
  margin-right: 10px;
}
.apj .btn {
  border-radius: 34px;
}
.apj label {
}
.cen {
  padding-bottom: 0px !important;
  margin-top: -5px;
}
.reject_popup {
  font-family: Lato;
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #78909e;
}
.reject_popup_model textarea {
  background-color: #eff2f5;
  border: solid 1px #78909e;
  padding: 15px;
  height: 133px;
  width: 568px;
  font-family: Lato;
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #78909e;
  border: none;
}
.reject_popup_model textarea {
  width: 867px;
  height: 219px;
}
.reject_popup_model .modal-footer,
.reject_popup_model .modal-header {
  border: none;
}
.reject_popup_model {
  width: 900px;
}
a.cn_btn {
  font-family: "Roboto-Bold";
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #78909e !important;
  border-radius: 3px;
  border: solid 1px #78909e;
  padding: 10px 15px;
  margin-right: 15px;
  text-transform: capitalize;
}
a.rr_cta {
  font-family: "Roboto-Bold";
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff !important;
  border-radius: 3px;
  background-color: #4990e2;
  border: none;
  padding: 10px 15px;
  text-transform: capitalize;
}
.pop_col_btn .btn-info:hover {
  color: #fff;
  background-color: transparent;
  border-color: none;
}
.pop_col_btn .btn-info {
  background: none;
  border: none;
}
.btn:focus {
  outline: none;
}
.pop_col_btn_two {
  padding: 0px 16px;
}
.select_hotel_row_de {
  margin-bottom: 25px;
}
.attach_section {
  display: inline-flex;
}
.des_field {
  width: 176px;
}
.st_field {
  margin-left: 0px;
}
.attachment_link_section img {
  float: left;
}
.attachment_link_section h3 {
  float: left;
  margin-top: 0px;
  font-family: "Roboto-Medium";
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #78909e;
}
.attachment_link_section {
  /*margin-left: 15px;*/
}
.attachment_link_section h3 {
  margin-left: 9px;
  margin-top: 5px;
}
a.resecta {
  font-family: "Roboto-Bold";
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #78909e !important;
  background-color: #fff !important;
  padding: 6px 12px;
  text-transform: capitalize;
}
a.resecta.btn-primary:hover {
  color: #78909e;
  background-color: transparent;
  border: solid 1px #78909e;
}
a.ae_cta {
  font-family: "Roboto-Bold";
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a90e2 !important;
  border-radius: 3px;
  border: solid 1px #4990e2 !important;
  background-color: #fff !important;
  text-transform: capitalize;
  padding: 6px 12px;
}
.rae_col {
  text-align: right;
}
.mgr_section_bsection {
  /*background: #fff;*/
}
.col-md-12.filter_hotels {
  padding-left: 0;
  padding-right: 0;
}
.expense-notes {
  width: 100%;
  height: 211px;
  background-color: #eff2f5;
  border: solid 1px #78909e;
  font-family: Roboto;
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #78909e;
  padding: 15px;
}
.t_b_i_d a.info {
  margin-right: 8px;
}
.padding-bottom-10 {
  padding-bottom: 8px;
}
.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
  position: relative;
  min-height: 1px;
  padding: 5px;
  /*padding-right: 5px;
    padding-left: 5px;*/
}
.col-xs-15 {
  width: 20%;
  float: left;
}
@media (min-width: 768px) {
  .col-sm-15 {
    width: 20%;
    float: left;
  }
}
@media (min-width: 992px) {
  .col-md-15 {
    width: 20%;
    float: left;
  }
}
@media (min-width: 1200px) {
  .col-lg-15 {
    width: 20%;
    float: left;
  }
}
.display-inherit {
  display: inherit;
}
.error-new-element {
  width: 100%;
  min-height: 50px;
  background-color: #ef9a9a;
  display: none;
  padding: 10px;
  color: #333;
}
.margin-left-15 {
  margin-left: 15px;
}
.onoffswitch {
  position: relative;
  width: 100px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.onoffswitch-checkbox {
  display: none;
}
.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 20px;
  max-width: 87%;
}
.onoffswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  -moz-transition: margin 0.3s ease-in 0s;
  -webkit-transition: margin 0.3s ease-in 0s;
  -o-transition: margin 0.3s ease-in 0s;
  transition: margin 0.3s ease-in 0s;
}
.onoffswitch-inner:before,
.onoffswitch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 26px;
  padding: 0;
  line-height: 26px;
  font-size: 11px;
  color: white;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.onoffswitch-inner:before {
  content: attr(data-text-on);
  padding-left: 11px;
  color: #ffffff;
  background-color: #7fc881;
}
.onoffswitch-inner:after {
  content: attr(data-text-off);
  padding-right: 20px;
  background-color: #ff6555;
  color: #fff;
  text-align: right;
}
.onoffswitch-switch {
  display: block;
  width: 18px;
  margin: 6px;
  background: #ffffff;
  border: 2px solid transparent;
  border-radius: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 71px;
  -moz-transition: all 0.3s ease-in 0s;
  -webkit-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 12px;
}
.padding-top-10 {
  padding-top: 10px;
}
/*fushed box styles*/
.fushed-cross::before {
  top: -1px;
  /* bottom: 0; */
  position: absolute;
  content: " ";
  width: 1px;
  left: 50%;
  height: 9px;
  background-color: #78909e;
}
.fushed-cross::after {
  /* top: 0; */
  bottom: 0;
  position: absolute;
  content: " ";
  width: 1px;
  left: 50%;
  height: 9px;
  background-color: #78909e;
}
.fushed-cross {
  color: #78909e;
}
.fuse-cross-icon {
  position: relative;
  left: -6px;
  top: 1px;
  color: #9b9b9b;
}
/*fushed box styles*/
/*checkedbox new styles*/
span.ex-read-only {
  width: 146px;
  padding-left: 5px;
  background-color: #f0f0f0;
}
span.ex-read-only input {
  background-color: #f0f0f0;
}
.control {
  position: relative;
  display: block;
  cursor: pointer;
}
.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.control__indicator {
  position: absolute;
  top: 2px;
  right: 0;
  width: 20px;
  height: 20px;
  background: #e6e6e6;
}
/* Hover and focus states */
.control:hover input ~ .control__indicator,
.control input:focus ~ .control__indicator {
  background: #ccc;
}
/* Checked state */
.control input:checked ~ .control__indicator {
  background: #78909e;
}
/* Hover state whilst checked */
.control:hover input:not([disabled]):checked ~ .control__indicator,
.control input:checked:focus ~ .control__indicator {
  background: #0e647d;
}
/* Disabled state */
.control input:disabled ~ .control__indicator {
  pointer-events: none;
  opacity: 0.6;
  background: #e6e6e6;
}
/* Check mark */
.control__indicator:after {
  position: absolute;
  display: none;
  content: "";
}
/* Show check mark */
.control input:checked ~ .control__indicator:after {
  display: block;
}
/* Checkbox tick */
.control--checkbox .control__indicator:after {
  top: 3px;
  left: 8px;
  width: 5px;
  height: 11px;
  transform: rotate(45deg);
  border: solid #fff;
  border-width: 0 2px 2px 0;
}
/* Disabled tick colour */
.control--checkbox input:disabled ~ .control__indicator:after {
  border-color: #7b7b7b;
}
/*checkedbox new styles*/
/*Attachment Files Styles*/
.label.file-name {
  border-radius: 232px;
  background-color: #d8d8d8;
  font-family: Roboto;
  font-size: 12px !important;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #78909e;
  padding-top: 4px;
}
.fileUpload {
  vertical-align: -1px;
  /*padding-left: 5px;*/
  position: relative;
  overflow: hidden;
  /*margin: 10px;*/
}
.fileUpload input.upload {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
  display: none;
}
.glyphicon.glyphicon-remove {
  vertical-align: -1px;
  cursor: pointer;
  padding-left: 5px;
  top: 1px;
}
/*Attachment Files Styles*/
.border-left-none {
  border-left: none !important;
}
.info-multi {
  height: 33px;
}
.list_box,
.reim-col-height {
  height: 175px;
  overflow: hidden;
}
.list_box:hover {
  overflow: auto;
}
.vertical-center {
  /*display: table-cell;
    vertical-align: middle;*/
  position: absolute;
  top: 50%;
  left: 50%;
  height: 30%;
  width: 50%;
  margin: -15% 0 0 -25%;
}
.vertical-middle {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0px auto;
  transform: translateY(-50%);
}
.reim-info-res span {
  color: #9b9b9b;
}
.color-tooltip-info {
  color: #9b9b9b;
}
.inr .reimb-amount {
  display: block;
  padding-left: 0;
  margin-left: 0;
  font-size: 10px;
}
.font-bold {
  font-weight: bold !important;
}
.text-align-right {
  text-align: right;
}
.text-initial {
  text-transform: initial !important;
}
.text-capitallize {
  text-transform: capitalize !important;
}
.fix-box {
  position: absolute;
  top: -6px;
  left: 0;
}
input.fix-box {
  height: 28px;
  background-color: #fbfbfb;
}
.ese_s p {
  color: #4a4a4a;
}
.white-padding-bg {
  background-color: #fff;
  padding: 16px;
}
.mgr_section:nth-child(odd) {
  background-color: rgba(120, 143, 158, 0.17);
}
.mgr_section:nth-child(even) {
  background-color: #fff;
}
.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.t_b select,
.t_b label {
  font-size: 12px;
}
.remove-icon {
  vertical-align: -1px;
  cursor: pointer;
  padding-left: 5px;
  top: 1px;
  color: #646464;
}
.app-reimb-btn {
  padding: 6px 10px;
}
.exp-status {
  display: block;
  overflow: hidden;
  border: 2px solid transparent;
  border-radius: 20px;
  max-width: #7fc881;
  text-align: center;
  background-color: #7fc881;
  color: #fff;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  font-size: 11px;
  line-height: 18px;
  height: 23px;
  width: 80px;
}
.exp-status.approved {
  background-color: #7fc881;
}
.exp-status.rejected {
  background-color: #ff6555;
}
.nav-tabs-new > li > a.active,
.nav-tabs-new > li > a.active:hover,
.nav-tabs-new > li > a.active:focus {
  color: #555;
  cursor: default;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-color: transparent;
}
.nav-tabs-new > li > a {
  margin-right: 2px;
  line-height: 1.42857143;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
  color: #428bca;
}
.nav-tabs-new > li {
  float: left;
  margin-bottom: -1px;
}
.nav-tabs-new > li > a {
  position: relative;
  display: block;
  padding: 10px 15px;
}
.nav-tabs-new > li {
  position: relative;
  display: block;
}
.nav-tabs-new {
  border-bottom: 1px solid #ddd;
}
.margin-right-10 {
  margin-right: 10px;
}
.attachment-section {
  width: 140px;
  position: absolute;
  right: 0;
  top: 10px;
}
.attachment-section .fa {
  color: var(--primary-color);
}
.message-box-text.reply-message {
  width: 90%;
  float: left;
  resize: none;
  border: 0;
  min-height: 40px;
  /*padding-top: 10px;
    padding-left: 10px;*/
  /*padding: 5px 0 5px 10px;*/
  overflow: hidden;
}
.message-box-text.reply-message:focus {
  box-shadow: none !important;
}
.message-module-inner {
  border: 1px solid #e1e1e1;
  padding: 5px 0 5px 5px;
}
.opa-btn {
  opacity: 0.2;
}
.opa-btn:hover {
  opacity: 0.6;
}
.comment-divider {
  border-top: 1px solid #333;
}
.margin-bottom-0 {
  margin-bottom: 0 !important;
}
.padding-5 {
  padding: 5px;
}
.main-navigation1 .logo1 {
  /* margin-left: 25px; */
}
.requestDiv {
  position: relative;
}
/*RECRUITEMENT OFERLETTER STARTS*/
.margin-left-right-50 {
  margin-left: 50px;
  margin-right: 50px;
  border-top: 1px solid #eeeeee;
}
.padding-left-right-50 {
  padding-left: 50px;
  padding-right: 50px;
}
.budget-title {
  font-family: Lato;
  font-size: 14px;
  letter-spacing: -0.1px;
  color: #485465;
}
.budget-number {
  font-family: Lato;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: -0.2px;
  color: #485465;
}
.budget-update {
  font-family: Lato;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 1.2px;
  color: #4a4a4a;
}
.budget-update-status {
  font-family: Lato;
  font-size: 13px;
  color: #4a4a4a;
}
.reload-icon {
  color: #4a4a4a;
}
.table-header {
  background-color: rgba(136, 136, 136, 0.17);
}
div.table-wrapper {
  overflow: hidden;
  /*overflow-x: scroll;*/
}
.btn-navigation {
  /*width: 35px;
    height: 50px;*/
  position: absolute;
  background-color: #ebebeb;
  cursor: pointer;
  color: var(--primary-color);
  font-family: lato;
  font-size: 24px;
  height: 66px;
  padding-top: 6px;
  padding-left: 2px;
  padding-right: 2px;
}
.btn-navigation.left {
  left: 0;
}
.btn-navigation.right {
  right: 0;
}
.left-0 {
  left: 0 !important;
}
.right-0 {
  right: 0 !important;
}
.padding-left-right-25 {
  padding-right: 25px;
  padding-left: 25px;
}
div.table-wrapper > .table > tbody > tr > td,
div.table-wrapper > .table > tbody > tr > th,
div.table-wrapper > .table > tfoot > tr > td,
div.table-wrapper > .table > tfoot > tr > th,
div.table-wrapper > .table > thead > tr > td,
div.table-wrapper > .table > thead > tr > th {
  line-height: 20px !important;
  border-left: none !important;
}
div.table-wrapper > .table > tbody > tr > th,
div.table-wrapper > .table > thead > tr > th {
  line-height: 35px !important;
  border-left: none !important;
}
div.table-wrapper table > tbody > tr > td:last-child,
div.table-wrapper table > tbody > tr > th:last-child,
div.table-wrapper table > tfoot > tr > td:last-child,
div.table-wrapper table > tfoot > tr > th:last-child,
div.table-wrapper table > thead > tr > td:last-child,
div.table-wrapper table > thead > tr > th:last-child {
  border-right: none !important;
}
.comp-data {
  font-family: Lato;
  font-size: 12px;
  color: #4a4a4a;
}
.comp-data .content-title {
  font-family: Lato;
  font-size: 13px;
  color: var(--primary-color);
}
#custom_fields_container input {
  max-width: 500px;
}
.info-box > .box1 {
  display: inline-block;
  font-family: Lato;
  font-size: 14px;
  color: #9b9b9b;
}
.info-box > .box2 {
  display: inline-block;
  font-family: Lato;
  font-size: 12px;
  color: #9b9b9b;
}
.margin-bottom-0 {
  margin-bottom: 0;
}
.width-100 {
  width: 100% !important;
}
.width-22p {
  width: 22%;
}
.width-26p {
  width: 26%;
}
.width-27p {
  width: 27%;
}
.width-auto {
  width: auto !important;
}
.right-border-ol {
  border-right: solid 1px #d8d8d8;
}
.border-ol {
  border: solid 1px #d8d8d8;
}
.initiate-heading {
  font-family: Roboto;
  font-size: 22px;
  font-weight: 300;
  color: var(--primary-color);
  margin-top: 9px !important;
}
.modal-header.initiate-modal-header {
  padding: 20px 22px 20px 22px;
  background-color: #f8f8f8;
}
.margin0 {
  margin: 0;
}
.initiate-ob-btn {
  font-family: Lato;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 1.1px;
  color: var(--primary-color);
}
.modal-action-btn {
  height: 40px;
  padding-top: 10px;
  text-align: center;
}
.modal-body.ini-ob-body {
  padding: 5px;
  padding-bottom: 28px;
}
.ini-ob-tbl {
  background-color: #f8f8f8;
}
.ini-tbl-col {
  height: 60px;
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
  padding: 12px 5px 12px 5px;
}
.ini-tbl-col > h6 {
  text-transform: uppercase;
  font-family: Lato;
  font-size: 10px;
  font-weight: 900;
  color: #d8d8d8;
  margin: 0 0 8px 0;
}
.ini-tbl-col > h5 {
  font-family: Lato;
  font-size: 14px;
  color: #4a4a4a;
  margin: 0 0 5px 0;
}
.ini-tbl-col > h5.ini-title {
  color: var(--primary-color);
}
.ob-template {
  padding: 20px;
  /*border: 1px solid #333;*/
  border: none !important;
}
.ob-template-col > h5 {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 300;
  color: #4a4a4a;
  margin: 0;
}
.ob-template-col {
  padding: 0;
}
.offer-board {
  height: 220px;
  background-color: #ffffff;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.14);
  margin: 20px 0;
  overflow-y: auto;
}
.offer-board h6 {
  /*font-family: Lato;
    font-size: 10px;
    font-weight: 900;
    color: #d8d8d8;*/
  font-family: "latoregular";
  font-size: 14px;
  font-weight: 400;
  color: #b2b2b2;
}
.offer-board h4 {
  /*font-family: Roboto;
    font-size: 18px;
    font-weight: 300;
    color: #4a4a4a;
    margin-bottom: 0;*/
  font-family: "latoregular";
  font-size: 14px;
  font-weight: 400;
  color: #333;
  margin-bottom: 0;
}
.ol-board-doj-dor .ob-doj,
.ol-board-doj-dor .ob-dor {
  display: inline-block;
  width: 46%;
}
.ob-doj h4,
.ob-doj h6,
.ob-dor h4,
.ob-dor h6 {
  margin: 0;
}
.ob-dor {
  margin-left: 20px;
}
.ob-new-offer-button {
  width: 216px;
  height: 40px;
  padding: 10px 20px;
  border-radius: 1px;
  background-color: var(--primary-color);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
}
.ob-new-offer-drop-menu {
  width: 200px;
  height: 40px;
  padding: 10px 20px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
}
.ob-new-offer-drop-menu option {
  color: #333;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}
.ob-new-bulk-action {
  width: 140px;
  height: 32px;
  border: solid 1px var(--primary-color);
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  color: var(--primary-color);
  background: transparent;
  padding-left: 10px;
}
.ob-new-offer-drop-menu.rejected {
  color: #ff6555;
  border: solid 1px #ff6555;
}
.ob-new-offer-drop-menu.approved {
  color: #7fc881;
  border: solid 1px #7fc881;
}
.ob-new-offer-drop-menu.pending {
  color: #f5a623;
  border: solid 1px #f5a623;
}
.ob-make-attachment div {
  display: inline-block;
}
.ob-make-attachment a {
  font-family: Lato;
  font-size: 12px;
  color: #4a4a4a;
}
.ob-new-letter-btn {
  width: 216px;
  height: 40px;
  padding: 10px 20px;
  border-radius: 1px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
  border: solid 1px var(--primary-color);
}
.ob-border-shadow {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
}
.margin-top-13 {
  margin-top: 13.2px;
}
.content-width-81 {
  width: 81%;
}
.content-width-19 {
  width: 19%;
}
.text-align-right {
  text-align: right;
}
.signature-ob-btn {
  width: 130px;
  height: 40px;
  padding: 9px 12px;
  border-radius: 1px;
  background-color: var(--primary-color);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
}
.signature-field {
  height: 243px;
}
.modal-header.ob-sign-modal-header {
  padding: 22px 12px 20px 12px;
  background-color: #f8f8f8;
}
.ol-candidate-list {
  margin-top: 20px;
  padding: 15px 5px;
  background-color: #ffffff;
}
.ob-tbody-data p {
  margin: 0;
}
.ob-generate-letter-version > span {
  position: relative;
  top: -5px;
}
.ob-generate-letter-version > div {
  display: inline-block;
  padding-left: 5px;
}
.comp-data .status {
  font-family: Lato;
  font-size: 12px;
  font-weight: 900;
  letter-spacing: 1px;
}
.comp-data .status.initiate-ob {
  width: 215px;
  height: 30.6px;
  border-radius: 3px;
  background-color: var(--primary-color);
  font-family: Lato;
  font-size: 13px;
  font-weight: bold;
  color: #ffffff;
}
.comp-data .status.initiate-ob .initiate-ob-btn {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-left: 10px;
  display: inline-block;
  border: 1px solid #fff;
  position: relative;
  color: #fff;
  top: 3px;
}
.comp-data .status.initiate-ob .initiate-ob-btn .icon-place {
  top: 1px;
  left: 8px;
  position: relative;
  color: #fff;
}
.comp-data .status.initiate-ob span {
  position: relative;
  top: 4px;
  margin-left: 7px;
}
.comp-data .status.initiate-ob {
  cursor: pointer;
}
.comp-data .status.accepted {
  color: #6fbf6e;
}
.comp-data .status.pending {
  color: #f1961c;
}
.comp-data .status.rejected {
  color: #fc4c44;
}
.comp-data .status-date {
  font-family: Lato;
  font-size: 12px;
  color: #898989;
}
.ob-filter-btn {
  width: 65px;
  height: 40px;
  border-radius: 1px;
  background-color: #ffffff;
  color: #979797;
  padding-top: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
}
.ob-generate-button-grp > div {
  display: inline-block;
  margin-left: 8px;
}
.ol-search > div {
  width: 140px;
  height: 40px;
  border-radius: 1px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
  display: inline-block;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
}
.ol-search input {
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0.8px;
  color: #9b9b9b !important;
  padding-left: 7px;
}
.ol-search .fa-search {
  color: #9b9b9b;
}
.ol-fliter-input {
  height: 40px;
  border: solid 1px #d8d8d8;
  padding-left: 10px;
}
.ol-fliter-input select {
  padding-left: 15px;
  font-family: "Lato";
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #78909e;
}
.ol-fliter-input > * {
  display: inline-block;
  font-family: Lato;
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 1px;
  color: #9b9b9b;
}
.ol-fliter-input select {
  background: transparent;
  border: none;
}
.ol-fliter-input.date input {
  width: 108px;
  height: 100%;
  background-color: transparent;
  border: none !important;
}
.ob-card-info {
  width: 215.2px;
  height: 92.9px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
  margin-left: 10px;
}
.margin-top-15 {
  margin-top: 15px;
}
.ob-card-info p {
  padding-top: 10px;
  font-family: Lato;
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 1px;
  color: var(--primary-color);
  text-align: center;
}
.ob-card-info p span {
  font-family: Lato;
  font-size: 38px;
  font-weight: 300;
  letter-spacing: 3.8px;
  color: var(--primary-color);
  position: relative;
  top: 8px;
  padding-right: 10px;
}
.ob-card-info.completed * {
  color: #9b9b9b;
}
.ob-card-info.inprogress * {
  color: #7fc881;
}
.main-navigation-3 {
  background-color: #ffffff;
  height: 80px;
}
.acpt-ol-text p {
  margin-top: 10px;
  font-family: Lato;
  font-size: 12px;
  color: #4a4a4a;
}
.acpt-ol-letter {
  min-height: 571px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
  font-family: Lato;
  font-size: 13px;
  /*font-weight: bold;*/
  color: #9b9b9b;
  text-align: justify;
}
.acpt-ol-controls {
  height: 128px;
  background-color: #ececec;
  margin-top: 10px;
}
.acpt-ol-tools {
  margin-top: 10px;
  height: 242.1px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
}
.act-ol-action-btn {
  width: 170px;
  height: 40px;
  background-color: var(--primary-color);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
  padding: 10px 12px;
}
.acpt-ol-actions {
  margin-top: 10px;
}
.act-ol-action-btn.reject {
  width: 90px;
  height: 40px;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.6px;
  color: var(--primary-color);
  border: solid 1px var(--primary-color);
  background-color: #fff;
  margin-right: 11px;
}
.padding-left-5 {
  padding-left: 5px;
}
.sign-field {
  height: 80.6px;
  border: solid 0.5px #9b9b9b;
  font-family: Lato;
  font-size: 13px;
  font-weight: bold;
  color: #9b9b9b;
}
.acpt-ol-tools input {
  border: solid 0.5px #9b9b9b !important;
}
.acpt-ol-tools .label-title {
  padding: 5px 10px;
  font-family: Lato;
  font-size: 13px;
  font-weight: bold;
  color: var(--primary-color);
}
.acpt-ol-controls-label p {
  font-family: Lato;
  font-size: 13px;
  color: #9b9b9b;
}
.acpt-ol-controls-info p {
  font-family: Lato;
  font-size: 13px;
  font-weight: bold;
  color: #4a4a4a;
}
.acpt-ol-controls-info p.title {
  color: var(--primary-color);
}
.content-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}
.content-center {
  text-align: center;
}
.content-center p.icon span {
  display: inline-block;
  /*height: 84.9px;*/
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  padding: 10px;
  border-radius: 50%;
  font-size: 48px;
  font-weight: 100;
}
.content-center h1 {
  font-family: Lato;
  font-size: 48px;
  font-weight: 300;
  color: var(--primary-color);
}
.content-center p.details {
  font-family: Lato;
  font-size: 20px;
  text-align: center;
  color: #4a4a4a;
  width: 830px;
}
/*New Styles added May 27, 2017*/
.cover-image {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 425px;
  /* background-image: url(../images/cover-img.png); */
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
}
.welcome-cover-style {
  position: absolute;
  top: 230px;
  left: 83px;
  width: 50%;
  display: inline-block;
}
.welcome-to {
  /*width: 492px;*/
  height: 114px;
  color: #ffffff;
}
.welcome-to h1 {
  font-family: Lato;
  font-size: 48px;
  font-weight: 300;
  margin-top: 10px;
  margin-bottom: 0;
}
.ob-status-btn {
  width: 380.8px;
  height: 96px;
  margin-top: 10px;
  border-radius: 4px;
  background-color: var(--primary-color);
}
.ob-status-btn > div {
  display: inline-block;
  position: absolute;
}
.ob-status-btn .icon-section {
  width: 82px;
  line-height: 10;
}
.ob-status-btn .icon-section .status-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-left: 10px;
  background-color: #7fc881;
  border: 3px solid #fff;
  position: relative;
  top: 12px;
}
.ob-status-btn .text-section {
  margin-top: 10px;
  width: 223px;
  font-family: Lato;
  font-size: 17px;
  font-weight: 900;
  letter-spacing: 0.8px;
  color: #ffffff;
  margin-left: 90px;
}
.ob-status-btn .text-section p.info-title {
  font-family: Lato;
  font-size: 15px;
  font-style: italic;
  color: #ffffff;
  font-weight: 400;
  letter-spacing: 0.1px;
}
.ob-status-btn .icon-place {
  top: 16px;
  left: 16px;
  position: absolute;
  color: #fff;
}
.ob-status-btn .arrow-section {
  position: absolute;
}
.ob-container {
  min-height: 300px;
  background-color: #f4f7fe;
  padding: 15px 70px;
  font-family: Lato;
}
.message-ceo {
  margin-top: 15px;
}
.message-ceo h1 {
  font-size: 32px;
  color: var(--primary-color);
}
.message-ceo-body {
  height: 416px;
  border-radius: 4px;
  margin-top: 30px;
  background-color: #ffffff;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.07);
}
.message-ceo-body p {
  padding-top: 25px;
  margin: 30px 0;
  height: 360px;
  overflow-y: scroll;
}
.ob-comp-details .message-comp {
  padding-top: 15px;
  box-shadow: none;
  background-color: transparent;
  font-family: Lato;
  font-size: 16px;
  color: #000000;
}
.ob-comp-details .message-comp p {
  text-align: justify;
  margin: 0;
  padding: 0;
}
.ob-comp-details .ob-card {
  /*height: 100px;*/
  margin-left: 10px;
  margin-right: 10px;
  padding: 30px;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.05);
  height: 210px;
  margin-bottom: 10px;
}
.ob-comp-details .ob-card p.info-title {
  /* width: 201px;*/
  font-family: Lato;
  font-size: 32px;
  font-weight: 300;
  color: #000000;
  position: absolute;
  bottom: 30px;
}
.ob-comp-details .ob-card p.info-details {
  margin: 0;
  font-family: Lato;
  font-size: 16px;
  color: #9b9b9b;
  position: absolute;
  bottom: 15px;
}
.ob-user-info {
  width: 368px;
  min-height: 280px;
  background-color: #fff;
  position: absolute;
  right: 70px;
  border-radius: 4px;
  top: 250px;
}
.ob-user-details {
  padding: 12px;
  background-color: #f8f8f8;
  border-radius: 4px;
  height: 145px;
}
.ob-user-details .user-profile-info {
  display: inline-block;
  margin-left: 135px;
}
.ob-user-details .user-profile-image {
  display: inline-block;
  position: absolute;
  top: 22px;
  left: 22px;
}
.user-profile-image img {
  display: inline-block;
  width: 95px;
  height: 95px;
}
.user-profile-info * {
  padding: 5px 0;
  margin: 0;
}
.user-profile-info h3 {
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  color: #000000;
}
.user-profile-info h5 {
  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
  color: #4a4a4a;
}
.left-topic-list {
  padding: 0;
  width: 22%;
  /* min-height: 230px; */
  border-radius: 4px;
  background-color: #f8f8f8;
  position: absolute;
  max-height: calc(100vh - 200px);
  overflow: auto;
}
.left-topic-list ul {
  margin-bottom: 0;
}
.right-details-section {
  width: 78%;
  min-height: 230px;
  margin-left: 22.3%;
  padding: 0;
  /*height: 230px;*/
}
.left-topic-list ul {
  padding: 0;
}
.left-topic-list ul li a {
  min-height: 60px;
  position: relative;
  display: block;
  font-family: Lato;
  font-size: 18px;
  color: var(--primary-color);
  text-align: left;
  padding: 20px 25px;
}
.left-topic-list ul li a.active,
.left-topic-list ul li.current a,
.left-topic-list ul li a:hover {
  background-color: #d8d8d8;
  color: #4a4a4a;
}
.right-details-section > div {
  min-height: 300px;
  border-radius: 4px;
  /*border: 1px solid #333;*/
}
/*nav.page-navigation {
    width: 80px;
    background-color: #ebebeb;
    position: fixed;
    top:10%;
    left:0;
    }*/
.fixed-header-title {
  position: fixed !important;
  width: 92%;
  background-color: #fff;
  top: 48px;
  z-index: 1111111;
  padding-top: 12px;
}
.fixed-menu-bar {
  position: fixed !important;
  top: 142px;
  width: 277px;
}
.fixed-menu-bar-60 {
  position: fixed !important;
  top: 60px;
  width: 277px;
}
.fixed-menu-bar-50 {
  position: fixed !important;
  top: 60px;
  width: 269.08px;
}
.ob-form-section {
  padding: 0px 30px;
}
.ob-form-section input {
  width: 100% !important;
  background-color: #ffffff;
  border: solid 1px #78909e;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #78909e;
  border-radius: 0;
  height: 35px;
}
.ob-form-section label {
  font-family: Lato;
  font-size: 13px;
  font-weight: bold;
  color: #9b9b9b;
  position: relative;
  top: 8px;
}
.ob-form-field-only {
  padding: 15px 15px 15px 15px;
  margin-top: 5px;
  /*background-color: #f8f8f8;*/
  /*border: 1px solid #ccc;*/
  width: 80%;
}
.ob-form-field-only:hover {
  background-color: #f8f8f8;
}
.ob-action-btn {
  width: 80px;
  height: 40px;
  margin-right: 10px;
  padding: 10px;
  display: inline-block;
  font-family: Lato;
  font-size: 13px;
  font-weight: bold;
  color: #fff;
  background-color: #ccc;
  text-align: center;
}
.ob-action-btn.approve {
  background-color: #7fc881;
}
.ob-action-btn.decline {
  background-color: #ff6555;
}
.padding-left-0 {
  padding-left: 0 !important;
}
.chip {
  display: inline-block;
  height: 32px;
  font-size: 13px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
  line-height: 32px;
  padding: 0 12px;
  border-radius: 16px;
  background-color: #e4e4e4;
  margin-bottom: 5px;
  margin-right: 5px;
}
.margin-left-10 {
  margin-left: 10px;
}
.ob-assign-people h5 {
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  color: #9b9b9b;
  text-transform: uppercase;
}
.contain-wrraper {
  width: 90%;
  margin: auto;
  height: 500px;
  /*background-color: #fff;*/
}
.ob-user-title h3 {
  font-family: Lato;
  font-size: 24px;
  color: #000000;
}
.ob-user-title p {
  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
  color: #4a4a4a;
}
.ob-user-view-tab {
  border-bottom: none;
}
.ob-user-view-tab .tab-plate {
  width: 250px;
  height: 68px;
  margin-right: 10px;
  border-radius: 4px;
  background-color: #d8d8d8;
  box-shadow: 0 2px 40px 5px rgba(0, 0, 0, 0.07);
  font-family: Lato;
  font-size: 15px;
  font-weight: 900;
  color: #4a4a4a;
  letter-spacing: 1.2px;
}
.ob-user-view-tab .active .tab-plate {
  background-color: #fff;
  box-shadow: none;
}
.ob-user-view-tab .tab-plate::after {
  background: none;
}
.user-appl-bg {
  background-color: #f4f7fe;
}
.ob-user-view-tab-content {
  min-height: 330px;
  background-color: #fff;
}
/*.ob-user-view-tab-content *{
        margin: 0;
        }*/
.padding-top-15 {
  padding-top: 15px;
}
.ob-user-heading {
  font-family: Lato;
  font-size: 24px;
  color: var(--primary-color);
  font-weight: 300;
}
.ob-user-submit {
  border-radius: 4px;
  background-color: #7fc881;
  box-shadow: 0 2px 40px 5px rgba(0, 0, 0, 0.07);
  display: inline-block;
  color: #fff;
  padding: 14px 15px;
  position: relative;
  margin-left: 10px;
  text-align: center;
  border: 1px solid #38a53b;
}
.ob-user-reset {
  border-radius: 4px;
  box-shadow: 0 2px 40px 5px rgba(0, 0, 0, 0.07);
  border: solid 1px var(--primary-color);
  display: inline-block;
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 1.1px;
  color: var(--primary-color);
  padding: 14px 15px;
  position: relative;
}
.ob-form-section .header-title h3 {
  font-family: Lato;
  font-size: 20px;
  color: var(--primary-color);
}
.margin-top-0 {
  margin-top: 0px;
}
.margin-top-10 {
  margin-top: 10px;
}
.ob-form-section .help-block {
  font-family: Lato;
  font-size: 11px;
  font-style: italic;
  color: #9b9b9b;
}
.ob-checkbox label::before,
.ob-checkbox label::after {
  margin-left: 0px !important;
}
.ob-checkbox label {
  padding-left: 20px !important;
}
.margin-top-5 {
  margin-top: 5px;
}
.ob-peer-details {
  padding: 15px 10px 15px 15px;
}
.ob-peer-details h5 {
  font-family: Lato;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 1px;
  color: #4a4a4a;
}
.ob-peer-details .peer-list img {
  width: 50px;
  height: 50px;
}
.ob-peer-details .peer-list h6 {
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: #000000;
  line-height: 15px;
}
.ob-peer-details .peer-list p {
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: #9b9b9b;
}
.ob-peer-details .peer-list .peer {
  width: 62px;
  margin: 0 5px;
  display: inline-block;
  text-align: center;
}
.ob-tab-title span {
  display: inline-block;
  top: 3px;
  position: relative;
  text-transform: uppercase;
  font-size: 13.2px;
}
.ob-tab-title .ob-icon {
  margin-left: 5px;
  margin-right: 5px;
  position: relative;
  top: -1px;
}
.ob-btn-sign-document {
  height: 50px;
  display: inline-block;
  color: #fff;
  border-radius: 4px;
  background-color: #7fc881;
  box-shadow: 0 2px 40px 5px rgba(0, 0, 0, 0.07);
}
.ob-user-docs {
  padding-top: 30px;
  text-align: justify;
}
.acpt-ol-letter {
  padding: 20px 15px;
}
.ob-radio-padding-left {
  padding-left: 22px;
}
.margin-top-35 {
  padding-top: 35px;
}
.padding-top-70 {
  padding-top: 70px;
}
.ob-submit-review {
  font-family: Lato;
  font-size: 13px;
  font-weight: 900;
  letter-spacing: 1.1px;
  color: #ffffff;
  border-radius: 1px;
  background-color: var(--primary-color);
  padding: 16px 30px;
}
.ob-user-save-later {
  border-radius: 1px;
  box-shadow: 0 2px 40px 5px rgba(0, 0, 0, 0.07);
  border: solid 1px var(--primary-color);
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 1.1px;
  color: var(--primary-color);
  padding: 15px 30px;
  margin-right: 8px;
}
/*RECRUITEMENT OFERLETTER STARTS*/
/*ONBOARDING STARTS*/
/**** UI Fixes ****/

.owl-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -5%;
  color: #fff;
  background: #7fc881;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 10px;
}
.owl-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -3%;
  color: #fff;
  background: #7fc881;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 10px;
}
.ob-peer-details .peer-list img {
  width: 50px !important;
  height: 50px;
  margin: 0px auto;
}
.icon-app-file {
  padding: 10px !important;
}
.icon-app-file a.app-file img {
  margin-top: -6px;
}
/**** User Details Backend Css ****/

.main-sec .emp-detail-btns {
  text-align: right;
}
.main-sec .each-row {
  margin-bottom: 10px;
}
.main-sec .ob-form-section {
  padding: 0px 45px;
}
.main-sec .right-details-section > div {
  min-height: auto;
  margin-bottom: 30px;
}
.main-sec .ob-form-field-only {
  padding: 5px 15px;
  min-height: 50px;
}
.OnBoarding .fa {
  font-size: 20px;
  padding: 8px 5px 0px 5px;
  margin-top: 3px;
}
.OnBoarding i.fa.fa-trash {
  font-size: 13px;
  margin-top: 0px;
  padding-top: 5px;
}

.stepwizard.rec_stages {
  margin-top: 30px;
}
.download-button {
  margin-bottom: 10px;
}
.header-title h3 {
  margin-bottom: 10px;
}

span.tmc_heading {
  float: initial;
  display: inline-block;
}
.tmc p:after {
  content: "";
  clear: both;
  display: -webkit-box;
}

.checkbox input {
  width: initial;
}
.profile-details p {
  word-break: break-word;
  direction: initial;
}

/*ONBOARDING ENDS*/

.commetn-sec {
  margin-top: 15px;
}
span.app_attachment {
  font-size: 13px;
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #585858;
  margin-top: 10px;
  margin-bottom: 15px;
  display: inline-block;
}
.download-a .fa {
  font-size: 20px;
  margin-top: 10px;
  display: inline-block;
}
div#approval-data {
  padding: 15px 0px;
}
.no-margin {
  margin: 0px !important;
}
.mar-left-15 {
  padding-left: 15px;
}

.notice-board-tools {
  border-bottom: 1px solid #efefef;
  padding: 0px 0px 8px 0px;
  display: block;
}
.notice-board-tools h4 {
  display: inline;
}
.no-notice {
  display: block;
  position: relative;
  font-size: 14px;
  padding: 15px 0 5px 0;
}
.notices {
  overflow: hidden;
}
.notices ul {
  position: relative;
  transition: 0.5s;
  margin: 0;
  padding: 0;
  height: 165px;
}
.notices ul li {
  list-style-type: none;
  width: 100%;
  height: 165px;
  overflow: hidden;
  position: absolute;
  top: 0;
  float: left;
  text-overflow: ellipsis;
}
#right-button {
  width: 0;
  height: 0;
  margin-left: 8px;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid var(--primary-color);
}
#left-button {
  width: 0;
  height: 0;
  margin-right: 8px;
  border-right: 8px solid var(--primary-color);
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
}
#left-button:hover {
  border-right: 8px solid #01a2c5;
}
#right-button:hover {
  border-left: 8px solid #01a2c5;
}
.notice-controls {
  width: 100%;
  position: relative;
  top: 6px;
  text-align: center;
}
.notice-controls div {
  display: inline-block;
}
.notices i,
.notice-board-tools i {
  cursor: pointer;
}
#notice-board-form {
  display: none;
}
.notices {
  overflow: hidden;
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
}
.notices ul li .notice-title {
  display: block;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
}
.notices ul li .notice-edit {
  height: 18px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.light {
  color: #b0b0b0;
  font-size: 13px;
  margin-top: 10px;
}
.vibe-right .btn-small {
  border: 1px solid var(--primary-color);
  background: none;
  font-size: 12px;
  color: var(--primary-color);
  display: inline-block;
  transition: all 0.5s ease;
}
.vibe-right .btn-small i {
  font-size: 11px;
  transition: all 0.5s ease;
}
.vibe-right .btn-small:hover i {
  color: #fff;
  transition: all 0.5s ease;
}
.vibe-right .btn-small:hover {
  color: #fff;
  background: var(--primary-color);
  transition: all 0.5s ease;
}
.margin-top-5 {
  margin-top: 5px;
}
.posts-info li {
  border-right: initial;
}
.readmore_notice {
  width: 65%;
  margin: 30px auto;
}
#notice-board-form .modal-header .close,
#readmore-form .modal-header .close {
  margin-top: 0px;
  /* margin-right: -20px; */
  font-size: 30px;
  position: absolute;
  right: 35px;
  top: 50%;
  transform: translateY(-50%);
}
.vibe-right .modal-header {
  position: relative;
}
.vibe-right .modal-title {
  margin-right: 50px;
}
.readmore_notice .modal-body {
  overflow: auto;
  max-height: 200px;
  margin: 20px 0px;
  padding-right: 15px;
  text-align: justify;
}
.readmore_notice .modal-footer {
  text-align: initial;
  border-top: 1px solid #ddd;
  padding: 15px 0;
}
.vibe-right .modal-content {
  padding: 15px 30px;
}
.notice-readmore {
  font-size: 12px;
}
p.posted_by {
  display: inline-block;
  color: #b1b1b1;
  font-size: 15px;
}
.notice-edit i {
  padding: 0px 4px;
}

.t_b a.info {
  position: absolute;
}

ul.suggestion-container {
  padding: 0px;
  border: 1px solid #dedede;
  margin-top: 0px;
  overflow-y: auto;
  width: 93%;
}
ul.suggestion-container li a {
  font-size: 14px;
  color: #7d7d7d;
  padding: 3px 10px;
  display: block;
}
ul.suggestion-container li a:hover {
  background: var(--primary-color);
  color: #fff;
}
.serchbar.search_employee_field {
  /* background: #fff url(../images/search.png) left no-repeat; */
  background-size: 18px;
  padding-left: 35px !important;
  background-position: 10px;
  height: 40px !important;
  color: #333 !important;
}

/**** Talent Profile Screen Css ***/
.user_profile {
  padding-top: 95px;
  padding-top: 140px;
  /* background: url(../images/talent_profile_bg.jpg); */
}
.profile_strip {
  background: #fff;
  box-shadow: 1px 1px 5px #e6e6e6;
}
.deatils_content {
  position: relative;
}
.deatils_content img {
  position: absolute;
  top: -35px;
  width: 110px;
  height: 110px !important;
  border: 2px solid #fff;
  border-radius: 2px;
  background: #0e74bd;
}
.profile_details {
  padding: 20px 0 20px 125px;
}
.profile_details h2 {
  font-size: 24px;
  font-weight: 700;
  text-align: justify;
  color: #4a4a4a;
  text-transform: capitalize;
  font-family: "lato", sans-serif;
  margin: 0px 0px 10px 0;
}
.profile_details ul {
  padding: 0px;
  margin: 0px;
}
.profile_details ul li {
  display: inline;
  padding-right: 30px;
  position: relative;
}
.profile_details ul li:after {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  background: #4a4a4a;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  border-radius: 50%;
}
.profile_details ul li:last-child:after {
  background: initial;
}
.profile_details ul li a {
  font-size: 15px;
  font-weight: 600;
  color: #4a4a4a;
}

.talent_profile_details {
  padding: 0px 0px 25px 0px;
}
.row_15 {
  margin-right: -15px !important;
  margin-left: -15px !important;
}
.profile_content {
  padding: 0px 25px;
}
.back_shadow {
  background: #fff;
  box-shadow: 0px 0px 1px 1px #ececec;
}
.title_sec {
  padding: 20px 0px;
  position: relative;
  border-bottom: 1px solid #ddd;
  margin-bottom: 15px;
}
.profile_quick_links .title_sec {
  padding: 20px;
}
.title_sec:after {
  content: "";
  clear: both;
  display: block;
}

.title_sec h3 {
  font-size: 20px;
  color: #4a4a4a;
  margin: 0px;
}

.profile_quick_links ul {
  padding: 0px;
  margin: 0px;
}
.profile_quick_links ul li a {
  padding: 15px 5px 15px 25px;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  text-align: left;
  color: #4a4a4a;
  display: block;
  text-transform: uppercase;
  position: relative;
  transition: all 0.5s ease-in;
}
.profile_quick_links ul li a:hover:before,
.profile_quick_links ul li.active a:before {
  content: "\f0da";
  font-family: fontAwesome;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
}
.profile_quick_links ul li a:hover,
.profile_quick_links ul li.active a {
  background: #4a4a4a;
  color: #fff;
  transition: all 0.5s ease-in;
}
.select-placeholder select {
  padding: 0px 0 0 35px;
  font-weight: 700;
  color: #444;
}
.select-placeholder:before {
  content: "Year :";
  position: absolute;
  font-size: 12px;
  text-align: left;
  color: #b6b6b6;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
}
.select-placeholder {
  position: relative;
}
.select-placeholder:before {
  content: "Year";
  position: absolute;
}
.performance-grid {
  max-width: 300px;
  margin-top: 23px;
}
.performance-grid ul {
  padding: 0px;
  margin: 0px;
  margin-bottom: 10px;
  position: relative;
}
.performance-grid ul:after {
  content: "";
  clear: both;
  display: block;
}
.performance-grid ul li {
  float: left;
  width: 86px;
  text-align: center;
  border-radius: 2px;
  color: #fff;
  margin: 2px;
  position: relative;
}
.light_bg {
  background: rgba(0, 118, 191, 0.25);
}
.mid_bg {
  background: rgba(0, 118, 191, 0.65);
}
.dark_bg {
  background: var(--primary-color);
}
.performance-grid ul li:nth-last-child(-n + 9) {
  height: 65px;
  margin-bottom: 2px;
}
.performance-grid p {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 700;
  color: #4a4a4a;
}
.performance-grid p span {
  font-weight: 300;
}
p.para_left_vertical {
  position: absolute;
  left: -26%;
  transform: rotate(-90deg);
  width: auto;
  bottom: 47%;
}
.top-pad-0 {
  padding-top: 0px;
}
tr.text-uppercase.tr-bg1.sub_goal th {
  font-size: 11px !important;
  letter-spacing: 1px;
  text-align: center;
  color: #9b9b9b !important;
  line-height: 14px !important;
  padding: 5px !important;
}
span.talent_stage {
  width: 15px;
  height: 15px;
  background: #fff !important;
  z-index: 99;
  display: inline-block;
  border-radius: 50%;
  border: 2px solid #33a1e4;
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0px auto;
}
#sb-title,
#sb-info {
  position: absolute !important;
  top: -20px;
  right: 0px;
}
.mar-bot-30 {
  margin-bottom: 30px;
}
.row_15 {
  margin-left: -15px;
  margin-right: -15px;
}
.cascade_goal_modal .goal_name {
  font-size: 12px;
  color: var(--primary-color);
}
.justify-content-space-between {
  display: flex;
  justify-content: space-between;
}
.justify-content-space-around {
  display: flex;
  justify-content: space-around;
}
.justify-content-space-evenly {
  display: flex;
  justify-content: space-evenly;
}
.flex-eq-width {
  flex: 1 1 0px;
}
.align-items-center {
  align-items: center;
}
.align-baseline {
  align-items: baseline;
}
.align-items-top {
  align-items: flex-start;
}
.align-items-bottom {
  align-items: flex-end;
}
.cascade_goal_modal .child_goals {
  display: -webkit-flex;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.cascade_goal_modal .child_goals:before {
  content: "";
  height: 1px;
  background: #ddd;
  width: calc(100% - 251px);
  display: block;
  position: absolute;
  top: 0px;
}
.cascade_goal_modal .each_child_goal:before {
  content: "";
  width: 1px;
  height: 16px;
  background: #ddd;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0px auto;
  top: -16px;
}
.cascade_goal_modal .each_child_goal {
  position: relative;
  display: inline-block;
  background: #fff;
  margin: 16px 10px 20px 10px;
  padding: 15px;
  border: 1px solid #dbe1e8;
  max-width: 232px;
  min-width: 232px;
}
.cascade_goal_modal ::-webkit-scrollbar:horizontal {
  height: 7px;
}
.cascade_goal_modal .controle_bottons {
  z-index: 999;
  margin-right: 35px;
}
.cascade_goal_modal .con_btn .fa {
  font-size: 20px;
  padding: 0px 5px;
  display: inline-block;
  color: #888;
}
.cascade_goal_modal .modal-body {
  overflow-y: hidden;
}
.cascade_goal_modal .organizers.organizers-modal {
  margin: 0px auto;
  min-width: 820px;
  position: relative;
}
.btns-right {
  text-align: right;
}
.btns-right div {
  display: inline-block;
}
.hr_separator {
  border-top: 1px solid #f9f8f8;
  padding-top: 15px;
}
.available-people {
  position: absolute;
  bottom: 0px;
  width: 68.2%;
}
.dahbord-emp-main {
  position: relative;
}
.dahbord-emp-right {
  min-height: 830px;
}
.note-editable ol ul li {
  list-style-type: initial !important;
}
.cascade_tree {
  height: 380px;
  overflow: hidden;
  background: #f7f8fa;
  padding: 30px;
}
.btn-default.active,
.btn-default:active,
.open > .dropdown-toggle.btn-default {
  color: #fff !important;
}
.disabled_sec {
  background: #eeeeef;
  border: 1px solid #ddd;
  padding: 5px;
  min-height: 80px;
}
.note-editing-area ol ul li {
  list-style-type: initial !important;
}

/**** New Table Css ****/

table.table_light thead {
  border-radius: 2px;
  background-color: #f7f8fa;
  border: solid 1px #e8ecf1;
}
table.table_light thead tr th {
  border: none !important;
  border-radius: initial !important;
  color: #616161 !important;
}
.table_light tbody tr td {
  border: none !important;
  border-bottom: 1px solid #e8ecf1 !important;
  padding: 10px 15px;
  font-size: 14px !important;
  font-family: "Roboto", sans-serif;
}
.actionicons a {
  padding: 0px 5px;
}

/*** Full Screen Modal Css ***/
.modal-fullscreen {
  padding: 0px !important;
}
.modal-fullscreen .modal-dialog {
  width: 100%;
  margin: 0px;
  padding: 0px;
}
.modal-fullscreen button.close {
  position: absolute;
  right: 15px;
  font-size: 25px;
}

/**** DB Tabs Css ****/

ul.nav.nav-tabs.db_tabs {
  width: fit-content;
}
.db_tabs > li {
  padding-right: 35px;
}
.db_tabs > li:last-child {
  padding-right: 0px;
}
.db_tabs > li > a {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  padding: 10px 0px;
}
.db-tab-content .fade.in {
  background: none;
}
.db-tab-content .popover.bottom,
.table_light .popover.bottom,
.table_light .popover.top {
  background: #fff !important;
  border-radius: 2px !important;
  z-index: 999;
  border: 1px solid #ddd;
}
.db-tab-content .popover-content,
.table_light .popover-content {
  padding: 4px 10px !important;
  font-size: 12px;
  color: #000;
  white-space: nowrap;
  background: #fff !important;
}
.db-tab-content .popover > .arrow,
.table_light .popover > .arrow {
  display: none !important;
}

.dotted_manager .popover.bottom {
  background: #fff1e9 !important;
  border-radius: 2px !important;
  z-index: 999;
  border: none;
}
.dotted_manager .tooltip.fade.in {
  background: #f44336 !important;
}
.dotted_manager .tooltip > .tooltip-inner {
  background-color: #f44336 !important;
}
.dotted_manager .tooltip > .tooltip-arrow {
  border-bottom-color: #f44336 !important;
}
.dotted_manager .popover-content,
.dotted_manager .info .color-tooltip-info {
  padding: 4px 8px;
  font-size: 12px;
  color: #f44336;
}

/*** Employee Self Service Css***/

.page-title {
  font-size: 22px;
  font-weight: 300;
  font-family: "Roboto", sans-serif;
}
.page-head {
  font-size: 22px;
  font-weight: 300;
  font-family: "Roboto", sans-serif;
  margin: 16px 0 0 0;
}
.sub-heading {
  font-size: 16px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  margin: 0 0 15px 0;
}
.sub-sub-heading {
  font-size: 14px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  margin: 0 0 15px 0;
  color: #2a333e;
}
.caption-text {
  font-size: 12px;
  color: #a5a2a2;
  font-family: "Roboto", sans-serif;
}
.btn-add {
  padding: 11px 25px;
  border: solid 1px #dbe1e8;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 500;
  color: var(--primary-color);
  background: transparent;
}
.btn-submit {
  padding: 8px 25px;
  border-radius: 2px;
  font-size: 12px;
  color: #f8f9fb;
  background: var(--primary-color);
}
.btn-cancel {
  padding: 8px 25px;
  border-radius: 2px;
  font-size: 12px;
  color: #adb6c1;
  background: #fff;
}
.btn-reset {
  padding: 8px 25px;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 500;
  color: #bbbbbb;
}
.db-btn-reset {
  color: #7d8d9f;
  font-size: 12px;
  background: none;
  border: none;
  font-weight: normal;
  height: 34px;
  display: flex;
  align-items: center;
}
.raise-request-header {
  background: #edf4fd;
  padding: 14px 0px 15px 0px;
}

.request-option-single {
  padding: 27px 0px;
  border-bottom: 1px solid #e8ecf1;
}
.widthCol3 {
  max-width: 277px;
  min-height: 90px;
}
.date_icon {
  position: relative;
}
.date_icon input {
  padding: 10px;
}
.date_icon:after {
  content: "";
  /* background: #fff url(../images/calendar.svg) right no-repeat; */
  position: absolute;
  top: 2px;
  right: 4px;
  height: 36px;
  width: 25px;
}
.request-box {
  max-height: 350px;
  border-radius: 2px;
  border: solid 1px #e8ecf1;
  overflow: auto;
  margin: 0;
}
.modal-footer .btn {
  margin-left: 10px;
}

.flow-details ul {
  padding: 0px;
}
.flow-details ul li {
  display: inline-block;
  padding-left: 40px;
  text-align: left;
}
.table_light a {
  color: #000;
}
.maodal-footer {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  background: #fff;
  box-shadow: 0 -6px 4px 0 rgba(232, 232, 232, 0.5);
  padding: 23px 0px;
  bottom: 0;
}
.overflow-scroll-sec {
  height: calc(100vh - 250px);
  overflow: scroll;
  margin-bottom: 80px;
}
.text-areamin {
  min-height: 90px;
  padding: 15px;
}
a.approval-status-view {
  font-size: 13px;
  font-weight: 500;
  color: var(--primary-color);
  font-family: "Roboto", sans-serif;
}
.fileBtn {
  font-size: 12px;
  text-transform: initial;
  color: #7d8d9f;
  border-radius: 2px;
  background-color: #f7f8fa;
  border: solid 1px #e8ecf1;
  padding: 6px 10px;
}

span#filesShowWrapper {
  color: #bcc6d6;
}
.multiple_file_attachment .file-name {
  text-transform: initial;
  color: #000000;
  border-radius: 2px;
  background-color: #f7f8fa;
  border: solid 1px #e8ecf1;
  padding: 10px 10px;
  font-weight: 400 !important;
  display: inline-block;
  margin-right: 5px;
}
.custom-file-upload ul li a {
  text-transform: initial;
  color: #000000;
  border-radius: 2px;
  background-color: #f7f8fa;
  border: solid 1px #e8ecf1;
  padding: 6px 15px;
  font-weight: 400 !important;
  display: inline-block;
  margin-right: 5px;
}
.custom-file-upload ul li a span {
  font-size: 12px;
  color: #7d8d9f;
  padding: 0 6px;
}
.custom-file-upload ul li {
  display: inline;
}
.custom-file-upload ul {
  padding: 0px;
}

.margin-t30b10 {
  margin: 30px 0 10px 0px;
}
.margin-top15 {
  margin-top: 15px;
}
.margin-top20 {
  margin-top: 20px;
}
.margin-top25 {
  margin-top: 25px;
}
.margin-bot20 {
  margin-bottom: 20px;
}
.trigger_selction {
  position: relative;
  padding-right: 20px;
}
.trigger_selction span {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.attachment_icons i {
  font-size: 19px !important;
  padding: 0px 5px !important;
}
.total-team li {
  display: inline-block;
  padding: 0px 10px;
  color: #a09d9d;
}
.total-team li span {
  font-size: 14px;
  color: #000;
}
.canvas_position {
  position: relative;
  width: 210px;
}
.canvas_position p {
  display: none;
  transition: all 0.5s ease;
  color: #0774cac4;
}
.canvas_position:hover p {
  display: inline-block;
  transition: all 0.5s ease;
}
.canvas_position p.header-text {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  top: 6px;
}
.canvas_position p.footer-text {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  bottom: -2px;
}
.helpdesk {
  /* background: url(../images/helpdesk_inactive.png) no-repeat top center; */
}
.helpdesk:hover {
  /* background: url(../images/helpdesk_active.png) no-repeat top center; */
}
.nav-helpdesk.active {
  /* background: url(../images/helpdesk_active.png) no-repeat top center; */
  background-size: 25px;
  color: var(--primary-color);
}
.issue_count_row .parent_panel {
  position: relative;
  height: 103px;
}
.issue_count_row .child_panel {
  position: absolute;
  width: 10px;
  top: 4px;
  right: 10px;
  z-index: 20;
  cursor: pointer;
  color: #777;
  z-index: 8;
}
.issue_count_row .child_panel i {
  font-size: 18px;
}
.issue_count_row .child_panel i:hover {
  color: var(--primary-color);
}
.issue_count_row .body_panel {
  font-weight: normal;
  font-family: latolight;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 7;
}
.issue_count_row .body_panel h4 {
  font-size: 30px;
  padding-top: 9px;
}
.issue_count_row .body_panel span {
  font-size: 14px;
}
.issue_count_row .hiring-step.dark-grey-selected {
  color: var(--primary-color);
  border: 2px solid var(--primary-color);
}
.issue_count_row .hiring-step {
  margin-bottom: 10px;
  padding: 12px 0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  color: #445;
  border: 1px solid #eee;
}
.helpdesk-above {
  border-top: 1px solid #efefef;
}
.helpdesk-above .bulk-actions-btn {
  left: auto;
  right: 0;
  background: #fff;
  color: var(--primary-color);
  padding-left: 15px;
  padding-right: 0px;
  border-right: 0px;
}
.helpdesk-above .bulk-menu:before {
  content: "";
  border-bottom: 10px solid #ccc;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  position: absolute;
  top: -11px;
  right: 48px;
  z-index: 9;
  left: 12px;
}
.helpdeskList .table-heading {
  text-transform: uppercase;
}
.helpdeskList thead.table-heading tr th {
  padding: 8px 10px;
}
.helpdeskList td {
  padding: 15px 10px;
  border-bottom: 1px solid #eee;
  min-height: 60px;
  max-width: 250px;
}
.helpdeskList tbody tr:hover {
  background: #f5f5f5;
}
.helpdeskList .row {
  margin-left: -15px;
}
.helpdeskList .dataTables_filter,
.helpdeskList .dataTables_length {
  display: none;
}
.helpdesk-above .search-job1 .serchbar {
  height: 30px !important;
}
.helpdesk-above .dataTables_paginate .paginate_active,
.helpdeskList .dataTables_paginate .paginate_active {
  margin-left: 5px;
  margin-right: 5px;
}
.assign_issue_modal .assign_issues {
  height: 40px;
}
.issue-status-div span.pull-right {
  margin-top: 10px;
}

.helpdesk_table td div.more-options-block {
  position: relative;
  display: inline;
  width: 82px;
  float: right;
}
.helpdesk_table tr:hover .more-option-button,
.helpdesk_table td div.open .more-option-button {
  display: inline;
}
.issue-details .curve-border {
  border-radius: 5px;
}
.issue-details .right-margin {
  margin-right: 10px;
}
.issue-details .top-margin {
  margin-top: 7px;
}
.issue-details .message-item {
  margin-bottom: 25px;
  margin-left: 40px;
  position: relative;
}
.issue-details .message-item .message-inner {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 10px;
  position: relative;
  margin-left: 10px;
}
.issue-details .message-item:before {
  background: #fff;
  border-radius: 2px;
  bottom: -30px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  content: "";
  height: 100%;
  left: -30px;
  position: absolute;
  width: 3px;
  top: 15px;
}
.issue-details .message-item:after {
  background: #fff;
  border: 2px solid #ccc;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  content: "";
  height: 15px;
  left: -36px;
  position: absolute;
  top: -3px;
  width: 15px;
}
.issue-details .message-item .message-head {
  border-bottom: 1px solid #eee;
  margin-bottom: 8px;
  padding-bottom: 8px;
}
.issue-details .message-item .message-head .avatar {
  margin-right: 20px;
}
.issue-details .message-item .message-head .user-detail {
  overflow: hidden;
}
.issue-details .message-item .message-head .user-detail h5 {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}
.issue-details .message-item .message-head .post-meta {
  float: left;
  padding: 0 15px 0 0;
}
.issue-details .message-item .message-head .post-meta > div {
  color: #333;
  font-weight: bold;
  text-align: right;
}
.issue-details .post-meta > div {
  color: #777;
  font-size: 12px;
  line-height: 22px;
}
.issue-details .message-item .message-head .post-meta > div {
  color: #333;
  font-weight: bold;
  text-align: right;
}
.issue-details .post-meta > div {
  color: #777;
  font-size: 12px;
  line-height: 22px;
}
.issue-details .issue-details .closure-bg {
  background-color: #eeeeee4d;
}
.issue-details p.issue_no {
  font-size: 24px;
  margin-bottom: 0px;
  color: #000;
}
.issue-details .edit_issue .fa {
  font-size: 14px;
  color: #000000;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  text-align: center;
  margin-top: 6px;
  margin-right: 10px;
}
.issue-details p.issue_description {
  font-size: 15px;
  color: #888;
  font-weight: 400;
  margin-top: 15px;
  font-family: "latoregular";
}
.issue-details .issue_details_list ul {
  padding: 0px;
}
.issue-details .issue_details_list ul li {
  display: inline-block;
  padding-right: 15px;
  padding-top: 15px;
}
.issue-details .issue_details_list ul li:first-child {
  padding-left: 0px;
}
.issue-details .issue_details_list ul li span {
  color: #a9a9a9;
  text-transform: capitalize;
}
.issue-details #attachment-comment-container,
.issue-details #i-attachment-container {
  margin-top: 10px;
}
.issue-details #attachment-comment-container .f-name,
.issue-details #i-attachment-comment-container .f-name {
  background: #eaeaea;
  padding: 5px 15px;
  margin-right: 8px;
  display: inline-block;
  border: 1px solid #ddd;
  color: #868686;
}
.issue-details #attachment-comment-container span .fa,
.issue-details #i-attachment-container span .fa,
.create_new_issue #i-attachment-container span .fa {
  margin-left: 10px;
  color: #949494;
}
.issue-details #attachment-comment-container .f-name,
.issue-details #i-attachment-container .f-name,
.create_new_issue #i-attachment-container .f-name {
  background: #eaeaea;
  padding: 5px 15px;
  margin-right: 8px;
  margin-top: 5px;
  display: inline-block;
  border: 1px solid #ddd;
  color: #868686;
}
.issue-details #attachment-comment-container .f-name .fa,
.issue-details #i-attachment-container .f-name .fa,
.create_new_issue #i-attachment-container .f-name .fa {
  margin-left: 20px;
  font-size: 16px;
}
.issue-details .issue_details {
  margin-bottom: 20px;
  border-bottom: 1px solid #e4e3e3;
  padding-bottom: 20px;
}
.issue-details .request-photo {
  display: inline-block;
}
.issue-details .comment_area textarea {
  width: 95%;
  position: relative;
  left: 2%;
  border-radius: 0;
  outline: none !important;
  box-shadow: none;
  border: 1px solid #ddd;
}
.issue-details .comment-textarea {
  position: relative;
}
.issue-details .issue-comment-section h4 {
  margin: 0 0 15px 0;
}
.issue-details .message-item .message-inner:before,
.issue-details .comment-textarea:before {
  color: #000;
  content: "";
  display: block;
  height: 0;
  position: absolute;
  left: 43px;
  top: 0px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 20px 20px 0;
  border-color: transparent #d5d6d8 transparent transparent;
}
.issue-details .message-item .message-inner:after,
.issue-details .comment-textarea:after {
  color: #fff;
  content: "";
  display: block;
  height: 0;
  position: absolute;
  left: 47px;
  top: 1px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 17px 17px 0;
  border-color: transparent #ffffff transparent transparent;
}
.issue-details .message-item .message-inner:before {
  left: -20px;
  top: -1px;
}
.issue-details .message-item .message-inner:after {
  left: -17px;
  top: 0;
}
.issue-details .comment_area .request-photo {
  margin-top: 15px;
}
.issue-details .comment_attachments label {
  color: #0374c5;
  padding: 0px 15px;
  cursor: pointer;
}
.issue-details .issue-comment-section {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e4e3e3;
}
.issue-details div#attachment-comment-container {
  text-align: right;
}
.issue-details #attachment-comment-container .f-name:last-child {
  margin-right: 0px;
}
.issue-details .comment_buttons {
  margin-top: 10px;
}
.download-button a:not(:last-child) {
  margin-right: 5px;
}
.fixednav.affix {
  z-index: 9999 !important;
}
.leave_recipients_list a {
  font-size: 13px;
  font-weight: 500;
  color: #575b5d;
  font-family: "Roboto", sans-serif;
  padding: 0px 8px 0 0px;
}
.leave_recipients_list a:not(:last-child):after {
  content: ",";
  padding-left: 2px;
}

.halfDayRow {
  padding-top: 6px;
  padding-bottom: 15px;
  min-height: 43px;
}
.switchbtnParent {
  float: left;
  padding-right: 23px;
}
.switchbtnParent .label {
  font-size: 13px !important;
  color: #4b5c72;
  font-weight: 400 !important;
}
.switchBtn {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 18px;
}

.switchbtnParent .label {
  font-size: 13px !important;
  color: #4b5c72;
  font-weight: 400 !important;
}
.switchBtn {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 18px;
}
.switchBtn input[type="checkbox"] {
  display: none;
}
.switchBtn input[type="checkbox"]:checked + label:before {
  width: 36px;
  height: 17px;
  right: 0px;
  background: var(--primary-color);
  border-radius: 15px;
}
.switchBtn input[type="checkbox"] + label:before,
.switchBtn input[type="checkbox"] + label:after {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -7.5px;
  box-sizing: border-box;
}
.switchBtn input[type="checkbox"] + label:before {
  width: 36px;
  height: 17px;
  right: 0px;
  background: #dbe1e8;
  border-radius: 15px;
}
.switchBtn input[type="checkbox"]:checked + label:after {
  right: 0px;
  background: white;
  right: 2px;
}
.switchBtn input[type="checkbox"] + label:after {
  width: 15px;
  height: 15px;
  right: 19px;
  background: white;
  margin-top: -6px !important;
  border-radius: 50%;
  -webkit-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
  cursor: pointer;
}
.switchBtn input[type="checkbox"] + label:before,
.switchBtn input[type="checkbox"] + label:after {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -7.5px;
  box-sizing: border-box;
}
.date_field_icon {
  /* background: #fff url(../images/calendar.svg) right no-repeat; */
  background-position-x: calc(100% - 6px);
}
#attachedFileNameShow {
  display: inline-block;
  background: #dbe1e8;
  float: left;
  padding: 6px 12px;
  border-radius: 2px;
  margin-top: 5px;
  font-size: 14px;
  color: #4b5c72;
}
#attachedFileNameShow .label {
  color: #4b5c72;
}
.closeAttchment {
  cursor: pointer;
  padding-left: 4px;
  opacity: 1;
}
.addReceptionWrapper {
  color: var(--primary-color);
  padding-bottom: 6px;
  padding-top: 6px;
  display: inline-block;
  cursor: pointer;
  margin-bottom: 15px;
}
.addReceptionWrapper .icon {
  cursor: pointer;
}
.addReceptionWrapper .text {
  font-size: 13px;
  padding-left: 4px;
}
#searchOtherEmployeeWrapper,
#addReceptionInputWrapper,
#halfDayShow {
  display: none;
}
#openAttachment {
  position: relative;
  height: 36px;
  width: 155px;
  padding: 6px 12px;
}
.uploadAttachmentBtn input {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 155px;
  height: 36px;
  opacity: 0;
}
.zabuto_calendar table tr td {
  border: none !important;
}
div.zabuto_calendar .table tr.calendar-dow-header th {
  color: #222222;
  font-size: 11px !important;
  font-weight: normal;
  text-transform: uppercase;
  padding: 5px 0;
}
.total-working-days {
  background: #f7f8fa;
  margin-bottom: 10px;
}
tr.calendar-month-header {
  border-bottom: 1px solid #ddd;
}
.total-working-days p {
  font-size: 13px;
  font-family: "Roboto", sans-serif !important;
  color: #4b5c72;
  margin: 0;
}
.total-working-days p img {
  width: 17px;
}
.total-working-days p span {
  color: #000;
}
.working-dayHeader {
  padding: 10px 0;
}
.working-dayHeader .fa {
  font-size: 18px;
  color: #a5a1a1;
  cursor: pointer;
  width: 30px;
}
.calendar-dow [id^="zabuto_calendar_"] {
  color: #ddd;
}
.appliedLeaves div {
  color: #000 !important;
}
.approved-leaves {
  border: solid 2px #a8cd1b;
}
.pending-leaves {
  border: solid 2px #c63d0f;
}
span.badge.approved {
  background-color: #a8cd1b;
  display: inline-block;
}
span.badge.pending {
  background-color: #c63d0f;
  display: inline-block;
}
span.legend-text .badge {
  min-width: 10px !important;
  height: 10px !important;
}
div#leave_recipients_list a:not(:last-child):after {
  content: ",";
}
.leaves-divider ul {
  padding: 0px;
  margin: 0px;
  border-top: 1px solid rgba(34, 34, 34, 0.1);
}
.leaves-divider ul li {
  display: inline-block;
  font-size: 13px;
  font-weight: 400;
  color: #aaabaf;
  font-family: "Roboto", sans-serif !important;
  padding: 10px 30px;
}
.leaves-divider ul li span {
  display: block;
  color: #000;
  font-size: 14px;
}
.leave_modal .modal-header {
  border-bottom: none;
}
.leave_modal .popup-title {
  font-size: 16px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  color: #292929;
}
.form-control.border-radius2 {
  border-radius: 2px !important;
}
 
select#leave_change {
  color: #444343 !important;
}
.color_default_label {
  color: #7d8d9f !important;
}
.color_active_label {
  color: #000 !important;
  font-weight: 600;
}
.leave_modal .token {
  background: #f7f8fa !important;
  border: solid 1px #d9d9d9;
  border-radius: 25px !important;
  padding: 3px;
  height: 28px !important;
}
.ratings_n_comments h4 {
  font-family: "Roboto", sans-serif;
}
.row_10 {
  margin-left: -10px;
  margin-right: -10px;
}
.row_5 {
  margin-left: -5px;
  margin-right: -5px;
}
.single_ratingCommentsec {
  border-bottom: 1px solid #ddd;
  margin-bottom: 12px;
  padding: 0px 0px 10px 0;
}
.single_ratingCommentsec:after {
  content: "";
  clear: both;
  display: block;
}
.single_ratingCommentsec p {
  margin: 0px;
}
.ratings_n_comments {
  margin-bottom: 30px;
}

/***** PMS Goal Setting Card Css *****/
.font-size12 {
  font-size: 12px;
}
.showDiv {
  display: block !important;
}
.m-t-35 {
  margin-top: 35px;
}
.each_goal_settingcard {
  border: 1px solid #e8ecf1;
  margin-bottom: 20px;
}
.goal_setting_header {
  margin: 0px -10px 10px -5px;
}

.goal_head img {
  float: left;
  margin-right: 10px;
}
.goal_head h2 {
  font-size: 17px;
  font-weight: 500;
  color: #2a333e;
  overflow: hidden;
  line-height: 22px;
  font-family: "Roboto", sans-serif;
  margin: 0px 0 15px 0;
}
.goal_start_end p {
  color: #2a333e;
  font-family: "Roboto", sans-serif;
}
.text-heiglight {
  color: #7d8d9f;
}
.goal-status-det {
  margin-left: 25px;
  margin-bottom: 15px;
}
.goal_start_end li {
  line-height: 25px;
  list-style-type: none;
}
.goal_start_end li:nth-child(2),
.goal_start_end li:nth-child(3) {
  width: 50%;
  float: left;
}
.goal-setting-other-details li {
  text-align: center;
}
.sub-goal-icon {
  width: 10px;
  height: 10px;
  background: #ddd;
  display: inline-block;
  border-radius: 50%;
  margin: 0px 2px !important;
}
.btn-goal {
  background: none;
  text-transform: initial;
  font-size: 13px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  padding: 5px;
  margin-right: 10px;
}
.add-sub-goal {
  color: var(--primary-color);
}
.btn-pending {
  color: #f44336;
  font-weight: 400 !important;
}
.btn-approved {
  color: #1ac964;
  font-weight: 400 !important;
}
.btn-goal img {
  margin-top: -3px !important;
  margin-right: 5px;
}
.goal-descriptions {
  display: -webkit-flex;
  display: flex;
  padding: 0px 20px;
}
.each-description-sec {
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 0px 25px;
}
.each-description-sec h3 {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: 500;
  text-align: justify;
  color: #2a333e;
}
.each_goal_settingcard p,
.goal_para {
  font-size: 14px;
  color: #4b5c72;
}
.over-hidden {
  overflow: hidden;
}
.sub-goals-details {
  border-top: 2px solid #dddddd63;
  margin-top: 15px;
  display: none;
}

ul.goal-det {
  padding: 0px;
  text-align: right;
  margin: 15px 0 0 0;
}
ul.goal-det > li {
  display: inline-block;
  text-align: center;
  padding: 0px 20px;
  border-left: 1px solid #ddd;
  vertical-align: middle;
  min-height: 50px;
}

.ach_icon {
  float: left;
  margin-right: 15px;
}
.ach_icon img {
  margin-top: 10px;
}
.goal-det .action_buttons ul {
  margin: 0px !important;
}
.achievement p {
  overflow: hidden;
  text-align: initial;
  font-family: "Roboto", sans-serif;
  margin: 0;
}
/* span.achivement-percent {
    font-size: 24px;
    font-weight: 300;
    color: #2a333e;
    display: block;
    } */
.hide_sec {
  margin-top: 10px;
}

/*
 * Responsive Bootstrap Tabs 
 */
@media screen and (max-width: 479px) {
  .nav-tabs-responsive > li {
    display: none;
    width: 23%;
  }
  .nav-tabs-responsive > li > a {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    width: 100%;
    width: 100%;
    text-align: center;
    vertical-align: top;
  }
  .nav-tabs-responsive > li.active {
    width: 54%;
  }
  .nav-tabs-responsive > li.active:first-child {
    margin-left: 23%;
  }
  .nav-tabs-responsive > li.active,
  .nav-tabs-responsive > li.prev,
  .nav-tabs-responsive > li.next {
    display: block;
  }
  .nav-tabs-responsive > li.prev,
  .nav-tabs-responsive > li.next {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  .nav-tabs-responsive > li.next > a,
  .nav-tabs-responsive > li.prev > a {
    -webkit-transition: none;
    transition: none;
    border: 0;
    color: var(--primary-color) !important;
  }

  .nav-tabs-responsive > li.next > a .text,
  .nav-tabs-responsive > li.prev > a .text {
    display: none;
  }
  .nav-tabs-responsive > li.next > a:after,
  .nav-tabs-responsive > li.next > a:after,
  .nav-tabs-responsive > li.prev > a:after,
  .nav-tabs-responsive > li.prev > a:after {
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: "Glyphicons Halflings";
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .nav-tabs-responsive > li.prev > a:after {
    content: "\e079";
  }
  .nav-tabs-responsive > li.next > a:after {
    content: "\e080";
  }
  .nav-tabs-responsive > li.dropdown > a > .caret {
    display: none;
  }
  .nav-tabs-responsive > li.dropdown > a:after {
    content: "\e114";
  }
  .nav-tabs-responsive > li.dropdown.active > a:after {
    display: none;
  }
  .nav-tabs-responsive > li.dropdown.active > a > .caret {
    display: inline-block;
  }
  .nav-tabs-responsive > li.dropdown .dropdown-menu.pull-xs-left {
    left: 0;
    right: auto;
  }
  .nav-tabs-responsive > li.dropdown .dropdown-menu.pull-xs-center {
    right: auto;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .nav-tabs-responsive > li.dropdown .dropdown-menu.pull-xs-right {
    left: auto;
    right: 0;
  }
  .nav-tabs-responsive li.next a:after,
  .nav-tabs-responsive li.prev a:after {
    height: min-content;
    transform: initial !important;
  }
  .nav-tabs.nav-tabs-responsive > li > a::after {
    background: initial;
  }
}
.nav-tabs.nav-tabs-responsive > li > a::after {
  background: transparent;
}
.nav-tabs.nav-tabs-responsive > li.active > a::after {
  background: #4285e6;
}

/**
 * Demo Styles
 */
.wrapper {
  padding: 15px 0;
}

@media (max-width: 479px) {
  #narrow-browser-alert {
    display: none;
  }
}
div#myTabContent {
  margin: 0px -20px;
}

.bs-example.bs-example-tabs #myTab {
  padding-left: 45px;
  border-bottom: none;
  padding-bottom: 2px;
}
.goals_tree {
  padding-bottom: 5px;
}
.goals_tree ul.sub_goal_tree {
  position: relative;
  padding: 15px 20px 0px 115px;
  margin: 0px;
}
.goals_tree ul.sub_goal_tree:after {
  position: absolute;
  content: "";
  width: 2px;
  height: 100%;
  background: #e8ecf1;
  top: 0px;
}
.sub-goals-details .fade.in {
  background: #f7f8fa !important;
}

.goals_tree ul.sub_goal_tree > li {
  background: #fff;
  padding: 15px;
  box-shadow: 0 0 1px 0 #bcc6d6;
  margin: 0px 0 15px 25px;
  position: relative;
  list-style-type: none;
}
span.side-triangle {
  position: absolute;
  left: -11px;
  top: 50%;
  transform: translateY(-50%);
}
.side-triangle:before {
  content: "";
  border-top: 8px solid transparent;
  border-right: 10px solid #e7ebf0;
  border-bottom: 8px solid transparent;
  position: absolute;
  left: -1px;
  top: -8px;
}
.side-triangle:after {
  content: "";
  border-top: 8px solid transparent;
  border-right: 10px solid white;
  border-bottom: 8px solid transparent;
  position: absolute;
  left: 1px;
  top: -8px;
}

.db-triangle-top:after {
  content: "";
  display: block;
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-color: transparent transparent #ffffff transparent;
  border-style: solid;
  border-width: 10px;
}

.db-triangle-down:after {
  content: "";
  display: block;
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-color: #ffffff transparent transparent transparent;
  border-style: solid;
  border-width: 10px;
}

.db-triangle-left:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  transform: translateX(-50%);
  left: -10px;
  width: 0;
  height: 0;
  border-color: transparent #ffffff transparent transparent;
  border-style: solid;
  border-width: 10px;
}

.db-triangle-right:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  transform: translateX(-50%);
  right: -30px;
  width: 0;
  height: 0;
  border-color: transparent transparent transparent #ffffff;
  border-style: solid;
  border-width: 10px;
}

.db-triangle-top_grey:before {
  content: "";
  display: block;
  position: absolute;
  top: -11px;
  left: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-color: transparent transparent #e8ecf1 transparent;
  border-style: solid;
  border-width: 10px;
}

.db-triangle-down_grey:before {
  content: "";
  display: block;
  position: absolute;
  bottom: -31px;
  left: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-color: #e8ecf1 transparent transparent transparent;
  border-style: solid;
  border-width: 10px;
}

.db-triangle-left_grey:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  transform: translateX(-50%);
  left: -11px;
  width: 0;
  height: 0;
  border-color: transparent #e8ecf1 transparent transparent;
  border-style: solid;
  border-width: 10px;
}

.db-triangle-right_grey:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  transform: translateX(-50%);
  right: -31px;
  width: 0;
  height: 0;
  border-color: transparent transparent transparent #e8ecf1;
  border-style: solid;
  border-width: 10px;
}

.goals_tree ul.sub_goal_tree > li:last-child:after {
  content: "";
  width: 2px;
  background: #f7f8fa;
  height: 50%;
  position: absolute;
  left: -25px;
  bottom: 0;
  z-index: 1;
}
li.goal-completed:before {
  content: " ";
  position: absolute;
  /* background: url(../../../images/Icons_latest/circle-right-mark.svg) no-repeat; */
  background-size: 100%;
  display: block;
  z-index: 2;
  height: 20px;
  width: 20px;
  left: -35px;
  top: 50%;
  transform: translateY(-50%);
}
li.goal-pending:before {
  content: " ";
  position: absolute;
  /* background: url(../../../images/Icons_latest/category.svg) no-repeat; */
  background-size: 100%;
  display: block;
  z-index: 2;
  height: 20px;
  width: 20px;
  left: -35px;
  top: 50%;
  transform: translateY(-50%);
}
li.goal-notstarted:before {
  content: " ";
  position: absolute;
  /* background: url(../../../images/Icons_latest/group-32.svg) no-repeat; */
  background-size: 100%;
  display: block;
  z-index: 2;
  height: 20px;
  width: 20px;
  left: -35px;
  top: 50%;
  transform: translateY(-50%);
}
span.goal_started_month {
  position: absolute;
  left: -105px;
  top: 50%;
  font-size: 12px;
  text-align: right;
  color: #7d8d9f;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  transform: translateY(-50%);
}

.action_buttons ul {
  padding: 0px;
  margin-top: 20px;
  text-align: center;
}
.action_buttons ul li {
  display: inline-block;
  padding: 10px;
}
.started-bg {
  background: #6ab94c;
}
.pending-bg {
  background: #ff7a28;
}
.notstarted-bg {
  background: #c3352b;
}
.br-theme-fontawesome-stars-o .br-widget a:after {
  font-size: 25px;
  padding: 2px;
}
.br-theme-fontawesome-stars-o .br-widget a.br-selected:after {
  color: #1ac964 !important;
}
.review_card h4 {
  font-size: 13px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
}
.review_card {
  border-top: 1px solid #e8ecf1;
  padding: 20px;
}
.dis_block {
  display: block;
}
textarea.form-control-new {
  border-radius: 2px !important;
  border: solid 1px #dbe1e8;
}

 
.fileBtn {
  font-size: 12px;
  text-transform: initial;
  color: #7d8d9f;
  border-radius: 2px;
  background-color: #f7f8fa;
  border: solid 1px #e8ecf1;
  padding: 6px 10px;
}
span.submited_rating {
  font-size: 16px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  color: #1ac964;
}
.side-head {
  float: left;
}
.given_rating {
  overflow: hidden;
  margin-left: 15px;
  float: left;
}
span.rating_cont {
  color: #4b5c72;
  font-family: "Roboto", sans-serif;
}
.border-tint-blue-1 {
  border: 1px solid #c4dcf7;
}
.border-left-1 {
  border-left: 1px solid #e8ecf1;
}
.border-right-1 {
  border-right: 1px solid #e8ecf1;
}
ul.attached_files {
  padding: 0px;
}
ul.attached_files li {
  background: #f5f5f5;
  border: 1px solid #f5f5f5;
  padding: 2px 8px;
  font-size: 12px;
  color: #7d8d9f;
  border-radius: 2px;
  margin-bottom: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 150px;
}
.font-size-50 {
  font-size: 50px !important;
}
/* .multiple_file_attachment .file-name-list {
    margin-top: 10px;
    } */
span#filesShowWrapper {
  color: #bcc6d6;
}
.review_sec {
  margin-bottom: 20px;
  padding-bottom: 30px;
  border-bottom: 1px solid #ddd;
}
.comment-part {
  position: relative;
}
a.edit-comment {
  position: absolute;
  top: 0;
  right: 15px;
}
.comment-part p {
  margin-right: 25px;
}
.goal_upper-section {
  padding: 20px;
}
.btn-submit {
  background: var(--primary-color);
  border-radius: 2px;
  font-size: 12px;
  font-weight: 500;
  text-transform: initial;
  color: #fff;
  font-family: "Roboto", sans-serif;
  padding: 8px 25px;
  border: 1px solid var(--primary-color);
  text-transform: uppercase;
}
.btn-draft {
  background: #fff;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 500;
  text-transform: initial;
  color: #dbe1e8;
  font-family: "Roboto", sans-serif;
  padding: 8px 25px;
  border: 1px solid #dbe1e8;
  text-transform: uppercase;
}
.border-1px {
  border: 1px solid #e8ecf1;
  margin-bottom: 15px;
}
.pms-rating a.br-selected ~ a {
  background: transparent;
}

span.bulk_onboard_info {
  color: #9ba5bc;
}
span.bulk_onboard_info img {
  width: 20px;
  margin-right: 4px;
  margin-top: -3px;
}
.bulk_action_modal .ob-template label {
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  color: #9b9b9b !important;
  text-transform: uppercase;
}
.max-height155 {
  max-height: 155px;
  overflow: hidden;
}
.max-height155:hover {
  overflow: auto;
}
.has-switch {
  border-radius: 25px !important;
}
.has-switch label {
  z-index: 1 !important;
}
::-webkit-scrollbar {
  width: 5px;
  height: 7px;
  background-color: #fff;
}
.onboarding {
  /* background: url(../images/onboarding_inactive.png) no-repeat top center; */
  background-size: 45px auto !important;
}
.onboarding:hover {
  /* background: url(../images/onboarding_active.png) no-repeat top center; */
}
.nav-onboarding.active {
  /* background: url(../images/onboarding_active.png) no-repeat top center; */
  background-size: 25px;
  color: var(--primary-color);
}
@media only screen and (min-width: 1366px) {
  .before:before,
  .beforenew:before {
    border-left: 2px solid var(--primary-color);
  }
  .devider:before {
    border-top: 2px solid var(--primary-color);
  }
  .before-l:before {
    border-left: 2px solid var(--primary-color);
  }
  .beforenew:after {
    border-left: 2px solid var(--primary-color);
    top: 80px;
  }
  .before5:before,
  .before5:after {
    width: 2px;
    top: 80px;
  }
}

.alert-success.requestDiv .btn-hoverlayer {
  display: none !important;
}
.box_bg {
  position: relative;
}
a.download-pdf {
  position: absolute;
  right: 10px;
  top: 14px;
  display: none;
  background: #fff;
  padding: 2px 10px;
}
.box_bg:hover a.download-pdf {
  display: block;
}
a.download-pdf img {
  width: 30px;
  height: auto;
}

.cascade_goal_modal .modal-header {
  padding: 20px;
  position: relative;
}
.goalCascade_details {
  padding: 30px 15px;
}
.cascade_details {
  margin-bottom: 35px;
}
.cascade_progressbar {
  height: 6px;
  margin: 10px 0px;
  box-shadow: initial !important;
  background: #e8ecf1;
}
.weightage p,
.completed p {
  font-size: 20px;
  font-weight: 300 !important;
  font-family: lato;
}
.weightage p span,
.completed p span {
  font-size: 14px;
  font-weight: 400;
}
.displayblock {
  display: block;
}
.sub-goals-tabs .nav-tabs {
  border-bottom: none;
}
.sub-goals-tabs .nav-tabs > li > a::after {
  content: "";
  background: none !important;
}
.sub-goals-tabs .nav-tabs > li > a:hover {
  background: var(--primary-color) !important;
  color: #fff !important;
  z-index: 99;
}
.sub-goals-tabs .nav-tabs > li.active > a:hover {
  background: var(--primary-color) !important;
  color: #fff !important;
  z-index: 99;
}
.sub-goals-tabs ul li {
  position: relative;
}
.sub-goals-tabs ul li a {
  border: 1px solid #ddd;
  border-radius: 50%;
  margin: 0px 20px;
  width: 39px;
  height: 39px;
  z-index: 99;
  background: #fff;
}
.sub-goals-tabs ul li:after {
  content: "";
  background: #dbe1e8;
  width: 100%;
  position: absolute;
  height: 1px;
  top: 50%;
}
.sub-goals-tabs ul li.active a {
  background: var(--primary-color) !important;
  color: #fff !important;
  border: 1px solid var(--primary-color);
}
.sub-goals-tabs ul li.active a:hover {
  background: none !important;
}
.sub-goals-tabs ul li:first-child a {
  margin-left: 0px;
}
.sub-goals-tabs ul li:last-child:after {
  width: 50%;
  left: 0;
}
.sub-goals-tabs .tab-pane {
  background: none !important;
}
.sub-goals-tabs .tab-content {
  padding: 25px 0px 0px 0px;
}
.subgoal-completed:before {
  content: "\f00c";
  font-family: FontAwesome;
  right: 20px;
  position: absolute;
  top: 0;
  z-index: 999;
  background: #1ac964;
  color: #fff;
  font-size: 8px;
  width: 14px;
  height: 14px;
  text-align: center;
  line-height: 12px;
  border-radius: 50%;
}
#organizers_width .organizers a {
  border-radius: 2px;
  padding: 15px;
}
#organizers_width .organizers a {
  text-align: initial;
}
.green_bar {
  background: #1ac964;
}
.orrange_bar {
  background: #ff8d21;
}
.orrange_font {
  color: #ff8d21;
}
.organizers a.active .progress {
  background-color: #115eb4;
  border-radius: 5px;
  box-shadow: none !important;
}
.child_goals_peers span.active {
  background: var(--primary-color);
  color: #fff;
  border-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  box-shadow: 0 10px 20px 0 rgba(22, 118, 226, 0.5);
  margin: 16px 10px 20px 10px;
  min-width: 232px;
  max-width: 232px;
  border-radius: 2px;
  padding: 15px;
  position: relative;
}
.child_goals_peers span.active:before {
  content: "";
  width: 1px;
  height: 16px;
  background: #ddd;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0px auto;
  top: -17px;
}
.peers-sub-goals span.active:after {
  content: "";
  width: 1px;
  height: 20px;
  background: #b5b5b5;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0px auto;
  bottom: -21px;
}
.child_goals_peers .each_goal_check {
  text-align: left;
}
.child_goals_peers span.active .progress {
  background-color: #115eb4;
  border-radius: 5px;
  box-shadow: none !important;
}
#organizers_width .organizers a.active {
  background: var(--primary-color);
  color: #fff;
  border-color: var(--primary-color);
  box-shadow: 0 10px 20px 0 rgba(22, 118, 226, 0.5);
  margin: 15px 15px 25px 15px;
}
.each_child_goal a {
  background: none;
  border: none;
  padding: 0px !important;
  margin: 0px;
}
#organizers_width .progress-bar {
  border-radius: 6px;
}
.each_child_goal a:hover {
  background: none;
}
.zoom-btns {
  position: absolute;
  right: -15px;
  top: -15px;
  z-index: 99;
}
.cascade_goal_modal .modal-header .close {
  margin-top: 0px;
  margin-right: 0px;
  position: absolute;
  right: 20px;
  top: 35%;
  z-index: 999;
}
.sub-heading span {
  float: right;
}
.cascade_goal_modal .ui-widget-content {
  background: #e8ecf1;
}
.cascade_goal_modal .ui-state-default,
.cascade_goal_modal .ui-widget-content .ui-state-default,
.cascade_goal_modal .ui-widget-header .ui-state-default {
  background-color: #eee;
  border: 1px solid #eee !important;
  color: #333 !important;
  background-image: none;
  box-shadow: none;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  top: -0.6em;
}
.no-result {
  margin-left: 5px;
}
.prefix_suffix_panel .form-check-inline,
.prefix_suffix_panel .form-check {
  width: 32%;
}
.star-five .br-widget {
  border: none !important;
}
.star-five .br-widget a {
  margin: 10px 0;
  position: relative;
  display: inline-block !important;
  color: transparent !important;
  width: 0px !important;
  height: 0px !important;
  border-right: 14px solid transparent;
  border-bottom: 10px solid #bef5e8;
  border-left: 14px solid transparent;
  transform: rotate(35deg);
  overflow: initial !important;
  padding: 0px !important;
  background: transparent !important;
}
.star-five .br-widget a:before {
  border-bottom: 10px solid #bef5e8;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  position: absolute;
  height: 0;
  width: 0;
  top: -7px;
  left: -10px;
  display: block;
  content: "";
  transform: rotate(-35deg);
}
.star-five .br-widget a:after {
  position: absolute;
  display: block;
  color: #bef5e8;
  top: 1px;
  left: -15px;
  width: 0px;
  height: 0px;
  border-right: 14px solid transparent;
  border-bottom: 10px solid #bef5e8;
  border-left: 14px solid transparent;
  transform: rotate(-70deg);
  content: "";
}

.star-five .br-widget a:hover,
.star-five .br-widget a:hover:after,
.star-five .br-widget a:hover:before,
.star-five .star-clicked,
.star-five .star-clicked:after,
.star-five .star-clicked:before,
.star-five .pre-active,
.star-five .pre-active:after,
.star-five .pre-active:before {
  border-bottom: 10px solid #30d7b1 !important;
}

.br-theme-bars-pill.star-five .br-widget a.br-active:after,
.br-theme-bars-pill.star-five .br-widget a.br-selected:after {
  position: absolute !important;
  display: block !important;
  color: #bef5e8 !important;
  border: initial !important;
  top: 1px !important;
  left: -15px !important;
  width: 0px !important;
  height: 0px !important;
  border-right: 14px solid transparent !important;
  border-bottom: 10px solid #30d7b1 !important;
  border-left: 14px solid transparent !important;
  transform: rotate(-70deg) !important;
  content: "" !important;
  background: initial !important;
  border-radius: initial !important;
  box-shadow: initial !important;
}
.star-five .bar-pill-txt {
  font-size: 14px !important;
}

.doc-edit-hr ol ul li {
  list-style-type: initial;
}
.bg-wheight {
  background: #fff !important;
}

/*.selecte-btn:after{
    content: "\f0dd";
    font-family: FontAwesome;
    left:-5px;
    position:absolute;
    top:0;
    }*/
.selecte-btn:focus,
.select-attr:focus {
  outline: none;
}
.selecte-btn span.caret {
  float: right;
  margin-top: 8px;
}
.select-attr {
  background: none;
  border: none;
  font-size: 12px;
  color: #4b5c72;
  max-width: 200px;
  padding: 4px 0px;
}
#resetfilters {
  position: absolute;
  top: 5px;
  right: 55px;
  z-index: 0;
}
/*.search-sec {
    width: calc(100% - 165px);
    float: left;
    position: relative;
    }*/
/*.rec-candidate-search input.form-control.serchbar {
    float: left;
    height: 36px !important;
    border-top-right-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
    padding-right: 65px;
    }*/
ul.dropdown-menu.attr-menu {
  max-width: 200px;
  right: 0;
  left: initial;
}
.dropdown.attr-drowpdown {
  display: inline-block;
  padding: 0px 8px;
}
input.form-control.serchbar::-webkit-input-placeholder {
  color: #bfc8d5;
}
input.form-control.serchbar::-moz-placeholder {
  color: #bfc8d5;
}
input.form-control.serchbar:-ms-input-placeholder {
  color: #bfc8d5;
}
input.form-control.serchbar:-moz-placeholder {
  color: #bfc8d5;
}
/** Directory Search CSS**/

.search-item h5 {
  font-size: 13px;
  color: #4b5c72;
  font-weight: 500;
  margin: 0px;
  transition: all 0.5s ease;
  font-family: "Roboto", sans-serif;
}
.search-item {
  box-shadow: inset 0 -1px 0 0 #e8ecf1;
  padding: 10px 19px;
  transition: all 0.5s ease;
  color: #7d8d9f;
}

.search-item:hover {
  background: #f7f8fa;
  transition: all 0.5s ease;
}
.search-item:hover h5 {
  color: var(--primary-color);
  transition: all 0.5s ease;
}

.des-deprt span {
  font-size: 12px;
  display: inline-block;
  padding: 0px 10px 0px 10px;
  color: #7d8d9f;
  line-height: 10px;
  margin-top: 5px;
  border-left: 1px solid #7d8d9f;
}
.des-deprt span:first-child {
  padding: 0px 10px 0px 0px;
  border-left: none;
}
/***** Recruitment css *****/

.btn-dtable {
  border-radius: 2px;
  background-color: #ffffff;
  border: solid 1px #dbe1e8;
  font-family: "Roboto", sans-serif;
  font-size: 11px;
  font-weight: 500;
  color: var(--primary-color);
  text-transform: uppercase;
  padding: 5px 15px;
  margin-right: 10px;
}
.top-toolbar label {
  margin-bottom: 0px;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: normal;
  letter-spacing: 0.2px;
  color: #4b5c72;
}
.top-toolbar select {
  border: none;
  background: no-repeat;
  width: auto !important;
  margin-bottom: 0px;
}
.top-toolbar select:focus {
  outline: none;
}
table#datatabs thead {
  background-color: #f7f8fa;
  box-shadow: inset 0 -1px 0 0 #dbe1e8;
}
table#datatabs thead tr th {
  border: initial !important;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #7d8d9f;
}
.rec-acions {
  position: relative;
}
.hoverable-actions {
  position: absolute;
  background: #fff;
  height: 100%;
  opacity: 1;
  transition: all 0.5s ease;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  text-align: right;
  background: #fff;
  height: 40px;
  /* padding: 0px 15px; */
  width: 0px !important;
  overflow: hidden;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.hoverable-actions:hover {
  overflow: initial !important;
}
.hoverable-actions > a,
.hoverable-actions > span,
.hoverable-actions > div {
  padding: 0px 3px;
}
table.dataTable tbody tr:hover .hoverable-actions {
  opacity: 1;
  transition: all 0.5s linear;
  padding: 0px 15px;
  width: 290px !important;
}
a#query-search {
  font-size: 14px;
  height: 36px;
  padding: 8px 28px;
}
a.jobcand-search {
  position: absolute;
  right: 12px;
  top: 7px;
}
/* .paginate_button.previous.disabled i,
.paginate_button.next.disabled i {
    color: #ddd !important;
} */
.btn-text {
  font-family: Roboto;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--primary-color);
  padding: 10px 8px;
  cursor: pointer;
}
.btn-text-hover {
  border: 1px solid transparent;
  transition: all 0.5s ease;
  border-radius: 2px;
}
.btn-text-hover:hover {
  border: 1px solid var(--primary-color);
  transition: all 0.5s ease;
}
button.btn-text {
  background: none;
  border: 1px solid transparent;
}
button.btn-text:focus {
  outline: none !important;
}
.search-sec .dropdown-menu {
  max-width: 610px;
  border-radius: 2px !important;
  background-color: #ffffff;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.24), 0 6px 10px 0 rgba(0, 0, 0, 0.08),
    0 1px 3px 0 rgba(0, 0, 0, 0.08);
  border: none;
  z-index: 9;
  left: initial;
  right: 2px;
}
span.action-btn img {
  width: 18px;
  height: 24px;
}

.padding-40 {
  padding: 40px;
}
.candidate-filter-dropdown h3 {
  color: #4b5c72 !important;
  font-size: 12px !important;
}
.form-control.form-control-new {
  border-radius: 2px !important;
  border: solid 1px #dbe1e8;
}
.row-action {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
ul.action-dropdown {
  position: absolute;
  min-width: 200px;
  right: 0;
  bottom: 36px;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.24), 0 6px 10px 0 rgba(0, 0, 0, 0.08),
    0 1px 3px 0 rgba(0, 0, 0, 0.08);
  padding: 20px;
  display: none;
  text-align: initial !important;
  z-index: 9;
  margin: 0px;
}
ul.action-dropdown li a {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.57;
  letter-spacing: normal;
  color: #2a333e;
  transition: all 0.5s ease;
  display: block;
}
ul.action-dropdown li a:hover {
  color: var(--primary-color);
  transition: all 0.5s ease;
}
.filter-applied:before {
  content: "";
  width: 8px;
  height: 8px;
  background: #f44336;
  position: absolute;
  right: -2px;
  border-radius: 50%;
  top: 0px;
}
.hover-effect::after {
  content: "";
  display: block;
  /*pacity: 0;*/
  position: absolute;
  transition-duration: 0.15s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  z-index: -1;
  bottom: -10px;
  left: -10px;
  right: -10px;
  top: -10px;
  background: none;
  border-radius: 50%;
  box-sizing: border-box;
  transform: scale(0);
  transition-property: transform, opacity;
}
.hover-effect:hover::after {
  background-color: rgba(255, 255, 255, 0.2);
  border: none;
  opacity: 1;
  transform: scale(1);
}
.select-from:before {
  content: "From :";
  position: absolute;
  left: 8px;
  top: 10px;
  color: #bcc6d6;
}
.select-from input {
  padding: 0 0 0 48px;
  color: #000;
}
.select-to:before {
  content: "To :";
  position: absolute;
  left: 18px;
  top: 10px;
  color: #bcc6d6;
}
.select-to input {
  padding: 0 0 0 48px;
  color: #000;
}
.candidate-filter-dropdown .chosen-container.chosen-container-multi {
  width: 100% !important;
}
.candidate-filter-dropdown .chosen-container-active .chosen-choices {
  border: 1px solid #dbe1e8;
  box-shadow: initial !important;
}
.candidate-filter-dropdown .chosen-container-multi .chosen-choices {
  padding: 4px 5px;
  border: solid 1px #dbe1e8;
  background-color: #fff;
  background-image: none;
  box-shadow: none !important;
  border-radius: 2px;
}
.candidate-filter-dropdown .chosen-container .chosen-drop {
  border: solid 1px #dbe1e8;
}
.duplicity_checkboxes .checkbox:not(:last-child) {
  margin-bottom: 10px;
}
.text-initial {
  text-transform: initial;
}
.text-none {
  text-transform: none !important;
}
.single-custom-field-setting {
  padding: 10px 0px 0px 0px;
}
span.warning_message {
  color: #f38231;
}
.multiselect-container .checkbox input[type="checkbox"] {
  opacity: 1 !important;
}
.multiselect-container {
  width: fit-content;
}
.down-arrow {
  font-size: 18px;
  position: relative;
  top: 5px;
  left: 5px;
}

.btn-addnew {
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--primary-color);
  padding: 8px 5px;
  font-family: "Roboto", sans-serif;
}
.btn-submit-borderd {
  border-radius: 2px;
  background-color: #ffffff;
  border: solid 1px #dbe1e8;
  color: var(--primary-color);
  font-size: 12px;
  font-weight: 500 !important;
  font-family: "Roboto", sans-serif;
  padding: 10px 15px;
}

.table-details ul {
  padding: 0px;
  list-style-type: none;
}
.table-details ul li {
  display: inline-block;
  padding-right: 30px;
}
input.no_of_positions_field {
  width: 60px;
  height: 30px;
  box-shadow: none;
  border: solid 1px #dbe1e8;
  border-radius: 2px;
  padding: 5px;
}

.add_job_description {
  font-size: 13px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  color: var(--primary-color);
  margin: 10px;
  display: inline-block;
}
.add_job_description df {
  font-size: 14px;
  border-radius: 50%;
  border: 1px solid var(--primary-color);
  margin-right: 5px;
  font-weight: bold;
  position: relative;
  top: 1px;
}
.job_description {
  display: none;
  margin-bottom: 20px;
}
.showDiv {
  display: block;
}
.description_notes li {
  list-style-type: circle;
  line-height: 22px;
  margin-bottom: 10px;
}
.form-control.border-radius-2 {
  border-radius: 2px !important;
}
.border-radius-4 {
  border-radius: 4px !important;
}
.border-radius-2 {
  border-radius: 2px !important;
}
.box-shadow-1 {
  box-shadow: 0 11px 55px 10px rgba(0, 0, 0, 0.07),
    0 13px 18px -8px rgba(0, 0, 0, 0.15);
}
.box-shadow-2 {
  box-shadow: 0 0 10px 0 rgba(146, 144, 144, 0.19);
}
.chosen-container-multi .chosen-choices {
  padding: 5px !important;
  border: 1px solid #dbe1e8 !important;
  background-image: none !important;
  border-radius: 2px;
}
.getreimball div#filterBySelect h5 {
  display: inline-block;
}
.getreimball select#expenses_dynamic_tabs {
  display: inline-block;
  width: 75%;
  margin-left: 10px;
}
.dbSelect {
  font-size: 14px !important;
  padding: 7px 30px 7px 12px !important;
  border-radius: 2px !important;
  border: 1px solid #dbe1e8 !important;
  color: #4b5c72 !important;
  box-shadow: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  position: relative;
  background-size: 32px;
  background-image: url("data:image/svg+xml;utf8,<svg fill='%23e4e8ef' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>") !important;
  background-repeat: no-repeat !important;
  background-position: calc(100% - 0px) !important;
  height: 36px !important;
}
.dbSelect option {
  color: #2a333e !important;
}
.dbSelect:focus {
  border-color: var(--primary-color) !important;
  box-shadow: 0 3px 8px 0 rgba(22, 118, 226, 0.16);
  -webkit-box-shadow: none;
  transition: all 0.5s ease;
}
.dbSelect:hover {
  border-color: #96c8da !important;
  -webkit-box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15);
  box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15);
}
.dbSelectMultiple {
  height: auto !important;
}

.dbSelect-sm {
  padding: 0px 30px 0px 12px !important;
  height: 30px !important;
  position: relative;
  top: 1px;
}

/**** Icon animation CSS ****/

.fill-circle {
  width: 30px;
  height: 30px;
  position: relative;
  transition: border-color 0.2s ease-in-out;
  cursor: pointer;
  color: #bcc6d6 !important;
}
.fill-circle:after {
  content: " ";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0px;
  height: 0px;
  background: #c5c5c545;
  transition: all 0.2s ease-in-out;
}

.fill-circle {
  border-radius: 100%;
}
.fill-circle:after {
  border-radius: 100%;
}
.fill-circle:hover:after {
  top: -7px;
  left: -7px;
  width: 36px;
  height: 36px;
}

.note-editor .note-toolbar .note-btn-group button:focus {
  color: #fff !important;
  background-color: var(--primary-color) !important;
}

.note-btn-group .dropdown-menu > li > a.checked span {
  color: var(--primary-color) !important;
}

.note-btn-group .dropdown-menu > li > a.checked {
  background: #f7f8fa;
}

span.custom-icon .directory-avatar {
  width: 28px;
  height: 28px;
  background: #dbe1e8;
  font-family: "Roboto";
  font-size: 11px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: normal;
  color: #7d8d9f;
}
.note-placeholder {
  color: #bfc8d5 !important;
}
.down-arrow {
  font-size: 18px;
  position: relative;
  top: 5px;
  left: 5px;
}

/*** Settings Tabs ***/
ul.nav.nav-tabs {
  margin-bottom: 30px;
}
.settings-tabs .nav-tabs > li {
  padding-right: 30px;
}
.settings-tabs .nav-tabs > li > a {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  color: #2a333e;
  padding: 8px 0px;
}
.settings-tabs .fade.in {
  background: transparent;
}

.db-table thead {
  border-radius: 2px;
  background-color: #f7f8fa;
  border: solid 1px #e8ecf1;
}
.table.db-table > thead > tr > th {
  color: #7d8d9f !important;
  padding: 5px 12px !important;
}
.table.db-table > thead > tr > th,
.table.db-table > tbody > tr > td {
  border-left: initial !important;
  font-family: "Roboto", sans-serif;
  color: #4b5c72;
}
.table.db-table .dbcheckbox-new {
  padding-top: 4px;
}
.bradius2 {
  border-radius: 2px !important;
}
.form-control.bradius2 {
  border-radius: 2px !important;
}
.immybox.immybox_witharrow {
  /* background-image: url(../images/immybox-arrow.png) !important; */
}
.multiselect {
  font-size: 14px;
  padding: 17px 15px 17px 12px;
  border-radius: 2px;
  border: 1px solid #dbe1e8;
  color: #353f4c !important;
  -webkit-text-fill-color: inherit;
  box-shadow: none;
  caret-color: var(--primary-color);
  background: #fff;
  transition: all 0.5s ease;
  text-transform: initial;
  font-weight: 500 !important;
  line-height: 0;
  width: 100%;
  font-weight: 400 !important;
  text-align: initial;
}

.multiselect b.caret {
  float: right;
  right: -8px;
  position: relative;
}

.multiselect-native-select input[type="checkbox"],
.multiselect-native-select input[type="radio"] {
  opacity: 1 !important;
}

.multiselect-native-select .btn-group,
.multiselect-native-select .btn-group-vertical {
  width: 100% !important;
}
.multiselect-native-select .input-group-addon,
.multiselect-native-select .input-group-btn {
  display: none;
}
.multiselect-native-select .dropdown-menu {
  width: auto !important;
  max-height: 200px;
  overflow: auto;
  border: 1px solid #dbe1e8;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  z-index: 11;
}
.multiselect-container > li > a > label {
  padding: 3px 20px 3px 25px;
}
.multiselect-container .input-group {
  display: -webkit-box !important;
}
.multiselect-container.dropdown-menu > .active > a {
  background-color: transparent;
}
.multiselect-container.dropdown-menu > .active > a:hover {
  background-color: var(--primary-color);
}
.multiselect-container.dropdown-menu a:hover label {
  color: #fff !important;
}
.multiselect-container.dropdown-menu a label {
  color: #2a333e !important;
}
table.table.db-table td {
  vertical-align: top !important;
}
.edit-field-setting df {
  font-size: 20px;
  color: #bcc6d6 !important;
  cursor: pointer;
}
.dbcheckbox-new input[type="checkbox"]:focus {
  outline: none !important;
}

.dbcheckbox-new input[type="checkbox"]:checked + label::after {
  font-family: "darwinbox" !important;
  font-size: 20px;
  left: -4px;
  top: 0;
  font-weight: bold;
}

.dbcheckbox-new.semi-checked input[type="checkbox"]:checked + label::after {
  content: "" !important;
  left: 0px;
  position: absolute;
  color: #fff;
  height: 17px;
  width: 18px;
  background: #0d6efd;
  text-align: center;
  padding: 0;
  display: inline;
  border-radius: 2px;
  z-index: 1;
  pointer-events: none;
}
.semi-checked .form-check-input:checked {
  z-index: 1;
}
.semi-checked {
  position: relative;
}
.dbcheckbox-new.semi-checked input[type="checkbox"]:checked + label::before {
  content: "";
  display: inline-block;
  width: 12px !important;
  height: 2px !important;
  background-color: #fff !important;
  border: none !important;
  position: absolute;
  left: 3px;
  top: 7px;
  z-index: 2;
  pointer-events: none;
}

.dbcheckbox-new label {
  outline: none;
  color: #4b5c72;
}
.dbcheckbox-new label::before {
  width: 18px !important;
  height: 18px !important;
}

.prevent-pointer-events .note-editable {
  background: #ddd !important;
}
.prevent-pointer-event {
  pointer-events: none !important;
  opacity: 0.5;
}
.prevent-pointer-events {
  pointer-events: none !important;
  opacity: 0.7 !important;
}
.form-check.prevent-pointer-events input[type="checkbox"]:checked,
.form-check-inline.prevent-pointer-events input[type="checkbox"]:checked {
  background-color: #c1c1c1 !important;
  border-color: #c1c1c1 !important;
}

.dbcheckbox-new input[type="checkbox"]:checked + label::before {
  background-color: #1676e2 !important;
  background-color: var(--primary-color) !important;
  border-color: #1676e2 !important;
  border-color: var(--primary-color) !important;
  border-radius: 2px !important;
}

.dbcheckbox-new.readonly_checkbox
  input[type="checkbox"]:checked
  + label::before {
  background-color: rgba(22, 118, 226, 0.4) !important;
  border: 2px rgba(22, 118, 226, 0.4) !important;
  border-radius: 2px !important;
}
.dbcheckbox-new label::before {
  border: 2px solid #dbe1e8 !important;
  border-radius: 2px !important;
}

.dbcheckbox-new.prevent-pointer-events
  input[type="checkbox"]:checked
  + label::before {
  background-color: #c1c1c1 !important;
  border-color: #c1c1c1 !important;
}

input.form-control.multiselect-search {
  height: 28px;
}
.db-label {
  color: #7d8d9f !important;
}
.cselect {
  /* background: url(../images/dropdown.png) no-repeat right #fefefe; */
  border-radius: 2px !important;
  -webkit-appearance: none;
  background-position: 98%;
}
.profile-setting-modal {
  border-radius: 5px;
}
.profile-setting-modal a.chosen-single,
.db-chosen a.chosen-single,
.db-table a.chosen-single {
  background: none !important;
  font-size: 14px;
  padding: 10px 12px !important;
  border-radius: 2px !important;
  border: 1px solid #dbe1e8 !important;
  color: #353f4c !important;
  -webkit-text-fill-color: inherit;
  box-shadow: none !important;
  caret-color: var(--primary-color);
  max-width: 100%;
  height: 40px !important;
  text-align: initial;
}
.db-chosen .chosen-results {
  text-align: initial;
}
.profile-setting-modal .chosen-container.chosen-container-single,
.db-chosen .chosen-container.chosen-container-single,
.db-table .chosen-container.chosen-container-single {
  width: 100% !important;
}
.profile-setting-modal .chosen-container-single .chosen-single div b,
.db-chosen .chosen-container-single .chosen-single div b,
.db-table .chosen-container-single .chosen-single div b {
  /* background: url(../../../images/Icons_latest/arrow-drop-down.svg) no-repeat; */
  background-position: 0px 16px !important;
}
.profile-setting-modal .chosen-container .chosen-drop,
.db-chosen .chosen-container .chosen-drop,
.db-table .chosen-container .chosen-drop {
  border: 1px solid transparent !important;
}
.profile-setting-modal .chosen-container-single .chosen-single span,
.db-table .chosen-container-single .chosen-single span,
.db-chosen .chosen-container-single .chosen-single span {
  line-height: initial;
}
#edit_pf_modal_output .fade.in {
  background: rgba(0, 0, 0, 0.4) !important;
}
button.btn-add-more {
  background: no-repeat;
  border: none;
  text-transform: uppercase;
  font-size: 12px;
  padding-left: 10px;
  font-weight: 500;
  color: var(--primary-color);
}
button.btn-add-more:focus {
  outline: none;
  box-shadow: initial !important;
}
button.btn-add-more df {
  font-size: 13px;
  border: 1px solid var(--primary-color);
  border-radius: 50%;
  position: relative;
  top: 1px;
  margin-right: 5px;
}
.bulk_action_filedsettings {
  font-size: 26px;
  position: relative;
  line-height: 0px;
  color: #2a333e;
}
.db-switche input {
  opacity: 0;
}
.filter-dropdwon ul.dropdown-menu {
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.24), 0 6px 10px 0 rgba(0, 0, 0, 0.08),
    0 1px 3px 0 rgba(0, 0, 0, 0.08) !important;
  border: none;
  border-radius: 2px !important;
  z-index: 9 !important;
  padding: 30px;
}
.filter-dropdwon li:after {
  content: "";
  clear: both;
  display: block;
}

.filter-dropdwon li {
  padding: 10px 0px;
  border-bottom: 1px solid #e8ecf1;
}
.multiselect-container::-webkit-scrollbar {
  width: 6px !important;
}
.multiselect-container::-webkit-scrollbar-track {
  background: #f7f8fa !important ;
}
.multiselect-container::-webkit-scrollbar-thumb {
  background: #bcc6d6 !important ;
  border: none !important;
}
.multiselect-container::-webkit-scrollbar-thumb:hover {
  background: #7d8d9f !important ;
}
.setting-action {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  padding: 20px;
  box-shadow: 0px 0px 15px #ddd;
  z-index: 9;
}
.applicable_countries .chosen-container-single {
  margin-bottom: 5px;
}
.applicable_companies span.multiselect-native-select {
  margin-bottom: 5px;
  display: block;
}
a.remove_alias df:hover,
.remove_applicable df:hover {
  color: #f44336;
  transition: all 0.5s ease;
}
.full-screen-modal .modal-dialog {
  width: 100%;
  margin-top: 0;
  height: max-content;
}
.each-detail-action df:hover {
  color: var(--primary-color);
}
.full-screen-modal label,
.personal-details-sec label,
.personal-details-sec h6 {
  text-transform: capitalize;
}
.full-screen-modal label {
  text-transform: none;
}
.stick-top-70 {
  position: fixed;
  top: 70px;
  width: calc(100% - 290px);
  z-index: 9;
}
.sticky-leftPanel {
  position: sticky !important;
  top: 70px;
}
.stickySection {
  height: calc(100vh - 235px);
  overflow-y: scroll;
}
.rel-top-170 {
  position: relative;
  top: 170px;
  margin-bottom: 160px;
}
.stickyMenu124 {
  position: fixed;
  top: 124px;
}
.whitebg {
  background: #fff !important;
}

.social_addons-icons .tooltip.fade.in {
  background-color: #fff !important;
}
.social_addons-icons .tooltip-inner {
  color: #000 !important;
  background: #fff !important;
}
.social_addons-icons .tooltip-arrow {
  border-top-color: #fff !important;
}

/**** Notification dropdown css ****/

.right-menu1 ul {
  padding: 0px;
  margin: 16px 0 0 0;
}
.right-menu1 ul li {
  display: inline-block;
  font-family: "Roboto", sans-serif;
}
.right-menu1 ul li:first-child {
  width: 30%;
}

.right-menu1 ul.dropdown-menu {
  width: inherit;
  left: initial;
  right: 0;
  border: initial;
  border-radius: 2px !important;
  background-color: #ffffff;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.4), 0 30px 30px 0 rgba(0, 0, 0, 0.1);
  min-width: 320px;
  margin-top: 0px;
  max-height: 400px;
  overflow: auto;
  z-index: 99;
}

ul#notification-menu:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 9px solid #dedede;
  position: absolute;
  top: -9px;
  right: 16px;
}

ul#notification-menu:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #fff;
  position: absolute;
  top: -8px;
  right: 17px;
}

.right-menu1 ul.dropdown-menu li {
  width: 100% !important;
  padding: 13px;
  font-size: 13px;
  color: #2a333e !important;
  border-bottom: 1px solid #e8ecf1;
}

.right-menu1 #notificationContainer {
  width: 50px;
  text-align: center;
  top: -5px;
  position: relative;
}
.right-menu1 .item-body a.site-color {
  color: #2a333e !important;
  font-weight: 500 !important;
  font-family: "Roboto", sans-serif;
}
.right-menu1 .item-body a {
  color: var(--primary-color);
  font-family: "Roboto", sans-serif;
}

.right-menu1 .badge-icon {
  margin: 0px auto;
  cursor: pointer;
}

.note-editor .note-toolbar .note-btn-group button:focus {
  color: #fff !important;
  background-color: var(--primary-color) !important;
}

.right-menu ul li a img {
  width: 30px;
  height: 30px !important;
  border-radius: 50%;
  position: relative;
  top: 5px;
}

.ui-autocomplete,
.ui-autocomplete.global-search-autocomplete {
  max-height: 300px !important;
  overflow-y: auto !important;
  /* prevent horizontal scrollbar */
  overflow-x: hidden !important;
  padding: 0px !important;
}
ul.ui-autocomplete,
ul.ui-autocomplete.global-search-autocomplete {
  border: none !important;
  box-shadow: 0 0 1px 0 rgba(42, 51, 62, 0.2),
    0 30px 30px 0 rgba(42, 51, 62, 0.08), 0 30px 30px 0 rgba(42, 51, 62, 0.08);
  position: fixed;
  top: 59px;
}
@media (max-width: 768px) {
  ul.ui-autocomplete,
  ul.ui-autocomplete.global-search-autocomplete {
    top: 130px !important;
  }
}
ul.ui-autocomplete::-webkit-scrollbar,
ul.ui-autocomplete.global-search-autocomplete::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
  background-color: #f7f8fa !important;
}
ul.ui-autocomplete::-webkit-scrollbar-thumb,
ul.ui-autocomplete.global-search-autocomplete::-webkit-scrollbar-thumb {
  background-color: #dbe1e8 !important;
  border: 2px solid transparent !important;
  border-radius: 9px !important;
  background-clip: content-box !important;
}
ul.ui-autocomplete::-webkit-scrollbar-thumb:hover,
ul.ui-autocomplete.global-search-autocomplete::-webkit-scrollbar-thumb:hover {
  border: initial !important;
}
ul.ui-autocomplete li .search-item,
ul.ui-autocomplete.global-search-autocomplete li .search-item {
  padding: 0px !important;
  box-shadow: none !important;
  border: none !important;
}
ul.ui-autocomplete li .search-item:hover,
ul.ui-autocomplete.global-search-autocomplete li .search-item:hover {
  background-color: #e8ecf1;
}
ul.ui-autocomplete .ui-menu-item {
  padding: 5px 20px !important;
  cursor: pointer;
}
.global-search-autocomplete .ui-autocomplete-category {
  font-weight: bold !important;
  padding: 10px 20px 10px 24px !important;
  font-size: 12px !important;
  text-transform: uppercase !important;
  color: #bcc6d6 !important;
  pointer-events: none;
}
.global-search-autocomplete .ui-state-focus {
  background: #e8ecf1 !important;
}
.global-search-autocomplete li.ui-menu-item {
  border: none !important;
}
input#employee-search_new::placeholder {
  font-size: 16px !important;
  position: relative;
  top: -1px;
}
input#employee-search_new {
  font-size: 16px !important;
}

.posi {
  top: 68px;
}
.posi2 {
  top: 129px !important;
}
.tabpane-ul-list li:hover .btn-hoverlayer {
  z-index: 9 !important;
}

.employee-list-3 {
  background: #f7f8fa;
  padding: 20px 0 0 0;
}
.mln-10 {
  margin-left: -10px !important;
}
.mln-15 {
  margin-left: -15px !important;
}

.mln-5 {
  margin-left: -5px !important;
}

.top-header .directory-avatar {
  cursor: pointer !important;
}

.bg-transparent {
  background: transparent !important;
}
table.table-condensed thead tr:first-child {
  border-bottom: 1px solid #bcc6d6 !important;
}
.max-width-550 {
  max-width: 550px;
}

.ellipsis-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
input.global-search {
  font-size: 12px;
}
.dataTables_wrapper.no-footer .dataTables_scrollBody {
  border-bottom: initial !important;
}
.prevent-pointer-events {
  pointer-events: none !important;
  opacity: 0.7 !important;
}
.social_addons-icons ul li {
  display: inline-block;
}
.approved_text {
  position: relative;
  width: 33%;
  float: right;
}
.t-initial {
  top: initial !important;
}
.approved_text a.t-initial {
  top: 0px !important;
  right: -4px !important;
}
.approved_text p {
  position: relative;
}
.approved_text p.decline_reason {
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0px !important;
  width: 210px;
}
.approved_text a.readmore {
  position: absolute;
  top: -40px;
  right: 0px;
}

.m-600 {
  max-width: 600px !important;
}

.unselectDate {
  position: absolute !important;
  top: -9px !important;
  right: 0;
}
.app_comp,
.alias_label_remove,
.app_country {
  position: relative;
}
.app_country .remove_applicable,
.app_comp .remove_applicable {
  position: absolute;
  right: -27px;
  top: 10px;
  font-size: 20px;
  color: #ddd;
  cursor: pointer;
}
.pr-10 {
  padding-right: 10px;
}

.scrolledTable {
  overflow-y: auto;
  clear: both;
}

#vertical_tabs {
  width: 100%;
}
.tab_container {
  clear: both;
}

.fixed-80 {
  position: fixed;
  top: 80px;
}

/** Org Chart Css **/
.user-main-deatails.pull-left {
  overflow: hidden;
  width: calc(100% - 96px);
}
.user-org-chart .single-org-panel {
  width: 200px;
  height: 100px;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.24), 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  margin: 0px auto;
  padding: 15px 10px;
  margin-bottom: 30px;
  position: relative;
  text-align: initial;
}
.user-org-chart .single-org-panel .profile-pic {
  width: 40px;
  height: 40px;
  background-color: #f7f8fa;
  border: solid 1px #dbe1e8;
  border-radius: 50%;
  line-height: 40px;
  text-align: center;
  text-transform: uppercase;
}
.user-org-chart .single-org-panel .profile-pic p {
  margin: 0px;
  color: #7d8d9f;
  font-size: 12px;
}
.user-org-chart .single-org-panel:after {
  content: "";
  position: absolute;
  height: 30px;
  background: #bcc6d6;
  bottom: -30px;
  left: 0;
  right: 0;
  width: 1px;
  margin: 0px auto;
}
.user-org-chart .current-user-panel {
  background-color: var(--primary-color) !important;
  box-shadow: 0 16px 20px 0 rgba(22, 118, 226, 0.3),
    0 6px 10px 0 rgba(22, 118, 226, 0.3) !important;
}
.user-org-chart .current-user-panel h5,
.user-org-chart .current-user-panel p {
  color: #fff !important;
}
.user-org-chart .current-user-panel .profile-pic {
  background: transparent;
  border: 1px solid #ffffff;
  overflow: hidden;
}
.user-org-chart .current-user-panel .profile-pic p {
  color: #fff !important;
}
.current-user-reportees {
  text-align: center;
  width: fit-content;
  margin: 0px auto;
  position: relative;
}
.current-user-reportees:before {
  content: "";
  position: absolute;
  height: 1px;
  background: #bcc6d6;
  top: 0px;
  left: 0;
  right: 0;
  width: calc(100% - 200px);
  margin: 0px auto;
  z-index: 0;
}
.current-user-reportees .single-org-panel {
  display: inline-block;
  margin: 20px 20px 20px 20px;
}
.current-user-reportees .single-org-panel:before {
  content: "";
  position: absolute;
  height: 20px;
  background: #bcc6d6;
  top: -20px;
  left: 0;
  right: 0;
  width: 1px;
  margin: 0px auto;
  z-index: 0;
}
.current-user-reportees .single-org-panel:after {
  background: none;
}
.current-user-reportees .single-org-panel:first-child {
  margin: 20px 20px 20px 0px;
}
.current-user-reportees .single-org-panel:last-child {
  margin: 20px 0px 20px 20px;
}
.single-reportee:before {
  background: transparent;
}
.single-reportee .single-org-panel {
  margin: 0px !important;
}
.multiple-reportee {
  border: 1px solid #bcc6d6;
}
.multiple-reportee:before {
  background: transparent;
}
.multiple-reportee .single-org-panel:before {
  background: transparent;
}
.multiple-reportee .single-org-panel:after {
  background: none;
}
.multiple-reportee .single-org-panel:first-child {
  margin: 20px;
}
.multiple-reportee .single-org-panel:last-child {
  margin: 20px;
}
.each-detail-action df {
  font-size: 24px;
  color: #bcc6d6;
}
.progress {
  height: 8px;
  margin-bottom: 0px;
  overflow: hidden;
  background-color: #dbe1e8;
  border-radius: 64px !important;
}
.progress-bar {
  background-color: var(--primary-color);
  box-shadow: none;
  border-radius: 65px;
}
.atachment-link {
  width: 160px;
  height: 36px;
  border-radius: 2px;
  background-color: #f7f8fa;
  border: solid 1px #e8ecf1;
  display: inline-block;
  padding: 6px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #4b5c72;
  font-size: 12px;
}
.atachment-link df {
  font-size: 18px;
  margin-right: 6px;
  position: relative;
  top: 3px;
}
.pending-indication {
  width: 116px;
  height: 20px;
  border-radius: 12px !important;
  background-color: #ffbb04;
  border: solid 1px #f4a21b;
  font-size: 12px;
  padding: 3px 2px 3px 10px;
  color: #2a333e !important;
}
.pending-indication df {
  font-size: 16px;
  position: relative;
  top: 3px;
}
.profile-nav {
  position: -webkit-sticky;
  position: sticky;
  top: 200px;
}
.profile-nav nav > ul {
  padding: 0px;
  list-style-type: none;
}
.profile-nav nav > ul {
  padding: 0px;
  list-style-type: none;
}
.profile-nav nav > ul > li {
  display: block;
}
.profile-nav nav > ul > li > a {
  font-weight: 500;
  color: #4b5c72;
  padding: 2px 10px;
  display: block;
  margin-bottom: 10px;
}
.profile-nav nav > ul > li > a.active {
  color: var(--primary-color);
  border-left: 2px solid var(--primary-color);
}
.profile-nav nav > ul > li ul {
  list-style-type: none;
  padding-left: 18px;
}
.profile-nav nav > ul > li ul li {
  display: block;
}
.profile-nav nav > ul > li ul li a {
  font-size: 12px;
  color: #7d8d9f;
  padding: 6px 0px;
  display: block;
}
.profile-nav nav > ul > li ul li a.active {
  color: #2a333e;
  font-weight: 500;
}
.sticky-sidebar {
  position: fixed;
  top: 0px;
}
.each-personal-detail-type:after {
  content: "";
  clear: both;
  display: block;
}
.user-avathar {
  max-width: 80px;
  position: relative;
  border-radius: 50% !important;
  overflow: hidden;
  transition: all 0.5s ease;
  margin: 0px auto;
}
.user-avathar:hover:after {
  content: "";
  background: #0000004a;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  transition: all 0.5s ease;
}
.user-avathar img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
}
.editimg-con {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  color: #fff;
  z-index: 9;
  cursor: pointer;
  width: fit-content;
  margin: 0px auto;
  transition: all 0.5s ease;
  display: none;
}
.user-avathar:hover .editimg-con {
  display: block;
  transition: all 0.5s ease;
}
.profile-pic span.directory-avatar {
  background: transparent;
  font-size: 12px;
  font-weight: 500;
  color: #7d8d9f;
  font-family: "Roboto", sans-serif;
}
.current-user-panel span.directory-avatar {
  color: #fff;
}
.single-org-panel h5,
.single-org-panel p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.bg-transparent {
  background: transparent !important;
}
.current-user-panel.no-reportees:after {
  background: none;
}
.scrolledTable {
  overflow-y: auto;
  clear: both;
}

.table-bordered {
  border: 1px solid #eee !important;
}

div.modal-header button.close {
  outline: none;
}
.posi_top_6 {
  position: relative;
  top: 6px;
}
.text_ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.chosen-container {
  font-size: 12px !important;
}

.chosen-newDesigne .chosen-container.chosen-container-single {
  width: 100% !important;
  height: 36px;
}

.chosen-newDesigne .chosen-container.chosen-container-single .chosen-single {
  height: 36px;
  font-size: 14px;
  padding: 4px 12px;
  border-radius: 2px !important;
  border: 1px solid #dbe1e8 !important;
  color: #353f4c !important;
  -webkit-text-fill-color: inherit;
  box-shadow: none;
  caret-color: var(--primary-color);
  background: #fff;
  transition: all 0.5s ease;
}

.chosen-newDesigne
  .chosen-container.chosen-container-single
  .chosen-single
  div
  b {
  background-position: 0px 7px;
}

.chosen-newDesigne
  .chosen-container.chosen-container-single.chosen-container-active
  .chosen-single {
  border: 1px solid var(--primary-color) !important;
}

.chosen-newDesigne .chosen-container.chosen-container-single .chosen-drop {
  border: none !important;
  top: calc(100% + 1px);
}

.chosen-newDesigne
  .chosen-container-active.chosen-with-drop
  .chosen-single
  div
  b {
  background-position: -17px 7px;
}
/* attendance module styles */
.howmany-days {
  font-family: "Roboto", sans-serif;
  font-size: 22px;
  color: #2a333e;
  font-weight: 300;
  margin-bottom: 0;
}
.howmany-days small {
  font-size: 12px;
  color: #2a333e;
}
.border-right-2 {
  border-right: 2px solid #e8ecf1;
}
.shift-section .col-md-3 {
  min-height: auto;
}
.btn-sm.border-radius-2 {
  border-radius: 2px !important;
}
a.btn.border-radius-l {
  border-radius: 2px 0 0 2px !important;
}
a.btn.border-radius-r {
  border-radius: 0 2px 2px 0 !important;
}
div.flot-left .dbSelect {
  height: 32px !important;
  padding-top: 5px !important;
}

.mln-1 {
  margin-left: -1px;
}
button.raise_requistion {
  font-size: 11px;
  border: none;
  background: none;
  position: absolute;
  bottom: -5px;
  right: 0;
  left: 0;
  font-weight: 500;
  font-family: roboto;
}

.checkbox-green label::before {
  border: 1px solid #2ca860 !important;
}
.checkbox-green label:focus {
  outline: none !important;
}

.checkbox-green input[type="checkbox"]:checked + label::before,
.checkbox-green input[type="radio"]:checked + label::before {
  background-color: #4ca961 !important;
  border-color: #4ca961 !important;
}

.note-editor.note-frame.fullscreen .note-toolbar-wrapper.panel-default {
  display: none;
}

/*** Custom Roster New CSS ***/
.custom-roster ul.list1 {
  padding: 0px;
}
.custom-roster div#custom_r {
  padding: 0px !important;
}
.custom-roster .box-item {
  z-index: 1000;
}
.custom-roster .welldrop {
  border: initial;
  overflow: auto;
}
.custom-roster .drop-here-reports,
.custom-roster .drop-from-reports {
  min-height: calc(100vh - 145px);
  max-height: calc(100vh - 145px);
  overflow: auto;
}
.custom-roster .welldrop ul {
  padding: 0px;
}
.custom-roster .labels-selected {
  position: relative;
}
.custom-roster .custom-roster-tree {
  position: absolute;
  background: #fff;
  z-index: 99;
  min-width: 400px;
  padding: 15px;
  max-height: 380px;
  overflow: auto;
}
.custom-roster .select-dropdown {
  height: 36px;
  line-height: 36px;
  border: 1px solid #ddd;
  background-position: 99%;
}
.custom-roster .modal-body li {
  list-style: none;
  padding: 6px 10px 6px 25px;
  font-size: 14px;
  color: #4b5c72;
  position: relative;
}
.custom-roster .modal-body li:hover {
  background: #fff;
  box-shadow: 0 2px 10px 0 rgba(42, 51, 62, 0.1);
  border: solid 1px #e8ecf1;
}
.custom-roster .dragdroph {
  font-size: 13px;
  font-weight: 500;
  text-align: left;
  color: #bcc6d6;
  font-family: "Roboto", sans-serif;
  padding-left: 25px;
}
.custom-roster .custom-heading {
  margin-top: 0;
  font-size: 13px;
  font-weight: 500;
  text-align: left;
  color: #353f4c;
  font-family: "Roboto", sans-serif;
  padding-left: 25px;
}
.custom-roster .labels-selected,
.custom-roster-tree {
  display: none;
}
.custom-roster .showDiv {
  display: block !important;
}
.custom-roster-tree li,
li.search-field {
  padding: initial !important;
}
.custom-roster-tree li:hover,
li.search-field:hover {
  box-shadow: initial !important;
  border: initial !important;
}
.custom-roster .modal-content {
  padding: 0px;
}
.custom-roster .modal-header {
  padding: 30px 30px 10px 30px;
}
.custom-roster .modal-body {
  padding: 0px 30px 30px 30px;
}
.custom-roster .selectedNodes {
  padding: 3px 10px;
  font-weight: initial !important;
}
.custom-roster .dep-select {
  border-bottom: 1px solid #ddd;
}
.custom-roster .dep-select p.log {
  margin: 10px 0 0 0;
}
.custom-roster li.placeHolder:hover {
  border: initial;
  box-shadow: initial;
  background: transparent;
}
.custom-roster li.placeHolder:hover:before {
  background: none !important;
}
a#close-custom-roster-tree {
  clear: both;
  display: block;
  text-align: center;
  color: #0374c5;
  border: 1px solid #1379c1;
  max-width: 65px;
  padding: 3px 10px;
  float: right;
  text-transform: uppercase;
  font-size: 11px;
}
.custom-roster #toggleArrow {
  height: 40px;
  width: 40px;
  background: #fff;
  text-align: center;
  line-height: 40px;
  display: none;
  position: absolute;
  right: 0px;
  bottom: 2px;
  cursor: pointer;
  font-size: 26px;
}
.custom-roster button#btn_view_roster {
  border-radius: 3px;
  padding: 8px 10px;
  font-size: 12px;
  margin: 20px 5px 0px 0px;
}

.custom-roster .modal-body li:hover:before {
  content: "";
  /* background: url(../images/custom-roster-itembg.svg); */
  position: absolute;
  left: -2px;
  width: 20px;
  height: 20px;
  top: 3px;
}

.modal-body.hide-action-bar::after {
  content: "";
  position: absolute;
  height: 21px;
  background: #e3e3e3;
  width: 89px;
  right: 1px;
  bottom: 6px;
  z-index: 1;
}

.modal-body.hide-action-bar::before {
  content: "";
  position: absolute;
  height: 21px;
  background: #e3e3e3;
  left: 117px;
  right: 120px;
  bottom: 6px;
  z-index: 1;
}

.modal-body.hide-action-bar iframe {
  border: 1px solid #e1e1e1;
  border-width: 0px 1px 1px 0;
}

.modal-body:hover.hide-action-bar iframe {
  border-color: #257ee2;
}

.custom-roster .drop-here-bg {
  /* background: url(../images/drop-here.svg) no-repeat; */
  background-position-y: 30%;
  background-position-x: center;
  min-height: 500px;
}
.custom-roster .modal-header .close {
  margin-top: -30px;
  margin-right: -20px;
}
.drop-here-reports {
  border-right: 1px solid #e8ecf1;
}
.list1 li.slide-fade,
#list2 li.slide-fade {
  transition: all 0.5s ease-out;
  opacity: 0;
}
.custom-roster-tree li:hover:before,
li.search-field:hover:before,
li.result-selected:hover:before {
  background: none !important;
}
.reportssheduler_customroseter .modal-title {
  border-bottom: 1px solid #e8ecf1;
  margin-top: 8px;
  padding-bottom: 10px;
}
.token a.close {
  margin-right: initial !important;
  margin-top: initial !important;
}
.chosen-results li:hover:before {
  background: none !important;
}
.log span.selectedNodes {
  background: #fff;
  border: 1px solid #ddd;
  padding: 3px 10px;
  border-radius: 10px;
  margin: 0px 3px 5px 3px;
  display: inline-block;
  font-size: 13px;
  color: #444444;
}
.log span.selectedNodes .fa {
  margin-left: 8px;
  color: #a5a5a5;
}

#roster_input label.single_asign_user_shift:before {
  visibility: hidden;
}

#roster_input tr td:hover label.single_asign_user_shift:before {
  visibility: visible;
}

#roster_input input[type="checkbox"]:checked + label::before {
  visibility: visible;
}

#status_input .inactive-cell span {
  display: block;
}
#status_input .inactive-cell span.pending_request {
  cursor: pointer;
}

.notification_text {
  min-width: 260px;
}
.notification_text li {
  list-style-type: decimal;
  font-size: 13px;
  color: #7d8d9f;
}

.main-logo a {
  line-height: 15px !important;
}

.checkin-list {
  height: 450px;
  overflow: hidden;
  overflow-y: scroll;
  padding-left: 50px;
}
.checkin-list li {
  border-bottom: 1px solid #e8ecf1;
  position: relative;
  padding: 8px 15px 15px 15px;
  margin-right: 10px;
}
.checkin-list li.active_location {
  background: #e8ecf1;
  border: 1px solid #ddd;
  border-radius: 4px !important;
}
.checkin-list li:not(:last-child):before {
  content: "";
  background: #e8ecf1;
  width: 2px;
  height: 124%;
  position: absolute;
  left: -31px;
}
.checkin-list span.counter_point {
  left: -45px;
  position: absolute;
  top: 0px;
  background: #e8ecf1;
  border-radius: 50%;
  padding: 2px;
}
.checkin-list span.conter_num {
  /* background-image: url(../images/location-icon.svg); */
  display: block;
  background-size: 28px;
  background-position: -1px 1px;
  background-repeat: no-repeat;
  line-height: 28px;
  width: 28px;
  height: 28px;
  color: #fff;
  font-size: 11px;
  text-align: center;
  font-weight: 700;
}
.default-table-style {
  border-spacing: 0;
  border-collapse: collapse;
}

.default-cell-style {
  border: 1px solid #e6e6e6 !important;
  padding: 12px;
}

.custom-popover.fade {
  max-width: 600px !important;
}

.custom-popover.fade.in {
  color: #333 !important;
}

.custom-popover.top .popover-content {
  padding: 15px !important;
}

.custom-popover .popover-content {
  max-height: 300px !important;
  overflow-y: auto !important;
  padding: 15px !important;
}

.custom-popover ul li {
  list-style-type: disc;
}

.custom-popover ol li {
  list-style-type: decimal;
}

tr[table_type="formatted"] td,
tr[table_type="formatted"] th,
td[table_type="formatted"],
th[table_type="formatted"] {
  border: 1px solid black !important;
}

#letter_preview table > tbody > tr > td,
#letter_preview table > tbody > tr > th,
#letter_preview table > tfoot > tr > td,
#letter_preview table > tfoot > tr > th,
#letter_preview table > thead > tr > td,
#letter_preview table > thead > tr > th {
  border-left: 1px solid #e6e6e6 !important;
}

.custom-popover table > tbody > tr > td:first-child,
.custom-popover table > tbody > tr > th:first-child,
.custom-popover table > tfoot > tr > td:first-child,
.custom-popover table > tfoot > tr > th:first-child,
.custom-popover table > thead > tr > td:first-child,
.custom-popover table > thead > tr > th:first-child {
  border-left: 1px solid !important;
}
.emp_checkin_empty_state p {
  font-size: 20px;
  font-weight: normal;
  color: #4b5c72;
}
.filed_description {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  display: inline-block;
}

.phone_prefix_style {
  display: flex;
}
.phone_prefix_style .db-dropdown.ui.selection.searchabledropdown {
  min-width: 1em !important;
  max-width: 90px !important;
  margin-right: 8px;
}

.form-inline .checkbox input[type="checkbox"],
.form-inline .radio input[type="radio"] {
  margin-right: 0 !important;
}

.fixModal {
  padding-right: 0 !important;
  margin-right: 0 !important;
}
.counter_num {
  border: solid 1px #c4dcf7;
  background-color: #edf4fd;
  width: 30px;
  height: 30px;
  display: inline-block;
  line-height: 30px;
  border-radius: 50%;
  font-size: 12px;
}

.main_details_section .title-1 {
  cursor: pointer;
}

.main_details_section .title-1.primary-blue {
  border-bottom: 2px solid #1576e2;
  padding-bottom: 2px;
}

#request_attendance_modal .width-x.db-dropdown.ui.selection.searchabledropdown {
  min-width: 48% !important;
  width: 48%;
  display: inline-block !important;
}

.waiter-dark {
  background: #fff !important;
}

.tokenfield.form-control.db-input {
  font-size: 14px;
  padding: 7px 12px;
  border-radius: 4px !important;
  border: 1px solid #bcc6d6 !important;
  color: #353f4c !important;
  -webkit-text-fill-color: inherit;
  box-shadow: none;
  caret-color: var(--primary-color);
  background: #fff;
  transition: all 0.5s ease;
  padding-right: 36px;
  min-height: 36px !important;
}

ul.ui-autocomplete.global-search-autocomplete {
  border-radius: 4px;
}

.tokenfield .token {
  position: relative;
  height: auto;
  padding-right: 24px;
  line-height: normal;
  margin: 4px;
  border-radius: 2px;
}

.tokenfield .token-input {
  margin: 0 !important;
  padding: 0 !important;
  font-size: 14px;
}

.tokenfield .token .token-label {
  max-width: initial !important;
  padding: 0;
  line-height: 1.5;
  white-space: normal;
  font-size: 12px;
  color: #2a333e;
}

.tokenfield .token .close {
  padding-right: 0;
  padding-left: 0;
  line-height: 1.2 !important;
  position: absolute;
  right: 8px;
  top: 8px;
}

.vertical-align-unset .pending-indication {
  vertical-align: 2px;
}
.visitor_managment .chosen-container.chosen-container-single {
  width: 100% !important;
}

a.copy-request {
  position: absolute;
  right: 50px;
  top: 14px;
  display: none;
  background: #fff;
  padding: 2px 10px;
}
.box_bg:hover a.copy-request {
  display: block;
}
a.copy-request img {
  width: 30px;
  height: auto;
}

div.rating-stars {
  min-width: 20px;
  display: inline-block;
}
.rating-stars input.each-star {
  display: none;
}
.rating-stars label.each-star {
  padding: 5px 5px 0 5px;
  font-size: 23px;
  color: #444;
  transition: all 0.2s;
  margin-bottom: 0;
  background: transparent !important;
}

.rating-stars label.each-star:before {
  content: "\f006";
  font-family: FontAwesome;
  background: transparent !important;
}

.rating-stars label.rating_selected:before {
  content: "\f005";
  font-family: FontAwesome;
  color: var(--third-blue);
  background: transparent !important;
}
.rating-stars label.each-star:hover {
  transform: rotate(-15deg) scale(1.3);
}

.sub-navigation1 {
  text-align: center;
  margin-bottom: 0;
  padding: 0 44px 0 0;
  box-shadow: 0px 2px 4px #ddd;
  margin: 0 !important;
  border-bottom: 1px solid #ccc;
}
.sub-scroll {
  border: none;
}
.pag {
  text-align: center;
  padding: 24px 0;
}
.pag a {
  border: 1px solid transparent !important;
  height: 36px;
  width: 36px;
  text-align: center;
  line-height: 24px;
  font-size: 12px;
  color: #4b5c72 !important;
  margin: 0px 4px;
  box-shadow: none;
  padding: 6px 12px;
  border-radius: 50%;
  font-weight: normal !important;
}
.pag a.active {
  border: 1px solid var(--primary-color) !important;
  color: var(--primary-color) !important;
  background-color: #fff !important;
  border-radius: 50%;
  box-shadow: none;
}
.min-h-300 {
  min-height: 300px !important;
}
.popover.bottom > .arrow {
  border-bottom-color: rgba(195, 189, 189, 0.25) !important;
}
.popover {
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2) !important;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2) !important;
}

.max-h-36 {
  max-height: 36px !important;
}
/* text-turncate for 3 line */
.hg-text-turncate {
  display: -webkit-box;
  max-width: 108px;
  height: 68.2px !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.external-recruiter-header {
  height: 60px;
  box-shadow: 0 2px 6px 0 rgba(47, 83, 151, 0.1);
  background-color: #ffffff;
  padding: 5px 0;
  position: fixed;
  width: 100%;
  z-index: 10;
}

@keyframes glowing {
  0% {
    background-color: var(--primary-color);
    box-shadow: 0 0 5px var(--primary-color);
  }
  50% {
    background-color: var(--primary-color);
    filter: brightness(80%);
    box-shadow: 0 0 20px var(--primary-color);
  }
  100% {
    background-color: var(--primary-color);
    box-shadow: 0 0 5px var(--primary-color);
  }
}
.heighlighted_btn {
  animation: glowing 1300ms infinite;
}
.max-h-36 {
  max-height: 36px !important;
}
.pt_slab_versions a:not(:last-child) {
  border-bottom: 1px solid #e8ecf1;
}
.pt_slab_versions a {
  padding: 10px 0px;
  color: #2a333e;
  display: block;
}
.ptslabtable.db-table-one tbody tr td {
  padding: 12px 4px !important;
}
.db-customized-modal .full-page-modal .ptslabeditmodal {
  padding: 35px 26px !important;
}
.customrequired {
  color: #8f9fc4;
}

.ui-menu .ui-menu-item.ui-state-focus {
  background: #e8ecf1;
}
.open > .distancemenu_section {
  width: 552px;
  height: 400px;
  overflow: scroll;
  border-radius: 2px !important;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  left: 40%;
  transform: translateX(-40%);
}
.open > .distancemenu_section:after {
  content: "";
  display: block;
  position: absolute;
  top: -20px;
  left: 40%;
  transform: translateX(-40%);
  width: 0;
  height: 0;
  border-color: transparent transparent #ffffff transparent;
  border-style: solid;
  border-width: 10px;
}
.btn-group.open .dropdown-toggle {
  box-shadow: none;
}
.db-btn.btn-primary {
  border: 1px solid var(--primary-color) !important;
}
.db-label-legend {
  z-index: 1;
  margin-bottom: 0;
  position: relative;
  left: 8px;
  top: 8px;
  font-size: 10px;
  background-color: #ffffffff;
}
ul.locationtimeline {
  list-style-type: none;
  position: relative;
}
ul.locationtimeline:before {
  content: " ";
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 400;
}
ul.locationtimeline > li {
  margin: 20px 0;
  padding-left: 20px;
  border-bottom: 1px solid #dce0e5;
}
ul.locationtimeline > li:nth-last-child(1):before {
  background: #ea7d0c;
  border: 3px solid #ea7d0c;
}
ul.locationtimeline > li:before {
  content: " ";
  background: #3bca17;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #3bca17;
  left: 20px;
  width: 20px;
  height: 20px;
  z-index: 400;
}
.manage_date {
  padding: 12px 6px 0px 6px;
  background: #eee;
}
.distancetext {
  font-size: 12px;
  color: #827a7a;
}
button:focus,
a:focus {
  outline: none !important;
}
.message-word-wrap {
  white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
  white-space: -webkit-pre-wrap; /* Chrome & Safari */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  white-space: pre-wrap; /* CSS3 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  word-break: normal;
  white-space: normal;
}
.form-control {
  height: 36px;
  color: #555;
  transition: none !important;
  border-radius: 4px !important;
  border: 1px solid #bcc6d6 !important;
}
.form-control[disabled],
.form-control[readonly]:not(.db-datepicker),
fieldset[disabled] .form-control {
  background: #f7f8fa !important;
  border: solid 1px #e8ecf1 !important;
}
.form-group .clearBtn,
.controls .clearBtn {
  background: transparent;
  border: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 8px;
  font-size: 22px;
  line-height: 1;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  padding: 1px 0;
}

.list-style-lower-alpha li {
  list-style-type: lower-alpha;
}
.close:focus,
.close:hover {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  filter: alpha(opacity=50);
  opacity: 0.5;
}
.fc-left,
.fc-header-left {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0 !important;
}
.fc-header-left {
  padding: 0px â€‹0px 12px 0px !important;
}
.fc-header .fc-header-center,
.fc-header .fc-header-right {
  display: none;
}
.fc-left .fc-button,
.fc-header-left .fc-button {
  margin: 0 !important;
  width: 33px;
  height: 32px;
  line-height: 0 !important;
  padding: 11px 0 !important;
  text-align: center;
}
.fc-header-left .fc-header-space {
  display: none;
}
.fc-left .fc-button .fc-text-arrow,
.fc-header-left .fc-button .fc-text-arrow {
  font-size: 24px;
  line-height: 12px;
}
.fc-left .fc-prevYear-button,
.fc-header-left .fc-button-prevYear {
  order: 1;
  margin-right: 8px !important;
}
.fc-left .fc-prev-button,
.fc-header-left .fc-button-prev {
  order: 2;
}
.fc-left h2,
.fc-header-left .fc-header-title {
  order: 3;
  margin: 0 !important;
  width: calc(100% - 128px);
  text-align: center;
}
.fc-left .fc-next-button,
.fc-header-left .fc-button-next {
  order: 4;
}
.fc-left .fc-nextYear-button,
.fc-header-left .fc-button-nextYear {
  order: 5;
  margin-left: 8px !important;
}
.custom_tooltip {
  font-size: 14px;
  position: relative;
  top: 1px;
  color: #7d8d9f;
}
.angular-form .db-label,
.angular-form .caption,
.angular-form .form-label {
  font-weight: 500 !important;
  font-size: 1.1rem !important;
  margin-bottom: 1.66rem !important;
}
.angular-form .select_emp,
.angular-form .randr-citation {
  padding-left: 1rem;
}
.nav-wrapper {
  position: relative;
  z-index: 99;
}
.calendar_font:before {
  font-size: 20px;
  position: relative;
  top: 3px;
}
.calendar_font {
  padding: 0px 7px 4px 7px !important;
}
.fixed-top {
  position: fixed !important;
}
.h1,
.h2,
.h3,
h1,
h2,
h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}
.clearDatesBtn {
  border-width: thin;
}
.dropdown-toggle {
  white-space: unset;
}
.fit-content {
  width: fit-content;
}
.opacity-0 {
  opacity: 0;
}
.color-black {
  color: #000;
}
.italic {
  font-style: italic;
}
optgroup {
  color: #333 !important;
}
.expand_modal {
  width: 100% !important;
}
.sidebar_footer_expand {
  position: fixed;
  width: 100% !important;
  right: 0;
  bottom: 0;
  padding: 12px 16px;
  box-shadow: 0 -1px 0 0 #dbe1e8;
  background: #fff !important;
  z-index: 9;
}
.min-h-60 {
  min-height: 60vh;
}
.request-review-orange {
  color: #fd7e14 !important;
}
.request-pending {
  color: black;
}
.right_sidebar .sidebar_show_section {
  right: 0 !important;
  min-height: 100vh;
}
.right_sidebar .sidebar_show_section .sidebar_content {
  height: calc(100vh - 64px);
  overflow-x: auto;
}
.right_sidebar .sidebar_show_section .sidebar-actions {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 10px 16px;
  box-shadow: 0 -1px 0 0 #dbe1e8;
  background: #fff !important;
  z-index: 9;
  width: 100% !important;
}
.pr-120 {
  padding-right: 120px;
}
.min-h-75 {
  min-height: 75px !important;
}
.align-baseline {
  align-items: baseline;
}
.db-dropdown.ui.selection.searchabledropdown .menu .header.optgroup {
  position: relative;
  text-align: left;
}
.db-dropdown.ui.selection.searchabledropdown .menu .header.optgroup span {
  background: #fff;
  font-size: 12px;
  padding: 9px 15px;
  font-weight: 500;
  display: inline-block;
}

.menu section.show-label {
  position: relative;
  margin-top: 32px;
}
.menu section.show-label:before {
  content: attr(data-label);
  display: block;
  width: 100%;
  background: #f7f8fa;
  position: absolute;
  top: -31px;
  left: 0;
  padding: 8px 16px;
  font-size: 12px;
  font-weight: 500;
  pointer-events: none;
  z-index: 15;
  border-top: 1px solid #e8ecf1;
  border-bottom: 1px solid #e8ecf1;
}
.ui.selection.searchabledropdown .menu .item {
  border-top: none !important;
}
.text-turncate {
  white-space: nowrap;
  width: 144px !important;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cursor-unset {
  cursor: unset !important;
}
.sticky-head {
  position: sticky;
  top: 0px;
  z-index: 999;
  background-color: #ffffff;
}
.mr-100 {
  margin-right: 100px !important;
}
.pl-30 {
  padding-left: 30px !important;
}
.bg-f1-imp {
  background-color: #f1f1f1 !important;
}
